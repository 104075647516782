<template>
  <v-col cols="12">
    <v-tabs v-model="active">
      <v-tab key="search" ripple>Rechercher</v-tab>
      <v-tab key="create" ripple>Créer</v-tab>
      <v-tab-item key="search">
        <v-card flat>
          <v-card-text>
            <v-row class="px-2 pb-3">
              <v-text-field
                v-model="search.value"
                clearable
                label="Identifiant, Nom du projet, Nom du client"
                hint="Elements séparés par une virgule: 1234, Voyage Vacances, Jean Martin"
                persistent-hint
                append-icon="mdi-magnify"
                @keypress.enter="searchBookingProject"
                @click:append="searchBookingProject"
                :loading="search.loading"
              ></v-text-field>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-list subheader dense v-if="search.results.length>0">
                  <v-subheader>{{search.results.length}} Projets</v-subheader>
                  <template v-for="(item, index) in search.results">
                    <v-list-tile :key="'customer-'+item.id" :active="item.active">
                      <v-list-tile-content>
                        <v-list-tile-title>
                          Projet {{item.name}} {{ item.customerName ? "pour le client" + " " + item.customerName : ""}} créé
                          le {{item.creationDate}}
                        </v-list-tile-title>
                      </v-list-tile-content>

                      <v-list-tile-action>
                        <v-icon @click="linkBookingProject(item.id)">add</v-icon>
                      </v-list-tile-action>
                    </v-list-tile>
                    <v-divider v-if="index + 1 < search.results.length" :key="'divider-'+index"></v-divider>
                  </template>
                </v-list>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item key="create">
        <create-and-associate-booking-to-project
          :bookingId="parseInt(bookingId)"
          @success="close()"
        ></create-and-associate-booking-to-project>
      </v-tab-item>
    </v-tabs>
  </v-col>
</template>

<script>
import { get, post } from "../../utils/api";
import { EventBus } from "@/components/commons/event-bus";
import CreateAndAssociateBookingToProject from "../commons/CreateAndAssociateBookingToProject";

export default {
  name: "AttachToBookingProject",
  components: {
    CreateAndAssociateBookingToProject,
  },
  props: {
    bookingId: {
      type: Number,
      required: true,
    },
  },
  data: function () {
    return {
      active: null,
      search: {
        value: null,
        loading: false,
        results: [],
      },
    };
  },
  methods: {
    searchBookingProject() {
      const url = `/api/bookingproject/search?search=${this.search.value}`;

      this.search.loading = true;

      get(url).json((r) => {
        this.search.results = r;
        this.search.loading = false;
      });
    },
    linkBookingProject(id) {
      const url = `/api/bookingproject/${id}/attachbookings`;

      post(url, [this.bookingId])
        .badRequest((err) => {
          EventBus.$emit("toaster-msg", err);
        })
        .json((response) => {
          EventBus.$emit("toaster-msg", "Dossier associé");
          this.$emit("success", {});
          this.search = {
            ...this.search,
            ...{ value: null },
            ...{ results: [] },
          };
        });
    },
    close() {
      this.$emit("success", {});
    },
  },
};
</script>

<style scoped>
</style>