<template>
  <div>
  <WidgetTile :item="data" v-if="data != null" :title="conf.title"/>
    </div>
</template>
<script>
import {post} from "@/utils/api";
import WidgetTile from "@/components/commons/widgets/WidgetTile.vue";
import {EventBus} from "@/components/commons/event-bus";

export default {
  name: "SalesTile",
  components: {WidgetTile},
  props: {
    form: {type: Object, required: true},
    conf: {type: Object, required: false, default: null}
  },
  data() {
    return {
      url: `/api/widget/dashboard/total`,
      data: null,
    }
  },
  computed: {},
  created() {
    EventBus.$on('searchDashboard', () => this.init());
    this.init();
  },
  methods: {
    init() {
      const searchForm = this._formatPayloadWidgetDashboard(this.conf,this.form)
      post(this.url, searchForm, {"Content-Type": "application/json"})
          .json((r) => {
            this.data = r.yearN.find((n) => n.name === "Total Vente")
            this.data.old = r.yearPast.find((n) => n.name === "Total Vente").value
            r.yearN.forEach((p, ind) => {
              p.old = r.yearPast[ind].value;
            });
            this.data = {
              ...this.data,
              type: 'tile',
              x : 0,
              y : 0,
              w : 2,
              h : 1,
              i : this.data.name,
              isPrice: true
            }
          })
    }
  },
}
</script>