<template>
  <layout :drawerShow="_isDesktop()" :drawerWidth="350">
    <template slot="drawer">
      <v-form>
        <v-subheader light class="pa-0 mb-0 mt-4 mx-0 form-subheader"
          >Dates de réservation</v-subheader
        >
        <v-divider></v-divider>

        <v-row justify="start">
          <v-col class="pr-1" cols="6">
            <smart-picker
              v-model="searchform.bookingStartDate"
              :stopDate="searchform.bookingStopDate"
              :inputOpts="{
                label: $t('begin'),
                clearable: true,
                placeholder: ' ',
              }"
            ></smart-picker>
          </v-col>
          <v-col cols="6">
            <smart-picker
              v-model="searchform.bookingStopDate"
              :startDate="searchform.bookingStartDate"
              :inputOpts="{
                label: $t('end'),
                clearable: true,
                placeholder: ' ',
              }"
            ></smart-picker>
          </v-col>
        </v-row>

        <v-subheader light class="pa-0 mb-0 mt-6 mx-0 form-subheader"
          >Dates de voyage</v-subheader
        >
        <v-divider></v-divider>

        <v-row>
          <v-col cols="6">
            <smart-picker
              v-model="searchform.startDate"
              :stopDate="searchform.stopDate"
              :inputOpts="{
                label: $t('begin'),
                clearable: true,
                placeholder: ' ',
              }"
            ></smart-picker>
          </v-col>
          <v-col cols="6">
            <smart-picker
              v-model="searchform.stopDate"
              :startDate="searchform.startDate"
              :inputOpts="{
                label: $t('end'),
                clearable: true,
                placeholder: ' ',
              }"
            ></smart-picker>
          </v-col>
        </v-row>

        <v-subheader light class="pa-0 mb-0 mt-6 mx-0 form-subheader"
          >Hôtels</v-subheader
        >
        <v-divider></v-divider>

        <smart-select
          v-model="searchform.selectedHotel"
          item-text="name"
          item-value="id"
          label="Hotels"
          :default-select-all="true"
          :all-is-same-as-empty="true"
          :loading="loadingHotels"
          :items="hotels"
          :chip-style="{ width: '120px' }"
          class="mt-6 mb-4"
        ></smart-select>

        <smart-btn
          block
          primary
          :loading="listing.loading"
          @click.native="search()"
          >Rechercher</smart-btn
        >
      </v-form>
    </template>

    <template slot="content">
      <v-container fluid>
        <v-row justify="space-between">
          <v-col cols="12" class="mb-3">
            <v-card class="pa-0 ma-0 blue-grey lighten-1 white--text">
              <v-row justify="space-around">
                <v-col
                  cols="12"
                  md="3"
                  class="pa-2 ma-0"
                  align-content="center"
                >
                  <v-row justify="center" class="headline text-center">
                    Montant provisionné
                  </v-row>
                  <v-row justify="center" class="body-1 text-center">{{
                    provisionedAmount | currency("EUR")
                  }}</v-row>
                  <v-row justify="center" class="body-1 text-center"
                    >{{ nbIncentives }} résas</v-row
                  >
                </v-col>
                <v-col cols="12" md="3" class="pa-2 ma-0">
                  <v-row justify="center" class="headline text-center">
                    Montant versé
                  </v-row>
                  <v-row justify="center" class="body-1 text-center">{{
                    paidAmount | currency("EUR")
                  }}</v-row>
                  <v-row justify="center" class="body-1 text-center"
                    >{{ nbValidatedGiftcard }} résas</v-row
                  >
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>

        <v-data-table
          :headers="listing.headers"
          :loading="listing.loading"
          :items="listing.items"
          :options.sync="listing.pagination"
          :footer-props="{
            'items-per-page-options': listing.rowsPerPageItems,
            'items-per-page-text': 'Lignes par page',
          }"
          class="elevation-1 tbl"
          no-data-text="Il n'y a pas d'incentive à afficher"
        >
          <template #header.copy>
            <v-icon @click="copy()" small>mdi-clipboard-outline</v-icon>
          </template>
          <template #item.amount="{ item }">
            {{ item.amount ? item.amount : 0 }} €
          </template>

          <template #item.status="{ item }">
            <span v-if="item.status">{{ item.status }}</span>
            <span v-else>-</span>
          </template>
        </v-data-table>
      </v-container>
    </template>
  </layout>
</template>

<script>
import Layout from "@/views/Layout";
import SmartSelect from "@/components/commons/SmartSelect";
import Availability from "@/components/commons/Availability";
import { get, post } from "@/utils/api";
import clipboard from "@/utils/clipboard";

export default {
  name: "IncentiveRecap",
  components: {
    Layout,
    SmartSelect,
    Availability,
  },
  data() {
    return {
      hotels: [],
      loadingHotels: false,
      nbIncentives: 0,
      nbValidatedGiftcard: 0,
      paidAmount: 0,
      provisionedAmount: 0,
      listing: {
        headers: [
          {
            text: "Agence",
            value: "agency",
            align: "left",
          },
          {
            text: "Agent",
            value: "agent",
            align: "left",
          },
          {
            text: "Dossier",
            value: "bookingId",
            align: "left",
          },
          {
            text: "Hôtel",
            value: "hotel",
            align: "left",
          },
          {
            text: "Status",
            value: "status",
            align: "left",
          },
          {
            text: "Montant",
            value: "amount",
            align: "left",
          },
          {
            text: "",
            value: "copy",
            align: "right",
          },
        ],
        loading: false,
        rowsPerPageItems: [{ text: "Tous", value: -1 }, 50, 200],
        pagination: {
          options: {
            page: 1,
            itemsPerPage: 200,
            sortBy: [],
            sortDesc: [false],
          },
        },
        items: [],
      },
      searchform: {
        bookingStartDate: this._formatDate(
          this._getFirstMonthDay(this._now()),
          "dd/MM/yyyy"
        ),
        bookingStopDate: this._formatDate(
          this._getLastMonthDay(this._now()),
          "dd/MM/yyyy"
        ),
        startDate: null,
        stopDate: null,
        selectedHotel: [],
      },
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      get("/api/search/hotels").json((hotels) => {
        this.hotels = hotels;
      });
    },
    search() {
      const url = "/api/incentive/search";
      this.listing.loading = true;

      let form = {
        ...this.searchform,
        selectedHotel: this.searchform.selectedHotel[0],
        bookingStartDate: this.searchform.bookingStartDate
          ? this._parseAndFormatDate(
              this.searchform.bookingStartDate,
              this.datePatternConfig.serverLocalDate,
              this.datePatternConfig.serverLocalDateTime
            )
          : null,
        bookingStopDate: this.searchform.bookingStopDate
          ? this._parseAndFormatDate(
              this.searchform.bookingStopDate,
              this.datePatternConfig.serverLocalDate,
              this.datePatternConfig.serverLocalDateTime
            )
          : null,
        startDate: this.searchform.startDate
          ? this._parseAndFormatDate(
              this.searchform.startDate,
              this.datePatternConfig.serverLocalDate,
              this.datePatternConfig.serverLocalDateTime
            )
          : null,
        stopDate: this.searchform.stopDate
          ? this._parseAndFormatDate(
              this.searchform.stopDate,
              this.datePatternConfig.serverLocalDate,
              this.datePatternConfig.serverLocalDateTime
            )
          : null,
      };
      post(url, form).json((data) => {
        this.listing.items = data.incentives.map((incentive) => {
          return {
            ...incentive,
            ...{
              hotel: incentive.hotel
                .replaceAll('{"', "")
                .replaceAll('"}', "")
                .replaceAll('","', ", "),
            },
          };
        });
        this.paidAmount = data.paidAmount ? data.paidAmount : 0;
        this.provisionedAmount = data.provisionedAmount
          ? data.provisionedAmount
          : 0;
        this.nbIncentives = data.nbIncentive ? data.nbIncentive : 0;
        this.nbValidatedGiftcard = data.nbGiftcard ? data.nbGiftcard : 0;
        this.listing.loading = false;
      });
    },
    copy() {
      const defaultSeparator = "\t";
      const headers =
        this.listing.headers
          .map((header) => header.text)
          .filter((v) => v != "")
          .join(defaultSeparator) + "\n";

      const rows = this.listing.items
        .map((item) => {
          return `${item.agency}${defaultSeparator}${
            item.agent
          }${defaultSeparator}${item.bookingId}${defaultSeparator}${
            item.hotel
          }${defaultSeparator}${item.status || "-"}${defaultSeparator}${
            item.amount
          }\n`;
        })
        .join("");

      clipboard.copyTextToClipboard(headers + rows);
    },
  },
};
</script>
