<template>
  <widget-container
      :url="url"
      :bus="bus"
      :dark="false"
      :alwaysrender="true"
      headlineclass
      cardclass="ma-0 pa-1"
      color="transparent"
      @ready="setData"
  >
    <template slot="content" v-if="data && table.headers.length > 0">

      <v-simple-table class="pa-0 ma-0 tbl">
        <template #default>
          <thead class="">
          <tr class="">
            <th class="default-cell sticky">
              <div>Desti</div>
            </th>
            <th
                v-for="(header, index) in table.headers"
                :key="index"
                class="default-cell sticky"
            >
              <div class="text-center">{{ header.text }}</div>
            </th>
            <th class="default-cell body-2 font-weight-medium sticky">
              <div class="text-center">Total</div>
            </th>
            <th class="text-center cell-icon sticky">
              <v-icon small @click="copy" class="pl-2"
              >mdi-clipboard-outline
              </v-icon>
            </th>
          </tr>

          <tr class="subrow">
            <th class="subrow-cell default-cell"></th>
            <th
                v-for="(header, index) in table.headers"
                :key="index"
                class="default-cell"
            >
              <div class="text-right subrow-cell-content sub-cell">CA</div>
              <div class="text-right subrow-cell-content sub-cell">N1</div>
              <div class="text-right subrow-cell-content sub-cell">%</div>
            </th>
            <th class="default-cell">
              <div class="text-right subrow-cell-content sub-cell">CA</div>
              <div class="text-right subrow-cell-content sub-cell">N1</div>
              <div class="text-right subrow-cell-content sub-cell">%</div>
            </th>
            <th class="cell-icon"></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, index) in table.items" :key="index">
            <td class="text-center subrow-cell default-cell">
              {{ item.destiName }}
            </td>
            <td
                class="subrow-cell default-cell"
                v-for="(header, index) in table.headers"
                :key="index"
            >
              <div
                  class="text-right subrow-cell-content sub-cell"
                  :class="
                    getCa(header.value, item.cells) == 0
                      ? 'grey--text lighten-3--text'
                      : ''
                  "
              >
                {{
                  getCa(header.value, item.cells)
                      | currency("EUR", {maximumFractionDigits: 0})
                }}
              </div>
              <div
                  class="text-right subrow-cell-content sub-cell"
                  :class="
                    getCa(header.value, item.cells, 'prevCa') == 0
                      ? 'grey--text lighten-3--text'
                      : ''
                  "
              >
                {{
                  getCa(header.value, item.cells, 'prevCa')
                      | currency("EUR", {maximumFractionDigits: 0})
                }}
              </div>
              <div
                  class="text-right subrow-cell-content sub-cell"
                  v-html="getPercentageChange(header.value, item.cells)"
              ></div>
            </td>
            <td class="subrow-cell default-cell">
              <div
                  class="text-right subrow-cell-content sub-cell body-2 font-weight-medium"
              >
                {{
                  getRowTotalCa(item.cells)
                      | currency("EUR", {maximumFractionDigits: 0})
                }}
              </div>
              <div
                  class="text-right subrow-cell-content sub-cell body-2 font-weight-medium"
              >
                {{
                  getRowTotalCa(item.cells, 'prevCa')
                      | currency("EUR", {maximumFractionDigits: 0})
                }}
              </div>
              <div
                  class="text-right subrow-cell-content sub-cell body-2 font-weight-medium"
                  v-html="getRowPercentageChange(item.cells)"
              >

              </div>

            </td>
            <td class="cell-icon"></td>
          </tr>
          </tbody>
          <tfoot>
          <tr>
            <td
                class="text-center body-2 font-weight-medium subrow-cell default-cell"
            >
              Total
            </td>
            <td
                class="body-2 font-weight-medium subrow-cell default-cell"
                v-for="(header, index) in table.headers"
                :key="index"
            >
              <div class="text-right subrow-cell-content sub-cell">
                {{
                  getCa(header.value, table.footer)
                      | currency("EUR", {maximumFractionDigits: 0})
                }}
              </div>
              <div class="text-right subrow-cell-content sub-cell">
                {{
                  getCa(header.value, table.footer, 'prevCa')
                      | currency("EUR", {maximumFractionDigits: 0})
                }}
              </div>
              <div
                  class="text-right subrow-cell-content sub-cell"
                  v-html="getPercentageChange(header.value, table.footer)"
              ></div>

            </td>
            <td class="body-2 font-weight-medium subrow-cell default-cell">
              <div
                  class="text-right subrow-cell-content sub-cell body-2 font-weight-medium"
              >
                {{
                  getTotalCa() | currency("EUR", {maximumFractionDigits: 0})
                }}
              </div>
              <div
                  class="text-right subrow-cell-content sub-cell body-2 font-weight-medium"
              >
                {{
                  getTotalCa('prevCa') | currency("EUR", {maximumFractionDigits: 0})
                }}
              </div>
              <div
                  class="text-right subrow-cell-content sub-cell body-2 font-weight-medium"
                  v-html="getPercentContent(getTotalCa(), getTotalCa('prevCa'))"
              >

              </div>

            </td>
            <td class="cell-icon"></td>
          </tr>
          </tfoot>
        </template>
      </v-simple-table>
    </template>
  </widget-container>
</template>

<script>
import WidgetContainer from "./WidgetContainer";
import clipboard from "../../../utils/clipboard";
import _ from "lodash";

export default {
  name: "WidgetActivitySiteDestiN1",
  components: {WidgetContainer},
  mixins: [],
  props: {
    bus: {
      type: Object,
      required: true,
    },
    url: {
      type: String,
      required: false,
      default: "/api/widget/activitysitedestiwithprevyear",
    },
  },
  data() {
    return {
      data: null,
      table: {
        loading: false,
        headers: [],
        items: [],
        footer: [],
      },
    };
  },
  created() {
    this.$nextTick(() => {
      this.bus.$on("search", (searchform) => {
        this.table.loading = true;
        this.$emit("loading", true);
      });
    });
  },
  destroyed() {
    this.bus.$off("search");
  },
  watch: {},
  computed: {},
  methods: {
    setData(data) {
      this.data = data.items;
      this.buildHeaders();
      this.buildRows();
      this.buildFooter();
      this.table.loading = false;
      this.$emit("loading", false);
    },
    buildHeaders() {
      this.table.headers = this.data
      .map((item) => {
        return {
          text: item.siteName,
          value: item.siteId,
          class: "text-center",
          icon: "",
        };
      })
      .reduce((acc, curr) => {
        return acc.map((a) => a.value).indexOf(curr.value) < 0
            ? acc.concat(curr)
            : acc;
      }, [])
      .sort((a, b) => b.siteId - a.siteId);
    },
    buildRows() {
      const grouped = _.groupBy(this.data, "destinationId");
      const items = Object.keys(grouped)
      .map((key) => {
        const destiName = grouped[key][0].destiName;
        return {
          destiName: destiName,
          cells: grouped[key],
        };
      })
      .sort((a, b) => a.destiName.localeCompare(b.destiName));

      this.table.items = items;
    },
    buildFooter() {
      const grouped = _.groupBy(this.data, "siteId");
      const footer = Object.keys(grouped).map((key) => {
        return grouped[key].reduce((acc, curr) => {
          return {
            siteId: curr.siteId,
            bookingSize: curr.bookingSize + (acc ? acc.bookingSize : 0),
            ca: curr.ca + (acc ? acc.ca : 0),
            prevCa: curr.prevCa + (acc ? acc.prevCa : 0)
          };
        }, null);
      });

      this.table.footer = footer;
    },
    getCa(siteId, items, property = "ca") {
      return this.getValue(siteId, items, property);
    },
    getSize(siteId, items) {
      return this.getValue(siteId, items, "bookingSize");
    },
    getValue(siteId, items, propertyName) {
      const index = items.map((i) => i.siteId).indexOf(siteId);
      const item = items[index];
      if (item && item[propertyName]) return item[propertyName];
      else return 0;
    },
    getPercentage(siteId, items) {
      const ca = this.getCa(siteId, items);
      const total = this.getRowTotalCa(items);

      return this.computePercentage(ca, total);
    },
    getPercentageChange(siteId, items) {
      const ca = this.getCa(siteId, items) || 0;
      const prevCa = this.getCa(siteId, items, 'prevCa') || 0;


      return this.getPercentContent(ca, prevCa)
    },
    getPercentageChangeValue(siteId, items){
      const ca = this.getCa(siteId, items) || 0;
      const prevCa = this.getCa(siteId, items, 'prevCa') || 0;

      return this.getPercentContentRaw(ca, prevCa)
    },
    getPercentContent(a, b){
      if(a==0 && b == 0){
        return `<span class="grey--text lighten-3--text"> -</span>`
      }
      else if (a == 0) {
        return `<span class="red--text"> -∞</span>`
      } else if (b == 0) {
        return `<span class="green--text"> +∞</span>`
      } else {
        const value = this.getPercentDiff(a, b)
        const symbol = value > 0 ? '+':'';
        const clazz = value > 0 ? 'green--text' : 'red--text'

        return `<span class="${clazz}">${symbol}${this.$options.filters.decimal(value, {maximumFractionDigits: 0})}%</span>`
      }
    },
    getPercentContentRaw(a, b){
      if(a==0 && b == 0){
        return '-'
      }
      else if (a == 0) {
        return `∞`
      } else if (b == 0) {
        return `∞`
      } else {
        const value = this.getPercentDiff(a, b)

        return `${this.$options.filters.decimal(value, {maximumFractionDigits: 0})}`
      }
    },
    getPercentDiff(a, b) {
      return ((a - b) / (b || 1)) * 100
    },
    getRowTotalSize(items) {
      return this.getRowTotalValue(items, "bookingSize");
    },
    getRowTotalCa(items, property = 'ca') {
      return this.getRowTotalValue(items, property);
    },
    getRowPercentageChange(items){
      const ca = this.getRowTotalCa(items) || 0;
      const prevCa = this.getRowTotalCa(items, 'prevCa') || 0;


      return this.getPercentContent(ca, prevCa)
    },
    getRowPercentageChangeValue(items){
      const ca = this.getRowTotalCa(items) || 0;
      const prevCa = this.getRowTotalCa(items, 'prevCa') || 0;

      return this.getPercentContentRaw(ca, prevCa)
    },
    getRowTotalValue(items, property) {
      let defaultObj = {};
      defaultObj[property] = 0;
      return items.reduce((acc, curr) => {
        acc[property] = acc[property] + curr[property];
        return acc;
      }, defaultObj)[property];
    },
    getColTotalValue(property) {
      return this.table.items.reduce((prev, acc) => {
        return prev + acc.cells.reduce((a, b) => a + b[property], 0);
      }, 0);
    },
    getTotalSize() {
      return this.getTotalValue("bookingSize");
    },
    getTotalCa(property='ca') {
      return this.getTotalValue(property);
    },
    getTotalValue(property) {
      return this.table.items
      .map((i) => i.cells.map((c) => c[property]))
      .reduce((acc, curr) => acc.concat(curr), [])
      .reduce((a, b) => a + b);
    },
    computePercentage(ca, total) {
      return (ca * 100) / (total || 1);
    },
    copy() {
      const defaultSeparator = "\t";
      const decimalOpts = {maximumFractionDigits: 0, useGrouping: false};

      const header = this.table.headers
      .map((header) => {
        return header.text;
      })
      .concat("Total")
      .join(defaultSeparator + defaultSeparator + defaultSeparator);

      const subHeader = [...Array(this.table.headers.length + 1).keys()]
      .map(() => {
        return `CA${defaultSeparator}N-1${defaultSeparator}%`;
      })

      .join(defaultSeparator);

      const itemRows = this.table.items.map((item) => {
        return [
          item.cells[0].destiName,
          ...this.table.headers.map((header) => {
            return `${this.$options.filters.decimal(
                this.getCa(header.value, item.cells),
                decimalOpts
            )}${defaultSeparator}${this.$options.filters.decimal(
                this.getCa(header.value, item.cells, 'prevCa'),
                decimalOpts
            )}${defaultSeparator}${this.$options.filters.decimal(
                this.getPercentageChangeValue(header.value, item.cells),
                decimalOpts
            )}`;
          }),
          this.getRowTotalCa(item.cells),
          this.getRowTotalCa(item.cells, 'prevCa'),
          this.getRowPercentageChangeValue(item.cells)
        ].join(defaultSeparator);
      });

      const rowTotal = this.table.headers
      .map((header) => {
        return `${this.$options.filters.decimal(
            this.getCa(header.value, this.table.footer),
            decimalOpts
        )}${defaultSeparator}${this.$options.filters.decimal(
            this.getCa(header.value, this.table.footer, 'prevCa'),
            decimalOpts
        )}${defaultSeparator}${this.getPercentContentRaw(this.getCa(header.value, this.table.footer), this.getCa(header.value, this.table.footer, 'prevCa'))}`;
      })
      .concat([this.getTotalCa(), this.getTotalCa('prevCa'), this.getPercentContentRaw(this.getTotalCa(), this.getTotalCa('prevCa'))])
      .join(defaultSeparator);

      const rows = _.flatten([
        `Destination${defaultSeparator}${header}`,
        `${defaultSeparator}${subHeader}`,
        itemRows,
        `Total${defaultSeparator}${rowTotal}`,
      ]);

      clipboard.copyTextToClipboard(rows.join("\n"));
    },
  },
};
</script>

<style scoped>
>>> .v-data-table__wrapper table {
  position: relative;
}
>>> .v-data-table__wrapper .sticky {
  position: sticky;
  top:0;
}
.subrow {
  height: 25px;
}

.subrow-cell {
  padding: 0 !important;
  margin: 0;
}

.subrow-cell-content {
  width: 32%;
  float: left;
}

.default-cell {
  width: 200px;
  min-width: 200px;
  border-left: 1px solid #d1d1d1;
  padding-right: 10px !important;
}

.cell-icon {
  width: 20px;
  border-left: 1px solid #d1d1d1;
}

>>> .v-table__overflow {
  width: auto;
  min-width: 0;
  display: table;
  margin: auto;
}
</style>
