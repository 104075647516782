<template>
	<v-card width="100%">
		<v-card-title>
			<v-row dense  >
				<v-col cols="12">
					<h3 class="mb-0 headline">
						<v-row dense  >
							<v-col cols="9">
								{{carrentalprice.name}}
							</v-col>

							<v-col class="text-right" cols="3" >{{totalPrice}}
								{{$t(carrentalprice.pricing.prices.currency.code+'-symbol')}}
								<small v-if="carrentSelected">
									(
									<price-diff v-if="carrentSelected"
															:cssdisplay="'inline'"
															:show-baseprice="false"
															:new-price="totalPrice"
															:base-price="carrentSelected.price"
									></price-diff>
									)
								</small>
							</v-col>
						</v-row>
						<v-row dense>
							<v-col cols="12" class="caption">
								{{carrentalprice.category}}
							</v-col>
						</v-row>
					</h3>
				</v-col>
				<v-col cols="12">
					<availability v-model="carrentalprice.pricing.availability" kind="Stock"></availability>
					<v-chip small>{{$t("vehicleType."+carrentalprice.vehicleType)}}</v-chip>
					<v-chip small>Min {{carrentalprice.minPassenger}}
						<v-icon right>mdi-account-multiple</v-icon>
					</v-chip>
					<v-chip small>Max {{carrentalprice.maxPassenger}}
						<v-icon right>mdi-account-multiple</v-icon>
					</v-chip>
				</v-col>
			</v-row>

		</v-card-title>
		<v-card-text>

			<v-row dense v-for="(option, index) in carrentalprice.options" :key="index"   class="pb-2">
				<v-col cols="9" class="font-weight-medium">
					<v-checkbox
						:label="option.name"
						v-model="option.checked"
					></v-checkbox>
				</v-col>
				<v-col class="text-right" cols="3" >
					{{option.pricing.prices.price}}
					{{$t(option.pricing.prices.currency.code+'-symbol')}}<br/>
				</v-col>
			</v-row>
			<v-col cols="9" class="font-weight-medium details" @click="showdetails = !showdetails">
				- Plus de Détails
			</v-col>

		</v-card-text>

		<v-slide-y-transition>
			<v-card-text v-show="showdetails">
				<span v-html="carrentalprice.description"></span>
				<span v-html="carrentalprice.specificConditions"></span>
			</v-card-text>
		</v-slide-y-transition>

		<v-card-actions>
			<v-spacer></v-spacer>
			<v-btn v-if="!carrentSelected" @click="add" :loading="loading">{{$t('add')}}</v-btn>
			<v-btn v-else @click="editdialog = !editdialog" :loading="loading">{{$t('edit')}}</v-btn>
		</v-card-actions>


		<v-dialog v-if="carrentSelected" v-model="editdialog" :max-width="1200"  persistent>
			<v-card>
				<v-toolbar dark color="primary" dense>
					<v-toolbar-title>Edition transfert</v-toolbar-title>
					<v-spacer></v-spacer>
					<v-btn icon dark @click.native="editdialog = false">
						<v-icon>close</v-icon>
					</v-btn>
				</v-toolbar>
				<v-card-title>
					<v-row dense  >
						<v-col cols="6" class="headline">
							Location actuel
						</v-col>
						<v-col cols="6" class="headline">
							Location selectionnée
						</v-col>
					</v-row>
				</v-card-title>
				<v-card-text>
					<v-row dense  >
						<v-col cols="6" class="pa-1">
							<v-row dense   class="pb-2">
								<v-col cols="11" class="font-weight-medium">
									{{carrentSelected.fromName}}

									<small>
										<br/>
										<v-icon size="18">mdi-arrow-top-right</v-icon>
										{{_parseAndFormatLocalDateTime(carrentSelected.start)}}
										<br/>
										<v-icon size="18">mdi-arrow-bottom-left</v-icon>
										{{_parseAndFormatLocalDateTime(carrentSelected.stop)}}
									</small>
								</v-col>
								<v-col class="text-right" cols="1" >
									{{carrentSelected.price}}
									{{$t(carrentSelected.currency+'-symbol')}}<br/>
								</v-col>
							</v-row>
						</v-col>
						<v-col cols="6" class="pa-1">
							<v-row dense   class="pb-2">
								<v-col cols="11" class="font-weight-medium">
									{{ctx.from.name}}
									<small>
										<br/>
										<v-icon size="18">mdi-arrow-top-right</v-icon>
										{{_parseAndFormatDate(ctx.start, datePatternConfig.serverLocalDate, datePatternConfig.fr.full)}}
										<br/>
										<v-icon size="18">mdi-arrow-bottom-left</v-icon>
										{{_parseAndFormatDate(ctx.stop, datePatternConfig.serverLocalDate, datePatternConfig.fr.full)}}
									</small>
								</v-col>
								<v-col class="text-right" cols="1" >
									{{totalPrice}}
									{{$t(carrentalprice.pricing.prices.currency.code+'-symbol')}}<br/>
								</v-col>
							</v-row>
						</v-col>
						<v-col cols="6" class="pa-1">
							<v-row dense  >

								<v-col class="mt-2 font-weight-medium" cols="11">
									<v-divider></v-divider>
									Total
								</v-col>
								<v-col class="mt-2 text-right font-weight-medium" cols="1">
									<v-divider></v-divider>
									{{carrentSelected.price}}
									{{$t(carrentSelected.currency+'-symbol')}}
								</v-col>
							</v-row>
						</v-col>
						<v-col cols="6" class="pa-1">
							<v-row dense  >
								<v-col class="mt-2 font-weight-medium" cols="3">
									<v-divider></v-divider>
									Total
								</v-col>
								<v-col class="mt-2 text-right font-weight-medium" cols="9">
									<v-divider></v-divider>

									{{totalPrice}}
									{{$t(carrentalprice.pricing.prices.currency.code+'-symbol')}}

									(
									<price-diff
										:cssdisplay="'inline'"
										:show-baseprice="false"
										:new-price="totalPrice"
										:base-price="carrentSelected.price"
									></price-diff>
									)

								</v-col>
							</v-row>
						</v-col>
					</v-row>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="primary" :loading="loading" @click="edit">{{$t('add')}}</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-card>
</template>

<script>
	import Availability from "@/components/commons/Availability";
	import RenderIconMixin from "@/components/mixins/RenderIconMixin";
	import {EventBus} from "@/components/commons/event-bus";
	import PriceDiff from "@/components/commons/PriceDiff";
	import {post} from "@/utils/api"

	export default {
		name: "CarrentalPrice",
		components: {PriceDiff, Availability},
		props: {
			bookingid: {
				type: Number,
				required: true
			},
			carrentalprice: {
				type: Object,
				required: true
			},
			carrentSelected: {
				type: Object,
				required: false
			},
			ctx: {
				type: Object,
				required: true
			}
		},
		mixins: [RenderIconMixin],
		data: () => ({
			loading: false,
			showdetails: false,
			editdialog: false,
			totalPrice: 0
		}),
		created() {
			this.setTotalPrice()
		},
		watch: {
			carrentalprice: {
				deep: true, handler() {
					this.setTotalPrice()
				}
			},
		},
		computed: {},
		methods: {
			setTotalPrice() {
				const optPrices = this.carrentalprice.options.map(opt => {
					if (opt.payOnSite || !opt.checked) return 0
					else return opt.pricing.prices.price
				})

				const totalOptPrices = optPrices.length>0 ?optPrices.reduce((a, b) => a + b) : 0

				const rentPrice = this.carrentalprice.pricing.prices.price

				this.totalPrice = rentPrice + totalOptPrices
			},
			add() {

				this.loading = true

				post(`/api/prestation/add/carrental2?bookingId=${this.bookingid}&hash=${this.carrentalprice.hash}`, this.ctx)
					.badRequest(err => {
						EventBus.$emit('toaster-msg', JSON.parse(err.null).errors.join(','))
					})
					.json(() => {
						EventBus.$emit('toaster-msg', this.$t("carrentaladded"))
						EventBus.$emit('reloadbooking', {})
						EventBus.$emit('prestation-added', {})
					})
					.then(() => {
						this.loading = false
					})
					.catch(() => {
						this.loading = false
					})

			},
			edit() {
				this.loading = true
				const ctx = this.buildCtx()

				post(`/api/prestation/edit/carrental?bookingId=${this.bookingid}&prestationGroupId=${this.carrentSelected.prestationGroupId}`, ctx)
					.badRequest(err => {
						EventBus.$emit('toaster-msg', JSON.parse(err.null).errors.join(','))
					})
					.json(() => {
						EventBus.$emit('carrental-edited', {})
						setTimeout(() => {
								EventBus.$emit('reloadbooking', {})
							}, 500
						)
					})
					.then(() => {
						this.loading = false
					})
					.catch(() => {
						this.loading = false
					})
			},
			buildCtx() {
				return {
					ctx: {
						name: "",
						startDate: this._formatDate(this._parseDate(this.carrentalprice.start),this.datePatternConfig.serverLocalDateTime),
						stopDate: this._formatDate(this._parseDate(this.carrentalprice.stop),this.datePatternConfig.serverLocalDateTime),
						rent: {
							...this.carrentalprice,
							...this.ctx.priceClassTypeId
						},
						to: this.ctx.to,
						from: this.ctx.from
					},
					guests: this.ctx.guests,
				}
			}
		}
	}
</script>

<style scoped>

	.details {
		cursor: pointer;
	}
</style>
