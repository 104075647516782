<template>
	<v-row dense class="content-pdf " v-if="prestationsByGuest.length > 0">
		<v-col class="relative" cols="12">
			<v-row class="ba-always" dense v-for="(prestationByGuest, index) in prestationsByGuest" :key="index">
				<v-col cols="6">
					<v-row dense>
						<v-col cols="6" class="grey--text text--darken-1">{{ $t('document.invoice.place') }}:</v-col>
						<v-col cols="6">{{ (_nowFormatted("dd/MM/yyyy")) }}</v-col>
						<v-col cols="6" class="grey--text text--darken-1">{{ $t('document.invoice.bookingRef') }}:</v-col>
						<v-col cols="6">{{ bookingid }}</v-col>

					</v-row>
				</v-col>
				<v-col cols="6"><span class="grey--text text--darken-1">Passager:</span> {{ prestationByGuest.guest }}</v-col>


				<v-col cols="12" class="px-2">
					<v-row dense class="tile mt-2">
						<v-col cols="12" class="head display-1 my-2 "> Prestation(s) </v-col>
						<v-col cols="12" class="content body-2  pa-2" v-for="(prestation, idx) in prestationByGuest.prestations"
							:key="idx">
							<v-row dense class="" v-if="prestation.type == prestationType.RoomPrestation">
								<v-col cols="9" class="font-weight-bold body-2 font-weight-medium ">
									{{ prestation.prestations[0].hotelName }} ({{ prestation.prestations[0].data.destination.name
									}})</v-col>
								<v-col cols="3" class="text-right body-2 font-weight-medium "> {{ prestation.price |
		currency('EUR') }}</v-col>
								<v-col cols="12">
									{{ prestation.prestations[0].data.room.customerName }} en
									{{ prestation.prestations[0].data.meal.customerName }}<br />
									Séjour du
									{{ _parseAndFormatDate(prestation.prestations[0].start, datePatternConfig.serverLocalDateTime,
		_datePattern.localDate) }}
									au {{ _parseAndFormatDate(prestation.prestations[0].stop, datePatternConfig.serverLocalDateTime,
		_datePattern.localDate) }}

								</v-col>
							</v-row>

							<v-row dense class=""
								v-if="prestation.type == prestationType.TransferPrestation || prestation.type == prestationType.CarrentalPrestation || prestation.type == prestationType.InsurancePrestation || prestation.type == prestationType.UntypedPrestation">
								<v-col cols="9" class="font-weight-bold body-2 font-weight-medium ">
									{{ prestation.prestations[0].data.customerName }}</v-col>
								<v-col cols="3" class="text-right body-2 font-weight-medium "> {{ prestation.price |
		currency('EUR') }}</v-col>
							</v-row>

							<v-row dense class="" v-if="prestation.type == prestationType.FlightPrestation">
								<v-col cols="9" class="font-weight-bold body-2 font-weight-medium ">Vols: <br /></v-col>
								<v-col cols="3" class="text-right body-2 font-weight-medium "> {{ prestation.price |
		currency('EUR') }}</v-col>

								<template v-for="(p, pIndex) in prestation.prestations">
									<v-col cols="12">Compagnie: {{ p.route.segments[0].validatingAirlinename
										}} • Dossier: {{ p.pnr }}</v-col>
									<v-col cols="12" class="pl-10" v-for="(s, sIndex) in p.route.segments">
										{{ s.marketingAirlinecode }}{{ s.flightNumber }} •
										{{ _parseAndFormatDate(s.start, datePatternConfig.serverLocalDateTime,
		_datePattern.localDateTime) }}
										•
										{{ s.fromName }} ({{ s.fromCode }}) -> {{ s.toName }} ({{ s.toCode }})
									</v-col>
								</template>
							</v-row>
						</v-col>
						<v-col cols="12" class="font-weight-medium body-2 font-weight-medium  pa-3 grey lighten-1">
							<v-row dense>
								<v-col cols="9">{{ $t('document.invoice.total') }}</v-col>
								<v-col cols="3" class="text-right body-2 font-weight-medium "> {{
		buildTotal(prestationByGuest.prestations) | currency('EUR') }}</v-col>
							</v-row>
						</v-col>
					</v-row>

				</v-col>


				<v-col cols="12">
					<v-row dense class="tile mt-2">
						<v-col cols="12" class="head text-h6 ma-2 ">{{ $t('document.invoice.payments') }}</v-col>

						<v-col cols="12" class="content body-2  pa-2 ma-1">
							<v-row dense>
								<v-col cols="9">Payé</v-col>
								<v-col cols="3" class="text-right body-2 font-weight-medium "> {{
		buildTotal(prestationByGuest.prestations) | currency('EUR') }}</v-col>
							</v-row>
						</v-col>
					</v-row>
				</v-col>
			</v-row>
		</v-col>

		<span id="loaded"></span>
	</v-row>
</template>

<script>
import { get, post } from "@/utils/api"

export default {
	name: "Invoice",
	mixins: [],
	props: ['bookingid'],
	data() {
		return {
			prestationsByGuest: [],

			invoice: null,
			prestationType: {
				RoomPrestation: 'room',
				TransferPrestation: 'transfer',
				TransferSegmentPrestation: 'transfer',
				CarrentalPrestation: 'carrental',
				FlightPrestation: 'flight',
				UntypedPrestation: 'untyped',
				InsurancePrestation: 'insurance',
			}
		}
	},
	created() {
		this.init()
	},
	watch: {},
	computed: {},
	methods: {
		async init() {

			const guestIds = typeof this.$route.query.guestId == 'string' ? [this.$route.query.guestId] : this.$route.query.guestId

			this.prestationsByGuest = await Promise.all(guestIds.map(async (guestId) => {
				return await this.getPrestationData(guestId)
			}))

		},
		async getPrestationData(guestId) {
			const url = `/api/document/attestation/${this.bookingid}/guest/${guestId}`
			return get(url)
				.badRequest(err => {
					console.error(err)
				}).json((data) => {
					return data;
				})
		},
		buildTotal(prestations) {
			return prestations.map(p => {
				return p.type == "insurance" && p.prestations[0].euroPrice == 0 ? 0 : p.price
			}).reduce((p, c) => p + c, 0)
		}

	}
}
</script>


<style scoped src="@/assets/pdf/css/default.less" lang="less"></style>
<style scoped lang="less">
.tile {
	border: 1px solid black;

	.head {
		border-bottom: 1px dotted black;
	}
}

.content-pdf {
	height: 100.5%; //hack to avoid grey background here
}

.total {}
</style>
