<template>
	<div class="editor">
		<editor
			v-if="show"
			ref="editor"
			api-key="no-api-key"
			v-model="content"
			:init="options"
		></editor>
	</div>
</template>

<script>
	import "tinymce/tinymce";
	import "tinymce/plugins/lists";
	import "tinymce/themes/silver";
	import "tinymce/icons/default";
	import "tinymce/skins/ui/oxide/skin.css";
	import Editor from '@tinymce/tinymce-vue'


	export default {
		name: "SmartEditor",
		components: {
			Editor
		},
		props: {
			value: {
				type: String, required: false,
			},
			height: {
				type: Number, required: false, default: 200
			}
		},
		mixins: [],
		data() {
			return {
				show: false,
				content: '',
				options: {
					height: this.height,
					plugins: 'lists',
					toolbar: 'undo redo | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat',
					menubar: false,
					statusbar: false
				}
			}
		},
		mounted(){
			this.$nextTick(() => {
				this.show = true
			})
		},	
		watch: {
			value:{
				immediate: true, handler(value){
					this.content = this.value
				}
			},
			content: {
				handler: "update"
			}
		},
		computed: {},
		methods: {
			update(content) {
				this.$emit('input', content)
			}
		}
	}
</script>

<style scoped>
</style>
