<template>
	<widget-container
		:url="url"
		:bus="bus"
		color="transparent"
		cardclass="pa-1 "
		@ready="setData"
	>
		<template slot="content" v-if="data">
			<div>{{ $tc('booking', (data.size + (data.sizeOption || 0)), {size: data.size})}}</div>
			<div class="body-2">
				{{$tc('nights', data.nights, {size:data.nights})}}.
				Moy. {{data.average | currency('EUR')}}
			</div>

      <div class="body-1" v-if="data.sizeOption">Dont {{$tc('optionsize', data.sizeOption, {size:data.sizeOption})}}</div>
		</template>
		<template slot="default">
			Aucun dossiers
		</template>
	</widget-container>
</template>

<script>
	import WidgetContainer from "@/components/commons/widgets/WidgetContainer";

	export default {
		name: "WidgetBookingSize",
		components: {WidgetContainer},
		mixins: [],
		props: {
			bus: {
				type: Object,
				required: true
			},
			url: {
				type: String,
				required: false,
				default: '/api/widget/bookingsize'
			}
		},
		data() {
			return {
				search: null,
				data: null
			}
		},
		created() {
		},
		watch: {},
		computed: {},
		methods: {
			setData(data) {
				this.data = data;
			}
		}
	}
</script>

<style scoped>

</style>
