<template>
  <layout :drawer="true" :drawerShow="_isDesktop()" :drawerWidth="300">
    <template slot="drawer">
      <v-form ref="searchform" @submit.prevent="loadData">
        <v-row no-gutters class="pt-2 pr-1">
          <v-col cols="12" class="mb-2">
            <v-subheader light class="pa-0 mb-0 mx-0 form-subheader"
              >Dates de réservation</v-subheader
            >
            <v-divider></v-divider>
          </v-col>
          <v-col class="pr-1" cols="6">
            <smart-picker
              v-model="searchform.bookingDateStart"
              :stopDate="searchform.bookingDateStop"
              :inputOpts="{
                label: $t('begin'),
                clearable: true,
                placeholder: ' ',
              }"
            ></smart-picker>
          </v-col>
          <v-col cols="6" class="pr-1">
            <smart-picker
              v-model="searchform.bookingDateStop"
              :startDate="searchform.bookingDateStart"
              :inputOpts="{
                label: $t('end'),
                clearable: true,
                placeholder: ' ',
              }"
            ></smart-picker>
          </v-col>
          <v-col cols="12" class="mt-5 mb-2">
            <v-subheader light class="pa-0 mb-0 mx-0 form-subheader"
              >Statut</v-subheader
            >
            <v-divider></v-divider>
          </v-col>
          <v-col cols="12" class="pl-0">
            <v-checkbox
              v-for="(bs, index) in optionsStatus"
              :key="'bs-' + index"
              v-model="searchform.optionsStatus"
              :label="bs.label"
              :value="bs.value"
              :class="'pt-1 ' + (index == 0 ? 'mt-2' : 'mt-0')"
              height="1"
            >
            </v-checkbox>
          </v-col>
        </v-row>
        <smart-btn
          :loading="listing.loading"
          class="mt-3"
          type="submit"
          block
          primary
          >Rechercher</smart-btn
        >
        <smart-btn block tertiary @click.native="clearSearchform()"
          >Réinitialiser la recherche</smart-btn
        >
      </v-form>
    </template>
    <template slot="content">
      <v-container fluid>
        <v-data-table
          :headers="listing.headers"
          :items="listing.items"
          :loading="listing.loading"
          item-key="name"
          class="elevation-1 tbl"
          :footer-props="{
            'items-per-page-options': listing.rowsPerPageItems,
            'items-per-page-text': 'Lignes par page',
          }"
          no-data-text="Il n'y a pas d'option disponible"
          :sort-by="['bookingDate']"
          :sort-desc="true"
        >
          <template #item="{ item }">
            <tr
              @click.ctrl="goto(item.bookingId, true)"
              @click.meta="goto(item.bookingId, true)"
              @click.exact="goto(item.bookingId)"
            >
              <td
                class="text-left font-weight-regular"
                v-if="getHeader('siteName')"
              >
                <span class="caption grey--text text--darken-1">{{
                  item.bookingId
                }}</span>
                <site-label :sitename="item.siteName"></site-label>
              </td>
              <td class="text-left" v-if="getHeader('bookingDate')">
                <div class="font-weight-regular">
                  {{
                    _parseAndFormatDate(
                      item.bookingDate,
                      datePatternConfig.serverLocalDateTime,
                      "dd/MM/yy"
                    )
                  }}
                </div>
                <div class="caption grey--text text--darken-1">
                  {{
                    _parseAndFormatDate(
                      item.bookingDate,
                      datePatternConfig.serverLocalDateTime,
                      datePatternConfig.fr.time
                    )
                  }}
                </div>
              </td>
              <td class="text-left" v-if="getHeader('startDate')">
                <div class="font-weight-regular">
                  {{
                    _parseAndFormatDate(
                      item.startDate,
                      datePatternConfig.serverLocalDateTime,
                      "dd/MM/yy"
                    )
                  }}
                </div>

                <div class="caption grey--text text--darken-1">
                  {{
                    _parseAndFormatDate(
                      item.stopDate,
                      datePatternConfig.serverLocalDateTime,
                      "dd/MM/yy"
                    )
                  }}
                  • {{ item.duration }}n
                </div>
              </td>
              <td class="text-left" v-if="getHeader('customer')">
                <div
                  class="font-weight-regular"
                  v-if="item.customer && item.customer != ''"
                >
                  {{ item.customer }}
                </div>
                <span
                  class="caption grey--text text--darken-1"
                  v-for="(guest, i) in item.guests"
                  :key="i + '-guest'"
                  >{{
                    $tc(guest.ageClass + "-count-small", guest.size, {
                      size: guest.size,
                    })
                  }}
                </span>
                <span v-if="item.guestsName" class="caption grey--text"
                  >- {{ item.guestsName }}</span
                >
              </td>

              <td class="text-left" v-if="getHeader('customerPhone')">
                <div
                  class="font-weight-regular"
                  v-if="item.customerPhone && item.customerPhone != ''"
                >
                  {{ item.customerPhone }}
                </div>
              </td>

              <td
                class="text-left font-weight-regular"
                v-if="getHeader('hotelWithDesti')"
              >
                <div
                  v-if="item.hotelWithDesti"
                  v-html="renderHotelWithDesti(item.hotelWithDesti)"
                ></div>
              </td>
              <td
                class="text-left font-weight-regular"
                v-if="getHeader('airlineCodes')"
              >
                <span v-if="item.airlineCodes.length > 0">{{
                  item.airlineCodes.join(" ")
                }}</span>
                <span v-else>-</span>
              </td>
              <td
                class="text-left font-weight-regular"
                v-if="getHeader('transferName')"
              >
                <div class v-if="item.transferName">
                  <v-tooltip top>
                    <template #activator="{ on }">
                      <v-icon v-on="on" :size="16">mdi-bus-side</v-icon>
                    </template>
                    <span>{{ item.transferName }}</span>
                  </v-tooltip>
                </div>
                <div class v-if="item.carrentName">
                  <v-tooltip top>
                    <template #activator="{ on }">
                      <v-icon v-on="on" :size="15">mdi-car-side</v-icon>
                    </template>
                    <span>{{ item.carrentName }}</span>
                  </v-tooltip>
                </div>
                <div class v-if="!item.transferName && !item.carrentName">
                  -
                </div>
              </td>

              <td
                class="text-right body-2 font-weight-medium"
                v-if="getHeader('ca')"
              >
                <div>
                  <v-tooltip top v-if="item.withEasyBooking">
                    <template #activator="{ on }">
                      <v-icon small color="orange lighten-1" v-on="on"
                        >mdi-numeric-1-circle-outline</v-icon
                      >
                    </template>
                    Paiement à 1€
                  </v-tooltip>

                  <v-tooltip top v-if="item.creationCtx == 'payment'">
                    <template #activator="{ on }">
                      <v-icon small color="blue" v-on="on"
                        >mdi-cart-arrow-right</v-icon
                      >
                    </template>
                    Créé dans le tunnel de paiement
                  </v-tooltip>
                  <v-tooltip top v-if="item.creationCtx == 'cart'">
                    <template #activator="{ on }">
                      <v-icon small color="blue" v-on="on">mdi-cart</v-icon>
                    </template>
                    Créé dans le panier
                  </v-tooltip>
                  <v-tooltip top v-if="item.paymentFailed">
                    <template #activator="{ on }">
                      <v-icon small color="red" v-on="on"
                        >mdi-credit-card-off</v-icon
                      >
                    </template>
                    Dernier paiement en échec
                  </v-tooltip>
                  {{ item.ca | currency("EUR") }}
                </div>
                <div class="body-2 grey--text">
                  {{ item.purchase | currency("EUR") }}
                  <span class="caption ml-1 grey--text text--lighten-1">{{
                    item.markup | percent
                  }}</span>
                </div>
              </td>
              <td
                class="text-left"
                v-if="getHeader('bookingStatus')"
                style="min-width: 175px"
              >
                <availability v-model="item.bookingStatus" kind="BookingStatus">
                </availability>
                <availability
                  v-model="item.supplierStatus"
                  kind="SupplierStatus"
                  v-if="item.supplierStatus"
                />
                <div>
                  <v-tooltip top v-for="(tag, index) in item.tags" :key="index">
                    <template #activator="{ on }">
                      <v-chip
                        v-on="on"
                        small
                        label
                        class="pink white--text mx-1"
                      >
                        <v-icon left dark class="mr-0 pr-1">mdi-tag</v-icon>
                        {{ tag }}
                      </v-chip>
                    </template>
                    {{ tag }}
                  </v-tooltip>
                </div>
              </td>
              <td>
                <v-icon small v-if="item.locked">mdi-lock</v-icon>
                <template v-if="item.optionDate">
                  <v-tooltip top>
                    <template #activator="{ on }">
                      <v-icon v-on="on" small class="mr-1"
                        >mdi-clock-outline</v-icon
                      >
                    </template>
                    option jusqu'au {{ item.optionDate }}
                  </v-tooltip>
                </template>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-container>
    </template>
  </layout>
</template>
<script>
import { post } from "@/utils/api";
import { differenceInDays, parse } from "date-fns";
import { EventBus } from "../../components/commons/event-bus";
import Layout from "@/views/Layout";
import SiteLabel from "@/components/commons/SiteLabel";
import Availability from "@/components/commons/Availability";

export default {
  name: "Options",
  components: {
    Layout,
    Availability,
    SiteLabel,
  },
  data() {
    return {
      defaultSearchform: {
        bookingDateStart: this._formatDate(
          this._subDays(new Date(), 3), "dd/MM/yyyy"
        ),
        bookingDateStop: this._nowFormatted("dd/MM/yyyy"),
        optionsStatus: ["inprogress"],
      },
      searchform: null,
      optionsStatus: [
        { value: "inprogress", label: "En cours" },
        { value: "expired", label: "Expiré" },
      ],
      listing: {
        loading: false,
        rowsPerPageItems: [{ text: "Tous", value: -1 }, 50, 200],
        headers: [
          {
            text: "SITE",
            value: "siteName",
          },
          {
            text: "RÉSA",
            value: "bookingDate",
            align: "left",
          },
          {
            text: "SÉJOUR",
            value: "startDate",
            align: "left",
            width: "10%",
          },
          {
            text: "CLIENT",
            value: "customer",
            align: "left",
          },
          {
            text: "DESTINATION",
            value: "hotelWithDesti",
            align: "left",
            sortable: false,
          },
          {
            text: "VOLS",
            value: "airlineCodes",
            align: "left",
            sortable: false,
          },
          {
            text: "TRSF.",
            value: "transferName",
            align: "left",
            sortable: false,
          },
          {
            text: "MONTANT",
            value: "ca",
            align: "right",
            sortable: false,
          },
          {
            text: "STATUTS",
            value: "bookingStatus",
            align: "left",
            sortable: false,
          },
          {
            text: "",
            value: "locked",
            sortable: false,
          },
        ],
        items: [],
      },
    };
  },
  created() {
    this.initSearchform();
  },
  methods: {
    async loadData() {
      if (!this.listing.loading) {
        window.history.pushState(
          "",
          "",
          "?search=" + JSON.stringify(this.searchform)
        );
        this.listing.items = await this.getData();
        this.listing.loading = false;
      }
    },
    getData() {
      this.listing.loading = true;
      const url = "/api/booking/option/list";
      this.searchform.bookingDateStart == null
        ? (this.searchform.bookingDateStart = this._nowFormatted("dd/MM/yyyy"))
        : "";
      this.searchform.bookingDateStop == null
        ? (this.searchform.bookingDateStop = this._nowFormatted("dd/MM/yyyy"))
        : "";
      return post(url, this.searchform)
        .badRequest((err) => {
          EventBus.$emit("toaster-msg", err);
        })
        .json((data) => {
          return data;
        });
    },
    initSearchform() {
      const qs = new URLSearchParams(window.location.search);
      const search = (() => {
        try {
          return JSON.parse(qs.get("search"));
        } catch (e) {
          return null;
        }
      })();

      if (search) {
        this.searchform = search;
        this.loadData();
      } else this.clearSearchform();
    },
    clearSearchform() {
      this.searchform = {
        ...this.defaultSearchform,
      };
      this.loadData();
    },
    getHeader(value) {
      return this.listing.headers.find((x) => x.value === value) != null;
    },
    renderHotelWithDesti(hotelWithDesti) {
      return hotelWithDesti
        .map((hotel) => {
          return `<div class="text-xs-left font-weight-regular">${hotel[0]}: ${hotel[1]}</div>`;
        })
        .join("");
    },
    goto(bookingId, newTab) {
      if (newTab) {
        let route = this.$router.resolve({
          name: "Booking",
          params: { id: bookingId },
        });
        window.open(route.href, "_blank");
        return false;
      } else {
        this.$router.push({ name: "Booking", params: { id: bookingId } });
      }
    },
  },
  computed: {},
};
</script>
