<template>
  <prestation
    kind="prestationgroup"
    ref="prestationcpnt"
    :booking="booking"
    :prestation="prestation"
    :index="index"
    :loading="disablePanel"
    :renderfab="renderfab"
  >
    <div slot="header-sup">
      {{_parseAndFormatDate(prestation.start,datePatternConfig.serverLocalDateTime, datePatternConfig.fr.fullWithTime)}}
      ({{ _durationFormatted(prestation.start, prestation.stop, datePatternConfig.fr.time) }})
    </div>

    <div slot="header-title">
      <div class="subtitle-1 font-weight-medium">
        {{prestation.fromName}}
        <v-icon small>arrow_right_alt</v-icon>
        {{prestation.toName}}
      </div>
      <div class="subheading">
        <small>
          {{prestation.vehicleType.map(t => $t('vehicleType.'+t)).join(', ')}} -
          <availability
            v-model="prestation.availability"
            kind="Stock"
            :chip="false"
            class="caption"
          />
        </small>
      </div>
    </div>

    <div slot="header-status">
      <availability v-model="prestation.status" kind="BookingStatus" />
      <availability v-model="prestation.supplierStatus" kind="SupplierStatus" />
    </div>

    <div slot="header-price">
      <div
        class="headline font-weight-regular"
      >{{prestation.price}} {{$t(prestation.currency+"-symbol")}}</div>
    </div>

    <div slot="menu-top">
      <v-list-item @click="dialogs.edit = !dialogs.edit ">
        <v-list-item-title>
          <v-icon small>mdi-swap-horizontal</v-icon>
          {{$t("modify-transfer")}}
        </v-list-item-title>
      </v-list-item>
      <v-divider></v-divider>
    </div>
    <div slot="menu-bottom">
      <v-list-item @click="openEmailDialog">
        <v-list-item-title>{{$t("message-transfert")}}</v-list-item-title>
      </v-list-item>
      <v-list-item :disabled="prestation.supplierStatus !== 'ondemand'" @click="sendRevival">
        <v-list-item-title>{{$t("revival-dmd")}}</v-list-item-title>
      </v-list-item>
    </div>

    <template slot="tabs">
      <v-tab key="segments" ripple>
        <span>
          <v-icon small class="icon-segment">mdi-ray-start-end</v-icon>Segments
        </span>
      </v-tab>
      <v-tab-item key="segments" :transition="false" :reverse-transition="false">
        <v-card>
          <v-card-text>
            <transfer-segment :segments="prestation.segments"></transfer-segment>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </template>

    <div slot="content">
      <v-row v-if="prestation.oldPrestationGroup" class="align-center">
        <v-col cols="8" class="pa-1 elevation-1 grey-lighten-5" style="background:#FAFAFA;">
          <v-icon size="14">mdi-timer-sand</v-icon>
          Remplace la prestation
          {{prestation.oldPrestationGroup.fromName}}
          <v-icon small>arrow_right_alt</v-icon>
          {{prestation.oldPrestationGroup.toName}}.
          <availability v-model="prestation.oldPrestationGroup.status" kind="BookingStatus" />
          <availability
            v-model="prestation.oldPrestationGroup.supplierStatus"
            kind="SupplierStatus"
          />

          <b>{{prestation.oldPrestationGroup.price | currency(prestation.oldPrestationGroup.currency)}}</b>

          <v-icon size="14" @click="showOldPrestationGroup = !showOldPrestationGroup">mdi-eye</v-icon>
        </v-col>
      </v-row>

      <!-- ----------------------------- dialogs ----------------------------- -->
      <smart-dialog
        v-model="dialogs.edit "
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
        card-class="grey lighten-3"
        :title="$t('add-transfer')"
      >
        <template>
          <add-transfer
            :booking="booking"
            :transfer-selected="prestation"
            :lastPrestationDate="prestation.start"
            v-bind:sumPrestation="addPrestationRecap"
          ></add-transfer>
        </template>
        <v-btn
          dark
          icon
          absolute
          top
          right
          style="top:6px;right:70px;"
          @click="addPrestationRecap = !addPrestationRecap"
        >
          <v-icon>mdi-information-outline</v-icon>
        </v-btn>
      </smart-dialog>

      <smart-dialog title="Message Transfert" v-model="dialogs.email" :width="800">
        <mail-composer
          :subject="'Dossier réf '+booking.id"
          :tos="supplierEmails"
          :loading="mailLoading"
          @send="sendMsgSupplier"
          :message="messageSupplier"
        ></mail-composer>
      </smart-dialog>

      <smart-dialog
        v-if="prestation.oldPrestationGroup"
        v-model="showOldPrestationGroup"
        :width="900"
        :title="prestation.oldPrestationGroup.name"
      >
        <prestation-transfer
          :booking="booking"
          :prestation="prestation.oldPrestationGroup"
          :index="0"
          :renderfab="false"
        ></prestation-transfer>
      </smart-dialog>
    </div>
  </prestation>
</template>

<script>
import CollapsePanel from "@/components/commons/CollapsePanel";

import Prestation from "@/components/booking/Prestation";
import GuestsMixin from "@/components/mixins/GuestsMixin";
import Availability from "@/components/commons/Availability";
import BookingPrestaCancelfees from "@/components/commons/BookingPrestaCancelfees";
import AddTransfer from "@/components/booking/transfer/AddTransfer";
import GuestList from "@/components/commons/GuestList";
import { EventBus } from "@/components/commons/event-bus";
import PrestationMixin from "@/components/mixins/PrestationMixin";
import { get, post } from "@/utils/api";
import MailComposer from "../commons/MailComposer";
import BookingDrawerContent from "../commons/BookingDrawerContent";
import TransferSegment from "./transfer/TransferSegment";

export default {
  name: "PrestationTransfer",
  props: ["booking", "prestation", "index", "renderfab"],
  mixins: [PrestationMixin, GuestsMixin],
  components: {
    TransferSegment,
    BookingDrawerContent,
    MailComposer,
    AddTransfer,
    BookingPrestaCancelfees,
    Availability,
    Prestation,
    CollapsePanel,
    GuestList,
  },
  data() {
    return {
      addPrestationRecap: true,
      guests: this.prestation.guests,
      supplierEmails: [],
      dialogs: {
        edit: false,
        email: false,
      },
      mailLoading: false,
      showOldPrestationGroup: false,
    };
  },
  created() {
    EventBus.$on("transfer-edited", () => {
      this.dialogs.edit = false;
    });
  },
  beforeDestroy() {
    //EventBus.$off('transfer-edited');
  },
  computed: {
    messageSupplier() {
      const guests = this.guests
        .map((g) => `<li>${g.lastName} ${g.firstName}</li>`)
        .join("");
      return `
					Ref dossier: ${this.booking.id}<br/>
					Nom client: ${this.booking.customer.lastname} ${
        this.booking.customer.firstname
      }<br/>
					in: ${this._parseAndFormatDate(
            this.prestation.start,
            this._datePattern.localDateTime,
            this._prestationDatePattern(this.prestation.start)
          )}<br/>
					out: ${this._parseAndFormatDate(
            this.prestation.stop,
            this._datePattern.localDateTime,
            this._prestationDatePattern(this.prestation.stop)
          )}<br/>
					Passagers: <ul>${guests}</ul>
				`;
    },
  },
  methods: {
    remove() {
      this.deletePrestationGroup();
    },
    cancel() {
      this.cancelPrestationGroup();
    },
    openEmailDialog() {
      const suppliersMail = this.prestation.segments.map((s) => {
        const url = `/api/supplier/${s.supplierId}`;
        return get(url).json((r) => {
          return r && r.email ? r.email.split(",") : [];
        });
      });
      Promise.all(suppliersMail).then((r) => {
        this.supplierEmails = r.flat();
        this.dialogs.email = true;
      });
    },
    sendMsgSupplier(email) {
      this.mailLoading = true;
      const url = `/api/mail/sendSupplier/${this.booking.id}`;
      post(url, email)
        .json((r) => {
          EventBus.$emit("toaster-msg", "Email envoyé");
          EventBus.$emit("reloadbooking", {});
          return r;
        })
        .then(() => {
          this.mailLoading = false;
          this.dialogs.email = false;
        });
    },
    sendRevival() {
      this.$refs.prestationcpnt.sendRevival();
    },
  },
};
</script>

<style scoped>
.icon-segment {
  transform: rotate(90deg);
}
</style>
