<template>
  <v-form v-on:submit.prevent="send" ref="form" v-model="valid">
    <v-card-text>
      <v-row dense>
        <v-col cols="12">
          Il reste {{ smsLeft }} crédits SMS.
          <span v-if="smsLeftAfterSending != null">Après cet
            envoi il en restera {{ smsLeftAfterSending }} {{ leftNbSMS }}</span>
        </v-col>
        <v-col cols="12">
          <v-textarea
              solo
              v-model="sms.message"

              :rules="[...rules.nonEmpty(''), smsLeftAfterSending!=null ]"
              label="Message au clients"
              @blur="estimate"
          ></v-textarea>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="actions-btn">
      <v-spacer></v-spacer>
      <smart-btn primary type="submit" :disabled="!valid" :loading="isLoading">
        <v-icon small class="mr-1">send</v-icon>
        {{ $t("send") }}
      </smart-btn>
    </v-card-actions>
  </v-form>
</template>
<script>
import FormMixin from "../mixins/FormMixin";
import SmartEditor from "./SmartEditor";
import {get, post} from "@/utils/api";
import {EventBus} from "@/components/commons/event-bus";

export default {
  mixins: [FormMixin],
  props: {
    loading: {
      type: Boolean,
      required: true,
      default: false,
    },
    message: {
      type: String,
      required: false,
    },
    list: {
      type: Array,
      required: false,
    },
  },
  components: {
  },
  data() {
    return {
      smsLeft: null,
      smsLeftAfterSending: null,
      leftNbSMS:null,
      valid: false,
      isLoading: false,
      sms: null,
      smsFR: 4.5,
      smsRE: 13,
    };
  },
  created() {
    this.reset();
    this.countSmsLeft();
  },

  watch: {},
  methods: {
    estimate() {
      const smsList = this.list.map((booking) => {
        return {
          bookingId: booking.id,
          clientEmail: booking.email,
          siteId: booking.siteId,
          message: this.sms.message,
        };
      });

      post("/api/sms/estimate", smsList).json((r) => {
        this.smsLeftAfterSending = this.smsLeft - r
        this.leftNbSMS = `(soit ~${Math.round(this.smsLeftAfterSending/this.smsFR)} SMS vers la france)`
      });
    },

    send() {
      if (this.smsLeftAfterSending < 0) {
        EventBus.$emit("toaster-msg", "Envoi annulé: Manque de crédits SMS");
      } else {
        if (this.valid && this.$refs.form.validate()) {
          this.$emit("send", this.sms);
          this.reset();
        }
      }
    },
    reset() {
      this.smsLeftAfterSending = null;
      this.sms = {
        message: "",
      };
    },
    countSmsLeft() {
      get("/api/sms/left-credits").json((count) => {
        this.smsLeft = count
      });
    },
  },
};
</script>