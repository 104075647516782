<template>
  <v-data-table
    :headers="headers"
    :items="logs"
    :custom-sort="sortByDate"
    dense
    :options.sync="pagination"
    :footer-props="{'items-per-page-options':pagination.rowsPerPageItems, 'items-per-page-text':'Lignes par page'}"
    style="height:100%"
  >
    <template #body="{items}">
      <tbody>
        <tr v-for="(item, idx) in items" :key="idx" :class="{'multiple':item.bookings.length>0}">
          <td>{{item.id}}</td>
          <td>{{item.logDate}}</td>
          <td>{{item.account}}</td>
          <td>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span
                  v-bind="attrs"
                  v-on="on"
                  class="d-inline-block text-truncate"
                  style="max-width: 200px;"
                >{{item.label}}</span>
              </template>
              <span>{{item.label}}</span>
            </v-tooltip>
          </td>
          <td>{{item.logType}}</td>
          <td>{{item.debit}}</td>
          <td>{{item.credit}}</td>
          <td>{{item.exportDate}}</td>
          <td>{{item.paymentId}}</td>
          <td>{{item.supplierId}}</td>
        </tr>
      </tbody>
    </template>
  </v-data-table>
</template>
<script>
import { get, post } from "@/utils/api";
export default {
  name: "BookingAccountingLogs",
  mixins: [],
  props: {
    bookingId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      logs: [],
      headers: [
        { text: "#", value: "id", sortable: true },
        { text: "Date", value: "logDate", sortable: true },
        { text: "Compte", value: "account", sortable: true, class: "account" },
        { text: "Libellé", value: "label", sortable: true, class: "label" },
        { text: "Type", value: "logType", sortable: true },
        { text: "debit", value: "credit", sortable: true },
        { text: "credit", value: "debit", sortable: true },

        { text: "Date export", value: "exportDate" },
        { text: "Payment", value: "paymentId" },
        { text: "Fournisseur", value: "supplierId", sortable: true },
      ],
      pagination: {
        rowsPerPageItems: [{ text: "Tous", value: -1 }, 50, 200],
        page: 1,
        sortBy: [],
        sortDesc: [false],
      },
    };
  },
  created() {
    get("/api/booking/" + this.bookingId + "/accountinglogs").json((data) => {
      this.logs = data;
      this.logs.sort((a, b) => {
        return this._sortDates(
          a.logDate,
          b.logDate,
          this.datePatternConfig.fr.localDateTime
        );
      });
    });
  },
  methods: {
    sortByDate(items, index, isDesc) {
      items.sort((a, b) => {
        if (!isDesc[0]) {
          return this._sortDates(
            a.logDate,
            b.logDate,
            this.datePatternConfig.fr.localDateTime
          );
        } else {
          return this._sortDates(
            b.logDate,
            a.logDate,
            this.datePatternConfig.fr.localDateTime
          );
        }
      });
      return items;
    },
  },
};
</script>
<style scoped>
>>> .v-data-table__wrapper {
  height: 89% !important;
  max-height: 89% !important;
  overflow: auto;
}
tr.multiple {
  font-style: italic;
  background-color: #fff8e1;
}
>>> .account {
  min-width: 100px;
}
>>> .label {
  min-width: 230px;
}
</style>
