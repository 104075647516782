<template>
  <v-form id="cancelForm">
    <v-subheader>Liste des prestations qui seront annulées :</v-subheader>
    <v-row class="mx-3 my-4" v-for="(prestationGroup, index) in cancelForm" :key="index">
      <v-col cols="12" v-if="prestationGroup.kind == 'TransferPrestation'">
        <v-row align="center">
          <v-col cols="8">
            <v-icon small>{{displayIcon(prestationGroup)}}</v-icon>
            <b>{{prestationGroup.name}}</b>
          </v-col>
        </v-row>
        <v-row v-for="(prestation, idx) in prestationGroup.prestas" :key="idx" align="end">
          <v-col cols="6" class="pl-4 pb-2">{{prestation.name}}</v-col>
          <v-col>
            <v-row justify="space-between">
              <v-col cols="6">
                <v-text-field
                  v-model="cancelForm[index].prestas[idx].purchase"
                  hide-details
                  type="number"
                  :append-icon="iconForCurrency(prestation.purchaseCurrency)"
                  :label="'Prix d\'achat (' + cancelForm[index].prestas[idx].oldPurchase +')'"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="cancelForm[index].prestas[idx].fee.price"
                  hide-details
                  type="number"
                  :append-icon="iconForCurrency(prestation.currency)"
                  :label="'Frais d\'annulation ('+ cancelForm[index].prestas[idx].oldFee.price +')'"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" v-else>
        <v-row align="end">
          <v-col cols="6" class="pb-2">
            <v-icon small>{{displayIcon(prestationGroup)}}</v-icon>
            <b>{{prestationGroup.name}}</b>
          </v-col>
          <v-col cols="6">
            <v-row justify="space-between">
              <v-col>
                <v-text-field
                  v-for="(prestation, idx) in prestationGroup.prestas"
                  :key="idx"
                  hide-details
                  v-model="cancelForm[index].prestas[idx].purchase"
                  type="number"
                  :append-icon="iconForCurrency(prestation.purchaseCurrency)"
                  :label="'Prix d\'achat (' + cancelForm[index].prestas[idx].oldPurchase +')'"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  v-for="(prestation, idx) in prestationGroup.prestas"
                  :key="idx"
                  hide-details
                  v-model="cancelForm[index].prestas[idx].fee.price"
                  type="number"
                  :append-icon="iconForCurrency(prestation.currency)"
                  :label="'Frais d\'annulation ('+ cancelForm[index].prestas[idx].oldFee.price +')'"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row class="mt-5" justify="end">
      <v-col class="mr-2" cols="3">
        <v-text-field
          disabled
          v-model="cancelFormTotal"
          type="number"
          append-icon="mdi-currency-eur"
          label="Total des frais d'annulation"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row class="xs12">
      <v-card-actions class="actions-btn pr-3" style="width:100%">
        <v-spacer></v-spacer>
        <smart-btn
          :loading="isLoading"
          primary
          @click.native="cancelBookingAndPrestations()"
        >Annuler le dossier</smart-btn>
      </v-card-actions>
    </v-row>
  </v-form>
</template>

<script>
import _ from "lodash";
import { post } from "@/utils/api";
import { EventBus } from "@/components/commons/event-bus";

export default {
  name: "BookingCancelFees",
  props: {
    booking: { type: Object, required: true },
    covid: { type: Boolean, default: false },
  },
  mixins: {},
  components: {},
  created() {
    this.init();
  },
  data() {
    return {
      cancelForm: [],
      cancelFormTotal: 0,
      isLoading: false,
      noCancelFee: 0,
    };
  },
  computed: {},
  watch: {
    cancelForm: {
      deep: true,
      immediate: true,
      handler(newVal, oldVal) {
        this.cancelFormTotal = this.totalCancelFee()
          ? this.totalCancelFee()
          : 0;
      },
    },
  },
  methods: {
    init() {
      let cleanedPrestations = _.uniqBy(
        this.booking.prestations,
        "prestationGroupId"
      );

      this.cancelForm = cleanedPrestations.reduce((acc, prestation) => {
        if (prestation.status == "booked") {
          let obj = {};
          obj.name = prestation.data.name;
          obj.kind = prestation.kind;
          obj.prestationGroupId = prestation.prestationGroupId;
          obj.prestas = [];

          if (prestation.segments) {
            prestation.segments.map((segment) => {
              const fees = this.getCancelFees(segment);
              let objPresta = {};
              objPresta.id = segment.id;
              objPresta.name = segment.name;
              objPresta.oldPurchase = segment.purchase;
              objPresta.fee = fees;
              objPresta.oldFee = fees;
              objPresta.currency = segment.currency;
              objPresta.purchase =
                objPresta.fee.price != 0 ? segment.purchase : 0;
              objPresta.purchaseCurrency = segment.purchaseCurrency;
              obj.prestas.push(objPresta);
            });
          } else {
            const fees = this.getCancelFees(prestation);
            let objPresta = {};
            if (prestation.isLast) {
              objPresta.isLast = prestation.isLast;
            }
            objPresta.id = prestation.id;
            objPresta.name = null;
            objPresta.oldPurchase = prestation.purchase;
            objPresta.fee = fees;
            objPresta.oldFee = fees;
            objPresta.currency = prestation.currency;
            objPresta.purchase =
              objPresta.fee.price != 0 ? prestation.purchase : 0;
            objPresta.purchaseCurrency = prestation.purchaseCurrency;
            obj.prestas.push(objPresta);
          }
          return acc.concat(obj);
        } else return acc;
      }, []);
      this.cancelFormTotal = this.totalCancelFee().price;
    },
    getCancelFees(prestation) {
      let fee = null;
      const now = this._parseDate(this._formatDate(new Date(), "dd/MM/yyyy"));
      fee = prestation.cancelFees.fees.find((f) => {
        return (
          this._parseDate(f.start, "dd/MM/yyyy") < now &&
          this._parseDate(f.stop, "dd/MM/yyyy") > now
        );
      });
      if (
        !fee &&
        prestation.cancelFees.fees &&
        prestation.cancelFees.fees.length > 0
      ) {
        if (
          this._parseDate(prestation.cancelFees.fees[0].start, "dd/MM/yyyy") >=
          now
        ) {
          fee = prestation.cancelFees.fees[0];
        } else if (
          prestation.cancelFees.fees &&
          this._parseDate(
            prestation.cancelFees.fees[prestation.cancelFees.fees.length - 1]
              .stop,
            "dd/MM/yyyy"
          ) <= now
        ) {
          fee =
            prestation.cancelFees.fees[prestation.cancelFees.fees.length - 1];
        } else {
          fee = {
            price: 0,
            start: this._formatDate(new Date(), "dd/MM/yyyy"),
            stop: this._formatDate(new Date(), "dd/MM/yyyy"),
          };
        }
      } else {
        fee = {
          price: 0,
          start: this._formatDate(new Date(), "dd/MM/yyyy"),
          stop: this._formatDate(new Date(), "dd/MM/yyyy"),
        };
      }

      return fee;
    },
    totalCancelFee() {
      let flightPrestationsContainer = [];
      const allfees = this.cancelForm.map((cancels) => {
        if (cancels.kind == "FlightPrestation") {
          let cancelsCopy = cancels;

          if (
            flightPrestationsContainer.find(
              (elt) => elt.prestationGroupId === cancels.prestationGroupId
            )
          ) {
            return cancels.prestas.map((p) => (p.fee.price = 0));
          } else {
            flightPrestationsContainer.push(cancelsCopy);
            return cancels.prestas.map((p) => {
              if (p.fee) {
                return parseFloat(p.fee.price);
              } else {
                return 0;
              }
            });
          }
        } else {
          return cancels.prestas
            ? cancels.prestas.map((p) => parseFloat(p.fee.price))
            : [0];
        }
      });
      let result = 0;
      allfees.map((fees) => {
        fees.map((fee) => {
          result += fee;
        });
      });
      return result;
    },
    displayIcon(prestation) {
      if (prestation.kind == "RoomPrestation") {
        return "mdi-hotel";
      } else if (prestation.kind == "TransferPrestation") {
        return "mdi-bus";
      } else if (prestation.kind == "FlightPrestation") {
        return "mdi-airplane";
      } else if (prestation.kind == "CarrentalPrestation") {
        return "mdi-car";
      }
    },
    cancelBookingAndPrestations() {
      this.isLoading = true;

      const cancelFeesList = this.cancelForm.reduce(
        (accumulator, prestationsGroup) => {
          if (prestationsGroup.prestas) {
            const toCancel = prestationsGroup.prestas.map((prestation) => {
              return {
                id: prestation.id,
                prestationGroupId: prestationsGroup.prestationGroupId,
                fee: parseFloat(prestation.fee.price),
                purchase: prestation.purchase,
              };
            });
            return accumulator.concat(toCancel);
          } else return accumulator;
        },
        []
      );

      let cause = "";
      if (this.covid) {
        cause = "/covid";
      }

      const url = `/api/booking/cancel/${this.booking.id}${cause}`;
      return post(url, cancelFeesList)
        .badRequest((err) => {
          this.isLoading = false;
          EventBus.$emit("toaster-error", err.message);
        })
        .res((res) => {
          this.isLoading = false;
          EventBus.$emit("reloadbooking", {});
          this.$emit("clicked", true);
        });
      //
    },
    iconForCurrency(currency) {
      return "mdi-currency-" + currency.toLowerCase();
    },
  },
};
</script>

<style scoped>
.v-text-field {
  width: 200px;
}
</style>
