<template>
  <v-card>
    <v-form v-on:submit.prevent="save" ref="form" v-model="valid">
      <v-card-text>
        <v-row>
          <v-col cols="2" class="pr-1" v-if="!form.bookingId && !bookingId">
            <v-text-field
              v-model="input.bookingId"
              label="Dossier"
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="6" class="pr-1">
            <v-select
              v-model="form.levelId"
              :items="levels"
              label="Criticité"
              item-text="label"
              item-value="value"
              hide-details
            ></v-select>
          </v-col>
          <v-col cols="4" class="pl-1">
            <smart-picker
              v-model="form.expirationDate"
              label="Date d'expiration"
              :inputOpts="{
                label: 'Date d\'échéance',
                error: dateError,
                clearable: true,
                rules:
                  form.levelId != 5
                    ? rules.nonEmpty('Date d\'échéance obligatoire')
                    : [],
              }"
            ></smart-picker>
          </v-col>
          <v-col cols="12" class="pt-1 pt-2">
            <v-textarea
              label="Message"
              v-model="form.message"
              hide-details
              :rules="rules.nonEmpty()"
            ></v-textarea>
          </v-col>
          <v-col cols="4" class="pr-1 pt-2">
            <v-select
              v-model="form.groupId"
              :items="groups"
              label="Groupe utilisateur"
              item-text="name"
              item-value="id"
              hide-details
            ></v-select>
          </v-col>

          <v-col class="pa-1 align-self-end xs">
            <v-checkbox
              class="justify-center mb-0"
              v-model="form.closed"
              label="Terminé"
              hide-details
            ></v-checkbox>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <smart-btn
          primary
          class="justify-self-end mb-0"
          :disabled="!valid"
          :loading="loading"
          type="submit"
          >{{ $t("save") }}</smart-btn
        >
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import { EventBus } from "@/components/commons/event-bus";
import { get, post } from "@/utils/api";
import FormMixin from "../mixins/FormMixin";
import _ from "lodash";

export default {
  name: "TaskEdit",
  components: {},
  props: {
    bookingId: {
      type: Number,
      required: false,
    },
    prestationId: {
      type: Number,
      required: false,
    },
    task: {
      type: Object,
      required: false,
    },
  },
  mixins: [FormMixin],
  data() {
    return {
      form: {
        expirationDate: null,
        groupName: null,
        id: null,
        bookingId: null,
        prestationId: null,
        levelId: 5,
        message: null,
        closed: false,
        comment: null,
      },
      input: {
        bookingId: null,
      },
      loading: false,
      valid: false,
      groups: [],
      dateError: null,
      levels: [
        { value: 5, label: this.$t("Info") },
        { value: 4, label: this.$t("Control") },
        { value: 3, label: this.$t("Normal") },
        { value: 2, label: this.$t("Emergency") },
        { value: 1, label: this.$t("AbsoluteEmergency") },
      ],
    };
  },
  created() {
    this.init();
  },
  watch: {
    task: {
      immediate: true,
      handler(newVal) {
        if (newVal) this.form = _.cloneDeep(newVal);
      },
    },
    /*'form.levelId': {
				immediate: true, handler(newVal) {
					if (newVal = 5 && this.form.expirationDate == null) {
						this.valid = true
						this.loading = false
						EventBus.$emit('toaster-msg', "Veuillez remplir le champs de Date d'expiration")
					} else if (newVal != 5 && this.form.expirationDate) {
						this.valid = true
					} else if (newVal != 5 && !this.form.expirationDate) {
						this.valid = false
						this.loading = false
					}
				}
			}*/
  },
  computed: {},
  methods: {
    init() {
      get("/api/search/usergroups").json((r) => {
        this.groups = r;
      });
    },
    save() {
      this.loading = true;

      const bookingId = this.bookingId
        ? this.bookingId
        : this.form.bookingId
        ? this.form.bookingId
        : this.input.bookingId;
      this.form.bookingId = bookingId;

      if (this.valid && this.$refs.form.validate()) {
        const path =
          this.bookingId || this.input.bookingId
            ? "create"
            : `${this.form.id}/save`;
        const url = `/api/task/${path}`;

        const task = {
          ...this.form,
          ...{ bookingId: bookingId },
          ...{ prestationId: this.prestationId },
        };

        post(url, task)
          .json((r) => {
            this.$emit("change", this.form);
          })
          .then(() => {
            this.loading = false;
            EventBus.$emit("toaster-msg", "Action enregistrée");
            EventBus.$emit("reloadbooking", {});
            this.$emit("saved", {});
          });
      }
    },
  },
};
</script>

<style scoped></style>
