<template>
  <v-row>
    <v-col class="align-center">
      <v-subheader class="title">
        Informations Agents
        <div class="ml-4 cursor-pointer" @click="newAgent">
          <v-chip class="cursor-pointer" x-small>
            <v-icon small color="grey darken-1">mdi-plus</v-icon>
            Créer un agent
          </v-chip>
        </div>
      </v-subheader>
      <v-card flat :class="_isMobile() ? '' : 'px-8'">
        <v-row v-if="_isDesktop()" dense>
          <v-col cols="12" md="2" dense>
            <v-checkbox
                v-model="showInactive"
                label="Afficher agents inactifs"
                class="mt-1"
                dense
            ></v-checkbox>
          </v-col>

          <v-col cols="8" dense>
            <v-checkbox
                v-model="showMergeSelectors"
                label="Fusion/migration"
                class="mt-0"
                dense
            ></v-checkbox>
          </v-col>

          <v-col v-if="showMergeSelectors">
            <smart-btn small class="mt-4" @click.native="openMergeTool = true"
            >Fusionner
            </smart-btn
            >
            <!-- :disabled="selectedAgents.length < 2"-->
          </v-col>
          <v-col v-if="showMergeSelectors">
            <smart-btn
                small
                class="mt-4"
                @click.native="openMigrationTool = true"
            >Migrer
            </smart-btn
            >
          </v-col>
        </v-row>
        <v-row class="justify-center">
          <v-col cols="12" :class="_isMobile ? '' : ''">
            <v-data-table
                class="mb-4"
                :headers="listing.headers"
                :items="listing.items"
                dense
                :loading="listing.loading"
                :sort-by="['lastname']"
                :sort-desc="true"
                :hide-default-footer="true"
                :options.sync="listing.pagination"
                :footer-props="{
                'items-per-page-options': listing.rowsPerPageItems,
                'items-per-page-text': 'Lignes par page',
              }"
                :header-props="{ sortByText: 'Trier par' }"
            >
              <template #item="{ item, isMobile }">
                <tr>
                  <td width="30" :class="isMobile ? 'd-none' : ''">
                    <div class="d-flex justify-center">
                      <v-checkbox
                          class="ma-0 pa-0"
                          hide-details
                          v-model="item.selected"
                          dense
                          v-show="showMergeSelectors"
                          :disabled="
                          selectedAgents.length == 2 &&
                          !selectedAgents.find((a) => a.id == item.id)
                        "
                      ></v-checkbox>
                    </div>
                  </td>
                  <td :class="isMobile ? 'v-data-table__mobile-row px-1' : ''">
                    <div
                        v-if="_isMobile()"
                        class="v-data-table__mobile-row__header"
                    >
                      Statut
                    </div>
                    <div
                        :class="isMobile ? 'v-data-table__mobile-row__cell' : ''"
                    >
                      <v-chip
                          class="mx-1 my-0"
                          :color="
                          item.active ? 'green lighten-5' : 'red lighten-5'
                        "
                          :style="{
                          border:
                            'solid 1px ' + item.active
                              ? 'green'
                              : 'red' + '!important',
                        }"
                          small
                          label
                      >{{ item.active ? "Actif" : "Inactif" }}
                      </v-chip
                      >
                    </div>
                  </td>
                  <td :class="isMobile ? 'v-data-table__mobile-row px-1' : ''">
                    <div
                        v-if="_isMobile()"
                        class="v-data-table__mobile-row__header"
                    >
                      Id
                    </div>
                    <div
                        :class="isMobile ? 'v-data-table__mobile-row__cell' : ''"
                    >
                      {{ item.id }}
                    </div>
                  </td>
                  <td :class="isMobile ? 'v-data-table__mobile-row px-1' : ''">
                    <div
                        v-if="_isMobile()"
                        class="v-data-table__mobile-row__header"
                    >
                      Nom
                    </div>
                    <div
                        :class="isMobile ? 'v-data-table__mobile-row__cell' : ''"
                    >
                      {{ item.lastname }}
                    </div>
                  </td>
                  <td :class="isMobile ? 'v-data-table__mobile-row px-1' : ''">
                    <div
                        v-if="_isMobile()"
                        class="v-data-table__mobile-row__header"
                    >
                      Prénom
                    </div>
                    <div
                        :class="isMobile ? 'v-data-table__mobile-row__cell' : ''"
                    >
                      {{ item.firstname }}
                    </div>
                  </td>
                  <td :class="isMobile ? 'v-data-table__mobile-row px-1' : ''">
                    <div
                        v-if="_isMobile()"
                        class="v-data-table__mobile-row__header"
                    >
                      Tél
                    </div>
                    <div
                        :class="isMobile ? 'v-data-table__mobile-row__cell' : ''"
                    >
                      {{ item.phone }}
                    </div>
                  </td>
                  <td :class="isMobile ? 'v-data-table__mobile-row px-1' : ''">
                    <div
                        v-if="_isMobile()"
                        class="v-data-table__mobile-row__header"
                    >
                      Email
                    </div>
                    <div
                        :class="isMobile ? 'v-data-table__mobile-row__cell' : ''"
                    >
                      {{ item.email }}
                    </div>
                  </td>
                  <td :class="isMobile ? 'v-data-table__mobile-row px-1' : ''">
                    <div
                        v-if="_isMobile()"
                        class="v-data-table__mobile-row__header"
                    >
                      Login
                    </div>
                    <div
                        :class="isMobile ? 'v-data-table__mobile-row__cell' : ''"
                    >
                      {{ item.login }}
                    </div>
                  </td>
                  <td :class="isMobile ? 'v-data-table__mobile-row px-1' : ''">
                    <div
                        v-if="_isMobile()"
                        class="v-data-table__mobile-row__header"
                    >Incentive disponibles
                    </div>
                    <div
                        :class="isMobile ? 'v-data-table__mobile-row__cell' : ''"
                    >
                      {{ sum(item.incentives.availableIncentives.map(x => x.amount)) }}€
                      <v-icon small @click="detailsIncentive(item)">info</v-icon>
                    </div>
                  </td>
                  <td :class="isMobile ? 'v-data-table__mobile-row px-1' : ''">
                    <div
                        v-if="_isMobile()"
                        class="v-data-table__mobile-row__header"
                    >Incentive déjà convertis
                    </div>
                    <div
                        :class="isMobile ? 'v-data-table__mobile-row__cell' : ''"
                    >
                      {{ sum(item.incentives.giftcards.map(x => x.amount)) }}€
                    </div>
                  </td>
                  <td
                      :class="
                      isMobile ? 'v-data-table__mobile-row  mx-0 px-0' : ''
                    "
                  >
                    <v-chip
                        :class="isMobile ? 'mx-auto' : 'mx-1'"
                        class="my-0"
                        small
                        label
                        outlined
                        @click="openAutoLogin(item.id)"
                    >
                      Login
                      <v-icon small>open_in_new</v-icon>
                    </v-chip>
                  </td>
                  <td :class="isMobile ? 'v-data-table__mobile-row' : ''">
                    <v-chip
                        :class="isMobile ? 'mx-auto' : 'mx-1'"
                        class="my-0"
                        small
                        label
                        outlined
                        @click.native="confirmResend(item)"
                    >Renouveller mot de passe
                    </v-chip
                    >
                  </td>
                  <td :class="isMobile ? 'v-data-table__mobile-row' : ''">
                    <v-chip
                        :class="isMobile ? 'mx-auto' : 'mx-1'"
                        class="my-0"
                        small
                        label
                        outlined
                        @click.native="confirmWelcomemail(item)"
                    >Email bienvenu
                    </v-chip
                    >
                  </td>
                  <td :class="isMobile ? 'v-data-table__mobile-row' : ''">
                    <v-chip
                        :class="isMobile ? 'mx-auto' : 'mx-1'"
                        small
                        @click.native="editAgent(item)"
                    >
                      <span>
                        Éditer
                        <v-icon small color="grey">mdi-pencil</v-icon>
                      </span>
                    </v-chip>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card>
    </v-col>

    <smart-dialog
        v-model="openDialog"
        :title="this.editionForm?.id > 0 ? 'Édition Agent' : 'Création Agent'"
        :fullscreen="_isMobile()"
    >
      <agent-edit-tool
          v-if="openDialog"
          v-model="editionForm"
          @success="
          openDialog = false;
          $emit('success');
        "
      />
    </smart-dialog>

    <smart-dialog v-model="openMergeTool" title="Fusion Agents" width="950">
      <agent-merge-tool
          v-if="openMergeTool"
          :selectedAgents="selectedAgents"
          @success="openMergeTool = false"
      ></agent-merge-tool>
    </smart-dialog>

    <smart-dialog
        v-model="openMigrationTool"
        title="Migration Agents"
        width="950"
    >
      <agent-migration-tool
          :selectedAgents="selectedAgents"
          @success="openMigrationTool = false"
      ></agent-migration-tool>
    </smart-dialog>

    <smart-dialog
        v-model="openIncentiveDialog"
        title="Détails Incentive"
        width="200"
        class="white"
    >

      <v-data-table
          :headers="[
                  { text: 'Ref.', value:'bookingId', align: 'center' },
                  { text: 'Incentive', value: 'amount', align: 'right' }
                  ]"
          :items="detailsAgent != null ?detailsAgent.listIncentives :[]"
          dense
          :loading="listing.loading"
          :sort-by="['bookingId']"
          :sort-desc="true"
          :hide-default-footer="true"
          :footer-props="{
                'items-per-page-options': listing.rowsPerPageItems,
                'items-per-page-text': 'Lignes par page',
              }"
          :header-props="{ sortByText: 'Trier par' }"
      >
        <template #item.amount="{item}">
            {{ item.amount }} €
        </template>
      </v-data-table>

    </smart-dialog>
  </v-row>
</template>
<script>
import {get, post} from "@/utils/api";
import SmartDialog from "./SmartDialog.vue";
import {EventBus} from "@/components/commons/event-bus";
import AgentMergeTool from "@/components/commons/AgentMergeTool";
import AgentMigrationTool from "@/components/commons/AgentMigrationTool";
import AgentEditTool from "./AgentEditTool.vue";
import {sum} from "lodash/math";

export default {
  components: {
    SmartDialog,
    AgentMergeTool,
    AgentMigrationTool,
    AgentEditTool,
  },
  name: "CrmAgencyAgents",
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
    agents: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      agency: null,
      openDialog: false,
      openIncentiveDialog: false,
      detailsAgent: null,
      openMergeTool: false,
      openMigrationTool: false,
      showInactive: false,
      showMergeSelectors: false,
      //selectedAgents: [],
      listing: {
        rowsPerPageItems: [{text: "Tous", value: -1}, 50, 200],
        pagination: {
          options: {
            page: 1,
            itemsPerPage: 200,
            sortBy: [],
            sortDesc: [false],
          },
        },
        headers: [
          {text: "", value: "selected", align: "center"},
          {text: "", value: "status", align: "left"},
          {text: "Id", value: "id", sortable: true},
          {text: "Nom", value: "lastname", sortable: true},
          {text: "Prénom", value: "firstname", sortable: true},
          {text: "Téléphone", value: "phone", sortable: true},
          {text: "Email", value: "email", sortable: true},
          {text: "Login", value: "login", sortable: true},
          {text: "Incentives Dispo.", value: "incentives", sortable: true},
          {text: "Incentives convertis", value: "incentives", sortable: true},

          {text: "", value: "pass", sortable: false},
          {text: "", value: "welcome", sortable: false},
          {text: "Statut", value: "active", sortable: false},
          {text: "", value: "edit"},
        ],
        items: [],
        loading: false,
      },
      defaultForm: {
        id: null,
        agencyId: null,
        lastname: null,
        firstname: null,
        phone: null,
        email: null,
        active: null,
        incentiveRate: 0.005,
        allowIncentive: null,
      },
      editionForm: null,
    };
  },
  created() {
    this.getAgency().then(() => {
      this.defaultForm = {
        ...this.defaultForm,
        ...{agencyId: this.id},
        ...{phone: this.agency.phone},
      };
      this.editionForm = {
        ...this.defaultForm,
        ...{
          active: true,
        },
      };
    });
  },
  computed: {
    selectedAgents() {
      return this.listing.items.filter((agent) => agent.selected);
    },
    urlSavingAgent() {
      return this.editionForm && this.editionForm.id && this.editionForm.id > 0
          ? `/api/agency/agent/${this.editionForm.id}/edit`
          : `/api/agency/agent/create`;
    },
  },
  watch: {
    agents(newVal) {
      this.listing.items = this.filterAgents(this.showInactive);
    },
    showInactive(newVal) {
      this.listing.items = this.filterAgents(newVal);
    },
  },
  methods: {
    sum,
    getAgency() {
      const url = `/api/agency/${this.id}`;

      return get(url)
          .badRequest((br) => {
            console.log("Error: ", br);
          })
          .json((data) => {
            this.agency = data;
          });
    },
    editAgent(item) {
      this.openDialog = true;
      this.editionForm = {
        ...this.defaultForm,
        ...item,
        active: item.active || item.active == null ? true : false,
      };
    },
    newAgent() {
      this.openDialog = true;
      this.editionForm = {
        ...this.defaultForm,
        ...{allowIncentive: true},
        ...{active: true, id: -1},
      };
    },
    detailsIncentive(agent) {
      this.detailsAgent = agent
      this.openIncentiveDialog = true

    },
    confirmResend(item) {
      this.$root
          .$confirm(
              this.$t("confirm"),
              "Souhaitez-vous renouveller le mot de passe de cet agent ?",
              {
                width: 350,
              }
          )
          .then((confirm) => {
            if (!confirm) {
              return Promise.resolve();
            } else {
              const url = `/api/agency/agent/${item.id}/renew-password`;

              return get(url).badRequest((br) => {
                EventBus.$emit("toaster-error", `${br}`);
              }).res((data) => {
                EventBus.$emit("toaster-msg", "Email envoyé à l'agent");
              });
            }
          });
    },
    confirmWelcomemail(item) {
      this.$root
          .$confirm(
              this.$t("confirm"),
              "Souhaitez-vous envoyer l'email de bienvenu à cet agent ?",
              {
                width: 350,
              }
          )
          .then((confirm) => {
            if (!confirm) {
              return Promise.resolve();
            } else {
              const url = `/api/agency/agent/${item.id}/welcome-email`;

              return get(url).badRequest((br) => {
                EventBus.$emit("toaster-error", `${br}`);
              }).res((data) => {
                EventBus.$emit("toaster-msg", "Email envoyé à l'agent");
              });
            }
          });
    },

    filterAgents(showInnactive) {
      const agents = showInnactive
          ? this.agents
          : this.agents.filter((agent) => agent.active);

      return agents.map((a) => {
        return {...a, ...{selected: false}};
      });
    },

    openAutoLogin(id) {
      window.open(
          `${window.location.origin}/api/agency/agent/${id}/autolog`,
          "_blank"
      );
    },
  },
};
</script>
<style scoped>
.cursor-pointer {
  cursor: pointer;
}

>>> td.v-data-table__mobile-row {
  min-height: 38px !important;
  height: 38px !important;
}

>>> tr:nth-of-type(odd) > td.v-data-table__mobile-row {
  background-color: #ececec;
}
</style>
