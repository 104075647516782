<template>
  <layout :drawerShow="false" :drawerWidth="350">
    <template v-slot="nav"></template>

    <template slot="content">
      <v-container fluid>
        <v-row class=" mb-2 mt-2 " dense>
          <v-col cols="8" class=""><h3>{{ title }}</h3></v-col>
          <v-col cols="4" class="text-right">
            <v-btn href="#" small @click="setArrival" class="mr-4">
              <v-icon small round>mdi-airplane-landing</v-icon>
              Voir les arrivées
            </v-btn>
            <v-btn href="#" small @click="setDeparture" class="mr-4">
              <v-icon small round>mdi-airplane-takeoff</v-icon>
              Voir les départs
            </v-btn>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12">
            <v-data-table
                v-model="listing.selected"
                :loading="listing.loading"
                :headers="listing.headers"
                :items="listing.items"
                :sort-by="listing.sortValue"
                :sort-desc="false"
                :footer-props="{
                'items-per-page-options': listing.rowsPerPageItems,
                'items-per-page-text': 'Lignes par page',
              }"
                class="tbl"
                no-data-text="0 résultats"
            >
              <template #item.siteName="{ value }">
                <site-label :sitename="value"></site-label>
              </template>
              <template #item.startDate="{ value }">
                {{
                  _parseAndFormatDate(
                      value,
                      datePatternConfig.serverLocalDateTime,
                      datePatternConfig.serverLocalDate
                  )
                }}
              </template>
              <template #item.stopDate="{ value }">
                {{
                  _parseAndFormatDate(
                      value,
                      datePatternConfig.serverLocalDateTime,
                      datePatternConfig.serverLocalDate
                  )
                }}
              </template>
              <template #item.flights="{ item, value }">
                <span v-if="value && value.length > 0">
                  {{ value.join("\n ") }}
                </span>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </layout>
</template>
<script>
import Layout from "@/views/Layout";
import {EventBus} from "@/components/commons/event-bus";
import {get, post} from "@/utils/api";
import Vue from "vue";
import Availability from "@/components/commons/Availability";
import SmartSelect from "../../components/commons/SmartSelect";
import SiteLabel from "../../components/commons/SiteLabel";
import BookingDrawerContent from "../../components/commons/BookingDrawerContent";
import ExportMixin from "@/components/mixins/ExportMixin";

export default {
  components: {
    SiteLabel,
    SmartSelect,
    Availability,
    Layout,
    BookingDrawerContent,
  },
  data() {
    return {
      title: "",

      defaultStart: null,
      defaultStop: null,

      searchQuery: {
        flightCode: "",
        airportFrom: "",
        airportTo: "",
        flightAircraft: "",
        company: "",
        site: null,
        destination: null,
        hotel: null,
        supplierSelect: null,
        tripDateStart: null,
        tripDateStop: null,
        travelBegins: {
          start: null,
          stop: null,
        },
        travelEnds: {
          start: null,
          stop: null,
        },
        flightDateStart: null,
        flightDateStop: null,
        prestationDateStart: null,
        prestationDateStop: null,
      },
      listing: {
        loading: false,
        sortValue: "startDate",
        rowsPerPageItems: [{text: "Tous", value: -1}, 50, 100, 300, 500],
        headers: [
          {text: "", value: "selectable", align: "left"},
          {text: "Site", value: "siteName", align: "left"},
          {text: "Dossier", value: "bookingId", align: "left"},
          //{text: 'Agence', value: 'ref', align: 'left', width: 40},
          {text: "Passager", value: "guestName", align: "left"},
          {
            text: "Debut",
            value: "startDate",
            align: "left",
            sort: this.sortByDateTime,
          },
          {
            text: "Fin",
            value: "stopDate",
            align: "left",
            sort: this.sortByDateTime,
          },
          {text: "Hotel", value: "hotels", align: "left"},
          {text: "Vols", value: "flights", align: "left"},
          {text: "Transfers", value: "transfers", align: "left"},
        ],
        items: [],
        totalItems: 0,
        selected: [],
      },
    }
  },
  created() {
    this.init()
  },
  mounted() {
  },
  computed: {},
  watch: {},
  methods: {
    init() {
      this.setDefaults();
      this.setArrival();
      this.search();
    },
    setDefaults() {
      this.defaultStart = this._formatDate(new Date(), "dd/MM/yyyy")
      this.defaultStop = this._formatDate(this._addDays(new Date(), 10), "dd/MM/yyyy")
    },
    async setArrival() {
      this.setSearch(this.defaultStart, this.defaultStop, "travelBegins")
      this.setSearch(null, null, "travelEnds")
      this.listing.sortValue = "startDate"

      await this.search()
      this.title = `${this.listing.items.length} arrivées des 10 jours à venir`
    },
    async setDeparture() {
      this.setSearch(this.defaultStart, this.defaultStop, "travelEnds")
      this.setSearch(null, null, "travelBegins")
      this.listing.sortValue = "stopDate"
      await this.search()
      this.title = `${this.listing.items.length} départs des 10 jours à venir`
    },
    setSearch(start, stop, key) {
      this.searchQuery[key].start = start
      this.searchQuery[key].stop = stop


    },
    async search() {
      this.listing.loading = true;
      this.listing.selected = [];


      return post("/api/extranet/bookings/pax-on-place", this.searchQuery)
          .badRequest((err) => {
            EventBus.$emit("toaster-msg", err.toString());
          })
          .json((data) => {
            this.listing.items = data.map((i) => {
              return {...i, ...{id: i.bookingId}};
            });
          })
          .finally(() => {
            this.listing.loading = false;
          });
    },

    sortByDateTime(a, b) {
      const start = a != null ? a : this._formatDate(new Date(null), this.datePatternConfig.serverLocalDateTime)
      const stop = b != null ? b : this._formatDate(new Date(null), this.datePatternConfig.serverLocalDateTime)
      return this._sortDates(start, stop, this.datePatternConfig.serverLocalDateTime);
    },
  }
}
</script>
<style scoped>

</style>
<script setup>
</script>