<template>
  <layout drawerShow :drawerWidth="400">
    <template slot="nav">
      <v-tabs>
        <v-tab>TVA</v-tab>
      </v-tabs>
    </template>

    <template slot="drawer">
      <v-container fluid class="pa-0">
        <v-row no-gutters>
          <v-col cols="12">
            <v-subheader light class="pa-0 mt-1 mb-0 mx-0 form-subheader">Filtres</v-subheader>
            <v-divider></v-divider>
          </v-col>
          <v-col cols="12" class="pr-1">
            <v-select clearable v-model.number="searchCtx.siteId" :items="sites" item-value="id" item-text="name"
              label="Site" hide-details></v-select>
          </v-col>
          <v-col cols="12" class="pr-1">
            <v-select clearable v-model.number="searchCtx.destinationId" :items="destinations" item-value="id"
              item-text="name" label="Destination" hide-details></v-select>
          </v-col>


          <v-col cols="12">
            <v-subheader light class="pa-0 mt-5 mb-0 mx-0 form-subheader">Date de Réservation</v-subheader>
            <v-divider></v-divider>
          </v-col>
          <v-col cols="6" class="pr-1">
            <smart-picker v-model="searchCtx.bookingDateStart" format="yyyy-MM-dd" label="Début"
              :stopDate="searchCtx.bookingDateStop" :inputOpts="{ placeholder: ' ' }"></smart-picker>
          </v-col>
          <v-col cols="6" class="pr-1">
            <smart-picker v-model="searchCtx.bookingDateStop" format="yyyy-MM-dd" label="Fin"
              :startDate="searchCtx.bookingDateStart" :inputOpts="{ placeholder: ' ' }"></smart-picker>
          </v-col>

          <v-col cols="12">
            <v-subheader light class="pa-0 mt-5 mb-0 mx-0 form-subheader">Date de voyage de prestation</v-subheader>
            <v-divider></v-divider>
          </v-col>
          <v-col cols="6" class="pr-1">
            <smart-picker v-model="searchCtx.stayDateStart" format="yyyy-MM-dd" label="Date de début" clearable
              :stopDate="searchCtx.stayDateStop" :inputOpts="{ placeholder: ' ' }"></smart-picker>
          </v-col>
          <v-col cols="6" class="pr-1">
            <smart-picker v-model="searchCtx.stayDateStop" format="yyyy-MM-dd" label="Date de fin" clearable
              :startDate="searchCtx.stayDateStart" :inputOpts="{ placeholder: ' ' }"></smart-picker>
          </v-col>

          <v-col cols="12" class="text-right">
            <v-divider class="mb-3"></v-divider>
            <v-btn class="primary" :loading="loading" @click="search">
              <v-icon>search</v-icon>Rechercher
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </template>

    <template slot="content">

      <v-container>
        <div class="text-right mb-2">
          <v-btn href="#" @click="exportCsv('datatable')" small>
            <v-icon small round>mdi-file-download</v-icon> export
          </v-btn>
        </div>
        <v-card>
          <v-data-table ref="datatable" :headers="headers" :items="items" :loading="loading" no-data-text="-" hide-default-footer
            hide-default-header :footer-props="{
              'items-per-page-options': rowsPerPageItems,
              'items-per-page-text': 'Lignes par page',
            }" class="tbl">
            <template #header="{ props: { headers } }">

              <thead class="v-data-table-header">
                <tr>
                  <th v-for="header in headers" :key="header.value"
                    :class="['column sortable', pagination.descending ? 'desc' : 'asc', header.value === pagination.sortBy ? 'active' : '', header.value == 'price' || header.value == 'priceWithSupplierInvoice' ? 'bl' : '', 'text-xs-' + (header.align || 'left')]">
                    {{ header.text }}</th>
                </tr>
              </thead>
            </template>

            <template #item.tva="{ value }">{{ value | currency('EUR') }}</template>
            <template #item.tvaReport="{ value }">{{ value | currency('EUR') }}</template>
            <template #item.markup="{ value }">{{ value | percent }}</template>
            <template #item.paidAmount="{ value }">{{ value | percent }}</template>

            <template slot="body.append">
              <tr v-if="sumTVAReport > 0">
                <th colspan="3">Totaux</th>

                <th>{{ sumTTC | currency('EUR') }}</th>
                <th>{{ sumHT | currency('EUR') }}</th>
                <th colspan="2">{{ sumAHHT | currency('EUR') }}</th>
                <th colspan="2">{{ sumTVA | currency('EUR') }}</th>
                <th>{{ sumTVAReport | currency('EUR') }}</th>

              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-container>
    </template>
  </layout>
</template>
<script>
import Layout from '@/views/Layout'
import { EventBus } from "@/components/commons/event-bus";
import { get, post } from "@/utils/api"
import ExportMixin from "@/components/mixins/ExportMixin";

export default {
  components: {
    Layout
  },
  mixins: [ExportMixin],
  data: () => ({
    headers: [
      { text: "#", value: "id", width: "55px" },
      { text: "Site", value: "siteId", width: "55px" },
      { text: "Destination", value: "destination", width: "55px" },
      { text: "Vente TTC", value: "price", width: "55px" },
      { text: "Vente HT", value: "priceTaxFree", width: "55px" },
      { text: "Achat", value: "purchase", width: "55px" },
      { text: "Marge réelle", value: "markup", width: "55px" },
      { text: "TVA", value: "tva", width: "55px" },
      { text: "% dossier payé", value: "paidAmount", width: "55px" },
      { text: "TVA à déclarer", value: "tvaReport", width: "55px" },
    ],
    items: [],
    loading: false,
    searchCtx: {
      siteId: null,
      destinationId: null,
      bookingDateStart: null,
      bookingDateStop: null,
      stayDateStart: null,
      stayDateStop: null
    },
    sites: [],
    destinations: [],
    rowsPerPageItems: [{ text: "Tous", value: -1 }, 50, 200],
    pagination: {
      sortBy: "bookingDate",
    },
  }),
  created() {
    this.init()
  },
  computed: {
    sumTTC() {
      return this.items.reduce((sum, i) => sum + i.price, 0)
    },
    sumHT() {
      return this.items.reduce((sum, i) => sum + i.priceTaxFree, 0)
    },
    sumAHHT() {
      return this.items.reduce((sum, i) => sum + i.purchase, 0)
    },
    sumTVA() {
      return this.items.reduce((sum, i) => sum + i.tva, 0)
    },
    sumTVAReport() {
      return this.items.reduce((sum, i) => sum + i.tvaReport, 0)
    }
  },
  methods: {
    async init() {
      await this.loadDestinations()
      await this.loadSites()
      this.search()
    },
    async loadDestinations() {
      return get("/api/search/destinations-taxs")
        .json((destinations) => {
          this.destinations = destinations
          //this.searchCtx.destinationId = destinations.find(d => d.id == 18).id;

        })
    },
    async loadSites() {
      return get("/api/search/sites")
        .json((sites) => {
          this.sites = sites
        })
    },
    async search() {
      this.loading = true
      post("/api/accounting/tva", this.searchCtx)
        .json((data) => {
          this.items = data;
        })
        .catch((e) => {
          EventBus.$emit("toast", {
            color: "red",
            text: "Une erreur est survenue",
            exception: e,
          });
        })
        .then(() => {
          this.loading = false;
        });
    }
  }
}
</script>