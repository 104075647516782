<template>
  <div v-if="infosFlights" class="bg-white">
    <div class="page-content content">
      <div class="header">
        <div class="logo">
          <img :src="logo" />
        </div>
        <div class="title">
          <p>{{ $t("document.flightTicket.title") }}</p>
          <p>
            {{
              $t("document.flightTicket.referenceTicket", { numberTicket: pnr })
            }}
          </p>
        </div>
        <div class="image">
          <svg
            width="214"
            height="104"
            viewBox="0 0 214 104"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_76_264)">
              <path
                d="M202.839 127.135C202.839 127.135 197.806 128.497 193.161 122.076C188.516 115.654 183.677 111.957 166.258 118.573C166.258 118.573 155.032 121.687 144.387 111.373C135.648 102.906 113.086 99.9484 86.0148 117.458C83.0497 116.331 79.8213 114.451 76.6452 111.373C66 101.059 34.8387 98.9189 0 132H222C222 132 215.613 121.492 202.839 127.135Z"
                fill="#CEDDE0"
              />
              <path
                d="M177.712 27.5027H89.6845V-22.3505H177.712V27.5027ZM90.2652 26.9189H177.132V-21.7667H90.2652V26.9189Z"
                fill="#121212"
              />
              <path
                d="M101.001 6.87178H95.3981V-1.54639H101.001V6.87178ZM95.9787 6.28799H100.421V-0.962603H95.9787V6.28799Z"
                fill="#121212"
              />
              <path
                d="M120.797 -0.501404H103.308V0.0823801H120.797V-0.501404Z"
                fill="#121212"
              />
              <path
                d="M162.095 1.41342H103.394V1.99721H162.095V1.41342Z"
                fill="#121212"
              />
              <path
                d="M162.095 3.32822H103.394V3.912H162.095V3.32822Z"
                fill="#121212"
              />
              <path
                d="M162.095 5.24304H103.394V5.82683H162.095V5.24304Z"
                fill="#121212"
              />
              <path
                d="M101.001 19.7559H95.3981V11.3377H101.001V19.7559ZM95.9787 19.1721H100.421V11.9215H95.9787V19.1721Z"
                fill="#121212"
              />
              <path
                d="M120.797 12.3808H103.308V12.9646H120.797V12.3808Z"
                fill="#121212"
              />
              <path
                d="M162.095 14.2975H103.394V14.8813H162.095V14.2975Z"
                fill="#121212"
              />
              <path
                d="M162.095 16.2123H103.394V16.7961H162.095V16.2123Z"
                fill="#121212"
              />
              <path
                d="M162.095 18.1271H103.394V18.7109H162.095V18.1271Z"
                fill="#121212"
              />
              <path
                d="M79.0384 15.4757H49.95V-7H79.0384V15.4757ZM50.5307 14.8919H78.4578V-6.41622H50.5307V14.8919Z"
                fill="#121212"
              />
              <path
                d="M76.0907 12.8973H52.8978V-4.42163H76.0907V12.8973ZM53.4784 12.3135H75.51V-3.83785H53.4784V12.3135Z"
                fill="#121212"
              />
              <path
                d="M55.4952 9.34593L61.1081 4.28648L63.6242 6.42702L67.1081 2.92432L73.4952 9.34593H55.4952Z"
                fill="#121212"
              />
              <path
                d="M63.3339 2.24325C64.0821 2.24325 64.6887 1.63338 64.6887 0.881083C64.6887 0.128782 64.0821 -0.481079 63.3339 -0.481079C62.5856 -0.481079 61.979 0.128782 61.979 0.881083C61.979 1.63338 62.5856 2.24325 63.3339 2.24325Z"
                fill="#095464"
              />
              <path
                d="M111.619 22.9064C111.619 22.9064 108.012 22.3168 108.149 25.9362C108.286 29.5557 109.597 33.4106 109.597 33.4106C109.597 33.4106 110.081 35.5726 109.103 37.6469C109.103 37.6469 115.185 36.816 116.197 33.9555L115.949 23.4882L111.619 22.9064Z"
                fill="#121212"
              />
              <path
                d="M113.154 43.9654L113.479 34.1131C113.479 34.1131 107.911 35.594 110.005 24.8698C110.005 24.8698 112.111 22.7254 114.799 24.0817C117.488 25.4361 118.384 27.9892 118.384 27.9892L120.014 43.1111L113.156 43.9654H113.154Z"
                fill="#F3F3F3"
              />
              <path
                d="M112.854 44.2982L113.177 34.4595C112.622 34.5159 111.557 34.4848 110.661 33.6402C109.169 32.2333 108.852 29.2638 109.721 24.8173L109.738 24.7317L109.8 24.6694C109.823 24.6461 112.086 22.3907 114.931 23.8249C117.693 25.2162 118.62 27.7868 118.659 27.8958L118.674 27.962L120.335 43.37L112.856 44.3021L112.854 44.2982ZM113.783 33.7317L113.456 43.6346L119.694 42.8582L118.099 28.0592C117.991 27.781 117.064 25.5509 114.67 24.3444C112.44 23.2216 110.623 24.7044 110.272 25.0216C109.27 30.2329 110.145 32.354 111.058 33.216C112.076 34.1754 113.392 33.8387 113.406 33.8348L113.783 33.7336V33.7317Z"
                fill="#121212"
              />
              <path
                d="M110.102 24.4923C110.102 24.4923 111.112 25.9304 112.351 26.6951C113.398 27.3412 114.656 27.3081 115.123 27.5689C115.123 27.5689 116.4 32.5738 113.785 37.1351C113.785 37.1351 117.517 37.1857 120.188 33.6538C120.188 33.6538 119.706 34.5607 119.191 35.2631C119.191 35.2631 123.72 35.4519 120.974 25.2123C119.197 18.5864 111.35 21.803 110.175 22.92L110.104 24.4904L110.102 24.4923Z"
                fill="#121212"
              />
              <path
                d="M119.386 107.469C121.353 116.032 123.374 125.014 123.478 126.189C123.546 126.954 120.377 128.527 120.377 128.527L127.665 128.371L125.079 106.175L123.339 91.248H115.626C115.626 91.248 117.48 99.1622 119.388 107.471L119.386 107.469Z"
                fill="#F3F3F3"
              />
              <path
                d="M119.077 128.848L120.248 128.266C121.81 127.489 123.184 126.532 123.188 126.215C123.105 125.267 121.692 118.808 119.104 107.538C117.219 99.3237 115.361 91.3959 115.341 91.3161L115.258 90.9581H123.596L127.99 128.659L119.077 128.85V128.848ZM119.669 107.405C122.301 118.865 123.679 125.176 123.766 126.164C123.823 126.789 122.638 127.61 121.593 128.209L127.337 128.087L123.079 91.5399H115.99C116.373 93.1803 118.01 100.182 119.669 107.405Z"
                fill="#121212"
              />
              <path
                d="M107.162 128.528L114.449 128.373L114.126 107.269L113.88 91.2499H106.167C106.167 91.2499 106.995 98.2028 107.909 105.917C108.975 114.915 110.154 124.948 110.263 126.193C110.33 126.958 107.162 128.53 107.162 128.53V128.528Z"
                fill="#F3F3F3"
              />
              <path
                d="M105.861 128.848L107.032 128.266C108.594 127.489 109.968 126.532 109.972 126.215C109.852 124.855 108.447 112.934 107.621 105.95C106.709 98.2359 105.879 91.285 105.879 91.285L105.84 90.9581H114.166L114.743 128.659L105.861 128.85V128.848ZM106.494 91.5399C106.668 92.9994 107.4 99.1486 108.195 105.88C109.024 112.867 110.429 124.794 110.549 126.164C110.605 126.789 109.421 127.61 108.375 128.209L114.151 128.085L113.592 91.538H106.49L106.494 91.5399Z"
                fill="#121212"
              />
              <path
                d="M125.098 65.7873L106.355 67.237C106.355 67.237 103.711 98.5336 103.831 104.019C103.831 104.019 122.605 108.117 132.306 102.389C132.306 102.389 128.613 78.8309 125.098 65.7873Z"
                fill="#F3F3F3"
              />
              <path
                d="M117.654 105.751C110.535 105.751 104.218 104.403 103.771 104.305L103.548 104.257L103.543 104.027C103.423 98.5881 106.039 67.5269 106.066 67.2136L106.088 66.9665L125.317 65.4798L125.379 65.7133C128.859 78.6227 132.557 102.108 132.594 102.346L132.625 102.542L132.455 102.643C128.386 105.047 122.81 105.755 117.656 105.755L117.654 105.751ZM104.119 103.78C106.258 104.212 123.023 107.366 131.988 102.235C131.625 99.9405 128.164 78.3853 124.879 66.0967L106.622 67.5094C106.376 70.4283 104.073 98.0647 104.117 103.78H104.119Z"
                fill="#121212"
              />
              <path
                d="M96.2497 45.2147L93.2865 48.7485L99.869 56.365C99.869 56.365 101.491 57.6337 103.113 55.4582L107.458 50.317C107.458 50.317 108.219 54.6078 107.648 57.3885C107.648 57.3885 106.225 66.4605 105.84 69.1129C105.84 69.1129 119.402 72.8082 126.575 67.667L125.458 59.9883L129.196 61.1987C130.895 61.7494 132.691 60.5916 132.854 58.8032C132.933 57.9354 132.825 56.8145 132.395 55.3687L127.98 41.1457C127.98 41.1457 127.49 38.9877 125.663 38.5265L119.51 36.6156C119.51 36.6156 114.952 40.667 113.474 36.707L107.357 39.1356L100.138 48.6649L96.2497 45.2147Z"
                fill="#095464"
              />
              <path
                d="M109.593 55.6508L114.925 57.1083L118.608 56.546L120.95 57.2698L121.614 54.0707L119.385 53.8275L118.318 53.0978C115.701 51.8835 112.792 53.0978 112.792 53.0978C113.47 54.1914 114.052 53.5842 114.925 53.8275C115.798 54.0707 115.508 54.5572 115.508 54.5572L113.762 55.0437L110.563 54.5572C110.079 54.6779 109.593 55.6489 109.593 55.6489V55.6508Z"
                fill="#F3F3F3"
              />
              <path
                d="M121.173 57.6435L118.589 56.8437L114.91 57.406L109.175 55.8376L109.334 55.5185C109.425 55.3356 109.908 54.421 110.491 54.275L110.547 54.2614L113.743 54.7479L115.25 54.3276C115.214 54.2653 115.092 54.1797 114.848 54.1116C114.6 54.0415 114.377 54.0551 114.143 54.0688C113.648 54.0979 113.086 54.1291 112.546 53.2553L112.365 52.9635L112.68 52.8311C112.804 52.7786 115.736 51.5818 118.44 52.835L118.481 52.8584L119.487 53.5472L121.963 53.8177L121.169 57.6454L121.173 57.6435ZM118.634 56.2463L120.735 56.8962L121.268 54.3256L119.284 54.1096L118.177 53.3526C116.23 52.4595 114.091 52.9771 113.261 53.2417C113.518 53.5142 113.75 53.5025 114.112 53.4811C114.37 53.4655 114.664 53.4499 115.005 53.5453C115.426 53.6621 115.694 53.8566 115.798 54.1232C115.918 54.4229 115.773 54.6778 115.757 54.707L115.697 54.8063L113.781 55.3395L110.59 54.8549C110.419 54.9308 110.203 55.2013 110.034 55.4659L114.945 56.8086L118.634 56.2463Z"
                fill="#121212"
              />
              <path
                d="M97.8155 35.7827H85.8136L91.9103 51.8737H103.912L97.8155 35.7827Z"
                fill="#F3F3F3"
              />
              <path
                d="M104.334 52.1656H91.709L85.3916 35.4908H98.0168L104.334 52.1656ZM92.1097 51.5818H103.492L97.6161 36.0746H86.2336L92.1097 51.5818Z"
                fill="#121212"
              />
              <path
                d="M126.103 60.5488L120.346 58.4549L121.353 53.5998L129.726 54.5358L129.662 55.1157L121.814 54.2381L121.018 58.0794L126.3 60L126.103 60.5488Z"
                fill="#121212"
              />
              <path
                d="M107.311 51.2997C106.653 45.853 108.674 43.6619 108.759 43.5704L109.179 43.9732C109.159 43.9946 107.265 46.0846 107.886 51.2296L107.309 51.2997H107.311Z"
                fill="#121212"
              />
              <path
                d="M123.483 47.511L122.922 47.6633L124.724 54.3679L125.284 54.2156L123.483 47.511Z"
                fill="#121212"
              />
              <path
                d="M86.8781 38.6919L87.5226 40.2915L88.2193 39.9859C88.9161 39.6804 89.731 40.0365 89.9652 40.7643C90.329 41.9047 90.5458 43.6112 89.3961 45.1135C89.3961 45.1135 87.5071 44.9462 86.6864 42.1946C86.1058 40.2487 86.2819 39.6045 86.88 38.6919H86.8781Z"
                fill="#F3F3F3"
              />
              <path
                d="M89.5258 45.419L89.3671 45.4054C89.2819 45.3976 87.2729 45.1875 86.4039 42.2802C85.8039 40.2701 85.98 39.5287 86.6323 38.5323L86.9361 38.0673L87.6755 39.9042L88.0974 39.7194C88.5097 39.5384 88.98 39.5423 89.3903 39.7272C89.7929 39.9101 90.1006 40.2545 90.2342 40.6748C90.6271 41.9008 90.8381 43.6969 89.6187 45.2926L89.5219 45.419H89.5258ZM86.8297 39.3535C86.5374 39.9704 86.52 40.6301 86.9613 42.1109C87.5826 44.195 88.8348 44.6854 89.2665 44.7944C90.2148 43.4361 90.0232 41.9105 89.6845 40.8539C89.5994 40.5873 89.4116 40.3752 89.1542 40.2603C88.8929 40.1416 88.5948 40.1397 88.3316 40.2545L87.3619 40.6807L86.8277 39.3535H86.8297Z"
                fill="#121212"
              />
              <path
                d="M141.918 38.4L139.871 56.9332C139.758 57.947 140.548 58.8324 141.562 58.8324H168.953C169.82 58.8324 170.548 58.1766 170.645 57.3107L172.693 38.7775C172.805 37.7637 172.015 36.8783 171.001 36.8783H143.61C142.743 36.8783 142.015 37.5341 141.918 38.4Z"
                fill="#121212"
              />
              <path
                d="M174.148 38.7775L172.1 57.3107C172.005 58.1767 171.278 58.8324 170.409 58.8324H143.02C142.005 58.8324 141.214 57.947 141.328 56.9313L143.376 38.3981C143.471 37.5321 144.198 36.8763 145.067 36.8763H172.456C173.471 36.8763 174.262 37.7617 174.148 38.7775Z"
                fill="#F3F3F3"
              />
              <path
                d="M170.409 59.1263H143.02C142.453 59.1263 141.911 58.883 141.533 58.4569C141.156 58.0327 140.978 57.4664 141.04 56.9001L143.087 38.3669C143.198 37.3511 144.049 36.5844 145.067 36.5844H172.456C173.024 36.5844 173.565 36.8277 173.943 37.2538C174.32 37.6781 174.5 38.2443 174.436 38.8106L172.389 57.3438C172.278 58.3596 171.427 59.1263 170.409 59.1263ZM145.067 37.1702C144.345 37.1702 143.744 37.7131 143.664 38.4331L141.616 56.9663C141.572 57.3671 141.698 57.768 141.965 58.0677C142.232 58.3693 142.617 58.5425 143.018 58.5425H170.407C171.129 58.5425 171.731 57.9996 171.81 57.2796L173.858 38.7444C173.902 38.3436 173.776 37.9427 173.509 37.643C173.242 37.3414 172.857 37.1682 172.456 37.1682H145.067V37.1702Z"
                fill="#121212"
              />
              <path
                d="M156.887 49.3187H153.976V58.8324H156.887V49.3187Z"
                fill="#121212"
              />
              <path
                d="M153.976 49.3187H160.042L163.925 63.7129H148.88V62.2495H157.131L153.976 49.3187Z"
                fill="#F3F3F3"
              />
              <path
                d="M164.304 64.0048H148.59V61.9576H156.76L153.605 49.0288H160.263L164.302 64.0067L164.304 64.0048ZM149.171 63.421H163.545L159.82 49.6106H154.346L157.501 62.5395H149.171V63.419V63.421Z"
                fill="#121212"
              />
              <path
                d="M150.496 56.6355H149.976V57.4216H150.496V56.6355Z"
                fill="#095464"
              />
              <path
                d="M149.324 56.6355H148.803V57.4216H149.324V56.6355Z"
                fill="#095464"
              />
              <path
                d="M148.153 56.6355H147.632V57.4216H148.153V56.6355Z"
                fill="#095464"
              />
              <path
                d="M146.98 56.6355H146.459V57.4216H146.98V56.6355Z"
                fill="#095464"
              />
              <path
                d="M153.832 63.7771H53.7194V125.728H153.832V63.7771Z"
                fill="#F3F3F3"
              />
              <path
                d="M154.121 126.02H53.4271V63.4852H154.121V126.02ZM54.0077 125.436H153.54V64.069H54.0077V125.436Z"
                fill="#121212"
              />
              <path
                d="M190.734 63.6779H150.004V125.824H190.734V63.6779Z"
                fill="#F3F3F3"
              />
              <path
                d="M191.025 126.115H149.714V63.386H191.025V126.115ZM150.294 125.532H190.444V63.9697H150.294V125.532Z"
                fill="#121212"
              />
              <path
                d="M51.2226 63.6915V68.4182H153.83V63.6623L51.2226 63.6915Z"
                fill="#F3F3F3"
              />
              <path
                d="M154.121 68.7101H50.9323V63.3996L154.123 63.3704V68.7101H154.121ZM51.511 68.1263H153.54V63.9542L51.511 63.9834V68.1263Z"
                fill="#121212"
              />
              <path
                d="M150.186 63.6915V68.4182H196.152V63.6623L150.186 63.6915Z"
                fill="#121212"
              />
              <path
                d="M196.442 68.7101H149.895V63.3996L196.442 63.3704V68.7101ZM150.476 68.1263H195.861V63.9542L150.476 63.9834V68.1263Z"
                fill="#121212"
              />
              <path
                d="M138.652 91.3297H131.201V90.746H138.072V88.8973H138.652V91.3297Z"
                fill="#121212"
              />
              <path
                d="M138.652 97.946H131.201V97.3622H138.072V95.5135H138.652V97.946Z"
                fill="#121212"
              />
              <path
                d="M142.91 94.4433H135.459V93.8595H142.33V92.0108H142.91V94.4433Z"
                fill="#121212"
              />
              <path
                d="M134.394 94.4433H126.943V93.8595H133.814V92.0108H134.394V94.4433Z"
                fill="#121212"
              />
              <path
                d="M109.62 99.1135H102.168V98.5297H109.039V96.6811H109.62V99.1135Z"
                fill="#121212"
              />
              <path
                d="M90.8458 91.1351H83.3942V90.5513H90.2652V88.7027H90.8458V91.1351Z"
                fill="#121212"
              />
              <path
                d="M104.975 102.616H97.5232V102.032H104.394V100.184H104.975V102.616Z"
                fill="#121212"
              />
              <path
                d="M73.469 38.8865L73.8561 34.2396C73.8561 34.2396 78.7277 34.7202 76.1787 24.467H69.2361L67.5174 38.8846H73.469V38.8865Z"
                fill="#F3F3F3"
              />
              <path
                d="M73.7361 39.1784H67.1903L68.9787 24.1771H76.4052L76.4594 24.3989C77.5703 28.8688 77.4484 31.9336 76.0955 33.5098C75.3968 34.3252 74.5587 34.4984 74.1232 34.5315L73.7361 39.1803V39.1784ZM67.8445 38.5946H73.2019L73.591 33.9204L73.8832 33.9496C73.9219 33.9535 74.8916 34.0275 75.6639 33.1168C76.4865 32.1477 77.1948 29.9118 75.9484 24.7609H69.4916L67.8426 38.5946H67.8445Z"
                fill="#121212"
              />
              <path
                d="M74.6187 29.5946C74.6187 29.5946 74.5161 27.8919 72.991 28.5068C72.3213 28.7773 72.3871 30.227 73.3103 31.1961C73.3103 31.1961 72.9116 33.504 70.0432 33.4164C67.5484 33.3405 67.2329 31.7351 67.2329 31.7351C67.2329 31.7351 65.731 27.9931 67.0045 24.3094C67.0045 24.3094 67.5155 21.157 72.5806 21.6668C73.9781 21.8069 75.4239 21.2134 75.9387 20.4506C76.2368 20.0108 76.8271 19.8999 77.2471 20.2249C78.2458 20.9974 79.4129 22.4588 78.7277 24.8251C78.7277 24.8251 78.4084 26.4616 75.7006 26.9423L75.0987 29.4506L74.6206 29.5965L74.6187 29.5946Z"
                fill="#121212"
              />
              <path
                d="M58.2329 45.8919L56.6361 55.6878C56.4348 56.9215 57.0561 58.1416 58.1671 58.7021L67.9103 63.6L69.2652 59.1243L64.2794 53.6718L65.0594 48.0052L65.4213 45.3762L58.2329 45.8919Z"
                fill="#F3F3F3"
              />
              <path
                d="M68.0865 64.0164L58.0355 58.9648C56.8026 58.3459 56.1252 57.011 56.3477 55.643L57.9813 45.6195L65.7561 45.061L64.5832 53.5764L69.5884 59.0523L68.0845 64.0184L68.0865 64.0164ZM58.4806 46.1682L56.9206 55.7364C56.7387 56.8495 57.2923 57.9373 58.2948 58.4413L67.7303 63.1855L68.9361 59.2002L63.9697 53.7691L65.0807 45.6953L58.4787 46.1702L58.4806 46.1682Z"
                fill="#121212"
              />
              <path
                d="M51.151 124.366H59.1755C59.1755 124.366 68.9381 92.7425 69.2419 90.1446L66.7316 124.366H75.4277L80.9032 87.6324L79.0955 65.149C79.0955 65.149 59.8548 63.3626 59.6129 64.0865C58.2697 68.0932 58.3297 71.8703 58.2077 78.2238L58.7148 90.3568C58.7148 90.3568 50.8703 124.366 51.151 124.366Z"
                fill="#095464"
              />
              <path
                d="M58.1613 68.4649C58.1613 68.4649 62.9961 70.4108 68.2258 70.4108C77.3226 70.4108 79.9103 69.0487 79.9103 69.0487L78.9426 49.3946H82.0394C81.2652 46.0865 79.7671 42.3328 78.749 40.2486C76.8987 36.4618 74.8781 35.9676 73.9103 35.3838C73.5194 35.1483 67.9374 34.7864 67.9374 34.7864C66.6774 34.9284 63.6348 35.4344 61.1052 38.6238C59.1987 41.027 57.4568 46.8649 57.4568 46.8649L61.1748 46.9952L58.1594 68.4649H58.1613Z"
                fill="#F3F3F3"
              />
              <path
                d="M68.2258 70.7027C63 70.7027 58.1013 68.7548 58.0529 68.7354L57.8419 68.6497L60.8439 47.2755L57.0716 47.1431L57.18 46.7812C57.2516 46.5399 58.9606 40.8616 60.8787 38.4428C62.7019 36.1447 65.0652 34.8175 67.9045 34.4984H67.9297H67.9548C69.6542 34.6074 73.649 34.8895 74.0574 35.1367C74.1987 35.2223 74.3652 35.3059 74.5606 35.4032C75.6232 35.9384 77.4019 36.8335 79.0084 40.1222C80.1135 42.3853 81.5671 46.1098 82.32 49.3304L82.4032 49.6884H79.2445L80.2064 69.2218L80.0419 69.3075C79.9335 69.3639 77.2877 70.7027 68.2239 70.7027H68.2258ZM58.4806 68.2742C59.4658 68.64 63.7239 70.1189 68.2258 70.1189C75.8864 70.1189 78.9097 69.1382 79.6103 68.8618L78.6368 49.1027H81.6697C80.9052 46.0125 79.5387 42.5274 78.4877 40.3771C76.9664 37.2655 75.3639 36.4579 74.3013 35.9228C74.1058 35.8236 73.92 35.7302 73.7594 35.6348C73.4477 35.5005 70.5329 35.2476 67.9432 35.0802C65.2742 35.3857 63.0503 36.6389 61.3316 38.8067C59.7542 40.7974 58.2619 45.2828 57.8477 46.5866L61.5077 46.715L58.4806 68.2742Z"
                fill="#121212"
              />
              <path
                d="M74.2626 49.5036L74.8703 51.2393L77.7136 59.3753C78.0484 60.4456 78.9542 61.2318 80.0555 61.4089L93.6039 63.6H102.585L101.872 62.7652C101.565 62.4071 101.17 62.1347 100.725 61.981L96.0368 60.3464C95.7581 60.2491 95.4619 60.216 95.1678 60.2491L93.0136 60.4923L82.4787 56.2891L80.7813 49.8499L80.6477 49.344L74.2626 49.5016V49.5036Z"
                fill="#F3F3F3"
              />
              <path
                d="M103.215 63.8919L93.5574 63.888L80.009 61.6969C78.7916 61.5003 77.8064 60.6441 77.4368 59.461L73.8561 49.2195L80.8703 49.0463L82.7206 56.0711L93.0522 60.1926L95.1348 59.9572C95.4658 59.9183 95.8103 59.9572 96.1316 60.0681L100.819 61.7027C101.311 61.8739 101.752 62.1736 102.093 62.5725L103.217 63.888L103.215 63.8919ZM93.6252 63.3081H101.952L101.652 62.9559C101.379 62.6368 101.025 62.3955 100.628 62.2573L95.94 60.6227C95.7019 60.541 95.4464 60.5118 95.2006 60.541L92.9748 60.792L92.9071 60.7648L82.2368 56.507L80.429 49.6417L74.671 49.7838L77.9884 59.2761C78.2942 60.2549 79.1032 60.9574 80.1019 61.1189L93.6271 63.3062L93.6252 63.3081Z"
                fill="#121212"
              />
              <path
                d="M73.2174 45.602L72.6693 45.7949L74.0242 49.6869L74.5722 49.494L73.2174 45.602Z"
                fill="#121212"
              />
              <path
                d="M61.4277 42.9333L60.8771 46.9743L61.4524 47.0535L62.0029 43.0125L61.4277 42.9333Z"
                fill="#121212"
              />
              <path
                d="M68.2659 73.0808L67.6866 73.1213L68.8827 90.4082L69.462 90.3676L68.2659 73.0808Z"
                fill="#121212"
              />
              <path
                d="M36.6484 95.5555H35.6806V81.8677C35.6806 80.8266 34.8387 79.9801 33.8032 79.9801H28.6432C27.6077 79.9801 26.7658 80.8266 26.7658 81.8677V95.5555H25.7981V81.8677C25.7981 80.2915 27.0735 79.0072 28.6432 79.0072H33.8032C35.371 79.0072 36.6484 80.2895 36.6484 81.8677V95.5555Z"
                fill="#121212"
              />
              <path
                d="M25.8735 94.2284H42.0116C43.8542 94.2284 45.3465 95.7443 45.3465 97.6143V127.043C45.3465 128.913 43.8542 130.429 42.0116 130.429H25.8735C24.031 130.429 22.5387 128.913 22.5387 127.043V97.6143C22.5387 95.7443 24.031 94.2284 25.8735 94.2284Z"
                fill="#121212"
              />
              <path
                d="M40.8581 97.9354V127.076C40.8581 128.928 39.3658 130.429 37.5232 130.429H21.3852C19.5774 130.429 18.1065 128.983 18.0523 127.179C18.0523 127.144 18.0523 127.111 18.0523 127.076V97.9354C18.0523 97.8595 18.0542 97.7836 18.06 97.7077C18.1761 95.9622 19.6219 94.5826 21.3871 94.5826H37.5252C39.3677 94.5826 40.86 96.0829 40.86 97.9354H40.8581Z"
                fill="#F3F3F3"
              />
              <path
                d="M37.5232 130.721H21.3852C19.4129 130.721 17.8219 129.17 17.7619 127.189C17.7619 127.142 17.7619 127.109 17.7619 127.076V97.9354C17.7619 97.8537 17.7639 97.77 17.7697 97.6883C17.8955 95.7832 19.4845 94.2906 21.3871 94.2906H37.5252C39.5245 94.2906 41.1503 95.9252 41.1503 97.9354V127.076C41.1503 129.086 39.5245 130.721 37.5252 130.721H37.5232ZM21.3852 94.8744C19.7884 94.8744 18.4529 96.1276 18.3465 97.7272C18.3426 97.7953 18.3407 97.8653 18.3407 97.9354V127.162C18.3929 128.835 19.7284 130.137 21.3852 130.137H37.5232C39.2013 130.137 40.5678 128.763 40.5678 127.076V97.9354C40.5678 96.2483 39.2013 94.8744 37.5232 94.8744H21.3852Z"
                fill="#121212"
              />
              <path
                d="M34.8058 99.0329H20.3148C19.0642 99.0329 18.0503 100.052 18.0503 101.31V124.241C18.0503 125.498 19.0642 126.517 20.3148 126.517H34.8058C36.0565 126.517 37.0703 125.498 37.0703 124.241V101.31C37.0703 100.052 36.0565 99.0329 34.8058 99.0329Z"
                fill="#F3F3F3"
              />
              <path
                d="M34.8058 126.809H20.3148C18.9058 126.809 17.76 125.657 17.76 124.241V101.31C17.76 99.893 18.9058 98.741 20.3148 98.741H34.8058C36.2148 98.741 37.3606 99.893 37.3606 101.31V124.241C37.3606 125.657 36.2148 126.809 34.8058 126.809ZM20.3148 99.3248C19.2271 99.3248 18.3406 100.216 18.3406 101.31V124.241C18.3406 125.334 19.2271 126.226 20.3148 126.226H34.8058C35.8936 126.226 36.78 125.334 36.78 124.241V101.31C36.78 100.216 35.8936 99.3248 34.8058 99.3248H20.3148Z"
                fill="#121212"
              />
              <path
                d="M32.9748 101.6C33.4084 101.6 33.7606 101.954 33.7606 102.39V123.824C33.7606 124.26 33.4084 124.614 32.9748 124.614C32.5413 124.614 32.189 124.26 32.189 123.824V102.39C32.189 101.954 32.5413 101.6 32.9748 101.6Z"
                fill="#095464"
              />
              <path
                d="M29.1039 101.6C29.5374 101.6 29.8897 101.954 29.8897 102.39V123.824C29.8897 124.26 29.5374 124.614 29.1039 124.614C28.6703 124.614 28.3181 124.26 28.3181 123.824V102.39C28.3181 101.954 28.6703 101.6 29.1039 101.6Z"
                fill="#095464"
              />
              <path
                d="M25.2348 101.6C25.6684 101.6 26.0206 101.954 26.0206 102.39V123.824C26.0206 124.26 25.6684 124.614 25.2348 124.614C24.8013 124.614 24.449 124.26 24.449 123.824V102.39C24.449 101.954 24.8013 101.6 25.2348 101.6Z"
                fill="#095464"
              />
              <path
                d="M21.3639 101.6C21.7974 101.6 22.1497 101.954 22.1497 102.39V123.824C22.1497 124.26 21.7974 124.614 21.3639 124.614C20.9303 124.614 20.5781 124.26 20.5781 123.824V102.39C20.5781 101.954 20.9303 101.6 21.3639 101.6Z"
                fill="#095464"
              />
              <path
                d="M34.0239 79.5365C34.0239 80.0171 33.6368 80.4063 33.1587 80.4063H29.2877C28.8097 80.4063 28.4226 80.0171 28.4226 79.5365C28.4226 79.0558 28.8097 78.6667 29.2877 78.6667H33.1587C33.6368 78.6667 34.0239 79.0558 34.0239 79.5365Z"
                fill="#095464"
              />
            </g>
            <defs>
              <clipPath id="clip0_76_264">
                <rect
                  width="222"
                  height="180"
                  fill="white"
                  transform="translate(0 -48)"
                />
              </clipPath>
            </defs>
          </svg>
        </div>
      </div>
      <div class="infos">
        <div class="passager">
          <div class="title">
            {{ $t("document.flightTicket.passenger.title") }}
          </div>
          <div class="passagerListe">
            <div class="contentPassagerliste head">
              <div class="cellulePassager firstCel">
                {{ $t("document.flightTicket.passenger.name") }}
              </div>
              <div class="cellulePassager">
                {{ $t("document.flightTicket.passenger.category") }}
              </div>
              <div v-if="infosTickets" class="cellulePassager lastCel">
                {{ $t("document.flightTicket.passenger.numberTicket") }}
              </div>
            </div>
            <div
              class="contentPassagerliste border-top"
              v-for="(item, index) in passagers"
              v-bind:key="index"
            >
              <div class="cellulePassager firstCel">
                {{
                  !haveGuests
                    ? item.guest.firstName + " " + item.guest.lastName
                    : item.firstName + " " + item.lastName
                }}
              </div>
              <div class="cellulePassager">
                {{
                  !haveGuests
                    ? $t(item.guest.ageClassType)
                    : $t(item.ageClassType)
                }}
              </div>
              <div v-if="infosTickets" class="cellulePassager lastCel">
                {{
                  $t("document.flightTicket.passenger.numberTicketInTable", {
                    numberTicket: item.ticketNumber,
                  })
                }}
              </div>
            </div>
          </div>
        </div>
        <div class="itineraire">
          <div class="title">
            {{ $t("document.flightTicket.itinerary.title") }}
          </div>
          <div class="flights">
            <div
              class="flightSolo bi-avoid"
              v-for="(item, index) in infosFlights"
              v-bind:key="index"
            >
              <div class="flightGo">
                <div class="flightGoTitle">
                  {{
                    index == 0
                      ? $t("document.flightTicket.itinerary.outboundFlight")
                      : index == infosFlights.length - 1
                      ? $t("document.flightTicket.itinerary.returnFlight")
                      : $t("document.flightTicket.itinerary.interIslandFlight")
                  }}
                  •
                  {{
                    _formatDate(
                      _parseDate(item.start, "dd/MM/yyyy HH:mm:ss"),
                      "EEEE dd MMMM yyyy"
                    ) | capitalize
                  }}
                </div>
                <div class="flightGoLocator">
                  Réf. compagnie aérienne
                  {{ item.companyLocators.join("- ") }}
                </div>
              </div>
              <div class="infosFlight">
                <div class="warn">
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M5.28906 1.28674C5.50294 1.16063 5.74671 1.09412 5.995 1.09412C6.2433 1.09412 6.48706 1.16063 6.70095 1.28674C6.91364 1.41213 7.08907 1.5919 7.20923 1.80754L10.7574 7.92943C10.7666 7.94528 10.7747 7.96176 10.7815 7.97874C10.8666 8.18898 10.8989 8.41683 10.8757 8.64243C10.8524 8.86802 10.7744 9.08451 10.6482 9.273C10.5221 9.46149 10.3518 9.61625 10.1521 9.7238C9.95247 9.83135 9.72952 9.8884 9.50274 9.88999L9.5 9.89001L2.5 9.89C2.49082 9.89 2.4817 9.88968 2.47267 9.88906C2.45697 9.89015 2.44104 9.89032 2.42492 9.88948C2.20349 9.87807 1.98801 9.81385 1.79645 9.70218C1.6049 9.59052 1.44284 9.43465 1.3238 9.24759C1.20476 9.06052 1.1322 8.8477 1.11217 8.62688C1.09215 8.40607 1.12523 8.18366 1.20867 7.97823C1.2155 7.96143 1.22349 7.94513 1.23258 7.92943L4.7808 1.80752C4.90095 1.59189 5.07637 1.41213 5.28906 1.28674ZM2.4724 9.11096C2.48152 9.11033 2.49072 9.11 2.5 9.11H9.49842C9.59755 9.10912 9.69497 9.08409 9.78226 9.03708C9.86988 8.98988 9.94463 8.92196 9.99997 8.83924C10.0553 8.75653 10.0896 8.66152 10.0998 8.56252C10.1092 8.47153 10.0979 8.37971 10.0671 8.29382L6.52869 2.18885C6.47593 2.0935 6.39867 2.01399 6.30481 1.95865C6.21094 1.90331 6.10397 1.87412 5.995 1.87412C5.88604 1.87412 5.77907 1.90331 5.6852 1.95865C5.59133 2.01399 5.51401 2.09347 5.46125 2.18881L5.45749 2.19561L1.92277 8.29409C1.89249 8.37792 1.88092 8.46747 1.88898 8.55643C1.89777 8.65333 1.92962 8.74673 1.98186 8.82882C2.0341 8.91091 2.10522 8.97932 2.18928 9.02832C2.27334 9.07733 2.36791 9.10551 2.46508 9.11052C2.46753 9.11065 2.46997 9.11079 2.4724 9.11096ZM6 4.11C6.2154 4.11 6.39 4.28461 6.39 4.5V5.5C6.39 5.71539 6.2154 5.89 6 5.89C5.78461 5.89 5.61 5.71539 5.61 5.5V4.5C5.61 4.28461 5.78461 4.11 6 4.11ZM6 7.11C6.2154 7.11 6.39 7.28461 6.39 7.5V7.505C6.39 7.72039 6.2154 7.895 6 7.895C5.78461 7.895 5.61 7.72039 5.61 7.505V7.5C5.61 7.28461 5.78461 7.11 6 7.11Z"
                      fill="#FF0000"
                    />
                  </svg>
                  <div class="text-warn">
                    {{
                      item.route.segments[0].aircraft.code == "TRN"
                        ? $t("document.flightTicket.itinerary.warnTrain")
                        : $t("document.flightTicket.itinerary.warn", {
                            airline: item.route.segments[0].airlineName,
                            duration: airportsDurations[index]
                              ? minutesToHours(airportsDurations[index])
                              : "3h",
                          })
                    }}
                  </div>
                </div>
                <div
                  class="oneFlight"
                  v-for="(fly, i) in item.route.segments"
                  v-bind:key="i"
                >
                  <div class="companyLogo">
                    <img
                      :src="'/airlines/square/' + fly.airlineCode + '.png'"
                    />
                  </div>
                  <div class="dot">
                    <v-icon x-small>mdi-adjust</v-icon>
                    <div>
                      <div></div>
                    </div>
                    <v-icon x-small>mdi-adjust</v-icon>
                  </div>
                  <div class="flightContent">
                    <div>
                      {{
                        _formatDate(
                          _parseDate(fly.start, "dd/MM/yyyy HH:mm:ss"),
                          "EEEE dd MMMM yyyy, HH:mm"
                        ) | capitalize
                      }}
                      •
                      <span style="font-weight: 500">{{
                        `${fly.fromName} (${fly.fromCode}${
                          fly.fromTerminal
                            ? ` Terminal ${fly.fromTerminal}`
                            : ""
                        }) `
                      }}</span>
                    </div>
                    <div>
                      {{ fly.airlineName }}<template v-if="fly.operatingAirlinecode != airlineCode"> ({{$t('operatingBy', {name:fly.operatingAirlinename})}})</template> •
                      {{ fly.airlineCode }}
                      {{ fly.flightNumber }}

                      {{
                        fly.aircraft.maker == null &&
                        fly.aircraft.model == null &&
                        fly.aircraft.variant == null
                          ? ""
                          : "•"
                      }}
                      {{ fly.aircraft.maker != null ? fly.aircraft.maker : "" }}
                      {{ fly.aircraft.model != null ? fly.aircraft.model : ""
                      }}{{
                        fly.aircraft.variant != null
                          ? "-" + fly.aircraft.variant
                          : ""
                      }}

                      • {{ $t("document.flightTicket.itinerary.duration") }} :
                      {{ convertPTTimeFormat(fly.duration) }}
                      • {{ $t(fly.travelClass) }}
                    </div>
                    <div>
                      {{
                        _formatDate(
                          _parseDate(fly.stop, "dd/MM/yyyy HH:mm:ss"),
                          "EEEE dd MMMM yyyy, HH:mm"
                        ) | capitalize
                      }}
                      •
                      <span style="font-weight: 500">{{
                        `${fly.toName} (${fly.toCode}${
                          fly.toTerminal ? ` Terminal ${fly.toTerminal}` : ""
                        }) `
                      }}</span>
                    </div>
                  </div>
                  <div
                    class="escale"
                    v-bind:class="{
                      escalePlus: !(i == item.route.segments.length - 1),
                      lessPadding: i == item.route.segments.length - 1,
                    }"
                  >
                    <template v-if="i == item.route.segments.length - 1">
                      <div style="width: 100%" class="d-flex align-center mt-1">
                        <template
                          v-if="hasBags(item.route.segments[0], 'checked')"
                        >
                          <span class="mr-1 d-flex align-center"
                            ><v-icon class="mx-1" x-small
                              >mdi-bag-checked</v-icon
                            >
                            {{
                              $t(
                                "document.flightTicket.itinerary.bags.checked"
                              )
                            }}:</span
                          >
                          <span
                            v-for="(d, key, index) in groupSegmentDetails(
                              item.route.segments[0]
                            )"
                            :key="'checked.' + key"
                          >
                            <span v-if="index > 0" class="mx-1">•</span>
                            <template
                              v-if="
                                d[0].bags &&
                                d[0].bags.checked &&
                                d[0].bags.checked.quantity > 0
                              "
                              >{{ d[0].bags.checked.quantity }} par
                              {{ $t(d[0].passengerType) }} (max 23kg)</template
                            >
                            <template
                              v-if="
                                d[0].bags &&
                                d[0].bags.checked &&
                                d[0].bags.checked.weight > 0
                              "
                              >{{ d[0].bags.checked.weight
                              }}{{ d[0].bags.checked.weightUnit }} par
                              {{ $t(d[0].passengerType) }}</template
                            >
                          </span>
                        </template>
                        <template
                          v-if="hasBags(item.route.segments[0], 'cabin')"
                        >
                          <span class="ml-3 mr-1 d-flex align-center"
                            ><v-icon class="mx-1" x-small
                              >mdi-bag-carry-on</v-icon
                            >
                            {{
                              $t("document.flightTicket.itinerary.bags.cabin")
                            }}:</span
                          >
                          <span
                            v-for="(d, key, index) in groupSegmentDetails(
                              item.route.segments[0]
                            )"
                            :key="'cabin.' + key"
                          >
                            <span v-if="index > 0" class="mx-1">•</span>
                            <template
                              v-if="
                                d[0].bags &&
                                d[0].bags.cabin &&
                                d[0].bags.cabin.quantity > 0
                              "
                              >{{ d[0].bags.cabin.quantity }}</template
                            >
                            <template
                              v-if="
                                d[0].bags &&
                                d[0].bags.cabin &&
                                d[0].bags.cabin.weight != null
                              "
                              >{{ d[0].bags.cabin.weight
                              }}{{ d[0].bags.cabin.weightUnit }}</template
                            >
                            par {{ $t(d[0].passengerType) }}
                          </span>
                        </template>
                      </div>
                    </template>

                    <template v-else>
                      {{
                        minutesToHours(
                          _duration(
                            _parseDate(fly.stop, "dd/MM/yyyy HH:mm:ss"),
                            _parseDate(
                              item.route.segments[i + 1].start,
                              "dd/MM/yyyy HH:mm:ss"
                            ),
                            "minute"
                          )
                        ) +
                        " " +
                        $t("document.flightTicket.itinerary.stopover") +
                        " • " +
                        fly.toName
                      }}
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="infosCompany bi-avoid">
          <ul>
            <li>
              {{ $t("document.flightTicket.information.1") }}
            </li>
            <li>
              {{ $t("document.flightTicket.information.2") }}
            </li>
            <li>
              {{ $t("document.flightTicket.information.3") }}
            </li>
          </ul>
        </div>
      </div>
      <span id="loaded"></span>
    </div>
  </div>
</template>

<script>
import { get, post } from "@/utils/api";
import GuestsMixin from "@/components/mixins/GuestsMixin";
import _ from "lodash";

export default {
  props: ["pnr"],
  data() {
    return {
      passagers: null,
      infosFlights: null,
      infosTickets: null,
      haveGuests: null,
      logo: null,
      airportsDurations: [],
    };
    /*_duration(fly.duration,'HH h mm min')*/
  },
  created() {
    this.init(this.pnr);
  },
  computed: {
    haveBaby() {
      return this.passagers.filter((g) => g.ageClassType == "Baby").length > 0;
    },
  },
  methods: {
    init(pnr) {
      const url = `/api/document/flight-ticket/${pnr}`;

      return get(url).json((data) => {
        this.infosFlights = data.flightData;
        this.logo = data.data.logoPath;
        this.airportsDurations = data.data.airportsDurations;

        this.infosTickets = this.infosFlights && this.infosFlights[0].tickets.length > 0;
        this.haveGuests = this.infosFlights[0].guests.length > 0;
        this.passagers = this.haveGuests
          ? this.infosFlights[0].guests
          : this.infosFlights[0].tickets;

        if (this.haveGuests) {
        let ticketNumber = ""
          this.passagers = this.passagers.map((p, index) => {
            if(this.infosFlights != null && this.infosFlights.length > 0 && this.infosFlights[0].tickets != null && this.infosFlights[0].tickets.length > 0) {
                const ticket = this.infosFlights[0].tickets.find(t => t.guest.id == p.id)
                ticketNumber = ticket && ticket.ticketNumber != null ? ticket.ticketNumber : ""
            }

            return {
              ...p,
              ...{
                ticketNumber: ticketNumber,
              },
            };
          });
        }
      });
    },
    minutesToHours(totalMinutes) {
      const hours = Math.trunc(totalMinutes / 60);
      const minutes = totalMinutes % 60;

      const minutesStr =
        minutes == 0 ? "" : minutes < 10 ? `0${minutes}min` : `${minutes}min`;
      const hourStr = hours == 0 ? "" : `${hours}h`;

      return hourStr + minutesStr;
    },
    convertPTTimeFormat(ptTime) {
      let time = ptTime.slice(2);
      if (ptTime.includes("H")) {
        let hours = time.split("H")[0];
        let minutes = time.split("H")[1].slice(0, -1);
        if (minutes == "") {
          return `${hours} h`;
        } else {
          return `${hours} h ${minutes} min`;
        }
      } else {
        let minutes = time.split("M")[0];
        return `${minutes} min`;
      }
    },
    groupSegmentDetails(segment) {
      return _.groupBy(segment.details, "passengerType");
    },
    hasBags(segment, bagType) {
      return segment.details.find((d) => d.bags && d.bags[bagType] != null);
    },
  },
};
</script>
<style scoped src="@/assets/pdf/css/default.less" lang="less"></style>
<style scoped>
.bg-white {
  width: 100%;
  height: 100%;
  background-color: #fff;
}
.ticket-container {
  width: 595px !important;
}

.content {
  width: 21cm;
  margin: 0 auto;
  background-color: #fff;
}

.header {
  height: 104px;
  width: 100%;
  padding: 32px 0 32px 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.header .logo {
  background-color: #fff;
  font-size: 10px;
  line-height: 12px;
  font-weight: 600;
  color: #e4e4e4;
  width: 105px;
  display: inline-block;
  text-align: center;
}

.header .logo > img {
  width: 100%;
  height: 100%;
}

.header .logo p {
  margin-bottom: 0px;
}

.title {
  margin-left: 16px;
}

.title :nth-child(1) {
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #1a1a1a;
  margin-bottom: 0px;
}

.title :nth-child(2) {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: rgba(26, 26, 26, 0.7);
  margin-bottom: 0px;
}

.infos {
  padding: 24px 40px 16px 40px;
  display: block;
  flex-direction: column;
  row-gap: 16px;
}

.passager {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.passager > .title,
.itineraire > .title {
  font-weight: 700;
  font-size: 14px !important;
  line-height: 14px;
  color: #1a1a1a;
  margin-left: 0px;
}

.passagerListe {
  border: solid 1px #ededed;
  border-radius: 6px;
}

.contentPassagerliste {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  column-gap: 1rem;
}

.cellulePassager {
  grid-column: span 4 / span 4;
  padding-top: 4px;
  padding-bottom: 4px;
}

.border-top {
  border-top: 1px solid #ededed;
  font-size: 13px;
  line-height: 13px;
  font-weight: 400;
  color: #1a1a1a;
}

.head {
  background-color: #f3f3f3;
  -webkit-print-color-adjust: exact;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  font-size: 13px;
  line-height: 13px;
  font-weight: 400;
  color: rgba(26, 26, 26, 0.7);
}

.firstCel {
  margin-left: 12px;
}

.lastCel {
  margin-right: 12px;
}

.itineraire {
  display: block;
  flex-direction: column;
  row-gap: 8px;
}

.itineraire > .title {
  margin: 16px 0 8px 0;
}

.flights {
  position: relative;
  display: block;
  flex-direction: column;
  row-gap: 8px;
}

.flightSolo {
  display: block;
  flex-direction: column;
  row-gap: 8px;
  border: 1px solid #dfdfdf;
  border-radius: 6px;
  margin-bottom: 15px;
}

.flightGo {
  background-color: #f3f3f3;
  -webkit-print-color-adjust: exact;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom: 1px solid #dfdfdf;
  padding: 8px 12px;
  font-weight: 500;
  font-size: 13px;
  line-height: 13px;
  color: #1a1a1a;
}

.flightGoTitle {
  width: 50%;
  display: inline-block;
}
.flightGoLocator {
  width: 50%;
  margin-left: auto;
  display: inline-block;
  text-align: right;
}

.infosFlight {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  padding: 12px;
}

.warn {
  background-color: rgba(255, 0, 0, 0.05);
  -webkit-print-color-adjust: exact;
  padding: 8px 12px;
  display: flex;
  align-items: flex-start;
  column-gap: 4px;
  border-radius: 6px;
}

.text-warn {
  color: #ff0000;
  font-weight: 400;
  font-size: 13px;
  line-height: 13px;
}

.oneFlight {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.companyLogo {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
}

.companyLogo > img {
  width: 24px;
  height: 24px;
}

.dot {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 12px;
}

.dot > div {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 50%;
}

.dot > div > div {
  width: 1px !important;
  margin-left: auto;
  margin-right: auto;
  border-left-width: 1px !important;
  border-right-width: 0px !important;
  border-top-width: 0px !important;
  border-bottom-width: 0px !important;
  border-style: dashed;
  height: 83.333333% !important;
  border-color: #86868b;
}

.flightContent {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.flightContent div:nth-child(1),
.flightContent div:nth-child(3) {
  color: #1a1a1a;
  font-size: 13px;
  line-height: 13px;
}

.flightContent div:nth-child(2) {
  color: rgba(26, 26, 26, 0.7);
  font-size: 13px;
  line-height: 13px;
}

.escale {
  display: flex;
  align-items: center;
  font-size: 13px;
  line-height: 13px;
  color: #1a1a1a;
  flex-basis: 100%;
  padding: 8px 0px 8px 64px;
  margin-top: 8px;
}

.escalePlus {
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-right-width: 0px;
  border-left-width: 0px;
  border-color: #d5d5d5;
  border-style: solid;
}

.lessPadding {
  padding-left: 0px !important;
  padding-bottom: 0px !important;
  border-top-width: 1px;
  border-right-width: 0px;
  border-left-width: 0px;
  border-bottom: 0px;
  border-color: #d5d5d5;
  border-style: solid;
  font-size: 13px;
  line-height: 13px;
  color: rgba(26, 26, 26, 0.7);
}

.infosCompany {
  font-size: 13px;
  line-height: 14px;
  color: #1a1a1a;
  padding: 8px 12px;
  background-color: #eef1f1;
  -webkit-print-color-adjust: exact;
  border-radius: 6px;
  margin-top: 16px;
}
.infosCompany li {
  margin-top: 5px;
  margin-bottom: 5px;
}
</style>
