<template>
  <layout :drawer="false">
    <template slot="content">
      <v-container fluid>
        <v-data-table
          :headers="listing.headers"
          :loading="listing.loading"
          :items="listing.items"
          class="elevation-1 tbl"
          no-data-text="Il n'y a pas de chèque-cadeau à afficher"
          @click:row="openDetails"
        >
          <template #item.creationDate="{ item }">
            {{ item.creationDate ? item.creationDate : "-" }}
          </template>
          <template #item.amount="{ item }">
            {{ (item.amount ? item.amount : 0) | currency("EUR") }}
          </template>
        </v-data-table>

        <v-dialog v-model="dialog" width="800">
          <v-card>
            <v-card-title>Validation du chèque-cadeau</v-card-title>
            <v-card-text>
              <v-row class="pl-3 mb-2">
                Vous allez confirmer le chèque-cadeau avec les incentives
                suivants:
              </v-row>

              <v-data-table
                :headers="details.headers"
                :loading="details.loading"
                :items="details.items"
                dense
                class="elevation-1 tbl"
                hide-default-footer
              >
                <template #item.amount="{ item }">
                  {{ (item.amount ? item.amount : 0) | currency("EUR") }}
                </template>
              </v-data-table>
            </v-card-text>
            <v-card-actions>
              <v-row justify="end">
                <v-col cols="2">
                  <smart-btn @click.native="confirmGiftCard()"
                    >Confirmer</smart-btn
                  ></v-col
                >
                <v-col cols="2" class="mr-4">
                  <smart-btn secondary @click.native="dialog = false"
                    >Annuler</smart-btn
                  ></v-col
                >
              </v-row>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-container>
    </template>
  </layout>
</template>


<script>
import Layout from "@/views/Layout";
import { get, post } from "@/utils/api";
import { EventBus } from "@/components/commons/event-bus";

export default {
  name: "GiftCardsList",
  components: {
    Layout,
  },
  data() {
    return {
      dialog: false,
      hotels: [],
      loadingHotels: false,
      selectedGiftcard: null,
      listing: {
        headers: [
          {
            text: "Date de demande",
            value: "creationDate",
            align: "left",
            sortable: true,
            width: 30,
          },
          {
            text: "Agent",
            value: "agentName",
            align: "left",
            sortable: true,
            width: 30,
          },
          {
            text: "Agence",
            value: "agencyName",
            align: "left",
            sortable: true,
            width: 30,
          },
          {
            text: "Montant",
            value: "amount",
            align: "left",
            sortable: true,
            width: 30,
          },
        ],
        loading: false,
        rowsPerPageItems: [{ text: "Tous", value: -1 }, 50, 200],
        pagination: {
          options: {
            page: 1,
            itemsPerPage: 200,
            sortBy: [],
            sortDesc: [false],
          },
        },
        items: [
        ],
      },
      details: {
        loading: false,
        headers: [
          {
            text: "Dossier",
            value: "bookingId",
            align: "left",
            sortable: false,
            width: 30,
          },
          {
            text: "Hotel",
            value: "hotel",
            align: "left",
            sortable: false,
            width: 30,
          },
          {
            text: "Montant",
            value: "amount",
            align: "left",
            sortable: false,
            width: 30,
          },
        ],
        items: [],
      },
    };
  },
  created() {
    this.loadGiftcards();
  },
  methods: {
    loadGiftcards() {
      const url = "/api/giftcard";
      get(url).json((data) => {
        this.listing.items = data;
      });
    },
    openDetails(item) {
      this.dialog = true;
      this.details.items = item.incentives;
      this.selectedGiftcard = item;
    },
    confirmGiftCard() {
      const url = "/api/giftcard/validate";

      post(url, this.selectedGiftcard)
        .badRequest((err) => {
          EventBus.$emit("toaster-msg", err);
        })
        .json(() => {
          this.dialog = false;
          this.selectedGiftcard = null;
          this.loadGiftcards();
          EventBus.$emit("toaster-msg", "Chèque-cadeau validé");
        });
    },
  },
};
</script>