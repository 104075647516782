<template>
	<div :style="getStyle">

		<!-- <span>{{newPrice}} {{$t('EUR-symbol')}}</span>
		<span>{{basePrice}} {{$t('EUR-symbol')}}</span>-->
		<div :style="getStyle">{{renderDiff}}</div>
		<div v-if="showBaseprice" :class="classBaseprice" :style="getStyle">Soit {{newPrice}}
			{{$t(currencycode+'-symbol')}}
		</div>
	</div>
</template>

<script>
	export default {
		name: "PriceDiff",
		mixins: [],
		props: {
			newPrice: {
				type: Number,
				required: true
			},
			basePrice: {
				type: Number,
				required: true
			},
			currencycode: {
				type: String,
				required: false,
				default: "EUR"
			},
			cssdisplay: {
				type: String,
				required: false,
				default: "block"
			},
			classBaseprice: {
				type: String,
				required: false,
				default: "caption font-weight-medium grey--text text--lighten-1"
			},
			showBaseprice: {
				type: Boolean,
				required: false,
				default: true
			}
		},
		data: function () {
			return {}
		},
		created() {
		},
		watch: {},
		computed: {
			getStyle() {
				return {
					display: this.cssdisplay
				}
			},
			diff() {
				return this.newPrice - this.basePrice
			},
			renderDiff() {
				const p = this.diff
				const sign = p >= 0 ? "+" : ""
				return `${sign}${p} ${this.$t(this.currencycode + '-symbol')}`
			}
		},
		methods: {}
	}
</script>

<style scoped>

</style>
