<template>
  <smart-dialog v-model="dialog" :max-width="options.width" :title="title" @close="cancel">
    <div class="pa-3" v-if="options.isHtml" v-html="message"></div>
    <div class="pa-3" v-else v-show="!!message">{{ message }}</div>
    <v-card-actions class="actions-btn">
      <v-spacer></v-spacer>
      <smart-btn primary @click.native="agree">{{options.ok}}</smart-btn>
    </v-card-actions>
  </smart-dialog>
</template>

<script>
import DialogMixin from "@/components/mixins/DialogMixin";
//import { EventBus } from "@/components/commons/event-bus";

export default {
  data() {
    return {};
  },
  mixins: [DialogMixin],
  methods: {
    open(title, message, options) {
      this.dialog = true;
      this.title = title;
      this.message = message;
      this.options = Object.assign(this.options, options);

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },

    agree() {
      this.resolve(true);
      this.dialog = false;
    },
  },
};
</script>
