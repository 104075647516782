<template>
  <layout :drawer="true" :drawerShow="_isDesktop()" :drawerWidth="300">
    <template slot="drawer">
      <v-form @submit.prevent="loadData">
        <v-row no-gutters class="pt-2 pr-1">
          <v-col cols="12" class="mb-2">
            <v-subheader light class="pa-0 mb-0 mx-0 form-subheader"
            >Dates de réservation</v-subheader
            >
            <v-divider></v-divider>
          </v-col>
          <v-col class="pr-1" cols="6">
            <smart-picker
                v-model="searchform.bookingDateStart"
                :stopDate="searchform.bookingDateStop"
                :inputOpts="{
                label: $t('begin'),
                clearable: true,
                placeholder: ' ',
              }"
            ></smart-picker>
          </v-col>
          <v-col cols="6" class="pr-1">
            <smart-picker
                v-model="searchform.bookingDateStop"
                :startDate="searchform.bookingDateStart"
                :inputOpts="{
                label: $t('end'),
                clearable: true,
                placeholder: ' ',
              }"
            ></smart-picker>
          </v-col>
        </v-row>
        <smart-btn
            :loading="loading"
            class="mt-3"
            type="submit"
            block
            primary
        >Rechercher</smart-btn
        >
        <smart-btn block tertiary @click.native="clearSearchform()"
        >Réinitialiser la recherche</smart-btn
        >
      </v-form>
    </template>
    <template slot="content">
      <BookingsListTable ref="bookingListTable" @loading="setLoading" />
    </template>
  </layout>
</template>
<script>
import Layout from "@/views/Layout";
import {startOfMonth} from "date-fns";
import {post} from "@/utils/api";
import {EventBus} from "@/components/commons/event-bus";
import Availability from "@/components/commons/Availability.vue";
import SiteLabel from "@/components/commons/SiteLabel.vue";
import ExportMixin from "@/components/mixins/ExportMixin";
import BookingsListTable from "../../components/commons/BookingsListTable";

export default {
  name: "CanceledBooking",
  mixins: [ExportMixin],
  components: {
    BookingsListTable,
    SiteLabel,
    Availability,
    Layout,
  },
  data() {
    return {
      booking: null,
      headers:[
        {
          text: "SITE",
          value: "siteName",
          align: "left",
        },
        {
          text: "RÉSA",
          value: "bookingDate",
          align: "left",
        },
        {
          text: "SÉJOUR",
          value: "startDate",
          align: "left",
          width: "10%",
        },
        {
          text: "CLIENT",
          value: "customer",
          align: "left",
        },
        {
          text: "DESTINATION",
          value: "hotelWithDesti",
          align: "left",
          sortable: false,
        },
        {
          text: "VOLS",
          value: "airlineCodes",
          align: "left",
          sortable: false,
        },
        {
          text: "TRSF.",
          value: "transferName",
          align: "left",
          sortable: false,
        },
        {
          text: "MONTANT",
          value: "ca",
          align: "right",
          sortable: false,
        },
        {
          text: "STATUTS",
          value: "bookingStatus",
          align: "left",
          sortable: false,
        },
        {
          text: "",
          value: "locked",
          sortable: false,
        },
      ],
      items:[],
      defaultSearchform: {
        bookingDateStart: this._formatDate(
            startOfMonth(new Date()), "dd/MM/yyyy"
        ),
        bookingDateStop: this._nowFormatted("dd/MM/yyyy"),
      },
      searchform: null,
      loading: false
    };
  },
  created() {
    this.init()
  },
  methods:{
    init(){
      this.clearSearchform()
    },
    loadData(){
      this.$nextTick(() => {
        this.$refs.bookingListTable.load("/api/widget/bookings/canceled", this.searchform);
      })
    },
    setLoading(loading) {
      this.loading = loading;
    },
    clearSearchform() {
      this.searchform = {
        ...this.defaultSearchform,
      };
      this.loadData()
    },
  }
}
</script>