<template>
  <v-row dense>
    <v-col
      cols="12"
      class="mt-0"
      v-if="prestation.voucherIds && prestation.voucherIds.length > 0"
    >
      <file-items :items="prestation.voucherIds">
        <template v-slot:default="file">
          <file-item :download-url="`/document/voucher/${file.item}`" ext="pdf"
            >Voucher #{{ file.item }}
          </file-item>
        </template>
      </file-items>
    </v-col>
    <v-col
      v-if="prestation.documentsNames && prestation.documentsNames.length > 0"
      cols="12"
    >
      <file-items :items="prestation.documentsNames">
        <template v-slot:default="file">
          <file-item
            :download-url="
              `/api/file/download/${file.item}?bookingid=${bookingid}`
            "
            :remove-url="
              `/api/file/delete/voucherdoc/${prestation.id}/${file.item}`
            "
            @deleted="filesChanged"
            ext="pdf"
            >{{ documentName(file.item) }}
          </file-item>
        </template>
      </file-items>
    </v-col>
    <v-col cols="12">
      <file-items
        :items="[prestation.pnr]"
        v-if="
          prestation.kind == 'FlightPrestation' &&
            prestation.emittedDate &&
            prestation.pnr != null &&
            prestation.pnr != ''
        "
      >
        <template v-slot:default="file">
          <file-item
            :download-url="
              `/document/flight-ticket/${prestation.pnr}?inline=true`
            "
            ext="pdf"
            >Billet électronique #{{ file.item }}</file-item
          >
        </template>
      </file-items>
    </v-col>
  </v-row>
</template>

<script>
import { post } from "@/utils/api";
import FileItems from "./FileItems";
import FileItem from "./FileItem";
import { EventBus } from "@/components/commons/event-bus";

export default {
  name: "BookingPrestaVoucher",
  components: { FileItem, FileItems },
  props: {
    bookingid: {
      type: Number,
      required: true,
    },
    prestation: {
      type: Object,
      required: true,
    },
  },
  methods: {
    documentName(filePath) {
      const dirs = filePath.split("/");
      return dirs[dirs.length - 1];
    },
    filesChanged() {
      EventBus.$emit("reloadbooking", {});
    },
  },
};
</script>

<style scoped></style>
