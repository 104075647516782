<template>
	<smart-dialog v-model="task.editDialog" title="Édition">
		<task-edit :task="task" @saved="task.editDialog = false; save()" @change="change"></task-edit>
	</smart-dialog>
</template>

<script>
import TaskEdit from "@/components/commons/TaskEdit";

export default {
	name: "TodoItem",
	components: { TaskEdit },
	props: {
		task: {
			type: Object, required: true
		}
	},
	data() {
		return {
			editDialog: false
		}
	},
	created() {
	},
	methods: {
		openBooking(id) {
			let route = this.$router.resolve({ name: 'Booking', params: { id: id } })
			window.open(route.href, "_blank");
		},
		change() {
			this.$emit('change', {})
		},
		save() {
			this.$emit('save', {})
		}
	}
}
</script>