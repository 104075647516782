<template>
  <layout :drawerShow="true" :drawerWidth="350">
    <template slot="nav"></template>
    <template slot="nav-tools">
      <v-btn text @click="toggleAll" class="d-none d-sm-flex"
      >Tout sélectionner
      </v-btn
      >
      <v-btn
          class="primaryclass d-none d-sm-flex ml-2"
          :disabled="listing.selected.length == 0"
          @click="tagDialog.dialog = true"
      >Etiquetter la sélection
      </v-btn
      >
      <v-btn
          class="primary d-none d-sm-flex ml-2"
          :disabled="listing.selected.length == 0"
          @click="mailDialog.dialog = true"
      >Email sélection
      </v-btn
      >
      <v-btn
          class="primary d-none d-sm-flex ml-2 mr-2"
          :disabled="listing.selected.length == 0"
          @click="smsDialog.dialog = true"
      >SMS sélection
      </v-btn
      >
    </template>
    <template slot="drawer">
      <v-form ref="searchform" @submit.prevent="search">
        <v-row no-gutters>
          <v-col cols="12">
            <v-subheader light class="pa-0 ma-0 form-subheader"
            >Séjour
            </v-subheader
            >
            <v-divider></v-divider>
          </v-col>
          <v-col class="pr-1" cols="6">
            <smart-picker
                v-model="searchQuery.tripDateStart"
                :inputOpts="{ placeholder: ' ' }"
                :next-picker="$refs.tripDateStop"
                :rules="rules.nonEmpty()"
                label="Date de début"
                placeholder=" "
            />
          </v-col>
          <v-col class="pr-1" cols="6">
            <smart-picker
                v-model="searchQuery.tripDateStop"
                :inputOpts="{ placeholder: ' ' }"
                :rules="rules.nonEmpty()"
                ref="tripDateStop"
                label="Date de fin"
                placeholder=" "
            />
          </v-col>
          <v-divider></v-divider>
          <v-col cols="12">
            <v-subheader light class="pa-0 ma-0 mt-4 form-subheader"
            >Séjour débutant entre ces dates
            </v-subheader
            >
            <v-divider></v-divider>
          </v-col>
          <v-col class="pr-1" cols="6">
            <smart-picker
                v-model="searchQuery.travelBegins.start"
                :inputOpts="{ placeholder: ' ' }"
                :next-picker="$refs.travelBeginsStop"
                :rules="rules.nonEmpty()"
                label="Début"
                placeholder=" "
            />
          </v-col>
          <v-col class="pr-1" cols="6">
            <smart-picker
                v-model="searchQuery.travelBegins.stop"
                :inputOpts="{ placeholder: ' ' }"
                :rules="rules.nonEmpty()"
                ref="travelBeginsStop"
                label="Fin"
                placeholder=" "
            />
          </v-col>
          <v-divider></v-divider>
          <v-col cols="12">
            <v-subheader light class="pa-0 ma-0 mt-4 form-subheader"
            >Séjour se terminant entre ces dates
            </v-subheader
            >
            <v-divider></v-divider>
          </v-col>
          <v-col class="pr-1" cols="6">
            <smart-picker
                v-model="searchQuery.travelEnds.start"
                :inputOpts="{ placeholder: ' ' }"
                :next-picker="$refs.travelEndsStop"
                :rules="rules.nonEmpty()"
                label="Début"
                placeholder=" "
            />
          </v-col>
          <v-col class="pr-1" cols="6">
            <smart-picker
                v-model="searchQuery.travelEnds.stop"
                :inputOpts="{ placeholder: ' ' }"
                :rules="rules.nonEmpty()"
                ref="travelEndsStop"
                label="Fin"
                placeholder=" "
            />
          </v-col>
          <v-divider></v-divider>

          <v-col cols="12">
            <v-subheader light class="pa-0 mt-4 mb-0 mx-0 form-subheader"
            >Vols
            </v-subheader
            >
            <v-divider></v-divider>
          </v-col>
          <v-col class="pr-1" cols="6">
            <smart-picker
                v-model="searchQuery.flightDateStart"
                :inputOpts="{ placeholder: ' ' }"
                :next-picker="$refs.flightDateStop"
                label="Date de fin"
                placeholder=" "
            />
          </v-col>
          <v-col class="pr-1" cols="6">
            <smart-picker
                v-model="searchQuery.flightDateStop"
                :inputOpts="{ placeholder: ' ' }"
                ref="flightDateStop"
                label="Date de fin"
                placeholder=" "
            />
          </v-col>

          <v-col class="pr-1 mt-2" cols="4">
            <v-text-field
                v-model="searchQuery.flightCode"
                label="Numéro de vol"
                placeholder=" "
                hide-details
                @input="
                searchQuery.flightCode = searchQuery.flightCode.toUpperCase()
              "
            />
          </v-col>
          <v-col class="pr-1 mt-2" cols="4">
            <v-text-field
                v-model="searchQuery.company"
                label="Compagnie"
                placeholder=" "
                hide-details
                @input="searchQuery.company = searchQuery.company.toUpperCase()"
            />
          </v-col>
          <v-col class="pr-1 mt-2" cols="4">
            <v-text-field
                v-model="searchQuery.flightAircraft"
                label="Avion"
                placeholder=" "
                hide-details
                @input="
                searchQuery.flightAircraft =
                  searchQuery.flightAircraft.toUpperCase()
              "
            />
          </v-col>

          <v-col cols="12">
            <v-subheader light class="pa-0 mt-5 mb-0 mx-0 form-subheader"
            >Aéroports
            </v-subheader
            >
            <v-divider></v-divider>
          </v-col>
          <v-col class="pr-1" cols="6">
            <v-text-field
                v-model="searchQuery.airportFrom"
                label="De"
                placeholder=" "
                @input="
                searchQuery.airportFrom = searchQuery.airportFrom.toUpperCase()
              "
                hide-details
            />
          </v-col>
          <v-col class="pr-1" cols="6">
            <v-text-field
                v-model="searchQuery.airportTo"
                label="À"
                placeholder=" "
                @input="
                searchQuery.airportTo = searchQuery.airportTo.toUpperCase()
              "
                hide-details
            />
          </v-col>

          <v-divider class="my-4"/>

          <v-col cols="12">
            <v-subheader light class="pa-0 mt-4 mb-0 mx-0 form-subheader"
            >Prestation
            </v-subheader
            >
            <v-divider></v-divider>
          </v-col>
          <v-col class="pr-1" cols="6">
            <smart-picker
                v-model="searchQuery.prestationDateStart"
                :inputOpts="{ placeholder: ' ' }"
                :next-picker="$refs.prestationStop"
                label="Date de début"
            />
          </v-col>
          <v-col class="pr-1" cols="6">
            <smart-picker
                v-model="searchQuery.prestationDateStop"
                :inputOpts="{ placeholder: ' ' }"
                ref="prestationStop"
                label="Date de fin"
                placeholder=" "
            />
          </v-col>
          <v-col cols="12" class="mt-1">
            <v-select
                v-model="searchQuery.destination"
                :items="destinationList"
                item-value="id"
                item-text="name"
                placeholder=" "
                label="Destination"
                hide-details
                clearable
            />
          </v-col>
          <v-col cols="12" class="mt-1">
            <v-autocomplete
                v-model="searchQuery.hotel"
                :items="hotelList"
                placeholder=" "
                item-value="id"
                item-text="name"
                label="Hotel"
                hide-details
                clearable
            />
          </v-col>
          <v-col cols="12" class="mt-1">
            <v-autocomplete
                v-model="searchQuery.supplierSelect"
                :items="supplierList"
                item-value="id"
                item-text="name"
                placeholder=" "
                label="Fournisseurs"
                hide-details
                clearable
            />
          </v-col>
          <v-col cols="12" class="mt-5">
            <v-select
                v-model="searchQuery.site"
                :items="sites"
                item-value="id"
                item-text="name"
                label="Site"
                hide-details
                placeholder=" "
                clearable
            />
          </v-col>
        </v-row>
        <smart-btn
            :loading="listing.loading"
            type="submit"
            class="mt-5"
            block
            primary
        >Rechercher
        </smart-btn
        >
      </v-form>
    </template>

    <template slot="content">
      <v-container fluid>
        <v-row>
          <v-col cols="12">
            <v-btn
                @click="copy"
                small
                depressed
                :disabled="this.listing.items.length == 0"
            >
              <v-icon small>mdi-clipboard-outline</v-icon>
              Copier
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <div>Total Pax: {{ totalGuests }}</div>
            <v-data-table
                v-model="listing.selected"
                :loading="listing.loading"
                :headers="listing.headers"
                :items="listing.items"
                :footer-props="{
                'items-per-page-options': listing.rowsPerPageItems,
                'items-per-page-text': 'Lignes par page',
              }"
                class="tbl"
                show-select
                no-data-text="0 résultats"
            >
              <template #item.siteName="{ value }">
                <site-label :sitename="value"></site-label>
              </template>
              <template #item.startDate="{ value }">
                {{
                  _parseAndFormatDate(
                      value,
                      datePatternConfig.serverLocalDateTime,
                      datePatternConfig.serverLocalDate
                  )
                }}
              </template>
              <template #item.stopDate="{ value }">
                {{
                  _parseAndFormatDate(
                      value,
                      datePatternConfig.serverLocalDateTime,
                      datePatternConfig.serverLocalDate
                  )
                }}
              </template>
              <template #item.flights="{ item, value }">
                <span v-if="value && value.length > 0">
                  {{ value.join("\n ") }}
                </span>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
      <smart-dialog
          v-model="tagDialog.dialog"
          :title="`Etiquetter ${listing.selected.length} dossiers`"
      >
        <v-row class="ma-2 pa-2">
          <v-col cols="4">
            <v-subheader class>Etiquetter les dossiers avec:</v-subheader>
          </v-col>
          <v-col cols="4">
            <v-select
                v-model="tagDialog.selectedTags"
                :items="tagDialog.tags"
                item-value="id"
                item-text="name"
                multiple
            ></v-select>
          </v-col>
        </v-row>
        <template slot="actions">
          <smart-btn primary @click.native="tagging">Appliquer</smart-btn>
        </template>
      </smart-dialog>
      <smart-dialog
          v-model="mailDialog.dialog"
          title="Envoi d'email aux clients"
      >
        <mail-composer
            :loading="mailDialog.loading"
            :show-internal-attachment="false"
            :show-external-attachment="false"
            @send="sendEmails"
            :tos="mailDialog.tmpTos"
            :subject="mailDialog.subject"
            :message="mailDialog.message"
        ></mail-composer>
      </smart-dialog>

      <smart-dialog v-model="smsDialog.dialog" title="Envoi de SMS aux clients">
        <sms-composer
            v-if="smsDialog.dialog"
            :loading="smsDialog.loading"
            :list="listing.selected"
            @send="sendSmsBulk"
        ></sms-composer>
      </smart-dialog>
    </template>
  </layout>
</template>

<script>
import Layout from "@/views/Layout";
import SiteLabel from "@/components/commons/SiteLabel";
import {get, post} from "@/utils/api";
import {EventBus} from "@/components/commons/event-bus";
import clipboard from "../../utils/clipboard";
import MailComposer from "../../components/commons/MailComposer";
import SmsComposer from "../../components/commons/SmsComposer";
import FormMixin from "@/components/mixins/FormMixin";

export default {
  name: "PaxOnPlaceList",
  mixins: [FormMixin],
  components: {MailComposer, Layout, SiteLabel, SmsComposer},
  data() {
    return {
      supplierList: [],
      companyList: [],
      destinationList: [],
      hotelList: [],
      sites: [],
      searchQuery: {
        flightCode: "",
        airportFrom: "",
        airportTo: "",
        flightAircraft: "",
        company: "",
        site: null,
        destination: null,
        hotel: null,
        supplierSelect: null,
        tripDateStart: this._formatDate(new Date(), "dd/MM/yyyy"),
        tripDateStop: this._formatDate(new Date(), "dd/MM/yyyy"),
        travelBegins: {
          start: null,
          stop: null,
        },
        travelEnds: {
          start: null,
          stop: null,
        },
        flightDateStart: null,
        flightDateStop: null,
        prestationDateStart: null,
        prestationDateStop: null,
      },

      listing: {
        loading: false,

        rowsPerPageItems: [{text: "Tous", value: -1}, 50, 100, 300, 500],
        headers: [
          {text: "", value: "selectable", align: "left"},
          {text: "Site", value: "siteName", align: "left"},
          {text: "Dossier", value: "bookingId", align: "left"},
          //{text: 'Agence', value: 'ref', align: 'left', width: 40},
          {text: "Pax nb", value: "guestNb", align: "left"},
          {text: "Pax leader", value: "guestName", align: "left"},
          {text: "Téléphone", value: "originalPhone", align: "left"},
          {text: "Email", value: "email", align: "left"},
          {
            text: "Debut",
            value: "startDate",
            align: "left",
            sort: this.sortByDateTime,
          },
          {
            text: "Fin",
            value: "stopDate",
            align: "left",
            sort: this.sortByDateTime,
          },
          {text: "Hotel", value: "hotels", align: "left"},
          {text: "Vols", value: "flights", align: "left"},
          {text: "Transfers", value: "transfers", align: "left"},
        ],
        items: [],
        totalItems: 0,
        selected: [],
      },

      tagDialog: {
        dialog: false,
        tags: [],
        selectedTags: [],
      },
      mailDialog: {
        dialog: false,
        loading: false,
        tmpTos: ["contact@hotelissima.com"],
        subject: "",
        message: "",
        tos: ["contact@hotelissima.com"],
      },
      smsDialog: {
        dialog: false,
        loading: false,
        message: "",
      },
    };
  },
  computed: {
    totalGuests() {
      return this.listing.items.reduce((total, item) => total + item.guestNb, 0)
    }
  },
  created() {
    this.init();
  },
  watch: {
    "searchQuery.tripDateStart"(newVal) {
      if (!this.searchQuery.tripDateStop) {
        this.searchQuery.tripDateStop = newVal;
      }
    },
    "searchQuery.tripDateStop"(newVal) {
      if (!this.searchQuery.tripDateStart) {
        this.searchQuery.tripDateStart = newVal;
      }
    },
    "searchQuery.flightDateStart"(newVal) {
      if (!this.searchQuery.flightDateStop) {
        this.searchQuery.flightDateStop = newVal;
      }
    },
    "searchQuery.flightDateStop"(newVal) {
      if (!this.searchQuery.flightDateStart) {
        this.searchQuery.flightDateStart = newVal;
      }
    },
    "searchQuery.prestationDateStart"(newVal) {
      if (!this.searchQuery.prestationDateStop) {
        this.searchQuery.prestationDateStop = newVal;
      }
    },
    "searchQuery.prestationDateStop"(newVal) {
      if (!this.searchQuery.prestationDateStart) {
        this.searchQuery.prestationDateStart = newVal;
      }
    },
  },
  methods: {
    init() {
      const supplierUrl = "/api/supplier/get/forprestation";
      const destinationUrl = "/api/search/destinations";
      const hotelUrl = "/api/search/hotels";

      const loadSites = get("/api/search/sites").json((sites) => {
        this.sites = sites;
      });

      const loadSuppliers = get(supplierUrl)
          .badRequest((err) => {
            EventBus.$emit("toaster-msg", err.toString());
          })
          .json((r) => {
            this.supplierList = r;
          });

      const loadHotels = get(hotelUrl)
          .badRequest((err) => {
            EventBus.$emit("toaster-msg", err.toString());
          })
          .json((r) => {
            this.hotelList = r;
          });

      const loadDestinations = get(destinationUrl)
          .badRequest((err) => {
            EventBus.$emit("toaster-msg", err.toString());
          })
          .json((r) => {
            this.destinationList = r;
          });

      const loadTags = get("/api/search/tags").json((tags) => {
        this.tagDialog.tags = tags;
      });


      Promise.all([
        loadSites,
        loadSuppliers,
        loadHotels,
        loadDestinations,
        loadTags
      ]).then(() => {
        if (typeof this.$route.query.search !== "undefined")
          this.initSearchform();
        this.search();
      });

    },
    initSearchform() {
      const qs = new URLSearchParams(window.location.search);
      const search = (() => {
        try {
          return JSON.parse(qs.get("search"));
        } catch (e) {
          return null;
        }
      })();

      if (search) {
        this.searchQuery = search;
      }
    },

    search() {
      if (!this.listing.loading) {
        this.listing.loading = true;
        this.listing.selected = [];

        window.history.pushState(
            "",
            "",
            "?search=" + JSON.stringify(this.searchQuery)
        );

        return post("/api/booking/pax-on-place", this.searchQuery)
            .badRequest((err) => {
              EventBus.$emit("toaster-msg", err.toString());
            })
            .json((data) => {
              this.listing.items = data.map((i) => {
                return {...i, ...{id: i.bookingId}};
              });
            })
            .finally(() => {
              this.listing.loading = false;
            });
      }
    },
    copy() {
      const rows = this.listing.items.map((i) => {
        var pnrs = [
          ...new Set(
              [...i.flights.join("").matchAll(/\[([a-zA-Z0-9]*)\]/g)].map(
                  (pnr) => pnr[1]
              )
          ),
        ];

        return [
          i.siteName,
          i.bookingId,
          i.guestName,
          i.originalPhone,
          i.email,
          this._parseAndFormatDate(
              i.startDate,
              this.datePatternConfig.serverLocalDateTime,
              this.datePatternConfig.serverLocalDate
          ),
          this._parseAndFormatDate(
              i.stopDate,
              this.datePatternConfig.serverLocalDateTime,
              this.datePatternConfig.serverLocalDate
          ),
          i.hotels.join(", "),
          i.flights.join(", "),
          pnrs.join(", "),
          i.transfers.join(",")
        ].join("\t ");
      });
      clipboard.copyTextToClipboard(rows.join("\n"));
    },
    toggleAll() {
      if (this.listing.selected.length) this.listing.selected = [];
      else this.listing.selected = this.listing.items.slice();
    },
    tagging() {
      const obj = {
        bookingIds: this.listing.selected.map((t) => t.bookingId),
        tagIds: this.tagDialog.selectedTags,
      };

      post("/api/tags/tagging", obj).res(() => {
        EventBus.$emit("toaster-msg", "Etiquettes appliquées");
      });
    },
    sendEmails(email) {
      this.mailDialog.loading = true;
      const sends = this.listing.selected.map((booking) => {
        const richEmail = {
          ...email,
          ...{to: [booking.email]},
          ...{cc: ["contact@hotelissima.com"]},
        };

        if (booking.id < 300000) {
          return post(`/api/mail/sendOldCustomer/${booking.id}`, richEmail);
        } else {
          return post(`/api/mail/sendCustomer/${booking.id}`, richEmail);
        }
      });
      Promise.all(sends).then(() => {
        this.mailDialog.loading = false;
        this.mailDialog.dialog = false;
      });
    },
    sortByDateTime(a, b) {
      return this._sortDates(a, b, this.datePatternConfig.serverLocalDateTime);
    },
    sendSmsBulk(sms) {
      this.smsDialog.loading = true;
      const smsList = this.listing.selected.map((booking) => {
        return {
          bookingId: booking.id,
          clientEmail: booking.email,
          siteId: booking.siteId,
          message: sms.message,
        };
      });

      post("/api/sms/bulk", smsList).res((r) => {
        if (r.ok) {
          EventBus.$emit("toaster-msg", "Sms envoyés");
        } else {
          EventBus.$emit("toaster-error", "Erreur lors de l'envoi");
          console.error("Error", r)

        }
        this.smsDialog.loading = false;
        this.smsDialog.dialog = false;
      });
    },
  },
};
</script>

<style scoped></style>
