<template>
  <v-row dense @mouseenter="hoverDl" @mouseleave="leaveDl">
    <v-col cols="12">
      <v-card >
        <v-icon @click="download" size="18" :color="iconDl" class="pa-1 mr-0">{{ fileType }}</v-icon>
        <v-card-title @click="download" class="subtitle-2 pa-1 ma-1" :class="containerDlClass">
          <slot></slot>
        </v-card-title>

        <v-card-actions>
          <v-btn v-visible="showRm" icon>
            <v-icon color="red" size="18" class="pa-0 mr-0 notransition" @click="remove">mdi-delete</v-icon>
          </v-btn>
          <v-spacer></v-spacer>

          <v-btn icon :class="containerDlClass">
            <v-icon @click="download" size="18" :color="iconDl" class="pa-0 mr-0">mdi-download</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { get } from "@/utils/api";

export default {
  name: "FileItem",
  props: {
    downloadUrl: {
      type: String,
      required: true,
    },
    removeUrl: {
      type: String,
      required: false,
    },
    ext: {
      type: String,
      required: false,
    },
  },
  mixins: [],
  data() {
    return {
      containerDlClass: "default",
      iconDl: "",

      showRm: false,
    };
  },
  directives: {
    visible: function (el, binding) {
      el.style.visibility = binding.value ? "visible" : "hidden";
    },
  },
  created() { },
  watch: {},
  computed: {
    fileType() {
      if (!this.ext) {
        return "mdi-file";
      } else {
        switch (this.ext) {
          case "pdf":
            return "mdi-file-pdf-outline";
          case "jpg":
          case "png":
            return "mdi-file-image-outline";
          default:
            return "mdi-file";
        }
      }
    },
  },
  methods: {
    download() {
      const win = window.open(this.downloadUrl, "_blank");
      win.focus();
    },
    remove() {
      this.$root
        .$confirm(
          "Suppression",
          "Etes-vous sur de vouloir supprimer ce fichier ?",
          { width: 350 }
        )
        .then((confirm) => {
          if (!confirm) {
            return Promise.resolve();
          } else {
            get(this.removeUrl).res(() => {
              this.$emit("deleted", {});
            });
          }
        });
    },
    hoverDl() {
      this.showRm = !!this.removeUrl;
      this.iconDl = "blue darken-3";
      this.containerDlClass = "downloadable blue--text text--darken-3";
    },
    leaveDl() {
      this.showRm = false;
      this.iconDl = "";
      this.containerDlClass = "default";
    },
  },
};
</script>

<style scoped>
.downloadable {
  cursor: pointer;
}

.notransition {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}
</style>
