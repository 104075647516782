<template>
  <WidgetTile :item="data" v-if="!loading" :title="conf.title"/>
</template>
<script>
import {post} from "@/utils/api";
import WidgetTile from "@/components/commons/widgets/WidgetTile.vue";
import {EventBus} from "@/components/commons/event-bus";
export default {
  name:"HLIncentiveNotGiftedTile",
  components: {WidgetTile},
  props:{
    form: {type:Object, required:true},
    conf: {type: Object, required: false, default: null}
  },
  data(){
    return {
      url: `/api/widget/hl/dashboard/incentives/notgifted`,
      data:null,
      loading: true,
    }
  },
  computed: {
  },
  created() {
    EventBus.$on('searchDashboard', () => this.init());
    this.init();
  },
  methods: {
    init(){
      const searchForm = this._formatPayloadWidgetDashboard(this.conf,this.form)
      let tile = post(this.url, searchForm, {
        "Content-Type": "application/json",
      }).json((r) => {
        this.data =
            {
              name: r.yearN.notGift.name,
              value: r.yearN.notGift.value,
              old: r.yearPast.notGift.value,
              isPrice: r.yearN.notGift.isPrice
            }
      })
      Promise.all([tile]).then(() => {
        this.loading = false
      })
    }
  },
}
</script>