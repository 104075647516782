<template>
  <div>
    <v-row dense v-for="(segment, i) in fly.segments" :key="i">
      <v-col cols="12" class="ml-3">
        <v-row dense>
          <v-col cols="4">
            <div class="pt-1 pb-2 body-2 grey--text">
              {{ segment.marketingAirline.name }} ·
              {{ segment.passengerDetails && segment.passengerDetails.length>0 ? segment.passengerDetails[0].travelClass : segment.travelClass }} ·
              {{ segment.airplane.maker }} {{ segment.airplane.model }}
              {{ segment.airplane.variant }} ·
              {{ segment.flightCode }}
            </div>
            <ul class="details-segment">
              <li>
                {{ flightTime(segment.departureDate) }} · {{ segment.from.name }} ({{
                  segment.from.code
                }})
                <small v-if="segment.from.terminal"
                  >Terminal {{ segment.from.terminal }}</small
                >
              </li>
              <li>
                Durée du trajet: {{ _renderDuration(segment.duration) }}
                <span
                  v-if="
                    _nbNights(
                      _parseDate(segment.departureDate),
                      _parseDate(segment.arrivalDate)
                    ) > 0
                  "
                  class="red--text text--darken-1"
                >
                  De nuit
                  <v-icon color="red darken-1" size="14">warning</v-icon>
                </span>
              </li>
              <li>
                {{ flightTime(segment.arrivalDate) }}
                <sup v-html="renderSupDays(segment)"></sup> · {{ segment.to.name }} ({{
                  segment.to.code
                }})
                <small v-if="segment.to.terminal"
                  >Terminal {{ segment.to.terminal }}</small
                >
              </li>
            </ul>
          </v-col>
          <v-col cols="4" class="pt-3">
            <div v-if="segment.airplane.flightNumber && segment.airplane.airlinecode">
              <v-label>Flight Number:</v-label>
              {{ segment.airplane.airlinecode }}{{ segment.airplane.flightNumber }}
            </div>
            <div v-if="segment.passengerDetails && segment.passengerDetails.length>0 && segment.passengerDetails[0].fareOption">
              <v-label>Fare Option:</v-label>
              {{ segment.passengerDetails[0].fareOption }}
            </div>

            <div v-if="segment.passengerDetails && segment.passengerDetails.length>0 && segment.passengerDetails[0].fareBasis">
              <v-label>Fare Basis:</v-label>
              {{ segment.passengerDetails[0].fareBasis }}
            </div>
            <div v-if="segment.operatingAirline">
              <v-label>Operating Airline:</v-label>
              {{ segment.operatingAirline.code }}
            </div>
            <div v-if="segment.marketingAirline">
              <v-label>Marketing Airline:</v-label>
              {{ segment.marketingAirline.code }}
            </div>
            <div v-if="segment.validatingAirline">
              <v-label>Validating Airline:</v-label>
              {{ segment.validatingAirline.code }}
            </div>
          </v-col>
          <v-col cols="4" class="pt-3">
            <div
              v-for="(d, dIndex) in segment.details"
              :key="dIndex"
              class="d-flex justify-center align-center"
            >
              {{ d.passengerType }}

              <template v-if="d.bags">
                <template v-if="d.bags && d.bags.checked && d.bags.checked.quantity > 0">
                  <v-icon class="ml-1">mdi-bag-checked</v-icon> x{{
                    d.bags.checked.quantity
                  }}
                </template>
                <template v-if="d.bags && d.bags.checked && d.bags.checked.weight > 0">
                  <v-icon class="ml-1">mdi-bag-checked</v-icon>
                  {{ d.bags.checked.weight }}{{ d.bags.checked.weightUnit }}
                </template>

                <template v-if="d.bags && d.bags.cabin && d.bags.cabin.quantity > 0">
                  <v-icon class="ml-1">mdi-bag-carry-on</v-icon> x{{
                    d.bags.cabin.quantity
                  }}
                </template>
                <template v-if="d.bags && d.bags.cabin && d.bags.cabin.weight > 0">
                  <v-icon class="ml-1">mdi-bag-carry-on</v-icon>
                  {{ d.bags.cabin.weight }}{{ d.bags.cabin.weightUnit }}
                </template>
              </template>
              <template v-else>
                <v-icon size="18">mdi-wallet-travel</v-icon>
                <span v-if="d.luggages && d.luggages > 0">x{{ d.luggages }}</span>
                <span v-if="d.luggagesWeight && d.luggagesWeight > 0"
                  >{{ d.luggagesWeight }}kg</span
                >
                / par passager
              </template>
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <div class="details-segment-flystops" v-if="fly.segments[i + 1]">

          {{
            _durationFormatted(
              _parseDate(segment.arrivalDate, datePatternConfig.serverLocalDateTime),
              _parseDate(fly.segments[i + 1].departureDate, datePatternConfig.serverLocalDateTime),
              datePatternConfig.fr.time
            )
          }}
          d'escale · {{ segment.to.name }} ({{ segment.to.code }})
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "FlightSegment",
  props: {
    fly: {
      type: Object,
      required: true,
    },
  },
  mixins: [],
  data: function () {
    return {};
  },
  created() {},
  watch: {},
  computed: {},
  methods: {
    renderSupDays(fly) {
      const nb = this._nbNights(
        this._parseDate(fly.departureDate),
        this._parseDate(fly.arrivalDate)
      );
      if (nb > 0) return "+" + nb;
      else "";
    },
    flightTime(date) {
      return date.substring(11, 16);
    },
  },
};
</script>

<style scoped>
.row {
  color: #000000de !important;
}
</style>
