<template>
  <layout :drawerShow="false">
    <template slot="nav"></template>
    <template slot="nav-tools"></template>
    <template slot="drawer"> </template>
    <template slot="content">
      <v-container fluid>
        <bookings-list-table ref="bookingListTable"></bookings-list-table>
      </v-container>
    </template>
  </layout>
</template>
<script>
import { EventBus } from "@/components/commons/event-bus";
import { get, post } from "@/utils/api";
import Layout from "../Layout";
import BookingsListTable from "../../components/commons/BookingsListTable";
export default {
  name: "BookingLocked",
  components: { Layout, BookingsListTable },
  data: (self) => ({
    site: null,
    sites: [],
    tripDateStart: null,
    tripDateStop: null,
  }),
  created() {
    this.init();
  },
  mounted() {
    this.loadBookings();
  },
  methods: {
    init() {
      get("/api/search/sites").json((sites) => {
        this.sites = sites;
      });
    },
    loadBookings() {
      this.$refs.bookingListTable.load("/api/task/booking-locked", {});
    },
    copy() {},
    sortByDate(a, b) {
      return this._sortDates(a, b, this.datePatternConfig.serverLocalDate);
    },
  },
};
</script>
