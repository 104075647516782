<template>
  <v-row>
    <v-col cols="12" class="mailList">
      <smart-dialog
        v-model="dialogs.display"
        v-if="selectedMessage"
        :title="selectedMessage.title"
        :width="700"
      >
        <v-col class="align-center justify-center pl-2 pr-2 mb-2">
          <v-col>
            <p class="my-1" v-if="selectedMessage.sentDate">
              <span style="font-weight: bold">Date d'envoi:</span>
              {{ selectedMessage.sentDate }}
            </p>
            <p class="my-1" v-if="selectedMessage.recipients">
              <span style="font-weight: bold">Destinataire(s):</span>
              {{ selectedMessage.recipients }}
            </p>
            <p class="my-1" v-if="selectedMessage.sender">
              <span style="font-weight: bold">Emetteur:</span>
              {{ selectedMessage.sender }}
            </p>
            <p class="my-1" v-if="selectedMessage.cc">
              <span style="font-weight: bold">Cc:</span>
              {{ selectedMessage.cc }}
            </p>
            <p class="my-1" v-if="selectedMessage.bcc">
              <span style="font-weight: bold">Bcc:</span>
              {{ selectedMessage.bcc }}
            </p>
            <p class="my-1" v-if="selectedMessage.title">
              <span style="font-weight: bold">Objet:</span>
              {{ selectedMessage.title }}
            </p>
          </v-col>
          <v-divider></v-divider>
          <v-row>
            <v-chip outlined small color="secondary" v-if="selectedMessage.readDate">
              Date de lecture:
              {{ selectedMessage.readDate }}
            </v-chip>
            <v-chip outlined small color="secondary" v-if="selectedMessage.prestationId">
              Identifiant de la prestation:
              #{{ selectedMessage.prestationId }}
            </v-chip>
          </v-row>
          <v-divider></v-divider>
        </v-col>
        <v-row class="pb-3" v-html="messageContent" align="center" justify="center"></v-row>
      </smart-dialog>

      <v-list two-line subheader xs12 v-for="(messagesWithDates, idx) in filteredEmails" :key="idx">
        <v-subheader>
          <v-chip
            v-show="messagesWithDates.items.length > 0"
            style="border-radius: 5px"
            color="#e3f2fd"
            text-color="#2567c1"
            small
          >{{ messagesWithDates.date }}</v-chip>
        </v-subheader>
        <template v-for="(message, messageIndex) in messagesWithDates.items">
          <v-list-item @click="showBody(message)" :key="messageIndex" class="ma-0 pa-0">
            <v-list-item-content class="pl-1">
              <v-list-item-title dense>
                <v-row dense>
                  <v-col cols="2">
                    <v-tooltip top v-if="message.sentDate">
                      <span>Date d'envoi</span>
                      <template #activator="{ on }">
                        <div v-on="on">{{ message.sentDate.substr(10, 6) }}</div>
                      </template>
                    </v-tooltip>
                    <v-tooltip top v-else>
                      <span>Date de création: {{ message.creationDate }} - Envoi en cours</span>
                      <template #activator="{ on }">
                        <div v-on="on">
                          {{ message.creationDate.substr(10, 6) }}
                          <span>
                            <v-icon small color="amber lighten-1">mdi-send-clock</v-icon>
                          </span>
                        </div>
                      </template>
                    </v-tooltip>
                  </v-col>
                  <v-col cols="9">
                    <span
                      class="mail-tiles-title"
                      style="width: 100%;overflow: hidden;text-overflow: ellipsis;font-weight: bold;"
                    >{{ message.title }}</span>
                  </v-col>
                </v-row>
              </v-list-item-title>
              <v-list-item-subtitle dense>
                <v-row dense>
                  <v-col cols="9" offset="2">
                    <v-tooltip top v-if="message.messageType == 'customer'">
                      <span>Email client</span>
                      <template #activator="{ on }">
                        <v-icon v-on="on" small :color="customerColor">mdi-account-circle</v-icon>
                      </template>
                    </v-tooltip>
                    <v-tooltip top v-else>
                      <span>Email fournisseur</span>
                      <template #activator="{ on }">
                        <v-icon v-on="on" small :color="supplierColor">mdi-professional-hexagon</v-icon>
                      </template>
                    </v-tooltip>
                    <v-tooltip top v-if="message.haveData">
                      <span>Réenvoie email</span>
                      <template #activator="{ on }">
                        <v-btn v-on="on" @click.stop="showResend(message)" x-small icon>
                          <v-icon small dark>mdi-cached</v-icon>
                        </v-btn>
                      </template>
                    </v-tooltip>

                    <span v-if="message.readDate">
                      •
                      <v-tooltip top>
                        <span>Lu: {{ message.readDate }}</span>
                        <template #activator="{ on }">
                          <v-icon v-on="on" small>mdi-email-check-outline</v-icon>
                        </template>
                      </v-tooltip>
                    </span>
                    <span v-if="message.spamDate">
                      •
                      <v-tooltip top>
                        <span>Spam: {{ message.spamDate }}</span>
                        <template #activator="{ on }">
                          <v-icon v-on="on" slot="activator" small color="red">mdi-email-alert</v-icon>
                        </template>
                      </v-tooltip>
                    </span>
                    <span v-if="message.blockDate">
                      •
                      <v-tooltip top>
                        <span>Bloqué: {{ message.blockDate }}</span>
                        <template #activator="{ on }">
                          <v-icon v-on="on" slot="activator" small color="red">mdi-email-lock</v-icon>
                        </template>
                      </v-tooltip>
                    </span>
                    <span>
                      •
                      <span style="font-size: 11px">{{ message.recipients }}</span>
                    </span>
                  </v-col>
                </v-row>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider :key="'divider' + messageIndex"></v-divider>
        </template>
      </v-list>
    </v-col>
  </v-row>
</template>

<script>
import { get } from "@/utils/api";
import _ from "lodash";
import { EventBus } from "@/components/commons/event-bus";

export default {
  name: "EmailsResumeListing",
  props: {
    searchQuery: {
      type: String,
      required: false,
    },
    booking: {
      type: Object,
      required: true,
    },
    test: { type: Boolean, default: false },
  },
  data() {
    return {
      groupedMessages: null,
      customerColor: "light-blue accent-3",
      supplierColor: "teal accent-4",
      hideSuppliersItems: false,
      dialogs: {
        display: false,
      },
      messageContent: String,
      selectedMessage: null,
    };
  },
  watch: {
    booking: {
      immediate: true,
      handler(newVal, oldVal) {
        this.init();
      },
    },
  },
  computed: {
    filteredEmails() {
      if (this.searchQuery == "") {
        return this.groupedMessages;
      } else {
        const emailsList = _.cloneDeep(this.groupedMessages);
        return emailsList.map((obj) => {
          obj.items = [...obj.items].filter((item) => {
            let res = item.recipients.search(this.searchQuery);
            return res > -1;
          });
          return obj;
        });
      }
    },
  },
  methods: {
    init() {
      let url = `/api/mail/messages/booking/${this.booking.id}`;
      if (this.test) {
        url += "/test";
      }
      return get(url).json((data) => {
        const dates = Object.keys(data);
        const today = this._now();
        let count = 0;

        if (data) {
          for (let day in data) {
            count += data[day].length;
          }
          this.$emit("mailsloaded", { count: count });
        }

        this.groupedMessages = dates.map((date) => {
          const formattedDate = this._isSame(
            this._parseDate(date, this.datePatternConfig.en.localDate),
            this._parseDate(today, this.datePatternConfig.en.localDate),
            "day"
          )
            ? "Aujourd'hui"
            : _.capitalize(
              this._parseAndFormatDate(date, "yyyy-MM-dd", "EEE dd/MM/yyyy")
            );
          return {
            date: formattedDate,
            items: [
              ...data[date].map((d) => {
                const formattedTime = d.creationDate;
                return { ...d, ...{ time: formattedTime }, ...{ open: false } };
              }),
            ],
          };
        });
      });
    },
    showBody(message) {
      const url = `/api/mail/messages/booking/body/${message.id}`;
      get(url).json((body) => {
        this.messageContent = body ? body : "Aucun message";
        this.selectedMessage = message;
        this.dialogs.display = true;
      });
    },
    showResend(message) {
      this.$root
        .$confirm(this.$t("confirm"), `<h4>Souhaitez Ré-envoyer cet email ?</h4><br/> <i>Le contenu, les destinataires ainsi que les pièces jointes seront identiques. </i>`, {
          width: 450,
          isHtml: true
        })
        .then((confirm) => {
          if (!confirm) {
            return Promise.resolve();
          } else {
            return get(`/api/mail/messages/resend/${message.id}`)
              .badRequest((err) => {
                EventBus.$emit(
                  "toaster-msg",
                  JSON.parse(err.null).errors.map((e) => JSON.parse(e))
                );
              })
              .res(() => {
                return;
              })
              .then(() => {
                this.init()
              });
          }
        });
    }
  },
};
</script>

<style lang="less" scoped>
.mail-tiles :hover {
  background-color: #e3f2fd;

  .mail-tiles-title {
    color: #2c6dc3;
  }
}

.mailList {
  height: 300px;
  overflow: scroll;
}
</style>
<style scoped>
>>> .v-list__tile {
  font-size: 14px;
  color: #254050;
}
</style>
