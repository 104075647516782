<template>
  <layout :drawerShow="_isDesktop()" :drawer="true" :drawerWidth="350">
    <template slot="drawer">
      <v-form
        ref="searchform"
        v-model="valid"
        v-if="searchform"
        @submit.prevent="loadData"
      >
        <v-row dense>
          <v-col class="mt-2">
            <smart-select
              v-model="searchform.suppliers"
              label="Fournisseurs"
              :items="suppliers"
              item-text="name"
              item-value="id"
              :chip-style="{ width: '120px' }"
              :default-select-all="true"
              :all-is-same-as-empty="true"
              :loading="loadingSuppliers"
            >
            </smart-select>
          </v-col>

          <v-col cols="12" class="mt-2">
            <smart-multi-select
              v-model="searchform.destinations"
              label="Destination"
              :items="destinations"
              :all-is-same-as-empty="true"
              :default-select-all="true"
            >
            </smart-multi-select>
          </v-col>

           <v-col cols="12" class="mt-2">
            <smart-multi-select
              v-model="searchform.sites"
              label="Site"
              :items="sites"
              item-text="name"
              :all-is-same-as-empty="true"
              :default-select-all="true"
            >
            </smart-multi-select>
          </v-col>

          <v-col cols="12" class="">
            <v-subheader light class="pa-0 mb-0 mx-0 mt-2 form-subheader"
              >Dates de réservation | Dossier
            </v-subheader>
            <v-divider></v-divider>
          </v-col>

          <v-col class="pr-1" cols="6">
            <smart-picker
              v-model="searchform.bookingStartDate"
              :inputOpts="{ label: $t('begin') }"
              label="Début de réservation"
              :next-picker="$refs.bookingStopDate"
              :stopDate="searchform.bookinStopDate"
            />
          </v-col>
          <v-col cols="6">
            <smart-picker
              v-model="searchform.bookingStopDate"
              :inputOpts="{ label: $t('end') }"
              label="Fin de réservation"
              :startDate="searchform.bookingStartDate"
              :min="searchform.bookingStartDate"
              ref="stopDate"
            />
          </v-col>

          <v-col cols="12" class="mt-3">
            <v-subheader light class="pa-0 mb-0 mx-0 mt-2 form-subheader"
              >Dates de voyage | Prestation
            </v-subheader>
            <v-divider></v-divider>
          </v-col>

          <v-col class="pr-1" cols="6">
            <smart-picker
              v-model="searchform.prestationStartDate"
              :inputOpts="{ label: $t('begin') }"
              label="Début de prestation"
              :next-picker="$refs.prestationStopDate"
              :stopDate="searchform.prestationStopDate"
            />
          </v-col>
          <v-col cols="6">
            <smart-picker
              v-model="searchform.prestationStopDate"
              :inputOpts="{ label: $t('end') }"
              label="Fin de prestation"
              :startDate="searchform.prestationStartDate"
              :min="searchform.prestationStartDate"
              ref="stopDate"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col class="mt-4" cols="12">
            <smart-btn
              type="submit"
              block
              primary
              class="mt-5"
              :disabled="
                (searchform.startDate && !searchform.stopDate) ||
                (!searchform.startDate && searchform.stopDate)
              "
              :loading="loading"
              >Rechercher
            </smart-btn>
          </v-col>
        </v-row>
      </v-form>
    </template>

    <template slot="content">
      <v-container fluid>
        <v-row justify="space-between">
          <v-col cols="12">
            <v-tabs>
              <v-tab key="transfers">Transferts</v-tab>
              <v-tab key="carrent">Locations</v-tab>
              <v-tab-item eager key="transfers">
                <widget-transfer-stats
                  @loading="setLoading"
                  :bus="widgetBus"
                ></widget-transfer-stats>
              </v-tab-item>
              <v-tab-item eager key="carrent">
                <widget-carrental-stats
                  :bus="widgetBus"
                ></widget-carrental-stats>
              </v-tab-item>
            </v-tabs>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </layout>
</template>

<script>
import Layout from "../Layout";
import { get, post } from "../../utils/api";
import ColorMixin from "../../components/mixins/ColorMixin";
import FormMixin from "../../components/mixins/FormMixin";
import SmartMultiSelect from "../../components/commons/SmartMultiSelect";
import SmartSelect from "../../components/commons/SmartSelect";
import WidgetTransferStats from "../../components/commons/widgets/WidgetTransferStats";
import Vue from "vue";
import WidgetCarrentalStats from "../../components/commons/widgets/WidgetCarrentalStats";

export default {
  name: "TransferStats",
  mixins: [ColorMixin, FormMixin],
  components: {
    WidgetCarrentalStats,
    Layout,
    SmartMultiSelect,
    SmartSelect,
    WidgetTransferStats,
  },
  data() {
    return {
      widgetBus: new Vue(),
      valid: true,
      loading: false,
      loadingSuppliers: false,
      suppliers: [],
      destinations: [],
      sites: [],
      active: false,
      searchform: {
        suppliers: [],
        destinations: [],
        bookingStartDate: null,
        bookingStopDate: null,
        prestationStartDate: null,
        prestationStopDate: null,
      },
    };
  },
  created() {
    this.searchform.bookingStartDate = this._formatDate(
      this._now(),
      this.datePatternConfig.serverLocalDate
    );
    this.searchform.bookingStopDate = this._formatDate(
      this._now(),
      this.datePatternConfig.serverLocalDate
    );

    Promise.all(
        [
          this.getSupplierAccounts(),
          this.getDestinations(),
          this.getSites()
        ]
    ).then(() => {
      this.loadData();
    })
  },
  methods: {
    loadData() {
      if (!this.loading) {
        this.widgetBus.$emit("search", this.searchform);
      }
    },
    getSupplierAccounts() {
      const url = `/api/supplier/get/forprestation`;

      return get(url).json((r) => {
        let accounts = r.map((account) => {
          return { name: account.name, id: account.id };
        });

        this.suppliers = accounts;
        return this.suppliers
        //this.searchform.supplier = this.suppliers.map(s => s.value)
      });
    },
    getDestinations() {
      return get("/api/search/destinations").json((destinations) => {
        this.destinations = destinations;
        this.searchform.destinations = this.destinations.map((d) => d.id);
        return this.destinations
      });
    },
    getSites(){
      return get("/api/search/sites").json((sites) => {
        this.sites = sites
        this.searchform.sites = sites.map(s => s.id)
        return sites
      })
    },
    setLoading(loading) {
      this.loading = loading;
    },
  },
};
</script>

<style scoped></style>
