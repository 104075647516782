<template>
  <widget-container
    :url="url"
    :bus="bus"
    :dark="false"
    :alwaysrender="true"
    headlineclass=""
    cardclass="ma-0 pa-1"
    color="transparent"
    @ready="setData"
  >
    <template slot="content">
      <v-row>
        <v-col cols="12">
          <v-data-table
            :headers="listing.globalHeaders"
            :items="listing.globalListing"
            :loading="listing.loading"
            :footer-props="{
              'items-per-page-options': listing.rowsPerPageItems,
              'items-per-page-text': 'Lignes par page',
            }"
            hide-default-footer
            class="tbl"
            no-data-text="Il n'y a pas de résultat disponible"
          >
            <template #header.copy="{ header }">
              <v-icon small @click="copy(listing.globalListing)" class="pl-2">
                mdi-clipboard-text-outline
              </v-icon>
            </template>

            <template #body="{ items }">
              <tbody>
                <tr v-for="(item, idx) in items" :key="idx">
                  <td>
                    {{
                      item.supplierAccountingName
                        ? item.supplierAccountingName
                        : ""
                    }}
                  </td>
                  <td>
                    {{ item.prestationType ? $t(item.prestationType) : "" }}
                  </td>
                  <td>{{ item.nbReservations ? item.nbReservations : 0 }}</td>
                  <td>{{ item.nbPax ? item.nbPax : 0 }}</td>
                  <td>
                    {{ item.buyAmount | currency("EUR") }}
                  </td>
                  <td>
                    {{ item.sellAmount | currency("EUR") }}
                  </td>
                  <td></td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-col>

        <v-col cols="12">
          <v-data-table
            :headers="listing.headers"
            :items="listing.resultsListing"
            :loading="listing.loading"
            :footer-props="{
              'items-per-page-options': listing.rowsPerPageItems,
              'items-per-page-text': 'Lignes par page',
            }"
            hide-default-footer
            class="tbl"
            no-data-text="Il n'y a pas de résultat disponible"
          >
            <template #header.copy="{ header }">
              <v-icon small @click="copy(listing.resultsListing)" class="pl-2">
                mdi-clipboard-text-outline
              </v-icon>
            </template>

            <template #body="{ items }">
              <tbody>
                <tr v-for="(item, idx) in items" :key="idx">
                  <td>
                    {{
                      item.supplierAccountingName
                        ? item.supplierAccountingName
                        : ""
                    }}
                  </td>
                  <td>
                    {{ item.prestationType ? $t(item.prestationType) : "" }}
                  </td>
                  <td>{{ item.prestationName ? item.prestationName : "" }}</td>
                  <td>{{ item.nbReservations ? item.nbReservations : 0 }}</td>
                  <td>{{ item.nbPax ? item.nbPax : 0 }}</td>
                  <td>
                    {{ item.buyAmount | currency("EUR") }}
                  </td>
                  <td>
                    {{ item.sellAmount | currency("EUR") }}
                  </td>
                  <td></td>
                </tr>
              </tbody>
              <tfoot>
                <tr
                  style="font-weight: bold"
                  v-if="items.length > 0 && listing.resultsListing"
                >
                  <td colspan="3">Total</td>

                  <td>
                    {{
                      listing.totalReservations ? listing.totalReservations : 0
                    }}
                  </td>
                  <td>
                    {{ listing.totalPax ? listing.totalPax : 0 }}
                  </td>
                  <td>
                    {{ listing.totalBuyAmount | currency("EUR") }}
                  </td>
                  <td>
                    {{ listing.totalSellAmount | currency("EUR") }}
                  </td>
                  <td></td>
                </tr>
              </tfoot>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </template>
  </widget-container>
</template>

<script>
import WidgetContainer from "./WidgetContainer";
import clipboard from "../../../utils/clipboard";

export default {
  name: "WidgetSupplierAccountingStats",
  components: { WidgetContainer },
  mixins: [],
  props: {
    bus: {
      type: Object,
      required: true,
    },
    url: {
      type: String,
      required: false,
      default: "/api/widget/supplier-accountings",
    },
  },
  data() {
    return {
      data: null,
      listing: {
        loading: false,
        rowsPerPageItems: [200, 500, { text: "Tous", value: -1 }],
        pagination: {
          rowsPerPage: 200,
        },
        headers: [
          { text: "Fournisseur", value: "supplierAccountingName" },
          { text: "Catégorie", value: "prestationType" },
          { text: "Produit", value: "prestationName" },
          { text: "nb de dossiers", value: "nbReservations" },
          { text: "Pax", value: "nbPax" },
          { text: "Achat", value: "buyAmount" },
          { text: "Vente", value: "sellAmount" },
          {
            text: "",
            value: "copy",
            class: "text-center",
            sortable: false,
            width: "30px",
          },
        ],
        globalHeaders: [],
        resultsListing: [],
        globalListing: [],
        totalReservations: null,
        totalPax: null,
        totalBuyAmount: null,
        totalSellAmount: null,
      },
    };
  },
  created() {
    this.listing.globalHeaders = [...this.listing.headers];
    this.listing.globalHeaders.splice(3, 1);

    this.$nextTick(() => {
      this.bus.$on("search", (searchform) => {
        this.listing.loading = true;
        this.$emit("loading", true);
      });
    });
  },
  destroyed() {
    this.bus.$off("search");
  },
  watch: {},
  computed: {},
  methods: {
    setData(data) {
      this.listing.resultsListing = data.prestations;
      this.listing.globalListing = data.globalPrestationTypeData;
      this.listing.totalReservations = data.totalReservations;
      this.listing.totalPax = data.totalPax;
      this.listing.totalBuyAmount = data.totalBuyAmount;
      this.listing.totalSellAmount = data.totalSellAmount;
      this.listing.loading = false;
      this.$emit("loading", false);
    },
    copy(data) {
      const defaultSeparator = "\t";
      const decimalOpts = { maximumFractionDigits: 0, useGrouping: false };
      const headers = this.listing.headers
        .map((h) => {
          return `${h.text}${defaultSeparator}`;
        })
        .join(" ");

      const rows = data.map((i) => {
        //à modifier
        let rowObj = {};
        if (i.prestationName) {
          rowObj = {
            supplierAccountingName: i.supplierAccountingName,
            prestationType: i.prestationType,
            prestationName: i.prestationName,
            nbReservations: this.$options.filters.decimal(
              i.nbReservations,
              decimalOpts
            ),
            nbPax: this.$options.filters.decimal(i.nbPax, decimalOpts),
            buyAmount: this.$options.filters.decimal(i.buyAmount, decimalOpts),
            sellAmount: this.$options.filters.decimal(
              i.sellAmount,
              decimalOpts
            ),
          };
        } else {
          rowObj = {
            supplierAccountingName: i.supplierAccountingName,
            prestationType: i.prestationType,
            nbReservations: this.$options.filters.decimal(
              i.nbReservations,
              decimalOpts
            ),
            nbPax: this.$options.filters.decimal(i.nbPax, decimalOpts),
            buyAmount: this.$options.filters.decimal(i.buyAmount, decimalOpts),
            sellAmount: this.$options.filters.decimal(
              i.sellAmount,
              decimalOpts
            ),
          };
        }

        return `${Object.values(rowObj).join(defaultSeparator)}`;
      });

      const allRows = [headers].concat(rows);

      clipboard.copyTextToClipboard(allRows.join("\n"));
    },
  },
};
</script>

<style scoped></style>
