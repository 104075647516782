<template>
	<span>
		<v-tooltip top v-for="(g,index) in guests" :key="index">
			<template #activator="{on}">
				<v-chip
					:small="small"
					:color="color(g)"
					text-color="white"
					v-on="on"
				>
					<v-avatar>
						<v-icon>account_circle</v-icon>
					</v-avatar>
					{{initial(g.firstName, g.lastName)}}
				</v-chip>
			</template>
			<span>{{g.firstName + " " + g.lastName}} ({{$t(g.ageClassType)}})</span>
		</v-tooltip>
	</span>
</template>

<script>
	import GuestsMixin from "@/components/mixins/GuestsMixin";

	export default {
		name: "HotelPrice",
		mixins: [GuestsMixin],
		props: {
			guests: {type: Array, required: true},
			small: {type: Boolean, default: true}
		},
		methods: {
			color(g) {
				switch (g.ageClassType) {
					case 'Adult':
						return 'indigo accent-1';
					case 'Teenager':
						return 'blue accent-3';
					case 'Child':
						return 'amber accent-3';
					case 'Baby':
						return 'pink accent-3';
					default:
						return 'grey accent-3';

				}
			}
		}
	}
</script>
