<template>
  <div>
    <p class="mb-0 font-weight-bold">{{ title }}</p>
    <p class="mb-0">{{ desc }}</p>
    <p
      v-if="radio"
      :class="item == -1 ? '' : 'font-weight-bold yellow--text text--darken-2'"
    >
      {{ setRadio }}
    </p>
    <v-textarea
      v-else-if="text"
      prepend-icon="mdi-comment"
      style="cursor: not-allowed"
      :value="item"
    >
    </v-textarea>
    <div v-else class="mb-4">
      <v-icon
        v-for="(star, i) in 4"
        :key="i"
        small
        :color="i + 1 <= item ? 'yellow darken-2' : 'black'"
        >{{ setStars(i) }}</v-icon
      >
    </div>
  </div>
</template>
<script>
export default {
  name: "ReviewItem",
  props: {
    item: {
      type: [Number, String],
      required: true,
      default: "",
    },
    radio: {
      type: Boolean,
      required: false,
      default: false,
    },
    text: {
      type: Boolean,
      required: false,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
    desc: {
      type: String,
      required: false,
      default: "",
    },
  },
  computed: {
    setRadio() {
      let response = null;
      switch (this.item) {
        case 0:
          response = "Oui";
          break;
        case 1:
          response = "Non";
          break;
        case 2:
          response = "Ne se prononce pas";
          break;
        default:
          response = "Pas de réponse";
      }
      return response;
    },
  },
  methods: {
    setStars(i) {
      return i + 1 <= this.item ? "mdi-star" : "mdi-star-outline";
    },
  },
};
</script>
