<template>
  <v-container fluid>
    <v-row justify="center">
      <v-progress-circular class="text-center" v-if="loading" indeterminate color="primary"></v-progress-circular>
    </v-row>
    <v-row>
      <v-col v-if="insurances.length == 0" cols="12">
        <v-alert :value="error.show" color="error" icon="warning" outlined>{{ error.msg }}</v-alert>
      </v-col>
      <v-col cols="12" v-else>
        <div v-if="insurances.length == 0">Aucune assurances</div>
        <div v-for="(insurance, index) in insurances" :key="index">
          <v-card width="100%" class="mt-2">
            <v-card-title>
              <v-row>
                <v-col cols="12">
                  <h3 class="headline mb-0">
                    <v-row dense>
                      <v-col cols="9">{{ insurance.insuranceName }}</v-col>

                      <v-col class="text-right" cols="3">
                        {{ insurance.pricing.prices.price }}
                        {{ $t(insurance.pricing.prices.currency.code + '-symbol') }}
                        <small
                          v-if="insuranceSelected"
                        >
                          (
                          <price-diff
                            :cssdisplay="'inline'"
                            :show-baseprice="false"
                            :new-price="insurance.pricing.prices.price"
                            :base-price="insuranceSelected.price"
                          ></price-diff>)
                        </small>
                      </v-col>
                    </v-row>
                  </h3>

                  <v-col cols="12" class="ma-0 pa-0">
                    <div class="text-xs body-1 ml-0 mb-0">{{ insurance.formulaName }} - <small>{{ insurance.optionName }}</small> </div>
                    <div>
                      <availability v-model="availability" kind="Stock"></availability>
                      <v-chip v-if="insurance.translation" class="ml-1" small>Vendu sur site</v-chip>
                      <v-chip class="ml-1" small>{{ insurance.issuer }}</v-chip>
                    </div>
                  </v-col>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text class="mt-2">
              <div v-if="insurance.translation" class="mb-2">
                Nom produit : {{insurance.translation.name}}
              </div>
              <div class="text-xs body-2"><a :href="insurances.dinLink" target="_blank">DIN.pdf</a></div>
              <div class="text-xs body-2"><a :href="insurances.cgsLink" target="_blank">CGS.pdf</a></div>
              <div>
                <v-textarea
                  v-if="booking.status == 'booked' && booking.bookingDate && _isAfter(_now(), _parseDateTime(booking.bookingDate))"
                  label="Raison de la souscription tardive (obligatoire)"
                  filled
                  v-model="insurance.comment"
                ></v-textarea>
              </div>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                :loading="insurance.loadingAddBtn"
                :disabled="(booking.status == 'booked' && booking.bookingDate && _isAfter(_now(), _parseDateTime(booking.bookingDate))) && (insurance.comment == null || insurance.comment.trim() == '')"
                @click.native="add(insurance)"
              >{{ $t(insuranceSelected ? 'edit' : 'add') }}</v-btn>
            </v-card-actions>
          </v-card>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { get, post } from "@/utils/api";
import { EventBus } from "@/components/commons/event-bus";
import Availability from "../../commons/Availability";
import PriceDiff from "../../commons/PriceDiff";

export default {
  name: "AddInsurance",
  components: { PriceDiff, Availability },
  mixins: [],
  props: {
    booking: {
      type: Object,
      required: true,
    },
    insuranceSelected: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      availability: "onstock",
      error: {
        show: false,
        msg: "",
      },
      loading: false,
      insurances: [],
      guests: [],
      ctx: {
        siteId: null,
        langId: null,
        priceClassTypeId: null,
        country: null,
        bookingDate: null,
        startDate: null,
        stopDate: null,
        guestsPrice: [],
        comment: null,
        bookingId: null,
      },
    };
  },
  created() {
    this.init();
  },
  watch: {},
  computed: {},
  methods: {
    async init() {
      this.error.show = false;
      this.loading = true;
      this.guests = await this.getGuest();

      const allGuestsNamed = this.guests.reduce(
        (acc, g) =>
          acc &&
          g.guest != null &&
          g.guest.firstName != null &&
          g.guest.firstName.length > 0 &&
          g.guest != null &&
          g.guest.lastName != null &&
          g.guest.lastName.length > 0,
        true
      );

      if (!allGuestsNamed) {
        this.error = {
          ...{ show: true },
          ...{ msg: "Les passagers doivent être nommés" },
        };
      } else {
        const ctx = this.buildCtx(this.guests, "Commentaire pré-recherche");

        this.getInsurances(ctx);
      }
    },
    getInsurances(ctx) {
      return post(`/api/prestation/add/search-insurance`, ctx)
        .badRequest((err) => {
          EventBus.$emit("toaster-msg", err);
          this.error = { ...{ show: true }, ...{ msg: err } };
        })
        .json((data) => {

          this.insurances = data.map(d => {
            return {
              ...d,
              ...{ translation: this.getTranslatedName(d) },
              ...{ loadingAddBtn: false },
              ...{ comment: null },
            }
          })
        })
        .then(() => {
          this.loading = false;
        });
    },
    async getGuest() {
      return get(`/api/prestation/add/guests-booking/${this.booking.id}`)
        .badRequest((err) => {
          EventBus.$emit("toaster-msg", err);
          this.error = { ...{ show: true }, ...{ msg: err } };
        })
        .json((data) => {
          return data;
        });
    },
    buildCtx(guest, comment) {
      return {
        bookingId: this.booking.id,
        siteId: this.booking.context.site.id,
        langId: this.booking.context.lang.id,
        priceClassTypeId: this.booking.context.strokePriceClassType.id,
        strokePriceClassTypeId: this.booking.context.priceClassType.id,
        clientCountry: this.booking.context.countryCode,
        destiCountry: "",
        bookingDate: this.booking.bookingDate,
        startDate: this._parseAndFormatDate(
          this.booking.startDate,
          this.datePatternConfig.serverLocalDateTime,
          this._datePattern.localDate
        ),
        stopDate: this._parseAndFormatDate(
          this.booking.stopDate,
          this.datePatternConfig.serverLocalDateTime,
          this._datePattern.localDate
        ),
        products: [],
        lateSubscription: this.booking.status == "booked",
        comment: comment,
        guestsPrice: guest,
      };
    },
    add(proposition) {
      proposition.loadingAddBtn = true;

      const ctx = {
        ...this.buildCtx(this.guests, proposition.comment),
        ...{product:{
          id:proposition.productId,
          forcedPrice:null,
          forcedPurchasePrice:null
        }}
      }

      const verb = this.insuranceSelected ? "edit" : "add";
      const prestationIdQs =
        this.insuranceSelected && this.insuranceSelected.id
          ? `&prestationId=${this.insuranceSelected.id}`
          : "";
      const url = `/api/prestation/${verb}/insurance?bookingId=${this.booking.id}&hash=${proposition.hash}${prestationIdQs}`;

      return post(url, ctx)
        .badRequest((err) => {
          EventBus.$emit("toaster-msg", err);
          this.error = { ...{ show: true }, ...{ msg: err } };
        })
        .res((data) => {
          return data;
        })
        .then(() => {
          proposition.loadingAddBtn = false;
          EventBus.$emit("reloadbooking");
          EventBus.$emit("prestation-added");
        });
    },
    getTranslatedName(insurance) {
      if (!insurance.translation) return null;

      const locale = this.$i18n.locale || 'fr'
      return JSON.parse(insurance.translation)[locale]
    }
  },
};
</script>

<style scoped>
</style>

