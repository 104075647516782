<template>
  <widget-container
    :url="url"
    headlineclass
    cardclass="ma-0 pa-1"
    color="transparent"
    :bus="bus"
    :dark="false"
    :flat="true"
    :alwaysrender="true"
    @ready="setData"
  >
    <template slot="content">
      <div class>
        <v-row align="center">
          <v-col class="py-0">
            <v-subheader class="title"
              >Répartition du CA par destination</v-subheader
            >

            <v-data-table
              class="mb-4"
              :headers="headers"
              :items="data"
              dense
              :loading="loading"
              :sort-by="['ca']"
              :sort-desc="true"
              :hide-default-footer="dense"
              :header-props="{ sortByText: 'Trier par' }"
            >
              <template #item.ca="{ item }">
                {{ item.ca | currency("EUR") }}
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </div>
    </template>
  </widget-container>
</template>
<script>
import WidgetContainer from "./WidgetContainer";
export default {
  name: "WidgetAgenciesCaByDesti",
  components: { WidgetContainer },
  props: {
    bus: {
      type: Object,
      required: true,
    },
    url: {
      type: String,
      required: false,
      default: "",
    },
    dense: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      data: [],
      headers: [
        { text: "Destination", value: "destinationName", sortable: true },
        { text: "Agence", value: "agencyName", sortable: true },
        { text: "Ca", value: "ca", sortable: true, width: "100px" },
      ],
      option: {
        sortBy: "destinationName",
      },
    };
  },
  created() {
    if (this.dense) {
      let index = this.headers.findIndex((item) => item.value == "agencyName");
      this.headers.splice(index, 1);
    }

    this.$nextTick(() => {
      this.bus.$on("search", () => {
        this.loading = true;
        this.$emit("loading", true);
      });
    });
  },
  destroyed() {
    this.bus.$off("search");
  },
  methods: {
    setData(data) {
      this.data = data;
      this.loading = false;
      this.$emit("loading", false);
    },
  },
};
</script>
