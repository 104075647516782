var render = function render(){var _vm=this,_c=_vm._self._c;return _c('layout',[_c('template',{slot:"nav"},[_c('nav-currencies')],1),_c('template',{slot:"drawer"}),_vm._v(" | "),_c('template',{slot:"content"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-card',[_c('v-data-table',{staticClass:"tbl",attrs:{"dense":"","headers":_vm.headers,"items":_vm.rawData,"loading":_vm.loading,"no-data-text":"-","hide-default-footer":"","footer-props":{
            'items-per-page-options': _vm.rowsPerPageItems,
            'items-per-page-text': 'Lignes par page',
          }},scopedSlots:_vm._u([{key:"item.prevTotal",fn:function({ item }){return [_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(_vm.formattedAmount(item.prevTotal)))])]}},{key:"item.paidTotal",fn:function({ item }){return [_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(_vm.formattedAmount(item.paidTotal)))])]}},{key:"item.total",fn:function({ item }){return [_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(_vm.formattedAmount(item.prevTotal + item.paidTotal)))])]}},_vm._l((_vm.headersMonths),function(m){return {key:`item.${m.value}`,fn:function({ item, value }){return [_vm._l(([_vm.getMonth(item.periods, m.value)]),function(p){return [(p)?_c('div',{key:item.euroRate + '-' + p.date,on:{"click":function($event){return _vm.cellClicked(p.date, item.euroRate)}}},[_vm._v(" "+_vm._s(_vm.formattedAmount(p.prevAmount))+" ")]):_vm._e()]})]}}}),{key:"body.append",fn:function({ items }){return [_c('tr',[_c('td',[_vm._v("Total")]),_c('td',{staticClass:"font-weight-medium text-right"},[_vm._v(" "+_vm._s(_vm.formattedAmount( items.reduce((sum, item) => sum + item.prevTotal, 0) ))+" ")]),_c('td',{staticClass:"font-weight-medium text-right"},[_vm._v(" "+_vm._s(_vm.formattedAmount( items.reduce((sum, item) => sum + item.paidTotal, 0) ))+" ")]),_c('td',{staticClass:"font-weight-medium text-right"},[_vm._v(" "+_vm._s(_vm.formattedAmount( items.reduce( (sum, item) => sum + item.prevTotal + item.paidTotal, 0 ) ))+" ")]),_vm._l((_vm.headersMonths),function(m,index){return _c('td',{key:'qq' + index,staticClass:"text-right font-weight-medium"},[_vm._v(" "+_vm._s(_vm.formattedAmount(_vm.sumPeriod(items, m.value, "prevAmount")))+" ")])})],2)]}}],null,true)})],1),(
          _vm.prestationsNotInvoiced.length > 0 || _vm.prestationsInvoiced.length > 0
        )?_c('v-card',{staticClass:"mt-4"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('h4',{staticClass:"mb-2 ml-2 text-decoration-underline"},[_vm._v(" Prestations non attachées à une facture ")]),(_vm.prestationsNotInvoiced.length > 0)?_c('v-data-table',{staticClass:"tbl",attrs:{"dense":"","headers":_vm.headersPrestation,"items":_vm.prestationsNotInvoiced,"no-data-text":"-","hide-default-footer":"","footer-props":{
                'items-per-page-options': _vm.rowsPerPageItems,
                'items-per-page-text': 'Lignes par page',
              }},scopedSlots:_vm._u([{key:"item.purchase",fn:function({ value }){return [_vm._v(" "+_vm._s(_vm.formattedAmount(value))+" ")]}}],null,false,1028958897)}):_vm._e(),_c('v-divider'),(_vm.prestationsNotInvoiced.length > 0)?_c('div',{staticClass:"font-weight-bold text-right mr-1 mt-1"},[_vm._v(" Total: "+_vm._s(_vm.formattedAmount(_vm.sumPrestationsNotInvoiced))+" ")]):_c('div',[_vm._v("-")])],1),_c('v-col',{attrs:{"cols":"6"}},[_c('h4',{staticClass:"mb-2 ml-2 text-decoration-underline"},[_vm._v(" Factures saisies ")]),(_vm.prestationsInvoiced.length > 0)?_c('v-data-table',{staticClass:"tbl",attrs:{"dense":"","headers":_vm.headersInvoices,"items":_vm.prestationsInvoiced,"no-data-text":"-","hide-default-footer":"","footer-props":{
                'items-per-page-options': _vm.rowsPerPageItems,
                'items-per-page-text': 'Lignes par page',
              }},scopedSlots:_vm._u([{key:"item.amount",fn:function({ value }){return [_vm._v(" "+_vm._s(_vm.formattedAmount(value))+" ")]}}],null,false,2746233430)}):_vm._e(),_c('v-divider'),(_vm.prestationsInvoiced.length > 0)?_c('div',{staticClass:"font-weight-bold text-right mr-1 mt-1"},[_vm._v(" Total: "+_vm._s(_vm.formattedAmount(_vm.sumPrestationsInvoiced))+" ")]):_c('div',[_vm._v("-")])],1)],1)],1):_vm._e()],1)],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }