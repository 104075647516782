<template>
  <div>
    <v-row
        v-if="prestation.cancelFees && prestation.cancelFees.fees && prestation.cancelFees.fees.length > 0"
    >
      <v-col cols="2" v-for="(fee, index) in prestation.cancelFees.fees" :key="index">
        <span class="body-2 font-weight-medium">{{ $t('from') }}</span>
        {{ fee.start }}
        <br/>
        <span class="body-2 font-weight-medium">{{ $t('to') }}</span>
        {{ fee.stop }}
        <br/>
        <span class="body-2 font-weight-medium">{{ $t('price') }}</span>
        {{ _formatCurrency(fee.price, 'EUR')}}
        <br/>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col cols="12">{{ $t('missing-cancelfees') }}</v-col>
    </v-row>
    <cancel-fees-edit-dialog :prestation="prestation" />

  </div>
</template>

<script>
import {post} from "@/utils/api";
import {EventBus} from "./event-bus";
import CancelFeeEdit from "@/components/commons/CancelFeeEdit.vue";
import {format, parseISO} from "date-fns";
import CancelFeesEditDialog from "@/components/commons/CancelFeesEditDialog.vue";

export default {
  name: "BookingPrestaCancelfees",
  components: {CancelFeesEditDialog},
  props: {
    prestation: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      feeDialog: false,
      savedCancelFees:[],
      addedFeeCount:0
    }
  },
created(){
},

};
</script>

<style>
.cancelfees .v-input__control {
  min-height: 0px !important;
  font-size: 14px !important;
}

.cancelfees .v-input__control .v-icon {
  font-size: 15px;
}
</style>
