<template>
  <layout :drawerShow="_isDesktop()" :drawerWidth="350">
    <template slot="drawer">
      <v-form ref="searchform" v-if="searchform" @submit.prevent="search">
        <v-row no-gutters class="pt-2 pr-1">
          <v-col cols="12">
            <v-subheader light class="pa-0 mt-5 mb-0 mx-0 form-subheader"
              >Région</v-subheader
            >
            <v-divider></v-divider>
          </v-col>

          <v-col cols="12" class="pr-1 mt-6">
            <v-select
              v-model="searchform.marketingAreas"
              return-object
              item-text="name"
              label="Région"
              :items="marketingAreas"
            ></v-select>
          </v-col>
          <v-col cols="12" class="pr-1">
            <v-subheader light class="pa-0 mb-0 mx-0 form-subheader"
              >Réseau</v-subheader
            >
            <v-divider></v-divider>
          </v-col>
          <v-col cols="12" class="pr-1 mt-6">
            <smart-multi-select
              v-model="searchform.networks"
              label="Réseaux"
              :items="networks"
            ></smart-multi-select>
          </v-col>
        </v-row>

        <smart-btn
          v-if="searchform.marketingAreas"
          :loading="loading"
          type="submit"
          block
          primary
          >Rechercher</smart-btn
        >
        <smart-btn
          block
          tertiary
          @click.native="
            clearSearchform();
            init();
          "
          >Réinitialiser la recherche</smart-btn
        >
      </v-form>
    </template>

    <template slot="content">
      <v-container fluid>
        <v-row>
          <v-col cols="12" class="mb-3">
            <v-card class="pa-0 ma-0 lighten-1">
              <WidgetAgencyActivitySegment
                @loading="setLoading"
                :bus="widgetBus"
                url="/api/widget/agency-stats/activity-segment"
              />
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="mb-3">
            <v-card class="pa-0 ma-0 lighten-1">
              <WidgetAgencyActiveSegment
                :bus="widgetBus"
                url="/api/widget/agency-stats/active-segment"
              />
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </layout>
</template>
<script>
import Vue from "vue";
import Layout from "@/views/Layout";
import { get, post } from "@/utils/api";
import SmartSelect from "@/components/commons/SmartSelect";
import SmartMultiSelect from "@/components/commons/SmartMultiSelect";
import FormMixin from "@/components/mixins/FormMixin";
import WidgetAgencyActivitySegment from "../../components/commons/widgets/WidgetAgencyActivitySegment.vue";
import WidgetAgencyActiveSegment from "../../components/commons/widgets/WidgetAgencyActiveSegment.vue";

export default {
  name: "AgenciesStats",
  components: {
    SmartMultiSelect,
    SmartSelect,
    Layout,
    WidgetAgencyActivitySegment,
    WidgetAgencyActiveSegment,
  },
  mixins: [FormMixin],
  data() {
    return {
      widgetBus: new Vue(),
      searchform: {},
      defaultSearchform: {
        marketingAreas: [],
        networks: [],
      },
      marketingAreas: [],
      networks: [],
      loading: false,
    };
  },
  async created() {
    this.clearSearchform();
    await this.loadMarketingAreas();
    await this.getNetworks();
    this.search();
  },
  methods: {
    search() {
      if (!this.loading) {
        this.$nextTick(() => {
          this.loadWidgets(this.searchform);
        });
      }
    },
    loadWidgets(form) {
      this.widgetBus.$emit("search", form);
    },
    async loadMarketingAreas() {
      const url = "/api/search/marketingarea";
      return get(url)
        .json((data) => {
          this.marketingAreas = data;
        })
        .then(() => {
          this.searchform.marketingAreas = this.marketingAreas[2];
        });
    },
    clearSearchform() {
      this.searchform = {
        ...this.defaultSearchform,
      };
    },
    async getNetworks() {
      const url = "/api/search/networks";
      return get(url).json((data) => {
        this.networks = data;
      });
    },
    setLoading(loading) {
      this.loading = loading;
    },
  },
};
</script>
<style></style>
