<template>
  <v-col cols="12">
    <v-tabs v-model="active" slider-color="primary">
      <v-tab key="search" ripple>Rechercher</v-tab>
      <v-tab key="create" ripple>Créer</v-tab>
      <!-- <v-tab-item key="search">
				<v-card flat>
					<v-card-text>
						<v-layout px-3>
							<v-combobox
									v-model="bookingsToAdd"
									label="Dossier à associer au projet de voyage"
									multiple
									small-chips
									persistent-hint
									deletable-chips
									type="number"
									placeholder="test, test ,test"
							>
								<template v-slot:selection="data">
									<v-chip
											:key="JSON.stringify(data.item)"
											:selected="data.selected"
											:disabled="data.disabled"
											class="v-chip--select-multi"
											@input="data.parent.selectItem(data.item)"
									>
										<v-avatar v-if="generateAvatarIcon(data.item)"
															class="white--text"
															color="green"
										>
											<v-icon>mdi-check</v-icon>
										</v-avatar>
										<v-avatar v-else
															class="white--text"
															color="red"
										>
											<v-icon>mdi-close</v-icon>
										</v-avatar>
										{{ data.item }}
									</v-chip>
								</template>
							</v-combobox>
						</v-layout>
						<v-flex xs-12 px-3>
							Dossiers vérifiés
							<v-layout px-3>
								<ul>
									<li v-for="booking in checkedBookings">{{booking.id}} {{booking.customer.firstname}}
										{{booking.customer.lastname}} du {{_parseAndFormatDate(booking.startDate,
										datePatternConfig.serverLocalDateTime, datePatternConfig.serverLocalDate)}} au
										{{_parseAndFormatDate(booking.stopDate, datePatternConfig.serverLocalDateTime,
										datePatternConfig.serverLocalDate)}}
									</li>
								</ul>
							</v-layout>
						</v-flex>

						<v-layout row justify-end px-3>
							<v-flex xs2>
								<smart-btn block primary @click.native="attachBookings()">Associer</smart-btn>
							</v-flex>
						</v-layout>
					</v-card-text>
				</v-card>

      </v-tab-item>-->
      <v-tab-item key="search">
        <v-card flat>
          <v-card-text>
            <v-row class="px-2 pb-3">
              <v-text-field
                v-model="search.value"
                clearable
                label="Identifiant, Nom du projet, Nom du client"
                hint="Elements séparés par une virgule: 1234, Voyage Vacances, Jean Martin"
                persistent-hint
                append-icon="mdi-magnify"
                @keypress.enter="searchBooking"
                @click:append="searchBooking"
                :loading="search.loading"
              ></v-text-field>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-list subheader dense v-if="search.results.length > 0">
                  <v-subheader
                    >{{ search.results.length }} Dossiers</v-subheader
                  >
                  <template v-for="(item, index) in search.results">
                    <v-list-tile
                      :key="'customer-' + item.id"
                      :active="item.active"
                    >
                      <v-list-tile-content>
                        <v-list-tile-title>
                          Dossier
                          {{
                            item.euroPrice ? " à " + item.euroPrice + "€" : ""
                          }}
                          {{
                            item.customer
                              ? "pour le client" + " " + item.customer
                              : ""
                          }}
                          créé le
                          {{
                            _parseAndFormatDate(
                              item.bookingDate,
                              datePatternConfig.serverLocalDateTime,
                              datePatternConfig.serverLocalDate
                            )
                          }}
                        </v-list-tile-title>
                      </v-list-tile-content>

                      <v-list-tile-action>
                        <v-checkbox v-model="item.selected"></v-checkbox>
                      </v-list-tile-action>
                    </v-list-tile>
                    <v-divider
                      v-if="index + 1 < search.results.length"
                      :key="'divider-' + index"
                    ></v-divider>
                  </template>
                </v-list>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="actions-btn">
            <v-spacer></v-spacer>
            <smart-btn primary dark @click.native="linkBooking()"
              >Associer les dossiers</smart-btn
            >
          </v-card-actions>
        </v-card>
      </v-tab-item>

      <v-tab-item key="create">
        <create-and-associate-booking-to-project
          :bookingProjectId="parseInt(bookingProjectId)"
        ></create-and-associate-booking-to-project>
      </v-tab-item>
    </v-tabs>
  </v-col>
</template>

<script>
import { get, post } from "../../utils/api";
import { EventBus } from "@/components/commons/event-bus";
import CreateAndAssociateBookingToProject from "../commons/CreateAndAssociateBookingToProject";

export default {
  name: "AddToBookingProject",
  components: { CreateAndAssociateBookingToProject },
  props: {
    bookingProjectId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      active: null,
      search: {
        value: null,
        loading: false,
        results: [],
      },
      bookingsToAdd: [],
    };
  },
  methods: {
    searchBooking() {
      const url = `/api/booking/search?search=${this.search.value}`;

      this.search.loading = true;

      get(url).json((r) => {
        this.search.results = r.bookings;
        this.search.loading = false;
      });
    },
    linkBooking() {
      const url = `/api/bookingproject/${this.bookingProjectId}/attachbookings`;

      this.bookingsToAdd = [];

      this.search.results.map((item) => {
        if (item.selected) {
          this.bookingsToAdd.push(item.bookingId);
        }
      });

      if (this.bookingsToAdd.length > 0) {
        post(url, this.bookingsToAdd)
          .badRequest((err) => {
            EventBus.$emit("toaster-msg", err);
          })
          .res((response) => {
            EventBus.$emit("toaster-msg", "Dossier associé");
            this.$emit("success", {});
            this.search = {
              ...this.search,
              ...{ value: null },
              ...{ results: [] },
            };
          });
      }
    },
  },
};
</script>

<style scoped></style>
