<template>
  <widget-container
      :url="url"
      :bus="bus"
      color="transparent flat"
      cardclass="pa-1 "
      @ready="setData"
  >
    <template
        slot="content"
        v-if="data && data.ca != 0"
    >
      <div>CA: {{ (data.ca) | currency('EUR') }}</div>
      <div class="body-2" v-if="!noDetails">
        HA {{ data.sale | currency('EUR', {maximumFractionDigits: 0}) }} |
        Mrg {{ markup | currency('EUR', {maximumFractionDigits: 0}) }} ·
        {{ data.markup * 100 | decimal({maximumFractionDigits: 2}) }} %
      </div>
      <div class="body-1" v-if="data.maybeCaOpts">{{ data.maybeCaOpts | currency('EUR') }} en option</div>
    </template>
    <template slot="default">
      -
    </template>
  </widget-container>
</template>

<script>
import WidgetContainer from "@/components/commons/widgets/WidgetContainer";

export default {
  name: "WidgetBookingCa",
  components: {WidgetContainer},
  mixins: [],
  props: {
    noDetails: {type: Boolean, default: false},
    bus: {
      type: Object,
      required: true
    },
    url: {
      type: String,
      required: false,
      default: '/api/widget/bookingCa'
    }
  },
  data() {
    return {
      search: null,
      data: null
    }
  },
  created() {
  },
  watch: {},
  computed: {
    markup(){
      return this.data.ca - this.data.sale
    }
  },
  methods: {
    setData(data) {
      this.data = data;
    }
  }
}
</script>

<style scoped>

</style>
