<template>
  <layout :drawerShow="_isDesktop()" :drawerWidth="350">
    <template slot="nav"> </template>
    <template slot="drawer">
      <v-form ref="searchform" v-if="searchform" @submit.prevent="loadData">
        <v-row dense>
          <v-subheader light class="pa-0 ml-1">Réservation</v-subheader>
          <v-col class="pr-1" cols="6">
            <smart-picker
              v-model="searchform.bookingDateStart"
              :stopDate="searchform.bookingDateStop"
              :inputOpts="{ label: $t('begin') }"
            />
          </v-col>
          <v-col cols="6">
            <smart-picker
              v-model="searchform.bookingDateStop"
              :startDate="searchform.bookingDateStart"
              :inputOpts="{ label: $t('end') }"
            />
          </v-col>

          <v-subheader light class="pa-0 mt-3 mb-0 ml-1"
            >Dates de voyage</v-subheader
          >
          <v-col class="pr-1" cols="6">
            <smart-picker
              v-model="searchform.tripDateStart"
              :stopDate="searchform.tripDateStop"
              :inputOpts="{ label: $t('begin') }"
            />
          </v-col>
          <v-col cols="6">
            <smart-picker
              v-model="searchform.tripDateStop"
              :startDate="searchform.tripDateStart"
              :inputOpts="{ label: $t('end') }"
            />
          </v-col>

          <v-subheader light class="pa-0 mt-3 mb-0 ml-1"
            >Pax sur place</v-subheader
          >
          <v-col class="pr-1" cols="6">
            <smart-picker
              v-model="searchform.paxOnPlaceDateStart"
              :stopDate="searchform.paxOnPlaceDateStop"
              :inputOpts="{ label: $t('begin') }"
            />
          </v-col>
          <v-col cols="6">
            <smart-picker
              v-model="searchform.paxOnPlaceDateStop"
              :startDate="searchform.paxOnPlaceDateStart"
              :inputOpts="{ label: $t('end') }"
            />
          </v-col>
        </v-row>

        <smart-btn type="submit" block primary class="mt-2"
          >Rechercher</smart-btn
        >
        <smart-btn
          block
          tertiary
          @click.native="
            clearSearchform();
            loadData();
          "
          >Réinitialiser la recherche</smart-btn
        >
      </v-form>
    </template>
    <template slot="content">
      <v-container fluid>
        <div class="text-right mb-2">
          <v-btn href="#" @click="exportCsv('bookingListTable')" small>
            <v-icon small round>mdi-file-download</v-icon> export
          </v-btn>
        </div>
        <v-data-table
          ref="bookingListTable"
          :loading="loading"
          :headers="headers"
          :items="bookings"
          no-data-text="Il n'y a pas de dossiers disponible"
          :footer-props="{
            'items-per-page-options': rowsPerPageItems,
            'rows-per-page-options': 'Lignes par page',
          }"
          class="elevation-1 tbl"
        >
          <template #body="{ items }">
            <tbody>
              <tr
                v-for="(item, idx) in items"
                :key="idx"
                @click="openBookingResume(item.bookingId)"
              >
                <td class="text-left font-weight-regular">
                  <span class="caption grey--text text--darken-1">
                    {{ item.bookingId }}
                  </span>
                  <site-label :sitename="item.siteName" />
                </td>
                <td class="text-left">
                  <div class="font-weight-regular">
                    {{
                      _parseAndFormatDate(
                        item.bookingDate,
                        datePatternConfig.serverLocalDateTime,
                        "dd/MM/yy"
                      )
                    }}
                  </div>
                  <div class="caption grey--text text--darken-1">
                    {{
                      _parseAndFormatDate(
                        item.bookingDate,
                        datePatternConfig.serverLocalDateTime,
                        datePatternConfig.fr.time
                      )
                    }}
                  </div>
                </td>
                <td class="text-left">
                  <div class="font-weight-regular">
                    {{
                      _parseAndFormatDate(
                        item.startDate,
                        datePatternConfig.serverLocalDateTime,
                        "dd/MM/yy"
                      )
                    }}
                  </div>
                  <div class="caption grey--text text--darken-1">
                    <!-- {{ $tc('nights', item.duration, {size:item.duration}) }}-->
                    {{
                      _parseAndFormatDate(
                        item.stopDate,
                        datePatternConfig.serverLocalDateTime,
                        "dd/MM/yy"
                      )
                    }}
                    • {{ item.duration }}n
                  </div>
                </td>
                <td class="text-left">
                  <div v-if="item.guestsName">
                    {{item.guestsName}}
                  </div>
                  <div
                    class="font-weight-regular"
                    v-else-if="item.customer && item.customer != ''"
                  >
                    {{ item.customer }}
                  </div>

                  <span
                    class="caption grey--text text--darken-1"
                    v-for="(guest, i) in item.guests"
                    :key="i + '-guest'"
                  >
                    {{
                      $tc(guest.ageClass + "-count-small", guest.size, {
                        size: guest.size,
                      })
                    }}
                  </span>
                </td>
                <td class="text-left">
                  <div
                    class="font-weight-regular"
                    v-if="item.customerPhone && item.customerPhone != ''"
                  >
                    {{ item.customerPhone }}
                  </div>
                </td>
                <td class="text-left font-weight-regular">
                  <div
                    v-if="item.hotelWithDesti"
                    v-html="renderHotelWithDesti(item.hotelWithDesti)"
                  ></div>
                </td>
                <td class="text-left font-weight-regular">
                  <span v-if="item.airlineCodes.length > 0">{{
                    item.airlineCodes.join(" ")
                  }}</span>
                  <span v-else> - </span>
                </td>
                <td class="text-left font-weight-regular">
                  <div class="" v-if="item.transferName">
                    <v-tooltip top>
                      <template #activator="{ on }">
                        <v-icon v-on="on" :size="16">mdi-bus-side</v-icon>
                      </template>
                      <span>{{ item.transferName }}</span>
                    </v-tooltip>
                    <div style="position:absolute;width:0px;height:0px;overflow:hidden">{{ item.transferName }}</div>
                  </div>
                  <div class="" v-if="item.carrentName">
                    <v-tooltip top>
                      <template #activator="{ on }">
                        <v-icon v-on="on" :size="15">mdi-car-side</v-icon>
                      </template>
                      <span>{{ item.carrentName }}</span>
                    </v-tooltip>
                    <div style="position:absolute;width:0px;height:0px;overflow:hidden">{{ item.carrentName }}</div>
                  </div>
                  <div class="" v-if="!item.transferName && !item.carrentName">
                    -
                  </div>
                </td>
                <td class="text-left">
                  <availability
                    v-model="item.bookingStatus"
                    kind="BookingStatus"
                  />
                  <availability
                    v-model="item.supplierStatus"
                    kind="SupplierStatus"
                    v-if="item.supplierStatus"
                  />
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-container>

      <smart-dialog
        v-model="bookingDialog.dialog"
        :title="'Dossier ' + bookingDialog.bookingId"
      >
        <booking-drawer-content
          :showprices="false"
          :booking="bookingDialog.selectedBooking"
        ></booking-drawer-content>
      </smart-dialog>
    </template>
  </layout>
</template>

<script>
import Layout from "@/views/Layout";
import { EventBus } from "@/components/commons/event-bus";
import { get, post } from "@/utils/api";
import Vue from "vue";
import Availability from "@/components/commons/Availability";
import SmartSelect from "../../components/commons/SmartSelect";
import SiteLabel from "../../components/commons/SiteLabel";
import BookingDrawerContent from "../../components/commons/BookingDrawerContent";
import ExportMixin from "@/components/mixins/ExportMixin";

export default {
  components: {
    SiteLabel,
    SmartSelect,
    Availability,
    Layout,
    BookingDrawerContent,
  },
  mixins: [ExportMixin],
  data() {
    return {
      bookingDialog: {
        bookingId: null,
        dialog: false,
        selectedBooking: null,
      },

      loading: false,
      headers: [
        { text: "SITE", value: "siteName", align: "left" },
        { text: "RÉSA", value: "bookingDate", align: "left" },
        { text: "SÉJOUR", value: "startDate", align: "left", width: "10%" },
        { text: "CLIENT", value: "customer", align: "left" },
        { text: "TEL", value: "customerPhone", align: "left" },
        {
          text: "DESTINATION",
          value: "hotelWithDesti",
          align: "left",
          sortable: false,
        },
        { text: "VOLS", value: "airlineCodes", align: "left", sortable: false },
        {
          text: "TRSF.",
          value: "transferName",
          align: "left",
          sortable: false,
        },
        {
          text: "STATUTS",
          value: "bookingStatus",
          align: "left",
          sortable: false,
        },
      ],
      bookings: [],

      //searchform
      defaultSearchform: {
        selectedSites: [],
        selectedNetworks: [],
        selectedAgencies: [],
        selectedChains: [],
        selectedHotels: [],
        selectedDestinations: [],
        client: null,
        ref: null,
        bookingDateStart: this._nowFormatted("dd/MM/yyyy"),
        bookingDateStop: this._nowFormatted("dd/MM/yyyy"),
        tripDateStart: null,
        tripDateStop: null,
        paxOnPlaceDateStart: null,
        paxOnPlaceDateStop: null,
        bookingStatus: ["booked"],
        supplierStatus: ["confirmed"],
        paymentStatus: null,
        destinationId: null,
        tags: [],
      },
      searchform: null,

      //
      valid: false,
      bookingStatus: [
        { value: "quotation", label: "Devis" },
        { value: "booked", label: "Réservation" },
        { value: "deleted", label: "(supprimé)" },
      ],

      rowsPerPageItems: [{ text: "Tous", value: -1 }, 50, 100],

      //------
      widgetBus: new Vue(),
    };
  },
  created() {
    setTimeout(() => {
      this.clearSearchform();
      this.initSearchform();
      this.loadData();
    }, 1);
  },
  mounted() {},
  computed: {},
  watch: {},
  methods: {
    loadData() {
      //set qs
      window.history.pushState(
        "",
        "",
        "?search=" + JSON.stringify(this.searchform)
      );

      this.getBookings();
    },
    getBookings() {
      const withDesti = {
        ...this.searchform,
        ...{ destinationId: this.currentUser.defaultDestiId },
      };
      post("/api/extranet/bookings/onplaceagent", withDesti).json(
        (data) => (this.bookings = data)
      );
    },
    initSearchform() {
      const qs = new URLSearchParams(window.location.search);
      const search = (() => {
        try {
          return JSON.parse(qs.get("search"));
        } catch (e) {
          return null;
        }
      })();

      if (search) {
        this.searchform = search;
      } else this.clearSearchform();
    },
    clearSearchform() {
      this.searchform = { ...this.defaultSearchform };
      // /!\ black magic /!\ do not remove /!\
      this.$nextTick(() => {
        this.searchform.tripDateStart = null;
        this.searchform.tripDateStop = null;
      });
    },

    //
    openBookingResume(bookingId) {
      if (bookingId < 300000) {
        window
          .open(
            "https://admin.smart-be.com/SmartBe-admin/on-place-agent/bookings?bookingid=" +
              bookingId,
            "_blank"
          )
          .focus();
      } else {
        const url = `/api/booking/${bookingId}`;
        get(url)
          .json((r) => {
            this.bookingDialog.bookingId = bookingId;
            this.bookingDialog.selectedBooking = r.data;
            this.bookingDialog.selectedBooking.prestations = this.bookingDialog.selectedBooking.prestations.filter(p => p.status == 'booked' && p.supplierStatus == 'confirmed');
          })
          .then(() => {
            this.bookingDialog.dialog = true;
          });
      }
    },
    renderHotelWithDesti(hotelWithDesti) {
      return hotelWithDesti
        .map((hotel) => {
          return `
						<div class="text-xs-left font-weight-regular">${hotel[0]}: ${hotel[1]}</div>
					`;
        })
        .join("");
    },
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.v-subheader {
  width: 100%;
  height: 20px;
  color: #616161;
}

td:hover {
  cursor: pointer;
}
</style>











