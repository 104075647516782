<template>
  <v-breadcrumbs divider="/" class="hidden-sm-and-down">
    <v-breadcrumbs-item
        v-for="(breadcrumb, idx) in breadcrumbList"
        :key="idx"
        @click.native="routeTo(idx)"
        :disabled="!!!breadcrumb.route"
        exact
    > {{ nameOf(idx) }}
    </v-breadcrumbs-item>
  </v-breadcrumbs>
</template>
<script>
	export default {
		name: "Breadcrumb",
		data() {
			return {
				breadcrumbList: []
			}
		},
		mounted() {
			this.updateList()
		},
		watch: {
			'$route'() {
				this.updateList()
			}
		},
		methods: {
			routeTo(pRouteTo) {
				if (this.breadcrumbList[pRouteTo].route) {
					const route = this.breadcrumbList[pRouteTo].route
					if (typeof route === 'function') {
						const params = Object.values(this.$route.params)

						this.$router.push(route(params))
					} else {
						this.$router.push(route)
					}

				}
			},
			nameOf(pRouteTo) {
				if (this.breadcrumbList[pRouteTo].name) {
					const name = this.breadcrumbList[pRouteTo].name
					if (typeof name === 'function') {
						const params = Object.values(this.$route.params)

						return name(params)
					} else {
						return name
					}

				}
			},
			updateList() {
				//ternary is just a hotfix. We should render a home per userrole ?
        const home = this.isAdmin ? [{
          name:'Accueil',
          route:{name:'Home'}
        }] : []

				this.breadcrumbList = home.concat(
				  this.$route.meta.breadcrumb || []
        )
			}
		}
	}
</script>

<style scoped>
    ul {
        padding: 0
    }
</style>
