<template>
  <v-container fluid>
    <v-row dense align="center" class="sort-row">
      <v-col cols="6" offset="2" class="pl-5">
        <div class="headline">
          <span v-if="showFlies()">
            <v-tooltip top v-if="selected.length > 0">
              <template #activator="{ on }">
                <span v-on="on">
                  <v-icon @click="removeSelection(selected.length - 1)"
                    >mdi-chevron-left</v-icon
                  >
                </span>
              </template>
              <span>Retourner au vol précédent</span>
            </v-tooltip>
            <v-icon v-else>mdi-airplane</v-icon>

            <span>Vol n° {{ selected.length + 1 }}</span>
            <small>
              · {{ searchCtx.itenaries[selected.length].from }} -
              {{ searchCtx.itenaries[selected.length].to }} ·
              {{ searchCtx.itenaries[selected.length].date }}
            </small>
          </span>
          <span v-else>Votre sélection</span>
        </div>
      </v-col>
      <v-col v-if="showFlies()" cols="1" class="text-right">{{ $t("sort-by") }}:</v-col>
      <v-col v-if="showFlies()" cols="1">
        <v-menu bottom left>
          <template #activator="{ on }">
            <v-btn :ripple="false" text v-on="on">
              <v-tooltip bottom>
                <template #activator="{ on }">
                  <span v-on="on">
                    <v-icon>{{ sortIcon.icon }}</v-icon>
                  </span>
                </template>
                <span>{{ sortIcon.label }}</span>
              </v-tooltip>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(sortIcon, i) in sortIcons"
              :key="i"
              @click="updateSortIcon(sortIcon)"
            >
              <v-list-item-avatar>
                <v-icon>{{ sortIcon.icon }}</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ sortIcon.label }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="2" class="pr-3">
        <v-row dense>
          <v-col cols="12">
            <v-subheader class="pl-0">Marketing Airline</v-subheader>
            <v-select
              class="mt-0 pt-0"
              prepend-icon="mdi-airport"
              item-value="code"
              item-text="name"
              return-object
              v-model="filter.selectedMarketingAirline"
              v-if="marketingAirlines.length > 0"
              :items="marketingAirlines"
            ></v-select>
          </v-col>
          <v-col cols="12">
            <v-subheader class="pl-0">Validating Airline</v-subheader>
            <v-select
              class="mt-0 pt-0"
              prepend-icon="mdi-airport"
              item-value="code"
              item-text="name"
              return-object
              v-model="filter.selectedValidatingAirline"
              v-if="validatingAirlines.length > 0"
              :items="validatingAirlines"
            ></v-select>
          </v-col>
          <v-col cols="12">
            <v-subheader class="pl-0">Escales</v-subheader>
            <v-select
              class="mt-0 pt-0"
              prepend-icon="mdi-timer"
              item-value="code"
              item-text="name"
              return-object
              v-model="filter.stops"
              v-if="stops.length > 0"
              :items="stops"
            ></v-select>
          </v-col>
          <v-col cols="12">
            <v-subheader class="pl-0"
              >Heure de départ ({{ hours[0] }}, {{ hours[1] }})</v-subheader
            >
            <v-range-slider
              class="mt-0 pt-0"
              persistent-hint
              thumb-label
              v-model="hours"
              :min="0"
              :max="23"
              :step="1"
              @change="updateFilter()"
            ></v-range-slider>
          </v-col>
          <v-col cols="12">
            <v-subheader class="pl-0"
              >Heure d'arrivée({{ hoursArrival[0] }}, {{ hoursArrival[1] }})</v-subheader
            >
            <v-range-slider
              class="mt-0 pt-0"
              persistent-hint
              thumb-label
              v-model="hoursArrival"
              :min="0"
              :max="23"
              :step="1"
              @change="updateFilter()"
            ></v-range-slider>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="8" v-if="showFlies()">
        <flight-price-details
          :flies="flies"
          :sort="sortIcon.value"
          :filter="filter"
          :selectedfly="selected[selected.length - 1]"
          :is-for-selection="true"
          @flychosen="chose"
        ></flight-price-details>
      </v-col>
      <v-col cols="8" v-else-if="!showFlies()">
        <div v-for="(selection, index) in selected" :key="index">
          <flight-price-details
            v-if="selected.length > 0"
            :flies="[selection]"
            :selectedfly="selected[index - 1]"
            :sort="sortIcon.value"
            :filter="filter"
            :is-for-selection="false"
          ></flight-price-details>
          <v-divider v-if="index != selected.length - 1" class="flight-sep"></v-divider>
        </div>
      </v-col>
      <v-col cols="2" class="pl-3">
        <span v-if="selected[selected.length - 1]">
          <v-row dense>
            <v-col
              v-for="(select, n) in selected"
              :key="n"
              cols="12"
              class="flight-summary"
            >
              <v-card>
                <v-card-text>
                  <div class="subheading subheading-summary">
                    Vol n°{{ n + 1 }}: {{ select.from.code }} -
                    {{ select.to.code }}
                    <v-icon color="red darken-1" @click="removeSelection(n)" size="16"
                      >delete</v-icon
                    >
                  </div>
                  <div class="body-2">
                    <v-icon size="16">mdi-airplane-takeoff</v-icon>
                    {{ $t("departure") }}
                    <br />
                    {{
                      _parseAndFormatLocalDateTime(
                        select.departureDate,
                        datePatternConfig.fr.fullWithTime
                      )
                    }}
                  </div>
                  <div class="body-2">
                    <v-icon size="16">mdi-airplane-landing</v-icon>
                    {{ $t("arrival") }}
                    <br />
                    {{
                      _parseAndFormatLocalDateTime(
                        select.arrivalDate,
                        datePatternConfig.fr.fullWithTime
                      )
                    }}
                  </div>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12" class="text-center">
              <v-card>
                <v-card-text>
                  <div class="subheading">Prix total:</div>
                  <div class="headline">
                    {{ selected[selected.length - 1].pricing.price }}
                    {{ $t("EUR-symbol") }}
                  </div>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12" v-if="!showFlies()">
              <smart-btn
                v-if="!flightSelected"
                text
                block
                :loading="loading"
                @click.native="selectFlight()"
                >{{ $t("add-flight") }}</smart-btn
              >
              <smart-btn
                v-else
                :loading="loading"
                block
                @click.native="editdialog = !editdialog"
                >{{ $t("edit-flight") }}</smart-btn
              >
            </v-col>
          </v-row>
        </span>
        <span v-else>Aucun vol sélectionné</span>
      </v-col>
    </v-row>

    <v-dialog
      v-if="flightSelected && selected"
      v-model="editdialog"
      :max-width="1200"
      persistent
    >
      <v-card>
        <v-toolbar dark color="primary" dense>
          <v-toolbar-title>Edition vols</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click.native="editdialog = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-title>
          <v-row dense>
            <v-col cols="6" class="headline">Vol actuel</v-col>
            <v-col cols="6" class="headline">Vol selectionné</v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row dense>
            <v-col cols="6" class="pa-1">
              <v-row dense class="pb-2">
                <v-col
                  cols="12"
                  v-for="(fly, index) in flightSelected.allRoutes"
                  :key="index"
                >
                  <v-row dense class="pa-1">
                    <v-col cols="4">
                      <div class="font-weight-medium">
                        <v-tooltip bottom>
                          <template #activator="{ on }">
                            <span v-on="on">{{
                              _parseAndFormatLocalDateTime(
                                fly.start,
                                datePatternConfig.fr.time
                              )
                            }}</span>
                          </template>
                          <span>{{
                            _parseAndFormatLocalDateTime(fly.start)
                          }}</span> </v-tooltip
                        >-
                        <v-tooltip bottom>
                          <template #activator="{ on }">
                            <span v-on="on">
                              {{
                                _parseAndFormatLocalDateTime(
                                  fly.stop,
                                  datePatternConfig.fr.time
                                )
                              }}
                              <sup v-html="renderSupDays(fly)"></sup>
                            </span>
                          </template>

                          <span>{{ _parseAndFormatLocalDateTime(fly.start) }}</span>
                        </v-tooltip>

                        <div
                          class="caption font-weight-medium grey--text text--lighten-1"
                        >
                          {{ fly.segments[0].marketingAirlinename }}
                        </div>
                      </div>
                    </v-col>
                    <v-col cols="4">
                      {{ $t("duration") }}:
                      <span class="font-weight-medium">{{
                        _durationFormatted(fly.start, fly.stop, datePatternConfig.fr.time)
                      }}</span>
                      <div class="caption font-weight-medium grey--text text--lighten-1">
                        {{ fly.fromCode }} -
                        {{ fly.toCode }}
                      </div>
                    </v-col>
                    <v-col cols="4">
                      <div v-if="fly.segments.length == 1">Sans escales</div>
                      <div v-else>
                        <div>
                          {{
                            $tc("flystops", fly.segments.length - 1, {
                              size: fly.segments.length - 1,
                            })
                          }}
                        </div>
                        <div
                          class="caption font-weight-medium grey--text text--lighten-1"
                        >
                          {{ flyStopsCodes(fly.segments, "stop", "start", "toCode") }}
                        </div>
                      </div>
                    </v-col>
                    <v-col cols="12" v-if="index + 1 < flightSelected.allRoutes.length">
                      <v-divider></v-divider>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6" class="pa-1">
              <v-row dense class="pb-2">
                <v-col cols="12" v-for="(fly, index) in selected" :key="index">
                  <v-row dense class="pa-1">
                    <v-col cols="4">
                      <div class="font-weight-medium">
                        <v-tooltip bottom>
                          <template #activator="{ on }">
                            <span v-on="on">{{
                              _parseAndFormatLocalDateTime(
                                fly.departureDate,
                                datePatternConfig.fr.time
                              )
                            }}</span>
                          </template>
                          <span>{{
                            _parseAndFormatLocalDateTime(fly.departureDate)
                          }}</span> </v-tooltip
                        >-
                        <v-tooltip bottom>
                          <template #activator="{ on }">
                            <span v-on="on">
                              {{
                                _parseAndFormatLocalDateTime(
                                  fly.arrivalDate,
                                  datePatternConfig.fr.time
                                )
                              }}
                              <sup v-html="renderSupDays(fly)"></sup>
                            </span>
                          </template>
                          <span>{{ _parseAndFormatLocalDateTime(fly.arrivalDate) }}</span>
                        </v-tooltip>

                        <div
                          class="caption font-weight-medium grey--text text--lighten-1"
                        >
                          {{ fly.segments[0].marketingAirline.name }}
                        </div>
                      </div>
                    </v-col>
                    <v-col cols="4">
                      {{ $t("duration") }}:
                      <span class="font-weight-medium">{{
                        _durationFormatted(
                          fly.departureDate,
                          fly.arrivalDate,
                          datePatternConfig.fr.time
                        )
                      }}</span>
                      <div class="caption font-weight-medium grey--text text--lighten-1">
                        {{ fly.from.code }} -
                        {{ fly.to.code }}
                      </div>
                    </v-col>
                    <v-col cols="4">
                      <div v-if="fly.segments.length == 1">
                        Sans escales
                        <!--{{fly.hash}}-->
                      </div>
                      <div v-else>
                        <div>
                          {{
                            $tc("flystops", fly.segments.length - 1, {
                              size: fly.segments.length - 1,
                            })
                          }}
                        </div>
                        <div
                          class="caption font-weight-medium grey--text text--lighten-1"
                        >
                          {{ flyStopsCodes(fly.segments) }}
                        </div>
                      </div>
                    </v-col>

                    <v-col cols="12" v-if="index + 1 < selected.length">
                      <v-divider></v-divider>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6" class="pa-1">
              <v-row dense class="ma-1">
                <v-col cols="12">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="8">Total</v-col>
                <v-col cols="4" class="font-weight-medium text-right">
                  {{ flightSelected.price }}
                  {{ $t("EUR-symbol") }}
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6" class="pa-1">
              <v-row dense class="ma-1">
                <v-col cols="12">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="8">Total</v-col>

                <v-col
                  cols="4"
                  class="font-weight-medium text-right"
                  v-if="selected && selected[selected.length - 1]"
                >
                  {{ selected[selected.length - 1].pricing.price }}
                  {{ $t("EUR-symbol") }}
                  <small v-if="selected">
                    (
                    <price-diff
                      :cssdisplay="'inline'"
                      :show-baseprice="false"
                      :new-price="selected[selected.length - 1].pricing.price"
                      :base-price="flightSelected.price"
                    ></price-diff
                    >)
                  </small>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <smart-btn primary :loading="loading" @click.native="edit">{{
            $t("add")
          }}</smart-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import FlightPriceDetails from "@/components/booking/flight/FlightPriceDetails";
import FlightsMixin from "@/components/mixins/FlightsMixin";
import { EventBus } from "@/components/commons/event-bus";
import _ from "lodash";
import { get, post } from "@/utils/api";
import PriceDiff from "@/components/commons/PriceDiff";

export default {
  name: "FlightPrice",
  components: { PriceDiff, FlightPriceDetails },
  props: {
    booking: {
      type: Object,
      required: true,
    },
    searchCtx: {
      type: Object,
      required: true,
    },
    searchPassenger: {
      type: Array,
      required: true,
    },
    flyTrips: {
      type: Array,
      required: true,
    },
    flightSelected: {
      type: Object,
      required: false,
    },
  },
  mixins: [FlightsMixin],
  data: function () {
    return {
      editdialog: false,

      selected: [],

      allFlies: [],
      flies: [],

      loading: false,

      maxFlies: 0,

      sortIcon: { icon: "mdi-currency-eur", label: "Prix", value: "price" },
      sortIcons: [
        { icon: "mdi-currency-eur", label: "Prix", value: "price" },
        {
          icon: "mdi-clock-out",
          label: "Heure de départ",
          value: "departure-time",
        },
        {
          icon: "mdi-clock-in",
          label: "Heure d'arrivée",
          value: "arrival-time",
        },
        { icon: "mdi-clock-fast", label: "Durée", value: "duration" },
      ],

      hours: [0, 23],
      hoursArrival: [0, 23],
      filter: { ...this.defaultFilter },

      marketingAirlines: [],
      validatingAirlines: [],
      operatingAirlines: [],
      stops: [],
    };
  },
  created() {},
  watch: {
    flyTrips: {
      handler(newTrips) {
        this.filter = this.defaultFilter;

        this.allFlies = newTrips;
        this.selected = [];
        this.flies = [];
        this.maxFlies = this.allFlies[0] != null ? this.allFlies[0].flies.length : 0;

        this.buildFlies();
        this.airlines();
      },
      deep: true,
    },
  },
  computed: {
    defaultFilter() {
      return {
        hours: [0, 23],
        hoursArrival: [0, 23],
        selectedMarketingAirline: {},
        selectedValidatingAirline: {},
        stops: [],
      };
    },
  },
  methods: {
    updateFilter() {
      this.filter = {
        ...this.filter,
        ...{ hours: this.hours },
        ...{ hoursArrival: this.hoursArrival },
      };
    },

    showFlies() {
      return this.selected.length < this.maxFlies;
    },

    airlines() {
      const all = { code: "all", name: "Tous" };
      //let operating = [all]
      let validatings = [all];
      let marketings = [all];
      let stops = [];

      this.allFlies.forEach((fly) => {
        fly.prices.forEach((price) => {
          price.details.forEach((detail) => {
            validatings.push({
              code: detail.validatingAirline,
              name: detail.validatingAirline,
            });
          });
        });
        fly.flies.forEach((f) => {
          f.segments.forEach((s) => {
            marketings.push(s.marketingAirline);
            //operating.push(s.operatingAirline)
          });
        });
        fly.flies.forEach((f) => {
          const i = f.segments.length - 1;
          stops.push({ code: i, name: i });
        });
      });

      this.marketingAirlines = _.uniq(marketings);
      this.validatingAirlines = _.uniq(validatings);
      this.stops = [all].concat(_.reverse(_.uniq(stops)));
      this.filter.selectedMarketingAirline = this.marketingAirlines[0];
      this.filter.selectedValidatingAirline = this.validatingAirlines[0];
      this.filter.stops = this.stops[0];
      //this.operatingAirlines = _.uniq(operating)
    },

    buildFlies() {
      this.flies = _.uniqBy(
        this.allFlies.map((trip) => {
          const pricing = { pricing: trip.prices[0].pricing };
          return {
            ...trip.flies[0],
            ...pricing,
            ...{ triphash: trip.hash },
            ...{ prices: trip.prices[0] },
          };
        }),
        (trip) => {
          return trip.prices.details[0].validatingAirline + "-" + trip.hash;
        }
      );
    },

    buildNextFlies() {
      const hashes = this.selected.map((s) => s.hash);

      const corresponding = this.allFlies.reduce((acc, current) => {
        let doAdd = true;
        for (let i = 0; i < hashes.length; i++) {
          doAdd = doAdd && current.flies[i].hash == hashes[i];
        }

        if (doAdd) {
          acc.push(current);
        }

        return acc;
      }, []);

      const nextFlies = corresponding.map((trip) => {
        const prices = trip.prices[0];
        const pricing = { pricing: prices.pricing };
        return {
          ...trip.flies[this.selected.length],
          ...pricing,
          ...{ triphash: trip.hash },
          ...{ prices: prices },
        };
      });

      this.flies = _.uniqBy(nextFlies, "hash");
    },

    updateSortIcon(val) {
      this.sortIcon = val;
    },

    chose(fly) {
      this.selected.push(fly);
      this.buildNextFlies();
    },

    removeSelection(index) {
      this.selected = this.selected.slice(0, index);
      if (this.selected.length == 0) {
        this.buildFlies();
      } else {
        this.buildNextFlies();
      }
    },

    selectFlight() {
      this.loading = true;

      post(`/api/prestation/add/flights?bookingId=${this.booking.id}`, this.buildCtx())
        .internalError((err) => {
          EventBus.$emit("toaster-error", this.$t("error-500"));
        })
        .badRequest((err) => {
          EventBus.$emit("toaster-error", JSON.parse(err.message).errors.join(","));
        })
        .json((data) => {
          EventBus.$emit("toaster-msg", this.$t("flightadded"));
          setTimeout(() => {
            EventBus.$emit("reloadbooking", {});
            EventBus.$emit("prestation-added", {});
          }, 500);
        })
        .then(() => {
          this.loading = false;
          EventBus.$emit("flightadded", {});
        })
        .catch(() => {
          this.loading = false;
        });
    },

    edit() {
      this.loading = true;

      post(
        `/api/prestation/edit/flights?bookingId=${this.booking.id}&prestationGroupId=${this.flightSelected.prestationGroupId}`,
        this.buildCtx()
      )
        .forbidden((err) => console.log(err.status)) //TODO
        .internalError((err) => {
          EventBus.$emit("toaster-error", this.$t("error-500"));
        })
        .badRequest((err) => {
          EventBus.$emit("toaster-error", JSON.parse(err.message).errors.join(","));
        })
        .json((data) => {
          EventBus.$emit("toaster-msg", this.$t("flightadded"));
          EventBus.$emit("reloadbooking", {});
          this.editdialog = false;
          setTimeout(() => {
            EventBus.$emit("flight-edited", {});
          }, 500);
        })
        .then(() => {
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    buildCtx() {
      const tripHash = this.selected[this.selected.length - 1].triphash;
      const flyTrip = this.flyTrips.find((ft) => ft.hash == tripHash);

      const price = this.selected[this.selected.length - 1].prices;

      return {
        ctx: {
          flies: flyTrip.flies,
          price: price,
          passengers: flyTrip.passengers,
          customer: {
            countryCode: this.booking.customer.address.country,
            email: this.booking.customer.email,
            tel: this.booking.customer.phone != "" ? this.booking.customer.phone : null,
          },
          bookingId: this.booking.id,
          env: {
            mode: "PROD",
            siteId: this.booking.context.site.id,
            priceClassTypeId: this.booking.context.priceClassType.id,
          },
        },
        guests: this.searchPassenger,
      };
    },
  },
};
</script>

<style scoped>
.sort-row {
  min-height: 48px;
}

.flight-summary {
  margin-bottom: 10px;
}

.subheading-summary {
  margin-bottom: 5px;
  border-bottom: 1px solid #d9d9d9;
}

.flight-sep {
  width: 95%;
  margin: auto;
}
</style>
