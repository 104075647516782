<template>
  <div class="elevation-1">
    <v-simple-table expand hide-actions :loading="loading" class="tbl">
      <template #default>
        <thead v-if="!hideheaders">
          <tr>
            <th
              v-for="(header, headerIdx) in headers"
              :class="header.class"
              :key="headerIdx"
            >
              <div v-html="header.text"></div>
            </th>
          </tr>
        </thead>
        <tbody v-if="items.length == 0">
          <div>
            {{ hotel.name }}
            <div class="hotel">
              {{ hotel.rating }}* -
              <span
                class="tag"
                v-for="(tag, tagIdx) in hotel.tags"
                :key="tagIdx"
                >{{ tag }}</span
              >
            </div>
          </div>
        </tbody>
        <tbody v-else>
          <tr
            v-for="(item, idx) in items.filter(
              (item) => !item.isRoom || (item.isRoom && item.show)
            )"
            :key="idx"
          >
            <td
              class="day-label"
              @click="expand(item)"
              :class="{ isroom: item.isRoom }"
              style="position: absolute; background: #fff"
            >
              <div :class="{ 'room-label': item.isRoom }">
                {{ item.roomName }}
                <product-details
                  :title="item.roomName"
                  :services-items="[]"
                  :productId="item.productId || item.rooms[0].roomId"
                  :siteId="env.siteId"
                  :product-type="item.isRoom ? 'room' : 'hotel'"
                ></product-details>
                <div v-if="!item.isRoom" class="hotel">
                  {{ hotel.rating }}* -
                  <span
                    class="tag"
                    v-for="(tag, idxTags) in hotel.tags"
                    :key="idxTags"
                    >{{ tag }}</span
                  >
                </div>
              </div>
            </td>
            <td
              class="day-cell"
              v-for="(priceday, day) in item.priceByDays"
              @mouseenter="item.selected = priceday"
              @mouseleave="item.selected = null"
              :class="{ weekend: priceday.isWeekEnd }"
              :key="day"
            >
              <calendar-cell
                slot="activator"
                :day="day"
                :priceday="priceday"
                :hotelStock="priceday.hotelStock"
                :minPrice="item.minPrice"
                :selected="item.selected"
                @select="select"
              ></calendar-cell>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>
<script>
import _ from "lodash";
import { post } from "@/utils/api";
import CalendarCell from "./CalendarCell";
import ProductDetails from "../../components/commons/ProductDetails";


export default {
  name: "CalendarHotel",
  components: {
    ProductDetails,
    CalendarCell,
  },
  loading: false,
  props: {
    hotel: { type: Object, required: true },
    withFlights: { type: Boolean, default: false },
    withTransfers: { type: Boolean, default: false },
    flights: { type: Object, required: true },
    flightsLoading: { type: Boolean, required: true },
    startDate: { type: String, required: true },
    stopDate: { type: String, required: true },
    nbNights: { type: Number, required: true },
    roomsRepartition: { type: Array, required: true },
    env: { type: Object, required: true },
    hideheaders: { type: Boolean, default: false },
    mealPlanTypeIds: { type: Array, default: () => [] },
  },
  data: () => ({
    headers: [],
    items: [],
    hotelPrices: {},
    transfers: {},
    hotelCtx: {
      hotelId: null,
      bookingDate: "",
      startDate: null,
      stopDate: null,
      roomsRepartition: [
        {
          guests: [
            { ageClassType: "Adult", id: 0 },
            { ageClassType: "Adult", id: 1 },
          ],
          repartition: "SAMEROOM",
        },
      ],
      declarations: [],
      roomTypeIds: [],
      mealPlanTypeIds: [],
      extras: [],
      hotelSelections: [],
      env: {
        siteId: 1,
        langId: 1,
        priceClassTypeId: 2,
        strokePriceClassTypeId: 3,
        clientCountry: "FR",
        debug: false,
        withCache: false,
      },
    },
    hotelLoading: false,
  }),
  created() {
    this.init();
  },
  watch: {
    startDate() {
      //this.init()
    },
    stopDate() {
      //this.init()
    },
    nbNights() {
      //this.hotelPrices = {}
      //this.init()
    },
    roomsRepartition() {
      //this.hotelPrices = {}
      //this.init()
    },
    env() {
      //this.hotelPrices = {}
      //this.init()
    },
    flights: {
      handler: function (newValue) {
        if (!this.hotelLoading) {
          this.build(newValue);
        }
      },
      deep: true,
    },
  },
  computed: {
    loading() {
      if (this.withFlights) {
        this.$emit("loading", this.hotelLoading || this.flightsLoading);
        return this.hotelLoading || this.flightsLoading;
      }
      this.$emit("loading", this.hotelLoading);
      return this.hotelLoading;
    },
  },
  methods: {
    selectRoom(roomName, p) {
      this.items.forEach((item, i) => {
        if (item.roomName === roomName) {
          this.items[i].selected = p;
        }
      });
    },
    expand(props) {
      if (props.isRoom) {
        props.expanded = !props.expanded;
      } else {
        props.expanded = false;
        this.items = this.items.map((i) => {
          i.show = !i.show;
          return i;
        });
      }
    },
    init() {
      this.items = [];
      this.headers = [];

      this.hotelCtx = Object.assign({}, this.hotelCtx, {
        bookingDate: this._nowFormatted(this._datePattern.localDateTime),
        hotelId: this.hotel.id,
        roomsRepartition: this.roomsRepartition,
        mealPlanTypeIds: this.mealPlanTypeIds,
        env: this.env,
      });

      const start = this._parseDate(
        this.startDate,
        this._datePattern.localDate
      );
      const stop = this._parseDate(this.stopDate, this._datePattern.localDate);
      var days = this._duration(start, stop, "days");

      this.headers.push({
        text: "",
        sortable: false,
        class: ["day-label"],
        align: "center",
      });

      for (let i = 0; i < days; i++) {
        this.headers.push({
          text: this._formatDate(
            this._addDays(start, i),
            "'<small>'eee'</small><br/>'dd/MM"
          ),
          align: "center",
          sortable: false,
          isDay: true,
          class: ["day-cell"],
        });
      }
      //this.headers.push({text:'', sortable:false})

      this.hotelLoading = true;
      var promises = [];

      const pStock = post(`/api/search/hotel-stocks`, {
        hotelId: this.hotelCtx.hotelId,
        startDate: this.startDate,
        stopDate: this.stopDate,
        roomTypes: [],
      }).json((data) => {
        this.stocks = data;
      });

      promises.push(pStock);

      for (let i = 0; i < days + 1; i++) {
        const day = this._addDays(start, i);
        const dayFormatted = this._formatDate(day, this._datePattern.localDate);
        if (!this.hotelPrices[dayFormatted]) {
          const startdate = dayFormatted;
          const stopdate = this._formatDate(
            this._addDays(day, this.nbNights),
            this._datePattern.localDate
          );

          const pHotel = post(
            `/api/search/hotel-light`,
            Object.assign({}, this.hotelCtx, {
              startDate: startdate,
              stopDate: stopdate,
            })
          ).json((data) => {
            this.hotelPrices[dayFormatted] = {
              hotel: data.hotel,
              hotelStart: startdate,
              hotelStop: stopdate,
            };
            //this.build()
          });

          promises.push(pHotel);

          if (this.withTransfers) {
            const pTransfer = post(`/api/search/transfer`, {
              hotelId: this.hotel.id,
              transferPOI: this.hotel.transferPOI,
              startDate: startdate,
              stopDate: stopdate,
              guests: _.flatMap(this.roomsRepartition, (r) => r.guests),
              env: this.hotelCtx.env,
              // l'objet prestation sera fait server side car il n'y a pas d'objet.
            }).json((data) => {
              this.transfers[dayFormatted] = {
                transfer: data,
                transferStart: startdate,
                transferStop: stopdate,
              };
              //this.build()
            });
            promises.push(pTransfer);
          }
        }
      }

      Promise.all(promises)
        .then(() => {
          this.hotelLoading = false;
          this.build(this.flights);
        })
        .catch(() => {
          this.hotelLoading = false;
        });
    },
    build(flights) {
      if (this.withFlights && Object.keys(this.flights).length === 0) {
        return;
      }

      var items = [];

      const start = this._parseDate(
        this.startDate,
        this._datePattern.localDate
      );
      const stop = this._parseDate(this.stopDate, this._datePattern.localDate);
      const guests = _.flatMap(this.roomsRepartition, (r) => r.guests);

      var days = this._duration(start, stop, "days");

      const defaultPriceDays = {};
      for (var i = 0; i < days; i++) {
        const day = this._addDays(start, i);
        const dayFormatted = this._formatDate(day, this._datePattern.localDate);
        const weekday = this._weekDay(day);

        defaultPriceDays[dayFormatted] = {
          priceClassTypeId: this.hotelCtx.env.priceClassTypeId,
          hotel: null,
          hotelInfos: this.hotel,
          hotelStock: null,
          flight: null,
          transfers: null,
          total: null,
          menu: false,
          selected: null,
          isWeekEnd: dayFormatted === 6 || weekday === 7,
          hotelStart: dayFormatted,
          hotelStop: this._formatDate(
            this._addDays(start, i + this.nbNights),
            this._datePattern.localDate
          ),
        };
      }

      for (let i = 0; i < days; i++) {
        const day = this._addDays(start, i); //start.clone().add(i, 'days')
        const dayFormatted = this._formatDate(day, this._datePattern.localDate);

        let startdate = this._formatDate(day, this._datePattern.localDate);
        let stopdate = this._formatDate(
          this._addDays(day, this.nbNights),
          this._datePattern.localDate
        );

        let flight = null;
        if (flights[dayFormatted]) {
          flight = flights[dayFormatted][this.hotel.airport];
          if (flight) {
            startdate = this._parseAndFormatDate(
              flight.flies[0].arrivalDate,
              this._datePattern.localDateTime,
              this._datePattern.localDate
            );
            stopdate = this._parseAndFormatDate(
              flight.flies[flight.flies.length - 1].departureDate,
              this._datePattern.localDateTime,
              this._datePattern.localDate
            );
          }
        }

        // skip building hotel date if we ask price with flight and we have no flight price for this date
        if (this.withFlights && flight == null) {
          continue;
        }

        const hotelprice = this.hotelPrices[startdate];
        const transfer = this.transfers[startdate];
        if (
          hotelprice &&
          (!this.withTransfers ||
            (this.withTransfers &&
              transfer &&
              transfer.transfer &&
              transfer.transfer[0]))
        ) {
          hotelprice.hotel.propositions.map((p) => {
            p.id = hotelprice.hotel.id;

            const roomName = p.rooms.map((r) => r.name).join(", ");
            const rooms = _.intersectionBy(
              hotelprice.hotel.rooms,
              p.rooms,
              "roomId"
            );

            let roomIndex = _.findIndex(items, (i) => {
              return i.roomName === roomName;
            });
            if (roomIndex === -1) {
              const length = items.push({
                isRoom: true,
                show: false,
                roomName: roomName,
                rooms: rooms,
                meals: [],
                priceByDays: _.cloneDeep(defaultPriceDays),
                selected: null,
                minPrice: null,
              });
              roomIndex = length - 1;
            }

            const mealName = p.meals.map((r) => r.name).join(", ");
            const meals = _.intersectionBy(
              hotelprice.hotel.meals,
              p.meals,
              "mealPlanTypeId"
            );

            let mealIndex = _.findIndex(items[roomIndex].meals, (i) => {
              return i.mealName === mealName;
            });
            if (mealIndex === -1) {
              const length = items[roomIndex].meals.push({
                mealName: mealName,
                meals: meals,
                priceByDays: _.cloneDeep(defaultPriceDays),
                selected: null,
                minPrice: null,
              });
              mealIndex = length - 1;
            }

            const flightprice = flight ? flight.prices[0].pricing.price : 0;
            const transferprice = transfer
              ? transfer.transfer[0].pricing.prices.price
              : 0;

            const price = {
              priceClassTypeId: this.hotelCtx.env.priceClassTypeId,
              hotel: p,
              flight: flight,
              transfer: transfer ? transfer.transfer[0] : null,
              total: p.prices
                ? p.prices.price + flightprice + transferprice
                : 0,
              hotelId: this.hotel.id,
              hotelName: this.hotel.name,
              hotelStart: startdate,
              hotelStop: stopdate,
              guests: guests,
            };

            items[roomIndex].meals[mealIndex].priceByDays[dayFormatted] =
              Object.assign(
                {},
                items[roomIndex].meals[mealIndex].priceByDays[dayFormatted],
                price
              );

            if (
              items[roomIndex].priceByDays[dayFormatted] === void 0 ||
              items[roomIndex].priceByDays[dayFormatted].total === null ||
              items[roomIndex].priceByDays[dayFormatted].total === 0 ||
              items[roomIndex].priceByDays[dayFormatted].total > price.total
            ) {
              items[roomIndex].priceByDays[dayFormatted] = Object.assign(
                {},
                items[roomIndex].priceByDays[dayFormatted],
                price
              );
            }
          });
        }
      }

      var hotel = {
        productId: this.hotel.id,
        isRoom: false,
        expanded: false,
        roomName: this.hotel.name,
        rooms: [],
        meals: [],
        priceByDays: _.cloneDeep(defaultPriceDays),
        selected: null,
        minPrice: null,
      };

      const newItems = items.map((item) => {
        const firstRoomTypeId = item.rooms.map((r) => r.roomTypeId)[0];

        const roomStocks = this.stocks.find(
          (s) => s.roomTypeId === firstRoomTypeId
        );

        for (var day in item.priceByDays) {
          const stockday = roomStocks ? roomStocks.stocks[day] : null;

          item.priceByDays[day].hotelStock = stockday;

          //item.priceByDays[day].hotelStart = (hotelprice) ? hotelprice.hotelStart:null
          //item.priceByDays[day].hotelStop = (hotelprice) ? hotelprice.hotelStop:null

          if (
            (hotel.priceByDays[day].total == null &&
              item.priceByDays[day].total > 0) ||
            (hotel.priceByDays[day].total != null &&
              item.priceByDays[day].total > 0 &&
              item.priceByDays[day].total < hotel.priceByDays[day].total)
          ) {
            hotel.priceByDays[day] = item.priceByDays[day];
            if (
              hotel.minPrice == null ||
              hotel.minPrice === 0 ||
              hotel.minPrice > item.priceByDays[day].total
            ) {
              hotel.minPrice = item.priceByDays[day].total;
            }
          }

          if (
            (item.minPrice == null && item.priceByDays[day].total > 0) ||
            (item.priceByDays[day].total != null &&
              item.priceByDays[day].total > 0 &&
              item.priceByDays[day].total < item.minPrice)
          ) {
            item.minPrice = item.priceByDays[day].total;
          }

          item.meals.map((meal) => {
            meal.priceByDays[day].hotelStock = item.priceByDays[day].hotelStock;
            //meal.priceByDays[day].hotelStart = item.priceByDays[day].hotelStart
            //meal.priceByDays[day].hotelStop = item.priceByDays[day].hotelStop

            if (
              (meal.minPrice == null && meal.priceByDays[day].total > 0) ||
              (meal.priceByDays[day].total != null &&
                meal.priceByDays[day].total > 0 &&
                meal.priceByDays[day].total < meal.minPrice)
            ) {
              meal.minPrice = meal.priceByDays[day].total;
            }
          });
        }

        return item;
      });

      this.items = [hotel].concat(newItems);
    },
    buildBasePrice(priceday) {
      let total = 0;

      if (priceday.flight != null) {
        total += priceday.flight.prices[0].pricing.price;
      }
      if (priceday.transfers != null) {
        total += priceday.transfers.prices.price;
      }
      return total;
    },
    select(item) {
      this.$emit("select", item);
    },
  },
};
</script>
<style scoped>
.v-datatable__expand-col {
  border: 0;
}

>>> table.v-table {
  table-layout: fixed;
}

.v-table td:not(.v-datatable__expand-col) {
  border: solid 1px #eee;
  border-bottom: 0;
}

>>> .v-datatable__expand-col {
  padding: 0 !important;
}

.day-label {
  min-width: 300px;
  width: 300px;
  height: 60px !important;
  position: absolute;
  background: #fff;
  z-index: 1;
  padding: 10px !important;
}

.day-label.isroom {
  padding-left: 50px;
}

.day-cell {
  padding: 5px !important;
  width: 60px !important;
  height: 60px;
  text-align: center;
  line-height: 14px;
  vertical-align: center;
}

thead .day-cell,
thead .day-label {
  height: 45px !important;
}

.weekend {
  background: #f5f5f5;
}

.hotel {
  color: #757575;
}

.tag {
  margin-right: 5px;
}

.room-label {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-self: center;
  align-items: center;
}

.meal-label {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: flex-end;
}
</style>
<style>
>>> th {
  padding: 5px !important;
}
</style>
