/**
 *
 */

export default {
	data() {
		return {
			dialog: false,
			resolve: null,
			reject: null,
			message: null,
			title: null,
			options: {
				color: 'primary',
				width: 290,
				ok: "Ok"
			}
		}
	},
	methods: {

		_open(title, message, options) {
			this.dialog = true
			this.title = title
			this.message = message
			this.options = Object.assign(this.options, options)
		},

		cancel() {
			if(this.resolve) {
				this.resolve(false)
				this.dialog = false
			}
		}
	}
}
