<template>
  <widget-container
    headlineclass
    cardclass=" ma-0 pa-1"
    :url="url"
    :bus="bus"
    :dark="false"
    :flat="false"
    color="transparent"
    :alwaysrender="true"
    @ready="setData"
  >
    <template slot="content">
      <div class>
        <v-row align="center">
          <v-col class="mb-3 text-right">
            <v-btn @click="copy" small depressed :disabled="this.table.items.length==0">
              <v-icon small>mdi-clipboard-outline</v-icon>Copier
            </v-btn>
          </v-col>
        </v-row>
        <div :style="'overflow-x: auto;margin:auto;' ">
          <div :style="'width:'+tableWidth+'px;border: 1px solid #e1e1e1;margin:auto;'">
            <v-simple-table class="pa-0 ma-0 tbl">
              <template #default>
                <thead>
                  <tr>
                    <th
                      v-for="(header, index) in table.headers"
                      :key="index"
                      :style="'width:'+ (index==0?230:150) +'px;'"
                      :class="[index==0?'hotel-cell':'default-cell ']"
                      class="head"
                    >
                      <div
                        @click="sort(header, (index-1))"
                        :class="[{'sorted':header.sorted != null}, header.sorted]"
                      >
                        {{header.name}}
                        <span
                          v-if="header.year && !withLastYear"
                        >{{header.year.toString().slice(2)}}</span>
                        <v-icon small>arrow_upward</v-icon>
                      </div>
                    </th>
                  </tr>

                  <!-- sub header -->

                  <tr v-if="withLastYear">
                    <th
                      v-for="(header, index) in table.headers"
                      :key="index"
                      :style="'width:'+ (index==0?230:150) +'px;'"
                      :class="[index==0?'hotel-cell':'default-cell ']"
                      class="head"
                    >
                      <div :class="[{'sorted':header.subsorted != null}, header.subsorted]">
                        <span
                          v-if="header.year "
                          class="cell-subheader-content-container"
                          @click="sortSub(header, (index-1), false)"
                        >
                          {{(header.year).toString().slice(2)}}
                          <v-icon small>arrow_upward</v-icon>
                        </span>
                        <span
                          v-if="header.year "
                          class="cell-subheader-content-container"
                          @click="sortSub(header, (index-1), true)"
                        >
                          {{(header.year-1).toString().slice(2)}}
                          <v-icon small>arrow_upward</v-icon>
                        </span>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in table.items" :key="index">
                    <td class="hotel-cell" :class="{'odd':index%2==1}" style="width:230px;">
                      <span>{{ item.hotel}}</span>
                    </td>
                    <td
                      v-for="(month, monthIndex) in item.months"
                      class="default-cell"
                      style="width:100px;"
                      :key="monthIndex"
                    >
                      <span v-if="month.nights">
                        <hotel-stats-cell
                          v-model="month.nights"
                          :with-last-year="withLastYear"
                          :width="150"
                          :percent="false"
                          :do-show-diff="false"
                        ></hotel-stats-cell>
                      </span>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr v-if="table.total">
                    <td class="hotel-cell body-2" style="width:230px;">TOTAL</td>

                    <td
                      v-for="(month, monthIndex) in table.total"
                      :key="monthIndex"
                      class="default-cell body-2"
                      style="width:100px;"
                    >
                      <span v-if="month.nights">
                        <hotel-stats-cell
                          v-model="month.nights"
                          :with-last-year="withLastYear"
                          :tooltip-text="month.name + ' '+ month.year"
                          :width="150"
                          :percent="false"
                          :do-show-diff="false"
                        ></hotel-stats-cell>
                      </span>
                    </td>
                  </tr>
                </tfoot>
              </template>
            </v-simple-table>
          </div>
        </div>
      </div>
    </template>
  </widget-container>
</template>

<script>
import WidgetContainer from "./WidgetContainer";
import HotelStatsCell from "../../../views/stats/HotelStatsCell";
import clipboard from "../../../utils/clipboard";
import _ from "lodash";
export default {
  name: "WidgetNightsPerMonth",
  components: { HotelStatsCell, WidgetContainer },
  mixins: [],
  props: {
    bus: {
      type: Object,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
    withLastYear: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      table: {
        loading: false,
        items: [],
        rowsPerPageItems: [{ text: "Tous", value: -1 }],
        headers: [],
      },
    };
  },
  created() {
    this.$nextTick(() => {
      this.bus.$on("search", (searchform) => {
        this.table.loading = true;
      });
    });
  },
  destroyed() {
    this.bus.$off("search");
  },
  watch: {},
  computed: {
    tableWidth() {
      if (this.table.headers.length > 0) {
        const width = this.table.headers
          //.filter(h => this.table.selectedHeaders.length <= 0 ? true : this.doShowColumn(h.value))
          .map((h) => (this.withLastYear ? h.fullSize : h.smallSize))
          .reduce((acc, curr) => acc + curr);
        return width;
      } else return 0;
    },
  },
  methods: {
    setData(data) {
      this.table.loading = false;

      this.table.headers = [
        { name: "Hôtel", fullSize: 230, smallSize: 230, value: "hotel" },
        ...data.headers.map((obj) => ({
          ...obj,
          fullSize: 150,
          smallSize: 150,
          value: "months",
        })),
      ];
      this.table.items = data.items;
      this.table.total = data.total;
    },
    sort(header, index) {
      const asc = "asc";
      const desc = "desc";

      this.table.headers = this.table.headers.map((h) => {
        h.sorted = !h.sorted
          ? "asc"
          : h.sorted && h.sorted == "asc"
          ? "desc"
          : null;
        return h;
      });

      if (header.sorted) {
        const isDescending = header.sorted == "desc";
        const items = _.cloneDeep(this.table.items);
        items.sort((a, b) => {
          const aValue =
            typeof a[header.value] == "string"
              ? a[header.value]
              : a[header.value][index].nights.value;
          const bValue =
            typeof b[header.value] == "string"
              ? b[header.value]
              : b[header.value][index].nights.value;

          if (typeof aValue == "number" && typeof bValue == "number")
            return isDescending ? bValue - aValue : aValue - bValue;
          else
            return isDescending
              ? bValue.localeCompare(aValue)
              : aValue.localeCompare(bValue);
        });

        this.table.items = items;
      }
    },
    sortSub(header, index, previous) {
      const asc = "asc";
      const desc = "desc";

      this.table.headers = this.table.headers.map((h) => {
        h.subsorted = !h.subsorted
          ? "asc"
          : h.subsorted && h.subsorted == "asc"
          ? "desc"
          : null;
        return h;
      });

      if (header.subsorted) {
        const isDescending = header.subsorted == "desc";
        const items = _.cloneDeep(this.table.items);
        items.sort((a, b) => {
          const aValue = previous
            ? a[header.value][index].nights.previousValue
            : a[header.value][index].nights.value;
          const bValue = previous
            ? b[header.value][index].nights.previousValue
            : b[header.value][index].nights.value;

          return isDescending ? bValue - aValue : aValue - bValue;
        });

        this.table.items = items;
      }
    },

    copy() {
      const defaultSeparator = "\t";
      const decimalOpts = { maximumFractionDigits: 0, useGrouping: false };

      const buildCell = (item) => {
        if (this.withLastYear) {
          return `${
            item && item.nights
              ? this.$options.filters.decimal(item.nights.value, decimalOpts)
              : 0
          }${defaultSeparator} ${
            item && item.nights
              ? this.$options.filters.decimal(
                  item.nights.previousValue,
                  decimalOpts
                )
              : 0
          }${defaultSeparator}`;
        } else {
          return `${
            item && item.nights
              ? this.$options.filters.decimal(item.nights.value, decimalOpts)
              : 0
          }${defaultSeparator}`;
        }
      };

      const headers = this.table.headers
        .map((h) => {
          return `${h.name}${defaultSeparator} ${
            !this.withLastYear ? "" : defaultSeparator
          }`;
        })
        .join(" ");

      const subHeaders = !this.withLastYear
        ? []
        : this.table.headers
            .map((h, idx) => {
              if (idx == 0) return defaultSeparator;
              else {
                return `${h.year}${defaultSeparator} ${
                  h.year - 1
                }${defaultSeparator}`;
              }
            })
            .join(" ");

      const rows = this.table.items.map((i) => {
        const cell = i.months
          .map((m) => {
            return buildCell(m);
          })
          .join(" ");

        return `${i.hotel}${defaultSeparator}${cell}`;
      });

      const allRows = [headers, subHeaders].concat(rows);

      clipboard.copyTextToClipboard(rows.join("\n"));
    },
  },
};
</script>

<style scoped lang="less">
table {
  box-sizing: border-box;
  border-collapse: collapse;

  tbody {
    border-top: 1px solid #d1d1d1;
  }

  tbody tr:nth-of-type(even) {
    background-color: #eeeeee;
  }

  tbody tr td.odd {
    background-color: #eeeeee;
  }

  thead tr:first-child {
    .hotel-cell .default-cell .sub-cell {
      border: 1px solid #d1d1d1;
    }
  }

  thead tr:nth-child(2) {
    height: 30px;
  }

  thead {
    th {
      > div {
        height: 100%;
        height: 100%;
        display: block;
        line-height: 35px;
      }

      div,
      span {
        &:hover {
          cursor: pointer;

          .v-icon {
            opacity: 0.6;
          }
        }

        &.sorted {
          .v-icon {
            opacity: 1;
          }
        }

        &.desc {
          .v-icon {
            transform: rotate(-180deg);
          }
        }

        .v-icon {
          opacity: 0;
        }
      }
    }
  }

  tfoot tr {
    height: 30px;

    td {
      font-weight: bold;
    }
  }
}

tr {
  .hotel-cell {
    height: 30px !important;
    position: absolute;
    background: #fff;
    z-index: 1;
    padding: 0 !important;
    margin: 0 !important;
    border-right: 2px solid #d1d1d1;

    > span {
      position: relative;
      top: 10%;
      left: 5px;
    }

    &.head {
      height: 36px !important;
    }
  }

  .default-cell {
    height: 30px;
    width: 150px !important;
    padding: 0 !important;
    margin: 0 !important;
    text-align: center;
    line-height: 14px;
    vertical-align: center;
    border-right: 2px solid #d1d1d1;
  }

  .sub-cell {
    height: 30px !important;
    padding: 0 !important;
    margin: 0 !important;
    text-align: center;
    line-height: 14px;
    vertical-align: center;
    border-right: 2px solid #d1d1d1;
  }

  .cell-subheader {
    margin: 0 !important;
    padding: 0 !important;
  }

  .cell-subheader-content-container {
    width: 75px;
    display: inline-block;
    text-align: right;

    &:not(:first-child) {
      border-left: 1px solid #d1d1d1;
    }
  }

  .cell-subheader-content {
    height: 100%;
    top: 30%;
    width: 55px;
    position: relative;
  }
}
</style>
