<template>
  <layout :drawer="true" :drawerShow="true" :drawerWidth="400">
    <template slot="nav">
      <v-tabs>
        <v-tab :to="{ name: 'customer-search' }">Clients</v-tab>
      </v-tabs>
    </template>
    <template slot="drawer">
      <v-row>
        <v-col>
          <v-form v-on:submit.prevent @submit="searchCustomer">
            <v-text-field
              v-model="searchTerm"
              prepend-inner-icon="mdi-magnify"
              :label="searchTerm == '' ? '' : 'Recherche'"
              placeholder="Rechercher un client par nom, prénom, email"
            ></v-text-field>
            <smart-btn
              block
              primary
              :loading="isLoading"
              @click.native="searchCustomer"
              :disabled="searchTerm === ''"
              >Rechercher</smart-btn
            >
          </v-form>
        </v-col>
      </v-row>
    </template>
    <template slot="content">
      <v-container fluid>
        <v-row justify="center">
          <v-data-table
            class="elevation-1 tbl"
            show-expand
            :headers="headers"
            :items="users"
            :loading="isLoading"
            :no-data-text="noDataSentence"
            :options.sync="listing.pagination"
            :footer-props="{
              'items-per-page-options': listing.rowsPerPageItems,
              'items-per-page-text': 'Lignes par page',
            }"
            :server-items-length="pagination.total"
            @click:row="openUserProfile"
            @update:page="paginate"
            @update:items-per-page="paginate"
            style="width: 810px"
          >
            <template #item.data-table-expand="{ item, isExpanded, expand }">
              <v-icon
                @click.stop="expand(!isExpanded)"
                v-if="item.bookingProjects.length > 0"
                >mdi-{{ isExpanded ? "chevron-up" : "chevron-down" }}</v-icon
              >
            </template>

            <template #expanded-item="{ headers, item }" class="text-center">
              <td :colspan="headers.length">
                <v-row class="v-data-table_mobile-table-row">
                  <v-col
                    cols="4"
                    v-for="(project, projectIndex) in item.bookingProjects"
                    :key="projectIndex"
                  >
                    <v-card class="mx-auto" outlined>
                      <v-row no-gutters>
                        <v-col
                          cols="12"
                          class="caption mb-3 pa-1 ma-0 overline font-regular-capitalize font-regular"
                        >
                          <v-row no-gutters>
                            <v-col cols="8" class="font-weight-bold caption">{{
                              project.name
                            }}</v-col>
                            <v-col cols="4" class="text-right"
                              >Le
                              {{
                                _parseAndFormatDate(
                                  project.creationDate,
                                  datePatternConfig.serverLocalDateTime,
                                  datePatternConfig.serverLocalDate
                                )
                              }}</v-col
                            >
                            <v-col cols="4 caption"
                              >{{ project.bookingCount }}
                              {{ $t("quotation") }}</v-col
                            >
                          </v-row>
                        </v-col>
                        <v-col
                          cols="12"
                          class="caption pa-1 ma-0 overline font-regular-capitalize font-regular"
                        >
                          <v-row no-gutters>
                            <v-col cols="12" class="text-right"
                              >par {{ project.authorName }}</v-col
                            >
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                </v-row>
              </td>
            </template>
          </v-data-table>
        </v-row>
      </v-container>
    </template>
  </layout>
</template>
<script>
import { get } from "@/utils/api";
import Layout from "../Layout";

export default {
  name: "CustomersList",
  components: {
    Layout,
  },
  data() {
    return {
      headers: [
        { sortable: false, text: "", value: "data-table-expand" },
        { sortable: true, text: "Nom", align: "left", value: "lastname" },
        { sortable: false, text: "Prénom", align: "left", value: "firstname" },
        { sortable: false, text: "Email", align: "left", value: "email" },
        { sortable: false, text: "Devis", align: "left", value: "quotations" },
        { sortable: false, text: "Dossiers", align: "left", value: "bookings" },
        {
          sortable: false,
          text: "Projets de voyage",
          align: "left",
          value: "projectSize",
        },
      ],
      noDataSentence: "Veuillez effectuer une recherche",
      listing: {
        loading: false,
        rowsPerPageItems: [10, 50, 200],
      },
      searchTerm: "",
      users: [],
      pagination: {},
      isLoading: false,
      expandArrow: false,
      prevPath: "",
    };
  },
  created() {
    this.initSearchform();
    this.loadData();
  },
  methods: {
    paginate(page) {
      this.searchCustomer({
        offset: this.listing.pagination.itemsPerPage * (page - 1),
        limit: this.listing.pagination.itemsPerPage,
      });
    },
    searchCustomer(pagination) {
      if (!this.isLoading) {
        const params = {
          ...{
            search: this.searchTerm,
            limit: this.listing.pagination.itemsPerPage,
          },
          ...pagination,
        };

        const qs = Object.keys(params)
          .map((key) => {
            return `${key}=${params[key]}`;
          })
          .join("&");

        this.isLoading = true;
        const url = "/api/customer/search";

        get(url + "?" + qs)
          .badRequest(() => {
            this.noDataSentence = "Aucun client trouvé";
          })
          .json((data) => {
            this.users = data.customers.map((d) => {
              return { ...d, ...{ projectSize: d.bookingProjects.length } };
            });
            this.pagination = data.pagination;

            if (data.customers.length == 0) {
              this.noDataSentence = "Aucun client trouvé";
            }
          })
          .then(() => {
            this.isLoading = false;
            this.loadData();
          });
      }
    },
    openUserProfile(item) {
      this.$router.push({ name: "customer-edition", params: { id: item.id } });
    },
    openBookingProject(id) {
      this.$router.push({ name: "booking-project", params: { id: id } });
    },
    loadData() {
      //set qs
      window.history.pushState(
        "",
        "",
        "?search=" + JSON.stringify(this.searchTerm)
      );
    },
    initSearchform() {
      const qs = new URLSearchParams(window.location.search);
      const search = (() => {
        try {
          return JSON.parse(qs.get("search"));
        } catch (e) {
          return null;
        }
      })();

      if (search) {
        this.searchTerm = search;
        this.searchCustomer();
      } else this.searchTerm = "";
    },
  },
};
</script>
<style scoped></style>
