<template>
  <div>
    <smart-btn class="float-right mb-2" @click.native="openFeeDialog()">Modifier</smart-btn>
    <smart-dialog
        v-model="feeDialog"
        eager
        title="Modification des frais d'annulation"
        :width="widthDialog">
      <template>
        <div class="pa-4 d-flex flex-row">
          <div class="" v-for="(fee, index) in cancelFees.fees" :key="index + '-' + fee.invalid ">
            <CancelFeeEdit :fee="fee"
                           :startPrestaDate="getFormattedDatePresta()"
                           :divider="index == (cancelFees.fees.length-1) || cancelFees.fees.length == 1"
                           @change="(f) => updateFee(f,index)"
                           @remove="(f) => removeFee(f,index)"
                           v-if="!refreshRemoved"
            />
          </div>
        </div>
      </template>
      <template #prependAction>
        <smart-btn @click.native="addPeriod()">Ajouter période</smart-btn>
      </template>
      <template #actions>
        <smart-btn class="mr-3" @click.native="feeIsValid()">Vérifier les périodes</smart-btn>
        <smart-btn @click.native="saveFee()">Valider</smart-btn>
      </template>

    </smart-dialog>
  </div>
</template>
<script>
import CancelFeeEdit from "@/components/commons/CancelFeeEdit.vue";
import {EventBus} from "@/components/commons/event-bus";
import {post} from "@/utils/api";
import {nextTick} from "vue";

export default {
  name: 'CancelFeesEditDialog',
  components: {CancelFeeEdit},
  props: {
    prestation: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      cancelFees: [],
      addedFeeCount: 0,
      feeDialog: false,
      fees: [],
      refreshRemoved: false,
    }
  },
  watch: {},
  created() {
    this.cancelFees = JSON.parse(JSON.stringify(this.prestation.cancelFees));
    this.cancelFees.fees.forEach(x => x.invalid = null)
  },
  computed: {
    widthDialog() {
      return this.cancelFees.fees.length * 200 + (this.addedFeeCount * 200)
    }
  },
  methods: {

    openFeeDialog() {
      if (this.cancelFees.length == 0) {
        this.cancelFees.push({start: null, stop: null, price: 0})
      }
      this.feeDialog = true
    },
    saveFee() {
      this.cancelFees.fees.map(f => f.invalid = null)
      if (this.feeIsValid()) {
        post(`/api/prestation/${this.prestation.id}/updatefees`, this.cancelFees)
            .badRequest((err) => {
              EventBus.$emit(
                  "toaster-msg",
                  JSON.parse(err.null).errors.map((e) => JSON.parse(e))
              );
            })
            .json(() => {
              //const guestId = res.data
              EventBus.$emit("toaster-msg", this.$t("update-cancelfees"));
              EventBus.$emit("reloadbooking", {});
              this.feeDialog = false
            });

      } else {
        EventBus.$emit("toaster-error", "Les dates des périodes ne correspondent pas");
      }
    },
    addPeriod() {
      this.addedFeeCount++
      if (this.prestation.cancelFees.fees.length > 0) {
        const start = this._addDays(this._parseDate(this.cancelFees.fees[this.cancelFees.fees.length - 1].stop), 1)
        this.cancelFees.fees.push({
          start: this._formatDate(start, "dd/MM/yyyy"),
          stop: this._formatDate(this._addDays(start, 1), "dd/MM/yyyy"),
          price: 0
        })
      }
    },
    updateFee(fee, index) {
      this.cancelFees.fees[index] = fee
    },
    removeFee(fee, index) {
      this.refreshRemoved = true

      this.cancelFees.fees.splice(index, 1)
      nextTick(() => this.refreshRemoved = false)

    },
    getFormattedDatePresta() {
      return this._formatDate(this._parseDate(this.prestation.start.split(' ')[0], "dd/MM/yyyy"), "yyyy-MM-dd")
    },
    feeIsValid() {
      this.cancelFees.fees = this.sortCancelFees()
      this.cancelFees.fees = this.cancelFees.fees.reduce((allfees, curr) => {

        const prev = allfees.length > 0 ? allfees[allfees.length - 1] : null
        let i = this.cancelFees.fees.indexOf(curr)


        if (prev != null && prev.stop != null && curr.start != null) {
          if (!this._isSame(this._addDays(this._parseDate(prev.stop, 'dd/MM/yyyy'), 1),
              this._parseDate(curr.start, "dd/MM/yyyy"), "days")) {
            curr.invalid = 'start'
          } else if (this._isAfter(this._parseDate(curr.stop, 'dd/MM/yyyy'), this._parseDate(this.getFormattedDatePresta(), 'yyyy-MM-dd'))) {
            curr.invalid = 'stop'
          } else if (this.prestation.price < curr.price) {
            curr.invalid = 'price'
          }
        }
        allfees.push(curr)

        return allfees
      }, [])
      return !this.cancelFees.fees.some(f => f.invalid != null)


    },
    sortCancelFees() {
      this.cancelFees.fees = this.cancelFees.fees.sort((a, b) => {
        let aParse = this._parseDate(a.start, "dd/MM/yyyy")
        let bParse = this._parseDate(b.start, "dd/MM/yyyy")
        if (this._isBefore(aParse, bParse)) {
          return -1
        } else if (this._isAfter(aParse, bParse)) {
          return 1
        } else {
          return 0
        }
      })
      return this.cancelFees.fees
    },
  }
};
</script>