<template>
  <div class="content-pdf" v-if="pdf" style="width: 21cm; margin: 0 auto">
    <div>
      <div v-if="!detailsOnly" class="page ba-always">
        <div class="intro">
          <div class="images">
            <div class="img">
              <img
                  class
                  :src="
                  pdf.logoPath || '/images/document/hotelissima/quotation.png'
                "
              />
            </div>
            <div
                class="img"
                v-for="(media, idx) in pdf.medias"
                :key="'pdf-media-' + idx"
            >
              <img :src="media"/>
              <div class="line"></div>
            </div>
          </div>

          <div class="title content-pdf">
            <h1 v-if="pdf.clientName">{{ pdf.clientName }}</h1>
            <component :is="pdf.clientName ? 'h2' : 'h1'" v-if="pdf.title">
              {{ pdf.title }}
            </component>
            <h1 v-else>{{ $t("document.quotation.label") }} {{ pdf.id }}</h1>

            <p class="subtitle" v-if="pdf.subtitle && pdf.subtitle != ''">
              {{ pdf.subtitle }}
            </p>
          </div>
          <div class="infos" v-if="!pdf.isAgency">
            <div class="bloc">
              <h4>{{ pdf.siteUrl }}</h4>
              <hr/>
              <div>30 Rue Ferrandière</div>
              <div>69002 Lyon</div>
              <div>Immatriculation Atout France : IM069100005</div>
              <div>Garantie Financière APST</div>
            </div>

            <div class="bloc">
              <h4>Contact</h4>
              <hr/>
              <div>Tel: {{ pdf.sitePhone }}</div>
              <div>Email : {{ pdf.siteContact }}</div>
            </div>
          </div>
          <div class="infos" v-if="pdf.isAgency && pdf.agencyData">
            <div class="bloc">
              <h4>{{ pdf.agencyData.name }}</h4>
              <hr/>
              <div>{{ pdf.agencyData.address.street }}</div>
              <div>
                {{ pdf.agencyData.address.postalcode }}
                {{ pdf.agencyData.address.city }}
              </div>
              <br/>
              <div>
                {{ $t("document.quotation.imat") }} {{ pdf.agencyData.licence }}
              </div>
            </div>

            <div class="bloc" v-if="pdf.agencyData.agent">
              <h4>{{ $t("document.quotation.contact") }}</h4>
              <hr/>
              <div>
                {{ pdf.agencyData.agent.firstname }}
                {{ pdf.agencyData.agent.lastname }}
              </div>
              <br/>
              <div>Tel: {{ pdf.agencyData.agent.telephone }}</div>
              <div>Email : {{ pdf.agencyData.email }}</div>
            </div>
          </div>

          <div v-if="!pdf.itenaryOnly" class="bookingnumber">
            {{
              $t("document.quotation.labelDate", {
                id: pdf.id,
                bookingDate: pdf.bookingDate,
              })
            }}
          </div>
          <!-- <hr v-if="!pdf.itenaryOnly" style="width: 30%"/> -->
        </div>
      </div>

      <div v-if="!detailsOnly" class="page ba-always timeline">
        <h2>{{ $t("document.quotation.yourTravel.label") }}</h2>
        <hr/>

        <div
            class="event"
            v-for="(presta, i) in pdf.prestations"
            :key="'p' + i"
        >
          <div class="date">
            <template v-if="_nbNightsFormatted(presta.start, presta.stop) > 2">
              {{
                $t("document.quotation.yourTravel.hotel.dateFromTo", {
                  0: _parseAndFormatDate(
                      presta.start,
                      datePatternConfig.serverLocalDateTime,
                      _datePattern.full
                  ),
                  1: _parseAndFormatDate(
                      presta.stop,
                      datePatternConfig.serverLocalDateTime,
                      _datePattern.full
                  ),
                })
              }}
            </template>
            <template v-else>
              {{
                _firstLetterUpper(
                    _parseAndFormatDate(
                        presta.start,
                        _datePattern.localDateTime,
                        _datePattern.full
                    )
                )
              }}
            </template>

          </div>
          <ul>
            <li>
              <template v-if="presta.kind === 'RoomPrestation'">
                {{
                  $t("document.quotation.yourTravel.hotel.stayAt", {
                    0: presta.hotelName,
                  })
                }}
                <v-rating
                    v-model="presta.nbStars"
                    small
                    color="black"
                ></v-rating>
                <ul style="margin-left: 10px; list-style-type: circle">
                  <li>
                    {{
                      $t("document.quotation.yourTravel.hotel.stayAtDetails", {
                        room: presta.shortDesc,
                        adult: $tc(
                            "document.quotation.adultCount",
                            countAdult(presta.guests),
                            {size: countAdult(presta.guests)}
                        ),
                        child: $tc(
                            "document.quotation.childCount",
                            countChild(presta.guests),
                            {size: countChild(presta.guests)}
                        ),
                        meal: presta.translatedMealplanName,
                        nights: $tc(
                            "document.quotation.nightsCount",
                            _nbNightsFormatted(presta.start, presta.stop),
                            {
                              size: _nbNightsFormatted(presta.start, presta.stop),
                            }
                        ),
                      })
                    }}
                  </li>
                </ul>
              </template>
              <template v-else-if="presta.kind == 'TransferPrestation'">
                {{ $t("document.quotation.yourTravel.transfer.label") }} ({{
                  buildVehicles(presta.vehicleType)
                }}) {{ presta.fromCustomerName }} ->
                {{ presta.toCustomerName }}
              </template>
              <template v-else-if="presta.kind == 'CarrentalPrestation'"
              >{{ $t("document.quotation.yourTravel.carRent.label") }}
                {{ presta.name }}
              </template
              >
              <template v-else-if="presta.kind == 'FlightPrestation'">
                {{ $t("document.quotation.yourTravel.flight.label") }}
                {{ presta.route.segments[0].fromName }}
                <v-icon small class="pdf-blue">play_arrow</v-icon>
                {{ presta.route.toName }}
                <p>
                  <span
                      v-for="(segment, segmentIndex) in presta.route.segments"
                      :key="segmentIndex"
                  >
                    {{
                      $t("document.quotation.yourTravel.flight.segment", {
                        airlineCode: segment.airlineCode,
                        flightNumber: segment.flightNumber,
                        from: segment.fromName,
                        departureDate: _parseAndFormatDate(
                            segment.start,
                            _datePattern.localDateTime,
                            _datePattern.localDateTime
                        ),
                        toName: segment.toName,
                        arrivalDate: _parseAndFormatDate(
                            segment.stop,
                            _datePattern.localDateTime,
                            _datePattern.localDateTime
                        ),
                        travelClass: segment.travelClass,
                        adult: $tc(
                            "document.quotation.adultCount",
                            countAdult(presta.guests),
                            {size: countAdult(presta.guests)}
                        ),
                        child: $tc(
                            "document.quotation.childCount",
                            countChild(presta.guests),
                            {size: countChild(presta.guests)}
                        ),
                      })
                    }}
                    <br/>
                  </span>
                </p>
              </template>
              <template
                  v-else-if="presta.kind == 'InsurancePrestation'"
              ></template>
              <template v-else>{{ presta.name }}</template>
            </li>
          </ul>
        </div>
      </div>
      <div
          v-if="!detailsOnly"
          class="page destination">
        <div class="page destination"
             v-for="(t, idx) in pdf.destinationsDescriptions"
             :key="idx">
          <h2>{{ t.name }}</h2>
          <hr/>
          <div v-html="t.longdescription"></div>
        </div>
      </div>

      <div v-if="!detailsOnly"
           class="page hotel bi-avoid">
        <div

            v-for="(hotel, idx) in pdf.hotelsInfos"
            :key="'hotel-' + idx"
        >
          <h2>
            {{ hotel.hotelName }}
            <v-rating v-model="hotel.hotelRating" color="black"></v-rating>
          </h2>
          <hr/>

          <div class="location">{{ hotel.destinationName }}</div>
          <div class="medias">
            <div
                class="media"
                v-for="(media, i) in hotel.medias"
                :key="'hotel-media-' + i"
            >
              <img :src="media"/>
            </div>
          </div>

          <div class="desc" v-html="hotel.longDescription"></div>

          <div
              v-for="(rooms, roomid) in groupBy(hotel.rooms, 'roomId')"
              :key="roomid"
          >
            <h2>{{ rooms[0].roomName }}</h2>
            <hr/>
            <div class="room" v-for="(room, i) in rooms" :key="'room-' + i">
              <div class="roombook">
                {{
                  $t("document.quotation.hotelPage.pax", {
                    adult: $tc(
                        "document.quotation.adultCount",
                        countAdult(room.guests),
                        {size: countAdult(room.guests)}
                    ),
                    child: $tc(
                        "document.quotation.childCount",
                        countChild(room.guests),
                        {size: countChild(room.guests)}
                    ),
                    startDate: _parseAndFormatDate(
                        room.startDate,
                        datePatternConfig.serverLocalDateTime,
                        datePatternConfig.serverLocalDate
                    ),
                    stopDate: _parseAndFormatDate(
                        room.stopDate,
                        datePatternConfig.serverLocalDateTime,
                        datePatternConfig.serverLocalDate
                    ),
                    nights: $tc(
                        "document.quotation.nightsCount",
                        _nbNightsFormatted(room.startDate, room.stopDate),
                        {size: _nbNightsFormatted(room.startDate, room.stopDate)}
                    ),
                    meal: room.mealName,
                  })
                }}
              </div>
            </div>
            <div class="room">
              <div v-if="rooms[0]">
                <table>
                  <tr>
                    <td class="roomdesc" v-html="rooms[0].description"></td>
                    <td class="image">
                      <img
                          v-for="(media, i) in rooms[0].medias"
                          :src="media"
                          :key="'room-media-' + i"
                      />
                    </td>
                  </tr>
                </table>
                <div class="services">
                  <div
                      class="service"
                      v-for="(service, i) in rooms[0].services"
                      :key="'room-service' + i"
                  >
                    <div class="service-title">{{ service.name }}</div>
                    <ul>
                      <li
                          v-for="(item, i) in service.items"
                          :key="'room-service-item' + i"
                      >
                        {{ item }}
                      </li>
                    </ul>
                  </div>

                  <div class="service" v-if="rooms[0].mealDescription">
                    <div class="service-title">
                      {{ $t("document.quotation.hotelPage.meal") }}
                    </div>
                    <ul v-html="rooms[0].mealDescription"></ul>
                  </div>
                  <div class="service">
                    <div class="service-title">
                      {{ $t("document.quotation.hotelPage.accessibility") }}
                    </div>
                    <ul
                        v-if="
                      rooms[0].services
                        .map((s) => s.items)
                        .indexOf('mobilité') >= 0
                    "
                    >
                      {{
                        $t("document.quotation.hotelPage.pmrReady", {
                          room: hotel.rooms[0].roomName,
                        })
                      }}
                    </ul>
                    <ul v-else>
                      {{
                        $t("document.quotation.hotelPage.pmrNotReady", {
                          room: hotel.rooms[0].roomName,
                        })
                      }}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="page pdfdetails bb-always">
        <h2>
          {{ $t("document.quotation.resume.label") }}
          <span v-if="detailsOnly">n°{{ pdf.id }}</span>
        </h2>
        <hr/>

        <div class="bloc">
          <div
              v-if="pdf.prestationFlights && pdf.prestationFlights.length > 0"
              class="flightsdetails"
          >
            <h3>
              <span>{{ $t("document.quotation.resume.flight.label") }}</span>
            </h3>

            <table>
              <tr
                  class="price-flights"
                  v-for="(flight, i) in pdf.prestationFlights"
                  :key="'flight-' + i"
              >
                <td class="date-vol">
                  <span class="pdf-blue">
                    {{
                      _parseAndFormatDate(
                          flight.start,
                          _datePattern.localDateTime,
                          _datePattern.full
                      )
                    }}
                  </span>
                </td>
                <td>
                  <span
                      v-for="(segment, segmentIndex) in flight.route.segments"
                      :key="segmentIndex"
                  >
                    {{
                      $t("document.quotation.resume.flight.details", {
                        airline: `${segment.airlineName} ${segment.airlineCode} ${segment.flightNumber}`,
                        prepositionDeparture: areSameDate(
                            segment.start,
                            segment.stop
                        )
                            ? $t(
                                "document.quotation.resume.flight.prepositionHour"
                            )
                            : $t(
                                "document.quotation.resume.flight.prepositionDate"
                            ),
                        departureDate: _parseAndFormatDate(
                            segment.start,
                            _datePattern.localDateTime,
                            areSameDate(segment.start, segment.stop)
                                ? _datePattern.time
                                : _datePattern.fullWithTime
                        ),
                        from: segment.fromName,
                        to: segment.toName,
                        prepositionArrival: areSameDate(
                            segment.start,
                            segment.stop
                        )
                            ? $t(
                                "document.quotation.resume.flight.prepositionHour"
                            )
                            : $t(
                                "document.quotation.resume.flight.prepositionDate"
                            ),
                        arrivalDate: _parseAndFormatDate(
                            segment.stop,
                            _datePattern.localDateTime,
                            areSameDate(segment.start, segment.stop)
                                ? _datePattern.time
                                : _datePattern.fullWithTime
                        ),
                        travelClass: segment.travelClass,
                        adult: $tc("Adult-count", countAdult(flight.guests), {
                          size: countAdult(flight.guests),
                        }),
                        child: $tc("Child-count", countChild(flight.guests), {
                          size: countChild(flight.guests),
                        }),
                      })
                    }}
                    <br/>
                  </span>
                </td>

                <td class="price">
                  <span v-if="pdf.showPrice"
                  >{{ flight.commissionPrice || flight.euroPrice }} €</span
                  >
                </td>
              </tr>
            </table>
          </div>

          <div v-if="pdf.isAgency && agencyFlightInfo" class="flightsdetails">
            <h3>
              <span>{{ $t("document.quotation.resume.flight.label") }}</span>
            </h3>

            <table v-if="agencyFlightInfo.hlagonsPnr">
              <tr
                  v-for="(flight, i) in agencyFlightInfo.hlagonsPnr.flightInfo
                  .flights"
                  :key="'flight-' + i"
              >
                <td class="date-vol">
                  <span class="pdf-blue">
                    {{
                      _parseAndFormatDate(
                          flight.departureTime,
                          datePatternConfig.en.localDateTime,
                          _datePattern.full
                      )
                    }}
                    <!--
                    -->
                  </span>
                </td>
                <td>
                  {{
                    $t("document.quotation.resume.flight.details", {
                      airline: `${flight.company.description} ${flight.company.iataCode} ${flight.flight}`,
                      prepositionDeparture: $t(
                          "document.quotation.resume.flight.prepositionHour"
                      ),
                      departureDate: _parseAndFormatDate(
                          flight.departureTime,
                          datePatternConfig.en.localDateTime,
                          _datePattern.time
                      ),
                      from: flight.departureAirport.description,
                      to: flight.landingAirport.description,
                      prepositionArrival: $t(
                          "document.quotation.resume.flight.prepositionHour"
                      ),
                      arrivalDate: _parseAndFormatDate(
                          flight.landingTime,
                          datePatternConfig.en.localDateTime,
                          _datePattern.time
                      ),
                      travelClass: flight.travelClass,
                      adult: $tc("Adult-count", countAdult(flight.guests), {
                        size: countAdult(flight.guests),
                      }),
                      child: $tc("Child-count", countChild(flight.guests), {
                        size: countChild(flight.guests),
                      }),
                    })
                  }}
                </td>
                <td class="price">
                  <span
                      v-if="
                      pdf.showPrice &&
                      i ==
                        agencyFlightInfo.hlagonsPnr.flightInfo['flights']
                          .length -
                          1
                    "
                  >
                    {{ agencyFlightInfosPrice }}
                    €
                  </span>
                </td>
              </tr>
            </table>
            <table v-else-if="agencyFlightInfo">
              <tr>
                <td class="date-vol">
                  <span class="pdf-blue">
                    {{
                      _parseAndFormatDate(
                          `${agencyFlightInfo.departure.date} ${agencyFlightInfo.departure.hour}:${agencyFlightInfo.departure.minute}:00`,
                          datePatternConfig.en.localDateTime,
                          _datePattern.full
                      )
                    }}
                  </span>
                </td>
                <td>
                  {{
                    $t("document.quotation.resume.flight.detailsAgency", {
                      flightNumber: agencyFlightInfo.flightNumber,
                      time: `${agencyFlightInfo.departure.hour}h${agencyFlightInfo.departure.minute}`,
                      from: agencyFlightInfo.departure.name,
                      to: agencyFlightInfo.arrival.name,
                      date: _parseAndFormatDate(
                          `${agencyFlightInfo.arrival.date} ${agencyFlightInfo.arrival.hour}:${agencyFlightInfo.arrival.minute}:00`,
                          datePatternConfig.en.localDateTime,
                          _datePattern.full
                      ),
                    })
                  }}
                </td>
                <td class="price">
                  <span v-if="pdf.showPrice">
                    {{ agencyFlightInfosPrice }}
                    €
                  </span>
                </td>
              </tr>
            </table>
          </div>
        </div>

        <div
            class="bloc"
            v-if="pdf.prestationTransfers && pdf.prestationTransfers.length > 0"
        >
          <div class="transferdetails">
            <h3>
              <span>{{ $t("document.quotation.resume.transfers.label") }}</span>
            </h3>

            <table
                v-for="(transfer, i) in pdf.prestationTransfers"
                :key="'transfer' + i"
            >
              <tr>
                <td class="date-vol">
                  <span class="pdf-blue">
                    {{
                      _parseAndFormatDate(
                          transfer.start,
                          _datePattern.localDateTime,
                          _datePattern.full
                      )
                    }}
                  </span>
                </td>

                <td class>
                  {{ transfer.fromCustomerName }} ->
                  {{ transfer.toCustomerName }} ({{
                    buildVehicles(transfer.vehicleType)
                  }})
                </td>
                <td class="price">
                  <span v-if="pdf.showPrice">
                    {{ transfer.commissionPrice || transfer.euroPrice }}
                    €
                  </span>
                </td>
              </tr>
            </table>
          </div>
        </div>

        <div
            class="bloc"
            v-if="pdf.prestationCarrental && pdf.prestationCarrental.length > 0"
        >
          <div class="carrentaldetails">
            <h3>
              <span>{{ $t("document.quotation.resume.carRent.label") }}</span>
            </h3>

            <table
                v-for="(carrental, i) in pdf.prestationCarrental"
                :key="'carrental' + i"
            >
              <tr>
                <td class="date-vol">
                  <span
                      class="pdf-blue"
                      v-html="
                      $t('document.quotation.resume.carRent.date', {
                        from: _parseAndFormatDate(
                          carrental.start,
                          _datePattern.localDateTime,
                          _datePattern.full
                        ),
                        to: _parseAndFormatDate(
                          carrental.stop,
                          _datePattern.localDateTime,
                          _datePattern.full
                        ),
                      })
                    "
                  ></span>
                </td>
                <td>{{ carrental.name }}</td>
                <td class="price">
                  <span v-if="pdf.showPrice">
                    {{ carrental.commissionPrice || carrental.euroPrice }}
                    €
                  </span>
                </td>
              </tr>
            </table>
          </div>
        </div>

        <div
            class="bloc"
            v-for="(hotel, i) in pdf.hotelsInfos"
            :key="'hotel' + i"
        >
          <div class="hoteldetails">
            <h3>
              <span>{{ hotel.hotelName }}</span>
            </h3>

            <table>
              <tr>
                <td class="date-vol" colspan="6">
                  <span class="pdf-blue">
                    {{
                      $t("document.quotation.resume.hotel.date", {
                        from: `${_parseAndFormatDate(
                            hotel.startDate,
                            datePatternConfig.serverLocalDateTime,
                            "dd"
                        )}
                                        ${
                            _getMonth(
                                _parseDate(
                                    hotel.startDate,
                                    datePatternConfig.serverLocalDateTime
                                )
                            ) !=
                            _getMonth(
                                _parseDate(
                                    hotel.stopDate,
                                    datePatternConfig.serverLocalDateTime
                                )
                            )
                                ? _parseAndFormatDate(
                                    hotel.startDate,
                                    datePatternConfig.serverLocalDateTime,
                                    "MMMM"
                                )
                                : ""
                        }
                                        ${
                            _getYear(
                                _parseDate(
                                    hotel.startDate,
                                    datePatternConfig.serverLocalDateTime
                                )
                            ) !=
                            _getYear(
                                _parseDate(
                                    hotel.stopDate,
                                    datePatternConfig.serverLocalDateTime
                                )
                            )
                                ? _parseAndFormatDate(
                                    hotel.startDate,
                                    datePatternConfig.serverLocalDateTime,
                                    "yyyy"
                                )
                                : ""
                        }
                                        `,
                        to: `
                                        ${_parseAndFormatDate(
                            hotel.stopDate,
                            datePatternConfig.serverLocalDateTime,
                            "dd MMMM yyyy"
                        )}
                                        ${$tc(
                            "document.quotation.nightsCount",
                            _nbNightsFormatted(
                                hotel.startDate,
                                hotel.stopDate
                            ),
                            {
                              size: _nbNightsFormatted(
                                  hotel.startDate,
                                  hotel.stopDate
                              ),
                            }
                        )}
                                        `,
                      })
                    }}
                  </span>
                </td>
              </tr>
              <tr v-for="room in hotel.rooms" :key="'details-room' + room.id">
                <td class colspan="4">
                  <span class="label">{{ room.roomName }}</span>
                  <ul>
                    <li>
                      {{
                        $t("document.quotation.resume.hotel.pax", {
                          adult: $tc(
                              "document.quotation.adultCount",
                              countAdult(room.guests),
                              {size: countAdult(room.guests)}
                          ),
                          child: $tc(
                              "document.quotation.childCount",
                              countChild(room.guests),
                              {size: countChild(room.guests)}
                          ),
                        })
                      }}
                    </li>
                    <li>
                      {{
                        $t("document.quotation.resume.hotel.meal", {
                          meal: room.mealName,
                        })
                      }}
                    </li>

                    <li
                        v-for="(offer, offerIndex) in room.offers"
                        :key="offerIndex"
                    >
                      {{ offer }}
                    </li>
                    <li
                        v-for="(extra, extraIndex) in room.extras"
                        :key="extraIndex"
                    >
                      {{ extra }}
                    </li>
                  </ul>
                </td>
                <td class="price" v-if="pdf.showPrice">
                  {{ room.commissionPrice || room.price }} €
                </td>
              </tr>
            </table>
          </div>
        </div>

        <div
            class="bloc"
            v-for="(p, pIndex) in pdf.prestationUntyped"
            :key="pIndex"
        >
          <div class="untypeddetails">
            <h3>
              <span>{{ p.name }}</span>
            </h3>
          </div>
        </div>

        <div class="bloc">
          <div
              class="prestation"
              v-if="pdf.prestationInsurance && pdf.prestationInsurance.length > 0"
          >
            <h3>
              <span>{{ $t("document.quotation.resume.insurance.label") }}</span>
            </h3>
            <table v-for="(p, index) in pdf.prestationInsurance" :key="index">
              <tr>
                <td class="date-vol">
                  <span class="pdf-blue">Durée du voyage</span>
                </td>
                <td>{{ p.data.customerName }}</td>
                <td class="price">
                  <span v-if="pdf.showPrice"
                  >{{ p.commissionPrice || p.euroPrice }} €</span
                  >
                </td>
              </tr>
            </table>
          </div>
        </div>

        <div class="bloc">
          <div
              class="prestation"
              v-if="
              prestationsAgency &&
              prestationsAgency.length > 0 &&
              prestationsAgency[0].price &&
              prestationsAgency[0].price != null
            "
          >
            <h3>
              <span>{{ $t("document.quotation.other.prestations") }}</span>
            </h3>
            <table v-for="(p, index) in prestationsAgency" :key="index">
              <tr>
                <td>{{ p.name }}</td>
                <td class="price">
                  <span v-if="pdf.showPrice"
                  >{{ p.commissionPrice || p.price }} €</span
                  >
                </td>
              </tr>
            </table>
          </div>
        </div>

        <div class="total">
          <span class="totalprice" v-if="pdf.isAgency && detailsOnly">
            {{ $t("document.quotation.other.agencyPriceNet") }}
            <b>{{ bookingPriceNet }} €</b>
          </span>

          <span class="totalprice">
            {{
              detailsOnly
                  ? $t("document.quotation.other.customerPrice")
                  : $t("document.quotation.other.customerPrice")
            }}
            <b
            >{{ totalPriceCommissioned }} €
              {{ $t("document.quotation.other.ttc") }}</b
            >
            <span v-if="detailsOnly && showPrices" class="ml-2"
            >({{ pdf.commission }} %)</span
            >
          </span>
        </div>

        <span>
          <div class="text-right">
            {{ $t("document.quotation.other.priceInfo") }}
          </div>

          <div
              v-if="
              insuranceAgency &&
              insuranceAgency.price &&
              insuranceAgency.price != null
            "
              class="included insurance"
          >
            <div class="label">
              {{ $t("document.quotation.other.optionalInsurance") }} :
            </div>
            <table style="width: 100%">
              <tr>
                <td>{{ insuranceAgency.name }}</td>
                <td
                    class="text-right"
                    style="color: #0099d9; font-size: 20px; font-weight: bold"
                >
                  {{ insuranceAgency.price }} €
                </td>
              </tr>
            </table>
          </div>

          <div class="included">
            <div class="label">
              {{ $t("document.quotation.other.priceInclude") }}:
            </div>
            <ul>
              <li v-if="pdf.priceInclude">{{ pdf.priceInclude }}</li>
              <li v-else>
                L'hébergement pour
                {{
                  $tc(
                      "nights",
                      _nbNightsFormatted(pdf.startDate, pdf.stopDate),
                      {size: _nbNightsFormatted(pdf.startDate, pdf.stopDate)}
                  )
                }}
                <span v-if="pdf.prestationTransfers.length > 0"
                >, les transferts</span
                >
                <span v-if="pdf.prestationFlights.length > 0"
                >, le transport aérien, les taxes d'aéroport</span
                >
                et l’assistance sur place de notre correspondant.
              </li>
            </ul>
          </div>

          <div class="included">
            <div class="label">
              {{ $t("document.quotation.other.priceNotInclude") }}:
            </div>
            <ul>
              <li v-if="pdf.priceExclude">{{ pdf.priceExclude }}</li>
              <li v-else>
                Les dépenses personnelles, les pourboires, les taxes de séjour
                locale ainsi que les formalités d'entré du pays visité.
              </li>
            </ul>
          </div>

          <div class="included remarks">
            <div class="label">
              {{ $t("document.quotation.other.remarks") }} :
            </div>
            <ul>
              <li v-if="pdf.quotationRemark">{{ pdf.quotationRemark }}</li>
              <li v-else-if="!pdf.isAgency">
                Formalités : Pensez à bien vérifier que vous êtes en possession
                des documents exigés pour la destination
              </li>
            </ul>
          </div>

          <div v-if="pdf.paymentterms" class="included paymentterms">
            <div class="label">
              {{ $t("document.quotation.other.paymentterms") }} :
            </div>
            <ul>
              <li v-if="pdf.paymentterms" v-html="pdf.paymentterms"></li>
            </ul>
          </div>

          <div v-if="pdf.isAgency && agencyCancelFees != null">
            <div class="included">
              <div class="label">
                {{ $t("document.quotation.other.cancelFees") }} :
              </div>
              <div
                  v-if="agencyCancelFees != ''"
                  v-html="agencyCancelFees"
              ></div>
              <div v-else>-</div>
            </div>
          </div>

          <div
              v-else-if="
              pdf.commissionCancelFees &&
              pdf.commissionCancelFees.fees.length > 0
            "
          >
            <div class="included">
              <div class="label">
                {{ $t("document.quotation.other.cancelFees") }} :
              </div>
              <div
                  v-for="(cf, i) in buildCancelFeesLabel(
                  pdf.commissionCancelFees.fees,
                  true
                )"
                  :key="'cf' + i"
              >
                {{ cf }}
              </div>
            </div>
          </div>

          <div v-else-if="pdf.cancelFees && pdf.cancelFees.fees.length > 0">
            <div class="included">
              <div class="label">
                {{ $t("document.quotation.other.cancelFees") }} :
              </div>
              <div
                  v-for="(cf, i) in buildCancelFeesLabel(
                  pdf.cancelFees.fees,
                  false
                )"
                  :key="'cf' + i"
              >
                {{ cf }}
              </div>
            </div>
          </div>
        </span>
      </div>

      <div v-if="!detailsOnly" class="page bb-always devis legal-notice">
        <h3>{{ $t("document.quotation.other.legalNoticeLabel") }}</h3>
        <div v-html="pdf.legalNotice"></div>
      </div>

      <span id="loaded"></span>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import {get} from "@/utils/api";
import GuestsMixin from "@/components/mixins/GuestsMixin";

export default {
  name: "Quotation",
  mixins: [GuestsMixin],
  props: {
    bookingid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      baseUrl: process.env.BASE_URL,
      pdf: null,
      agencyData: null,
      allPrestations: {},
    };
  },
  created() {
    this.init();
  },
  watch: {},
  computed: {
    detailsOnly() {
      return this.$route.query.detailsOnly === "true";
    },
    showPrices() {
      return this.$route.query.showPrices === "true";
    },
    prestationsAgency() {
      return this.pdf.prestationsAgency
          ? JSON.parse(this.pdf.prestationsAgency)
          : [];
    },
    insuranceAgency() {
      return this.pdf.insuranceAgency
          ? JSON.parse(this.pdf.insuranceAgency)
          : null;
    },
    warranter() {
      return this.pdf.warranter ? JSON.parse(this.pdf.warranter) : null;
    },
    agencyCancelFees() {
      return this.pdf.agencyCancelfees != null
          ? this.pdf.agencyCancelfees
          : null;
    },
    agencyFlightInfo() {
      if (this.pdf.flightinfos) {
        const flightinfos = JSON.parse(this.pdf.flightinfos);
        return flightinfos.arrival && flightinfos.departure
            ? flightinfos
            : null;
      } else return null;
    },
    agencyFlightInfosPrice() {
      if (this.agencyFlightInfo != null) {
        let price = 0;
        try {
          const p = parseFloat(this.agencyFlightInfo.price);
          price = isNaN(p) ? 0 : p;
        } catch (e) {
          price = 0;
        }
        return price;
      } else return 0;
    },
    totalPriceAgencyPrestations() {
      const pricePrestations =
          this.prestationsAgency && this.prestationsAgency.length > 0
              ? this.prestationsAgency
                  .map((p) => p.price || 0)
                  .reduce((a, b) => a + b, 0)
              : 0;

      return pricePrestations + this.agencyFlightInfosPrice;
    },
    bookingPriceNet() {
      return this.pdf.totalPrice;
    },
    bookingPriceCommissioned() {
      return this.pdf.totalPriceCommissioned;
    },
    totalPriceNet() {
      return this.pdf.totalPrice + this.totalPriceAgencyPrestations;
    },
    totalPriceCommissioned() {
      return this.pdf.totalPriceCommissioned + this.totalPriceAgencyPrestations;
    },
  },
  methods: {
    init() {
      const url = `/api/document/quotation/${this.bookingid}?inline=true&showPrices=${this.showPrices}`;

      return get(url).json((data) => {
        this.pdf = data;
        this.$locale = data.localCode;
        this.setLocale(this.$locale);

        this.allPrestations = this.buildPrestaByDate(
            _.groupBy(
                data.prestations,
                (o) => o.start.substring(0, 10) //+ "_" + o.stop.substring(0, 10)
            )
        );
      });
    },
    buildPrestaByDate(prestations) {
      return Object.keys(prestations)
          .sort((left, right) => {
            const leftStart = left.substring(0, left.indexOf("_"));
            const rightStart = right.substring(0, left.indexOf("_")); //right.substring(right.indexOf("_") + 1);

            const a = this._parseAndFormatDate(
                leftStart,
                this._datePattern.localDate,
                "T"
            ); //Unix Millisecond Timestamp

            const b = this._parseAndFormatDate(
                rightStart,
                this._datePattern.localDate,
                "T"
            ); //Unix Millisecond Timestamp
            return a - b;
          })
          .map((key) => {
            const items = prestations[key];
            return {key, items};
          });
    },
    buildCancelFeesLabel(cancelFees, commissionned) {
      return cancelFees.map((fee, i) => {
        const from = this._parseDate(
            fee.start,
            this.datePatternConfig.fr.localDate
        );
        const to = this._parseDate(
            fee.stop,
            this.datePatternConfig.fr.localDate
        );

        if (i == 0) {
          return this.$t("document.cancelfees.from", {
            0: this._formatDate(from, "dd/MM/yyyy"),
            1: this._formatDate(to, "dd/MM/yyyy"),
            2:
                commissionned && fee.price > this.pdf.totalPriceCommissioned
                    ? this.pdf.totalPriceCommissioned
                    : fee.price,
          });
        } else {
          return this.$t("document.cancelfees.between", {
            0: this._formatDate(from, "dd/MM/yyyy"),
            1: this._formatDate(to, "dd/MM/yyyy"),
            2:
                commissionned && fee.price > this.pdf.totalPriceCommissioned
                    ? this.pdf.totalPriceCommissioned
                    : fee.price,
          });
        }
      });
    },
    buildVehicles(vehicles) {
      return _.uniq(vehicles.map((v) => this.$t("vehicleType." + v))).join(
          ", "
      );
    },
    uniqBy(items, aggregat) {
      return _.uniqBy(items, aggregat);
    },
    groupBy(items, aggregat) {
      return _.groupBy(items, aggregat);
    },
    areSameDate(d1Str, d2Str) {
      return this._isSame(
          this._parseDate(d1Str, "dd/MM/yyyy HH:mm:ss"),
          this._parseDate(d2Str, "dd/MM/yyyy HH:mm:ss"),
          "day"
      );
    },
  },
};
</script>

<style scoped lang="less" src="@/assets/pdf/css/default.less"></style>
<style scoped lang="less">
@blue: #0099d9;
@blue-1: #004a90;
@grey: #77797b;

.content-pdf {
  font-family: Times, serif !important;
}

h2 {
  font-size: 20pt;
  text-align: center;
  margin: 5px auto 5px auto;
}

.v-icon {
  width: 17px;
}

hr {
  border: 0;
  border-bottom: solid 1px;
  width: 70%;
  margin: auto auto 20px;
}

.pdf-blue {
  color: @blue;
}

.clearfix() {
  &:before,
  &:after {
    content: " "; // 1
    display: table; // 2
  }
  &:after {
    clear: both;
  }
}

.intro {
  width: 80%;
  margin: 0 auto;
  padding-top: 50px;
  text-align: center;

  .images {
    .clearfix();

    .img {
      float: left;
      width: 49%;
      height: 310px;
      margin-bottom: 10px;
      position: relative;
      overflow: hidden;

      img {
        width: 100%;
        //height: 310px;
      }

      &:nth-child(even) {
        margin-left: 2%;
      }

      &:nth-child(3) {
        width: 100%;
      }

      &:last-child .line {
        height: 310px;
        width: 10px;
        background-color: #fff;
        position: absolute;
        top: -1px;
        left: 50%;
        margin-left: -5px;
      }
    }
  }

  .infos {
    .clearfix();

    .bloc {
      height: 160px;
      padding: 20px;
      width: 49%;
      background-color: #d9eaf7;
      float: left;

      &:nth-child(even) {
        margin-left: 2%;
      }

      h4 {
        margin: 0;
      }

      hr {
        margin-bottom: 5px !important;
      }
    }
  }

  .bookingnumber {
    margin-top: 20px;
  }

  .title {
    background-color: @blue;
    padding: 20px;
    min-height: 200px;
    margin: 0;
    color: #fff;

    .subtitle {
      font-style: italic;
    }

    margin-bottom: 10px;
  }
}

.timeline {
  .v-rating {
    display: inline-block;

    /deep/ i {
      padding: 0 !important;
      margin: 0 !important;
      font-size: 11px !important;
      bottom: 5px;
      position: relative;
    }
  }
}

.event {
  .date {
    color: #094ab1;
    font-size: 12pt;
    font-weight: bold;
    position: relative;
    padding-left: 10px;

    &:before {
      content: "";
      position: absolute;
      top: 7px;
      left: 0;
      width: 0;
      height: 0;
      border: 5px solid transparent;
      border-left: 5px solid #094ab1;
    }
  }

  ul {
    margin-top: 5px;
    margin-left: 100px;
    list-style-type: none;

    li {
      p {
        font-style: italic;
        padding-left: 30px;
        margin-top: 0;
        color: @blue;
      }
    }
  }
}

.destination {
  /deep/ p {
    font-size: 10pt;
  }

  /deep/ p:first-child b {
    font-size: 10pt;
    color: #000;
    margin-bottom: 10px;
    margin-top: -10px;
  }

  /deep/ b {
    display: block;
    text-align: center;
    color: @blue;
    font-size: 16px;
  }

  /deep/ p:nth-child(3) b {
    display: inline;
    color: #000;
    font-size: 14px;
  }

  /deep/ p:first-of-type {
    margin: 0;
    text-align: center;
  }
}

.hotel {
  font-size: 13px;

  hr {
    margin-bottom: 10px;
  }

  h2 {
    font-size: 20pt;

    .rating-container {
      position: relative;
      bottom: 20px;
      left: 20px;
    }
  }

  .v-rating {
    display: inline-block;

    /deep/ i {
      padding: 0 !important;
      margin: 0 !important;
      font-size: 16px !important;
      bottom: 20px;
      position: relative;
    }
  }

  .location {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    color: @blue;
    margin-bottom: 20px;
  }

  .media {
    float: left;
    width: 49%;
    height: 200px;
    margin-bottom: 10px;
    position: relative;
    overflow: hidden;

    img {
      width: 100%;
    }

    &:nth-child(even) {
      margin-left: 2%;
    }
  }

  .desc {
    p {
      padding-left: 150px;
    }

    strong {
      color: @grey;
      display: inline-block;
      width: 150px;
      margin-left: -150px;
      position: relative;
      padding-left: 10px;

      &:before {
        content: "";
        position: absolute;
        top: 7px;
        left: 0;
        width: 0;
        height: 0;
        border: 5px solid transparent;
        border-left: 5px solid @grey;
      }
    }
  }

  .room {
    page-break-inside: avoid;

    .roombook {
      text-align: center;
      font-size: 16px;
      margin-bottom: 20px;
    }

    .roomdesc {
      vertical-align: top;
    }

    .media {
      height: 120px;
      margin-bottom: 10px;
      position: relative;
      overflow: hidden;

      img {
        width: 100%;
      }
    }

    .whatin {
      color: @blue;
    }

    .services {
      margin-top: 10px;

      .service {
        .clearfix();

        .service-title,
        ul {
          float: left;
        }

        .service-title {
          width: 150px;
          color: @grey;
          font-weight: bold;
          position: relative;
          padding-left: 10px;

          &:before {
            content: "";
            position: absolute;
            top: 7px;
            left: 0;
            width: 0;
            height: 0;
            border: 5px solid transparent;
            border-left: 5px solid @grey;
          }
        }

        ul {
          margin-top: 0;
          width: 640px;
          display: inline-block;
          padding-left: 0;
          list-style-position: inside;

          li {
            float: left;
            width: 210px;
          }

          padding-bottom: 5px;
          border-bottom: solid 1px #000;
        }
      }
    }
  }
}

.pdfdetails {
  h3 {
    img {
      vertical-align: middle;
    }

    span {
      color: @blue-1;
      display: inline-block;
      width: 100%;
      border-bottom: solid 1px @blue;
      position: relative;
      padding-left: 12px;

      &:before {
        content: "";
        position: absolute;
        top: 3px;
        left: 0;
        width: 0;
        height: 0;
        border: 7px solid transparent;
        border-left: 7px solid @blue-1;
      }
    }
  }

  .bloc {
    page-break-inside: avoid;
    margin-bottom: 20px;
  }

  .total {
    page-break-inside: avoid;
    border-top: solid 1px @blue;
    border-bottom: solid 1px @blue;
    padding: 10px 0;
    text-align: right;
    margin-bottom: 5px;
    font-size: 20px;

    .totalprice {
      background: @blue;
      color: #fff;
      padding: 5px 15px;
      font-weight: bold;

      b {
        margin-left: 15px;
      }
    }
  }

  td.price {
    font-weight: bold;
  }

  td.date-vol {
    vertical-align: top;
    width: 20%;
  }

  .flightsdetails {
    .blue {
      display: inline-block;
      width: 120px;
    }
  }

  .hoteldetails,
  .flightsdetails,
  .transferdetails,
  .carrentaldetails,
  .prestation {
    .label {
      padding-left: 20px;
      font-weight: bold;
    }

    table {
      border-spacing: 0;
      width: 100%;

      .price {
        width: 100px;
        text-align: right;
      }
    }

    ul {
      list-style-type: none;
      margin-top: 0;
    }
  }

  .included {
    .label {
      color: @blue;
      font-size: 20px;
      font-weight: bold;
    }

    ul {
      list-style-type: none;
    }

    /*&.remarks {
					height: 520px;
			}*/
  }
}

.legal-notice p {
  font-size: 11px !important;
}

.semi {
  float: left;
  width: 50%;
  padding: 5px;
}

tr.price-flights:nth-child(odd) td:last-of-type {
  display: none;
}
</style>
