<template>
  <v-data-table
    :headers="headers"
    class="elevation-1"
    :items="internalTicketsList"
    :hide-default-footer="true"
    v-model="refundRequested"
    :show-select="true"
    @item-selected="itemChanged"
    @toggle-select-all="itemsChanged"
    :loading="loading"
    loading-text="Enregistrement en cours"
  >
    <template #header.data-table-select="{ on, props }">
      Demande remboursement
      <v-simple-checkbox v-bind="props" v-on="on"></v-simple-checkbox>
    </template>
    <template #item.name="{ item }">{{ `${item.guest.firstName} ${item.guest.lastName}` }}</template>
    <template #item.ageClassType="{ item }">{{ item.guest.ageClassType }}</template>
    <template
      #item.status="{ item }"
    >{{ item.item.ticketStatus == 'Ticketed' ? 'Émis' : 'Non émis' }}</template>
    <template #item.refundRequestDate="{ value }">{{ value?value:'-' }}</template>
    <template #item.refundedDate="{ value }">{{ value?value:'-' }}</template>
  </v-data-table>
</template>
<script>
import { get, post } from "@/utils/api";
import { EventBus } from "@/components/commons/event-bus";
export default {
  name: "FlightTickets",
  props: {
    ticketsList: {
      type: Array,
      required: true,
    },
  },
  components: {},
  data() {
    return {
      internalTicketsList: [],
      loading: false,
      refundRequested: [],
      headers: [
        {
          text: "Nom du voyageur",
          align: "left",
          sortable: false,
          value: "name",
        },
        {
          text: "Tranche d'age",
          align: "left",
          sortable: false,
          value: "ageClassType",
        },
        {
          text: "Numéro du ticket",
          align: "left",
          sortable: false,
          value: "ticketNumber",
        },
        {
          text: "Type",
          align: "left",
          sortable: false,
          value: "ticketType",
        },
        {
          text: "Status",
          align: "left",
          sortable: false,
          value: "ticketStatus",
        },
        {
          text: "Date Demande Remboursement",
          align: "left",
          sortable: false,
          value: "refundRequestDate",
        },
        {
          text: "Date remboursement",
          align: "left",
          sortable: false,
          value: "refundedDate",
        },
      ],
    };
  },
  created() {
    this.init();
    
  },
  computed: {

  },
  watch: {
    ticketsList:{
      initial:false,
      handler(newVal){
        this.init()
      }
    }
  },
  methods: {
    init() {
      this.internalTicketsList = [...this.ticketsList]
      this.refundRequested = this.internalTicketsList.reduce((acc, curr) => {
        return curr.refundRequestDate != null ? [...acc, ...[curr]] : acc;
      }, []);
    },
    itemChanged(payload) {
      this.update([payload.item], payload.value)
    },

    itemsChanged(payload) {
      this.update(payload.items, payload.value)

    },

    update(items, state) {
      this.loading = true
      post(`/api/ticket/update-refund/${state}`, items)

        .json((data) => {
          this.internalTicketsList = [...data]
        }).then(() => {
          this.loading = false
        })
    }
  }
};
</script>
<style scoped>
</style>
