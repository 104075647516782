<template>
  <layout :drawerShow="_isDesktop()" :drawerWidth="350">
    <template slot="drawer">
      <v-form
        ref="searchform"
        class="mt-2"
        v-if="searchform"
        @submit.prevent="loadData"
      >
        <v-row>
          <v-col cols="12" class="my-0 ml-3 pa-0">
            <v-subheader light class="pa-0 mt-1 mb-0 mx-0 form-subheader"
              >Réservation</v-subheader
            >
            <v-divider></v-divider>
          </v-col>
          <v-col class="pr-1" cols="6">
            <smart-picker
              v-model="searchform.bookingDateStart"
              :stopDate="searchform.bookingDateStop"
              :inputOpts="{ label: $t('begin') }"
            ></smart-picker>
          </v-col>
          <v-col cols="6">
            <smart-picker
              v-model="searchform.bookingDateStop"
              :startDate="searchform.bookingDateStart"
              :inputOpts="{ label: $t('end') }"
            ></smart-picker>
          </v-col>

          <v-col cols="12" class="mt-2 mb-0 ml-3 pa-0">
            <v-subheader light class="pa-0 mt-2 mb-0 mx-0 form-subheader"
              >Dates de voyage</v-subheader
            >
            <v-divider></v-divider>
          </v-col>
          <v-col class="pr-1" cols="6">
            <smart-picker
              v-model="searchform.tripDateStart"
              :stopDate="searchform.tripDateStop"
              :inputOpts="{ label: $t('begin') }"
            ></smart-picker>
          </v-col>
          <v-col cols="6">
            <smart-picker
              v-model="searchform.tripDateStop"
              :startDate="searchform.tripDateStart"
              :inputOpts="{ label: $t('end') }"
            ></smart-picker>
          </v-col>
          <v-col cols="12" class="my-0 ml-3 pa-0">
            <v-subheader light class="pa-0 mt-1 mb-0 mx-0 form-subheader"
              >Aéroports</v-subheader
            >
            <v-divider></v-divider>
          </v-col>
          <v-col cols="6">
            <v-select
              multiple
              v-model="searchform.airportsFrom"
              :items="airportDeparture"
              label="Départ"
              class="pa-1"
            ></v-select>
          </v-col>
          <v-col cols="6">
            <v-select
              multiple
              v-model="searchform.airportsTo"
              :items="airportArrival"
              label="Départ"
              class="pa-1"
            ></v-select>
          </v-col>

          <v-col cols="12" class="my-0 ml-3 pa-0">
            <v-subheader light class="pa-0 mt-1 mb-0 mx-0 form-subheader"
              >Sites</v-subheader
            >
            <v-divider></v-divider>
          </v-col>

          <v-col cols="12" class="pr-1 mt-4">
            <smart-multi-select
              v-model="searchform.selectedSites"
              label="Sites"
              :items="sites"
              :all-is-same-as-empty="true"
              :default-select-all="true"
            ></smart-multi-select>
          </v-col>
        </v-row>

        <smart-btn type="submit" :loading="loading" block primary
          >Rechercher</smart-btn
        >
        <smart-btn
          block
          tertiary
          @click.native="
            clearSearchform();
            loadData();
          "
          >Réinitialiser la recherche</smart-btn
        >
      </v-form>
    </template>

    <template slot="content">
      <v-container fluid>
        <v-row justify="space-between">
          <v-col cols="12" class="mb-3 mt-2">
            <v-card class="pa-0 ma-0 blue-grey lighten-1">
              <v-row justify="space-between">
                <v-col cols="12" md="3" class="px-1">
                  <widget-booking-size
                    :bus="widgetBus"
                    url="/api/widget/flights/bookingsize"
                  ></widget-booking-size>
                </v-col>
                <v-col cols="12" md="3" class="px-1">
                  <widget-booking-passenger
                    :bus="widgetBus"
                    url="/api/widget/flights/passangerSize"
                  ></widget-booking-passenger>
                </v-col>
                <v-col cols="12" md="3" class="px-1">
                  <widget-booking-ca
                    :bus="widgetBus"
                    url="/api/widget/flights/bookingCa"
                  ></widget-booking-ca>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <v-row justify="space-around">
          <v-col cols="12" class="mb-3 mt-3">
            <widget-flight-airline-charts
              :bus="widgetBus"
            ></widget-flight-airline-charts>
          </v-col>
          <v-col cols="12" class="mb-3 mt-3">
            <widget-flight-airline
              @loading="setLoading"
              :bus="widgetBus"
            ></widget-flight-airline>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </layout>
</template>

<script>
import BookingDetails from "../../components/booking/BookingDetails";
import BookingCustomerDetails from "../../components/booking/BookingCustomerDetails";
import BookingPayments from "../../components/booking/BookingPayments";
import WidgetBookingSize from "../../components/commons/widgets/WidgetBookingSize";
import WidgetBookingPassenger from "../../components/commons/widgets/WidgetBookingPassenger";
import WidgetBookingResume from "../../components/commons/widgets/WidgetBookingResume";
import Vue from "vue";
import Layout from "../Layout";
import SmartMultiSelect from "../../components/commons/SmartMultiSelect";
import WidgetBookingCa from "../../components/commons/widgets/WidgetBookingCa";
import WidgetBookingResumeWithFlights from "../../components/commons/widgets/WidgetBookingResumeWithFlights";
import WidgetFlightAirline from "../../components/commons/widgets/WidgetFlightAirline";
import WidgetFlightAirlineCharts from "../../components/commons/widgets/WidgetFlightAirlineCharts";
import { get, post } from "@/utils/api";

export default {
  name: "FlightStats",
  components: {
    WidgetFlightAirline,
    WidgetFlightAirlineCharts,
    WidgetBookingResumeWithFlights,
    WidgetBookingCa,
    Layout,
    WidgetBookingResume,
    WidgetBookingPassenger,
    WidgetBookingSize,
    BookingPayments,
    BookingCustomerDetails,
    BookingDetails,
    SmartMultiSelect,
  },
  mixins: [],
  data() {
    return {
      widgetBus: new Vue(),
      defaultSearchform: {
        selectedSites: [],
        selectedNetworks: [],
        selectedAgencies: [],
        selectedChains: [],
        selectedHotels: [],
        selectedDestinations: [],
        client: null,
        ref: null,
        bookingDateStart: this._nowFormatted("dd/MM/yyyy"),
        bookingDateStop: this._nowFormatted("dd/MM/yyyy"),
        tripDateStart: null,
        tripDateStop: null,
        bookingStatus: ["booked", "cancelled"],
        supplierStatus: [],
        paymentStatus: null,
        tags: [],
      },
      searchform: {},
      airportDeparture: [],
      airportArrival: [],
      sites: [],
      loading: false,
    };
  },
  created() {
    this.init();
  },
  watch: {},
  computed: {},
  methods: {
    init() {
      setTimeout(() => {
        this.clearSearchform();
        this.loadAirportCodes(true).json((r) => {
          this.airportDeparture = r;
        });
        this.loadAirportCodes(false).json((r) => {
          this.airportArrival = r;
        });
        this.loadSites().json((r) => {
          this.sites = r;
        });
        this.loadData();
      }, 1);
    },

    loadAirportCodes(isDeparture) {
      const url = `/api/search/airportCodes?departure=${isDeparture}`;
      return get(url);
    },
    loadSites() {
      const url = "/api/search/sites";
      return get(url);
    },
    loadData() {
      if (!this.loading) {
        this.widgetBus.$emit("search", this.searchform);
      }
    },
    clearSearchform() {
      this.searchform = { ...this.defaultSearchform };
    },
    setLoading(loading) {
      this.loading = loading;
    },
  },
};
</script>

<style scoped>
.v-subheader {
  width: 100%;
  height: 20px;
  color: #616161;
}
</style>
