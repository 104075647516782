<template>
      <v-form
          class="pa-4"
          ref="eventForm"
          v-model="eventform.valid"
          v-on:submit.prevent="dataReport == null ? Event('create') : Event('edit')"
          autocomplete="off"
      >
        <v-row>
          <v-col class="pb-0" cols="12" md="8">
            <v-radio-group
                v-model="eventform.commercialEventType"
                label="Type d'évènement:"
                dense
                row
                class="mx-auto"
                :rules="rules.nonEmpty()"
            >
              <v-radio
                  small
                  label="Visite"
                  color="primary"
                  value="visit"
                  dense
              ></v-radio>
              <v-radio
                  small
                  label="Appel"
                  color="primary"
                  value="call"
                  dense
              ></v-radio>
              <v-radio
                  small
                  label="Mail"
                  color="primary"
                  value="mail"
                  dense
              ></v-radio>
              <v-radio
                  small
                  label="Autre"
                  color="primary"
                  value="other"
                  dense
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col>
            <v-checkbox label="Démo" small color="primary" v-model="eventform.demo" />
          </v-col>
        </v-row>
        <v-row>
          <v-col :class=" _isMobile() ? 'py-0' : 'pt-0'" cols="6" md="2">
            <smart-picker
                :rules="rules.nonEmpty()"
                label="Date"
                v-model="eventform.eventDate"
                autocomplete="off"
            ></smart-picker>
          </v-col>
          <v-col :class=" _isMobile() ? 'py-0' : 'pt-0'" cols="3" md="2">
            <v-select
                :rules="rules.nonEmpty()"
                label="Heure"
                :items="hours"
                v-model="eventform.hours"
                autocomplete="off"
            ></v-select>
          </v-col>
          <v-col :class=" _isMobile() ? 'py-0' : 'pt-0'" cols="3" md="2">
            <v-select
                :rules="rules.nonEmpty()"
                label="Minute"
                :items="minutes"
                v-model="eventform.minutes"
                autocomplete="off"
            ></v-select>
          </v-col>
          <v-col :class=" _isMobile() ? 'py-0' : 'pt-0'" cols="12" md="3">
            <smart-multi-select
                :default-select-all="false"
                :items="selectorAgents"
                style="margin-top: 22px"
                item-text="name"
                item-value="id"
                label="Participants"
                v-model="eventform.agents"
            >
            </smart-multi-select>
          </v-col>
          <v-col :class=" _isMobile() ? 'py-0' : 'pt-0'" cols="12" md="3">
            <v-select
                :default-select-all="false"
                :rules="rules.nonEmpty()"
                :error="checkRecipient ? eventform.recipien == null : checkRecipient"
                :items="commercials"
                item-text="name"
                item-value="id"
                label="Destinataire"
                v-model="eventform.recipientId"
            >
            </v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col :class=" _isMobile() ? '' : 'pt-0'" cols="12" md="12">
            <p class="mb-1">Commentaires</p>
            <smart-editor
                v-model="eventform.report"
                label="Compte rendu"
                :rules="rules.nonEmpty()"
                autocomplete="off"
            ></smart-editor>
          </v-col>
        </v-row>
        <v-row v-if="checkReport">
          <v-col cols="9" md="6" class="d-flex align-center red lighten-5 py-1 ml-4" style="border-radius: 5px">
            <v-icon small class="red--text">warning</v-icon>
            <p class="mb-0 ml-2 red--text">Saisir un commentaire est obligatoire</p>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3" offset="9" md="3" class="d-flex justify-end">
            <smart-btn
                @click.native="openEventEditor = false"
                type="submit"
                :disabled="!eventform.valid"
            >Enregistrer</smart-btn
            >
          </v-col>
        </v-row>
      </v-form>
</template>
<script>
import SmartEditor from "@/components/commons/SmartEditor.vue";
import SmartMultiSelect from "@/components/commons/SmartMultiSelect.vue";
import FormMixin from "@/components/mixins/FormMixin";
import {get, post} from "@/utils/api";
import {EventBus} from "@/components/commons/event-bus";
import Layout from "@/views/Layout.vue";
import CrmAgencyInfo from "@/components/commons/CrmAgencyInfo.vue";
import {format} from "date-fns";

export default {
  mixins: [FormMixin],
  components: {
    SmartEditor,
    SmartMultiSelect,
  },
  props:{
    selectorAgents:{type:Array,required:true},
    dataReport:{type:Object, required: false, default: null},
    agencyId:{type:Number,required:false, default: null}
  },
  data(){
    return{
      eventform: {
        valid: true,
        commercialEventType: null,
        eventDate: format(new Date(),"dd/MM/yyyy"),
        hours: format(new Date(),"HH"),
        minutes: format(new Date(),"mm"),
        agents: [],
        agencyId: this.agencyId,
        recipientId:89,
        report: null,
        demo:false
      },
      hours: [],
      minutes: [],
      agents: [],
      commercials:null,
      checkRecipient:null,
      checkReport:null
    }
  },
  created() {
    this.setTime()
    this.setData()
    this.getCommercials()
  },
  methods:{
    async Event(event) {
      this.$refs.eventForm.validate();

      const url = event == 'create' ? "/api/agency/crm/event/create" : `/api/agency/crm/event/${this.dataReport.id}/edit`;

      let form = {
        ...this.eventform,
        eventDate:
            this.eventform.eventDate &&
            this.eventform.hours &&
            this.eventform.minutes
                ? this._parseAndFormatDate(
                    this.eventform.eventDate +
                    " " +
                    this.eventform.hours +
                    ":" +
                    this.eventform.minutes +
                    ":00",
                    this.datePatternConfig.serverLocalDateTime,
                    this.datePatternConfig.serverLocalDateTime
                )
                : null,
      };

      this.checkRecipient = form.recipientId.length == 0

      this.checkReport = !(form.report != null && form.report != "")

      if(!this.checkRecipient && form.report != null && form.report != ""){

        this.checkReport = false
        await post(url, form)
            .badRequest((br) => {
              EventBus.$emit('toaster-error', "erreur lors de l'enregistrement de l'évenement")
              console.log("Error: ", br);
            })
            .res(() => {
              EventBus.$emit('toaster-msg', "Evènement enregistré")
              this.$emit('closeEvent');
        });
        EventBus.$emit('updateVisite', {})
      }
    },
    setTime() {
      for (let h = 0; h <= 23; h++) {
        if (h.toString().length < 2) {
          this.hours.push("0" + h.toString());
        } else {
          this.hours.push(h.toString());
        }
      }
      for (let m = 0; m <= 59; m++) {
        if (m.toString().length < 2) {
          this.minutes.push("0" + m.toString());
        } else {
          this.minutes.push(m.toString());
        }
      }
    },
    setData(){
      if(this.dataReport != null){
        this.eventform.eventDate = this._parseAndFormatDate(this.dataReport.creationDate,"dd/MM/yyyy HH:mm:ss","dd/MM/yyyy")
        this.eventform.hours = this._parseAndFormatDate(this.dataReport.creationDate,"dd/MM/yyyy HH:mm:ss","HH")
        this.eventform.minutes = this._parseAndFormatDate(this.dataReport.creationDate,"dd/MM/yyyy HH:mm:ss","mm")
        this.eventform.report = this.dataReport.report
        this.eventform.agents = this.dataReport.agentsIds
        this.eventform.commercialEventType = this.dataReport.logType
        this.eventform.recipientId = this.dataReport.recipientCommercialId
        this.eventform.demo = this.dataReport.demo
      }
    },
    async getCommercials(){
      const url = "/api/search/commercials"

      await get(url)
        .badRequest((br) => {
          EventBus.$emit('toaster-error', "erreur lors de l'enregistrement de l'évenement")
          console.log("Error: ", br);
        })
        .json((data) => {
          this.commercials = data
        })
    },
  }
}
</script>