<template>
  <div>

    <div v-if="proposition !== void(0) && proposition != null">
      <div v-if="proposition.prices"
            @click="select(proposition)"
            class="cell-price">
        <span class="price">
          {{(this.basePrice+proposition.prices.price) | currency(proposition.prices.currency.code)}}
          <span class="promo" if="proposition.prices.stroke > proposition.prices.price">
            -{{((proposition.prices.stroke-proposition.prices.price)*100/proposition.prices.stroke).toFixed()}}%
          </span>
        </span>
        <div>
          <span class="status" :class="[proposition.availability.status]"></span>
        </div>
      </div>
      <div v-else class="soldout">

        <span class="occupable" v-if="!proposition.availability.occupable">
          <span class="stroke">occupable</span>
        </span>
        <span class="minStay" v-if="proposition.availability.minStay != null">
          <span class="stroke">minStay</span> {{proposition.availability.minStay}}</span>
        <span v-else>
          {{proposition.availability.status}}
        </span>
        <div><span class="status" :class="[proposition.availability.status]"></span></div>
      </div>
    </div>
    <div v-else>-</div>

  </div>
</template>
<script>

import _ from 'lodash';


export default {
  name: "HotelPriceCell",
  props:{
    'proposition':{required:true},
    'basePrice':{type:Number, default:0}
  },
  data(){
    return {
      dialog:false,
    }
  },
  computed:{
    freeOffers(){
      return _.filter(this.proposition.prices.offers, (o) => {
          return o.amount === 0
      })
    }
  },
  methods:{
    select(proposition){
      this.$emit('select', proposition)
    }
  }
}

</script>
<style scoped>
.cell-price{
  cursor: pointer;
}
.price {
  font-size: 14px;
}
.status {
  width: 20px;
  border-radius: 5px;
}
</style>
