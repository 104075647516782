<template>
  <div class="d-flex">
    <div class="ml-5">
						<v-icon class="float-right" @click="removePeriod()">mdi-close-circle-outline</v-icon>
      <div class="d-flex align-baseline">
        <span class="caption font-weight-medium mr-2">{{ $t('from') }}</span>

        <div :class="fee.invalid == 'start' ? 'red lighten-4' :''">
          <smart-picker v-model="localFee.start" label="Date de début" :max="localFee.stop"
                        @input="change()"></smart-picker>
        </div>
      </div>
      <br/>
      <div class="d-flex align-baseline">
        <span class="caption font-weight-medium mr-2">{{ $t('to') }}</span>
        <div :class="fee.invalid == 'stop' ? 'red lighten-4' :''">
          <smart-picker v-model="localFee.stop" label="Date de fin" :min="localFee.start"
                        @input="change()"></smart-picker>
        </div>
      </div>
      <br/>
      <div :class="fee.invalid == 'price' ? 'red lighten-4' :''">
        <v-text-field class="ml-6" append-icon="euro_symbol" v-model.number="localFee.price" :label="$t('price')"
                      @change="change()"></v-text-field>
      </div>
    </div>
    <v-divider v-if="!divider" vertical class="ml-9"/>
  </div>
</template>
<script>

export default {
  name: "CancelFeeEdit",
  props: {
    fee: {type: Object, required: true},
    divider: {type: Boolean, default: true},
    startPrestaDate: {type: String, required: true},
  },
  data() {
    return {
      localFee: null
    }
  },
  watch: {
    fee: {
      handler(newVal){
        this.localFee = JSON.parse(JSON.stringify(newVal))
      },
      deep: true,
      immediate: true,
    }
  },
  methods: {
    change() {
      this.$emit('change', this.localFee)
    },
    removePeriod(){
      this.$emit('remove', this.localFee)
    },
  },
}
</script>