<template>
  <div>
    <v-progress-circular
      v-if="loading"
      :indeterminate="loading"
      :size="50"
      color="primary"
      class="text-center"
    ></v-progress-circular>

    <div v-show="!loading" id="payzen-form"></div>
    <v-alert v-show="isBackoffice && errors.length>0" color="error" icon="warning" outlined>
      <div v-for="(error,errorIndex) in errors" :key="errorIndex">{{error}}</div>
    </v-alert>
  </div>
</template>

<script>
import KRGlue from "@lyracom/embedded-form-glue";
import { get, post } from "@/utils/api";
import { EventBus } from "@/components/commons/event-bus";

export default {
  name: "PayzenPaymentForm",
  mixins: [],
  props: {
    payment: {
      type: Object,
      required: true,
    },
    isBackoffice: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      loading: true,
      defaultTokenRequest: {
        bookingId: null,
        amount: null,
        totalCardSize: 1,
        cardIndex: 0,
        capture: true,
      },
      errors: [],
      KR: null,
    };
  },
  created() {
    this.init();
  },
  destroyed() {
    this.KR && this.KR.removeForms();
  },
  watch: {},
  computed: {},
  methods: {
    async init() {
      const [publicKey, token] = await Promise.all([
        this.getKey(),
        this.getToken(),
      ]);
      this.buildForm(publicKey, token);
    },
    async getKey() {
      const url = `/api/payment/publickey/${this.payment.bookingId}`;
      return get(url)
        .badRequest((error) => {
          EventBus.$emit("toaster-error", error);
        })
        .res((resp) => resp.text())
        .then((key) => key);
    },
    async getToken() {
      const url = "/api/payment/create";
      const body = {
        ...this.defaultTokenRequest,
        ...{ bookingId: this.payment.bookingId },
        ...{ amount: this.payment.amount },
        ...{ capture: !this.payment.footprint },
        backoffice: true,
      };

      return post(url, body)
        .badRequest((error) => {
          EventBus.$emit("toaster-msg", error);
        })
        .res((resp) => resp.text())
        .then((token) => token);
    },
    buildForm(publicKey, token) {
      KRGlue.loadLibrary("https://api.payzen.eu", publicKey)
        .then(({ KR }) =>
          KR.setFormConfig({
            formToken: token,
            "kr-language": "fr-FR",
          })
        )
        .then(({ KR }) => KR.addForm("#payzen-form"))
        .then(({ KR, result }) => KR.showForm(result.formId))
        .then(({ KR }) => {
          KR.onFormReady(() => {
            this.loading = false;
          });
          KR.onError(this.formError);
          KR.onSubmit(this.formSubmit);
          KR.button.onClick(this.buttonClick);

          this.KR = KR;
        });
    },
    buttonClick() {
      this.errors = [];
      return true;
    },
    formSubmit(event) {
      if (event.isValid()) this.$emit("paid", event);
      else this.errors = ["Erreur inconnue", JSON.stringify(event)];
    },
    formError(error) {
      this.errors = [].concat(
        error.children.map((e) => e.errorMessage),
        `${error.errorMessage || ""} ${error.detailedErrorMessage || ""}`
      );
    },
  },
};
</script>

<style scoped>
.v-progress-circular {
  display: block;
  margin: auto;
}

>>> .kr-embedded {
  margin: auto;
}

.v-alert {
  width: 266px;
}
</style>
