<template>
  <v-menu
    v-if="!pickerOnly"
    v-model="menu"
    v-bind="menuOpts"
    :disabled="options.disabled"
  >
    <template #activator="{ on }">
      <v-text-field
        v-on="on"
        slot="activator"
        v-model="inputValue"
        v-bind="inputOpts"
        hide-details
        :autocomplete="'nope'"
        :rules="rules"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="pickerValue"
      v-bind="options"
      @change="menu = false"
    ></v-date-picker>
  </v-menu>
  <v-date-picker v-else v-model="pickerValue" v-bind="options"></v-date-picker>
</template>
<script>
export default {
  name: "SmartPicker",
  props: {
    value: { type: String },
    format: { type: String, default: "dd/MM/yyyy" },
    "picker-only": { type: Boolean, default: false },
    startDate: { type: String },
    stopDate: { type: String },
    nextPicker: { type: Object, required: false }, //should be a $refs.anyProperty,
    rules: { type: Array, required: false },
    //defaultDate: { type: String},
  },
  data: () => ({
    pickerFormat: "yyyy-MM-dd",
    menu: false,
    options: {
      "first-day-of-week": 1,
      locale: "fr-fr",
      "no-title": true,
      "event-color": "blue lighten-2",
      "picker-date": null,
    },
    menuOpts: {
      "close-on-content-click": false,
      transition: "scale-transition",
      "offset-y": true,
    },
    inputOpts: {},
  }),
  watch: {
    $attrs: {
      deep: true,
      immediate: true,
      handler(newVal) {
        const {
          min,
          max,
          events,
          menuOpts,
          inputOpts,
          startDate,
          stopDate,
          label,
          ...attrs
        } = newVal;

        this.options = {
          ...this.options,
          ...attrs,
          ...{
            min: min
              ? this._parseAndFormatDate(min, this.format, this.pickerFormat)
              : null,
            max: max
              ? this._parseAndFormatDate(max, this.format, this.pickerFormat)
              : null,
            events: events
              ? events.map((ev) =>
                  this._parseAndFormatDate(ev, this.format, this.pickerFormat)
                )
              : [],
            "picker-date": this.defaultDate
              ? this._parseAndFormatDate(
                  this.defaultDate,
                  this.format,
                  this.pickerFormat
                )
              : null,
          },
        };
        this.menuOpts = { ...this.menuOpts, ...(menuOpts || {}) };
        this.inputOpts = {
          ...this.inputOpts,
          ...(inputOpts || {}),
          ...{
            mask: this.format.replace(/[a-zA-Z]/g, "#"),
            disabled: this.options.disabled || false,
            label: label ? label : inputOpts ? inputOpts.label : "Date",
            "return-masked-value": true,
          },
        };

        this.buildLinkedDateEvents();
      },
    },
    startDate: {
      immediate: true,
      handler(newVal) {
        this.buildLinkedDateEvents();
        //this.buildRenderedNextMonth()
      },
    },
    stopDate: {
      immediate: true,
      handler(newVal) {
        this.buildLinkedDateEvents();
      },
    },
  },
  computed: {
    pickerValue: {
      get() {
        if (!this.value) return null;
        return this._parseAndFormatDate(
          this.value,
          this.format,
          this.pickerFormat
        );
      },
      set(newVal) {
        this.$emit(
          "input",
          this._parseAndFormatDate(newVal, this.pickerFormat, this.format)
        );
        this.buildLinkedDateEvents();
        this.openNext();
      },
    },
    inputValue: {
      get() {
        return this.value;
      },
      set(newVal) {
        if (newVal && newVal.length === this.format.length) {
          this.$emit("input", newVal);
        } else if (!newVal || newVal.length == 0) {
          this.$emit("input", null);
        }
      },
    },
  },
  methods: {
    buildRenderedNextMonth() {
      this.options = {
        ...this.options,
        ...{
          "picker-date": this.options.min
            ? this._parseAndFormatDate(this.options.min, this.format, "yy/MM")
            : null,
        },
      };
    },
    buildLinkedDateEvents() {
      if (this.value && this.startDate) {
        this.options.showCurrent = this._parseAndFormatDate(
          this.startDate,
          this.format,
          this.pickerFormat
        );
        this.buildEvents(this.startDate, this.value);
      } else if (this.value && this.stopDate) {
        this.options.showCurrent = this._parseAndFormatDate(
          this.stopDate,
          this.format,
          this.pickerFormat
        );
        this.buildEvents(this.value, this.stopDate);
      }
    },
    buildEvents(startDate, stopDate) {
      const start = this._parseDate(startDate, this.format);
      const stop = this._parseDate(stopDate, this.format);

      const duration = this._duration(start, stop, "days");

      var events = [];
      for (var i = 0; i <= duration; i++) {
        events.push(
          this._formatDate(this._addDays(start, i), this.pickerFormat)
        );
      }
      this.options.events = events;
    },
    openNext() {
      if (this.nextPicker) {
        this.nextPicker.menu = true;
      }
    },
  },
};
</script>
