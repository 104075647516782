<template>
  <v-card ref="vCardRef" :elevation="1"
          class="pa-4"
          style="height:100%; width:100%; overflow:hidden">
    <div class="font-weight-bold text-lg mb-10">
      {{conf.title }}
    </div>
    <apexchart style="width:100%"
               :series="series"
               :chart="chartOptions.chart"
               :xaxis="chartOptions.xaxis"
               :fill="chartOptions.fill"
               :dataLabels="chartOptions.dataLabels"
               :title="chartOptions.title"
               :yaxis="chartOptions.yaxis"
               ref="apexcharts"
               type="bubble"
               :height="series.length > 0 ? chartHeight : 0"
               :options="chartOptions"
    ></apexchart>
  </v-card>
</template>
<script>
import {post, get} from "@/utils/api";
import {EventBus} from "@/components/commons/event-bus";
import {ref} from "vue";

export default {
  name: "BubbleCADayDepartureChart",
  component: {},
  props: {
    form: {type: Object, required: true},
    conf: {type: Object, required: false, default: null}
  },
  data: function () {
    return {
      vCardRef: ref(null),
      url: `/api/widget/dashboard/price/day/departure`,
      chartOptions: {
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: false
        },
        fill: {
          opacity: 0.8,
          gradient: {
            enabled: true
          }
        },

        xaxis: {
          title: {
            text: "Nombre de jours avant départ"
          },
          tickAmount: 12,
          type: 'category',
          min: -10,
          max: null,
        },
        yaxis: {
          title: {
            text: "Chiffre d'affaire"
          },
        }
      },
      series: []
    }
  },
  created() {
    this.init()
    EventBus.$on('searchDashboard', () => this.init());
    EventBus.$on('editMode', () => this.updateSize())
  },
  mounted(){
    this.vCardRef = this.$refs.vCardRef.$el.clientHeight
    if(this.conf.chartOptions == null){
          this.conf.chartOptions = this.chartOptions
        delete this.conf.chartOptions.xaxis.min
        delete this.conf.chartOptions.xaxis.max
      } else {
        this.chartOptions = {...this.chartOptions, ...this.conf.chartOptions}
      }
  },
  computed: {
    chartHeight() {
      return this.vCardRef != null ? `${this.$refs.vCardRef.$el.clientHeight - 100}px` : 'auto';
    },
  },
  methods: {
    init() {
      this.chartOptions.xaxis.max = this.conf.daysMax
      const searchForm = this._formatPayloadWidgetDashboard(this.conf,this.form)
      this.series = []
      post(this.url, searchForm, {"Content-Type": "application/json"})
          .json(r => {
            r.forEach(b => {
              this.series.push({
                name: b.name,
                data: this.generateData(parseInt(b.name), b.count, b.sales)
              })
            })
          })
    }
    ,

    generateData(xVal, size, yVal) {
      var series = [];
      series.push([xVal, yVal, size]);
      //if(size > 20) {}
      return series;
    },
    updateSize(){
      this.vCardRef = this.$refs.vCardRef != null ? this.$refs.vCardRef.$el.clientHeight : 0
    },
  }
}
</script>