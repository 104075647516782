<template>
  <layout :drawer="true" :drawerShow="_isDesktop()" :drawerWidth="300">
    <template slot="nav">
      <v-tabs>
        <v-tab :to="{name:'avis-moderation'}">Modération</v-tab>
        <v-tab :to="{name:'avis-hotels'}">Hotels</v-tab>
        <v-tab :to="{name:'avis-vols'}">Vols</v-tab>
      </v-tabs>
    </template>
    <template slot="drawer">
      <v-form v-on:submit.prevent="loadAverage">
        <v-row>
          <v-col cols="12" class="pb-0">
            <v-subheader light class="pa-0 mb-0 mx-0 form-subheader"
            >Date de séjour
            </v-subheader
            >
            <v-divider></v-divider>
          </v-col>
          <v-col class="pr-1" cols="6">
            <smart-picker
                v-model="form.start_date"
                :stopDate="form.start_date"
                :inputOpts="{
                label: $t('begin'),
                placeholder: ' ',
              }"
            ></smart-picker>
          </v-col>
          <v-col cols="6" class="pr-1">
            <smart-picker
                v-model="form.stop_date"
                :startDate="form.stop_date"
                :inputOpts="{
                label: $t('end'),
                placeholder: ' ',
              }"
            ></smart-picker>
          </v-col>
          <v-col cols="12" class="pb-0">
            <v-divider></v-divider>
          </v-col>
          <v-col class="pr-1 pb-0" cols="12">
            <v-select
                v-model="form.site_id"
                :items="data.sites"
                clearable
                item-text="name"
                item-value="id"
                label="Site"
                class="pa-1"
            ></v-select>
          </v-col>
          <v-col cols="12" class="pr-1 pt-0 pb-0">
            <v-select
                v-model="form.company"
                :rules="rules.nonEmpty()"
                :items="data.company"
                item-text="name"
                :error="errorSelectCompany"
                item-value="id"
                label="Compagnie"
                class="pa-1"
            ></v-select>
          </v-col>
          <v-col cols="12" class="pr-1 pt-0 pb-0">
            <v-select
                v-model="form.plane_model"
                :items="data.plane_model"
                clearable
                item-text="name"
                item-value="id"
                label="Modèle"
                class="pa-1"
            ></v-select>
          </v-col>
          <v-col cols="12" class="pr-1 pt-0">
            <v-select
                v-model="form.destination_id"
                :items="data.destinations"
                clearable
                item-text="name"
                item-value="id"
                label="Destinations"
                class="pa-1"
            ></v-select>
          </v-col>
        </v-row>
        <smart-btn
            class="mt-3"
            type="submit"
            :loading="loading"
            block
            primary
        >Rechercher
        </smart-btn
        >
        <smart-btn block tertiary @click.native="clearSearchform()"
        >Réinitialiser la recherche
        </smart-btn
        >
      </v-form>
    </template>
    <template slot="content">
      <v-card v-if="result" class="pa-4 ma-4 blue-grey lighten-1 white--text">
        <v-row no-gutters>
          <v-col cols="6" class="d-flex flex-column justify-center align-center">
            <AverageItem :average="result.confort" :count="result.countConfort" title="Confort" />
          </v-col>
          <v-col cols="6" class="d-flex flex-column justify-center align-center">
            <AverageItem :average="result.service" :count="result.countService" title="Service" />
          </v-col>
        </v-row>
      </v-card>
    </template>
  </layout>
</template>
<script>

import Layout from "@/views/Layout";
import {get, post} from "@/utils/api";
import FormMixin from "@/components/mixins/FormMixin";
import {format, setDayOfYear} from "date-fns";
import ReviewItem from "@/components/commons/ReviewItem.vue";
import AverageItem from "@/components/commons/AverageItem.vue";

export default {
  name:"AverageFlight",
  components:{
    AverageItem,
    ReviewItem,
    Layout
  },
  mixins: [FormMixin],
  data(){
    return{
      data:{
        sites:[],
        company:[],
        plane_model: [],
        destinations:[]
      },
      form:{
        site_id:null,
        company:null,
        plane_model:null,
        start_date:format(setDayOfYear(new Date(),1),"dd/MM/yyyy"),
        stop_date:format(new Date(),"dd/MM/yyyy"),
        destination_id:null
      },
      errorSelectCompany: false,
      result: null,
      loading:false
    }
  },
  created() {
    this.init()
  },
  methods:{
    init(){
      this.getSite()
      this.getCompany()
      this.getAircraft()
      this.getDestinations()
    },
    getSite(){
      get("/api/search/sites")
          .json((sites) => {
            this.data.sites = sites
          })
    },
    getCompany(){
      get("/api/search/company")
          .json((company) => {
            this.data.company = company
          })
    },
    getAircraft(){
      get("/api/search/aircraft")
          .json((aircraft) => {
            this.data.plane_model = aircraft.map(x => {
              return {
                name: (this.checkIfNullOrEmpty(x.maker) + " " + this.checkIfNullOrEmpty(x.model) + " " + this.checkIfNullOrEmpty(x.variant)).trim()
              }
            })
          })
    },
    getDestinations(){
      get("/api/search/destinations")
          .json((destinations) => {
            this.data.destinations = destinations
          })
    },
    async loadAverage(){
      if(this.form.company != null){
        this.loading = true
        this.errorSelectCompany = false
        const form = {
          site_id:this.form.site_id,
          company:this.form.company,
          plane_model:this.form.plane_model == null ? this.form.plane_model : this.form.plane_model.replaceAll(" ",""),
          start_date:this.form.start_date,
          stop_date:this.form.stop_date,
          destination_id:this.form.destination_id,
        }
        await post("/api/average/flight",form)
            .json((result) => {
              this.result = {
                ...result,
                confort: result.confort == null ? 0 : result.confort,
                service: result.service == null ? 0 : result.service
              }
            })
        this.loading = false
      } else {
        this.errorSelectCompany = true
      }
    },
    checkIfNullOrEmpty(x){
      return x == null || x == "" ? "" : x
    },
    clearSearchform(){
      this.form = {
        site_id:null,
        company:null,
        plane_model:null,
        start_date:format(setDayOfYear(new Date(),1),"dd/MM/yyyy"),
        stop_date:format(new Date(),"dd/MM/yyyy"),
        destination_id:null
      }
    }
  },
  watch: {
    form: {
      deep: true,
      handler(newVal) {
        this.errorSelectCompany = newVal.company == null
      }
    },
  },
}
</script>
