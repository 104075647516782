<template>
	<v-form v-model="formEditBooking.valid" ref="formEditBooking" v-on:submit.prevent="validEditBooking">
		<template>
			<v-stepper v-model="step">
				<v-stepper-header dense>
					<v-stepper-step :complete="step > 1" step="1" editable>Modification du dossier</v-stepper-step>

					<v-divider></v-divider>

					<v-stepper-step :complete="step > 2" step="2">Vérification des prix</v-stepper-step>

				</v-stepper-header>

				<v-stepper-items>
					<v-stepper-content step="1" class="pa-0 ma-0">
						<v-row dense   class="ma-3">

							<v-col cols="12" class="grey--text mt-3 mb-2 pl-1">
								Dossier
							</v-col>
							<v-col cols="12">
								<v-text-field v-model="formEditBooking.name" label="Nom du dossier" class="pa-2"></v-text-field>
							</v-col>
							<v-col cols="4">
								<v-select
									v-model="formEditBooking.bookingCtx.site"
									:rules="rules.nonEmpty()"
									:items="formData.sites"
									item-text="name"
									item-value="id"
									label="Site"
									class="pa-1"
									return-object
								></v-select>
							</v-col>
							<v-col cols="4">
								<v-select
									v-model="formEditBooking.bookingCtx.priceClassType"
									:rules="rules.nonEmpty()"
									:items="formData.priceclass"
									item-text="name"
									item-value="id"
									label="Classe de prix"
									class="pa-1"
									return-object
								></v-select>
							</v-col>
							<v-col cols="4">
								<v-select
									v-model="formEditBooking.bookingCtx.currency.code"
									:rules="rules.nonEmpty()"
									:items="formData.currencies"
									item-text="name"
									item-value="code"
									label="Vente"
									class="pa-1"
									return-object
								></v-select>
							</v-col>

							<v-col cols="12" class="grey--text mb-2 pl-1">
								Client
							</v-col>
							<v-col cols="4">
								<v-select
									v-model="formEditBooking.bookingCtx.countryCode"
									:items="formData.countries"
									item-text="name"
									item-value="code"
									label="Pays"
									class="pa-1"
								></v-select>
							</v-col>
							<v-col cols="4">
								<v-select
									v-model="formEditBooking.bookingCtx.lang"

									:items="formData.langs"
									item-text="name"
									item-value="id"
									label="Langue"
									class="pa-1"
									return-object
								></v-select>
							</v-col>
							<v-col cols="4">
								<v-select
									v-model="formEditBooking.bookingCtx.declarations"
									:items="formData.declarations"
									label="PACS / Voyage de noces"
									class="pa-1"
									clearable multiple
									@change="declarationsChange"
								></v-select>
							</v-col>
							<v-col cols="12" class="grey--text mt-3 mb-2 pl-1">
								 <v-checkbox v-model="editQuotationsInfo" label="Editer infos Prix & remarque devis"></v-checkbox>
							</v-col>
							<v-col cols="12">
								<v-textarea v-model="formEditBooking.priceInclude" label="Ce prix comprends" class="pa-2" auto-grow rows="2" :disabled="!editQuotationsInfo"></v-textarea>
							</v-col>
							<v-col cols="12">
								<v-textarea v-model="formEditBooking.priceExclude" label="Ce prix ne comprends pas" class="pa-2" auto-grow rows="2" :disabled="!editQuotationsInfo"></v-textarea>
							</v-col>
							<v-col cols="12">
								<v-textarea v-model="formEditBooking.quotationRemark" label="Remarque" class="pa-2" auto-grow rows="2" :disabled="!editQuotationsInfo"></v-textarea>
							</v-col>

						</v-row>
						<v-card-actions class="actions-btn">
							<v-spacer></v-spacer>
							<smart-btn primary type="submit" :loading="editButtonLoading"
												 @click.native="formEditBooking.calc = false">{{$t('save')}}
							</smart-btn>
						</v-card-actions>
					</v-stepper-content>

					<v-stepper-content step="2" class="pa-0 ma-0">
						<v-col v-if="updatedPrestations.length > 0">
							<v-col cols="12" :min-height="150">
								<v-card-text mb-10 class="text-left" xs12>
									Le prix des prestations suivantes a changé :
									<ul>
										<li v-for="prestation in updatedPrestations" :key="prestation.id">
											<p v-if="prestation.newPrice !== null">
												{{prestation.type}} {{prestation.name}} {{prestation.newPrice}}€ ({{prestation.newPrice -
												prestation.oldPrice}}€)
											</p>
											<p v-else>
												{{prestation.type}} {{prestation.name}} : <span style="color:red">Nouvelle tarification indisponible</span>
											</p>
										</li>

									</ul>
									Le prix des prestations suivantes reste inchangé :
									<ul>
										<li v-for="unchangedPrestation in unchangedPrestations" :key="unchangedPrestation.id">
											<p>
												{{unchangedPrestation.kind}} {{unchangedPrestation.hotelName}}
											</p>
										</li>
									</ul>
								</v-card-text>
							</v-col>

							<v-card-actions class="actions-btn">
								<v-spacer></v-spacer>
								<smart-btn primary @click.native="updatePrices()" :loading="majloading">Mettre à jour
								</smart-btn>
							</v-card-actions>
						</v-col>

						<v-col class="wrap" v-else>
							<v-card-text xs12 class="mb-5" align-center justify-center>
								<v-col cols="12" class="text-center mb-10">
									Aucun de changement de prix
								</v-col>
							</v-card-text>
							<v-card-actions class="actions-btn">
								<v-spacer></v-spacer>
								<smart-btn primary @click.native="close()">Quitter
								</smart-btn>
							</v-card-actions>
						</v-col>
					</v-stepper-content>
				</v-stepper-items>
			</v-stepper>
		</template>
	</v-form>
</template>

<script>
	import FormMixin from "@/components/mixins/FormMixin";
	import {EventBus} from "@/components/commons/event-bus";
	import {get, post} from "@/utils/api"

	export default {
		name: "BookingContextEdit",
		props: {
			booking: {
				type: Object,
				required: true
			}
		},
		mixins: [FormMixin],
		components: {},
		data() {
			return {
				step: 1,
				updatedPrestations: [],
				editButtonLoading: false,
				majloading: false,
				editQuotationsInfo:false,
				formEditBooking: {
					calc: false,
					valid: true,
					name: this.booking.name,
					priceInclude: null,
					priceExclude: null,
					quotationRemark: null,
					bookingCtx: {
						...this.booking.context,
						priceClassType: {
							id: this.booking.context.priceClassType.id,
							name: this.booking.context.priceClassType.name
						},
						site: {
							name: this.booking.context.site.name,
							id: this.booking.context.site.id
						},
						countryCode: this.booking.context.countryCode,
						currency: {
							code: this.booking.context.currency.code,
							id: this.booking.context.currency.id
						},
						declarations: this.booking.context.declarations !== [] ? this.booking.context.declarations : [],
						guests: [],
					},
				},
				formData: {
					sites: [],
					priceclass: [],
					langs: [
						{id: 1, locale: "fr_FR", name: 'Français'},
						//{id: 2, locale: "en_GB", name: 'Anglais'},
						{id: 4, locale: "it_IT", name: 'Italien'},
					],
					countries: [
						{code: 'FR', name: 'France'},
						{code: 'RE', name: 'Réunion'},
						{code: 'IT', name: 'Italie'}
					],
					currencies: [
						{id: 1, code: "EUR", name: 'Euros'}
					],
					declarations: ['Voyage de Noces', 'PACS']
				},
				unchangedPrestations: []
			}
		},
		created() {
			this.init()
		},
		watch: {},
		computed: {},
		methods: {
			init() {
				get('/api/search/priceclasstypes')
					.json((priceclass) => {
						this.formData.priceclass = priceclass
					})
				get("/api/search/sites")
					.json((sites) => {
						this.formData.sites = sites
					})

				this.buildPriceInclude()
				this.buildPriceExclude()
				this.buildQuotationRemark()
			},
			buildPriceExclude(){
				this.getAdditionalInfo("priceExclude")
					.then((value) => {
						const exists = value !== ''
						if (!exists) {
							value = "Les dépenses personnelles, les pourboires, les taxes de séjour locale ainsi que les formalités d'entré du pays visité."
						}
						this.editQuotationsInfo = exists
						this.formEditBooking.priceExclude = value
					})
			},
			buildPriceInclude(){

				this.getAdditionalInfo("priceInclude")
					.then((value) => {
						const exists = value !== ''
						if (!exists) {

							const pattern = this._datePattern.localDateTime
							const nbNight = this._nbNights(this._parseDate(this.booking.startDate, pattern), this._parseDate(this.booking.stopDate, pattern))
							const duration = this.$tc('nights', nbNight, {size: nbNight })

							const transfers = this.booking.prestations.filter(p => p.kind == 'TransferPrestation').length > 0 ? ", les transfers " : ""
							const flights = this.booking.prestations.filter(p => p.kind == 'FlightPrestation').length > 0 ? ", le transport aérien, les taxes d'aéroport " : ""

							value = `L'hébergement pour ${duration}${transfers}${flights} et l’assistance sur place de notre correspondant.`
						}

						this.editQuotationsInfo = exists
						this.formEditBooking.priceInclude = value
					})
			},
			buildQuotationRemark(){
				this.getAdditionalInfo("quotationRemark")
					.then((value) => {
						const exists = value !== ''
						if (!exists) {

							value = "Formalités : Pensez à bien vérifier que vous êtes en possession des documents exigés pour la destination"
						}
						this.editQuotationsInfo = exists
						this.formEditBooking.quotationRemark = value
					})
			},
			getAdditionalInfo(item) {
				const url = `/api/booking/${this.booking.id}/getadditionalinfos?item=${item}`
				return get(url)
				.badRequest(err => {
					EventBus.$emit('toaster-msg', err)
				})
				.json((r) => {
					return r
				})
			},
			declarationsChange() {
				if (this.formEditBooking.bookingCtx.declarations.length > 1) {
					this.formEditBooking.bookingCtx.declarations = this.formEditBooking.bookingCtx.declarations.slice(-1)
				}
			},
			validEditBooking() {

				if (this.$refs.formEditBooking.validate()) {
					this.editButtonLoading = true
					this.loadingDetail = true

					const form = !this.editQuotationsInfo
						? {...this.formEditBooking, ...{priceInclude:null}, ...{priceExclude:null}, ...{quotationRemark:null}}
						: {...this.formEditBooking}

					const url = `/api/booking/editbookingcontext/${this.booking.id}`
					post(url, form)
						.badRequest(err => {
							EventBus.$emit('toaster-msg', err)
						})
						.json((r) => {
							EventBus.$emit('toaster-msg', "Dossier mis à jour")
							EventBus.$emit('reloadbooking', {})
							this.updatedPrestations = r.badPrestations
						})
						.then(() => {
							this.editButtonLoading = false
							this.loadingDetail = false
							setTimeout(() => {
								this.step = 2
							}, 1000)
							this.createUnchangedPrestionsList()
						})
				}
			},
			createUnchangedPrestionsList() {
				this.unchangedPrestations = this.booking.prestations
				this.unchangedPrestations.map((unPrestation) => {
					unPrestation.id
					this.updatedPrestations.map((upPrestation) => {
						if (unPrestation.id == upPrestation.id) {
							let index = this.unchangedPrestations.findIndex((elt) => {
								if (elt.id === upPrestation.id) {
									return elt
								}
							})
							this.unchangedPrestations.splice(index, 1)
						}
					})
				})
			},
			updatePrices() {
				this.formEditBooking.calc = true;
				this.majloading = true;
				get(`/api/booking/${this.booking.id}/refresh-price`)
					.res(() => {
						EventBus.$emit('reloadbooking', {})
						this.majloading = false
						this.close()
					})
				//location.reload()
			},
			close() {
				this.$emit('exit', true)
			}
		}
	}

</script>

<style scoped>
	.v-stepper__header, .v-stepper__step {
		height: 65px;
	}

	.v-card__text {
		min-height: 150px;
	}
</style>
