<template>
  <layout :drawerShow="_isDesktop()" :drawerWidth="350">
    <template slot="drawer">
      <v-form ref="searchform" v-if="searchform" @submit.prevent="search">
        <v-row no-gutters class="pt-2 pr-1">
          <v-col cols="12">
            <v-subheader light class="pa-0 mt-5 mb-0 mx-0 form-subheader"
              >Dates de voyage</v-subheader
            >
            <v-divider></v-divider>
          </v-col>

          <v-col cols="6" class="pr-1">
            <smart-picker
              v-model="searchform.bookingStartDate"
              :stopDate="searchform.bookingStopDate"
              :inputOpts="{
                label: $t('begin'),
                clearable: true,
                placeholder: ' ',
              }"
            ></smart-picker>
          </v-col>

          <v-col cols="6" class="pr-1">
            <smart-picker
              v-model="searchform.bookingStopDate"
              :startDate="searchform.bookingStartDate"
              :inputOpts="{
                label: $t('end'),
                clearable: true,
                placeholder: ' ',
              }"
            ></smart-picker>
          </v-col>

          <v-col cols="12" class="pr-1 mt-6">
            <smart-multi-select
              v-model="searchform.agencies"
              item-text="name"
              item-value="id"
              label="Agences"
              :all-is-same-as-empty="true"
              :default-select-all="true"
              :items="agencies"
            ></smart-multi-select>
          </v-col>
        </v-row>

        <smart-btn
          type="submit"
          block
          :loading="loading"
          primary
          :disabled="searchform.agencies.length <= 0"
          >Rechercher</smart-btn
        >
        <smart-btn
          block
          tertiary
          @click.native="
            clearSearchform();
            init();
          "
          >Réinitialiser la recherche</smart-btn
        >
      </v-form>
    </template>

    <template slot="content">
      <v-container fluid>
        <v-row>
          <v-col cols="12" class="mb-3">
            <v-card class="pa-0 ma-0 blue-grey lighten-1">
              <v-row justify="space-around" align="center">
                <widget-agencies-booking-resume
                  class="center"
                  :bus="widgetBus"
                  url="/api/widget/agency-stats/booking-resume"
                ></widget-agencies-booking-resume>
                <widget-agencies-quotation-resume
                  :bus="widgetBus"
                  url="/api/widget/agency-stats/quotation-resume"
                ></widget-agencies-quotation-resume>
                <widget-agencies-resume
                  :bus="widgetBus"
                  url="/api/widget/agency-stats/ca-resume"
                ></widget-agencies-resume>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <v-col>
          <widget-agencies-ca-by-desti
            @loading="setLoading"
            :bus="widgetBus"
            url="/api/widget/agency-stats/ca-destination"
          ></widget-agencies-ca-by-desti>
        </v-col>
      </v-container>
    </template>
  </layout>
</template>
<script>
import Vue from "vue";
import Layout from "@/views/Layout";
import { get, post } from "@/utils/api";
import SmartMultiSelect from "@/components/commons/SmartMultiSelect";
import SmartSelect from "@/components/commons/SmartSelect";
import FormMixin from "@/components/mixins/FormMixin";
import WidgetAgenciesResume from "../../components/commons/widgets/WidgetAgenciesResume.vue";
import WidgetAgenciesCaByDesti from "../../components/commons/widgets/WidgetAgenciesCaByDesti.vue";
import WidgetAgenciesBookingResume from "../../components/commons/widgets/WidgetAgenciesBookingResume.vue";
import WidgetAgenciesQuotationResume from "../../components/commons/widgets/WidgetAgenciesQuotationResume.vue";

export default {
  name: "AgenciesStats",
  components: {
    SmartSelect,
    SmartMultiSelect,
    Layout,
    WidgetAgenciesCaByDesti,
    WidgetAgenciesResume,
    WidgetAgenciesBookingResume,
    WidgetAgenciesQuotationResume,
  },
  mixins: [FormMixin],
  data() {
    return {
      widgetBus: new Vue(),
      searchform: {},
      defaultSearchform: {
        bookingStartDate: null,
        bookingStopDate: null,
        agencies: [],
      },
      agencies: [],
      loading: false,
    };
  },
  created() {
    this.clearSearchform();
    this.loadAgencies();
  },
  methods: {
    search() {
      if (!this.loading) {
        let form = {
          ...this.searchform,
          bookingStartDate: this.searchform.bookingStartDate
            ? this.searchform.bookingStartDate + " 00:00:00"
            : null,
          bookingStopDate: this.searchform.bookingStopDate
            ? this.searchform.bookingStopDate + " 23:59:59"
            : null,
        };
        this.loadWidgets(form);
      }
    },
    loadWidgets(form) {
      this.widgetBus.$emit("search", form);
    },
    loadAgencies() {
      const url = "/api/search/agencies";

      get(url).json((data) => {
        this.agencies = data;
      });
    },

    clearSearchform() {
      this.searchform = {
        ...this.defaultSearchform,
        bookingStartDate: this._nowFormatted(
          this.datePatternConfig.serverLocalDate
        ),
        bookingStopDate: this._formatDate(
          this._getLastMonthDay(this._now()),
          this.datePatternConfig.serverLocalDate
        ),
      };
    },
    setLoading(loading) {
      this.loading = loading;
    },
  },
};
</script>
<style></style>
