<template>
  <div>
    {{ payment.details.card.brand }} {{ payment.details.card.cardType }} • {{ cardNumber }} • exp
    {{ payment.details.card.expireMonth }}/{{ payment.details.card.expireYear }}
    <v-tooltip top>
      <template #activator="{ on }">
        <span v-on="on" v-if="is3dSecure">
          <v-icon small style="margin-left:1px;">mdi-rotate-3d</v-icon>
        </span>
      </template>
      3D Secure
    </v-tooltip>

    <v-menu bottom>
      <template #activator="{ on }">
        <v-btn v-on="on" icon small>
          <v-icon small color="grey">more_vert</v-icon>
        </v-btn>
      </template>
      <v-list dense>
        <v-list-item @click="refresh">
          <v-list-item-title>
            <v-icon small>mdi-refresh</v-icon>Rafraichir la transaction
          </v-list-item-title>
        </v-list-item>
        <v-list-item @click="validate" :disabled="canValidStatus.indexOf(payment.status) < 0">
          <v-list-item-title>
            <v-icon small>mdi-bank-transfer-in</v-icon>Valider la transaction
          </v-list-item-title>
        </v-list-item>
        <v-list-item
          @click="handleCancel(payment)"
          :disabled="canCancelStatus.indexOf(payment.status) < 0"
        >
          <v-list-item-title>
            <v-icon small>mdi-cancel</v-icon>
            {{ payment.details.transactionStatus != 'CAPTURED' && payment.status !== 'cancelled' ? 'Annuler' : 'Remboursement de' }}
            la transaction
          </v-list-item-title>
        </v-list-item>
        <v-list-item
          @click="openDuplicate()"
          :disabled="duplicateCtx.status.indexOf(payment.details.transactionStatus) < 0"
        >
          <v-list-item-title>
            <v-icon small>mdi-content-duplicate</v-icon>Dupliquer la transaction
          </v-list-item-title>
        </v-list-item>
        <v-list-item
          @click="openModify()"
          :disabled="modifyCtx.status.indexOf(payment.details.transactionStatus) < 0"
        >
          <v-list-item-title>
            <v-icon small>mdi-cloud-upload-outline</v-icon>Modifier la transaction
          </v-list-item-title>
        </v-list-item>

        <v-divider />
        <v-list-item @click="dialog = true">
          <v-list-item-title>Détails transactions</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <smart-dialog v-model="dialog" title="Détail du paiement" scrollable max-width="900">
      <v-list dense>
        <template v-for="(value,key,index) in payment.details.params">
          <v-divider v-if="index > 0" :key="'divider' + index" />
          <v-list-item :key="index">
            <v-list-item-content class="grey--text">{{ key }}:</v-list-item-content>
            <v-list-item-content class="align-end">{{ value }}</v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
      <template slot="actions">
        <smart-btn tertiary @click.native="dialog = false">Fermer</smart-btn>
      </template>
    </smart-dialog>

    <smart-dialog v-model="refundCtx.dialog" title="Remboursement" scrollable width="300px">
      <v-row class="pa-2">
        <v-col cols="12">Remboursement de la transaction</v-col>
        <v-col cols="12">
          <smart-number v-model="refundCtx.amount" />
        </v-col>
      </v-row>
      <template slot="actions">
        <smart-btn tertiary @click.native="refund">VALIDER</smart-btn>
      </template>
    </smart-dialog>

    <smart-dialog v-model="duplicateCtx.dialog" title="Dupliquer une transaction">
      <v-card-text>
        <v-container fluid grid-list-lg>
          <div class="grey--text mb-3 text-center">
            Seul les transactions avec les status suivants peuvent être dupliquées:
            <div>
              <v-chip
                label
                outlined
                disabled
                v-for="(s,sIndex) in duplicateCtx.status"
                :key="sIndex"
              >{{ s }}</v-chip>
            </div>
          </div>

          <v-row>
            <v-col cols="12">
              <v-select v-model="payment" :items="[payment]" label="Transaction" readonly>
                <template slot="item" slot-scope="data">
                  <span class="grey--text mr-3">{{ data.item.creationDate }}</span>
                  {{ data.item.details.card.brand }} {{ data.item.details.card.cardType }} • {{ data.item.details.card.cardNumber }}
                  <span
                    class="blue--text ml-3 font-weight-bold"
                  >{{ data.item.amount | currency(data.item.currency) }}</span>
                  <span class="ml-3 caption">({{ data.item.details.transactionStatus }})</span>
                </template>
                <template slot="selection" slot-scope="data">
                  <span class="grey--text mr-3"></span>
                  {{ data.item.details.card.brand }} {{ data.item.details.card.cardType }} • {{ data.item.details.card.cardNumber }}
                  <span
                    class="blue--text ml-3 font-weight-bold"
                  >{{ data.item.amount | currency(data.item.currency) }}</span>
                  <span class="ml-3 caption">({{ data.item.details.transactionStatus }})</span>
                </template>
              </v-select>
            </v-col>
            <v-col cols="2">
              <v-text-field v-model="duplicateCtx.data.amount" label="Montant" />
            </v-col>
            <v-col cols="2">
              <v-text-field v-model="duplicateCtx.data.currency" label="Devise" readonly />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <template slot="actions">
        <smart-btn tertiary @click.native="duplicateCtx.dialog = false">Fermer</smart-btn>
        <smart-btn secondary @click.native="refresh()">Refresh</smart-btn>
        <smart-btn
          primary
          @click.native="duplicate()"
          :disabled="duplicateDisabled"
          :loading="duplicateCtx.loading"
        >Dupliquer</smart-btn>
      </template>
    </smart-dialog>

    <smart-dialog v-model="modifyCtx.dialog" title="Modifier une transaction">
      <v-card-text>
        <v-container fluid grid-list-lg>
          <div class="grey--text mb-3 text-center">
            Seule les transactions avec le status suivants peuvent être modifiées:
            <div>
              <v-chip
                label
                outlined
                disabled
                v-for="(s,sIndex) in modifyCtx.status"
                :key="sIndex"
              >{{ s }}</v-chip>
            </div>
            Le nouveau montant doit obligatoirement être inférieur au montant actuel de la transaction:
            {{ payment.amount }} {{ payment.currency }}.
          </div>

          <v-row>
            <v-col cols="12">
              <v-select v-model="payment" :items="[payment]" label="Transaction" readonly>
                <template slot="item" slot-scope="data">
                  <span class="grey--text mr-3">{{ data.item.creationDate }}</span>
                  {{ data.item.details.card.brand }} {{ data.item.details.card.cardType }} • {{ data.item.details.card.cardNumber }}
                  <span
                    class="blue--text ml-3 font-weight-bold"
                  >{{ data.item.amount | currency(data.item.currency) }}</span>
                  <span class="ml-3 caption">({{ data.item.details.transactionStatus }})</span>
                </template>
                <template slot="selection" slot-scope="data">
                  <span class="grey--text mr-3"></span>
                  {{ data.item.details.card.brand }} {{ data.item.details.card.cardType }} • {{ data.item.details.card.cardNumber }}
                  <span
                    class="blue--text ml-3 font-weight-bold"
                  >{{ data.item.amount | currency(data.item.currency) }}</span>
                  <span class="ml-3 caption">({{ data.item.details.transactionStatus }})</span>
                </template>
              </v-select>
            </v-col>
            <v-col cols="2">
              <v-text-field v-model="modifyCtx.data.amount" label="Montant"></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-text-field v-model="modifyCtx.data.currency" label="Devise" readonly></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <template slot="actions">
        <smart-btn tertiary @click.native="modifyCtx.dialog = false">Fermer</smart-btn>
        <smart-btn secondary @click.native="refresh()">Refresh</smart-btn>
        <smart-btn
          primary
          @click.native="modify()"
          :disabled="modifyDisabled"
          :loading="modifyCtx.loading"
        >Modifier</smart-btn>
      </template>
    </smart-dialog>
  </div>
</template>
<script>
import { get, post } from "@/utils/api";
import { EventBus } from "@/components/commons/event-bus";

export default {
  name: "PaymentCardDetail",
  props: {
    payment: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    dialog: false,
    canValidStatus: ["pending"],
    canCancelStatus: ["pending", "confirmed"],

    refundCtx: {
      dialog: false,
      amount: 0,
    },

    duplicateCtx: {
      loading: false,
      status: ["CAPTURED", "EXPIRED", "CANCELLED", "REFUSED"],
      dialog: false,
      data: {
        amount: 0,
        currency: "EUR",
      },
    },

    modifyCtx: {
      loading: false,
      status: ["AUTHORISED_TO_VALIDATE"],
      dialog: false,
      data: {
        amount: 0,
        currency: "EUR",
      },
      payments: [],
    },
  }),
  computed: {
    cardNumber() {
      return this.payment.details.card.cardNumber.substring(
        this.payment.details.card.cardNumber.length - 4
      );
    },
    duplicateDisabled() {
      return (
        this.payment == null ||
        this.duplicateCtx.status.indexOf(
          this.payment.details.transactionStatus
        ) === -1
      );
    },
    modifyDisabled() {
      return (
        this.payment == null ||
        this.modifyCtx.status.indexOf(
          this.payment.details.transactionStatus
        ) === -1
      );
    },
    is3dSecure() {
      return (
        this.payment.details.params &&
        this.payment.details.params[
        "transactionDetails.cardDetails.threeDSResponse.authenticationResultData.status"
        ] &&
        this.payment.details.params[
        "transactionDetails.cardDetails.threeDSResponse.authenticationResultData.status"
        ] != ""
      );
    },
  },
  methods: {
    refresh() {
      const url = `/api/payment/${this.payment.id}/refresh`;
      get(url, {}).res((r) => {
        EventBus.$emit("reloadbooking", {});
        EventBus.$emit("toaster-msg", "Transaction à jour");
        return r;
      });
    },

    validate() {
      this.$root
        .$confirm(
          "Valider la transaction",
          "Souhaitez vous valider cette transaction ?",
          { width: 350 }
        )
        .then((confirm) => {
          if (!confirm) {
            return Promise.resolve();
          } else {
            const url = `/api/payment/${this.payment.id}/validate`;
            return post(url, {}).res((r) => {
              this.capture().then(() => {
                EventBus.$emit("toaster-msg", "Transaction validée");
              });
            });
          }
        })
        .then(() => (this.isLoading = !this.isLoading));
    },

    handleCancel(payment) {
      payment.details.transactionStatus != "CAPTURED" &&
        payment.status !== "cancelled"
        ? this.cancel()
        : (() => {
          this.refundCtx.amount = payment.amount;
          this.refundCtx.dialog = true;
        })();
    },

    cancel() {
      this.$root
        .$confirm(
          "Annuler la transaction",
          "Souhaitez vous annuler cette transaction ?",
          { width: 350 }
        )
        .then((confirm) => {
          if (!confirm) {
            return Promise.resolve();
          } else {
            const url = `/api/payment/${this.payment.id}/cancel`;
            return post(url, {})
              .badRequest((err) => {
                EventBus.$emit("toaster-msg", err.toString());
              })
              .res((r) => {
                EventBus.$emit("reloadbooking", {});
                EventBus.$emit("toaster-msg", "Transaction Annulée");
                return r;
              });
          }
        })
        .then(() => (this.isLoading = !this.isLoading));
    },

    refund() {
      const url = `/api/payment/${this.payment.id}/cancel`;
      return post(url, {
        amount: this.refundCtx.amount,
      })
        .badRequest((err) => {
          EventBus.$emit("toaster-msg", err.toString());
        })
        .res((r) => {
          EventBus.$emit("reloadbooking", {});
          EventBus.$emit("toaster-msg", "Transaction remboursée");
          this.refundCtx.dialog = false;
          return r;
        });
    },

    capture() {
      const url = `/api/payment/${this.payment.id}/capture`;
      return post(url, {}).res((r) => {
        EventBus.$emit("reloadbooking", {});
        return r;
      });
    },

    openDuplicate() {
      this.duplicateCtx.dialog = true;
    },

    duplicate() {
      this.duplicateCtx.loading = true;
      post(
        `/api/payment/${this.payment.id}/duplicate?amount=${this.duplicateCtx.data.amount}`,
        {}
      )
        .badRequest((e) => {
          EventBus.$emit("toaster-error", e);
          this.duplicateCtx.loading = false;
        })
        .res(() => {
          this.duplicateCtx.loading = false;
          this.duplicateCtx.diaog = false;
          EventBus.$emit("reloadbooking", {});
        });
    },

    openModify() {
      this.modifyCtx.dialog = true;
    },

    modify() {
      this.modifyCtx.loading = true;
      post(
        `/api/payment/${this.payment.id}/modify?amount=${this.modifyCtx.data.amount}`,
        {}
      )
        .badRequest((e) => {
          EventBus.$emit("toaster-error", e);
          this.modifyCtx.loading = false;
        })
        .res(() => {
          this.modifyCtx.loading = false;
          this.modifyCtx.diaog = false;
          EventBus.$emit("reloadbooking", {});
        });
    },
  },
};
</script>

