
import { post } from "@/utils/api";

export default {
    methods: {
        updatePack(pack) {

            const url = `/api/packages/update/${pack.id}`

           return post(url, pack)
                .json((data) => {
                    this.$emit("created", {});
                    return data
                })

        },
        isPackageValid(skeletonIslandIds, packageIslandIds) {

            /*
            return packageIslandIds.length > 0 && skeletonIslandIds.reduce((prev, curr) => {
                return (
                    prev &&
                    packageIslandIds.indexOf(curr) > -1
                );
            }, true);
            */

            return packageIslandIds.length > 0;
        }
    }
}