<template>
	<div>
		<v-card-text class="d-flex" style="gap: 10px">
      <v-select
        style="width: 50%"
        :items="select"
        item-value="id"
        item-text="name"
        v-model="selectChose"
      >
      </v-select>
			<v-text-field
        style="width: 50%"
				v-model="pnr"
				@keypress.enter="add"
        :label="selectChose == 'AMADEUS' ? $t('pnr') : 'Référence'"
				:error="error"
        :placeholder="selectChose == 'AIRGATEWAY' ? 'AGW-' : ''"
				:error-messages="error ? 'PNR manquant' : null"
			></v-text-field>
		</v-card-text>
		<v-card-actions class="pt-0">
			<v-btn color="primary " block :loading="loading" type="submit" @click="add">Ajouter</v-btn>
		</v-card-actions>
	</div>
</template>

<script>
import { post } from '@/utils/api'
import { EventBus } from "@/components/commons/event-bus";

export default {
	name: "AddPnr",
	mixins: [],
	props: {
		booking: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			loading: false,
			error: false,
			pnr: "", //null
      select: [
        {
          id : "AMADEUS",
          name: "Amadeus",
        },
        {
          id: "AIRGATEWAY",
          name: "AirGateway"
        }
      ],
      selectChose: "AMADEUS",
      ctx: null
		}
	},
	created() {
	},
	watch: {},
	computed: {},
	methods: {
		add() {
			this.error = false
			if (this.pnr == null) {
				this.error = true
			} else {
				this.loading = true

				const url = `/api/prestation/add/pnr?bookingId=${this.booking.id}`

        if(this.selectChose == "AMADEUS"){
          this.ctx = {
            pnr: this.pnr,
          }
        } else {
          this.ctx = {
            source: "AIRGATEWAY",
            sourceReference: this.pnr,
          }
        }

        this.ctx.env = {
          mode: "PROD",
          siteId: this.booking.context.site.id,
          priceClassTypeId: this.booking.context.priceClassType.id
        }

				post(url, this.ctx)
					.internalError((err) => {
						EventBus.$emit('toaster-error', JSON.parse(err.text).message)
					})
					.badRequest((err) => {
						EventBus.$emit('toaster-error', JSON.parse(err.text).message)
					})
					.res(() => {
						EventBus.$emit('toaster-msg', this.$t("flightadded"))
						EventBus.$emit('reloadbooking', {})
						setTimeout(() => {
							EventBus.$emit('prestation-added', {})
						}, 500)
					}).then(() => {
						this.loading = false
					})
			}
		}
	}
}
</script>

<style scoped>
</style>
