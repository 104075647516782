const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const validateEmail = email => {
  return emailRegex.test(String(email).toLowerCase());
};

export const emailValidator = (v, message = "Un email valide est requis") => {
  return validateEmail(v) || message;
};

export const requireValidator = (v, message = "Ce champs est requis") => {
  return !!v || message;
};