<template>
  <v-container fluid class="px-1 py-0">
    <v-row  >
      <v-col cols="6">
        <booking-guest-repartition
            v-if="fixedGuests"
            v-model="item.guests" :guests="guests"
        ></booking-guest-repartition>
        <guest-repartition
          v-else
          v-model="item.guests"
        ></guest-repartition>
      </v-col>
      <v-col cols="6">
        <v-select
            v-model="item.repartition"
            :items="repartitions"
            item-value="value"
            item-text="label"
            label="Répartition"
            placeholder=" "
        ></v-select>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

  import BookingGuestRepartition from "@/components/commons/BookingGuestRepartition";
  import GuestRepartition from "@/components/commons/GuestRepartition";
  import GuestsMixin from "@/components/mixins/GuestsMixin";

	export default {
		name: "RoomRepartition",
    components: {BookingGuestRepartition, GuestRepartition},
    mixins:[
      GuestsMixin
    ],
    props: {
        value: {
            type: Object,
            required: true,
            default: () => {}
        },
        'fixedGuests': { type: Boolean, default:false},
        'index': { type: Number, default:0},
        'guests': { type: Array, required: true, default: () => []},
        'removable': { type: Boolean, default:false}
    },
		data: () => ({
			item:{},
      repartitions:[
          {value:"SAMEROOM", label:"Même chambre"}
      ]
		}),
    created(){
      this.init();
    },
    watch:{
		  item:{
        deep:true, handler(newVal){
          this.$emit('input', newVal)
        }
		  },
      value(){
        this.init();
      }
    },
    computed:{
      totalGuests(){
          return this.buildTotalGuests([this.item])
      }
    },
    methods:{
      init(){
        this.item = this.value
      }
    }
	}
</script>