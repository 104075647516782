import { render, staticRenderFns } from "./WidgetHotelStat.vue?vue&type=template&id=88553132&scoped=true&"
import script from "./WidgetHotelStat.vue?vue&type=script&lang=js&"
export * from "./WidgetHotelStat.vue?vue&type=script&lang=js&"
import style0 from "./WidgetHotelStat.vue?vue&type=style&index=0&id=88553132&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "88553132",
  null
  
)

export default component.exports