<template>
  <v-row justify="space-around" class="mr-1">
    <v-col cols="3" class="pa-1" align-self="end">
      <v-autocomplete
        ref="autocompletefrom"
        v-model="itenary.from"
        :items="airports"
        :label="$t('departure')"
        :filter="customFilter"
        :clearable="itenary.from != ''"
        hide-no-data
        item-text="name"
        item-value="code"
        hide-details
        dense
      >
        <template slot="item" slot-scope="data">
          <v-list-item-action
            dense
            :class="{
              'child-tile': data.item.parent && data.item.fromHasParent,
            }"
          >
            <v-icon>local_airport</v-icon>
          </v-list-item-action>
          <v-list-item-content dense>
            <v-row dense align="center">
              <v-col class="d-flex" cols="12">
                <div class="body-2 font-weight-medium">
                  {{ data.item.name }} -
                  <span class="caption"
                    >{{ data.item.code }}
                    <span v-show="data.item.haveChild"
                      >({{ $t("all-airports") }})</span
                    >
                  </span>
                </div>
              </v-col>
            </v-row>
          </v-list-item-content>
        </template>
        <template slot="selection" slot-scope="data">
          <v-icon>local_airport</v-icon>
          <span class="ml-2">{{ data.item.name }} - {{ data.item.code }}</span>
        </template>
      </v-autocomplete>
    </v-col>
    <v-col cols="3" class="pa-1" align-self="end">
      <v-autocomplete
        ref="autocompleteto"
        v-model="itenary.to"
        :items="airports"
        :label="$t('arrival')"
        :filter="customFilter"
        hide-no-data
        item-text="name"
        item-value="code"
        :clearable="itenary.to != ''"
        dense
        hide-details
      >
        <template slot="item" slot-scope="data">
          <v-list-item-action
            :class="{ 'child-tile': data.item.parent && toHasParent }"
          >
            <v-icon>local_airport</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-row dense align="center">
              <v-col class="d-flex" cols="12">
                <div class="body-2 font-weight-medium">
                  {{ data.item.name }} -
                  <span class="caption">{{ data.item.code }} </span>
                </div>
              </v-col>
            </v-row>
          </v-list-item-content>
        </template>

        <template slot="selection" slot-scope="data">
          <span class="ml-2">{{ data.item.name }} - {{ data.item.code }}</span>
        </template>
      </v-autocomplete>
    </v-col>
    <v-col cols="2" class="pa-1" align-self="end">
      <smart-picker
        v-model="itenary.date"
        :next-picker="$refs.bookingStopDatePicker"
        :picker-date="itenary.date"
        format="dd/MM/yyyy"
        :startDate="itenary.date"
        :inputOpts="{ label: $t('startdate') }"
      ></smart-picker>
    </v-col>
    <v-col cols="2" class="pa-1" align-self="end">
      <v-select
        label="Travel Class"
        :items="travelClasses"
        v-model="selectedTravelClass"
        hide-details
      ></v-select>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "FlightItenary",
  components: {},
  props: {
    index: {
      type: Number,
      required: true,
    },
    airports: {
      type: Array,
      required: true,
    },
    itenary: {
      type: Object,
      required: true,
    },
    booking: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    from: null,
    to: null,

    fromHasParent: true,
    airportsFrom: [],

    toHasParent: true,
    airportsTo: [],

    travelClasses: ["ECONOMY", "PREMIUM_ECONOMY", "BUSINESS", "FIRST"],
    selectedTravelClass: "ECONOMY",
  }),
  mounted() {
    /*
			this.$watch(() => {
					return this.$refs.autocompletefrom.filteredItems
				}, (val) => {

					const checkHasRef = (arr, val) => {
						return arr.some(function (arrVal) {
							return val === arrVal.ref && arrVal.haveChild;
						});
					}

					val = val.map(v => {
						v.fromHasParent = checkHasRef(val, v.ref)
						return v
					})
				}
			)
			this.$watch(() => {
					return this.$refs.autocompleteto.filteredItems
				}, (val) => {
					this.toHasParent = val.filter(v => v.haveChild).length > 0
				}
			)
			 */
  },
  created() {},
  watch: {
    "itenary.date"() {
      this.modelChange("date");
    },
    "itenary.from"(val) {
      this.modelChange("from");
    },
    "itenary.to"(val) {
      this.modelChange("to");
    },
    selectedTravelClass(val) {
      this.itenary.travelClass = val;
      this.modelChange("travelclass");
    },
  },
  computed: {},
  methods: {
    modelChange(eventType) {
      let formatedItenary = {
        ...this.itenary,
        date: this.itenary.date.substring(0, 10),
      };
      this.$emit("itenarychange", {
        ...formatedItenary,
        ...{ index: this.index },
        ...{ eventType: eventType },
      });
    },

    customFilter(item, query, itemText) {
      const querylc = query.toLocaleLowerCase();

      return (
        item.code.toLocaleLowerCase().indexOf(querylc) > -1 ||
        item.name.toLocaleLowerCase().indexOf(querylc) > -1
      );
    },
  },
};
</script>

<style>
.v-autocomplete__content .layout {
  width: 100%;
}

.child-tile {
  margin-left: 50px;
}
</style>
