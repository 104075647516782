<template>
  <layout :drawer-show="false">
    <template #nav>
      <div>

      </div>

    </template>
    <template #nav-tools>

      <smart-btn type="button" primary @click.native="editConfig(editDashboard)" class="mr-4" v-if="!_isMobile()">
        <div v-if="!editDashboard">
          <v-icon>edit</v-icon>
          Editer
        </div>
        <div v-if="editDashboard">
          <v-icon>save</v-icon>
          Sauvegarder
        </div>
      </smart-btn>
      <smart-btn type="button" primary color="delete" @click.native="cancelConfig()" v-if="editDashboard">
        <v-icon>close</v-icon>
        Annuler
      </smart-btn>
    </template>
    <template slot="drawer">
      <v-container fluid class="pa-0">
        <v-row no-gutters>
          <v-col cols="6">
            <v-subheader light class="pa-0 mt-1 mb-0 mx-0 form-subheader">Filtres</v-subheader>
            <v-divider></v-divider>
          </v-col>
          <v-col cols="12" class="mt-4">
            <v-subheader light class="pa-0  mb-0 mx-0 mt-2 form-subheader">Période actuelle</v-subheader>
            <v-divider></v-divider>
          </v-col>
          <v-col class="pr-1" cols="6">
            <smart-picker v-model="form.period.startDate" :stopDate="form.period.stopDate"
                          :inputOpts="{label:$t('begin')}"></smart-picker>
          </v-col>
          <v-col cols="6">
            <smart-picker v-model="form.period.stopDate" :startDate="form.period.startDate"
                          :inputOpts="{label:$t('end')}"></smart-picker>
          </v-col>
          <v-col cols="12" class="mt-4">
            <v-subheader light class="pa-0   mt-2 form-subheader">Période n-1</v-subheader>
            <v-divider></v-divider>
          </v-col>
          <v-col class="pr-1" cols="6">
            <smart-picker v-model="form.lastPeriod.startDate"
                          :stopDate="form.lastPeriod.stopDate"
                          :inputOpts="{label:$t('begin')}"></smart-picker>
          </v-col>
          <v-col cols="6">
            <smart-picker v-model="form.lastPeriod.stopDate"
                          :startDate="form.lastPeriod.startDate"
                          :inputOpts="{label:$t('end')}"></smart-picker>
          </v-col>
          <v-col class="pr-1 mt-6" cols="12">
            <v-select
                :items="destinations"
                item-value="id"
                item-text="name"
                label="Destination"
                v-model="form.destinations"
                multiple
                dense
                hide-details
                placeholder=" "
            >
            </v-select>
          </v-col>
          <v-col class="pr-1 mt-6" cols="12">
            <v-select
                :items="sites"
                item-value="id"
                item-text="name"
                label="Site"
                v-model="form.sites"
                multiple
                dense
                hide-details
                placeholder=" "

            >
            </v-select>
          </v-col>
          <v-col cols="12" class="mb-3 mt-3">
            <smart-btn type="button" block primary class=" mt-4" @click.native="search()">
              <v-icon>search</v-icon>
              Rechercher
            </smart-btn>
          </v-col>
        </v-row>
      </v-container>
    </template>
    <template slot="content">
      <div class="grid grid-r">
        <v-skeleton-loader v-if="!gridReady" height="100%" type="list-item-avatar-two-line, list-item-three-line@7"></v-skeleton-loader>
        <GridLayoutCustom :items="widgets" :form="buildBody()" v-if="gridReady"/>
      </div>
    </template>
  </layout>

</template>
<script>
import Layout from "@/views/Layout.vue";
import {get} from "@/utils/api";
import {format} from "date-fns";
import {EventBus} from "@/components/commons/event-bus";
import GridLayoutCustom from "@/components/commons/GridLayoutCustom.vue";

export default {
  components: {
    GridLayoutCustom,
    Layout,
  },


  data(vm) {
    return {
      gridReady: false,
      editDashboard: false,
      widgets: [],
      pending: false,
      destinations: [],
      sites: [],
      rawDataTable: null,
      selectedYear: new Date().getFullYear(),
      data: null,
      actualView: 'month',
      oldFullYear: true,
      form: {
        lastPeriod: {
          startDate: format(new Date().setFullYear(new Date().getFullYear() - 1, 0, 1), 'dd/MM/yyyy'),
          stopDate: format(new Date().setFullYear(new Date().getFullYear() - 1), 'dd/MM/yyyy')
        },
        period: {
          startDate: format(new Date().setFullYear(new Date().getFullYear(), 0, 1), 'dd/MM/yyyy'),
          stopDate: format(new Date(), 'dd/MM/yyyy')
        },
        destinations: [],
        sites: [],
      },
    }
  },
  destroyed( ){
    EventBus.$off('getUpdateWidget', () => {})
  },
  created() {
    EventBus.$on('getUpdateWidget', () => {
      this.init()
    })
    this.init()
  },
  mounted() {
    get(`/api/search/destinations`, {"Content-Type": "application/json"}).json(rs => this.destinations.push(...rs))
    get(`/api/search/virtual-sites`, {"Content-Type": "application/json"}).json(rs => this.sites.push(...rs))

  },
  watch: {},
  computed: {},
  methods: {
    init() {
      const path = window.location.pathname
      const url = `/api/search/widgets/user/${this.currentUser.id}?path=${path}`
      get(url).json(r => {
        r.forEach((wdgt) => {
          Object.defineProperty(wdgt, "i",
              Object.getOwnPropertyDescriptor(wdgt, "id"));
        })

        this.widgets = r
        this.gridReady = true
      })
    },
    format,
    buildBody() {
      const startDateA = this._parseDate(this.form.period.startDate, 'dd/MM/yyyy')
      const stopDateA = this._parseDate(this.form.period.stopDate, 'dd/MM/yyyy')
      let startDateO = this._parseDate(this.form.lastPeriod.startDate, 'dd/MM/yyyy')
      let stopDateO = this._parseDate(this.form.lastPeriod.stopDate, 'dd/MM/yyyy')

      return {
        startDateAct: this._formatDate(startDateA, "yyyy-MM-dd"),
        stopDateAct: this._formatDate(stopDateA, "yyyy-MM-dd"),
        startDateOld: this._formatDate(startDateO, "yyyy-MM-dd"),
        stopDateOld: this._formatDate(stopDateO, "yyyy-MM-dd"),
        destinations: this.form.destinations,
        sites: this.form.sites,
        commercialIds: [],
        allAgencies: false,
        sameDates: {start: this._subYears(startDateA, 1), stop: this._subYears(stopDateA, 1)}
      }
    },
    cancelConfig() {
      this.editConfig(false)
    },
    editConfig(save = true) {
      EventBus.$emit('editDashboard', {save: this.editDashboard ? save : false})
      this.editDashboard = !this.editDashboard
    },
    search() {
      this.pending = true
      EventBus.$emit('searchDashboard', {})
      this.rawDataTable = null
    },
    
  },
}

</script>