<template>
  <v-menu
    v-model="menu"
    :open-on-click="true"
    :close-on-content-click="false"
    :close-delay="0"
    offset-y
    min-width="550"
    absolute
    transition="scale-transition"
    origin="top right"
    hide-on-leave
  >
    <template v-slot:activator="{ on }">
      <div v-on="on">
        <div style="height: 20px">
          <v-icon color="blue" small v-if="selectedFlyStartTakeOff">mdi-airplane-takeoff</v-icon>
          <v-icon color="blue" small v-if="selectedFlyStartLanding">mdi-airplane-landing</v-icon>

          <v-icon color="blue" small v-if="selectedHotel">mdi-hotel</v-icon>

          <v-icon color="blue" small v-if="selectedFlyStopTakeOff">mdi-airplane-takeoff</v-icon>
          <v-icon color="blue" small v-if="selectedFlyStopLanding">mdi-airplane-landing</v-icon>
        </div>
        <div v-if="priceday.hotel !== void(0) && priceday.hotel != null">
          <div
            v-if="priceday.hotel.prices"
            class="cell-price"
            :class="{'min-price': priceday.total == minPrice}"
          >{{(priceday.total).toLocaleString()}}{{priceday.hotel.prices.currency.symbol}}</div>
          <div v-else>
            <div v-if="!priceday.hotel.availability.occupable">
              <v-icon small>mdi-account-off</v-icon>
            </div>
            <div v-else-if="priceday.hotel.availability.minStay != null">
              <v-icon small>mdi-format-horizontal-align-right</v-icon>
              {{priceday.hotel.availability.minStay}}
            </div>
            <div v-else>-</div>
          </div>
        </div>
        <div v-else>-</div>
      </div>
      <div class="status" v-if="hotelStock" :class="[hotelStock.status]"></div>
    </template>

    <v-card>
      <v-card-text>
        <v-timeline dense align-top>
          <v-timeline-item color="pink" v-if="priceday.flight" icon="mdi-airplane-takeoff">
            <v-row>
              <v-col cols="3">{{formatDate(priceday.flight.flies[0].departureDate)}}</v-col>
              <v-col>
                Vol
                {{priceday.flight.prices[0].details[0].validatingAirline}}
              </v-col>
              <v-col cols="2" class="recap-price">{{priceday.flight.prices[0].pricing.price}}</v-col>
            </v-row>
            <ul>
              <li v-for="(s,sIndex) in priceday.flight.flies[0].segments" :key="sIndex">
                {{s.flightCode}} - {{s.duration}}
                <span
                  v-if="s.flyStops.length > 0"
                >- {{s.flyStops.length}} escales</span>
                <div
                  class="caption"
                >Depart le {{s.departureDate}} de {{s.from.name}} ({{s.from.code}})</div>
                <div class="caption">Arrivée le {{s.arrivalDate}} à {{s.to.name}} ({{s.to.code}})</div>
              </li>
            </ul>
          </v-timeline-item>
          <v-timeline-item color="orange" v-if="priceday.transfer" icon="mdi-transit-transfer">
            <v-row>
              <v-col cols="3">{{formatDate(priceday.transfer.come.start)}}</v-col>
              <v-col>Transfers</v-col>
              <v-col cols="2" class="recap-price">{{priceday.transfer.pricing.prices.price}}</v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                De {{priceday.transfer.come.from.name}} à {{priceday.transfer.come.to.name}}
                <div class="caption">
                  <span
                    v-for="(s,sIndex) in priceday.transfer.come.segments"
                    :key="sIndex"
                  >{{$t('vehicleType.'+s.transfer.vehicleType)}}</span>
                </div>
              </v-col>
            </v-row>
          </v-timeline-item>
          <v-timeline-item
            color="blue"
            v-if="priceday.hotel && priceday.hotel.prices"
            icon="mdi-hotel"
          >
            <v-row>
              <v-col cols="3">{{formatDate(priceday.hotel.startDate)}}</v-col>
              <v-col>{{priceday.hotelInfos.name}}</v-col>
              <v-col cols="2" class="recap-price">{{priceday.hotel.prices.price}}</v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
              >{{roomName}} - {{mealName}} - {{_nbNights(priceday.hotel.startDate, priceday.hotel.stopDate)}} nuits</v-col>
            </v-row>
          </v-timeline-item>
          <v-timeline-item color="orange" v-if="priceday.transfer" icon="mdi-transit-transfer">
            <v-row>
              <v-col cols="3">{{formatDate(priceday.transfer.back.start)}}</v-col>
              <v-col>Transfers</v-col>
              <v-col cols="2"></v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                De {{priceday.transfer.back.from.name}} à {{priceday.transfer.back.to.name}}
                <div class="caption">
                  <span
                    v-for="(s,sIndex) in priceday.transfer.back.segments"
                    :key="sIndex"
                  >{{$t('vehicleType.'+s.transfer.vehicleType)}}</span>
                </div>
              </v-col>
            </v-row>
          </v-timeline-item>
          <v-timeline-item color="pink" v-if="priceday.flight" icon="mdi-airplane-landing">
            <v-row>
              <v-col
                cols="3"
              >{{formatDate(priceday.flight.flies[priceday.flight.flies.length-1].departureDate)}}</v-col>
              <v-col>
                Vol
                {{priceday.flight.prices[0].details[0].validatingAirline}}
              </v-col>
              <v-col cols="2"></v-col>
            </v-row>
            <ul>
              <li
                v-for="(s,sIndex) in priceday.flight.flies[priceday.flight.flies.length-1].segments"
                :key="sIndex"
              >
                {{s.flightCode}} - {{s.duration}}
                <span
                  v-if="s.flyStops.length > 0"
                >- {{s.flyStops.length}} escales</span>
                <div
                  class="caption"
                >Depart le {{s.departureDate}} de {{s.from.name}} ({{s.from.code}})</div>
                <div class="caption">Arrivée le {{s.arrivalDate}} à {{s.to.name}} ({{s.to.code}})</div>
              </li>
            </ul>
          </v-timeline-item>
        </v-timeline>

        <v-row>
          <v-col cols="12" class="recap-price">Prix total: {{priceday.total}}</v-col>
          <v-col cols="12" class="text-right">
            <v-btn @click="select">Ajouter</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-menu>
</template>
<script>
import _ from "lodash";

export default {
  name: "CalendarCell",
  props: {
    day: { type: String, required: true },
    priceday: { required: true },
    hotelStock: { required: true },
    minPrice: { type: Number },
    selected: { required: true },
  },
  data: () => ({
    menu: false,
    dayAsMoment: null,
  }),
  created() {
    this.dayAsMoment = this._parseDate(this.day);
  },
  watch: {},
  computed: {
    freeOffers() {
      return _.filter(this.priceday.hotel.prices.offers, (o) => {
        return o.amount === 0;
      });
    },
    roomName() {
      if (this.priceday.hotel && this.priceday.hotel.rooms) {
        return this.priceday.hotel.rooms.map((r) => r.name).join(", ");
      }
      return "";
    },
    mealName() {
      if (this.priceday.hotel && this.priceday.hotel.meals) {
        return this.priceday.hotel.meals.map((r) => r.name).join(", ");
      }
      return "";
    },
    selectedFlyStartTakeOff() {
      if (this.selected && this.selected.flight) {
        return this.flightTakeoff(this.selected.flight.flies[0]);
      }
      return false;
    },
    selectedFlyStartLanding() {
      if (this.selected && this.selected.flight) {
        return this.flightLanding(this.selected.flight.flies[0]);
      }
      return false;
    },
    selectedFlyStopTakeOff() {
      if (this.selected && this.selected.flight) {
        return this.flightTakeoff(
          this.selected.flight.flies[this.selected.flight.flies.length - 1]
        );
      }
      return false;
    },
    selectedFlyStopLanding() {
      if (this.selected && this.selected.flight) {
        return this.flightLanding(
          this.selected.flight.flies[this.selected.flight.flies.length - 1]
        );
      }
      return false;
    },
    selectedHotel() {
      if (this.selected) {
        var start = this._parseDate(this.selected.hotelStart, "dd/MM/yyyy");
        var stop = this._parseDate(this.selected.hotelStop, "dd/MM/yyyy");

        return (
          this._isSameOrAfter(this.dayAsMoment, start) &&
          this._isBefore(this.dayAsMoment, stop)
        );
      }
      return false;
    },
  },
  methods: {
    select() {
      this.$emit("select", this.priceday);
      this.menu = false;
    },
    flightTakeoff(fly) {
      var start = this._parseDate(fly.departureDate, "dd/MM/yyyy HH:mm:ss");
      return this._isSame(this.dayAsMoment, start, "day");
    },
    flightLanding(fly) {
      var stop = this._parseDate(fly.arrivalDate, "dd/MM/yyyy HH:mm:ss");
      return this._isSame(this.dayAsMoment, stop, "day");
    },
    formatDate(d) {
      return this._parseAndFormatDate(d, "dd/MM/yyyy HH:mm:ss", "dd/MM/yyyy");
    },
  },
};
</script>
<style scoped>
.cell-price {
  color: #9e9e9e;
  cursor: pointer;
}

.min-price {
  color: #000;
  font-weight: bold;
}

.price {
  font-size: 14px;
}

.status {
  width: 25px;
  border-radius: 5px;
}

.recap-price {
  text-align: right;
  font-weight: bold;
}
</style>
