var render = function render(){var _vm=this,_c=_vm._self._c;return _c('layout',{attrs:{"drawerShow":_vm._isDesktop(),"drawerWidth":350}},[_c('template',{slot:"drawer"},[(_vm.searchform && _vm.searchform.selectedNetworks)?_c('v-form',{ref:"searchform",on:{"submit":function($event){$event.preventDefault();return _vm.search.apply(null, arguments)}}},[_c('v-row',{staticClass:"pt-2 pr-1",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pr-1",attrs:{"cols":"12"}},[_c('smart-multi-select',{attrs:{"label":"Réseaux","items":_vm.networks,"all-is-same-as-empty":true,"default-select-all":true},on:{"input":function($event){return _vm.loadPaymentTerms()}},model:{value:(_vm.searchform.selectedNetworks),callback:function ($$v) {_vm.$set(_vm.searchform, "selectedNetworks", $$v)},expression:"searchform.selectedNetworks"}})],1),_c('v-col',{staticClass:"mt-2",attrs:{"cols":"12"}},[_c('v-subheader',{staticClass:"pa-0 mb-0 mx-0 form-subheader",attrs:{"light":""}},[_vm._v("Dates de réservation")]),_c('v-divider')],1),_c('v-col',{staticClass:"pr-1",attrs:{"cols":"6"}},[_c('smart-picker',{attrs:{"stopDate":_vm.searchform.bookingStopDate,"inputOpts":{
                label: _vm.$t('begin'),
                clearable: true,
                placeholder: ' ',
              }},model:{value:(_vm.searchform.bookingStartDate),callback:function ($$v) {_vm.$set(_vm.searchform, "bookingStartDate", $$v)},expression:"searchform.bookingStartDate"}})],1),_c('v-col',{staticClass:"pr-1",attrs:{"cols":"6"}},[_c('smart-picker',{attrs:{"startDate":_vm.searchform.bookingStartDate,"inputOpts":{
                label: _vm.$t('end'),
                clearable: true,
                placeholder: ' ',
              }},model:{value:(_vm.searchform.bookingStopDate),callback:function ($$v) {_vm.$set(_vm.searchform, "bookingStopDate", $$v)},expression:"searchform.bookingStopDate"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-subheader',{staticClass:"pa-0 mt-5 mb-0 mx-0 form-subheader",attrs:{"light":""}},[_vm._v("Dates de voyage")]),_c('v-divider')],1),_c('v-col',{staticClass:"pr-1",attrs:{"cols":"6"}},[_c('smart-picker',{attrs:{"stopDate":_vm.searchform.departureStopDate,"inputOpts":{
                label: _vm.$t('begin'),
                clearable: true,
                placeholder: ' ',
              }},model:{value:(_vm.searchform.departureStartDate),callback:function ($$v) {_vm.$set(_vm.searchform, "departureStartDate", $$v)},expression:"searchform.departureStartDate"}})],1),_c('v-col',{staticClass:"pr-1",attrs:{"cols":"6"}},[_c('smart-picker',{attrs:{"startDate":_vm.searchform.departureStartDate,"inputOpts":{
                label: _vm.$t('end'),
                clearable: true,
                placeholder: ' ',
              }},model:{value:(_vm.searchform.departureStopDate),callback:function ($$v) {_vm.$set(_vm.searchform, "departureStopDate", $$v)},expression:"searchform.departureStopDate"}})],1)],1),_c('smart-btn',{staticClass:"mt-8",attrs:{"loading":_vm.loading,"type":"submit","block":"","primary":""}},[_vm._v("Rechercher")]),_c('smart-btn',{attrs:{"block":"","tertiary":""},nativeOn:{"click":function($event){_vm.clearSearchform();
            _vm.init();}}},[_vm._v("Réinitialiser la recherche")])],1):_vm._e()],1),_c('template',{slot:"content"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"mb-3",attrs:{"cols":"12"}},[_c('v-card',{staticClass:"pa-0 ma-0 blue-grey lighten-1"},[_c('v-row',{attrs:{"justify":"space-around"}},[_c('v-col',{staticClass:"px-1",attrs:{"cols":"12","md":"3"}},[_c('widget-network-ca',{attrs:{"bus":_vm.widgetBus,"url":"/api/widget/networkca-resume"}})],1),_c('v-col',{staticClass:"px-1",attrs:{"cols":"12","md":"3"}},[_c('widget-network-agencies',{attrs:{"bus":_vm.widgetBus,"url":"/api/widget/networkagency-resume"}})],1)],1)],1)],1)],1),_c('v-col',[_c('widget-network-stats-by-ca',{attrs:{"bus":_vm.widgetBus,"url":"/api/widget/network-stats"}}),_c('widget-network-stats-by-desti',{attrs:{"bus":_vm.widgetBus,"url":"/api/widget/network-stats/desti"}}),_c('widget-network-top-agencies',{attrs:{"bus":_vm.widgetBus,"url":"/api/widget/network-stats/agency"},on:{"loading":_vm.setLoading}})],1)],1)],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }