<template>
  <v-card>
    <v-card-title>
      <v-icon>people</v-icon>
      {{ $tc("occupant", 1) }}
    </v-card-title>
    <v-card-text>
      <div
        v-for="guest in prestation.guests"
        :key="guest.id"
        class="subheading"
      >
        {{ guest.firstName }} {{ guest.lastName }}
        <span class="caption" v-if="guest.ageClassType === 'Adult'">
          ({{ $tc(guest.ageClassType, 1) }})
        </span>
        <span class="caption" v-else>
          ( {{ _age(guest.birthDate, prestation.start) }}ans -
          {{ guest.birthDate }} )
        </span>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "BookingPrestaGuests",
  mixins: [],
  props: {
    prestation: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped></style>
