<template>
  <v-menu :close-on-content-click="false" offset-y>
    <template #activator="{on}">
      <v-btn v-on="on" text>
        {{renderGuests}}
        <v-icon right color="grey">supervisor_account</v-icon>
      </v-btn>
    </template>

    <div class="menu-container white">
      <v-expansion-panels multiple readonly tile class="elevation-0 ma-0 pa-0" v-model="panelModel">
        <v-expansion-panel
          class="ma-0 pa-0 elevation-0"
          v-for="(room, index) in rooms"
          :key="index"
        >
          <v-expansion-panel-header class="ma-0 pa-0">
            <template v-slot:actions>
              <v-tooltip bottom v-if="index>0">
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" icon small text @click="removeRoom(index)">
                    <v-icon>close</v-icon>
                  </v-btn>
                </template>
                <span>Enlever la chambre</span>
              </v-tooltip>
              <v-icon v-else></v-icon>
            </template>
            <v-subheader class="pa-1 ma-0">
              Chambre {{index+1}} -
              <small>{{computeGuests(room.guests)}}</small>
            </v-subheader>
          </v-expansion-panel-header>

          <v-expansion-panel-content class="ma-1 py-0 px-1">
            <v-row class="elevation-0">
              <v-col cols="7" class="px-1">
                <v-select
                  :items="room.includedGuest"
                  item-value="id"
                  item-text="label"
                  :label="$t('guests')"
                  v-model="room.guests"
                  multiple
                  dense
                  hide-details
                  placeholder=" "
                  no-data-text="Tous les passagers ont déjà été sélectionné"
                  return-object
                  @change="resetRooms"
                >
                  <template slot="selection" slot-scope="data">
                    <template v-if="data.item" class>
                      <span
                        v-if="data.item.firstName"
                        class="pr-1"
                      >{{initial(data.item.firstName, data.item.lastName)}}</span>
                      <span v-else class="pr-1">
                        <span
                          class="caption grey--text"
                        >({{$t(data.item.ageClassType+'-small')}}{{data.item.index}})</span>
                      </span>
                    </template>
                    <template v-else>#{{data.item.id}}</template>
                  </template>
                </v-select>
              </v-col>
              <v-col cols="5" class="px-1">
                <v-select
                  v-model="room.repartition"
                  :items="repartitions"
                  item-value="value"
                  item-text="label"
                  label="Répartition"
                  dense
                  hide-details
                  placeholder="Sélectionner des passagers"
                ></v-select>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-col cols="12" class="text-right">
        <v-divider></v-divider>
        <v-btn small right text outlined @click.native="addRoom">Ajouter une chambre</v-btn>
      </v-col>
    </div>
  </v-menu>
</template>

<script>
import _ from "lodash";
import GuestsMixin from "../mixins/GuestsMixin";

import GuestRepartition from "./GuestRepartition";
import BookingGuestRepartition from "./BookingGuestRepartition";

export default {
  name: "RoomGuestsRepartition",
  components: { BookingGuestRepartition, GuestRepartition },
  mixins: [GuestsMixin],
  props: {
    value: {
      type: Array,
      required: true,
      default: () => [],
    },
    guests: { type: Array, required: true, default: () => [] },
  },
  data() {
    return {
      panelModel: [0],
      rooms: [],
      repartitions: [{ value: "SAMEROOM", label: "Même chambre" }],
    };
  },
  created() {
    this.init();
  },
  watch: {
    guests(newVal) {
      this.init();
    },
  },
  computed: {
    renderGuests() {
      const all = this.value.flatMap((v) => v.guests);
      return this.computeGuests(all);
    },
  },
  methods: {
    init() {
      this.globalGuests = this.guestsFactory(this.guests);
      this.rooms = this.roomsFactory(this.value);
      this.resetPanel();
    },
    resetRooms() {
      this.$nextTick(() => {
        this.rooms = this.roomsFactory(this.rooms);
        this.$emit("input", this.rooms);
      });
    },
    roomsFactory(rooms) {
      return rooms.map((room) => {
        return { ...room, ...{ includedGuest: this.leftGuests(room.index) } };
      });
    },
    guestsFactory(guests) {
      const checkGuest = (g, index, f) => {
        const tmpage = this._age(g.birthDate);
        const age = tmpage > 0 ? " - " + tmpage.toString() + "ans" : "";
        const birthDate = g.birthDate ? " - " + g.birthDate : "";

        return g.firstName == null ||
          g.firstName === "" ||
          g.lastName == null ||
          g.lastName === ""
          ? this.$t(g.ageClassType) + " " + index + " " + age + birthDate + " "
          : f(g);
      };

      let indexAd = 0;
      let indexCh = 0;

      const sortedGuests = _.cloneDeep(guests).sort((g1, g2) => g1.id - g2.id);

      return sortedGuests.map((g) => {
        let ageClass =
          g.ageClassType === "Adult"
            ? this.$t(g.ageClassType)
            : `${this._age(g.birthDate)} ans - ${g.birthDate}`;
        const index = (g.ageClassType === "Adult"
          ? () => {
              indexAd += 1;
              return indexAd;
            }
          : () => {
              indexCh += 1;
              return indexCh;
            })();

        const name = `${checkGuest(
          g,
          index,
          (g) => g.firstName + " " + g.lastName + " (" + ageClass + ")"
        )} `;

        return { ...g, ...{ label: name, index: index } };
      });
    },

    leftGuests(currentRoomIndex) {
      const otherRooms = this.rooms.reduce((previous, curr) => {
        return currentRoomIndex == curr.index
          ? previous
          : previous.concat(curr);
      }, []);

      const alreadySelectedGuestsIds = otherRooms
        .flatMap((v) => v.guests)
        .map((g) => g.id);

      return this.globalGuests.filter((g) => {
        return alreadySelectedGuestsIds.indexOf(g.id) == -1;
      });
    },

    //
    computeGuests(guests) {
      const ad = guests.filter((g) => g.ageClassType == "Adult").length;
      const ch = guests.length - ad;

      return `${ad} AD ${ch > 0 ? `${ch} CH` : ""}`;
    },
    addRoom() {
      this.rooms = this.rooms.concat({
        index: this.rooms.length,
        guests: [],
        repartition: "SAMEROOM",
        includedGuest: this.leftGuests(this.rooms.length),
      });
      this.resetPanel();
    },
    removeRoom(index) {
      this.rooms.splice(index, 1);
      this.resetRooms();
      this.resetPanel();
    },

    //
    resetPanel() {
      this.panelModel = this.rooms.map((r, idx) => idx);
    },
  },
};
</script>

<style scoped>
.menu-container {
  width: 450px;
}

>>> .v-expansion-panel-header {
  height: 44px !important;
}

>>> .v-expansion-panel-content__wrap {
  margin: 0;
  padding: 0 4px;
}
</style>
