<template>
  <div>
    <v-toolbar
      dense
      color="white"
      class="subnav elevation-1 breadcrumb-toolbar"
      style="z-index: 3!important;position: relative;"
      :style="{ 'padding-right': drawerSize, 'position': navFixed ? 'fixed' : 'relative', 'width': navFixed?'100%':'' }"
    >
      <slot name="nav" />
      <v-spacer />
      <slot name="nav-tools" />

      <template v-if="drawer">
        <v-divider vertical />
        <v-toolbar-items>
          <v-btn text @click="drawermodel = !drawermodel" active>
            <v-icon>{{ drawerIcon }}</v-icon>
            <span class="pl-1 d-none d-sm-flex">{{ drawerName }}</span>
          </v-btn>
        </v-toolbar-items>
      </template>
    </v-toolbar>

    <v-main class="pl-0" :style="{ 'padding-right': drawerSize + 'px', 'padding-top': '0px' }">
      <v-navigation-drawer
        v-if="drawer"
        fixed
        right
        clipped
        hide-overlay
        v-model="drawermodel"
        :width="drawerWidth"
        color="white"
        style="padding:20px;padding-top:116px;z-index:2!important;"
      >
        <slot name="drawer" />
      </v-navigation-drawer>

      <!--<div class="pa-3"><breadcrumb/></div>-->
      <slot name="content" />
    </v-main>
  </div>
</template>

<script>
import Breadcrumb from "@/components/commons/Breadcrumb";
import {EventBus} from "@/components/commons/event-bus";

export default {
  name: "Layout",
  props: {
    drawer: { type: Boolean, default: true },
    drawerIcon: { type: String, default: "mdi-tune" },
    drawerName: { type: String, default: "Filtres" },
    drawerShow: { type: Boolean, default: false },
    drawerWidth: { type: Number, default: 300 },
    navFixed: { type: Boolean, required: false, default: false }
  },
  components: {
    Breadcrumb,
  },
  mixins: [],
  data() {
    return {
      drawermodel: this.show,
    };
  },
  created() { },
  mounted() {
    EventBus.$on('close.filters', (msg) => {
      this.drawermodel = false
    })
  },
  destroyed() {
    EventBus.$off('close.filters')
  },
  watch: {
    drawerShow: {
      immediate: true,
      handler(newVal) {
        this.drawermodel = newVal;
      },
    },
  },
  computed: {
    drawerSize() {
      return this.drawermodel ? this.drawerWidth : 0;
    },
  },
  methods: {},
};
</script>

<style scoped>
.subnav >>> .v-toolbar__content {
  padding-right: 0;
}

>>> .v-toolbar.breadcrumb-toolbar {
  padding-left: 80px !important;
}

@media screen and (max-width: 600px) {
  >>> .v-toolbar.breadcrumb-toolbar {
    padding-left: 0px !important;
  }
}
</style>
