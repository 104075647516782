<template>
	<v-select
		:items="selections"
		item-value="id"
		item-text="label"
		:label="$t('guests')"
		v-model="selected"
		multiple
		dense
		hide-details
		placeholder=" "
		return-object
	>
		<template slot="selection" slot-scope="data">
			<template v-if="data.item" class="pr-1">

				<span v-if="data.item.firstName">
					{{initial(data.item.firstName, data.item.lastName)}}
				</span>
				<span v-else>
					<span class="caption grey--text">({{$t(data.item.ageClassType+'-small')}})</span>
				</span>

			</template>
			<template v-else>
      	#{{data.item.id}}
			</template>
		</template>
	</v-select>
</template>

<script>
	import GuestsMixin from "../mixins/GuestsMixin";

	export default {
		name: "BookingGuestRepartition",
		mixins: [GuestsMixin],
		props: {
			guests: {
				type: Array,
				required: true
			},
			value: {
				type: Array,
				required: true,
				default: () => []
			}
		},
		data: () => ({
			selections: [],
			selected: []
		}),
		created() {
			this.init()
		},
		watch: {
			value(newVal) {
				this.init()
			},
			selected(newVal) {
				this.$emit('input', newVal)
			}
		},
		computed: {},
		methods: {
			init() {
				const checkGuest = (g, index, f) => {
					const tmpage = this._age(g.birthDate)
					const age = tmpage>0 ? ' - ' + tmpage.toString() + 'ans' : ''
					const birthDate = g.birthDate ? ' - ' + g.birthDate : ''

					return g.firstName == null
					|| g.firstName === ''
					|| g.lastName == null
					|| g.lastName === ''
						? this.$t(g.ageClassType) + ' '  + index + ' ' + age + birthDate + ' ' : f(g)
				}


				let indexAd = 0
				let indexCh = 0

				this.selections = this.guests.map((g) => {
					let ageClass = g.ageClassType === 'Adult' ? this.$t(g.ageClassType) : `${this._age(g.birthDate)} ans - ${g.birthDate}`
					const index = g.ageClassType === 'Adult' ? () => {
						indexAd += 1
						return indexAd
					} : () => {
						indexCh += 1
						return indexCh
					}

					const name = `${checkGuest(g, index(), (g) => g.firstName + ' ' + g.lastName + ' (' + ageClass + ')')} `

					g.label = name
					return g;
				})
				this.selected = this.value
				this.$emit('input', this.selected)
			}
		}
	}
</script>

<style scoped>
	.v-select__selections > span:after {
		content: '- ';
	}

	.v-select__selections > span:last-of-type:after {
		content: ' ';
	}
</style>
