<template>
  <v-col cols="12" v-if="details && details.arrival && details.departure">
    <collapse-panel :collapsable="true" :open="false" :hidemenu="true">
      <div slot="title">
        <v-row align="center">
          <v-col class="caption grey--text lighten-1 font-weight-medium">
            {{ $t("customer-flight-infos") }}
          </v-col>
        </v-row>
      </div>
      <div slot="content">
        <v-row>
          <v-col cols="6">
            <b
              >{{ $t("outbound-flight") }} N°
              {{ details.arrival.flightNumber }}</b
            ><br />
            {{ $t("arrival-on") }} {{ details.arrival.name }}<br />
            {{ $t("on") }} {{ details.arrival.date }} {{ $t("at") }}
            {{ details.arrival.hour }}h{{ details.arrival.minute }}<br />
          </v-col>
          <v-col cols="6">
            <b
              >{{ $t("return-flight") }} N°
              {{ details.departure.flightNumber }}</b
            ><br />
            {{ $t("departure-on") }} {{ details.departure.name }}<br />
            {{ $t("on") }} {{ details.departure.date }} {{ $t("at") }}
            {{ details.departure.hour }}h{{ details.departure.minute }}<br />
          </v-col>
        </v-row>
      </div>
    </collapse-panel>
  </v-col>
</template>

<script>
import CollapsePanel from "@/components/commons/CollapsePanel";

export default {
  name: "CustomerFlightsInfos",
  components: { CollapsePanel },
  props: {
    additionalInfos: {
      type: Array,
      required: true,
    },
  },
  mixins: [],
  data() {
    return {
      details: null,
    };
  },
  created() {},
  watch: {
    additionalInfos: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          const flightinfos = newVal.find(
            (data) => data.item === "flightinfos"
          );
          if (flightinfos) {
            this.details = JSON.parse(flightinfos.value);
          }
        }
      },
    },
    computed: {},

    methods: {},
  },
};
</script>

<style scoped>
</style>
