<template>
  <v-tooltip left>
    <v-btn slot="activator" icon @click.native="toggleDrawerBookingSummary()">
      <v-icon v-show="!drawerBookingSummary">keyboard_arrow_left</v-icon>
      <v-icon v-show="drawerBookingSummary">keyboard_arrow_right</v-icon>
    </v-btn>
    <span>
      <span v-show="!drawerBookingSummary">Afficher infos booking</span>
      <span v-show="drawerBookingSummary">Cacher infos booking</span>
    </span>
  </v-tooltip>
</template>

<script>
//import {EventBus} from "@/components/commons/event-bus";

export default {
  name: "OpenBookingDrawer",
  mixins: [],
  props: {
    booking: {
      type: Object,
      required: true,
    },
    isInDialog: {
      type: Boolean,
      required: false,
      default: false,
    },
    isDialogOpen: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: function () {
    return {};
  },
  created() {},
  watch: {
    isDialogOpen(newVal) {
      if (this.isInDialog && this.drawerBookingSummary && !newVal) {
        this.$store.commit("toggleDrawerBookingSummary", this.drawerInfos);
      }
    },
  },
  computed: {
    drawerBookingSummary() {
      return this.$store.state.drawerBookingSummary;
    },
    drawerInfos() {
      return {
        booking: this.booking,
      };
    },
  },
  methods: {
    toggleDrawerBookingSummary() {
      this.$store.commit("toggleDrawerBookingSummary", this.drawerInfos);
    },
  },
};
</script>

<style scoped>
</style>
