<template>
  <v-card>
    <v-card-text>
      <v-toolbar flat color="white" class="mb-4">
        <v-spacer />
        <v-toolbar-title
          style="text-transform: capitalize"
        >{{ _formatDate(new Date(start), 'MMMM yyyy') }}</v-toolbar-title>
        <v-spacer />
        <v-btn fab small @click="prev" class="mx-1">
          <v-icon small>mdi-chevron-left</v-icon>
        </v-btn>

        <v-btn fab small @click="next" class="mx-1">
          <v-icon small>mdi-chevron-right</v-icon>
        </v-btn>
      </v-toolbar>

      <v-calendar
        ref="calendar"
        v-model="start"
        :now="today"
        :value="today"
        :start="start"
        :end="end"
        :weekdays="[1, 2, 3, 4, 5, 6, 0]"
        locale="fr"
        @change="change"
      >
        <template v-slot:day="{ date }">
          <div class="text-center">
            <template v-if="dates[date]">
              <v-menu v-model="dates[date].menu" offset-x min-width="600">
                <template v-slot:activator="{ on }">
                  <v-btn text small primary v-on="on" class="mb-1">
                    <span v-if="dates[date].price != null">{{dates[date].price | currency('EUR')}}</span>
                    <span v-else style="text-transform: none;" class="grey--text">non disp.</span>
                  </v-btn>
                </template>

                <v-card>
                  <v-card-title>
                    <span>du {{_formatDate(new Date(dates[date].start), 'dd/MM/yyyy')}} au {{_formatDate(new Date(dates[date].stop), 'dd/MM/yyyy')}}</span>
                    <v-spacer />
                    <v-btn icon small @click="dates[date].menu=false">
                      <v-icon small>mdi-close</v-icon>
                    </v-btn>
                  </v-card-title>
                  <v-card-text style="max-height: 600px;overflow:auto">
                    <v-row
                      v-for="(p, pIndex) in dates[date].prestations"
                      class="my-3"
                      :key="pIndex"
                    >
                      <v-col>
                        <div
                          class="grey--text"
                        >{{_formatDate(new Date(p.startDate), 'dd/MM/yyyy')}} - {{_formatDate(new Date(p.stopDate), 'dd/MM/yyyy')}}</div>
                        <div class="font-weight-bold">{{p.name}}</div>
                        <div>{{p.subtitle}}{{p.airline}}</div>
                      </v-col>
                      <v-col cols="2" class="text-right" style="align-self: center">
                        <span v-if="p.price != null">{{p.price | currency('EUR')}}</span>
                        <span v-else>non disp.</span>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col class="text-right font-weight-bold">Total</v-col>
                      <v-col cols="2" class="text-right font-weight-bold">
                        <span
                          v-if="dates[date].price != null"
                        >{{dates[date].price | currency('EUR')}}</span>
                        <span v-else>non disp.</span>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-divider class="my-3" />
                  <v-card-actions>
                    <div v-if="dates[date].price == null" class="orange--text">
                      <v-icon small>mdi-alert-decagram</v-icon>Les prestations sans prix seront manquantes
                    </div>
                    <v-spacer />
                    <v-btn small color="primary" @click="createBooking(date)">Créer Nouveau dossier</v-btn>
                  </v-card-actions>
                </v-card>
              </v-menu>
            </template>
            <v-btn disabled text small v-else>-</v-btn>
          </div>
        </template>

        <template v-slot:dayLabel="{ date, day }">
          <span class="grey--text">{{ day }}</span>
        </template>
        <template v-slot:dayMonth="{ date }">
          <span
            class="grey--text"
            style="text-transform: capitalize"
          >{{ _formatDate(new Date(date), 'MMMM') }}</span>
        </template>
      </v-calendar>
    </v-card-text>
    <smart-dialog
      v-model="dialogs.duplicate.show"
      title="Dossier dupliqué"
      @close="dialogs.duplicate.show = false"
      width="400px"
    >
      <v-row class="pa-2 text-center">
        <v-col cols="12">
          Le dossier a été dupliqué avec l'identifiant:
          <b>{{dialogs.duplicate.id}}</b>
        </v-col>
        <v-col cols="12">
          <div>
            <v-btn depressed small color="primary" @click="openDuplicate">Voir le dossier</v-btn>
          </div>
        </v-col>
      </v-row>
    </smart-dialog>
  </v-card>
</template>
<script>
import { post } from "@/utils/api";

export default {
  name: "BookingPostpone",
  components: {},
  props: {
    booking: { type: Object, required: true },
  },
  data: () => ({
    today: null,
    start: null,
    end: null,
    dates: {},
    newBookingId: null,
    dialogs: {
      duplicate: {
        id: null,
        show: false,
      },
    },
  }),
  watch: {
    booking: {
      immediate: true,
      handler(newVal, oldVal) {
        this.init();
      },
    },
  },
  computed: {},
  methods: {
    init() {
      this.start = this._formatDate(new Date(), "yyyy-MM-dd");
      this.stop = this._formatDate(this._addDays(new Date(), 30), "yyyy-MM-dd");
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    change({ start, end }) {
      post(`/api/booking-report/${this.booking.id}/all`, {
        fromDate: this._formatDate(
          this._addDays(new Date(start.date), -7),
          "dd/MM/yyyy"
        ),
        toDate: this._formatDate(
          this._addDays(new Date(end.date), 7),
          "dd/MM/yyyy"
        ),
        tags: [],
      }).json((data) => {
        data.map((p) => {
          this.dates[p.start.substring(0, 10)] = { ...p, ...{ menu: false } };
        });
        this.$forceUpdate(); // ugly but nothing else work
      });
    },
    createBooking(date) {
      this.dialogs.duplicate.id = null;
      var bookingReport = this.dates[date];
      post(`/api/booking-report/${this.booking.id}/report`, bookingReport).json(
        (data) => {
          this.dialogs.duplicate.id = data.newBookingId;
          this.dialogs.duplicate.show = true;
          this.dates[date].menu = false;
        }
      );
    },
    openDuplicate() {
      let route = this.$router.resolve({
        name: "Booking",
        params: { id: this.dialogs.duplicate.id },
      });
      window.open(route.href, "_blank");
      this.dialogs.duplicate.show = false;
    },
  },
};
</script>
