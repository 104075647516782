<template>
  <div>
    <v-subheader class="title mt-4" v-if="showTitle">
      Historique des évènements
    </v-subheader>
    <div :style="inDialog ? '': 'width:33%;'" v-if="events.length == 0">Aucun évènement à afficher</div>
    <div :style="inDialog ? '': 'width:33%;'" class="py-1" v-for="event in events" :key="event.id">
      <v-card class="card">
        <v-card-title class="d-flex flex-row justify-space-between pa-1">
          <v-row dense>
            <v-col cols="10" class="pa-2 mb-1">
              <div class="d-flex flex-column">
                <div class="d-flex flex-row">
                  <v-chip x-small :color="setColorLogType(event.logType)" text-color="white" label style="width:60px"
                          class="d-flex justify-center align-center ">
                    {{ $t(`crm.logType.${event.logType}`) }}
                  </v-chip>
                  <span class="caption ml-2 font-weight-medium">
                  {{ formatStringDate(event.creationDate) }}
                </span>
                </div>
                <span class="caption grey--text text--darken-2">par {{ event.commercialName }}</span>
              </div>
            </v-col>
            <v-col cols="2" class="caption pa-2">
              <v-chip x-small label outlined :color="event.demo ? 'green' : 'red'">
                <span class="font-weight-medium">Démo</span>
              </v-chip>
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="list-chips pa-2">
          <span class="caption font-weight-medium">@{{ event.recipientCommercialName }}:</span>

          <div class="reporting-zone px-2">
            <div v-html="event.report"></div>
          </div>
          <div class="mt-1">
            <span class="caption grey--text">Participants:</span>
            <v-chip v-for="agent in getListAgents(event)" color="#EEEEEE" :key="agent" x-small>{{ agent }}</v-chip>
          </div>
        </v-card-text>
        <v-divider class="my-1"></v-divider>
        <v-card-actions class="pa-2 d-flex flex-row justify-space-between">

          <v-chip label small :class="backGroundStatus(event.logStatus).bgAndText"
                  class="d-flex justify-center align-center"
                  style="width: 80px"
                  :style="'border:solid 1px ' + backGroundStatus(event.logStatus).border + '!important'">
            {{ $t(`logStatus.${event.logStatus}`) }}
          </v-chip>
          <smart-btn small @click.native="editEvent(event)">Editer</smart-btn>
        </v-card-actions>


      </v-card>
    </div>
  </div>
</template>
<script>
import {get} from "@/utils/api";
import {EventBus} from "@/components/commons/event-bus";

export default {
  components: {},
  name: "CrmAgencyEvents",
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
    inDialog: {type: Boolean, default: false},
    showTitle: {type: Boolean, default: true}
  },
  data() {
    return {
      events: []
    };
  },
  created() {
    this.getEvents()
  },
  computed: {},
  watch: {},
  methods: {
    getEvents() {
      const url = `/api/agency/events/${this.id}`;

      return get(url)
          .badRequest((br) => {
            console.warn("Error: ", br);
          })
          .json((data) => {
            this.events = data;
            this.events.forEach(event => {
              if (event.agentsIds != null) {
                event.agentsIds = event.agentsIds.split("; ").map(i => parseInt(i))
              }
            })
          });
    },
    openAutoLogin(id) {
      window.open(
          `${window.location.origin}/api/agency/agent/${id}/autolog`,
          "_blank"
      );
    },
    backGroundStatus(value) {
      switch (value) {
        case 'toreview':
          return {bgAndText: "blue lighten-4 blue--text text--darken-2", border: "#64B5F6"}
        case 'validated':
          return {bgAndText: "green lighten-4 green--text text--darken-2", border: "#81C784"}
        case 'pending':
          return {bgAndText: "yellow lighten-4 yellow--text text--darken-4", border: "#FDD835"}
      }
    },
    setColorLogType(value) {
      switch (value) {
        case 'visit' :
          return 'primary'
        case 'call':
          return 'green'
        case 'mail':
          return 'orange'
      }
    },
    formatStringDate(date) {
      const dateobj = this._parseDate(date, "dd/MM/yyyy HH:mm:ss")
      const day = this._formatDate(dateobj, "dd MMMM yyyy")
      const hour = this._formatDate(dateobj, "HH:mm")
      return `Le ${day} à ${hour} `
    },
    getListAgents(event) {
      if (event.agentsList != null) {
        return event.agentsList.split("; ");
      } else return []
    },
    editEvent(event) {
      EventBus.$emit("agency.event.edit", event)
    },

  },
};
</script>
<style scoped>
.reporting-zone {
  border: 1px dashed;
//box-shadow: inset 0 0 5px 0 lightgrey;
}

.card {
  background-color: white;
}

</style>

