<template>
  <layout :drawer="true" :drawerShow="_isDesktop()" :drawerWidth="300">
    <template slot="nav">
      <v-tabs>
        <v-tab :to="{name:'avis-moderation'}">Modération</v-tab>
        <v-tab :to="{name:'avis-hotels'}">Hotels</v-tab>
        <v-tab :to="{name:'avis-vols'}">Vols</v-tab>
      </v-tabs>
    </template>
    <template slot="drawer">
      <v-form v-on:submit.prevent="loadAverage">
        <v-row>
          <v-col cols="12" class="pb-0">
            <v-subheader light class="pa-0 mb-0 mx-0 form-subheader"
            >Date de séjour
            </v-subheader
            >
            <v-divider></v-divider>
          </v-col>
          <v-col class="pr-1" cols="6">
            <smart-picker
                v-model="form.start_date"
                :stopDate="form.start_date"
                :inputOpts="{
                label: $t('begin'),
                placeholder: ' ',
              }"
            ></smart-picker>
          </v-col>
          <v-col cols="6" class="pr-1">
            <smart-picker
                v-model="form.stop_date"
                :startDate="form.stop_date"
                :inputOpts="{
                label: $t('end'),
                placeholder: ' ',
              }"
            ></smart-picker>
          </v-col>
          <v-col cols="12" class="pb-0">
            <v-divider></v-divider>
          </v-col>
          <v-col class="pr-1 pb-0" cols="12">
            <v-select
                v-model="form.site_id"
                :items="data.sites"
                clearable
                item-text="name"
                item-value="id"
                label="Site"
                class="pa-1"
            ></v-select>
          </v-col>
          <v-col cols="12" class="pr-1 pt-0 pb-0">
            <v-select
                v-model="form.chain_id"
                :items="data.chains"
                clearable
                item-text="name"
                item-value="id"
                label="Chaines"
                class="pa-1"
            ></v-select>
          </v-col>
          <v-col cols="12" class="pr-1 pt-0 pb-0">
            <v-autocomplete
                dense
                v-model="form.hotels_id"
                :items="data.hotels"
                :rules="rules.nonEmpty()"
                :error="errorSelectHotel"
                hide-details
                hide-no-data
                multiple
                item-text="name"
                item-value="id"
                label="Hotel"
                class="pa-1 pb-4"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <smart-btn
            class="mt-3"
            type="submit"
            :loading="loading"
            block
            primary
        >Rechercher
        </smart-btn
        >
        <smart-btn block tertiary @click.native="clearSearchform()"
        >Réinitialiser la recherche
        </smart-btn
        >
      </v-form>
    </template>
    <template slot="content">
      <v-card v-if="result" class="pa-4 ma-4 blue-grey lighten-1 white--text">
        <v-row no-gutters style="row-gap: 16px">
          <v-col cols="6" class="d-flex flex-column justify-center align-center">
            <AverageItem title="Accueil" :count="result.countAccueil" :average="result.accueil" />
          </v-col>
          <v-col cols="6" class="d-flex flex-column justify-center align-center">
            <AverageItem title="Aménagements de l'hôtel" :count="result.countEquipement" :average="result.equipement" />
          </v-col>
          <v-col cols="6" class="d-flex flex-column justify-center align-center">
            <AverageItem title="Restauration" :count="result.countRestauration" :average="result.restauration" />
          </v-col>
          <v-col cols="6" class="d-flex flex-column justify-center align-center">
            <AverageItem title="Plage" :count="result.countPlage" :average="result.plage" />
          </v-col>
        </v-row>
      </v-card>
      <div v-if="result" class="ma-4" style="display: grid;gap: 16px;grid-template-columns: repeat(2, minmax(0, 1fr));">
        <div v-for="(item,i) in result.allPrestations" :key="i" style="grid-column: span 1 / span 1">
          <v-card class="white pa-4" style="display: grid;gap: 8px;grid-template-columns: repeat(3, minmax(0, 1fr));height: 100%">
            <div class="text-center font-weight-bold caption d-flex justify-center align-center" style="grid-column: span 1 / span 1;">
              {{item.name}}
            </div>
            <div class="text-center caption d-flex justify-center align-center" style="grid-column: span 1 / span 1;">
              <site-label :sitename="data.sites.find(x => x.id == item.site_id).name.replaceAll(' ','')" />
            </div>
            <div class="d-flex text-center align-center justify-end" style="grid-column: span 1 / span 1;">
              <v-icon
                  v-for="(star, i) in 4"
                  :key="i"
                  small
                  :color="i + 1 <= getAverage(item) ? 'yellow darken-2' : 'black'"
              >{{ setStars(i,getAverage(item)) }}</v-icon
              >
            </div>
            <div style="grid-column: span 3 / span 3; border-top: 1px solid lightgrey" class="d-flex flex-column pt-2">
              <p :class="item.comment ? 'mb-1' : 'mb-0'">Commentaire :</p>
              <p class="mb-0">{{item.comment ? item.comment : '-'}}</p>
            </div>
          </v-card>
        </div>
      </div>
    </template>
  </layout>
</template>
<script>
import Layout from "@/views/Layout";
import FormMixin from "@/components/mixins/FormMixin";
import {format, setDayOfYear} from "date-fns";
import {get, post} from "@/utils/api";
import AverageItem from "@/components/commons/AverageItem.vue";
import SiteLabel from "@/components/commons/SiteLabel.vue";

export default {
  name:"AverageHotel",
  components:{
    SiteLabel,
    AverageItem,
    Layout
  },
  mixins: [FormMixin],
  data(){
    return{
      data:{
        sites:[],
        chains:[],
        hotels: [],
      },
      form:{
        site_id:null,
        chain_id:null,
        hotels_id:[],
        start_date:format(setDayOfYear(new Date(),1),"dd/MM/yyyy"),
        stop_date:format(new Date(),"dd/MM/yyyy"),
      },
      errorSelectHotel: false,
      result: null,
      loading:false
    }
  },
  created() {
    this.init()
  },
  methods:{
    init(){
      this.getSite()
      this.getchains()
      this.getHotels()
    },
    getSite(){
      get("/api/search/sites")
          .json((sites) => {
            this.data.sites = sites
          })
    },
    getchains(){
      get("/api/search/chains")
          .json((chains) => {
            this.data.chains = chains
          })
    },
    getHotels(){
      get("/api/search/hotels")
          .json((hotels) => {
            this.data.hotels = hotels
          })
    },
    getHotelsByChain(){
      get(`/api/search/hotels/${this.form.chain_id}`)
          .json((hotels) => {
            this.data.hotels = hotels
          })
    },
    async loadAverage(){
      if(this.form.hotels_id.length > 0){
        this.loading = true
        this.errorSelectHotel = false
        await post("/api/average/hotel",this.form)
            .json((result) => {
              this.result = {
                ...result,
                accueil: result.accueil == null ? 0 : result.accueil,
                restauration: result.restauration == null ? 0 : result.restauration,
                equipement: result.equipement == null ? 0 : result.equipement,
                plage: result.plage == null ? 0 : result.plage
              }
            })
        this.loading = false
      } else {
        this.errorSelectHotel = true
      }
    },
    clearSearchform(){
      this.form = {
        site_id:null,
        chain_id:null,
        hotels_id:[],
        start_date:format(setDayOfYear(new Date(),1),"dd/MM/yyyy"),
        stop_date:format(new Date(),"dd/MM/yyyy"),
      }
    },
    setStars(i, star) {
      return i + 1 <= star ? "mdi-star" : "mdi-star-outline";
    },
    getAverage(item){
      const tab = [item.accueil, item.equipement, item.restauration, item.plage];
      const result = tab.filter(x => x != null);
      return result.reduce((acc, curr) => acc + curr, 0) / result.length;
    }
  },
  watch:{
    "form.chain_id": {
      deep: true,
      handler(newVal) {
        newVal != null ? this.getHotelsByChain() : this.getHotels()
        this.form.hotels_id = []
      }
    },
    "form.hotels_id": {
      deep: true,
      handler(newVal) {
        this.errorSelectHotel = newVal == null && newVal == []
      }
    },
  }
}
</script>
