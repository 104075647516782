<template>
  <smart-dialog v-model="dialog" :max-width="options.width" eager :title="title">
    <v-form v-model="valid" v-if="vouchers && vouchers.length > 0" ref="form" lazy-validation class>
      <v-expansion-panels light v-model="panel">
        <v-expansion-panel
          v-for="(voucher, idx) in vouchers"
          :key="idx"
          light
          :expand="expansionPanelExpand"
          focusable
        >
          <v-row>
            <v-col class="pa-2 ml-4" cols="10">
              <v-text-field v-model="voucher.customer" label="Nom du client" required></v-text-field>
            </v-col>
          </v-row>

          <v-expansion-panel-header>
            <template v-if="voucher.prestation.kind == 'RoomVoucherDto'">
              {{ voucher.prestation.hotel }}
              {{ voucher.prestation.room }}
            </template>
            <template
              v-if="voucher.prestation.kind == 'CarrentalVoucherDto'"
            >{{ voucher.prestation.name }}</template>
            <template v-if="voucher.prestation.kind == 'TransferVoucherDto'">
              {{ voucher.prestation.from }} ->
              {{ voucher.prestation.to }}
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content v-for="(voucher, idx) in vouchers" :key="idx">
            <v-card dense class="pa-2">
              <v-row class="pa-2">
                <template v-if="voucher.prestation.kind == 'RoomVoucherDto'">
                  <v-col class="pa-1" cols="12">
                    <v-text-field
                      v-model="voucher.prestation.hotel"
                      label="Hébergement"
                      :rules="rules.hotel"
                      required
                    ></v-text-field>
                  </v-col>

                  <v-col class="pa-1" cols="6">
                    <v-text-field
                      v-model="voucher.prestation.room"
                      label="Chambre"
                      :rules="rules.room"
                      required
                    ></v-text-field>
                  </v-col>

                  <v-col class="pa-1" cols="6">
                    <v-text-field
                      v-model="voucher.prestation.meal"
                      label="Meal Plan"
                      :rules="rules.meal"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col class="pa-1" cols="6">
                    <smart-picker
                      v-model="voucher.prestation.start"
                      :input-opts="{ rules: rules.start }"
                      format="dd/MM/yyyy HH:mm:ss"
                      :picker-opts="{ label: $t('startdate') }"
                    ></smart-picker>
                  </v-col>
                  <v-col class="pa-1" cols="6">
                    <smart-picker
                      v-model="voucher.prestation.stop"
                      :input-opts="{ rules: rules.stop }"
                      format="dd/MM/yyyy HH:mm:ss"
                      :picker-opts="{ label: $t('stopdate') }"
                    ></smart-picker>
                  </v-col>
                  <v-col class="pa-1" cols="6">
                    <v-text-field
                      v-model="voucher.prestation.checkin"
                      label="Hébergement disponible à partir de "
                      :rules="rules.checkin"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col class="pa-1" cols="6">
                    <v-text-field
                      v-model="voucher.prestation.checkout"
                      label="Hébergement à libérer avant "
                      :rules="rules.checkout"
                      required
                    ></v-text-field>
                  </v-col>
                </template>
                <template v-if="voucher.prestation.kind == 'CarrentalVoucherDto'">
                  <v-col class="pa-1" cols="12">
                    <v-text-field
                      v-model="voucher.prestation.name"
                      label="Prestation"
                      :rules="rules.name"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col class="pa-1" cols="12">
                    <v-text-field
                      v-model="voucher.prestation.description"
                      label="Prestation"
                      :rules="rules.description"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col class="pa-1" cols="6">
                    <smart-picker
                      v-model="voucher.prestation.start"
                      :input-opts="{ rules: rules.start }"
                      :picker-opts="{ label: $t('startdate') }"
                    ></smart-picker>
                  </v-col>
                  <v-col class="pa-1" cols="6">
                    <smart-picker
                      v-model="voucher.prestation.stop"
                      :input-opts="{ rules: rules.stop }"
                      :picker-opts="{ label: $t('stopdate') }"
                    ></smart-picker>
                  </v-col>
                </template>
                <template v-if="voucher.prestation.kind == 'TransferVoucherDto'">
                  <v-col class="pa-1" cols="6">
                    <v-text-field
                      v-model="voucher.prestation.from"
                      label="Prestation"
                      :rules="rules.name"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col class="pa-1" cols="6">
                    <v-text-field
                      v-model="voucher.prestation.to"
                      label="Prestation"
                      :rules="rules.description"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col class="pa-1" cols="6">
                    <smart-picker
                      v-model="voucher.prestation.start"
                      :input-opts="{ rules: rules.start }"
                      :picker-opts="{ label: $t('startdate') }"
                    ></smart-picker>
                  </v-col>
                </template>

                <v-col
                  class="pa-1"
                  cols="12"
                  v-for="(guest, i) in voucher.prestation.guests"
                  :key="'guest+' + i"
                >
                  <v-text-field
                    v-model="voucher.prestation.guests[i]"
                    :label="'Passager ' + i"
                    :rules="rules.guest"
                    required
                  ></v-text-field>
                </v-col>

                <v-col class="pa-1" cols="6" v-if="voucher.prestation.kind == 'RoomVoucherDto'">
                  <v-textarea
                    label="Offres spéciales (Client)"
                    v-model="voucher.prestation.clientOffers"
                  ></v-textarea>
                </v-col>
                <v-col class="pa-1" cols="6" v-if="voucher.prestation.kind == 'RoomVoucherDto'">
                  <v-textarea
                    label="Offres spéciales (Fournisseur)"
                    v-model="voucher.prestation.offers"
                  ></v-textarea>
                </v-col>

                <v-col class="pa-1" cols="6" v-if="voucher.prestation.kind == 'RoomVoucherDto'">
                  <v-textarea label="Extras (Client)" v-model="voucher.prestation.clientExtras"></v-textarea>
                </v-col>
                <v-col class="pa-1" cols="6" v-if="voucher.prestation.kind == 'RoomVoucherDto'">
                  <v-textarea label="Extras (Fournisseur)" v-model="voucher.prestation.extras"></v-textarea>
                </v-col>

                <v-col class="pa-1" cols="12">
                  <v-textarea
                    label="Prestation fournis par (nom)"
                    v-model="voucher.prestation.supplierName"
                  ></v-textarea>
                </v-col>
                <v-col class="pa-1" cols="12">
                  <v-textarea
                    label="Prestation fournis par (description)"
                    v-model="voucher.prestation.supplierDesc"
                  ></v-textarea>
                </v-col>
                <v-col class="pa-1" cols="12" v-if="voucher.onPlaceAgent">
                  <v-textarea label="Agent sur place" v-model="voucher.onPlaceAgent.name"></v-textarea>
                </v-col>
              </v-row>
            </v-card>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-card-actions class="actions-btn">
        <v-spacer></v-spacer>
        <smart-btn
          primray
          :disabled="!valid"
          :loading="loading"
          @click.native="agree"
        >{{ options.ok }}</smart-btn>
      </v-card-actions>
    </v-form>
  </smart-dialog>
</template>

<script>
import DialogMixin from "@/components/mixins/DialogMixin";
import { get, post } from "@/utils/api";
import GuestsMixin from "@/components/mixins/GuestsMixin";

export default {
  name: "EditVoucherData",
  components: {},
  mixins: [DialogMixin, GuestsMixin],
  data() {
    return {
      panel: [0],
      expansionPanelExpand: true,

      valid: true,
      loading: false,
      vouchers: null,
      form: null,
      extras: null,

      rules: {
        hotel: [(v) => !!v || "Nom d'hôtel requis"],
        name: [(v) => !!v || "Nom requise"],
        description: [(v) => !!v || "Description requise"],
        guest: [(v) => !!v || "Passager requis"],
        room: [(v) => !!v || "Nom de chambre requis"],
        meal: [(v) => !!v || "Meal requis"],
        start: [(v) => !!v || "Date de départ requis"],
        stop: [(v) => !!v || "Date de fin requis"],
        checkin: [(v) => !!v || "Checkin requis"],
        checkout: [(v) => !!v || "Checkout requis"],
      },
    };
  },
  created() { },
  watch: {},
  computed: {},
  methods: {
    getData(voucherids) {
      const futVouchers = voucherids.map((id) => {
        const url = `/api/document/voucher/${id}`;

        return get(url)
          .badRequest((err) => {
            console.error(err);
          })
          .json((data) => {
            return data;
          });
      });

      Promise.all(futVouchers).then((data) => {
        this.panel = data.map((v, i) => {
          return i;
        });
        this.vouchers = data;

        setTimeout(() => {
          //this.expansionPanelExpand = false
        }, 1500);
      });
    },
    open(title, message, options, prestationid) {
      this.getData(prestationid);

      this._open(title, message, options);

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },

    agree() {
      if (this.$refs.form.validate()) {
        this.loading = true;

        const time = " 00:00:00";
        const url = `/api/prestation/editVoucher`;

        const vouchers = this.vouchers.map((voucher) => {
          if (
            voucher.prestation.start &&
            voucher.prestation.start.length < 19
          ) {
            voucher.prestation.start = voucher.prestation.start + time;
          }
          if (voucher.prestation.stop && voucher.prestation.stop.length < 19) {
            voucher.prestation.stop = voucher.prestation.stop + time;
          }
          return voucher;
        });

        return post(url, vouchers)
          .badRequest((err) => {
            console.error(err);
          })
          .res((x) => {
            this.loading = false;
            this.resolve();
            this.dialog = false;
          });
      }
    },
    cancel() {
      this.resolve();
      this.dialog = false;
    },
  },
};
</script>

<style scoped>
</style>

