export default {
	data() {
		return {
			ageClassTypeItems: [
				{ label: this.$t('Adult'), value: 'Adult' },
				//{label: this.$t('Teen'), value: 'Teenager'},
				{ label: this.$t('Child'), value: 'Child' }
				//{label: this.$t('Baby'), value: 'Baby'}
			]

		}
	},
	computed: {

		adults() {
			return this.filterGuest(this.prestation.guests, true)
		},
		childs() {
			return this.filterGuest(this.prestation.guests, false)
		},
		nbAdults() {
			return this.adults.length
		},
		nbChilds() {
			return this.childs.length
		}

	},
	methods: {
		initials(guests) {
			return guests.map(g => this.initial(g.firstName, g.lastName)).join(', ')
		},
		initial(firstname, lastname) {
			if (firstname != null && lastname != null)
				return firstname.substr(0, 3).trim() + '. ' + lastname.substr(0, 1).trim()
			else return ""
		},
		countAdult(guests) {
			return this.countGuest(guests, true)
		},
		countChild(guests) {
			return this.countGuest(guests, false)
		},
		countGuest(guests, isAdult) {
			if (guests == null || guests.size == 0) return 0
			else {
				return guests.filter(
					(g) => isAdult ? g.ageClassType === 'Adult' : g.ageClassType !== 'Adult'
				).length
			}
		},
		filterGuest(guests, isAdult) {
			if (guests == null) return []
			else {
				return guests.filter(
					(g) => isAdult ? g.ageClassType === 'Adult' : g.ageClassType !== 'Adult'
				)
			}
		},
		buildTotalGuests(rooms) {
			var all = [];
			for (var i = 0; i < rooms.length; i++) {
				var room = rooms[i];
				var str = this.buildTotalGuestsForRoom(room.guests)
				all.push(str)
			}

			return all.join(' / ');
		},
		buildTotalGuestsForRoom(guests) {

			var nbAdults = 0;
			var nbChilds = 0;
			for (var n = 0; n < guests.length; n++) {
				if (guests[n].ageClassType === 'Adult') {
					nbAdults += 1
				} else {
					nbChilds += 1
				}
			}
			var str = []
			if (nbAdults > 0) {
				str.push(nbAdults + " AD")
			}

			if (nbChilds > 0) {
				str.push(nbChilds + " CH")
			}

			return str.join(", ");
		},
		slugify(text, replaceTxt) {
			const replace = replaceTxt || ''
			return text
				.toString()                           // Cast to string (optional)
				.normalize('NFKD')                    // The normalize() using NFKD method returns the Unicode Normalization Form of a given string.
				.toLowerCase()                        // Convert the string to lowercase letters
				.trim()                               // Remove whitespace from both sides of a string (optional)
				.replace(/\s+/g, replace)             // Replace spaces with 
				.replace(/[^\w\-]+/g, replace)             // Remove all non-word chars
				.replace(/[^a-z0-9 ]/g, replace)
				.replace(/\-\-+/g, replace);          // Replace multiple - with single -

		}

	}
}
