<template>
  <WidgetTile :item="data" v-if="!loading" :title="conf.title"/>
</template>
<script>
import {post} from "@/utils/api";
import WidgetTile from "@/components/commons/widgets/WidgetTile.vue";
import {EventBus} from "@/components/commons/event-bus";

export default {
  name: "RatioHotelFlightTile",
  components: {WidgetTile},
  props: {
    form: {type: Object, required: true},
    conf: {type: Object, required: false, default: null}

  },
  data() {
    return {
      url: `/api/widget/dashboard/ratio/booking`,
      data: {},
      loading: true,
    }
  },
  computed: {},
  created() {
    EventBus.$on('searchDashboard', () => this.init());
    this.init();
  },
  methods: {
    init() {
      const searchForm = this._formatPayloadWidgetDashboard(this.conf,this.form)
      let tile = post(this.url, searchForm, {"Content-Type": "application/json"})
          .json((r) => {
            this.data = {
              name: r.yearN.name,
              value: r.yearN.value,
              old: r.yearPast.value,
              isPrice: false,
              isPercent: true
            }

          })
      Promise.all([tile]).then(() => {
        this.loading = false
      })
    }
  },
}
</script>