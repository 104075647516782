export default {
	data() {
		return {}
	},
	methods: {
		renderIcon(s) {
			switch (s) {
				case 'POI':
					return 'mdi-flag-triangle';
				case 'HOTEL':
					return 'hotel';
				case 'train_station':
					return 'train';
				case 'airport':
				case 'medium_airport':
				case 'small_airport' :
				case 'large_airport':
					return 'local_airport';
				case 'city':
					return 'location_city';
				default:
					return ''

			}
		}

	}
}