<template>
  <div class="grey lighten-3">
    <v-toolbar>
      <v-container fluid class="pa-0">
        <v-row dense>
          <v-col cols="3" class="px-3" align-self="center">
            <v-autocomplete
              v-model="ctx.from"
              :items="points"
              hide-no-data
              item-text="name"
              item-value="(item) => (item.pointType + item.id)"
              return-object
              dense
              hide-details
              :label="$t('departure')"
              :search-input.sync="searchTermFrom"
              :error="isFromError"
              :append-icon="ctx.from ? '' : 'search'"
              :clearable="ctx.from != ''"
              :filter="filterPoints"
            >
              <template #item="data">
                <v-list-item-action>
                  <v-icon>{{ renderIcon(data.item.pointType) }}</v-icon>
                </v-list-item-action>
                <v-list-item-content
                  v-text="data.item.name"
                ></v-list-item-content>
              </template>
              <template slot="selection" slot-scope="data">
                <v-icon>{{ renderIcon(data.item.pointType) }}</v-icon>
                <span class="ml-2">{{ data.item.name }}</span>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="3" class="px-3" align-self="center">
            <v-autocomplete
              v-model="ctx.to"
              :items="points"
              hide-no-data
              item-text="name"
              item-value="(item) => (item.pointType + item.id)"
              return-object
              dense
              hide-details
              :label="$t('arrival')"
              :search-input.sync="searchTermTo"
              :error="isToError"
              :append-icon="ctx.to ? '' : 'search'"
              :clearable="ctx.to != ''"
              :filter="filterPoints"
            >
              <template #item="data">
                <v-list-item-action>
                  <v-icon>{{ renderIcon(data.item.pointType) }}</v-icon>
                </v-list-item-action>
                <v-list-item-content
                  v-text="data.item.name"
                ></v-list-item-content>
              </template>
              <template slot="selection" slot-scope="data">
                <v-icon>{{ renderIcon(data.item.pointType) }}</v-icon>
                <span class="ml-2">{{ data.item.name }}</span>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="2" class="px-3" align-self="center">
            <booking-guest-repartition
              :guests="booking.globalGuests"
              v-model="ctx.guests"
            ></booking-guest-repartition>
          </v-col>
          <v-col cols="1" class="px-3" align-self="center">
            <smart-picker
              v-model="ctx.start"
              :next-picker="$refs.bookingStopDatePicker"
              format="dd/MM/yyyy"
              :startDate="ctx.start"
              :inputOpts="{ label: $t('begin') }"
            ></smart-picker>
          </v-col>
          <v-col cols="1" class="px-3" align-self="center">
            <smart-picker
              v-model="ctx.stop"
              ref="bookingStopDatePicker"
              :startDate="ctx.stop"
              :min="ctx.start"
              :inputOpts="{ label: $t('end') }"
            ></smart-picker>
          </v-col>

          <v-col cols="1" class="px-3 text-right" align-self="center">
            <v-btn @click.native="search" :loading="loading">
              <v-icon>search</v-icon>
              {{ $t("search") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-toolbar>

    <v-container fluid>
      <v-row justify="center">
        <v-col class="text-center" cols="12" v-show="loading">
          <v-progress-circular
            v-show="loading"
            indeterminate
            color="primary"
            :width="3"
            :size="70"
          ></v-progress-circular>
        </v-col>
        <v-col
          cols="12"
          class="pl-2 pr-2 text-center"
          v-show="!loading"
          v-if="carrentals.length == 0"
        >
          <span v-if="hadSearchedOnce">{{ $t("no-results") }}</span>
          <span v-else>{{ $t("please-search") }}</span>
        </v-col>
        <v-col cols="8" class="pl-2 pr-2" v-show="!loading">
          <carrental-price
            class="pa-2 ma-2"
            v-for="carrental in carrentals"
            :key="carrental.id"
            :carrentalprice="carrental"
            :carrent-selected="carrentSelected"
            :bookingid="booking.id"
            :ctx="ctx"
          ></carrental-price>
        </v-col>

        <!---------- prestation recap ---------->
        <v-col class="mt-1 pa-1" cols="2" v-if="sumPrestation">
          <v-card xs9>
            <booking-drawer-content
              v-if="booking"
              :booking="booking"
            ></booking-drawer-content>
          </v-card>
        </v-col>
        <!---------- end prestation recap ---------->
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { get, post } from "@/utils/api";
import { EventBus } from "@/components/commons/event-bus";
import RenderIconMixin from "@/components/mixins/RenderIconMixin";
import GuestsMixin from "@/components/mixins/GuestsMixin";
import BookingGuestRepartition from "@/components/commons/BookingGuestRepartition";
import CarrentalPrice from "@/components/booking/carrental/CarrentalPrice";

import BookingDrawerContent from "../../commons/BookingDrawerContent";

export default {
  name: "AddCarrental",
  components: { CarrentalPrice, BookingGuestRepartition, BookingDrawerContent },
  mixins: [RenderIconMixin, GuestsMixin],
  props: {
    booking: {
      type: Object,
      required: true,
    },
    carrentSelected: {
      type: Object,
      required: false,
    },
    sumPrestation: { required: true },
    lastPrestationDate: { required: true },
  },
  data: function () {
    return {
      loading: false,
      hadSearchedOnce: false,
      selectIndex: 0,

      isFromError: false,
      isToError: false,
      isFromDateError: false,
      isToDateError: false,

      points: [],
      carrentals: [],

      searchTermFrom: null,
      searchTermTo: null,
      ctx: {
        start: null,
        stop: null,
        from: null,
        to: null,
        guests: this.booking.globalGuests,
        vehicles: [],
        debug: false,
      },
    };
  },
  created() {
    this.bindCtx();
    this.ctx.start = this.startRentCarDate;
    this.ctx.stop = this.ctx.start;

    get("/api/prestation/add/search-pickups")
      .badRequest((err) => {
        EventBus.$emit(
          "toaster-msg",
          JSON.parse(err.null).errors.map((e) => JSON.parse(e))
        );
      })
      .json((data) => {
        this.points = data;
      })
      .then(() => {
        if (this.carrentSelected) {
          this.ctx.from = this.points.find(
            (p) => parseInt(p.id) == this.carrentSelected.fromId
          );
          this.ctx.to = this.points.find(
            (p) => parseInt(p.id) == this.carrentSelected.toId
          );
          this.ctx.start = this._parseAndFormatDate(
            this.carrentSelected.start,
            this.datePatternConfig.serverLocalDateTime,
            this.datePatternConfig.serverLocalDate
          );
          this.ctx.stop = this._parseAndFormatDate(
            this.carrentSelected.stop,
            this.datePatternConfig.serverLocalDateTime,
            this.datePatternConfig.serverLocalDate
          );
          this.$nextTick(() => {
            this.doSearch();
          });
        }
      });
  },
  watch: {
    "ctx.from"(newVal) {
      this.ctx.to = newVal;
    },
    "ctx.to"(newVal) {
      this.ctx.from = newVal;
    },
    "ctx.start"(newVal) {
      this.isDateError = newVal == null || newVal == "";
      this.ctx.stop = newVal;
    },
    "ctx.stop"(newVal) {
      this.isDateError = newVal == null || newVal == "";
    },
  },
  computed: {
    startRentCarDate: function () {
      if (
        typeof this.lastPrestationDate !== "undefined" &&
        this.lastPrestationDate !== null
      ) {
        return this.lastPrestationDate.substring(0, 10);
      } else if (this.lastPrestationDate === undefined) {
        if (
          this.booking &&
          this.booking.prestations &&
          this.booking.prestations.length == 0
        ) {
          return this.booking.bookingDate.substring(0, 10);
        } else if (
          this.booking &&
          this.booking.prestations &&
          this.booking.prestations.length > 0
        ) {
          let pIndex = this.booking.prestations.findIndex((prestation) => {
            return (prestation.kind = "RoomPrestation");
          });
          return this.booking.prestations[pIndex].start.substring(0, 10);
        }
      }

      return null;
    },
  },
  methods: {
    search() {
      this.isFromError = this.ctx.from === null;
      this.isToError = this.ctx.to === null;
      this.isFromDateError = this.ctx.start === null;
      this.isToDateError = this.ctx.stop === null;

      if (
        this.isFromError ||
        this.isToError ||
        this.isFromDateError ||
        this.isToDateError
      ) {
        EventBus.$emit("toaster-msg", this.$t("error-form"));
      } else this.doSearch();
    },
    doSearch() {
      this.hadSearchedOnce = true;
      this.loading = true;

      post("/api/prestation/add/search-carrental", this.ctx)
        .badRequest((err) => {
          EventBus.$emit("toaster-msg", JSON.parse(err.null).errors.join(","));
        })
        .json((data) => {
          const withOptIn = data.map((d) => {
            return {
              ...d.car,
              ...{
                options: d.car.options.map((o) => {
                  o.checked = true;
                  return o;
                }),
              },
              hash: d.hash,
            };
          });
          this.carrentals = withOptIn;
        })
        .then(() => {
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    bindCtx() {
      this.ctx = {
        ...this.ctx,
        priceClassTypeId: this.booking.context.priceClassType.id,
        currency: {
          code: this.booking.currency,
        },
      };
    },
    filterPoints(item, query, itemText) {
      const querylc = query.toLocaleLowerCase();

      return (
        itemText.toLocaleLowerCase().indexOf(querylc) > -1 ||
        item.destination.toLocaleLowerCase().indexOf(querylc) > -1
      );
    },
  },
};
</script>

<style scoped></style>
