var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{ref:"vCardRef",staticClass:"pa-4",staticStyle:{"height":"100%","width":"100%","overflow":"hidden"},attrs:{"elevation":1}},[_c('div',{staticClass:"d-flex flex-row justify-space-between mb-10"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.conf.title))]),_c('div',[_c('span',{class:_vm.actualView == 'day'
              ? 'font-weight-black pointer-event-none'
              : 'text-decoration-underline cursor-pointer',on:{"click":function($event){return _vm.viewBy('day')}}},[_vm._v("Jour")]),_vm._v(" | "),_c('span',{class:_vm.actualView == 'week'
              ? 'font-weight-black pointer-event-none'
              : 'text-decoration-underline cursor-pointer',on:{"click":function($event){return _vm.viewBy('week')}}},[_vm._v("Semaine")]),_vm._v(" | "),_c('span',{class:_vm.actualView == 'month'
            ? 'font-weight-black pointer-event-none'
            : 'text-decoration-underline cursor-pointer',on:{"click":function($event){return _vm.viewBy('month')}}},[_vm._v("Mois")])])]),_c('span',{staticClass:"ml-4 text-decoration-underline"},[_vm._v("Nombre de dossiers")]),(_vm.series[0].length > 0)?_c('apexchart',{ref:"apexchartsOne",attrs:{"type":"area","height":_vm.series[0].length > 0 ? _vm.chartHeight : 0,"options":_vm.options,"series":_vm.series[0]}}):_vm._e(),_c('span',{staticClass:"ml-4 text-decoration-underline"},[_vm._v("Chiffre d'affaire")]),(_vm.series[1].length > 0)?_c('apexchart',{ref:"apexchartsTwo",attrs:{"type":"area","height":_vm.series[1].length > 0 ? _vm.chartHeight : 0,"options":_vm.options2,"series":_vm.series[1]}}):_vm._e(),_c('span',{staticClass:"ml-4 text-decoration-underline"},[_vm._v("Marge Brute")]),(_vm.series[2].length > 0)?_c('apexchart',{ref:"apexchartsThree",attrs:{"type":"area","id":"apexchartsThree","height":_vm.series[2].length > 0 ? _vm.chartHeight : 0,"options":_vm.options3,"series":_vm.series[2]}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }