/**
 *
 */

export default {
	data() {
		return {}
	},
	methods: {

		flyStopsCodes(segments, arrivalDateKey, departureDateKey, toCodeKey) {
			const heads = segments.slice(0, (segments.length - 1))
			return heads.map((segment, index) => {
				const start = segment.arrivalDate || segment[arrivalDateKey]
				const stop = segments[(index + 1)].departureDate || segments[(index + 1)][departureDateKey]

				return (segment[toCodeKey] || segment.to.code) + " " +
					this._durationFormatted(
						this._parseDate(start, this.datePatternConfig.serverLocalDateTime),
						this._parseDate(stop, this.datePatternConfig.serverLocalDateTime),
						this._datePattern.time
					);
			}).join(' - ')
		},


		renderSupDays(fly) {
			const nb = this._nbNights(this._parseDate(fly.departureDate), this._parseDate(fly.arrivalDate))
			if (nb > 0) return "+" + nb; else ""
		},

		isPriceClassWithFlight(priceClass){
			return [16,15,19].includes(priceClass.id)
		},
	}
}
