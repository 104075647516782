<template>
  <layout drawerShow :drawerWidth="400">
    <template slot="nav">
      <v-tabs>
        <v-tab :to="{name:'prestations-list'}">Liste des Prestations</v-tab>
      </v-tabs>
    </template>

    <template slot="drawer">
      <v-container fluid class="pa-0">
        <v-row no-gutters>
          <v-col cols="12">
            <v-subheader light class="pa-0 mt-1 mb-0 mx-0 form-subheader">Filtres</v-subheader>
            <v-divider></v-divider>
          </v-col>
          <v-col cols="12" class="pr-1">
            <v-select
              clearable
              v-model.number="searchCtx.destinationId"
              :items="listDestinations"
              item-value="id"
              item-text="name"
              label="Destination"
              hide-details
            ></v-select>
          </v-col>
          <v-col cols="12" class="pr-1">
            <v-autocomplete
              v-model.number="searchCtx.supplierAccountingId"
              :items="listSuppliers"
              item-value="id"
              item-text="name"
              label="Fournisseur"
              clearable
              hide-details
            ></v-autocomplete>
          </v-col>
          <v-col cols="6" class="pr-1">
            <v-select
              clearable
              v-model="searchCtx.withSupplierInvoice"
              :items="supplierInvoices"
              item-value="value"
              item-text="text"
              label="Facture fournisseur"
              hide-details
            ></v-select>
          </v-col>
          <v-col cols="6" class="pr-1">
            <v-select
              clearable
              v-model="searchCtx.widthCustomerInvoice"
              :items="customerInvoices"
              item-value="value"
              item-text="text"
              label="Facture client"
              hide-details
            ></v-select>
          </v-col>

          <v-col cols="12" class="pr-1">
            <v-select
              clearable
              v-model="searchCtx.paid"
              :items="booking"
              item-value="value"
              item-text="text"
              label="Dossier client"
              hide-details
            ></v-select>
          </v-col>

          <v-col cols="12">
            <v-subheader light class="pa-0 mt-5 mb-0 mx-0 form-subheader">Date de Réservation</v-subheader>
            <v-divider></v-divider>
          </v-col>
          <v-col cols="6" class="pr-1">
            <smart-picker
              v-model="searchCtx.bookingDateStart"
              :stopDate="searchCtx.bookingDateStop"
              :inputOpts="{placeholder:' '}"
            ></smart-picker>
          </v-col>
          <v-col cols="6" class="pr-1">
            <smart-picker
              v-model="searchCtx.bookingDateStop"
              :startDate="searchCtx.bookingDateStart"
              :inputOpts="{placeholder:' '}"
            ></smart-picker>
          </v-col>

          <v-col cols="12">
            <v-subheader light class="pa-0 mt-5 mb-0 mx-0 form-subheader">Date de la prestation</v-subheader>
            <v-divider></v-divider>
          </v-col>
          <v-col cols="6" class="pr-1">
            <smart-picker
              v-model="searchCtx.prestationDateStart"
              label="Date de début"
              :stopDate="searchCtx.prestationDateStop"
              :inputOpts="{placeholder:' '}"
            ></smart-picker>
          </v-col>
          <v-col cols="6" class="pr-1">
            <smart-picker
              v-model="searchCtx.prestationDateStop"
              label="Date de fin"
              :startDate="searchCtx.prestationDateStart"
              :inputOpts="{placeholder:' '}"
            ></smart-picker>
          </v-col>

          <v-col cols="12" class="text-right">
            <v-divider class="mb-3"></v-divider>
            <v-btn class="primary" :loading="loading" @click="search">
              <v-icon>search</v-icon>Rechercher
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </template>

    <template slot="content">
      <v-container>
        <v-card>
          <v-data-table
            :headers="synthesisHeaders"
            :items="synthesis"
            :loading="loading"
            no-data-text="-"
            hide-default-footer
            hide-default-header
            :footer-props="{'items-per-page-options':listing.rowsPerPageItems, 'items-per-page-text':'Lignes par page'}"
            class="tbl"
          >
            <template #header="{props: {headers}}">
              <thead class="v-data-table-header">
                <tr class="fakeheaderline">
                  <th colspan="4"></th>
                  <th colspan="3" class="bl">Avec Fact. fournisseur</th>
                  <th colspan="3" class="bl">Sans Fact. fournisseur</th>
                </tr>
                <tr>
                  <th
                    v-for="header in headers"
                    :key="header.value"
                    :class="['column sortable', pagination.descending ? 'desc' : 'asc', header.value === pagination.sortBy ? 'active' : '', header.value=='price' || header.value=='priceWithSupplierInvoice' ? 'bl' : '', 'text-xs-'+ (header.align || 'left')]"
                  >{{ header.text }}</th>
                </tr>
              </thead>
            </template>
            <template #item.priceWithSupplierInvoice="{value}">{{ value | currency('EUR')}}</template>
            <template #item.purchaseWithSupplierInvoice="{value}">{{ value | currency('EUR')}}</template>
            <template #item.markupWithSupplierInvoice="{value}">{{ value | percent}}</template>
            <template #item.price="{value}">{{ value | currency('EUR')}}</template>
            <template #item.purchase="{value}">{{ value | currency('EUR')}}</template>
            <template #item.markup="{value}">{{ value | percent}}</template>
          </v-data-table>
        </v-card>
        <v-divider class="my-4"></v-divider>
        <v-card>
          <v-data-table
            :headers="headers"
            :items="items"
            :loading="loading"
            :footer-props="{'items-per-page-options':listing.rowsPerPageItems, 'items-per-page-text':'Lignes par page'}"
            no-data-text="-"
            class="tbl prestation-list"
          >
            <template #item.siteName="{item, value}">
              {{ value }}
              <div class="grey--text darken-3">{{ item.bookingDate }}</div>
            </template>
            <template #item.prestationType="{item, value}">
              {{ value }}
              <div class="grey--text darken-3">{{ item.startDate }}</div>
            </template>
            <template #item.price="{value}">{{value | currency('EUR')}}</template>
            <template #item.purchase="{value}">{{value | currency('EUR')}}</template>
            <template #item.euroPurchase="{value}">{{value | currency('EUR')}}</template>
            <template #item.realEuroPurchase="{value}">{{value | currency('EUR')}}</template>
            <template #item.markup="{value}">{{value | percent}}</template>
            <template #item.realMarkup="{value}">{{value | percent}}</template>
            <!-- <template slot="items" slot-scope="props">
							<tr>
								<td>{{ props.item.bookingId }}</td>
								<td>
									{{ props.item.siteName }}
									<div class="grey--text darken-3">{{ props.item.bookingDate }}</div>
								</td>
								<td>{{ props.item.destinationName }}</td>
								<td>{{ props.item.supplierAccountingName }}</td>
								<td>
									{{ props.item.prestationType }}
									<div class="grey--text darken-3">{{ props.item.startDate }}</div>
								</td>
								<td class="text-right">{{ props.item.price | currency(props.item.currency)}}</td>
								<td class="text-right">{{ props.item.purchase | currency(props.item.purchaseCurrency)}}</td>
								<td class="text-right">{{ props.item.euroPurchase | currency('EUR')}}</td>
								<td class="text-right">{{ props.item.markup | percent }}</td>
								<td class="text-right">{{ props.item.realEuroPurchase | currency('EUR')}}</td>
								<td class="text-right">{{ props.item.diff }}</td>
								<td class="text-right">{{ props.item.realMarkup | percent }}</td>
							</tr>
						</template>
            -->
          </v-data-table>
        </v-card>
      </v-container>
    </template>
  </layout>
</template>

<script>
import { get, post } from "@/utils/api";
import Layout from "@/views/Layout";
import AccountingNav from "@/views/accounting/AccountingNav";
import { EventBus } from "@/components/commons/event-bus";

export default {
  name: "PrestationList",
  components: {
    Layout,
    AccountingNav,
  },
  mixins: [],
  props: {},
  data: () => ({
    loading: false,
    listDestinations: [],
    listSuppliers: [],
    listStatus: [],
    supplierInvoices: [
      { value: true, text: "Avec facture" },
      { value: false, text: "Sans facture" },
    ],
    customerInvoices: [
      { value: true, text: "Avec facture" },
      { value: false, text: "Sans facture" },
    ],
    booking: [
      { value: true, text: "Soldé" },
      { value: false, text: "Non soldé" },
    ],
    searchCtx: {
      destinationId: null,
      supplierAccountingId: null,
      paid: null,
      widthSupplierInvoice: null,
      widthCustomerInvoice: null,
      bookingDateStart: null,
      bookingDateStop: null,
      prestationDateStart: null,
      prestationDateStop: null,
    },
    headers: [
      { text: "#", value: "bookingId", width: "55px" },
      { text: "Source", value: "siteName", width: "70px" },
      { text: "Destination", value: "destinationName", width: "100px" },
      { text: "Fournisseur", value: "supplierAccountingName" },
      { text: "Type", value: "prestationType" },
      { text: "Vente", value: "price" },
      { text: "Achat prev", value: "purchase" },
      { text: "Achat prev (€)", value: "euroPurchase" },
      { text: "Marge prev", value: "markup" },
      { text: "Achat réel", value: "realEuroPurchase" },
      { text: "+/-", value: "realDiff" },
      { text: "Marge réelle", value: "realMarkup" },
    ],
    items: [],
    listing: {
      rowsPerPageItems: [{ text: "Tous", value: -1 }, 50, 200],
    },
    pagination: {
      sortBy: "bookingDate",
    },
    synthesisHeaders: [
      { text: "Destination", value: "destinationName", align: "left" },
      { text: "Site", value: "siteName", align: "left" },
      { text: "Année", value: "year", align: "left" },
      { text: "Mois", value: "month", align: "left" },
      { text: "Vente", value: "priceWithSupplierInvoice", align: "right" },
      { text: "Achat", value: "purchaseWithSupplierInvoice", align: "right" },
      { text: "Markup", value: "markupWithSupplierInvoice", align: "right" },
      { text: "Vente", value: "price", align: "right" },
      { text: "Achat", value: "purchase", align: "right" },
      { text: "Markup", value: "markup", align: "right" },
    ],
    synthesis: [],
  }),
  //beforeCreate(){},
  created() {
    this.init();
  },
  //beforeMount(){},
  //mounted(){},
  //beforeDestroy(){},
  //destroyed(){},
  //watch:{},
  //computed:{},
  methods: {
    init() {
      this.searchCtx.bookingDateStart = this._formatDate(
        new Date(),
        this._datePattern.localDate
      );
      this.searchCtx.bookingDateStop = this._formatDate(
        new Date(),
        this._datePattern.localDate
      );

      get("/api/accounting/list-supplier").json(
        (data) => (this.listSuppliers = data)
      );

      get("/api/accounting/list-destination").json(
        (data) => (this.listDestinations = data)
      );

      this.search();
    },
    search() {
      this.loading = true;
      this.items = [];
      post("/api/accounting/prestations/list", this.searchCtx)
        .json((data) => {
          this.items = data.prestations;
          this.synthesis = data.synthesis;
        })
        .catch((e) => {
          EventBus.$emit("toast", {
            color: "red",
            text: "Une erreur est survenue",
            exception: e,
          });
        })
        .then(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.prestation-list >>> th {
  word-break: break-word;
  white-space: initial;
  padding: 0 10px !important;
}

.prestation-list >>> td {
  padding: 0 10px !important;
}

.bl {
  border-left: solid 1px #e0e0e0;
}

.br {
  border-right: solid 1px #e0e0e0;
}

.tbl >>> thead tr.fakeheaderline {
  height: 20px !important;
  border-bottom: 0;
}
.tbl >>> thead tr.fakeheaderline th {
  text-align: center !important;
}
</style>
