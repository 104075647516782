<template>
	<widget-container
		:url="url"
		headlineclass
		cardclass="ma-0 pa-1"
		color="transparent"
		:bus="bus"
		:dark="false"
		:flat="false"
		:alwaysrender="true"
		@ready="setData"
	>
		<template slot="content">
			<v-card flat>
				<v-subheader>Parts de marché</v-subheader>
				<apexchart
					v-if="flightCompagniesChart.series.length > 0"
					ref="apexcharts"
					type="bar"
					height="350"
					:options="flightCompagniesChart.chartOptions"
					:series="flightCompagniesChart.series"
				></apexchart>
			</v-card>
		</template>
	</widget-container>
</template>

<script>
import WidgetContainer from "./WidgetContainer";

export default {
	name: "WidgetFlightAirlineCharts",
	components: { WidgetContainer },
	mixins: [],
	props: {
		bus: {
			type: Object,
			required: true
		},
		url: {
			type: String,
			required: false,
			default: '/api/widget/flights/airlines/marketShares'
		}
	},
	data() {
		return {
			loading: false,
			flightCompagniesChart: {
				series: [],
				chartOptions: {

					tooltip: {
						y: { formatter: (value) => { return value + " Passagers" } },
					},
					chart: {
						type: 'bar',
						height: 'auto',
						stacked: true,
						toolbar: {
							show: true,
							tools: {
								download: true,
								selection: false,
								zoom: false,
								zoomin: false,
								zoomout: false,
								pan: false,
								reset: false
							}
						},
						zoom: {
							enabled: true
						}
					},
					xaxis: {
						type: 'dates',
						categories: [],
					},
					/*yaxis: {
						title: {
							text: 'Parts de marché',
						},
						show: true,
						showAlways: true,
						min: 0,
						max: 100,
					},*/
				},
				responsive: [{
					breakpoint: 40,
					options: {
						legend: {
							position: 'bottom',
							offsetX: -10,
							offsetY: 0
						}
					}
				}],
				plotOptions: {
					bar: {
						horizontal: false,
						borderRadius: 20
					},
				},
				legend: {
					position: 'right',
					offsetY: 40
				},
				fill: {
					opacity: 1
				},
			},
		}
	},
	created() {
		this.$nextTick(() => {
			this.bus.$on('search', (searchform) => {
				this.loading = true
			});
		})
	},
	destroyed() {
		this.bus.$off('search')
	},
	methods: {
		setData(data) {
			const categories = [...data.caByDate.map(ca => {
				return ca.date
			}
			).sort((dateA, dateB) => {
				return this._parseDate(dateA, "MM/yyyy") - this._parseDate(dateB, "MM/yyyy")
			})]

			this.flightCompagniesChart.chartOptions.xaxis.categories = categories

			const companiesCa = data.allCompanies.map(company => {
				let marketShares = []
				this.flightCompagniesChart.chartOptions.xaxis.categories.forEach(() => marketShares.push(0))

				data.caByDate.forEach(caData => {

					const companyData = caData.caPerCompanies.find(companyItem => companyItem.company == company)

					if (companyData) {
						let index = this.flightCompagniesChart.chartOptions.xaxis.categories.findIndex(date => date == caData.date)
						marketShares.splice(index, 1, companyData.pax)
					}
				})

				return {
					name: company,
					data: marketShares
				}
			})

			//this.flightCompagniesChart.chartOptions.yaxis.max = data.totalPax;
			this.flightCompagniesChart.series = companiesCa

			//after first chart print we need to refresh series
			if (this.$refs.apexcharts) this.$refs.apexcharts.refresh()

		}
	}
}
</script>

<style scoped>
</style>