<template>
  <layout :drawer="true" :drawerShow="_isDesktop()" :drawerWidth="300">
    <template slot="nav">
      <v-tabs>
        <v-tab :to="{name:'avis-moderation'}">Modération</v-tab>
        <v-tab :to="{name:'avis-hotels'}">Hotels</v-tab>
        <v-tab :to="{name:'avis-vols'}">Vols</v-tab>
      </v-tabs>
    </template>
    <template slot="drawer">
      <v-form v-on:submit.prevent="loadData">
        <v-row>
          <v-col cols="12">
            <v-subheader light class="pa-0 mb-0 mx-0 form-subheader"
            >Date de création
            </v-subheader
            >
            <v-divider></v-divider>
          </v-col>
          <v-col class="pr-1" cols="6">
            <smart-picker
                v-model="searchform.startCreateDate"
                :stopDate="searchform.stopCreateDate"
                :inputOpts="{
                label: $t('begin'),
                clearable: true,
                placeholder: ' ',
              }"
            ></smart-picker>
          </v-col>
          <v-col cols="6" class="pr-1">
            <smart-picker
                v-model="searchform.stopCreateDate"
                :startDate="searchform.startCreateDate"
                :inputOpts="{
                label: $t('end'),
                clearable: true,
                placeholder: ' ',
              }"
            ></smart-picker>
          </v-col>
          <v-col cols="12">
            <v-subheader light class="pa-0 mb-0 mx-0 form-subheader"
            >Statut
            </v-subheader
            >
            <v-divider></v-divider>
            <v-col cols="12" class="pl-0">
              <v-checkbox
                  v-for="(bs, index) in status"
                  :key="'bs-' + index"
                  v-model="searchform.status"
                  :label="bs.label"
                  :value="bs.value"
                  :class="'pt-1 ' + (index == 0 ? 'mt-2' : 'mt-0')"
                  height="1"
              >
              </v-checkbox>
            </v-col>
          </v-col>
        </v-row>
        <smart-btn
            class="mt-3"
            type="submit"
            :loading="listing.loading"
            block
            primary
        >Rechercher
        </smart-btn
        >
        <smart-btn block tertiary @click.native="clearSearchform()"
        >Réinitialiser la recherche
        </smart-btn
        >
      </v-form>
    </template>
    <template slot="content">
      <v-container fluid>
        <v-card fluid style="max-width: 1024px; margin: auto">
          <v-data-table
              :headers="listing.headers"
              :items="listing.items"
              :loading="listing.loading"
              class="tbl"
              :footer-props="{
                'items-per-page-options': listing.rowsPerPageItems,
                'items-per-page-text': 'Lignes par page',
              }"
              sort-by="creationDate" :sort-desc="false"
              hide-default-footer
              no-data-text="Aucun avis"
          >
            <template #item.site="{ item }">
              <site-label
                  :sitename="$t('virtual-site.site.' + item.siteId)"
              ></site-label>
            </template>
            <template #item.status="{ item }">
              <div class="d-flex justify-start">
                <v-chip
                    class="mx-1"
                    :color="item.moderationStatus.color"
                    :style="{
                    border:
                      'solid 1px ' +
                      item.moderationStatus.border +
                      '!important',
                  }"
                    small
                    label
                >
                  <span
                      :style="
                      'color : ' + item.moderationStatus.border + '!important'
                    "
                      class="d-flex align-center"
                  >
                    {{ item.moderationStatus.text }}
                  </span>
                </v-chip>
                <v-chip
                    v-if="item.dataPrestations.length > 0"
                    class="detailsBtn"
                    color="blue"
                    label
                    small
                    outlined
                    @click.native="item.details = true"
                >Détails
                </v-chip>
                <smart-dialog
                    :persistent="false"
                    v-model="item.details"
                    :title="
                    $t('reviews.dateTravel', {
                      start: item.startDate,
                      stop: item.stopDate,
                    })
                  "
                >
                  <div class="pa-2">

                    <v-alert type="info" v-if="_isAfter(_parseDate(item.expirationDate, _datePattern.localDate), new Date())">
                      Cet avis peut encore être modifié par le client jusqu'au {{item.expirationDate}}.
                    </v-alert>

                    <div v-for="p in item.dataPrestations" :key="p.id">
                      <v-card v-if="p.type == 'Hotel'" class="pa-2 mb-4">
                        <collapse-panel
                            :elevation="false"
                            reverseChevron
                            class="mb-4"
                            :open="false"
                            :hidemenu="true"
                        >
                          <div slot="title">
                            <p class="text-h6 font-weight-bold mb-0">
                              {{ p.name }}
                            </p>
                            <div class="d-flex align-center mt-n2">
                              <v-icon small class="mr-1" color="black"
                              >mdi-map-marker
                              </v-icon
                              >
                              <p class="mb-0">{{ p.localisation }}</p>
                            </div>
                          </div>
                          <div slot="content">
                            <v-row no-gutters>
                              <v-col cols="6">
                                <review-item
                                    :item="p.accueil == null ? -1 : p.accueil"
                                    :title="$t('reviews.Service.title')"
                                    :desc="$t('reviews.Service.desc')"
                                />
                              </v-col>
                              <v-col cols="6">
                                <review-item
                                    :item="
                                    p.restauration == null ? -1 : p.restauration
                                  "
                                    :title="$t('reviews.Restoration.title')"
                                    :desc="$t('reviews.Restoration.desc')"
                                />
                              </v-col>
                              <v-col cols="6">
                                <review-item
                                    :item="
                                    p.equipement == null ? -1 : p.equipement
                                  "
                                    :title="$t('reviews.hotelEquipment.title')"
                                    :desc="$t('reviews.hotelEquipment.desc')"
                                />
                              </v-col>
                              <v-col cols="6">
                                <review-item
                                    :item="p.plage == null ? -1 : p.plage"
                                    :title="$t('reviews.Beach.title')"
                                    :desc="$t('reviews.Beach.desc')"
                                />
                              </v-col>
                              <v-col cols="6">
                                <review-item
                                    :item="p.animation == null ? -1 : p.animation"
                                    :title="$t('reviews.Animation.title')"
                                    :desc="$t('reviews.Animation.desc')"
                                    :radio="true"
                                />
                              </v-col>
                              <v-col cols="6">
                                <review-item
                                    :item="
                                    p.room.confort == null ? -1 : p.room.confort
                                  "
                                    :title="$t('reviews.comfort.title')"
                                    :desc="$t('reviews.comfort.desc')"
                                />
                              </v-col>
                              <v-col cols="6">
                                <review-item
                                    :item="
                                    p.room.equipement == null
                                      ? -1
                                      : p.room.equipement
                                  "
                                    :title="$t('reviews.roomEquipment.title')"
                                    :desc="$t('reviews.roomEquipment.desc')"
                                />
                              </v-col>
                              <v-col cols="6">
                                <review-item
                                    :item="
                                    p.room.proprete == null
                                      ? -1
                                      : p.room.proprete
                                  "
                                    :title="$t('reviews.cleanliness.title')"
                                    :desc="$t('reviews.cleanliness.desc')"
                                />
                              </v-col>
                              <v-col cols="6">
                                <review-item
                                    :item="
                                    p.recommendation == null
                                      ? -1
                                      : p.recommendation
                                  "
                                    :title="$t('reviews.recommendation.title')"
                                    :desc="$t('reviews.recommendation.desc')"
                                    :radio="true"
                                />
                              </v-col>
                            </v-row>
                          </div>
                        </collapse-panel>
                        <review-item
                            :item="p.comment == null ? '' : p.comment"
                            :title="$t('reviews.text-area.title')"
                            :desc="$t('reviews.text-area.desc')"
                            :text="true"
                        />
                      </v-card>
                      <collapse-panel
                          v-else-if="p.type == 'Flight'"
                          class="mb-4"
                          reverseChevron
                          :open="false"
                          :hidemenu="true"
                      >
                        <div slot="title">
                          <p class="text-h6 font-weight-bold mb-0">Vols</p>
                          <div class="d-flex align-center mt-n2">
                            <v-icon small class="mr-1" color="black"
                            >mdi-airplane
                            </v-icon
                            >
                            <p class="mb-0">
                              Votre vol avec la compangnie {{ p.company }}
                            </p>
                          </div>
                        </div>
                        <div slot="content">
                          <v-row no-gutters>
                            <v-col cols="6">
                              <review-item
                                  :item="p.confort == null ? -1 : p.confort"
                                  :title="$t('reviews.fly-comfort.title')"
                                  :desc="$t('reviews.fly-comfort.desc')"
                              />
                            </v-col>
                            <v-col cols="6">
                              <review-item
                                  :item="p.service == null ? -1 : p.service"
                                  :title="$t('reviews.fly-service.title')"
                                  :desc="$t('reviews.fly-service.desc')"
                              />
                            </v-col>
                          </v-row>
                        </div>
                      </collapse-panel>
                    </div>
                    <div class="d-flex flex-col pb-2 mt-4 pr-2" :class="item.moderationStatus.value != 'processing' ? 'justify-space-between' : 'justify-end'">
                      <smart-btn
                          v-if="item.moderationStatus.value != 'processing'"
                          class="ml-2"
                          secondary
                          @click.native="surveyDecision('processing', item)"
                      >à traiter
                      </smart-btn
                      >
                      <div class="d-flex flex-col justify-end">
                        <smart-btn
                            v-if="item.moderationStatus.value != 'validate'"
                            secondary
                            class="mr-2"
                            @click.native="surveyDecision('validated', item)"
                        >Valider
                        </smart-btn
                        >
                        <smart-btn
                            v-if="item.moderationStatus.value != 'rejected'"
                            secondary
                            action="delete"
                            @click.native="surveyDecision('rejected', item)"
                        >Rejeter
                        </smart-btn>
                      </div>
                    </div>
                  </div>
                </smart-dialog>
              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-container>
    </template>
  </layout>
</template>
<script>
import Layout from "@/views/Layout";
import {post} from "@/utils/api";
import {format, startOfMonth,} from "date-fns";
import SmartBtn from "../../components/commons/SmartBtn.vue";
import ReviewItem from "../../components/commons/ReviewItem.vue";
import CollapsePanel from "@/components/commons/CollapsePanel";
import {EventBus} from "../../components/commons/event-bus";
import SiteLabel from "@/components/commons/SiteLabel";

export default {
  name: "Reviews",
  components: {
    Layout,
    SmartBtn,
    ReviewItem,
    CollapsePanel,
    SiteLabel,
  },
  data() {
    return {
      details: false,
      listing: {
        loading: false,
        rowsPerPageItems: [{text: "Tous", value: -1}, 20, 50],
        headers: [
          {
            text: "Site",
            value: "site",
          },
          {
            text: "ref Dossier",
            value: "bookingId",
          },
          {
            text: "Nom du client",
            value: "customer.name",
            sortable: true
          },
          {
            text: "Date de création",
            value: "creationDate",
            sort: (a, b) => {
              return this._sortDates(a, b, this.datePatternConfig.serverLocalDate);
            },
            sortable: true
          },
          {
            text: "Max Mod. Client",
            value: "expirationDate",
            sort: (a, b) => {
              return this._sortDates(a, b, this.datePatternConfig.serverLocalDate);
            },
            sortable: true
          },
          {
            text: "Date de modération",
            value: "moderationDate",
          },
          {
            text: "Statut",
            value: "status",
            sortable: false,
          },
        ],
        items: [],
      },
      status: [
        {value: "pending", label: "En attente"},
        {value: "processing", label: "Traitement en cours"},
        {value: "validated", label: "Validé"},
        {value: "rejected", label: "Rejeté"},
      ],
      defaultForm: {
        startCreateDate: format(new Date(new Date().getFullYear(), new Date().getMonth() - 2, 1), "dd/MM/yyyy"),
        stopCreateDate: this._nowFormatted("dd/MM/yyyy"),
        status: ["pending","processing"],
      },
      searchform: {},
    };
  },
  created() {
    this.clearSearchform();
  },
  methods: {
    async loadData() {
      if (!this.listing.loading) {
        this.listing.items = await this.getData();
        this.listing.loading = false;
      }
    },
    getData() {
      this.listing.loading = true;
      const url = "/api/survey/list";
      this.searchform.startCreateDate == null
          ? (this.searchform.startCreateDate = format(
              startOfMonth(new Date()),
              "dd/MM/yyyy"
          ))
          : "";
      this.searchform.stopCreateDate == null
          ? (this.searchform.stopCreateDate = this._nowFormatted("dd/MM/yyyy"))
          : "";
      return post(url, this.searchform)
      .badRequest((err) => {
        EventBus.$emit("toaster-msg", err);
      })
      .json((data) => {
        return data.map((d) => {
          return {
            countryId: d.countryId,
            customerConsent: d.customerConsent,
            dataPrestations: d.dataPrestations,
            expirationDate: this._parseAndFormatDate(d.expirationDate, 'yyyy-MM-dd', 'dd/MM/yyyy'),
            id: d.id,
            langId: d.langId,
            moderationStatus: this.setTag(d.moderationStatus),
            startDate: this._parseAndFormatDate(d.startDate, 'yyyy-MM-dd', 'dd/MM/yyyy'),
            stopDate: this._parseAndFormatDate(d.stopDate, 'yyyy-MM-dd', 'dd/MM/yyyy'),
            token: d.token,
            userId: d.userId,
            creationDate: this._parseAndFormatDate(d.creationDate, 'yyyy-MM-dd', 'dd/MM/yyyy'),
            moderationDate: d.moderationDate != null ? this._parseAndFormatDate(d.moderationDate, 'yyyy-MM-dd', 'dd/MM/yyyy') : "",
            siteId: d.siteId,
            details: false,
            bookingId: d.bookingId,
            customer: d.customer,
          };
        }).filter(review => this.reviewIsEmpty(review.dataPrestations));
      });
    },
    reviewIsEmpty(review) {
      return review.some(x => {
        if (x.type == "Hotel") {
          return (x.room.proprete != null || x.room.confort != null || x.room.equipement != null || x.accueil != null
              || x.service != null || x.personnel != null || x.plage != null || x.restauration != null || x.equipement != null
              || x.animation != null || x.recommendation != null || x.comment != null)
        } else {
          return (x.confort != null || x.service != null)
        }
      })
    },
    async surveyDecision(value, item) {
      const url = `/api/survey/${item.id}/${value}`;

      await post(url).res((res) => {
        return res;
      });

      this.listing.items = await this.getData();
      this.listing.loading = false
      item.details = false;
    },
    clearSearchform() {
      this.searchform = {
        ...this.defaultForm,
      };
      this.loadData();
    },
    setTag(item) {
      switch (item) {
        case "pending":
          return {
            text: "En attente",
            color: "amber lighten-5",
            border: "#FFB300",
            value: "pending",
          };
        case "validated":
          return {
            text: "Validé",
            color: "green lighten-5",
            border: "#43A047",
            value: "validate",
          };
        case "rejected":
          return {
            text: "Rejeté",
            color: "red lighten-5",
            border: "#E53935",
            value: "rejected",
          };
        case "processing":
          return {
            text: "Traitement en cours",
            color: "amber lighten-5",
            border: "#FFB300",
            value: "processing",
          };
        default:
          return {
            text: "",
            color: "",
            border: "",
            value: "",
          };
      }
    },
  },
};
</script>
<style>
.detailsBtn {
  cursor: pointer;
}
</style>
