<template>
  <table class="price-grid pt-4">
    <tr>
      <th
        v-for="(d,i) in startDays"
        :key="d"
        :class="{selected: (i==selection.col)}"
        v-html="formatDay(d)"
      ></th>
      <td>
        <!--
          <v-progress-circular v-if="loading"
            indeterminate
            color="primary"
            :width="3"
          ></v-progress-circular>
        -->
      </td>
    </tr>
    <tr v-for="(line, i) in lines" :key="i">
      <td
        v-for="(cell, n) in line"
        :key="n"
        :class="{'selected': (cell.item!=null && cell.selected)}"
        @click="select(cell, i, n)"
      >
        <slot name="nodata" v-if="cell.item === void(0)"></slot>
        <slot name="nodata" v-else-if="cell.item === null">-</slot>
        <slot name="cell" v-else v-bind:item="cell.item">{{cell.item}}</slot>
      </td>
      <th :class="{selected: (i==selection.row)}" v-html="formatDay(stopDays[i])"></th>
    </tr>
  </table>
</template>
<script>
import _ from "lodash";

export default {
  name: "PriceGrid",
  mixins: [],
  props: {
    items: { type: Array, required: true }, // [{ start:'DD/MM/YYYY', stop: 'DD/MM/YYYY', item:'???'}
    inputDateFormat: { type: String, default: "dd/MM/yyyy" },
    //, 'outputDateFormat': { type: String, default: '[<span class="up">]EEE[</span><br/>]dd MMM'}
    outputDateFormat: { type: String, default: "EEE dd MMM" },
    loading: { type: Boolean, default: false },
  },
  data() {
    return {
      startDays: [],
      stopDays: [],
      lines: [],
      selection: {
        row: -1,
        col: -1,
      },
    };
  },
  created() {
    this.init();
  },
  watch: {
    items() {
      this.init();
    },
  },
  methods: {
    init() {
      this.startDays = _.sortBy(
        _.uniq(_.map(this.items, (p) => p.start)),
        (d) => this._toDate(this._parseDate(d, this.inputDateFormat)).getTime()
      );
      this.stopDays = _.sortBy(_.uniq(_.map(this.items, (p) => p.stop)), (d) =>
        this._toDate(this._parseDate(d, this.inputDateFormat)).getTime()
      );
      this.lines = _.map(this.stopDays, (stopDay, rowIndex) => {
        return _.map(this.startDays, (startDay, colIndex) => {
          const item = _.find(
            this.items,
            (i) => i.start == startDay && i.stop == stopDay
          );

          if (item) {
            if (item.selected) {
              this.selection.row = rowIndex;
              this.selection.col = colIndex;
            }
            return item;
          }

          return {
            start: startDay,
            stop: stopDay,
            item: null,
          };
        });
      });
    },
    formatDay(day) {
      return this._parseAndFormatDate(
        day,
        this.inputDateFormat,
        this.outputDateFormat
      );
    },
    select(cell, rowIndex, colIndex) {
      if (cell.item != null) {
        this.selection.row = rowIndex;
        this.selection.col = colIndex;
        this.$emit("onSelect", cell.item);
      }
    },
  },
};
</script>
<style lang="less">
.price-grid {
  width: 100%;
  border-collapse: collapse;
  td,
  th {
    padding: 5px;
    border: solid 1px #e0e0e0;
    text-align: center;
  }
  th {
    background-color: #f5f5f5;
    font-weight: normal;
    color: #222222;
    z-index: 2;
    position: relative;
    &.selected {
      font-weight: bold;
    }
  }
  td {
    position: relative;
  }
  td.selected {
    background-color: #4285f4;
    color: #fff;
    //text-shadow:1px 1px 2px black;
    z-index: 1;
    cursor: pointer;
  }
  .up {
    text-transform: uppercase;
  }

  /**
    Css to add background effect on all row and all column when curser hover a td
    th div, td div {
        position: relative;
        z-index: 3;
    }
    th span, td span {
        position: relative;
        z-index: 3;
    }

    //Row
    td:hover {
        color: #222222;
    }

    td:hover::before {
        background-color: #ecf7fe;
        content: '';
        height: 100%;
        left: 0px;
        position: absolute;
        top: 0;
        width: 10000px;
        z-index: 0;
    }

    //Column
    td:hover::after {
        background-color: #ecf7fe;
        content: '';
        height: 360px;
        left: 0;
        position: absolute;
        top: -300px;
        width: 100%;
        z-index: 0;
    }
     */
}
</style>
