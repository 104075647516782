<template>
  <v-card :elevation="1"
          class="pa-4"
          style="height:100%; width:100%; overflow: scroll">
    <span class="font-weight-bold text-lg">Chiffres d'affaires par site (<span class="grey--text lighten-1 body-2">données année n-1</span>)</span>
    <v-data-table class="mt-3"
                  :loading="items.length < 1 && loading"
                  :headers="headers"
                  :items="items"
                  search-field="name"
                  item-key="name"
                  hide-default-footer
    >
      <template v-slot:body="{ items }">
        <tbody>
        <tr
            v-for="item in items"
            :key="item.name"
        >
          <td class="">{{ item.name }}</td>
          <td class="text-right">
            <div class="d-flex flex-column">
              <span class=""> {{ _formatCurrency(item.day.value, "EUR") }}</span>
              <span class="caption grey--text lighten-1">{{
                  _formatCurrency(item.day.oldValue, "EUR")
                }}
                <span :class="['ml-2',item.day.evo[0] === '-' ? 'red--text' : 'green--text']">{{ item.day.evo }}</span></span>
            </div>
          </td>
          <td class="text-right">
            <div class="d-flex flex-column">
              <span class=""> {{ _formatCurrency(item.week.value, "EUR") }}</span>
              <span class="caption grey--text lighten-1">{{
                  _formatCurrency(item.week.oldValue, "EUR")
                }}
                <span :class="['ml-2',item.week.evo[0] === '-' ? 'red--text' : 'green--text']">{{ item.week.evo }}</span>
              </span>
            </div>
          </td>
          <td class="text-right">
            <div class="d-flex flex-column">
              <span class=""> {{ _formatCurrency(item.month.value, "EUR") }}</span>
              <span class="caption grey--text lighten-1">{{
                  _formatCurrency(item.month.oldValue, "EUR")
                }}
                <span :class="['ml-2',item.month.evo[0] === '-' ? 'red--text' : 'green--text']">{{ item.month.evo }}</span>
              </span>
            </div>
          </td>
          <td class="text-right">
            <div class="d-flex flex-column">
              <span class=""> {{ _formatCurrency(item.year.value, "EUR") }}</span>
              <span class="caption grey--text lighten-1">{{
                  _formatCurrency(item.year.oldValue, "EUR")
                }}
                <span :class="['ml-2',item.year.evo[0] === '-' ? 'red--text' : 'green--text']">{{ item.year.evo }}</span>
              </span>
            </div>
          </td>
        </tr>
        </tbody>

      </template>


    </v-data-table>
  </v-card>
</template>
<script>
import {EventBus} from "@/components/commons/event-bus";
import {get, post} from "@/utils/api";

export default {
  name: "WidgetCA",

  data() {
    return {
      loading: false,
      items: [],
      headers: [
        {
          text: "Site",
          value: "name",
          align: "left",
          sortable: true,
        },
        {
          text: "CA du Jour (€)",
          value: "day",
          align: "right",
          sortable: true,
        },
        {
          text: "CA de la semaine (€)",
          value: "week",
          align: "right",
          sortable: true,
        },
        {
          text: "CA du mois (€)",
          value: "month",
          align: "right",
          sortable: true,
        },
        {
          text: "CA de l'année (€)",
          value: "year",
          align: "right",
          sortable: true,
        }],
      url: `/api/widget/dashboard/ca/site`,
      totaux: {},
      totauxPast: {}
    }
  },
  props: {
    form: {type: Object, required: true},
    conf: {type: Object, required: false, default: null}
  },
  created() {
    this.init()
    EventBus.$on('searchDashboard', () => this.init());
  },
  computed: {},
  methods: {
    init() {
      this.items = []
      post(this.url, this.conf.data.sites, {"Content-Type": "application/json"})
          .json(r => {
            let dataList = []
            r.by_days.forEach(site => {
              dataList.push({
                name: site.name,
                day: {
                  value: this._formatCurrency(site.value, "EUR"),
                  oldValue: this._formatCurrency(site.oldValue, "EUR"),
                  evo: this.evolution(site.value, site.oldValue)
                },
              })
            })
            dataList.map(data => {

              data.week = {
                value: this._formatCurrency(r.by_weeks.find(site => site.name == data.name).value, "EUR"),
                oldValue: this._formatCurrency(r.by_weeks.find(site => site.name == data.name).oldValue, "EUR"),
                evo: this.evolution(r.by_weeks.find(site => site.name == data.name).value,
                    r.by_weeks.find(site => site.name == data.name).oldValue)
              }
              data.month = {
                value: this._formatCurrency(r.by_month.find(site => site.name == data.name).value, "EUR"),
                oldValue: this._formatCurrency(r.by_month.find(site => site.name == data.name).oldValue, "EUR"),
                evo: this.evolution(r.by_month.find(site => site.name == data.name).value,
                    r.by_month.find(site => site.name == data.name).oldValue)
              }
              data.year = {
                value: this._formatCurrency(r.by_year.find(site => site.name == data.name).value, "EUR"),
                oldValue: this._formatCurrency(r.by_year.find(site => site.name == data.name).oldValue, "EUR"),
                evo: this.evolution(r.by_year.find(site => site.name == data.name).value,
                    r.by_year.find(site => site.name == data.name).oldValue)
              }
            })
            this.items.push(...dataList, this.getTotal(r))

          })
    },
    getTotal(list) {
      let dayTotal = 0
      let dayOld = 0
      let weekTotal = 0
      let weekOld = 0
      let monthTotal = 0
      let monthOld = 0
      let yearTotal = 0
      let yearOld = 0
      list.by_days.forEach(day => dayTotal += day.value)
      list.by_days.forEach(day => dayOld += day.oldValue)
      list.by_weeks.forEach(w => weekTotal += w.value)
      list.by_weeks.forEach(w => weekOld += w.oldValue)
      list.by_month.forEach(m => monthTotal += m.value)
      list.by_month.forEach(m => monthOld += m.oldValue)
      list.by_year.forEach(y => yearTotal += y.value)
      list.by_year.forEach(y => yearOld += y.oldValue)

      return {
        name: "Totaux",
        day:
            {
              value: dayTotal,
              oldValue: dayOld,
              evo: this.evolution(dayTotal, dayOld)
            },
        week: {
          value: weekTotal,
          oldValue: weekOld,
          evo: this.evolution(weekTotal, weekOld)
        },
        month: {value: monthTotal, oldValue: monthOld, evo: this.evolution(monthTotal, monthOld)},
        year: {value: yearTotal, oldValue: yearOld, evo: this.evolution(yearTotal, yearOld)},
      }
    },
    evolution(act, old) {
      const result = this._formatEvolutionPercent(old, act)
      return result[0] == "-" ? result : "+" + result;
    }

  },

}
</script>