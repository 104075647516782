<template>
  <v-card
    class="elevation-2 mt-3"
    :class="color"
    :style="cardBackground()"
    @mouseover="hover = true"
    height="100%"
    @mouseleave="
      hover = false;
      fab = false;
    "
  >
    <v-speed-dial
      v-if="prestation.kind !== '' && hover && renderfab"
      v-model="fab"
      bottom
      right
      absolute
      direction="top"
      transition="slide-y-reverse-transition"
    >
      <template #activator="{ on }">
        <v-btn v-on="on" color="blue" v-model="fab" dark fab small>
          <v-icon v-if="fab">add</v-icon>
          <v-icon v-else>mdi-close</v-icon>
        </v-btn>
      </template>

      <v-tooltip right>
        <v-btn
          fab
          dark
          small
          :color="colorsPrestationList.RoomPrestation.color + ' lighten-2'"
          slot="activator"
          @click="addPresta('RoomPrestation')"
        >
          <v-icon>hotel</v-icon>
        </v-btn>
        <span>{{ $t("add-room") }}</span>
      </v-tooltip>

      <v-tooltip right>
        <v-btn
          fab
          dark
          small
          :color="colorsPrestationList.TransferPrestation.color + ' lighten-2'"
          slot="activator"
          @click="addPresta('TransferPrestation')"
        >
          <v-icon>directions_bus</v-icon>
        </v-btn>
        <span>{{ $t("add-transfer") }}</span>
      </v-tooltip>

      <v-tooltip right>
        <v-btn
          fab
          dark
          small
          :color="colorsPrestationList.FlightPrestation.color + ' lighten-2'"
          slot="activator"
          @click="addPresta('FlightPrestation')"
        >
          <v-icon>flight</v-icon>
        </v-btn>
        <span>{{ $t("add-flight") }}</span>
      </v-tooltip>

      <v-tooltip right>
        <v-btn
          fab
          dark
          small
          :color="colorsPrestationList.CarrentalPrestation.color + ' lighten-2'"
          slot="activator"
          @click="addPresta('CarrentalPrestation')"
        >
          <v-icon>directions_car</v-icon>
        </v-btn>
        <span>{{ $t("add-carrental") }}</span>
      </v-tooltip>
    </v-speed-dial>
    <div v-if="isLoading" class="loading-layer">
      <v-progress-circular
        :size="50"
        :color="color"
        :indeterminate="isLoading"
      />
    </div>

    <v-card-title class="pt-1">
      <v-row dense>
        <v-col style="max-width: 50px">
          <v-avatar :color="colorVariant" class="mt-1" size="32">
            <v-icon small dark>{{ icon }}</v-icon>
          </v-avatar>
        </v-col>
        <v-col>
          <v-row dense align="center">
            <v-col cols="7" class="grey--text text--darken-3 pb-0">
              {{
                _parseAndFormatDate(
                  prestation.start,
                  _datePattern.localDateTime,
                  _prestationDatePattern(prestation.start)
                )
              }}
              <span v-if="prestation.stop !== prestation.start">
                -
                {{
                  _parseAndFormatDate(
                    prestation.stop,
                    _datePattern.localDateTime,
                    _prestationDatePattern(prestation.start)
                  )
                }}
                -
                {{
                  _nbNights(
                    _parseDate(prestation.start, _datePattern.localDateTime),
                    _parseDate(prestation.stop, _datePattern.localDateTime)
                  )
                }}
                nuits
              </span>
              <span class="ml-4 grey--text text--lighten-1 caption"
                >#{{ prestation.id }}@{{ prestation.prestationGroupId }}.</span
              >
              <v-icon
                v-if="isCancel"
                @click.native="toggleTab"
                small
                class="ml-2"
              >
                {{
                  tabHidden ? "mdi-arrow-expand-all" : "mdi-arrow-collapse-all"
                }}
              </v-icon>
            </v-col>
            <v-col class="text-right">
              <availability v-model="prestation.status" kind="BookingStatus" />
              <availability
                v-model="prestation.supplierStatus"
                kind="SupplierStatus"
              />

              <v-tooltip top>
                <template #activator="{ on }">
                  <span
                    v-on="on"
                    v-if="isCancel && prestation.price > 0"
                    class="title font-weight-regular"
                    >{{
                      prestation.price | currency(prestation.currency)
                    }}</span
                  >
                </template>

                <div class="title font-weight-regular">
                  <span
                    class="cancelledprice"
                    v-if="prestation.status === 'cancelled'"
                    >{{
                      prestation.cancelledPrice | currency(prestation.currency)
                    }}</span
                  >
                  {{ prestation.price | currency(prestation.currency) }}
                </div>

                <div class="grey--text">
                  <span
                    class="cancelledprice"
                    v-if="prestation.status === 'cancelled'"
                    >{{
                      prestation.cancelledPurchasePrice
                        | currency(prestation.currency)
                    }}</span
                  >
                  {{
                    prestation.purchase | currency(prestation.purchaseCurrency)
                  }}
                </div>
              </v-tooltip>
            </v-col>
            <v-col class="menus" style="max-width: 35px">
              <v-menu bottom>
                <template #activator="{ on }">
                  <v-btn v-on="on" icon small>
                    <v-icon>more_vert</v-icon>
                  </v-btn>
                </template>
                <v-list class="menulist" dense>
                  <slot name="menu-top" />
                  <v-list-item @click="showPrices">
                    <v-list-item-title>
                      <v-icon small>mdi-playlist-edit</v-icon>
                      {{ $t("modify-price") }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    :disabled="!canEditTimes"
                    @click="
                      dialogs.modifyTimes = true;
                      isLoading = true;
                    "
                  >
                    <v-list-item-title>
                      <v-icon small>mdi-clock-outline</v-icon>
                      {{ $t("modify-time") }}
                    </v-list-item-title>
                  </v-list-item>

                  <v-divider></v-divider>
                  <v-subheader>Client</v-subheader>
                  <v-list-item :disabled="!canBook" @click="book">
                    <v-list-item-title>
                      <v-icon small>mdi-account-check-outline</v-icon>
                      {{ $t("book-prestation") }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item :disabled="!canCancel" @click="cancel">
                    <v-list-item-title>
                      <v-icon small>mdi-account-off-outline</v-icon>
                      {{ $t("cancel-prestation") }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item :disabled="!canDelete" @click="remove">
                    <v-list-item-title>
                      <v-icon small>mdi-account-remove-outline</v-icon>
                      {{ $t("delete-prestation") }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-divider></v-divider>

                  <v-subheader>Fournisseur</v-subheader>
                  <v-list-item :disabled="!canConfirm" @click="confirm">
                    <v-list-item-title>
                      <v-icon small>mdi-check</v-icon>
                      {{ $t("confirm") }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item :disabled="!canRefuse" @click="refuse">
                    <v-list-item-title>
                      <v-icon small>mdi-cancel</v-icon>
                      {{ $t("refuse") }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item :disabled="true" @click="cancelSupplier">
                    <v-list-item-title>
                      <v-icon small>mdi-file-cancel-outline</v-icon>
                      {{ $t("cancel") }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-divider></v-divider>

                  <v-list-item @click="dialogs.addActionDialog = true">
                    <v-list-item-title>
                      <v-icon small>mdi-playlist-plus</v-icon>Ajouter une action
                    </v-list-item-title>
                  </v-list-item>

                  <!--
									<v-list-item :disabled="!canReject" @click="reject">
										<v-list-item-title>{{$t("reject-prestation")}}</v-list-item-title>
									</v-list-item>
                  -->
                  <v-divider></v-divider>
                  <v-list-item
                    :disabled="prestation.status != 'booked' || hasVoucher"
                    @click="createVoucher"
                  >
                    <v-list-item-title>
                      <v-icon small>mdi-file-document-edit-outline</v-icon>
                      Créer voucher
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item :disabled="!hasVoucher" @click="modifyVoucher">
                    <v-list-item-title>
                      <v-icon small>mdi-file-document-edit-outline</v-icon>
                      {{ $t("edit-voucher") }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    :disabled="!hasVoucher || booking.locked"
                    @click="sendVoucher"
                  >
                    <v-list-item-title>
                      <v-icon small>mdi-file-send</v-icon>
                      {{ $t("send-voucher") }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-list-item @click="dialogs.documents = !dialogs.documents">
                    <v-list-item-title>
                      <v-icon small>mdi-file-plus</v-icon>Ajouter documents
                    </v-list-item-title>
                  </v-list-item>

                  <slot name="menu-bottom"></slot>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
          <v-row dense class="align-top" :class="{ 'd-none': tabHidden }">
            <v-col>
              <slot name="header-title"></slot>
            </v-col>
            <v-col
              class="text-right"
              cols="3"
              style="margin-right: 35px"
              v-if="!hidePrices"
              :class="{ fake: prestation.isLast }"
            >
              <div class="title font-weight-regular">
                <span
                  v-if="showCommissionPrice && prestation.commissionPrice"
                  class="amber rounded px-1"
                  >{{
                    prestation.commissionPrice | currency(prestation.currency)
                  }}</span
                >

                <span
                  class="cancelledprice"
                  v-if="prestation.status === 'cancelled'"
                  >{{
                    prestation.cancelledPrice | currency(prestation.currency)
                  }}</span
                >
                {{ prestation.price | currency(prestation.currency) }}
              </div>
              <div class="grey--text">
                <span
                  class="cancelledprice"
                  v-if="prestation.status === 'cancelled'"
                  >{{
                    prestation.cancelledPurchasePrice
                      | currency(prestation.currency)
                  }}</span
                >

                {{
                  prestation.purchase | currency(prestation.purchaseCurrency)
                }}
                <span class="ml-2 caption">{{
                  prestation.markup | percent
                }}</span>

                <span class="ml-2 caption"
                  >(tva:
                  {{ prestation.tva | currency(prestation.currency) }})</span
                >
              </div>
            </v-col>
            <v-col cols="12">
              <slot name="content"></slot>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-title>
    <v-divider class="mt-2" />
    <v-card-text class="pa-0">
      <v-tabs
        v-model="tab"
        dense
        background-color="grey lighten-5 "
        :slider-color="color"
        :active-class="colorText"
        :class="{ 'd-none': tabHidden }"
        id="prestation-tabs"
      >
        <v-tab key="action" ripple>
          <span>
            <v-icon small :color="tab == 0 ? color : ''"
              >mdi-format-list-checks</v-icon
            >
            {{ $t("actions") }}
            <small
              class="grey--text text--darken-1"
              v-show="prestation.tasks && prestation.tasks.length > 0"
              >• {{ prestation.tasks.length }}</small
            >
          </span>
        </v-tab>
        <v-tab-item
          key="action"
          :transition="false"
          :reverse-transition="false"
        >
          <v-card
            flat
            :style="{ height: prestation.tasks.length == 0 ? '0px' : 'auto' }"
          >
            <v-card-text>
              <v-row dense>
                <v-col
                  cols="4"
                  v-for="task in prestation.tasks"
                  :key="'task' + task.id"
                  class="pt-1 pr-1"
                >
                  <task-item :task="task" @saved="reload" />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-tab-item>

        <v-tab key="guests" ripple>
          <span>
            <v-icon small :color="tab == 1 ? color : ''">people</v-icon
            >Voyageurs
            <small class="grey--text text--darken-1">
              •
              <span v-if="nbAdults > 0">{{
                $tc("Adult-count-small", nbAdults, { size: nbAdults })
              }}</span>
              <span v-if="nbChilds > 0"
                >,
                {{
                  $tc("Child-count-small", nbChilds, { size: nbChilds })
                }}</span
              >
            </small>
          </span>
        </v-tab>
        <v-tab-item
          key="guests"
          :transition="false"
          :reverse-transition="false"
        >
          <v-card flat>
            <v-card-text>
              <div style="max-width: 800px">
                <v-data-table
                  :headers="[
                    { text: '#', sortable: false, value: 'id' },
                    { text: 'Nom', sortable: false, value: 'name' },
                    {
                      text: 'Classe d\'age',
                      sortable: false,
                      value: 'ageClassType',
                    },
                    { text: 'Prix', sortable: false, value: 'price' },
                    {
                      text: 'Prix d\'achat',
                      sortable: false,
                      value: 'purchase',
                    },
                  ]"
                  :items="prestation.guests"
                  :hide-default-footer="prestation.guests.length <= 10"
                  dense
                  class="elevation-1"
                >
                  <template #item.name="{ item }"
                    >{{ item.firstName }} {{ item.lastName }}</template
                  >
                  <template #item.price="{ item, value }">{{
                    value | currency(item.currency)
                  }}</template>
                  <template #item.purchase="{ item, value }">{{
                    value | currency(item.purchaseCurrency)
                  }}</template>
                  <template #item.ageClassType="{ item, value }">
                    {{ item.ageClassType }}
                    <span v-if="item.birthDate">({{ item.birthDate }})</span>
                  </template>
                </v-data-table>
              </div>
            </v-card-text>
          </v-card>
        </v-tab-item>

        <v-tab key="prix" ripple>
          <span>
            <v-icon small :color="tab == 2 ? color : ''">euro_symbol</v-icon
            >Détail du prix
          </span>
        </v-tab>
        <v-tab-item key="prix" :transition="false" :reverse-transition="false">
          <v-card flat>
            <v-card-text>
              <booking-price-computing
                :booking="booking"
                :prestation="prestation"
              />
            </v-card-text>

            <v-card-text>
              <booking-passengers-price-details :prestation="prestation" />
            </v-card-text>

            <v-card-text>
              <v-card>
                <v-card-title>
                  <v-icon>mdi-file-document</v-icon>
                  {{ $t("Facturation") }} fournisseur
                </v-card-title>

                <v-row dense class="mt-3">
                  <v-col class="pl-4" cols="6">
                    <span class="body-2 font-weight-medium">Factures</span>
                    <v-card-text v-if="supplierInvoices.length > 0">
                      <ul>
                        <li
                          v-for="(si, siIndex) in supplierInvoices"
                          :key="siIndex"
                        >
                          <router-link
                            :to="{
                              name: 'supplier-invoice',
                              params: { id: si.invoice.id },
                            }"
                          >
                            Fact. n°{{ si.invoice.id }}
                          </router-link>
                          <span class="ml-3">{{
                            si.prestation.amount
                              | currency(si.prestation.currencyCode)
                          }}</span>
                          <span
                            v-if="si.prestation.currencyCode !== 'EUR'"
                            class="ml-2"
                            >({{
                              si.prestation.euroAmount | currency("EUR")
                            }})</span
                          >
                          <span class="ml-3">
                            {{
                              $t("supplierinvoicestatus." + si.invoice.status)
                            }}
                            <span v-if="si.invoice.status == 'paid'">{{
                              si.invoice.paymentDate
                            }}</span>
                          </span>
                        </li>
                      </ul>
                    </v-card-text>
                    <v-card-text
                      v-if="
                        prestation.status == 'booked' ||
                        prestation.status == 'cancelled'
                      "
                    >
                      <v-btn
                        outlined
                        v-if="prestation && prestation.supplierAccountingId"
                        @click.native="
                          openInvoicesTab(
                            prestation.supplierAccountingId,
                            prestation.id
                          )
                        "
                        >Nouvelle facture:
                        {{ prestation.supplierAccountingName }}</v-btn
                      >

                      <v-btn
                        outlined
                        v-else-if="
                          prestation &&
                          prestation.segments &&
                          prestation.segments[0].supplierAccountingId &&
                          prestation.segments[0].supplierAccountingName
                        "
                        v-for="(
                          segment, segmentIndex
                        ) in groupSegmentBySupplier(prestation.segments)"
                        :key="segmentIndex"
                        @click.native="
                          openInvoicesTab(
                            segment.supplierAccountingId,
                            segment.id
                          )
                        "
                        >Nouvelle facture:
                        {{ segment.supplierAccountingName }}</v-btn
                      >
                    </v-card-text>
                  </v-col>

                  <v-col class="pl-4" cols="6">
                    <span class="body-2 font-weight-medium"
                      >Conditions de paiement</span
                    >
                    <v-card-text v-if="supplierPaymentConditions.length > 0">
                      <ul>
                        <li
                          v-for="(spc, spcIndex) in supplierPaymentConditions"
                          :key="spcIndex"
                        >
                          {{ spc.paymentDate }}
                          <span class="ml-3">
                            {{ spc.amount | currency(spc.currency) }}
                            <span v-if="spc.currency !== 'EUR'"
                              >({{ spc.euroAmount | currency("EUR") }} @
                              {{ spc.euroRate | decimal }}
                              {{ spc.currency }})</span
                            >
                          </span>
                        </li>
                      </ul>
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-card>
            </v-card-text>
          </v-card>
        </v-tab-item>

        <v-tab key="cancelfees" ripple>
          <span>
            <v-icon small :color="tab == 3 ? color : ''">mdi-cancel</v-icon
            >Frais d'annulation
          </span>
        </v-tab>
        <v-tab-item
          key="cancelfees"
          :transition="false"
          :reverse-transition="false"
        >
          <v-card flat>
            <v-card-text>
              <booking-presta-cancelfees :prestation="prestation" />
            </v-card-text>
          </v-card>
        </v-tab-item>

        <v-tab key="docs" ripple v-if="hasDocuments">
          <span>
            <v-icon small :color="tab == 4 ? color : ''"
              >mdi-file-document-outline</v-icon
            >Documents
          </span>
        </v-tab>
        <v-tab-item
          key="docs"
          :transition="false"
          :reverse-transition="false"
          v-if="hasDocuments"
        >
          <v-card flat>
            <v-card-text>
              <v-row dense>
                <v-col cols="12">
                  <booking-presta-voucher
                    :bookingid="booking.id"
                    :prestation="prestation"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-tab-item>

        <slot name="tabs" />
      </v-tabs>
    </v-card-text>

    <smart-dialog
      v-model="dialogs.modifyPrice"
      max-width="700"
      title="Modifier prix"
    >
      <v-expansion-panels v-model="pricesPanel" dense>
        <v-expansion-panel
          light
          :expand="pricesPanelExpand"
          focusable
          v-for="(price, i) in prices"
          :key="i"
        >
          <v-expansion-panel-header>{{ price.name }}</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-card>
              <v-card-text>
                <prestation-price v-model="prices[i]" />
              </v-card-text>
            </v-card>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-card-actions class="actions-btn">
        <v-spacer />
        <v-btn @click="modifyPrice()">Modifier les prix</v-btn>
      </v-card-actions>
    </smart-dialog>

    <smart-dialog
      v-model="dialogs.modifyTimes"
      max-width="600"
      title="Modifier horaires"
      @close="isLoading = false"
    >
      <modify-times
        :prestation-group-id="prestation.prestationGroupId"
        @done="
          dialogs.modifyTimes = false;
          isLoading = false;
        "
      />
    </smart-dialog>

    <smart-dialog
      title="Ajout document"
      v-model="dialogs.documents"
      :width="800"
    >
      <document-uploader
        :url="`/api/file/upload/prestation/${prestation.id}`"
        @uploaded="uploaded"
      />
    </smart-dialog>

    <smart-dialog v-model="dialogs.addActionDialog" title="Ajout action">
      <task-edit
        :booking-id="booking.id"
        :prestation-id="prestation.id"
        @saved="dialogs.addActionDialog = false"
      />
    </smart-dialog>
  </v-card>
</template>

<script>
import { EventBus } from "@/components/commons/event-bus";
import GuestsMixin from "@/components/mixins/GuestsMixin";

import BookingPriceComputing from "@/components/commons/BookingPriceComputing";
import BookingPassengersPriceDetails from "../commons/BookingPassengersPriceDetails";
import BookingPrestaGuests from "@/components/commons/BookingPrestaGuests";
import BookingPrestaInvoices from "@/components/commons/BookingPrestaInvoices";
import BookingPrestaVoucher from "@/components/commons/BookingPrestaVoucher";
import BookingPrestaCancelfees from "@/components/commons/BookingPrestaCancelfees";

import Availability from "@/components/commons/Availability";

import { get, post } from "@/utils/api";
import PrestationPrice from "@/components/booking/PrestationPrice";
import PrestationMixin from "@/components/mixins/PrestationMixin";
import ColorMixin from "@/components/mixins/ColorMixin";
import TaskItem from "@/components/commons/TaskItem";
import ModifyTimes from "@/components/commons/ModifyTimes";
import DocumentUploader from "@/components/commons/DocumentUploader";
import TaskEdit from "@/components/commons/TaskEdit";
import _ from "lodash";

export default {
  name: "Prestation",
  mixins: [PrestationMixin, GuestsMixin, ColorMixin],
  components: {
    DocumentUploader,
    ModifyTimes,
    TaskEdit,
    TaskItem,
    PrestationPrice,
    BookingPrestaCancelfees,
    BookingPrestaVoucher,
    BookingPrestaInvoices,
    BookingPrestaGuests,
    BookingPriceComputing,
    BookingPassengersPriceDetails,
    Availability,
  },
  props: {
    booking: { type: Object, required: true },
    prestation: {
      type: Object,
      required: true,
    },
    kind: { type: String, default: "prestation" },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    index: {
      type: Number,
      required: false,
      default: -1,
    },
    hidePrices: { type: Boolean, default: false },
    renderfab: { type: Boolean, default: true },
  },
  data() {
    return {
      isOpen: this.open,
      actionOpen: true,
      fab: false,
      hover: false,
      tab: null,
      dialogs: {
        modifyPrice: false,
        modifyTimes: false,
        documents: false,
        email: false,
        addActionDialog: false,
      },
      prices: [],
      pricesPanel: 0,
      pricesPanelExpand: true,
      isLoading: this.loading,
      supplierInvoices: [],
      supplierPaymentConditions: [],
      tabHidden: false,

      showCommissionPrice: false,
    };
  },
  watch: {
    loading(newVal) {
      this.isLoading = newVal;
    },
  },
  computed: {
    icon() {
      switch (this.prestation.kind) {
        case "RoomPrestation":
          return "hotel";
        case "TransferPrestation":
          return "directions_bus";
        case "CarrentalPrestation":
          return "directions_car";
        case "FlightPrestation":
          return "flight";
        case "InsurancePrestation":
          return "mdi-shield-account";
        case "UntypedPrestation":
          return "mdi-cube-outline";
        default:
          return "mdi-help";
      }
    },
    isPrestationGroup() {
      return (
        ["RoomPrestation", "InsurancePrestation"].indexOf(
          this.prestation.kind
        ) === -1
      );
    },
    color() {
      return this.prestationColor(this.prestation.kind) + " lighten-2";
    },
    colorVariant() {
      return this.prestationColor(this.prestation.kind) + " lighten-3";
    },
    colorText() {
      const color = this.prestationColor(this.prestation.kind);
      return `${color}--text text--darken-1 font-weight-bold`;
    },
    canBook() {
      return this.canBookStatus.includes(this.prestation.status);
    },
    canCancel() {
      return this.canCancelStatus.includes(this.prestation.status);
    },
    canDelete() {
      return this.canDeleteStatus.includes(this.prestation.status);
    },
    canConfirm() {
      return (
        (this.booking.status === "booked" ||
          this.canConfirmStatus.includes(this.prestation.status)) &&
        this.canConfirmSupplierStatus.includes(this.prestation.supplierStatus)
      );
    },
    canRefuse() {
      return (
        (this.booking.status === "booked" ||
          this.canRefuseStatus.includes(this.prestation.status)) &&
        this.canRefuseSupplierStatus.includes(this.prestation.supplierStatus)
      );
    },
    canEditTimes() {
      return this.prestation.kind !== "FlightPrestation";
    },
    isOnDemand() {
      return this.prestation.supplierStatus === "ondemand";
    },
    hasVoucher() {
      return (
        this.prestation.voucherIds && this.prestation.voucherIds.length > 0
      );
    },
    hasDocuments() {
      return (
        this.hasVoucher ||
        (this.prestation.documentsNames &&
          this.prestation.documentsNames.length > 0) ||
        (this.prestation.kind == "FlightPrestation" &&
          this.prestation.emittedDate &&
          this.prestation.pnr != null &&
          this.prestation.pnr != "")
      );
    },
    canReject() {
      return (
        this.prestation.supplierStatus === "refused" &&
        this.prestation.status === "booked"
      );
    },
    isCancel() {
      return this.prestation.status === "cancelled";
    },
  },

  created() {
    const url =
      this.prestation.kind === "RoomPrestation"
        ? "/api/prestation/" + this.prestation.id + "/supplier-invoice"
        : "/api/prestation-group/" +
          this.prestation.prestationGroupId +
          "/supplier-invoice";

    get(url).json((data) => {
      this.supplierInvoices = data.supplierInvoices;
      EventBus.$emit("invoices-ready", this.supplierInvoices.map(si => si.invoice))
    });

    //
    this.tabHidden = this.prestation.status === "cancelled";

    this.getPaymentConditons();

    EventBus.$on("toggleCommissionPrice", (payload) => {
      this.showCommissionPrice = payload;
    });
  },
  destroyed: function () {
    EventBus.$off("toggleCommissionPrice");
  },

  methods: {
    reload() {
      EventBus.$emit("reloadbooking", {});
    },
    toggle() {
      this.isOpen = !this.isOpen;
      this.actionOpen = !this.actionVisible && !this.isOpen;
    },

    addPresta(kind) {
      let date =
        this.booking.prestations[this.index].stop !== null
          ? this.booking.prestations[this.index].stop
          : null;
      const msg = {
        index: this.index,
        kind: kind,
        date: date,
      };
      EventBus.$emit("create-presta", msg);
    },
    getPaymentConditons() {
      const url = `/api/prestation/${this.prestation.id}/supplier-payement-condition`;

      get(url).json((data) => {
        this.supplierPaymentConditions = data.conditions;
      });
    },
    showPrices() {
      this.prices = [];
      if (this.prestation.kind === "TransferPrestation") {
        this.prestation.segments.map((s) => {
          this.prices.push({
            id: s.id,
            name: s.fromName + " → " + s.toName,
            price: s.price,
            currency: s.currency,
            purchase: s.purchase,
            purchaseCurrency: s.purchaseCurrency,
            autoConvert: true,
            euroRate: s.euroRate,
            euroPrice: s.euroPrice,
            euroPurchase: s.euroPurchase,
            euroPurchaseRate: s.euroPurchaseRate,
            tva: s.tva,
          });
        });
      } else {
        this.prices.push({
          id: this.prestation.id,
          name: this.prestation.name,
          price: this.prestation.price,
          currency: this.prestation.currency,
          purchase: this.prestation.purchase,
          purchaseCurrency: this.prestation.purchaseCurrency,
          autoConvert: true,
          euroRate: this.prestation.euroRate,
          euroPrice: this.prestation.euroPrice,
          euroPurchase: this.prestation.euroPurchase,
          euroPurchaseRate: this.prestation.euroPurchaseRate,
          tva: this.prestation.tva,
        });
      }

      this.dialogs.modifyPrice = true;
      this.pricesPanelExpand = true;
      setTimeout(() => {
        this.pricesPanelExpand = false;
      }, 500);
    },

    remove() {
      if (this.isPrestationGroup) {
        this.deletePrestationGroup();
      } else {
        this.deletePrestation();
      }
    },

    book() {
      this.isLoading = true;
      this.$root
        .$confirm(this.$t("confirm"), "Souhaitez réserver cette prestation ?", {
          width: 350,
        })
        .then((confirm) => {
          if (!confirm) {
            return Promise.resolve();
          } else {
            const url = this.isPrestationGroup
              ? `/api/prestation-group/${this.prestation.prestationGroupId}/book`
              : `/api/prestation/${this.prestation.id}/book`;

            post(url).res(() => {
              EventBus.$emit("reloadbooking", {});
            });
          }
        })
        .then(() => (this.isLoading = !this.isLoading));
    },

    cancel() {
      if (this.isPrestationGroup) {
        this.cancelPrestationGroup();
      } else {
        this.cancelPrestation();
      }
    },

    confirm() {
      this.isLoading = true;
      this.$root
        .$confirm(
          this.$t("confirm"),
          "Souhaitez confirmer cette prestation ?",
          { width: 350 }
        )
        .then((confirm) => {
          if (!confirm) {
            return Promise.resolve();
          } else {
            const url = this.isPrestationGroup
              ? `/api/prestation-group/${this.prestation.prestationGroupId}/confirm`
              : `/api/prestation/${this.prestation.id}/confirm`;

            post(url).res(() => {
              EventBus.$emit("reloadbooking", {});
            });
          }
        })
        .then(() => (this.isLoading = !this.isLoading));
    },

    refuse() {
      this.isLoading = true;
      this.$root
        .$confirm(this.$t("confirm"), "Souhaitez refuser cette prestation ?", {
          width: 350,
        })
        .then((confirm) => {
          if (!confirm) {
            return Promise.resolve();
          } else {
            const url = this.isPrestationGroup
              ? `/api/prestation-group/${this.prestation.prestationGroupId}/refuse`
              : `/api/prestation/${this.prestation.id}/refuse`;

            return post(url).res((data) => {
              EventBus.$emit("reloadbooking", {});
              this.loading = false;
            });
          }
        })
        .then(() => (this.isLoading = !this.isLoading));
    },
    cancelSupplier() {},

    createVoucher() {
      this.disablePanel = true;

      let pids = [this.prestation.id];
      if (this.prestation.kind == "") {
        pids = this.prestation.segments.map((s) => s.id);
      }

      pids.map((pid) => {
        const url = `/api/prestation/${pid}/createVoucher`;
        get(url)
          .json((data) => {
            this.prestation.voucherIds.push(data);
            return;
          })
          .then(() => {
            this.disablePanel = false;
            this.modifyVoucher();
          });
      });
    },

    modifyVoucher() {
      this.disablePanel = !this.disablePanel;
      this.$root
        .$editvoucherdata(
          this.$t("modify-voucher"),
          "",
          { width: 800 },
          this.prestation.voucherIds
        )
        .then((data) => {
          this.disablePanel = !this.disablePanel;
        });
    },

    sendVoucher() {
      this.isLoading = true;

      get(
        `/api/mail/sendVoucher/${this.booking.id}/${this.prestation.prestationGroupId}`
      )
        .badRequest((err) => {
          this.isLoading = false;
          EventBus.$emit("toaster-msg", err);
        })
        .res(() => {
          EventBus.$emit("reloadbooking", {});
          this.isLoading = false;
        });
    },

    modifyPrice() {
      const promises = [];
      this.prices.map((p) => {
        const promise = post(`/api/prestation/${p.id}/updatePrices`, p).res();
        promises.push(promise);
      });

      Promise.all(promises).then(() => {
        EventBus.$emit("reloadbooking", {});
        this.dialogs.modifyPrice = false;
      });
    },

    sendRevival() {
      this.$root
        .$confirm(this.$t("revival-dmd-hotel"), "Envoyer la relance ?", {
          width: 350,
        })
        .then((confirm) => {
          if (!confirm) {
            return Promise.resolve();
          } else {
            const url = `/api/mail/sendRevival/${this.booking.id}/${this.prestation.prestationGroupId}`;
            get(url)
              .res((r) => {
                EventBus.$emit("toaster-msg", "Email envoyé");
                EventBus.$emit("reloadbooking", {});
                return r;
              })
              .then(() => {
                this.mailLoading = false;
                this.dialogs.email = false;
              });
          }
        });
    },
    openInvoicesTab(supplierAccountingId, prestationId) {
      let routeData = this.$router.resolve({
        name: "supplier-invoice",
        params: { id: "create" },
        query: {
          supplierAccountingId: supplierAccountingId,
          prestationId: prestationId,
        },
      });
      window.open(routeData.href, "_blank");
    },
    groupSegmentBySupplier(segments) {
      const groupedSegment = _.groupBy(segments, "supplierAccountingId");
      return Object.keys(groupedSegment).map((key) => {
        return groupedSegment[key][0];
      });
    },

    cardBackground() {
      if (this.isCancel) {
        const line = "#BDBDBD";
        const bg = "#E0E0E0";
        return `
						background: ${bg};
						background-image: linear-gradient(150deg, ${line} 4.55%, ${bg} 4.55%, ${bg} 50%, ${line} 50%, ${line} 54.55%, ${bg} 54.55%, ${bg} 100%);
						background-size: 22.00px 12.70px;
					`;
      } else return "background: #fff !important;";
    },
    toggleTab() {
      if (this.isCancel) this.tabHidden = !this.tabHidden;
    },
    uploaded() {
      EventBus.$emit("reloadbooking", {});
      EventBus.$emit("toaster-msg", "Ajout des documents terminé");
      this.dialogs.documents = false;
    },
    roundValue(n) {
      if (n % 1 === 0) {
        return n;
      } else {
        return parseFloat(n.toFixed(2));
      }
    },
  },
};
</script>

<style scoped>
.collapse-container {
  margin-bottom: 5px;
}

.v-card {
  font-size: 14px !important;
  font-weight: normal !important;
  border-left: 5px solid transparent;
}

.v-card__title {
  font-size: 14px !important;
  font-weight: normal !important;
  line-height: 1.5 !important;
}

i.material-icons {
  cursor: pointer;
}

i.material-icons.toggled {
  transform: rotate(180deg);
}

.v-btn--active:before,
.v-btn:focus:before,
.v-btn:hover:before {
  background: transparent;
}

.v-btn {
  margin-bottom: 0;
  margin-top: 0;
}

.menus {
}

.v-speed-dial {
  bottom: 0;
  right: -20px;
}

.v-speed-dial .v-btn {
  margin: 0 0 5px 0;
}

.v-alert {
  padding: 5px;
}

/** --------- tabs -------- **/

.task-bloc {
  border-radius: 5px;
}

.task-infos {
  color: #000000de !important;
}

.task-message {
  padding-left: 24px !important;
}

/** --------- tabs -------- **/

.v-tabs__container .v-tabs__div {
  min-width: 150px;
}

>>> .v-tab {
  font-size: 12px;
  font-weight: 400;
}

.fake {
  opacity: 0.25;
}

>>> .v-datatable thead tr {
  height: 36px;
}
</style>
