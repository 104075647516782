<template>
  <v-col class="pt-0">
    <v-row class="px-2 justify-space-between">
      <v-col cols="9">
        <v-btn small @click="back()" text>
          <v-icon small>mdi-arrow-left</v-icon>Retour
        </v-btn>
      </v-col>

      <v-col cols="3" class="pt-0">
        <v-row class="justify-end">
          <v-col cols="5" class="pr-0">
            <v-btn
              x-small
              outlined
              color="blue"
              @click="duplicatePackage"
              v-if="$route.params.packageId > 0"
            >
              Dupliquer
            </v-btn>
          </v-col>
          <v-col cols="5" class="pl-0">
            <v-btn
              x-small
              outlined
              color="error"
              @click="deletePackage"
              v-if="$route.params.packageId > 0"
            >
              Supprimer
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-form
      v-model="form.valid"
      ref="form"
      v-on:submit.prevent="saveItems"
      style="position: relative"
    >
      <v-col cols="8" v-if="form.id && form.id > 0" class="pt-1">
        <v-card>
          <v-img
            class="white--text align-end"
            height="200px"
            :src="'/api/file/download/' + form.imagePath"
          >
            <v-card-title class="mb-2">
              <v-icon
                @click="dialogs.pkgImage = true"
                color="white"
                class="edit-pen"
                >mdi-lead-pencil</v-icon
              >
            </v-card-title>
          </v-img>
        </v-card>
      </v-col>

      <v-row>
        <v-col cols="12" class="mb-5 justify">
          <v-row class="justify-start mb-0 ml-2">
            <v-col cols="2" class="mb-0 pa-0">
              <v-checkbox
                label="Actif"
                v-model="form.active"
                class="mt-0"
                dense
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row class="mb-1 mt-0 cols" align="center">
            <v-col cols="6" class="mb-0 pb-0">
              <v-subheader
                light
                class="pa-0 mb-0 mx-0 mt-4 form-subheader solo-subheader"
                >Mise en avant marketing</v-subheader
              >
            </v-col>
            <v-col cols="12" class="mt-0 pt-0">
              <v-divider></v-divider>
            </v-col>
          </v-row>
          <v-row
            class="cols"
            justify="space-around"
            v-for="(item, idx) in form.translations"
            dense
            :key="idx"
          >
            <v-col cols="1">
              <v-icon @click="removeTranslationRow('translation', idx)" small
                >mdi-minus-circle-outline</v-icon
              >
            </v-col>

            <v-col cols="4">
              <v-select
                v-if="idx == 0"
                :items="languages"
                item-text="name"
                item-value="value"
                label="Langue"
                v-model="item.lang"
                :rules="rules.nonEmpty()"
                dense
              ></v-select>
              <v-select
                v-else
                :items="languages"
                item-text="name"
                item-value="value"
                label="Langue"
                v-model="item.lang"
                dense
              ></v-select>
            </v-col>

            <v-col cols="6">
              <v-text-field
                v-if="idx == 0"
                v-model="item.value"
                hint="ex: Hôtel de luxe"
                label="Nom"
                dense
                :rules="rules.nonEmpty()"
              ></v-text-field>
              <v-text-field
                v-else
                v-model="item.value"
                hint="ex: Hôtel de luxe"
                label="Nom"
                dense
                :disabled="displayEdit"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row
            class="cols"
            v-if="form.translations.length < languages.length"
            @click="addTranslationRow('translation')"
          >
            <v-icon small class="mr-2 pl-4">mdi-plus-circle-outline</v-icon
            >Ajouter une traduction
          </v-row>
        </v-col>
      </v-row>
      <v-row justify="start" class="pl-4 dense">
        <v-checkbox
          label="Conserver l'ordre des îles"
          v-model="form.keepOrdering"
          dense
          hide-details
          :disabled="displayEdit"
        ></v-checkbox>

        <v-checkbox
          label="Transferts compris"
          dense
          class="ml-5"
          v-model="form.transfer"
          @change="setTransfersAndRents('Transfer', form.transfer)"
        ></v-checkbox>

        <v-checkbox
          label="Location de voiture comprise"
          dense
          class="ml-5"
          v-model="form.carRent"
          @change="setTransfersAndRents('CarRent', form.carRent)"
        ></v-checkbox>
        <v-btn
          absolute
          v
          dark
          small
          right
          fab
          color="primary"
          class="mt-3"
          @click="addStep()"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-row>
      <div class="px-2">
        <v-row v-for="(item, idx) in steps" :key="idx" class="white my-5 px-3">
          <v-col class="elevation-2">
            <v-row class="justify-space-between">
              <v-col class="pt-0">
                <span class="pl-3 mt-2 mr-6">Étape {{ idx + 1 }}</span>
                <v-chip small>{{ item.nbHotels }} hôtels</v-chip>
              </v-col>
              <div class="d-inline-flex pa-0 mb-2">
                <v-checkbox
                  height="32"
                  label="Transferts compris"
                  hide-details
                  dense
                  v-model="item.withTransfer"
                  class="mt-0 mr-5 pa-0"
                  @change="updateGlobal('transfer')"
                ></v-checkbox>
                <v-checkbox
                  height="32"
                  label="Location de voiture comprise"
                  hide-details
                  dense
                  v-model="item.withCarRent"
                  @change="updateGlobal('carRent')"
                  class="mt-0 mr-5 pa-0"
                ></v-checkbox>
              </div>
            </v-row>

            <v-divider></v-divider>

            <v-row justify="start" dense class="mt-5">
              <v-col cols="2">
                <v-select
                  label="Îles"
                  :all-is-same-as-empty="true"
                  :default-select-all="true"
                  :items="item.island.items"
                  item-text="name"
                  item-value="id"
                  v-model="item.island.selected"
                  dense
                  @change="defineHotels(item)"
                ></v-select>
              </v-col>

              <v-col cols="1">
                <v-text-field
                  dense
                  type="number"
                  label="Nuits min"
                  v-model.number="item.nbNightsMin"
                  autocomplete="new-password"
                ></v-text-field>
              </v-col>
              <v-col cols="1">
                <v-text-field
                  dense
                  type="number"
                  label="Nuits max"
                  v-model.number="item.nbNightsMax"
                  autocomplete="new-password"
                ></v-text-field>
              </v-col>

              <v-col cols="2">
                <smart-multi-select
                  label="Étoiles"
                  :default-select-all="true"
                  :all-is-same-as-empty="false"
                  :items="item.rating.items"
                  item-text="value"
                  item-value="value"
                  item-value-name="value"
                  v-model="item.rating.selected"
                  dense
                  @input="defineHotels(item)"
                ></smart-multi-select>
              </v-col>

              <v-col cols="2">
                <smart-multi-select
                  label="Pensions"
                  :all-is-same-as-empty="true"
                  :default-select-all="false"
                  :items="item.meal.items"
                  item-text="name"
                  item-value="id"
                  item-value-name="id"
                  v-model="item.meal.selected"
                  dense
                  @input="updateHotelsList(item)"
                ></smart-multi-select>
              </v-col>

              <v-col
                :cols="item.hotel.selected.length > 1 ? 8 : 2"
                :class="item.hotel.selected.length > 1 ? 'mb-2' : ''"
              >
                <v-autocomplete
                  label="Hotels"
                  :items="item.hotel.items"
                  item-text="name"
                  item-value="id"
                  v-model="item.hotel.selected"
                  hide-details
                  dense
                  multiple
                  chips
                  small-chips
                  :loading="item.hotel.loading"
                >
                </v-autocomplete>
              </v-col>

              <v-col cols="1" class="mt-2">
                <v-tooltip top>
                  <template #activator="{ on }">
                    <v-icon v-on="on" v-if="idx > 0" @click="deleteStep(idx)"
                      >mdi-delete</v-icon
                    >
                  </template>
                  Supprimer une étape
                </v-tooltip>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
      <v-row class="font-weight-bold px-3">
        <v-col cols="2" class="text-right">Total nuits:</v-col>
        <v-col cols="1">{{ totalNights.min }}</v-col>
        <v-col cols="1">{{ totalNights.max }}</v-col>
      </v-row>
      <v-row>
        <v-col class="text-center">
          <smart-btn primary type="submit" :disabled="!isValid"
            >Enregistrer</smart-btn
          >
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-center">
          <v-data-table
            dense
            :items="combinations"
            :headers="combinationsHeaders"
            :disable-pagination="true"
            :hide-default-footer="true"
            class="elevation-1 mx-auto"
            style="max-width: 500px"
          >
          </v-data-table>
        </v-col>
      </v-row>
    </v-form>

    <smart-dialog
      title="Ajouter une image"
      v-model="dialogs.pkgImage"
      :width="800"
      v-if="form.id"
    >
      <document-uploader
        @uploaded="uploaded"
        :url="`/api/file/upload/packageimage/${form.id}`"
        acceptedFiles=".jpg,.jpeg"
      />
    </smart-dialog>
  </v-col>
</template>
<script>
import SmartMultiSelect from "@/components/commons/SmartMultiSelect";
import { get, post } from "@/utils/api";
import { EventBus } from "@/components/commons/event-bus";
import PackageMixin from "./PackageMixin";
import FormMixin from "@/components/mixins/FormMixin";
import DocumentUploader from "@/components/commons/DocumentUploader";
import _ from "lodash";

export default {
  name: "PackageEdit",
  props: ["packageId"],
  mixins: [FormMixin, PackageMixin],
  components: {
    SmartMultiSelect,
    DocumentUploader,
    EventBus,
  },
  data() {
    return {
      params: null,
      languages: ["fr", "en", "it"],
      ratings: [],
      meals: [],
      hotels: [],
      nights: null,
      islands: null,
      loadingHotels: false,
      sumMinNight: 0,
      sumMaxNight: 0,
      defaultStep: {
        rating: {
          selected: [],
          items: [],
        },
        meal: {
          selected: [],
          items: [],
        },
        hotel: {
          selected: [],
          items: [],
        },
        island: {
          selected: [],
          items: [],
        },
        nbNightsMin: 0,
        nbNightsMax: 0,
        withTransfer: false,
        withCarRent: false,
      },
      steps: [],
      form: {
        id: null,
        skeletonId: null,
        translations: [{ lang: "fr", value: null }],
        items: [
          {
            ratings: [],
            meals: [],
            hotels: [],
            nbHotels: 0,
            islandId: null,
            nbNightsMin: 0,
            nbNightsMax: 0,
            withTransfer: false,
            withCarRent: false,
          },
        ],
        transfer: false,
        carRent: false,
        imagePath: "",
        active: true,
        keepOrdering: true,
      },
      displayEdit: false,
      dialogs: {
        pkgImage: false,
      },
      combinationsHeaders: [
        { value: "nights", text: "Nombre de nuits" },
        { value: "combinations", text: "Combinaisons" },
        { value: "hotels", text: "Prix Hotels" },
      ],
      combinations: [],
    };
  },
  created() {
    this.init();
  },
  watch: {
    steps: {
      deep: true,
      immediate: false,
      handler() {
        this.steps.map((item) => {
          item.nbHotels =
            item.hotel.selected.length > 0
              ? item.hotel.selected.length
              : item.hotel.items.length;
        });
      },
    },
  },
  computed: {
    totalNights() {
      let totalMinNight = this.steps
        .map((step) => step.nbNightsMin)
        .reduce(function (acc, curr) {
          return acc + curr;
        }, 0);

      let totalMaxNight = this.steps
        .map((step) => step.nbNightsMax)
        .reduce(function (acc, curr) {
          return acc + curr;
        }, 0);

      return {
        min: totalMinNight,
        max: totalMaxNight,
      };
    },
    isValid() {
      return (
        this.steps.length > 0 &&
        this.steps.every((step) => step.island.selected != null)
      );
    },
  },
  methods: {
    init() {
      EventBus.$emit("pkgediting", {});
      let promises = [];
      const promiseSkeleton = this.getSkeleton();
      const promiseMeals = this.getMeals();

      promises = [...promises, promiseMeals];

      if (this.packageId && this.packageId > 0) {
        const promisePackage =
          this.$route.params.packageId > 0 ? this.getPackage() : null;
        promises = [...promises, promisePackage, promiseSkeleton];
      } else if (
        this.packageId &&
        this.packageId < 0 &&
        this.$route.params.id
      ) {
        this.form.skeletonId = this.$route.params.id;
      }

      this.setRatings();

      Promise.all(promises)
        .then(() => {
          if (this.packageId > 0) {
            this.buildStep();
          } else {
            this.islands.map(() => {
              this.addStep();
            });
          }
        })
        .finally(() => {
          this.steps.map((item) => {
            item.island.items.sort((a, b) =>
              a.name.toLowerCase() > b.name.toLowerCase()
                ? 1
                : b.name.toLowerCase() > a.name.toLowerCase()
                ? -1
                : 0
            );
          });

          this.steps.map((item) => {
            this.defineHotels(item);
          });

          this.buildCombinations();

          this.updateGlobal("transfer");
          this.updateGlobal("carRent");
        });
    },

    buildCombinations() {
      const totalHotels = this.steps.reduce((a, b) => {
        return a + b.nbHotels;
      }, 0);

      let promises = [];
      for (let n = this.totalNights.min; n <= this.totalNights.max; n++) {
        promises.push(
          this.getCombination(n).then((data) => ({
            nights: n,
            combinations: data,
            hotels: data * totalHotels,
          }))
        );
      }
      Promise.all(promises).then((data) => {
        this.combinations = data;
      });
    },
    getCombination(nights) {
      return get(
        `/api/packages/find/${this.form.id}/combinations?duration=` + nights
      ).json((data) => data);
    },

    setRatings() {
      for (let i = 1; i <= 5; i++) {
        this.ratings.push({
          name: i > 1 ? i + " Étoile" : i + " Étoiles",
          value: i,
        });
      }
    },
    getPackage() {
      let id = this.packageId;

      const url = `/api/packages/find/${id}`;

      return get(url).json((data) => {
        this.form.items = data.items;
        this.form.id = data.id;
        this.form.skeletonId = data.skeletonId;
        this.form.transfer = data.transfer;
        this.form.carRent = data.carRent;
        this.form.active = data.active;
        this.form.keepOrdering = data.keepOrdering;
        this.form.imagePath = data.imagePath;
        this.form.translations = data.translations;
      });
    },
    getSkeleton() {
      const url = `/api/packages-skeleton/${this.$route.params.id}`;

      return get(url)
        .json((data) => {
          this.nights = {
            min: data.nbNightsMin,
            max: data.nbNightsMax,
          };

          this.islands = data.islands;
        })
        .then((data) => {
          return this.getHotels();
        });
    },
    getMeals() {
      const url = "/api/search/mealplantypes";

      return get(url).json((data) => {
        this.meals = data;
      });
    },
    getHotels() {
      const url = "/api/search/packages-hotel";

      return post(
        url,
        this.islands.map((i) => i.id)
      ).json((data) => {
        this.hotels = data;
      });
    },
    defineHotels(item) {
      item.hotel.items = this.hotels.reduce((acc, curr) => {
        if (curr.islandId == item.island.selected) {
          if (
            item.rating.selected.length == 0 ||
            (item.rating.selected.length > 0 &&
              item.rating.selected.indexOf(curr.rating) > -1)
          ) {
            acc.push(curr);
          }
        }

        return acc;
      }, []);
      item.nbHotels =
        item.hotel.selected.length > 0
          ? item.hotel.selected.length
          : item.hotel.items.length;
    },
    deleteStep(idx) {
      this.steps.splice(idx, 1);
    },
    addStep() {
      let step = _.cloneDeep(this.defaultStep);
      step.meal.items = this.meals;
      step.meal.selected = this.meals.map((meal) => meal.id);
      step.rating.items = this.ratings;
      step.rating.selected = this.ratings.map((rating) => rating.value);
      step.island.items = this.islands.sort((a, b) =>
        a.name > b.name ? 1 : b.name > a.name ? -1 : 0
      );
      step.island.selected = null;
      step.hotel.items = this.hotels;
      step.hotel.loading = false;

      this.steps.push(step);
    },
    buildStep() {
      const steps = this.form.items.map((item) => {
        return {
          meal: {
            selected: item.meals,
            items: this.meals,
          },
          rating: {
            selected: item.ratings,
            items: this.ratings,
          },
          nbHotels: 0,
          hotel: {
            selected: item.hotels,
            items: this.hotels,
            loading: false,
          },
          island: {
            selected: item.islandId,
            items: this.islands.sort((a, b) =>
              a.name > b.name ? 1 : b.name > a.name ? -1 : 0
            ),
          },
          nbNightsMin: item.nbNightsMin,
          nbNightsMax: item.nbNightsMax,
          withTransfer: item.withTransfer,
          withCarRent: item.withCarRent,
        };
      });

      this.steps = [...steps];
    },
    saveItems() {
      const url = this.form.id
        ? `/api/packages/update/${this.form.id}`
        : "/api/packages/create";

      let steps = this.steps.map((step) => {
        return {
          ratings: step.rating.selected,
          meals: step.meal.selected.map((m) => {
            return typeof m == "object" ? m.id : m;
          }),
          hotels: step.hotel.selected.map((h) => {
            return typeof h == "object" ? h.id : h;
          }),
          islandId: step.island.selected,
          nbNightsMax: step.nbNightsMax,
          nbNightsMin: step.nbNightsMin,
          withTransfer: step.withTransfer,
          withCarRent: step.withCarRent,
        };
      });

      const form = {
        ...this.form,
        imagePath: this.form.id ? this.form.imagePath : "packages/default.jpg",
        active: this.checkIslands(),
        items: steps,
      };

      post(url, form)
        .json((data) => data)
        .then((response) => {
          EventBus.$emit("toaster-msg", "Package enregistré avec succès");

          if (this.$route.params.packageId == -1) {
            this.$router.push({
              name: "Package",
              params: { packageId: response.id },
            });
          } else {
            this.buildCombinations();
          }
        });
    },
    back() {
      this.$router.push({
        name: "EditPackages",
        params: { id: this.$route.params.id },
      });
    },
    uploaded(files) {
      EventBus.$emit("reloadbooking", {});
      EventBus.$emit("toaster-msg", "Image actualisée");
      this.dialogs.pkgImage = false;
      let img = files.map((file) => {
        let path = JSON.parse(file.xhr.responseText);
        return path.paths;
      })[0];

      this.form.imagePath = "/api/file/download/" + img[0];
    },
    checkIslands() {
      let formIslands = this.steps.map((step) => step.island.selected);

      const skeletonIslandIds = this.islands.map((i) => i.id);

      return (
        this.isPackageValid(skeletonIslandIds, formIslands) && this.form.valid
      );
    },
    checkNights() {
      const { min, max } = this.nights;

      const { stepsMin, stepsMax, ok } = this.steps.reduce(
        (prev, item) => {
          const min = prev.stepsMin + item.nbNightsMin;
          const max = prev.stepsMax + item.nbNightsMax;
          return {
            stepsMin: min,
            stepsMax: max,
            ok: prev.ok && min <= max,
          };
        },
        { stepsMin: 0, stepsMax: 0, ok: true }
      );

      this.sumMinNight = stepsMin;
      this.sumMaxNight = stepsMax;

      return stepsMin >= min && stepsMax <= max && ok;
    },
    addTranslationRow() {
      this.form.translations = [
        ...this.form.translations,
        ...[{ lang: null, value: null }],
      ];
    },
    removeTranslationRow(type, index) {
      if (type == "island" && this.form.islands.length > 1) {
        this.form.islands.splice(index, 1);
      } else if (type == "translation" && this.form.translations.length > 1) {
        this.form.translations.splice(index, 1);
      }
    },
    updateHotelsList(item) {
      let index = this.form.items.findIndex((i) => (i = item));
      this.steps[index].hotel.loading = true;
      const url = `/api/packages/hotels`;
      let form = item.meal.selected;

      post(url, form).json((data) => {
        this.form.items[index].hotels.items = data;
        this.defineHotels(item);
        this.steps[index].hotel.loading = false;
      });
    },
    setTransfersAndRents(type, value) {
      let updatedSteps = this.steps.map((item) => {
        if (type == "Transfer") {
          item.withTransfer = value;
        } else {
          item.withCarRent = value;
        }
        return item;
      });

      this.steps = updatedSteps;
    },
    updateGlobal(type) {
      let keys = [];

      if (type == "transfer") {
        keys = Object.keys(_.groupBy(this.steps, "withTransfer"));
        if (keys.length > 1) {
          this.form.transfer = false;
        } else if (keys.length == 1 && keys[0] == "false") {
          this.form.transfer = false;
        } else {
          this.form.transfer = true;
        }
      } else {
        keys = Object.keys(_.groupBy(this.steps, "withCarRent"));
        if (keys.length > 1) {
          this.form.carRent = false;
        } else if (keys.length == 1 && keys[0] == "false") {
          this.form.carRent = false;
        } else {
          this.form.carRent = true;
        }
      }
    },
    deletePackage() {
      this.$root
        .$confirm(
          this.$t("Suppression"),
          "Souhaitez vous supprimer ce package ?",
          { width: 350 }
        )
        .then((confirm) => {
          if (!confirm) {
            return Promise.resolve();
          } else {
            let pkgId = this.$route.params.packageId;

            const url = `/api/packages/delete/${pkgId}`;

            return get(url).json((data) => {
              return data;
            });
          }
        })
        .then(() => this.back());
    },
    duplicatePackage() {
      this.$root
        .$confirm(
          this.$t("Duplication"),
          "Souhaitez vous dupliquer ce package ?",
          { width: 350 }
        )
        .then((confirm) => {
          if (!confirm) {
            return Promise.resolve();
          } else {
            let pkgId = this.$route.params.packageId;

            const url = `/api/packages/duplicate/${pkgId}`;

            return get(url).json((data) => {
              return data;
            });
          }
        })
        .then(() => this.back());
    },
  },
};
</script>

<style scoped>
/*.fab-btn {
  bottom: 10px !important; 
}*/
.edit-pen {
  filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));
}

>>> .v-select__selections {
  min-height: 0;
}

>>> .v-select__selections > input {
  padding-bottom: 0;
}
</style>