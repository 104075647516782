<template>
<!-- MESSAGE DE DOM LE 24/11 À 17 h 14 : on ne va pas en faire des avaloir -->
	<v-row dense   class="page content-pdf" v-if="infos">
		<v-col cols="12">
			<v-row dense  >
				<v-col cols="8">
					<v-row dense  >
						<v-col cols="4" class="grey--text text--darken-1">Lyon le:</v-col>
						<v-col cols="6" class="pl-3">{{_parseAndFormatDate(infos.now, _datePattern.localDate, _datePattern.full)}}</v-col>
						<v-col cols="4" class="grey--text text--darken-1">{{infos.title}} ref:</v-col>
						<v-col cols="6" class="pl-3">{{bookingid}}-001</v-col>
						<v-col cols="4" class="grey--text text--darken-1">Inscription du ref:</v-col>
						<v-col cols="6" class="pl-3">{{_parseAndFormatDate(infos.bookingDate, _datePattern.localDateTime, _datePattern.full)}}</v-col>
					</v-row>
				</v-col>
				<v-col cols="4" v-if="infos.client.address">
					{{infos.client.lastname}} {{infos.client.firstname}}<br/>
					{{infos.client.address.street||''}}<br/>
					<span v-if="infos.client.address.streetComplement">{{infos.client.address.streetComplement||''}}<br/></span>
					{{infos.client.address.postalcode||''}} {{infos.client.address.city||''}}<br/>
					{{infos.client.address.country||''}}<br/>
				</v-col>

				<v-col cols="8" class="mt-4 ">
					<v-row dense  >
						<v-col cols="4" class="grey--text text--darken-1">Délivré par:</v-col>
						<v-col cols="6" class="pl-3 ">{{infos.sitename}}</v-col>
						<v-col cols="4" class="grey--text text--darken-1">Montant:</v-col>
						<v-col cols="6" class="pl-3 ">{{infos.amount | currency('EUR')}}</v-col>
						<v-col cols="4" class="grey--text text--darken-1">Bénéficiaire:</v-col>
						<v-col cols="6" class="pl-3 ">
							{{infos.client.lastname}} {{infos.client.firstname}}
						</v-col>
						<v-col cols="4" class="grey--text text--darken-1">Valable jusqu'au:</v-col>
						<v-col cols="6" class="pl-3 ">{{_parseAndFormatDate(infos.validity, _datePattern.localDate, _datePattern.full)}}</v-col>
					</v-row>
				</v-col>
				<v-col cols="12" class="mt-5">
					<p><b>Conditions d’utilisation :</b></p>

					<p>{{infos.title}} valable jusqu’au {{_parseAndFormatDate(infos.validity, _datePattern.localDate, _datePattern.full)}} octroyé dans le cadre de la résolution du contrat {{bookingid}} du fait de la
						crise d’urgence sanitaire décrétée en lien avec le Covid 19 en application de l’odonnance 2020-135 du 25
						mars 2020
					</p>
					<p>
						Valable en déduction d’un prochain séjour réservé auprès de notre agence<span v-if="!infos.restriction">.</span><span v-else> ({{infos.restriction}}).</span>
						Cet {{infos.title}} n’est pas cessible et est utilisable en plusieurs fois.
					</p>
					<p>
						En cas de non utilisation totale dans le délai imparti, nous procéderons à un remboursement monétaire du restant dû.
					</p>
				</v-col>
			</v-row>
		</v-col>
		<span id="loaded"></span>
	</v-row>
</template>

<script>
	import {get} from "@/utils/api"
	export default {
		name: "AValoir",
		mixins: [],
		props: ['bookingid'],
		data() {
			return {
				infos:null
			}
		},
		created() {

			const qs = this.$route.query.invoiceid ? `?invoiceid=${this.$route.query.invoiceid}` : ''
			get(`/api/document/toWorth/${this.bookingid}${qs}`)
			.json(r => {
				this.infos = r
			})
		},
		watch: {},
		computed: {},
		methods: {}
	}
</script>

<style scoped>
 .page {
	 height: 100%;
	 background: #FFF;
 }
</style>
