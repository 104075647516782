<template>
  <widget-container
    :url="url"
    :bus="bus"
    :dark="false"
    :alwaysrender="true"
    headlineclass
    cardclass="ma-0 pa-1"
    color="transparent"
    @ready="setData"
  >
    <template slot="content" v-if="listing.items">
      <v-data-table
        :headers="listing.headers"
        :items="listing.items.transfers"
        :loading="listing.loading"
        :footer-props="{
          'items-per-page-options': listing.rowsPerPageItems,
          'items-per-page-text': 'Lignes par page',
        }"
        hide-default-footer
        class="tbl"
        no-data-text="Il n'y a pas de transferts disponibles"
      >
        <template #header.copy="{ header }">
          <v-icon small @click="copy(listing.items.transfers)" class="pl-2"
            >mdi-clipboard-text-outline</v-icon
          >
        </template>

        <template #item.passengers="{ item }">
          <div>
            {{
              (item.adultPassenger ? item.adultPassenger : 0) +
              (item.childPassenger ? item.childPassenger : 0)
            }}
            | {{ item.adultPassenger ? item.adultPassenger : 0 }}
            AD.
          </div>
          <div>{{ item.childPassenger ? item.childPassenger : 0 }} CH.</div>
        </template>

        <template #item.buyAmount="{ value }">{{
          value | currency("EUR")
        }}</template>

        <template #item.sellAmount="{ value }">{{
          value | currency("EUR")
        }}</template>

        <template #item.averageBuy="{ value }">{{
          value | currency("EUR")
        }}</template>

        <template #item.averageSell="{ value }">{{
          value | currency("EUR")
        }}</template>

        <template #item.vehicle="{ item }"
          >{{ item.nbTransfers }} {{ $t(item.vehicleType) }}</template
        >

        <template #body.append="{ items }">
          <tr
            style="font-weight: bold"
            v-if="items.length > 0"
            class="transfer-stats-total"
          >
            <td>Total</td>
            <td>
              <div v-if="listing.items.totalAdultPassenger">
                {{
                  listing.items.totalAdultPassenger +
                  listing.items.totalChildPassenger
                }}
                |
                {{ listing.items.totalAdultPassenger }}
                AD
              </div>
              <div v-if="listing.items.totalChildPassenger">
                {{ listing.items.totalChildPassenger }}
                CH
              </div>
            </td>
            <td v-if="listing.items.totalBuyAmount">
              {{ listing.items.totalBuyAmount | currency("EUR") }}
            </td>
            <td v-if="listing.items.totalSellAmount">
              {{ listing.items.totalSellAmount | currency("EUR") }}
            </td>
            <td v-if="listing.items.totalAverageBuyAmount">
              {{ listing.items.totalAverageBuyAmount | currency("EUR") }}
            </td>
            <td v-if="listing.items.totalAverageSellAmount">
              {{ listing.items.totalAverageSellAmount | currency("EUR") }}
            </td>
            <td>
              {{
                listing.items.totalTransfers
                  ? listing.items.totalTransfers + " Transferts"
                  : ""
              }}
            </td>
            <td></td>
          </tr>
        </template>
      </v-data-table>
    </template>
  </widget-container>
</template>

<script>
import WidgetContainer from "./WidgetContainer";
import clipboard from "../../../utils/clipboard";

export default {
  name: "WidgetTransferStats",
  components: { WidgetContainer },
  mixins: [],
  props: {
    bus: {
      type: Object,
      required: true,
    },
    url: {
      type: String,
      required: false,
      default: "/api/widget/transfers",
    },
  },
  data() {
    return {
      data: null,
      listing: {
        loading: false,
        rowsPerPageItems: [200, 500, { text: "Tous", value: -1 }],
        pagination: {
          rowsPerPage: 200,
        },
        headers: [
          { text: "", value: "" },
          { text: "Passagers", value: "passengers" },
          { text: "Achat", value: "buyAmount" },
          { text: "Vente", value: "sellAmount" },
          { text: "Moyenne achat", value: "averageBuy" },
          { text: "Moyenne Vente", value: "averageSell" },
          { text: "Vehicule", value: "vehicle" },
          { text: "", value: "copy", class: "text-center", sortable: false },
        ],
        items: {
          transfers: [],
          sumPassenger: null,
          totalBuyAmount: null,
          totalSellAmount: null,
          totalAverageBuyAmount: null,
          totalAverageSellAmount: null,
          totalAdultPassenger: null,
          totalChildPassenger: null,
          totalTransfers: null,
        },
      },
    };
  },
  created() {
    this.$nextTick(() => {
      this.bus.$on("search", (searchform) => {
        this.listing.loading = true;
        this.$emit("loading", true);
      });
    });
  },
  destroyed() {
    this.bus.$off("search");
  },
  watch: {},
  computed: {},
  methods: {
    setData(data) {
      this.listing.items = data;
      this.listing.loading = false;
      this.$emit("loading", false);
    },
    copy(data) {
      const defaultSeparator = "\t";
      const decimalOpts = { maximumFractionDigits: 0, useGrouping: false };
      const headers = this.listing.headers
        .map((h) => {
          return `${h.text}${defaultSeparator}`;
        })
        .join(" ");

      const rows = data.map((i) => {
        const rowObj = {
          passenger: this.$options.filters.decimal(
            i.adultPassenger + i.childPassenger,
            decimalOpts
          ),
          buyAmount: this.$options.filters.decimal(i.buyAmount, decimalOpts),
          sellAmount: this.$options.filters.decimal(i.sellAmount, decimalOpts),
          averageBuy: this.$options.filters.decimal(i.averageBuy, decimalOpts),
          averageSell: this.$options.filters.decimal(
            i.averageSell,
            decimalOpts
          ),
          vehicleType:
            this.$options.filters.decimal(i.nbTransfers, decimalOpts) +
            " " +
            i.vehicleType,
        };

        return `${Object.values(rowObj).join(defaultSeparator)}`;
      });

      const allRows = [headers].concat(rows);

      clipboard.copyTextToClipboard(allRows.join("\n"));
    }
  },
};
</script>

<style scoped>
>>> .v-data-table__mobile-row {
  width: 100%;
  min-width: 325px !important;
}
@media screen and (max-width: 600px) {
  .transfer-stats-total {
    display: none;
  }
}
</style>
