/**
 *
 * An abstraction of navigator.clipboard API because Edge.
 *
 */

import {EventBus} from "@/components/commons/event-bus";

const clipboard = {

	fallbackCopyTextToClipboard(text) {
		const textArea = document.createElement("textarea");
		textArea.value = text;
		document.body.appendChild(textArea);
		textArea.focus();
		textArea.select();

		try {
			const successful = document.execCommand('copy');
			const msg = successful ? 'successful' : 'unsuccessful';
			console.log('Fallback: Copying text command was ' + msg);
		} catch (err) {
			console.error('Fallback: Oops, unable to copy', err);
		}
		
		document.body.removeChild(textArea);
	},

	copyTextToClipboard(text) {
		if (!navigator.clipboard) {
			this.fallbackCopyTextToClipboard(text);
			return;
		}
		navigator.clipboard.writeText(text).then(function () {
			console.log('Async: Copying to clipboard was successful!');
			EventBus.$emit('toast', {color:'green', text:'Données copiées dans le presse-papier'})
		}, function (err) {
			EventBus.$emit('toast', {color:'red', text:'Une erreur a eu lieu lors de la copie'})
			console.error('Async: Could not copy text: ', err);
		});
	}
}

export default clipboard;
