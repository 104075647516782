<template>

	<prestation ref="prestationcpnt" :booking="booking" :prestation="prestation" :index="index" :loading="disablePanel"
		:renderfab="renderfab">


		<template slot="header-title">
			<div class="subtitle-1 font-weight-medium">{{ prestation.hotelName }}</div>
			<div class="body-2 ">{{ prestation.name }} - {{ prestation.mealplanName }} -
				<availability v-model="prestation.availability" kind="Stock" :chip="false" class="caption" :noFlex="false"/>
			</div>
		</template>

		<template slot="header-price">
			<div class="headline font-weight-regular">{{ prestation.price }} {{ $t(prestation.currency + "-symbol") }}</div>
		</template>

		<template slot="menu-top">
			<v-list-item @click="modifyRoom">
				<v-list-item-title><v-icon small>mdi-swap-horizontal</v-icon> {{ $t("modify-room") }}</v-list-item-title>
			</v-list-item>
		</template>

		<template slot="menu-bottom">
			<v-list-item @click="openEmailDialog">
				<v-list-item-title><v-icon small>mdi-email</v-icon> {{ $t("message-hotelier") }}</v-list-item-title>
			</v-list-item>
			<v-list-item :disabled="prestation.supplierStatus !== 'ondemand'" @click="sendRevival">
				<v-list-item-title><v-icon small>mdi-email-alert</v-icon> {{ $t("revival-dmd-hotel") }}</v-list-item-title>
			</v-list-item>
		</template>

		<template slot="content">

			<v-row v-if="prestation.oldPrestation" class="align-center">
				<v-col cols="6" class="pa-1 elevation-1 grey-lighten-5" style="background:#FAFAFA;">
					<v-icon size="14">mdi-timer-sand</v-icon>
					Remplace la prestation {{ prestation.oldPrestation.name }}.
					<availability v-model="prestation.oldPrestation.status" kind="BookingStatus" />
					<availability v-model="prestation.oldPrestation.supplierStatus" kind="SupplierStatus" />

					<b>{{ prestation.oldPrestation.price | currency(prestation.oldPrestation.currency) }}</b>

					<v-icon size="14" @click="showOldPrestation = !showOldPrestation">mdi-eye</v-icon>
				</v-col>
			</v-row>

			<smart-dialog v-model="dialogs.modifyRoom" title="Modifier chambre" :width="1200">
				<modify-room :booking="booking" :prestation="prestation" :guests="booking.globalGuests"></modify-room>
			</smart-dialog>

			<smart-dialog title="Message à l'hotelier" v-model="dialogs.email" :width="800">
				<mail-composer :subject="'Dossier réf ' + booking.id" :tos="supplierEmails" :loading="mailLoading"
					@send="sendMsgSupplier" :message="messageSupplier"></mail-composer>
			</smart-dialog>

			<smart-dialog v-if="prestation.oldPrestation" v-model="showOldPrestation" :width="900"
				:title="prestation.oldPrestation.name">
				<prestation-hotel :booking="booking" :prestation="prestation.oldPrestation" :index="0"
					:renderfab="false"></prestation-hotel>
			</smart-dialog>
		</template>
		<template slot="tabs" v-if="competitors">
			<v-tab key="Competitors" ripple>
				<span>
					<v-icon small>mdi-ab-testing</v-icon> Concurence
				</span>
			</v-tab>
			<v-tab-item key="Competitors" :transition="false" :reverse-transition="false">
				<v-card flat>
					<v-card-text>

						<v-tabs vertical>
							<v-tab v-for="competitor in competitors">
								{{ competitor.scrapper }}
							</v-tab>
							<v-tab-item v-for="competitor in competitors" class="px-5">
								<v-card flat>
								    <v-card-text>
                                        <a :href="competitor.url" target="_blank">{{ competitor.hotel }} • {{ competitor.startDate }} au {{ competitor.stopDate }}</a>
                                        <v-simple-table dense>
                                            <template #default>
                                                <thead>
                                                    <tr>
                                                        <th>Pax</th>
                                                        <th>Chambre</th>
                                                        <th>Pension</th>
                                                        <th>Prix</th>
                                                        <th>Cancelfees</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <template v-for="(r, i) in competitor.rooms">
                                                        <tr v-for="(m, j) in r.prices" :key="i">
                                                            <td>{{ m.pax }}</td>
                                                            <td>
                                                                {{ r.name }}
                                                            </td>
                                                            <td>{{ m.meal }}</td>
                                                            <td>{{ m.price }}€</td>
                                                            <td>{{ m.cancelfees }}</td>
                                                        </tr>
                                                    </template>
                                                </tbody>
                                            </template>
                                        </v-simple-table>
								    </v-card-text>
								</v-card>
							</v-tab-item>
						</v-tabs>

					</v-card-text>

				</v-card>

			</v-tab-item>
		</template>
	</prestation>

</template>

<script>
import Prestation from "@/components/booking/Prestation";
import PrestationMixin from "@/components/mixins/PrestationMixin";
import GuestsMixin from "@/components/mixins/GuestsMixin"

import Availability from "@/components/commons/Availability"

import ModifyRoom from "@/components/booking/hotel/ModifyRoom";
import GuestList from "@/components/commons/GuestList";
import { EventBus } from "@/components/commons/event-bus";
import MailComposer from "@/components/commons/MailComposer";
import { get, post } from '@/utils/api'

export default {
	name: "PrestationHotel",
	props: {
		booking: { type: Object, required: true },
		prestation: { type: Object },
		index: { type: Number },
		renderfab: { type: Boolean, default: true }
	},
	mixins: [PrestationMixin, GuestsMixin],
	components: {
		MailComposer,
		Prestation,
		ModifyRoom,
		GuestList,
		Availability,
	},
	data: () => ({
		isOpen: false,
		showOldPrestation: false,
		guests: [],
		dialogs: {
			modifyRoom: false,
			email: false
		},
		supplierEmails: [],
		tab: '',
		editButtonLoading: false,
		mailLoading: false,
		competitors: null,
	}),
	created: function () {
		this.guests = this.prestation.guests

		this.getCompetitorsPrice();
	},
	computed: {
		messageSupplier() {
			const guests = this.guests.map(g => `<li>${g.lastName || ''} ${g.firstName || ''}</li>`).join('')
			return `
					Ref dossier: ${this.booking.id}<br/>
					Nom client: ${this.booking.customer.lastname || ''} ${this.booking.customer.firstname || ''}<br/>
					in: ${this._parseAndFormatDate(this.prestation.start, this._datePattern.localDateTime, this._prestationDatePattern(this.prestation.start))}<br/>
					out: ${this._parseAndFormatDate(this.prestation.stop, this._datePattern.localDateTime, this._prestationDatePattern(this.prestation.stop))}<br/>
					Passagers: <ul>${guests}</ul>
				`
		}
	},
	methods: {
		collapsetoggled(toggleState) {
			this.isOpen = toggleState.isOpen
		},

		modifyRoom(option) {
			option = option || false
			this.dialogs.modifyRoom = true
		},
		openEmailDialog() {
			const url = `/api/supplier/${this.prestation.supplierId}`
			get(url)
				.json(r => {
					this.supplierEmails = r && r.email ? r.email.split(',') : []
				}).then(() => {
					this.dialogs.email = true
				})
		},
		sendMsgSupplier(email) {
			this.mailLoading = true
			const url = `/api/mail/sendSupplier/${this.booking.id}`
			post(url, email)
				.json(r => {
					EventBus.$emit('toaster-msg', "Email envoyé")
					EventBus.$emit('reloadbooking', {})
					return r
				})
				.then(() => {
					this.mailLoading = false
					this.dialogs.email = false
				})
		},
		sendRevival() {
			this.$refs.prestationcpnt.sendRevival()
		},
		getCompetitorsPrice() {
			get(`/api/prestation/${this.prestation.id}/competitors`).json((data) => {
				this.competitors = data
			});
		},
	}
}
</script>

<style>
.v-card__title {
	padding-bottom: 0;
}
</style>
