<template>
  <div :style="'overflow-x: auto;margin:auto;'" v-show="tableWidth > 0">
    <div :style="'width:' + tableWidth + 'px;margin:auto;border: 1px solid #e1e1e1;'">
      <v-simple-table class="tbl">
        <template #default>
          <thead>
            <tr>
              <th
                v-for="(header, index) in table.headers"
                :class="[{ 'hotel-cell head': index == 0, 'default-cell': index > 0 && index <= 2, 'sub-cell': index > 2 }]"
                :style="widthStyleFor(header.value)"
                v-if="doShowColumn(header.value)"
                :key="index"
              >
                <div
                  :class="[{ 'hotel-cell-content': index == 0 }, { 'sorted': header.sorted != null }, header.sorted]"
                  @click="sort(header)"
                >
                  {{ header.text }}
                  <v-icon x-small>arrow_upward</v-icon>
                </div>
              </th>
            </tr>

            <tr v-if="withLastYear">
              <th
                v-for="(header, index) in table.headers"
                :key="index"
                class="pa-0 ma-0"
                :class="[{ 'hotel-cell head': index == 0, 'default-cell': index > 0 && index <= 2, 'sub-cell': index > 2 }]"
                :style="widthStyleFor(header.value)"
                v-if="doShowColumn(header.value)"
              >
                <div v-if="header.sub" :style="widthStyleFor(header.value)">
                  <span
                    class="cell-subheader-content-container"
                    v-for="(sub, subIndex) in header.sub"
                    :key="subIndex"
                    :class="[{ 'sorted': sub.sorted != null }, sub.sorted]"
                    @click="sortSub(header, sub)"
                  >
                    <div class="cell-subheader-content">
                      {{ sub.text }}
                      <v-icon small>arrow_upward</v-icon>
                    </div>
                  </span>
                </div>
                <div v-else></div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in table.items" :key="index">
              <td
                class="text-center hotel-cell"
                :class="{ 'odd': index % 2 == 1 }"
                :style="widthStyleFor('destination')"
                v-if="doShowColumn('destination')"
              >{{ item.destination ? item.destination : "-" }}</td>

              <td class="sub-cell" :style="widthStyleFor('ca')" v-if="doShowColumn('ca')">
                <hotel-stats-cell
                  v-model="item.ca"
                  :with-last-year="withLastYear"
                  :width="widthFor('ca')"
                ></hotel-stats-cell>
              </td>
              <td class="sub-cell" :style="widthStyleFor('ha')" v-if="doShowColumn('ha')">
                <hotel-stats-cell
                  v-model="item.ha"
                  :with-last-year="withLastYear"
                  :width="widthFor('ha')"
                ></hotel-stats-cell>
              </td>
              <td class="sub-cell" :style="widthStyleFor('markup')" v-if="doShowColumn('markup')">
                <hotel-stats-cell
                  v-model="item.markup"
                  :with-last-year="withLastYear"
                  :width="widthFor('markup')"
                  :percent="true"
                ></hotel-stats-cell>
              </td>
              <td class="sub-cell" :style="widthStyleFor('nights')" v-if="doShowColumn('nights')">
                <hotel-stats-cell
                  v-model="item.nights"
                  :with-last-year="withLastYear"
                  :width="widthFor('nights')"
                ></hotel-stats-cell>
              </td>
              <td class="sub-cell" :style="widthStyleFor('adults')" v-if="doShowColumn('pax')">
                <hotel-stats-cell
                  v-model="item.pax"
                  :with-last-year="withLastYear"
                  :width="widthFor('pax')"
                ></hotel-stats-cell>
              </td>
              <td class="sub-cell" :style="widthStyleFor('adults')" v-if="doShowColumn('adults')">
                <hotel-stats-cell
                  v-model="item.adults"
                  :with-last-year="withLastYear"
                  :width="widthFor('adults')"
                ></hotel-stats-cell>
              </td>
              <td
                class="sub-cell"
                :style="widthStyleFor('children')"
                v-if="doShowColumn('children')"
              >
                <hotel-stats-cell
                  v-model="item.children"
                  :with-last-year="withLastYear"
                  :width="widthFor('children')"
                ></hotel-stats-cell>
              </td>
              <td class="sub-cell" :style="widthStyleFor('booking')" v-if="doShowColumn('booking')">
                <hotel-stats-cell
                  v-model="item.booking"
                  :with-last-year="withLastYear"
                  :width="widthFor('booking')"
                ></hotel-stats-cell>
              </td>
              <td
                class="sub-cell"
                :style="widthStyleFor('averageDuration')"
                v-if="doShowColumn('averageDuration')"
              >
                <hotel-stats-cell
                  v-model="item.averageDuration"
                  :with-last-year="withLastYear"
                  :width="widthFor('averageDuration')"
                ></hotel-stats-cell>
              </td>
              <td
                class="sub-cell"
                :style="widthStyleFor('averageNightsCa')"
                v-if="doShowColumn('averageNightsCa')"
              >
                <hotel-stats-cell
                  v-model="item.averageNightsCa"
                  :with-last-year="withLastYear"
                  :width="widthFor('averageNightsCa')"
                ></hotel-stats-cell>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
  </div>
</template>

<script>
import HotelStatsCell from "../../../views/stats/HotelStatsCell";
import clipboard from "../../../utils/clipboard";
import WidgetHotelStatMixin from "../../mixins/WidgetHotelStatMixin";
import _ from "lodash";

export default {
  name: "WidgetHotelStatDesti",
  components: { HotelStatsCell },
  mixins: [WidgetHotelStatMixin],
  props: {
    withLastYear: {
      type: Boolean,
      required: true,
    },
    selectedHeaders: {
      type: Array,
      required: true,
    },
  },
  data() {
    const sub = [
      { text: "N", value: "value", sorted: null },
      { text: "N-1", value: "previousValue", sorted: null },
      { text: "Diff", value: "diff", sorted: null },
      { text: "%", value: "percentageDiff", sorted: null },
    ];
    return {
      table: {
        headers: [
          {
            text: "Desti",
            value: "destination",
            class: "text-center",
            icon: "",
            fullSize: 120,
            smallSize: 120,
            sorted: null,
          },
          {
            text: "CA",
            value: "ca",
            class: "text-center",
            icon: "",
            sub: _.cloneDeep(sub),
            fullSize: 235,
            smallSize: 120,
            sorted: null,
          },
          {
            text: "HA",
            value: "ha",
            class: "text-center",
            icon: "",
            sub: _.cloneDeep(sub),
            fullSize: 220,
            smallSize: 100,
            sorted: null,
          },
          {
            text: "Marge",
            value: "markup",
            class: "text-center",
            icon: "",
            sub: _.cloneDeep(sub),
            fullSize: 220,
            smallSize: 75,
            sorted: null,
          },
          {
            text: "Nuités",
            value: "nights",
            class: "text-center",
            icon: "",
            sub: _.cloneDeep(sub),
            fullSize: 220,
            smallSize: 75,
            sorted: null,
          },
          {
            text: "Pax",
            value: "pax",
            class: "text-center",
            icon: "",
            sub: _.cloneDeep(sub),
            fullSize: 220,
            smallSize: 70,
            sorted: null,
          },
          {
            text: "AD",
            value: "adults",
            class: "text-center",
            icon: "",
            sub: _.cloneDeep(sub),
            fullSize: 220,
            smallSize: 70,
            sorted: null,
          },
          {
            text: "CH",
            value: "children",
            class: "text-center",
            icon: "",
            sub: _.cloneDeep(sub),
            fullSize: 220,
            smallSize: 70,
            sorted: null,
          },
          {
            text: "Dos.",
            value: "booking",
            class: "text-center",
            icon: "",
            sub: _.cloneDeep(sub),
            fullSize: 220,
            smallSize: 70,
            sorted: null,
          },
          {
            text: "Moy durée",
            value: "averageDuration",
            class: "text-center",
            icon: "",
            sub: _.cloneDeep(sub),
            fullSize: 220,
            smallSize: 120,
            sorted: null,
          },
          {
            text: "Moy CA/nuits",
            value: "averageNightsCa",
            class: "text-center",
            icon: "",
            sub: _.cloneDeep(sub),
            fullSize: 220,
            smallSize: 150,
            sorted: null,
          },
        ],
        defaultData: [
          {
            adults: {
              diff: 0,
              name: "adults",
              percentageDiff: 0,
              previousValue: 0,
              value: 0,
            },
            averageDuration: {
              diff: 0,
              name: "averageDuration",
              percentageDiff: 0,
              previousValue: 0,
              value: 0,
            },
            averageNightsCa: {
              diff: 0,
              name: "averageNightCa",
              percentageDiff: 0,
              previousValue: 0,
              value: 0,
            },
            booking: {
              diff: 0,
              name: "booking",
              percentageDiff: 0,
              previousValue: 0,
              value: 0,
            },
            bookingId: 0,
            ca: {
              diff: 0,
              name: "ca",
              percentageDiff: 0,
              previousValue: 0,
              value: 0,
            },
            category: 0,
            chain: "",
            chainId: 0,
            children: {
              diff: 0,
              name: "children",
              percentageDiff: 0,
              previousValue: 0,
              value: 0,
            },
            destination: "",
            destinationId: 0,
            ha: {
              diff: 0,
              name: "ha",
              percentageDiff: 0,
              previousValue: 0,
              value: 0,
            },
            hotel: "",
            hotelId: 0,
            markup: {
              diff: 0,
              name: "markup",
              percentageDiff: 0,
              previousValue: 0,
              value: 0,
            },
            nights: {
              diff: 0,
              name: "nights",
              percentageDiff: 0,
              previousValue: 0,
              value: 0,
            },
            pax: {
              diff: 0,
              name: "pax",
              percentageDiff: 0,
              previousValue: 0,
              value: 0,
            },
            smartbeStaySelectionId: {
              diff: 0,
              name: "smartbeStaySelectionId",
              percentageDiff: 0,
              previousValue: 0,
              value: 0,
            },
          },
        ],
      },
    };
  },
  computed: {
    tableWidth() {
      return this._tableWidth(this.selectedHeaders);
    },
  },
  created() {
    this.table.selectedHeaders = this.selectedHeaders;
    this.table.items = this.table.defaultData;
  },
  methods: {
    doShowColumn(headerValue) {
      return this._doShowColumn(headerValue, this.selectedHeaders);
    },
    widthFor(key) {
      return this._widthFor(key);
    },
    widthStyleFor(key) {
      return this._widthStyleFor(key);
    },
    setData(processedData) {
      let sortedData = Object.values(
        _.groupBy(processedData, "destinationId")
      ).map((destinationStat) => {
        return this.generateStats(destinationStat);
      });

      this.table.items =
        sortedData.length > 0 ? sortedData : this.table.defaultData;
    },

    generateStats(obj) {
      const totalCa = _.sumBy(obj, "ca.value");
      const previousTotalCa = _.sumBy(obj, "ca.previousValue")
      const previousTotalNights = _.sumBy(obj, "nights.previousValue")
      const totalNights = _.sumBy(obj, "nights.value");
      const totalBooking = _.sumBy(obj, "booking.value");
      const previousTotalBooking = _.sumBy(obj, "booking.previousValue");
      
      const currentMarkup =
        this.getMarkup(totalCa, _.sumBy(obj, "ha.value")) * 100;
      let result = {};

      Object.entries(obj[0]).map((item) => {
        if (typeof item[1] == "object" && item[0] == "markup") {
          Object.assign(result, {
            markup: {
              diff:
                _.sumBy(obj, item[0] + ".value") -
                _.sumBy(obj, item[0] + ".previousValue"),
              name: "markup",
              percentageDiff:
                _.sumBy(obj, item[0] + ".previousValue") != 0
                  ? ((currentMarkup -
                    _.sumBy(obj, item[0] + ".previousValue")) /
                    _.sumBy(obj, item[0] + ".previousValue")) *
                  100
                  : 0,
              previousValue: _.sumBy(obj, item[0] + ".previousValue"),
              value: currentMarkup,
            },
          });
        } else if (typeof item[1] == "object" && item[0] == "averageNightsCa") {
          const value = (totalCa / totalNights) || 0
          const previousValue = (previousTotalCa / previousTotalNights) || 0
          Object.assign(result, {
            [item[0]]: {
              diff:
                value - previousValue,
              name: item[0],
              percentageDiff:
                previousValue != 0
                  ? (((value) -
                    previousValue) /
                    previousValue) *
                  100
                  : 100,
              previousValue: previousValue,
              value: totalCa / totalNights,
            },
          });
        } else if (typeof item[1] == "object" && item[0] == "averageDuration") {
          const value = (totalNights / totalBooking) || 0
          const previousValue = (previousTotalNights / previousTotalBooking) || 0
          Object.assign(result, {
            [item[0]]: {
              diff:
                value - previousValue,
              name: item[0],
              percentageDiff:
                previousValue != 0
                  ? (((value) -
                    previousValue) /
                    previousValue) *
                  100
                  : 100,
              previousValue: previousValue,
              value: value,
            },
          });
        } else if (typeof item[1] == "object" && item[0] != "markup") {
          Object.assign(result, {
            [item[0]]: {
              diff:
                _.sumBy(obj, item[0] + ".value") -
                _.sumBy(obj, item[0] + ".previousValue"),
              name: item[0],
              percentageDiff:
                _.sumBy(obj, item[0] + ".previousValue") != 0
                  ? ((_.sumBy(obj, item[0] + ".value") -
                    _.sumBy(obj, item[0] + ".previousValue")) /
                    _.sumBy(obj, item[0] + ".previousValue")) *
                  100
                  : 0,
              previousValue: _.sumBy(obj, item[0] + ".previousValue"),
              value: _.sumBy(obj, item[0] + ".value"),
            },
          });
        } else {
          Object.assign(result, {
            [item[0]]: item[1],
          });
        }
      });

      return result;
    },
    getMarkup(price, purchase) {
      if (price < purchase || price <= 0) {
        return 0;
      } else if (price == 0 && purchase == 0) {
        return 0;
      } else {
        return (price - purchase) / price;
      }
    },

    copy() {
      const wrongHeaders = ["hotel", "chain", "category"];
      const allHeaders =
        this.selectedHeaders.length == 0
          ? this.table.headers.map((h) => h.value)
          : this.selectedHeaders;
      const selectedH = allHeaders.filter((sh) => wrongHeaders.indexOf(sh) < 0);

      const defaultSeparator = "\t";
      const decimalOpts = { maximumFractionDigits: 0, useGrouping: false };
      const cell = (key, item) => {
        return !this.doShowColumn(key)
          ? ""
          : `${this.$options.filters.decimal(
            item[key].value,
            decimalOpts
          )}${defaultSeparator} ` +
          (!this.withLastYear
            ? ""
            : `${this.$options.filters.decimal(
              item[key].previousValue,
              decimalOpts
            )}${defaultSeparator} ${this.$options.filters.decimal(
              item[key].diff,
              decimalOpts
            )}${defaultSeparator} ${this.$options.filters.decimal(
              item[key].percentageDiff,
              decimalOpts
            )}${defaultSeparator} `);
      };

      const cellSimple = (key, item) =>
        this.doShowColumn(key) ? `${item[key]}${defaultSeparator} ` : "";

      const headerName = (headerValue) => {
        if (
          this.table.headers.find((h) => h.value === headerValue) != undefined
        ) {
          return headerValue;
        }
      };

      const header = selectedH.map((h) => {
        if (this.withLastYear && h != "destination") {
          return `${headerName(
            h
          ).toUpperCase()}${defaultSeparator} ${defaultSeparator} ${defaultSeparator} ${defaultSeparator} `;
        } else {
          return `${headerName(h).toUpperCase()}${defaultSeparator} `;
        }
      });

      const simpleCells = ["destination"];

      const subHeader = !this.withLastYear
        ? []
        : selectedH.map((h) => {
          if (simpleCells.indexOf(h) >= 0) {
            return defaultSeparator;
          } else {
            return `N${defaultSeparator} N-1${defaultSeparator} DIFF${defaultSeparator} %${defaultSeparator} `;
          }
        });

      const rows = _.flatten([header.join(" "), subHeader.join(" ")]).concat(
        this.table.items.map((item) => {
          return ` ${cellSimple("destination", item)}  ${cell(
            "ca",
            item
          )} ${cell("ha", item)} ${cell("markup", item)} ${cell(
            "nights",
            item
          )} ${cell("pax", item)} ${cell("adults", item)} ${cell(
            "children",
            item
          )} ${cell("booking", item)} ${cell("averageDuration", item)} ${cell(
            "averageNightsCa",
            item
          )}`;
        })
      );

      clipboard.copyTextToClipboard(rows.join("\n"));
      this.$emit("copied", {});
    },
    sort(header) {
      this._sort(header);
    },
    totalAverage(type, a, b, c) {
      if (type == "averageDuration") {
        if (c != 0) {
          return b / c;
        } else {
          return 0;
        }
      } else if (type == "averageNightsCa") {
        if (b != 0) {
          return a / b;
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    },
  },
};
</script>

<style scoped lang="less">
table {
  box-sizing: border-box;
  border-collapse: collapse;

  tbody {
    border-top: 1px solid #d1d1d1;
  }

  tbody tr:nth-of-type(even) {
    background-color: #eeeeee;
  }

  tbody tr td.odd {
    background-color: #eeeeee;
  }

  thead tr:first-child {
    .hotel-cell .default-cell .sub-cell {
      border: 1px solid #d1d1d1;
    }
  }

  thead tr:nth-child(2) {
    height: 30px;
  }

  thead {
    th {
      > div {
        height: 100%;
        height: 100%;
        display: block;
        text-align: center;
        line-height: 35px;
      }

      div,
      span {
        &:hover {
          cursor: pointer;

          .v-icon {
            opacity: 0.6;
          }
        }

        &.sorted {
          .v-icon {
            opacity: 1;
          }
        }

        &.desc {
          .v-icon {
            transform: rotate(-180deg);
          }
        }

        .v-icon {
          opacity: 0;
        }
      }
    }
  }

  tfoot tr {
    height: 30px;

    td {
      font-weight: bold;
    }
  }
}

tr {
  .hotel-cell {
    height: 30px !important;
    position: absolute;
    background: #fff;
    z-index: 1;
    padding: 0 !important;
    margin: 0 !important;
    border-right: 2px solid #d1d1d1;

    > span {
      position: relative;
      top: 10%;
      left: 5px;
    }

    &.head {
      height: 36px !important;
    }
  }

  .default-cell {
    height: 30px !important;
    //width: 120px ;
    padding: 0 !important;
    margin: 0 !important;
    text-align: center;
    line-height: 14px;
    vertical-align: center;
    border-right: 2px solid #d1d1d1;
  }

  .sub-cell {
    height: 30px !important;
    padding: 0 !important;
    margin: 0 !important;
    text-align: center;
    line-height: 14px;
    vertical-align: center;
    border-right: 2px solid #d1d1d1;
  }

  .cell-subheader {
    margin: 0 !important;
    padding: 0 !important;
  }

  .cell-subheader-content-container {
    width: 55px;
    display: inline-block;

    &:not(:first-child) {
      border-left: 1px solid #d1d1d1;
    }
  }

  .cell-subheader-content {
    height: 100%;
    top: 30%;
    width: 55px;
    position: relative;
  }
}
</style>
