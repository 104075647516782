import Vue from 'vue'
import jwtdecode from 'jwt-decode'
import {isBefore, addSeconds} from 'date-fns'
import {EventBus} from "@/components/commons/event-bus";

const allRoles = {
    admin: 1,
    onplaceagent: 2,
    commercial: 3,
    hlItaly: 4
}

const key = "auth"

const authMethods = {
    _isTokenExpired(token, offsetSeconds) {

        const expirationDate = this._getTokenExpirationDate(token);
        if (expirationDate == null) {
            return false;
        }

        let date = new Date()
        if (offsetSeconds) {
            date = addSeconds(date, offsetSeconds)
        }

        return isBefore(expirationDate, date);
    },

    _getTokenExpirationDate(token) {

        const decoded = jwtdecode(token);

        if (Object.prototype.hasOwnProperty.call(decoded, 'exp')) {
            return new Date(decoded.exp * 1000);
        }

        return null;
    },

    _getToken() {
        return window.localStorage.getItem(key);
    },

    _setToken(token) {
        window.localStorage.setItem(key, token);
    },
    _removeToken() {
        localStorage.removeItem(key);
    },
    _getParameterByName(name, url) {
        if (!url) url = window.location.href;
        name = name.replace(/[\[\]]/g, "\\$&");
        var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, " "));
    },

    _isAdmin() {
        const user = jwtdecode(localStorage.getItem(key));
        return user.roles.map(r => r.id).includes(allRoles.admin)
    }
}

Vue.mixin({
    data() {
        return {
            storageKey: key,
            roles: allRoles,
            viewsPerRole: {
                'admin': "BookingList",
                'onplaceagent': "BookingListReceptif",
                'commercial': 'Crm',
                'hlItaly': 'BookingList'
            }
        }
    },
    computed: {
        currentUser() {
            const item = localStorage.getItem(this.storageKey);
            return jwtdecode(item)
        },
        username() {
            return this.currentUser.name
        },

        isAdmin() {
            return this.userRolesIds.some(x => [this.roles.admin].includes(x))
        },
        isExternalLanguageAdmin() {
            return this.userRolesIds.some(x => [this.roles.hlItaly].includes(x))
        },
        isCommercial() {
            return this.currentUser.email != 'sviel@hotelissima.com' && this.userRolesIds.some(x => [this.roles.commercial].includes(x))
        },
        isSalma() {
            return this.currentUser.email == 'sviel@hotelissima.com' && this.currentUser.id == 89
        },
        userRolesIds() {
            return this.currentUser.roles.map(u => u.id)
        },
    },
    methods: {

        defaultViewPerRole() {
            return this.currentUser && this.currentUser.roles && this.currentUser.roles.length > 0 ?
                this.viewsPerRole[this.currentUser.roles[0].code] :
                "Home"
        },

        logout() {
            localStorage.removeItem(this.key);
            EventBus.$emit('logout', {})
            this.$router.push({name: 'Login'})
        },
        removeToken() {
            return authMethods._removeToken()
        },
        isTokenExpired(token, offsetSeconds) {
            return authMethods._isTokenExpired(token, offsetSeconds)
        },

        getTokenExpirationDate(token) {
            return authMethods._getTokenExpirationDate(token)
        },

        getToken() {
            return authMethods._getToken()
        },

        setToken(token) {
            return authMethods._setToken(token)
        },

        getParameterByName(name, url) {
            if (!url) url = window.location.href;
            name = name.replace(/[\[\]]/g, "\\$&");
            var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
                results = regex.exec(url);
            if (!results) return null;
            if (!results[2]) return '';
            return decodeURIComponent(results[2].replace(/\+/g, " "));
        }
    },
    filters: {}
});

export default authMethods;
