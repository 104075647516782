<template>
  <v-card :class="fillHeight ? 'fill-height' : ''">
    <div v-if="loading" class="loading-layer">
      <v-progress-circular
          :size="50"
          color="primary"
          :indeterminate="loading"
      ></v-progress-circular>
    </div>
    <v-card-text class="pt-1 pb-1 text--primary">
      <v-row dense align="center">
        <v-col>
          <slot name="title"></slot>
        </v-col>
        <v-col v-if="collapsable" style="max-width: 35px">
          <v-btn text icon small>
            <v-icon ripple :class="reverseChevron ? !isOpen ? '' : 'toggled' : isOpen ? '' : 'toggled'" @click="toggle">{{
                reverseChevron ? !isOpen ? "keyboard_arrow_down" : "keyboard_arrow_up" : isOpen ? "keyboard_arrow_down" : "keyboard_arrow_up"
            }}</v-icon>
          </v-btn>
        </v-col>
        <v-col v-if="!hidemenu" class="text-right" style="max-width: 35px">
          <v-menu bottom>
            <template #activator="{ on }">
              <v-btn v-on="on" icon small>
                <v-icon>more_vert</v-icon>
              </v-btn>
            </template>
            <v-list dense class="menulist">
              <slot name="menu"></slot>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12">
          <slot name="header"></slot>
        </v-col>
      </v-row>
    </v-card-text>

    <v-slide-y-transition>
      <v-card-text v-show="isOpen" class="pt-0 text--primary">
        <slot name="content"></slot>
      </v-card-text>
    </v-slide-y-transition>
  </v-card>
</template>

<script>
import { EventBus } from "@/components/commons/event-bus";

export default {
  name: "CollapsePanel",
  props: {
    open: {
      type: Boolean,
      default: true,
    },
    actionVisible: {
      type: Boolean,
      default: true,
    },
    hidemenu: {
      type: Boolean,
      required: false,
      default: false,
    },
    prestationkind: {
      type: String,
      required: false,
      default: "",
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    index: {
      type: Number,
      required: false,
      default: -1,
    },
    collapsable: {
      type: Boolean,
      default: true,
    },
    elevation: {
      type: Boolean,
      required: false,
      default: true,
    },
    fillHeight: {
      type: Boolean,
      default: true,
      required: false
    },
    reverseChevron: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  data() {
    return {
      isOpen: this.open,
      actionOpen: true,
      fab: false,
      hover: false,
    };
  },
  methods: {
    toggle() {
      this.isOpen = !this.isOpen;
      this.actionOpen = !this.actionVisible && !this.isOpen;
    },

    addPresta(kind) {
      const msg = {
        index: this.index,
        kind: kind,
      };
      EventBus.$emit("create-presta", msg);
    },
  },
};
</script>

<style scoped>
.collapse-container {
  margin-bottom: 5px;
}

.v-card {
  border-left: 5px solid transparent;
}
.v-card__title {
  padding-top: 10px;
}

i.material-icons {
  cursor: pointer;
}

i.material-icons.toggled {
  transform: rotate(180deg);
}

.v-btn--active:before,
.v-btn:focus:before,
.v-btn:hover:before {
  background: transparent;
}

.v-btn {
  margin-bottom: 0;
  margin-top: 0;
}

.menus {
  z-index: 3;
}

.v-speed-dial {
  bottom: 0;
  right: -20px;
}

.v-speed-dial .v-btn {
  margin: 0 0 5px 0;
}
</style>
