<template>
  <widget-container
    :url="url"
    headlineclass
    cardclass="ma-0 pa-1"
    color="transparent"
    :bus="bus"
    :dark="false"
    :flat="false"
    :alwaysrender="true"
    @ready="setData"
  >
    <template slot="content">
      <div class>
        <v-row align="center">
          <v-col cols="7" class>
            <smart-multi-select
              v-model="table.selectedHeaders"
              :items="table.headers"
              multiple
              item-value-name="value"
              item-value="value"
              item-text="text"
              label="Colonnes affichées"
              dense
              chips
            ></smart-multi-select>
          </v-col>
          <v-col cols="4" class="mb-3 text-right">
            <v-btn
              @click="copySummary"
              small
              depressed
              :disabled="this.table.items.length == 0"
            >
              <v-icon small>mdi-clipboard-outline</v-icon>Copier résumé
            </v-btn>
          </v-col>

          <v-col cols="1" class="mb-3 text-left">
            <v-btn
              @click="copy"
              small
              depressed
              :disabled="this.table.items.length == 0"
            >
              <v-icon small>mdi-clipboard-outline</v-icon>Copier
            </v-btn>
          </v-col>
        </v-row>

        <widget-hotel-stat-desti
          ref="widgethoteldesti"
          class="mb-3"
          :with-last-year="withLastYear"
          :selected-headers="table.selectedHeaders"
          :headers="table.headers"
        ></widget-hotel-stat-desti>
        <div :style="'overflow-x: auto;margin:auto;'">
          <div
            :style="
              'width:' +
              tableWidth +
              'px;margin:auto;border: 1px solid #e1e1e1;'
            "
          >
            <v-simple-table class="tbl">
              <template #default>
                <thead>
                  <tr>
                    <th
                      v-for="(header, index) in table.headers"
                      :key="index"
                      :class="[
                        {
                          'hotel-cell head': index == 0,
                          'default-cell': index > 0 && index <= 2,
                          'sub-cell': index > 2,
                        },
                      ]"
                      :style="widthStyleFor(header.value)"
                      v-if="doShowColumn(header.value)"
                    >
                      <div
                        :class="[
                          { 'hotel-cell-content': index == 0 },
                          { sorted: header.sorted != null },
                          header.sorted,
                        ]"
                        @click="sort(header)"
                      >
                        {{ header.text }}
                        <v-icon x-small>arrow_upward</v-icon>
                      </div>
                    </th>
                  </tr>
                  <!-- sub header with last year -->
                  <tr v-if="withLastYear">
                    <th
                      v-for="(header, index) in table.headers"
                      :key="index"
                      class="pa-0 ma-0"
                      :class="[
                        {
                          'hotel-cell head': index == 0,
                          'default-cell': index > 0 && index <= 2,
                          'sub-cell': index > 2,
                        },
                      ]"
                      :style="widthStyleFor(header.value)"
                      v-if="doShowColumn(header.value)"
                    >
                      <div
                        v-if="header.sub"
                        :style="widthStyleFor(header.value)"
                      >
                        <span
                          class="cell-subheader-content-container"
                          v-for="(sub, subIndex) in header.sub"
                          :key="subIndex"
                          :class="[{ sorted: sub.sorted != null }, sub.sorted]"
                          @click="sortSub(header, sub)"
                        >
                          <div class="cell-subheader-content">
                            {{ sub.text }}
                            <v-icon small>arrow_upward</v-icon>
                          </div>
                        </span>
                      </div>
                      <div v-else></div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in table.items" :key="index">
                    <td
                      class="hotel-cell"
                      :class="{ odd: index % 2 == 1 }"
                      :style="widthStyleFor('hotel')"
                      v-if="doShowColumn('hotel')"
                    >
                      <span>{{ item.hotel }}</span>
                    </td>
                    <td
                      class="default-cell"
                      :style="widthStyleFor('category')"
                      v-if="doShowColumn('category')"
                    >
                      {{ item.category }}
                    </td>
                    <td
                      class="default-cell"
                      :style="widthStyleFor('destination')"
                      v-if="doShowColumn('destination')"
                    >
                      {{ item.destination }}
                    </td>
                    <td
                      class="default-cell"
                      :style="widthStyleFor('chain')"
                      v-if="doShowColumn('chain')"
                    >
                      {{ item.chain }}
                    </td>

                    <td
                      class="sub-cell"
                      :style="widthStyleFor('ca')"
                      v-if="doShowColumn('ca')"
                    >
                      <hotel-stats-cell
                        v-model="item.ca"
                        :with-last-year="withLastYear"
                        :width="widthFor('ca')"
                      ></hotel-stats-cell>
                    </td>
                    <td
                      class="sub-cell"
                      :style="widthStyleFor('ha')"
                      v-if="doShowColumn('ha')"
                    >
                      <hotel-stats-cell
                        v-model="item.ha"
                        :with-last-year="withLastYear"
                        :width="widthFor('ha')"
                      ></hotel-stats-cell>
                    </td>
                    <td
                      class="sub-cell"
                      :style="widthStyleFor('markup')"
                      v-if="doShowColumn('markup')"
                    >
                      <hotel-stats-cell
                        v-model="item.markup"
                        :with-last-year="withLastYear"
                        :width="widthFor('markup')"
                        :percent="true"
                      ></hotel-stats-cell>
                    </td>
                    <td
                      class="sub-cell"
                      :style="widthStyleFor('nights')"
                      v-if="doShowColumn('nights')"
                    >
                      <hotel-stats-cell
                        v-model="item.nights"
                        :with-last-year="withLastYear"
                        :width="widthFor('nights')"
                      ></hotel-stats-cell>
                    </td>
                    <td
                      class="sub-cell"
                      :style="widthStyleFor('adults')"
                      v-if="doShowColumn('pax')"
                    >
                      <hotel-stats-cell
                        v-model="item.pax"
                        :with-last-year="withLastYear"
                        :width="widthFor('pax')"
                      ></hotel-stats-cell>
                    </td>
                    <td
                      class="sub-cell"
                      :style="widthStyleFor('adults')"
                      v-if="doShowColumn('adults')"
                    >
                      <hotel-stats-cell
                        v-model="item.adults"
                        :with-last-year="withLastYear"
                        :width="widthFor('adults')"
                      ></hotel-stats-cell>
                    </td>
                    <td
                      class="sub-cell"
                      :style="widthStyleFor('children')"
                      v-if="doShowColumn('children')"
                    >
                      <hotel-stats-cell
                        v-model="item.children"
                        :with-last-year="withLastYear"
                        :width="widthFor('children')"
                      ></hotel-stats-cell>
                    </td>
                    <td
                      class="sub-cell"
                      :style="widthStyleFor('booking')"
                      v-if="doShowColumn('booking')"
                    >
                      <hotel-stats-cell
                        v-model="item.booking"
                        :with-last-year="withLastYear"
                        :width="widthFor('booking')"
                      ></hotel-stats-cell>
                    </td>
                    <td
                      class="sub-cell"
                      :style="widthStyleFor('averageDuration')"
                      v-if="doShowColumn('averageDuration')"
                    >
                      <hotel-stats-cell
                        v-model="item.averageDuration"
                        :with-last-year="withLastYear"
                        :width="widthFor('averageDuration')"
                      ></hotel-stats-cell>
                    </td>
                    <td
                      class="sub-cell"
                      :style="widthStyleFor('averageNightsCa')"
                      v-if="doShowColumn('averageNightsCa')"
                    >
                      <hotel-stats-cell
                        v-model="item.averageNightsCa"
                        :with-last-year="withLastYear"
                        :width="widthFor('averageNightsCa')"
                      ></hotel-stats-cell>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr v-if="table.total">
                    <td
                      class="body-2 hotel-cell"
                      :style="widthStyleFor('hotel')"
                      v-if="doShowColumn('hotel')"
                      colspan="2"
                    >
                      <span>
                        <b>TOTAL</b>
                      </span>
                    </td>
                    <td
                      class="body-2 default-cell"
                      :style="widthStyleFor('category')"
                      v-if="doShowColumn('category')"
                    >
                      -
                    </td>
                    <td
                      class="body-2 default-cell"
                      :style="widthStyleFor('destination')"
                      v-if="doShowColumn('destination')"
                    >
                      -
                    </td>
                    <td
                      class="body-2 default-cell"
                      :style="widthStyleFor('chain')"
                      v-if="doShowColumn('chain')"
                    >
                      -
                    </td>
                    <td
                      class="body-2 sub-cell"
                      :style="widthStyleFor('ca')"
                      v-if="doShowColumn('ca')"
                    >
                      <hotel-stats-cell
                        v-model="table.total.ca"
                        tooltip-text="Chiffre d'affaire"
                        :with-last-year="withLastYear"
                        :width="widthFor('ca')"
                      ></hotel-stats-cell>
                    </td>
                    <td
                      class="body-2 sub-cell"
                      :style="widthStyleFor('ha')"
                      v-if="doShowColumn('ha')"
                    >
                      <hotel-stats-cell
                        v-model="table.total.ha"
                        tooltip-text="Achat"
                        :with-last-year="withLastYear"
                        :width="widthFor('ha')"
                      ></hotel-stats-cell>
                    </td>
                    <td
                      class="body-2 sub-cell"
                      :style="widthStyleFor('markup')"
                      v-if="doShowColumn('markup')"
                    >
                      <hotel-stats-cell
                        v-model="table.total.markup"
                        tooltip-text="Marge"
                        :with-last-year="withLastYear"
                        :width="widthFor('markup')"
                        :percent="true"
                      ></hotel-stats-cell>
                    </td>
                    <td
                      class="body-2 sub-cell"
                      :style="widthStyleFor('nights')"
                      v-if="doShowColumn('nights')"
                    >
                      <hotel-stats-cell
                        v-model="table.total.nights"
                        :with-last-year="withLastYear"
                        tooltip-text="Nuités"
                        :width="widthFor('nights')"
                      ></hotel-stats-cell>
                    </td>
                    <td
                      class="body-2 sub-cell"
                      :style="widthStyleFor('pax')"
                      v-if="doShowColumn('pax')"
                    >
                      <hotel-stats-cell
                        v-model="table.total.pax"
                        :with-last-year="withLastYear"
                        tooltip-text="Pax"
                        :width="widthFor('pax')"
                      ></hotel-stats-cell>
                    </td>
                    <td
                      class="body-2 sub-cell"
                      :style="widthStyleFor('adults')"
                      v-if="doShowColumn('adults')"
                    >
                      <hotel-stats-cell
                        v-model="table.total.adults"
                        :with-last-year="withLastYear"
                        tooltip-text="Adultes"
                        :width="widthFor('adults')"
                      ></hotel-stats-cell>
                    </td>
                    <td
                      class="body-2 sub-cell"
                      :style="widthStyleFor('children')"
                      v-if="doShowColumn('children')"
                    >
                      <hotel-stats-cell
                        v-model="table.total.children"
                        :with-last-year="withLastYear"
                        tooltip-text="Enfants"
                        :width="widthFor('children')"
                      ></hotel-stats-cell>
                    </td>
                    <td
                      class="body-2 sub-cell"
                      :style="widthStyleFor('booking')"
                      v-if="doShowColumn('booking')"
                    >
                      <hotel-stats-cell
                        v-model="table.total.booking"
                        :with-last-year="withLastYear"
                        tooltip-text="Dossiers"
                        :width="widthFor('booking')"
                      ></hotel-stats-cell>
                    </td>
                    <td
                      class="body-2 sub-cell"
                      :style="widthStyleFor('averageDuration')"
                      v-if="doShowColumn('averageDuration')"
                    >
                      <hotel-stats-cell
                        v-model="table.total.averageDuration"
                        :with-last-year="withLastYear"
                        tooltip-text="Durée moyenne"
                        :width="widthFor('averageDuration')"
                      ></hotel-stats-cell>
                    </td>
                    <td
                      class="body-2 sub-cell"
                      :style="widthStyleFor('averageNightsCa')"
                      v-if="doShowColumn('averageNightsCa')"
                    >
                      <hotel-stats-cell
                        v-model="table.total.averageNightsCa"
                        :with-last-year="withLastYear"
                        tooltip-text="CA moyen par nuits"
                        :width="widthFor('averageNightsCa')"
                      ></hotel-stats-cell>
                    </td>
                  </tr>
                </tfoot>
              </template>
            </v-simple-table>
          </div>
        </div>
      </div>
    </template>
  </widget-container>
</template>

<script>
import WidgetContainer from "@/components/commons/widgets/WidgetContainer";
import HotelStatsCell from "../../../views/stats/HotelStatsCell";
import SmartMultiSelect from "../SmartMultiSelect";
import clipboard from "../../../utils/clipboard";
import WidgetHotelStatDesti from "./WidgetHotelStatDesti";
import WidgetHotelStatMixin from "../../mixins/WidgetHotelStatMixin";
import _ from "lodash";

export default {
  name: "WidgetHotelStat",
  components: {
    SmartMultiSelect,
    HotelStatsCell,
    WidgetContainer,
    WidgetHotelStatDesti,
  },
  mixins: [WidgetHotelStatMixin],
  props: {
    bus: {
      type: Object,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
    withLastYear: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    const sub = [
      { text: "N", value: "value", sorted: null },
      { text: "N-1", value: "previousValue", sorted: null },
      { text: "Diff", value: "diff", sorted: null },
      { text: "%", value: "percentageDiff", sorted: null },
    ];
    return {
      table: {
        headers: [
          {
            text: "Hôtel",
            value: "hotel",
            class: "text-center",
            icon: "",
            fullSize: 220,
            smallSize: 220,
            sorted: null,
          },
          {
            text: "Cat.",
            value: "category",
            class: "text-center",
            icon: "",
            fullSize: 70,
            smallSize: 70,
            sorted: null,
          },
          {
            text: "Desti",
            value: "destination",
            class: "text-center",
            icon: "",
            fullSize: 120,
            smallSize: 120,
            sorted: null,
          },
          {
            text: "Chaine",
            value: "chain",
            class: "text-center",
            icon: "",
            fullSize: 120,
            smallSize: 120,
            sorted: null,
          },
          {
            text: "CA",
            value: "ca",
            class: "text-center",
            icon: "",
            sub: _.cloneDeep(sub),
            fullSize: 220,
            smallSize: 100,
            sorted: null,
          },
          {
            text: "HA",
            value: "ha",
            class: "text-center",
            icon: "",
            sub: _.cloneDeep(sub),
            fullSize: 220,
            smallSize: 100,
            sorted: null,
          },
          {
            text: "Marge",
            value: "markup",
            class: "text-center",
            icon: "",
            sub: _.cloneDeep(sub),
            fullSize: 220,
            smallSize: 75,
            sorted: null,
          },
          {
            text: "Nuités",
            value: "nights",
            class: "text-center",
            icon: "",
            sub: _.cloneDeep(sub),
            fullSize: 220,
            smallSize: 75,
            sorted: null,
          },
          {
            text: "Pax",
            value: "pax",
            class: "text-center",
            icon: "",
            sub: _.cloneDeep(sub),
            fullSize: 220,
            smallSize: 70,
            sorted: null,
          },
          {
            text: "AD",
            value: "adults",
            class: "text-center",
            icon: "",
            sub: _.cloneDeep(sub),
            fullSize: 220,
            smallSize: 70,
            sorted: null,
          },
          {
            text: "CH",
            value: "children",
            class: "text-center",
            icon: "",
            sub: _.cloneDeep(sub),
            fullSize: 220,
            smallSize: 70,
            sorted: null,
          },
          {
            text: "Dos.",
            value: "booking",
            class: "text-center",
            icon: "",
            sub: _.cloneDeep(sub),
            fullSize: 220,
            smallSize: 70,
            sorted: null,
          },
          {
            text: "Moy durée",
            value: "averageDuration",
            class: "text-center",
            icon: "",
            sub: _.cloneDeep(sub),
            fullSize: 220,
            smallSize: 120,
            sorted: null,
          },
          {
            text: "Moy CA/nuits",
            value: "averageNightsCa",
            class: "text-center",
            icon: "",
            sub: _.cloneDeep(sub),
            fullSize: 220,
            smallSize: 150,
            sorted: null,
          },
        ],
      },
    };
  },
  created() {
    this.$nextTick(() => {
      this.bus.$on("search", (searchform) => {
        this.$emit("loading", true);
      });
    });
  },
  destroyed() {
    this.bus.$off("search");
  },
  watch: {},
  computed: {
    tableWidth() {
      return this._tableWidth();
    },
  },
  methods: {
    doShowColumn(headerValue) {
      return this._doShowColumn(headerValue);
    },
    widthFor(key) {
      return this._widthFor(key);
    },
    widthStyleFor(key) {
      return this._widthStyleFor(key);
    },
    setData(data) {
      this.table.items = data.items;
      this.table.total = data.total;
      this.$refs.widgethoteldesti.setData(data.items);
      this.$emit("loading", false);
    },
    sort(header) {
      this._sort(header);
    },
    copySummary() {
      this.$refs.widgethoteldesti.copy();
    },
    copy() {
      const defaultSeparator = "\t";
      const decimalOpts = { maximumFractionDigits: 0, useGrouping: false };
      const cell = (key, item) => {
        return !this.doShowColumn(key)
          ? ""
          : `${this.$options.filters.decimal(
              item[key].value,
              decimalOpts
            )}${defaultSeparator} ` +
              (!this.withLastYear
                ? ""
                : `${this.$options.filters.decimal(
                    item[key].previousValue,
                    decimalOpts
                  )}${defaultSeparator} ${this.$options.filters.decimal(
                    item[key].diff,
                    decimalOpts
                  )}${defaultSeparator} ${this.$options.filters.decimal(
                    item[key].percentageDiff,
                    decimalOpts
                  )}${defaultSeparator} `);
      };

      const cellSimple = (key, item) =>
        this.doShowColumn(key) ? `${item[key]}${defaultSeparator} ` : "";

      const headerName = (headerValue) =>
        this.table.headers.find((h) => h.value === headerValue).text ||
        headerValue;

      const header =
        this.table.selectedHeaders.length > 0
          ? this.table.selectedHeaders.map((h) => {
              if (
                this.withLastYear &&
                h != "hotel" &&
                h != "destination" &&
                h != "chain" &&
                h != "category"
              ) {
                return `${headerName(
                  h
                ).toUpperCase()}${defaultSeparator} ${defaultSeparator} ${defaultSeparator} ${defaultSeparator} `;
              } else {
                return `${headerName(h).toUpperCase()}${defaultSeparator} `;
              }
            })
          : this.table.headers.map((h) => h.value);

      const simpleCells = ["hotel", "destination", "chain", "category"];
      const subHeader = !this.withLastYear
        ? []
        : this.table.selectedHeaders.map((h) => {
            if (simpleCells.indexOf(h) >= 0) {
              return defaultSeparator;
            } else {
              return `N${defaultSeparator} N-1${defaultSeparator} DIFF${defaultSeparator} %${defaultSeparator} `;
            }
          });

      const rows = _.flatten([header.join(" "), subHeader.join(" ")]).concat(
        this.table.items.map((item) => {
          return `${cellSimple("hotel", item)} ${cellSimple(
            "category",
            item
          )} ${cellSimple("destination", item)} ${cellSimple(
            "chain",
            item
          )} ${cell("ca", item)} ${cell("ha", item)} ${cell(
            "markup",
            item
          )} ${cell("nights", item)} ${cell("pax", item)} ${cell(
            "adults",
            item
          )} ${cell("children", item)} ${cell("booking", item)} ${cell(
            "averageDuration",
            item
          )} ${cell("averageNightsCa", item)}`;
        })
      );

      clipboard.copyTextToClipboard(rows.join("\n"));
    },
  },
};
</script>

<style scoped lang="less">
table {
  box-sizing: border-box;
  border-collapse: collapse;

  tbody {
    border-top: 1px solid #d1d1d1;
  }

  tbody tr:nth-of-type(even) {
    background-color: #eeeeee;
  }

  tbody tr td.odd {
    background-color: #eeeeee;
  }

  thead tr:first-child {
    .hotel-cell .default-cell .sub-cell {
      border: 1px solid #d1d1d1;
    }
  }

  thead tr:nth-child(2) {
    height: 30px;
  }

  thead {
    th {
      > div {
        height: 100%;
        height: 100%;
        display: block;
        text-align: center;
        line-height: 35px;
      }

      div,
      span {
        &:hover {
          cursor: pointer;

          .v-icon {
            opacity: 0.6;
          }
        }

        &.sorted {
          .v-icon {
            opacity: 1;
          }
        }

        &.desc {
          .v-icon {
            transform: rotate(-180deg);
          }
        }

        .v-icon {
          opacity: 0;
        }
      }
    }
  }

  tfoot tr {
    height: 30px;

    td {
      font-weight: bold;
    }
  }
}

tr {
  .hotel-cell {
    height: 48px !important;
    position: absolute;
    background: #fff;
    z-index: 1;
    padding: 0 !important;
    margin: 0 !important;
    border-right: 2px solid #d1d1d1;

    > span {
      position: relative;
      top: 10%;
      left: 5px;
    }

    &.head {
      height: 36px !important;
    }
  }

  .default-cell {
    height: 30px !important;
    //width: 120px ;
    padding: 0 !important;
    margin: 0 !important;
    text-align: center;
    line-height: 14px;
    vertical-align: center;
    border-right: 2px solid #d1d1d1;
  }

  .sub-cell {
    height: 30px !important;
    padding: 0 !important;
    margin: 0 !important;
    text-align: center;
    line-height: 14px;
    vertical-align: center;
    border-right: 2px solid #d1d1d1;
  }

  .cell-subheader {
    margin: 0 !important;
    padding: 0 !important;
  }

  .cell-subheader-content-container {
    width: 55px;
    display: inline-block;

    &:not(:first-child) {
      border-left: 1px solid #d1d1d1;
    }
  }

  .cell-subheader-content {
    height: 100%;
    top: 30%;
    width: 55px;
    position: relative;
  }
}
</style>
