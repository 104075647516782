var render = function render(){var _vm=this,_c=_vm._self._c;return _c('layout',{attrs:{"drawerShow":"","drawerWidth":400}},[_c('template',{slot:"nav"},[_c('nav-exports')],1),_c('template',{slot:"content"},[_c('v-container',[_c('v-card',[_c('v-data-table',{staticClass:"tbl",attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"no-data-text":"-","hide-default-footer":"","hide-default-header":"","footer-props":{
            'items-per-page-options': _vm.rowsPerPageItems,
            'items-per-page-text': 'Lignes par page',
          }},scopedSlots:_vm._u([{key:"header",fn:function({props: {headers}}){return [_c('thead',{staticClass:"v-data-table-header"},[_c('tr',_vm._l((headers),function(header){return _c('th',{key:header.value,class:[
                    'column sortable',
                    _vm.pagination.descending ? 'desc' : 'asc',
                    header.value === _vm.pagination.sortBy ? 'active' : '',
                    header.value == 'price' ||
                    header.value == 'priceWithSupplierInvoice'
                      ? 'bl'
                      : '',
                    'text-xs-' + (header.align || 'left'),
                  ]},[_vm._v(" "+_vm._s(header.text)+" ")])}),0)])]}},{key:"item.ecriture_vt",fn:function({item}){return [_c('span',{class:{ 'red--text': item.vt != item.ecriture_vt }},[_vm._v(_vm._s(item.ecriture_vt))])]}},{key:"item.ecriture_ha",fn:function({item}){return [_c('span',{class:{ 'red--text': item.ha != item.ecriture_ha }},[_vm._v(_vm._s(item.ecriture_ha))])]}}])})],1)],1)],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }