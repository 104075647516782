<template>
  <v-select
    dense
    v-model="selected"
    :items="items"
    v-bind="options"
    @change="select"
    placeholder="Aucun"
  >
    <v-list-item slot="prepend-item" ripple @click="toggleAll">
      <v-list-item-action>
        <v-icon :color="selected > 0 ? 'indigo darken-4' : ''">{{ icon }}</v-icon>
      </v-list-item-action>
      <v-list-item-title>Tous</v-list-item-title>
    </v-list-item>
    <v-divider slot="prepend-item" class="mt-2"></v-divider>

    <slot slot="selection" slot-scope="{ item, index }">
      <span v-if="allSelected && index === 0">
        <v-chip small>Tous</v-chip>
      </span>
      <span v-else-if="!allSelected">
        <v-chip small>{{item[options['item-text']]}}</v-chip>
      </span>
    </slot>
  </v-select>
</template>

<script>
export default {
  name: "SmartMultiSelect",
  mixins: [],
  props: {
    value: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: true,
      default: () => [],
    },
    itemValueName: {
      type: String,
      required: false,
      default: "id",
    },
    allIsSameAsEmpty: {
      //$emit event will replace returned array by an empty array if this props is true
      type: Boolean,
      required: false,
      default: false,
    },
    defaultSelectAll: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      selected: [],
      options: {
        "item-text": "name",
        "item-value": "id",
        multiple: true,
      },
    };
  },
  created() {
    this.list = this.items;
    this.selected =
      this.defaultSelectAll && this.value.length == 0
        ? this.list.map((l) => l[this.itemValueName]).slice()
        : this.value;
    //this.select()
  },
  watch: {
    $attrs: {
      deep: true,
      immediate: true,
      handler(newVal) {
        this.options = { ...this.options, ...newVal };
      },
    },
    value: {
      immediate: true,
      handler(newVal) {
        this.selected = newVal;
      },
    },
  },
  computed: {
    allSelected() {
      return this.selected.length == this.items.length;
    },
    haveSelected() {
      return this.selected.length > 0 && !this.allSelected;
    },
    icon() {
      if (this.allSelected) return "check_box";
      if (this.haveSelected) return "mdi-minus-box";
      else return "mdi-checkbox-blank-outline";
    },
  },
  methods: {
    toggleAll() {
      this.$nextTick(() => {
        if (this.allSelected) {
          this.selected = [];
        } else {
          this.selected = this.items.slice();
        }
        const selected = this.options["return-object"]
          ? this.selected
          : this.selected.map(
              (selected) => selected[this.options["item-value"]]
            );
        this.$emit("input", selected);
      });
    },
    select() {
      let input = null;
      if (this.selected.length == this.list.length && this.allIsSameAsEmpty) {
        input = [];
      } else {
        input = [...[this.selected], ...[]].flat();
      }
      this.focus = false;
      this.$emit("input", input);
    },
  },
};
</script>

<style scoped>
</style>
