<template>
  <layout :drawerShow="_isDesktop()" :drawer="true" :drawerWidth="350">
    <template slot="drawer">
      <v-form
        ref="searchform"
        v-model="valid"
        v-if="searchform"
        @submit.prevent="loadData"
      >
        <v-row dense>
          <v-col cols="12" class="mt-2">
            <smart-multi-select
              v-model="searchform.selectedSites"
              label="Sites"
              :items="virtualSites"
              :all-is-same-as-empty="true"
              :default-select-all="true"
            >
            </smart-multi-select>
          </v-col>
          <v-col cols="12">
            <smart-multi-select
              v-model="searchform.selectedDestinations"
              label="Destination"
              :items="destinations"
              :all-is-same-as-empty="true"
              :default-select-all="true"
            >
            </smart-multi-select>
          </v-col>
          <v-col cols="6" class="pr-1 mt-2">
            <smart-select
              v-model="searchform.selectedChains"
              item-text="name"
              item-value="id"
              label="Chaines"
              :default-select-all="true"
              :all-is-same-as-empty="true"
              :loading="loadingChains"
              :items="chains"
              :chip-style="{ width: '120px' }"
            ></smart-select>
          </v-col>
          <v-col cols="6" class="pr-1 mt-2">
            <smart-select
              v-model="searchform.selectedHotels"
              item-text="name"
              item-value="id"
              label="Hotels"
              :default-select-all="true"
              :all-is-same-as-empty="true"
              :loading="loadingHotels"
              :items="hotels"
              :chip-style="{ width: '120px' }"
            ></smart-select>
          </v-col>
          <v-col cols="12" class="">
            <v-subheader light class="pa-0 mb-0 mx-0 mt-2 form-subheader"
              >Dates de réservation | Dossier
            </v-subheader>
            <v-divider></v-divider>
          </v-col>
          <v-col class="pr-1" cols="6">
            <smart-picker
              v-model="searchform.bookingStartDate"
              :inputOpts="{ label: $t('begin') }"
              label="Début de réservation"
              :next-picker="$refs.bookingStopDate"
              :stopDate="searchform.bookinStopDate"
            />
          </v-col>
          <v-col cols="6">
            <smart-picker
              v-model="searchform.bookingStopDate"
              :inputOpts="{ label: $t('end') }"
              label="Fin de réservation"
              :startDate="searchform.bookingStartDate"
              :min="searchform.bookingStartDate"
              ref="bookingStopDate"
            />
          </v-col>
          <v-col cols="12" class="mt-3">
            <v-subheader light class="pa-0 mb-0 mx-0 mt-2 form-subheader"
              >Dates de voyage | Prestation
            </v-subheader>
            <v-divider></v-divider>
          </v-col>
          <v-col class="pr-1" cols="6">
            <smart-picker
              v-model="searchform.travelStartDate"
              :inputOpts="{ label: $t('begin') }"
              label="Début de prestation"
              :next-picker="$refs.travelStartDate"
              :stopDate="searchform.travelStartDate"
            />
          </v-col>
          <v-col cols="6">
            <smart-picker
              v-model="searchform.travelStopDate"
              :inputOpts="{ label: $t('end') }"
              label="Fin de prestation"
              :startDate="searchform.travelStopDate"
              :min="searchform.travelStartDate"
              ref="stopDate"
            />
          </v-col>
          <v-col cols="6">
            <v-subheader light class="pa-0 mt-5 mb-0 mx-0 form-subheader"
              >Statut Dossier</v-subheader
            >
            <v-divider></v-divider>
          </v-col>
          <v-col cols="6">
            <v-subheader light class="pa-0 mt-5 form-subheader"
              >Contexte</v-subheader
            >
            <v-divider></v-divider>
          </v-col>
          <v-col cols="6">
            <v-checkbox
              v-for="(bs, index) in bookingStatus"
              :key="'bs-' + index"
              v-model="searchform.bookingStatus"
              :label="bs.label"
              :value="bs.value"
              :class="'pt-1 ' + (index == 0 ? 'mt-2' : 'mt-0')"
              height="1"
            >
            </v-checkbox>
          </v-col>
          <v-col cols="6">
            <v-radio-group class="mt-0" v-model="searchform.context">
              <v-radio
                v-for="(bs, index) in context"
                :key="'bs-' + index"
                :label="bs.label"
                :value="bs.value"
              >
              </v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="mt-4" cols="12">
            <smart-btn
              type="submit"
              :loading="loading"
              block
              primary
              class="mt-5"
              :disabled="
                (searchform.startDate && !searchform.stopDate) ||
                (!searchform.startDate && searchform.stopDate)
              "
              >Rechercher
            </smart-btn>
          </v-col>
        </v-row>
      </v-form>
    </template>
    <template slot="content">
      <v-container fluid>
        <widget-travel-booking-stats
          @loading="setLoading"
          :url="url"
          :selectedContext="selectedContext"
          :bus="eventBus"
          :searchform="searchform"
        />
      </v-container>
    </template>
  </layout>
</template>
<script>
import Vue from "vue";
import SmartMultiSelect from "../../components/commons/SmartMultiSelect";
import SmartSelect from "../../components/commons/SmartSelect";
import FormMixin from "../../components/mixins/FormMixin";
import Layout from "../Layout";
import { EventBus } from "@/components/commons/event-bus";
import { get } from "@/utils/api";

import WidgetTravelBookingStats from "../../components/commons/widgets/WidgetTravelBookingStats.vue";

export default {
  name: "TravelBookingStats",
  mixins: [FormMixin],
  components: {
    Layout,
    SmartMultiSelect,
    SmartSelect,
    WidgetTravelBookingStats,
  },

  data() {
    return {
      eventBus: new Vue(),
      url: "/api/widget/booking-travel",

      valid: true,

      searchform: {
        destinations: [],
        bookingStartDate: null,
        bookingStopDate: null,
        travelStartDate: null,
        travelStopDate: null,
        selectedSites: [],
        selectedChains: [],
        selectedHotels: [],
        selectedDestinations: [],
        bookingStatus: ["booked"],
        context: "ca",
      },

      //
      loadingChains: false,
      loadingHotels: false,
      searchAgencyInput: "",
      //
      sites: [],
      virtualSites: [],
      chains: [],
      hotels: [],
      destinations: [],

      bookingStatus: [
        { value: "quotation", label: "Devis" },
        { value: "booked", label: "Réservation" },
        { value: "cancelled", label: "Annulé" },
      ],

      context: [
        { value: "ca", label: "Chiffre d'affaire" },
        { value: "night", label: "Nuits" },
        { value: "pax", label: "Passagers" },
      ],
      selectedContext: "ca",
      loading: false,
    };
  },

  created() {
    this.eventBus.$on("ready", () => {
      this.selectedContext = this.searchform.context;
    });
    this.init();
  },
  computed: {},

  methods: {
    init() {
      const loadVirtualSites = get("/api/search/virtual-sites").json(
        (sites) => {
          this.virtualSites = sites;
          this.searchform.selectedSites = this.virtualSites.map((s) => s.id);
        }
      );
      const loadChains = get("/api/search/chains").json((chains) => {
        this.chains = chains;
      });

      const loadHotels = get("/api/search/hotels").json((hotels) => {
        this.hotels = hotels;
      });

      const loadDesti = get("/api/search/destinations").json((destinations) => {
        this.destinations = destinations;
        this.searchform.selectedDestinations = this.destinations.map(
          (s) => s.id
        );
      });

      Promise.all([loadVirtualSites, loadChains, loadHotels, loadDesti]).then(
        () => {
          this.initForm();
          this.loadData();
        }
      );
    },
    initForm() {
      const frFormat = "dd/MM/yyyy";
      this.searchform.bookingStartDate = this._formatDate(
        this._getFirstMonthDay(new Date()),
        frFormat
      );
      this.searchform.bookingStopDate = this._formatDate(
        this._getLastMonthDay(new Date()),
        frFormat
      );
      this.searchform.travelStartDate = this._formatDate(
        this._getFirstMonthDay(new Date()),
        frFormat
      );
      this.searchform.travelStopDate = this._formatDate(
        this._getLastMonthDay(this._addYears(new Date(), 1)),
        frFormat
      );
    },
    loadData() {
      if (!this.loading) {
        const frFormat = "dd/MM/yyyy";
        const isoFormat = "yyyy-MM-dd";

        const form = {
          ...this.searchform,
          bookingStartDate: this._parseAndFormatDate(
            this.searchform.bookingStartDate,
            frFormat,
            isoFormat
          ),
          bookingStopDate: this._parseAndFormatDate(
            this.searchform.bookingStopDate,
            frFormat,
            isoFormat
          ),
          travelStartDate: this._parseAndFormatDate(
            this.searchform.travelStartDate,
            frFormat,
            isoFormat
          ),
          travelStopDate: this._parseAndFormatDate(
            this.searchform.travelStopDate,
            frFormat,
            isoFormat
          ),
        };

        this.eventBus.$emit("search", form);
      }
    },
    setLoading(loading) {
      this.loading = loading;
    },
  },
};
</script>

<style scoped></style>
