var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticStyle:{"height":"100%","width":"100%","background-color":"white"},attrs:{"elevation":1}},[_c('div',{staticClass:"d-flex flex-row justify-space-between pl-5 pt-5 pr-5"},[_c('div',{staticClass:"font-weight-bold text-lg"},[_vm._t("title",function(){return [_vm._v(" Default Title")]})],2),(_vm.nbTabs != 0)?_c('div',[(_vm.headersList != null && _vm.headersList.length > 0)?_c('div',{staticClass:"d-flex"},_vm._l((_vm.headersList),function(head,ind){return _c('div',{key:head.text},[(ind > 0)?_c('span',{staticClass:"mx-1"},[_vm._v("|")]):_vm._e(),(head.value !== 'name')?_c('span',{class:_vm.headers[1].value === head.value
                ? 'font-weight-black pointer-event-none'
                : 'text-decoration-underline cursor-pointer',on:{"click":function($event){return _vm.changeView(head.text)}}},[_vm._v(_vm._s(_vm.$t(`dashboard.tableHeaders.${head.value}`))+" ")]):_vm._e()])}),0):_c('div',[_c('span',{class:_vm.headers[1].value == 'sales'
            ? 'font-weight-black '
            : 'text-decoration-underline cursor-pointer',on:{"click":function($event){return _vm.changeView('sales')}}},[_vm._v("Vente")]),_vm._v(" | "),_c('span',{class:_vm.headers[1].value == 'purchases'
            ? 'font-weight-black pointer-event-none'
            : 'text-decoration-underline cursor-pointer',on:{"click":function($event){return _vm.changeView('purchases')}}},[_vm._v("Achat")]),_vm._v(" | "),_c('span',{class:_vm.headers[1].value == 'profits'
            ? 'font-weight-black pointer-event-none'
            : 'text-decoration-underline cursor-pointer',on:{"click":function($event){return _vm.changeView('profits')}}},[_vm._v("Marge")])])]):_vm._e()]),_c('v-row',{staticClass:"pa-5",staticStyle:{"height":"100%"}},[_c('v-col',{staticStyle:{"height":"95%"},attrs:{"cols":"12"}},[(_vm.searchable)?_c('v-text-field',{attrs:{"label":"Search","prepend-inner-icon":"mdi-magnify","single-line":"","variant":"outlined","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}):_vm._e(),_c('div',{staticStyle:{"height":"90%","overflow-scrolling":"auto","overflow":"scroll"}},[_c('v-data-table',{attrs:{"items-per-page":1000,"search":_vm.search,"loading":_vm.items.length < 1 && _vm.loading,"headers":_vm.headers,"items":_vm.items,"sort-by":[_vm.headers[1].value],"sort-desc":[true],"search-field":"name","item-key":"name"},scopedSlots:_vm._u([{key:"item.name",fn:function({item}){return [_c('div',{staticClass:"ellipsis"},[_vm._v(_vm._s(item.name))])]}},{key:_vm.getCurrentSlotName,fn:function({ item }){return [_c('v-row',{staticClass:"d-flex justify-end align-center text-xs"},[(_vm.nbTabs === 0)?_c('div',{staticClass:"d-flex flex-row justify-space-between"},[_c('span',{},[_vm._v(" "+_vm._s(item.isPrice == false ? item[_vm.currentTarget] : _vm._formatCurrency(Math.round(item[_vm.currentTarget]), "EUR"))+" ")]),_c('div',{staticStyle:{"width":"100px"}},[_c('v-chip',{attrs:{"small":"","outlined":"","color":_vm.getDiffPercent(item[_vm.currentTarget], item.total) != null && _vm.getDiffPercent(item[_vm.currentTarget], item.total)[0] === '-'
                    ? (_vm.reverseChips ? 'green':'red')
                    : (_vm.reverseChips ? 'red':'green')}},[_vm._v(" "+_vm._s(item[_vm.currentTarget] != null ? _vm.getDiffPercent(item[_vm.currentTarget], item.total, true) : _vm.getDiffPercent(0, 0, true))+" ")])],1)]):_c('div',{staticClass:"d-flex flex-column"},[_c('span',[_vm._v(" "+_vm._s(item.isPrice == false || _vm.getCurrentSlotName == 'item.count' ? item[_vm.currentTarget] != null ? item[_vm.currentTarget] : '-' : _vm._formatCurrency(Math.round(item[_vm.currentTarget]), "EUR"))+" ")]),(item.last != null)?_c('span',{staticClass:"caption grey--text lighten-1"},[_vm._v(" "+_vm._s(item.last.isPrice == false || _vm.getCurrentSlotName == 'item.count' ? item.last[_vm.currentTarget] != null ? item.last[_vm.currentTarget] : 0 : _vm._formatCurrency(Math.round(item.last[_vm.currentTarget]), "EUR"))+" ")]):_vm._e()]),(item.last != null)?_c('div',{staticStyle:{"width":"100px"}},[_c('v-chip',{attrs:{"small":"","outlined":"","color":_vm.getDiffPercent(item[_vm.currentTarget], item.last[_vm.currentTarget]) != null && _vm.getDiffPercent(item[_vm.currentTarget], item.last[_vm.currentTarget])[0] === '-'
                    ? (_vm.reverseChips ? 'green':'red')
                    : (_vm.reverseChips ? 'red':'green')}},[_vm._v(" "+_vm._s(item[_vm.currentTarget] != null ? _vm.getDiffPercent(item[_vm.currentTarget], item.last[_vm.currentTarget], true) : _vm.getDiffPercent(0, 0, true))+" ")])],1):_vm._e()])]}},{key:"item.date",fn:function({ item }){return [_c('v-row',{staticClass:"d-flex items-center justify-end text-xs"},[_c('span',[_vm._v(" "+_vm._s(_vm._formatDate(new Date(item.date), "dd MMMM yyyy")))]),_c('div',{staticStyle:{"width":"100px"}},[_c('v-chip',{attrs:{"small":"","outlined":"","color":item.evol[0] === '-'
                    ? (_vm.reverseChips ? 'green':'red')
                    : (_vm.reverseChips ? 'red':'green')}},[_vm._v(" "+_vm._s(item.evol)+" ")])],1)])]}},{key:"body.append",fn:function(){return [(_vm.showTotal)?_c('tr',{staticClass:"sticky-table-footer flex-row justify-space-between font-weight-medium"},[_c('td',{domProps:{"textContent":_vm._s('Total')}}),_c('td',{staticClass:"text-right"},[_c('div',{staticClass:"row d-flex justify-end text-xs"},[_c('span',[_vm._v(_vm._s(_vm.getTotal.number))]),_c('div',{staticStyle:{"width":"100px"}},[_c('v-chip',{attrs:{"small":"","outlined":"","color":_vm.getTotal.chips[0] === '-'
                    ? (_vm.reverseChips ? 'green':'red')
                    : (_vm.reverseChips ? 'red':'green')}},[_vm._v(" "+_vm._s(_vm.getTotal.chips)+" ")])],1)])])]):_vm._e()]},proxy:true}],null,true)})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }