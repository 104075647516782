<template>
  <v-card ref="vCardRef"
          :elevation="1"
          class="pa-4"
          style="height:100%; width:100%; overflow: hidden">
    <div class="d-flex flex-row justify-space-between mb-8">
      <span class="mr-10 font-weight-bold">
        {{ conf.title }}</span>
      <div>
        <span @click="viewBy('day')"
              :class="actualView == 'day'
              ? 'font-weight-black pointer-event-none'
              : 'text-decoration-underline cursor-pointer'">Jour</span> |
        <span @click="viewBy('week')"
              :class="actualView == 'week'
                ? 'font-weight-black pointer-event-none'
                : 'text-decoration-underline cursor-pointer'">Semaine</span> |
        <span @click="viewBy('month')"
              :class="actualView == 'month'
              ? 'font-weight-black pointer-event-none'
              : 'text-decoration-underline cursor-pointer'">Mois</span>
      </div>
    </div>
    <apexchart
        ref="apexcharts"
        :type="options.chart.type"
        :height="series.length > 0 ? chartHeight : null"
        :options="options"
        :series="series"
        v-if="series.length > 0"
    >

    </apexchart>
  </v-card>
</template>
<script>
import {defineComponent, ref} from "vue";
import {addYears, getDayOfYear, getDaysInYear, isValid} from "date-fns";
import {post} from "@/utils/api";
import _ from "lodash";
import {EventBus} from "@/components/commons/event-bus";
import ColorMixin from "@/components/mixins/ColorMixin";

export default defineComponent({
  name: "CallPerSiteChart",
  components: {},
  props: {
    form: {type: Object, required: true},
    conf: {type: Object, required: false, default: null}
  },
  mixins: [ColorMixin],
  data(vm) {
    return {
      vCardRef: ref(null),
      rawDataTable: null,
      url: `/api/widget/dashboard/chart/callpersite`,
      actualView: 'month',
      importantsDate: [],
      series: [],
      options: {
        stroke: {
          show: true,
          curve: 'smooth',

          width: 3,
          dashArray: 0,
        },
        chart: {
          defaultLocale: 'fr',
          id: "area",
          datetimeUTC: false,
          type: "area",
          stacked: true,
          height: 350,
          zoom: {
            enabled: false,
            autoScaleYaxis: true,
          },
          locales: [{
            name: "fr",
            options: {
              months: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Aout", "Septembre", "Octobre", "Novembre", "Décembre"],
              shortMonths: ["Jan", "Fev", "Mar", "Avr", "Mai", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
              days: ["Dimanche", "Lundi", "Merdi", "Mercredi", "Jeudi", "Vendredi", "Samedi"],
              shortDays: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
            }
          }],
        },
        yaxis: {
          type: 'numeric',
          decimalsInFloat: false,
        },
        xaxis: {
          floating: false,
          position: 'bottom',
          axisTicks: {
            show: false,
          },
          min: new Date(vm.form.startDateAct),
          max: new Date(vm.form.stopDateAct),
          type: 'datetime',
          tickAmount: 'dataPoints',
          labels: {
            datetimeUTC: false,
            show: true,
            datetimeFormatter: {
              year: 'yyyy',
              month: 'MMMM ',
              day: 'dd MMMM',
              hour: 'HH:mm'
            },
            rotate: -45,
            rotateAlways: false,
            hideOverlappingLabels: true,
            showDuplicates: false,
            trim: true,
            minHeight: undefined,
            maxHeight: 120,
            style: {
              fontSize: '12px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 400,
              cssClass: 'apexcharts-xaxis-label',
            },
          }
        },
        dataLabels: {
          enabled: false
        },
        tooltip: {
          x: {
            show: false,
            format: '',
            formatter: undefined,
          },
          fixed: {
            enabled: false,
            position: 'topRight',
            offsetX: 0,
            offsetY: 0,
          },

        },
        fill: {
          //type: 'gradient',
        },
        legend: {position: 'top'},
        annotations: {
          xaxis: [],
        },
      },
      sites: [
        {name:'Hotelissima',color:"#D84315"},
        {name:'Hôtels & Lagons',color:"#FF8F00"},
        {name:'Zil Maurice',color:"#AD1457"}]
    }
  },
  created() {
    EventBus.$on('editMode', () => this.updateSize())
    EventBus.$on('searchDashboard', () => this.init());
    this.init()
  },
  mounted() {
    this.vCardRef = this.$refs.vCardRef.$el.clientHeight
    if (this.conf.chartOptions == null) {
      this.conf.chartOptions = this.options
      delete this.conf.chartOptions.xaxis.min
      delete this.conf.chartOptions.xaxis.max
    } else {
      this.options = {...this.options, ...this.conf.chartOptions}
    }
  },
  computed: {
    chartHeight() {
      return this.vCardRef != null ? `${this.$refs.vCardRef.$el.clientHeight - 100}px` : 'auto';
    },
  },
  methods: {
    init() {
      const searchForm = this._formatPayloadWidgetDashboard(this.conf, this.form)
      this.series = []
      post(this.url, searchForm, {"Content-Type": "application/json"})
          .json(rs => {
            this.rawDataTable = rs
            if (this.conf.view != null) {
              switch (this.conf.view) {
                case 'day':
                  this.viewBy('day')
                  this.actualView = "day"
                  break;
                case 'month':
                  this.viewBy('month')
                  this.actualView = "month"
                  break;
                case "week" :
                  this.viewBy('week')
                  this.actualView = "week"
                  break;
              }
            } else {
              this.viewBy('week')
            }
            const self = this

            let annotationLabel = null
            this.importantsDate = this._getHotelissimaImportantDates()
            this.importantsDate.forEach((data => {
              const dateShowed = new Date(data.date)
              dateShowed.setFullYear(new Date().getFullYear())
              this.options.annotations.xaxis.push({
                x: dateShowed.getTime(),
                borderColor: '#999',
                yAxisIndex: 0,
                label: {
                  show: true,
                  textAnchor: "middle",

                  orientation: "horizontal",
                  text: "*",
                  mouseEnter: (o, n) => {

                    const rect = self.$refs.apexcharts.$el.getBoundingClientRect()
                    const y = n.y - rect.y + 25
                    const x = n.x - rect.x + 25

                    annotationLabel = document.createElement("div")

                    annotationLabel.style.position = 'absolute';
                    annotationLabel.style.background = '#775DD0';
                    annotationLabel.style.padding = "5px"
                    annotationLabel.style.color = "#fff"
                    annotationLabel.style.top = y + "px"
                    annotationLabel.style.left = x + "px"
                    annotationLabel.style.transform = "translateX(-50%)"
                    annotationLabel.appendChild(document.createTextNode(this._formatDate(new Date(data.date), "dd/MM/yyyy") + " : " + data.name))

                    self.$refs.apexcharts.$el.appendChild(annotationLabel)

                  },
                  mouseLeave: () => {
                    self.$refs.apexcharts.$el.removeChild(annotationLabel)
                  },
                  style: {
                    color: "#fff",
                    background: '#775DD0'
                  }
                },
              })

            }))
          })


    },
    updateSize() {
      this.vCardRef = this.$refs.vCardRef != null ? this.$refs.vCardRef.$el.clientHeight : 0
    },
    chartByMonth() {
      const r = JSON.parse(JSON.stringify(this.rawDataTable))
      r.forEach(sitesData => {
        let serie =  {name: sitesData.name,
          data: this.groupByMonth(sitesData.data)}
        if( this.sites.find(s => s.name ==sitesData.name) != null){
        serie.color =  this.sites.find(s => s.name ==sitesData.name).color
        }

        this.series.push(serie)
      })
    },

    chartByWeek() {
      const r = JSON.parse(JSON.stringify(this.rawDataTable))
      r.forEach(sitesData => {
        let serie = {
          name: sitesData.name,
          data: this.groupByWeek(sitesData.data)
        }
        if( this.sites.find(s => s.name ==sitesData.name) != null){
        serie.color =  this.sites.find(s => s.name ==sitesData.name).color
        }
        this.series.push(serie)
      })
    },
    chartByDay() {
      const r = JSON.parse(JSON.stringify(this.rawDataTable))
      r.forEach(raw => {
        let serie = {name: raw.name, data:raw.data.map(d => {return [d.date,d.count]})}
        if( this.sites.find(s => s.name ==raw.name) != null){
        serie.color =  this.sites.find(s => s.name ==raw.name).color
        }
        this.series.push(serie)
      })
    },
    groupByMonth(list) {
      const yearData = _.groupBy(list, e => {
        return this._parseAndFormatDate(e.date, "yyyy-MM-dd", 'yyyy-MM')
      })
      return Object.keys(yearData).map((key) => {
        const date = this._parseDate(yearData[key][0].date, "yyyy-MM-dd")
        let moy = 0
        yearData[key].forEach((line) => {
          if (line?.count != null) {
            moy += line.count
          }
        })
        return [date, moy]
      }).sort((a, b) => {
        return a[0] - b[0]
      })
    },
    groupByWeek(list) {
      const yearData = _.groupBy(list, e => {

        const d = this._parseDate(e.date, "yyyy-MM-dd")

        return d.getFullYear() + "-" + Math.ceil(
            (getDayOfYear(d) * 52) / getDaysInYear(d)
        )

      })
      return Object.keys(yearData).map((key) => {
        const date = this._parseDate(yearData[key][0].date, "yyyy-MM-dd")
        let moy = 0
        yearData[key].forEach((line) => {
          if (line?.count != null) {
            moy += line.count
          }
        })

        return [date,  moy ]
      }).sort((a, b) => {
        return a[0] - b[0]
      })
    },
    viewBy(timeUnit) {
      this.options.tooltip.x.format = null
      this.series = []
      this.actualView = timeUnit;
      switch (timeUnit) {
        case 'month': {
          this.options.tooltip.x.format = 'MMMM'
          this.chartByMonth()
          break;
        }
        case 'week': {
          this.options.tooltip.x.format = 'dd MMMM'
          this.chartByWeek()
          break;
        }
        case 'day': {
          this.options.tooltip.x.format = 'dd MMMM'
          this.chartByDay()
          break;
        }

      }
    },
  }
})
</script>
<style scoped>
.cursor-pointer {
  cursor: pointer;
}

.pointer-event-none {
  pointer-events: none;
}
</style>