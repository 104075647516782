var render = function render(){var _vm=this,_c=_vm._self._c;return _c('widget-container',{attrs:{"url":_vm.url,"bus":_vm.bus,"dark":false,"alwaysrender":true,"headlineclass":"","cardclass":"ma-0 pa-1","color":"transparent"},on:{"ready":_vm.setData}},[_c('template',{slot:"content"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"tbl",attrs:{"headers":_vm.listing.globalHeaders,"items":_vm.listing.globalListing,"loading":_vm.listing.loading,"footer-props":{
            'items-per-page-options': _vm.listing.rowsPerPageItems,
            'items-per-page-text': 'Lignes par page',
          },"hide-default-footer":"","no-data-text":"Il n'y a pas de résultat disponible"},scopedSlots:_vm._u([{key:"header.copy",fn:function({ header }){return [_c('v-icon',{staticClass:"pl-2",attrs:{"small":""},on:{"click":function($event){return _vm.copy(_vm.listing.globalListing)}}},[_vm._v(" mdi-clipboard-text-outline ")])]}},{key:"body",fn:function({ items }){return [_c('tbody',_vm._l((items),function(item,idx){return _c('tr',{key:idx},[_c('td',[_vm._v(" "+_vm._s(item.supplierAccountingName ? item.supplierAccountingName : "")+" ")]),_c('td',[_vm._v(" "+_vm._s(item.prestationType ? _vm.$t(item.prestationType) : "")+" ")]),_c('td',[_vm._v(_vm._s(item.nbReservations ? item.nbReservations : 0))]),_c('td',[_vm._v(_vm._s(item.nbPax ? item.nbPax : 0))]),_c('td',[_vm._v(" "+_vm._s(_vm._f("currency")(item.buyAmount,"EUR"))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm._f("currency")(item.sellAmount,"EUR"))+" ")]),_c('td')])}),0)]}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"tbl",attrs:{"headers":_vm.listing.headers,"items":_vm.listing.resultsListing,"loading":_vm.listing.loading,"footer-props":{
            'items-per-page-options': _vm.listing.rowsPerPageItems,
            'items-per-page-text': 'Lignes par page',
          },"hide-default-footer":"","no-data-text":"Il n'y a pas de résultat disponible"},scopedSlots:_vm._u([{key:"header.copy",fn:function({ header }){return [_c('v-icon',{staticClass:"pl-2",attrs:{"small":""},on:{"click":function($event){return _vm.copy(_vm.listing.resultsListing)}}},[_vm._v(" mdi-clipboard-text-outline ")])]}},{key:"body",fn:function({ items }){return [_c('tbody',_vm._l((items),function(item,idx){return _c('tr',{key:idx},[_c('td',[_vm._v(" "+_vm._s(item.supplierAccountingName ? item.supplierAccountingName : "")+" ")]),_c('td',[_vm._v(" "+_vm._s(item.prestationType ? _vm.$t(item.prestationType) : "")+" ")]),_c('td',[_vm._v(_vm._s(item.prestationName ? item.prestationName : ""))]),_c('td',[_vm._v(_vm._s(item.nbReservations ? item.nbReservations : 0))]),_c('td',[_vm._v(_vm._s(item.nbPax ? item.nbPax : 0))]),_c('td',[_vm._v(" "+_vm._s(_vm._f("currency")(item.buyAmount,"EUR"))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm._f("currency")(item.sellAmount,"EUR"))+" ")]),_c('td')])}),0),_c('tfoot',[(items.length > 0 && _vm.listing.resultsListing)?_c('tr',{staticStyle:{"font-weight":"bold"}},[_c('td',{attrs:{"colspan":"3"}},[_vm._v("Total")]),_c('td',[_vm._v(" "+_vm._s(_vm.listing.totalReservations ? _vm.listing.totalReservations : 0)+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.listing.totalPax ? _vm.listing.totalPax : 0)+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.listing.totalBuyAmount,"EUR"))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.listing.totalSellAmount,"EUR"))+" ")]),_c('td')]):_vm._e()])]}}])})],1)],1)],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }