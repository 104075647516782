<template>
  <layout :drawer="false">
    <template slot="nav-tools">
      <smart-btn @click.native="openDialogNew()" class="mr-1">
        <v-icon>mdi-folder-plus</v-icon>
        <span class="pl-1">Nouvelle condition</span>
      </smart-btn>
    </template>
    <template slot="content">
      <v-container fluid>
        <v-card fluid style="max-width: 1000px; margin: auto ">
          <div class="d-flex justify-space-between align-center mx-4 py-6" style="gap: 16px">
            <p style="width: 70%" class="mb-0 font-weight-bold text-h6">Condition de règlement</p>
            <v-text-field
                style="width: 30%"
                v-model="search"
                append-icon="search"
                class="mt-0 pt-0"
                label="Recherche (Nom)"
                hide-details>
            </v-text-field>
          </div>
          <v-data-table
              :headers="headers"
              :items="items"
              :search="search"
              :sort-by="['name']"
              :disable-pagination="true"
              :hide-default-footer="true">
            <template #item.termType="{ item }">
              {{ $t(`paymentterm.${item.termType}`) }}
            </template>
            <template #item.countAgency="{ item }">
              <div class="d-flex justify-space-between">
                <span>{{ item.countAgency }}</span>
                <div class="d-flex">
                  <v-icon @click.native="opendialogEdit(item)" size="16" style="cursor: pointer">mdi-pencil</v-icon>
                  <v-icon @click.native="openDialogDelete(item)" size="16" style="cursor: pointer">mdi-delete</v-icon>
                </div>
              </div>
            </template>
          </v-data-table>
        </v-card>
        <smart-dialog
            v-model="dialogConfirmDelete"
            title="Supprimer la condition de règlement"
            :width="450">
          <div class="mx-4 mt-4 pb-4">
            <p v-if="form.countAgency > 0">Vous ne pouvez pas supprimer cette condition car elle a {{form.countAgency}} agences ratachées</p>
            <p v-else>Voulez vous supprimer la condition de règlement</p>
            <div class="d-flex justify-end">
              <smart-btn :disabled="form.countAgency > 0" @click.native="form.countAgency > 0 ? '' : deletePaymentTerm()">
                Confirmer
              </smart-btn>
            </div>
          </div>
        </smart-dialog>
        <smart-dialog
            @close="init()"
            v-model="dialogAction"
            :title="formAction == 'new' ? 'Créer une condition de règlement' : 'Éditer la condition de règlement'"
            :width="1200">
          <div class="mx-4 mt-4 pb-4">
            <div class="d-flex mb-4" style="gap: 16px" v-if="formError">
              <v-icon color="red" size="16">mdi-alert-circle</v-icon>
              <span class="red--text font-weight-bold">Veuillez remplir correctement les champs</span>
            </div>
            <v-form>
              <v-row>
                <v-col cols="4">
                  <v-text-field label="Nom" v-model="form.name"></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field label="Mail" v-model="form.mail"></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-select
                      :clearable="true"
                      v-model="form.termType"
                      label="Type"
                      :items="termType">
                  </v-select>
                </v-col>
                <v-col cols="2">
                  <v-text-field label="Compte comptable" v-model="form.account"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="d-flex flex-column">
                  <div class="d-flex align-center mb-4" style="gap: 16px">
                    <p class="font-weight-bold mb-0">Type de paiement :</p>
                    <v-icon @click.native="addPaymentTerm()" style="cursor: pointer">mdi-plus</v-icon>
                  </div>
                  <div v-for="(item,i) in form.paymentType" :key="i" :class="i == form.paymentType.length - 1 ? '' : 'mb-4'" class="grey lighten-4 rounded-lg ml-5 mr-5 pa-4">
                    <v-row>
                      <v-col cols="12">
                        <span class="font-weight-bold text-decoration-underline">Paiement {{i+1}}</span>
                      </v-col>
                      <v-col cols="3">
                        <v-select
                            :clearable="true"
                            :items="paymentMethod"
                            label="Méthode de paiement"
                            v-model="item.paymentMethod">
                        </v-select>
                      </v-col>
                      <v-col cols="3" offset="2">
                        <v-text-field v-model="item.daysBeforeCheckin" label="Jours avant départ" type="number"></v-text-field>
                      </v-col>
                      <v-col cols="3">
                        <v-text-field v-model="item.daysBeforeBalance" label="Jours avant solde" type="number"></v-text-field>
                      </v-col>
                      <v-col cols="1" class="d-flex justify-center align-center">
                        <v-icon style="cursor: pointer" @click.native="deletePaymentType(i)">mdi-delete</v-icon>
                      </v-col>
                    </v-row>
                  </div>
                </v-col>
                <v-col cols="12">
                  <div class="d-flex align-center mb-4" style="gap: 16px">
                    <p class="font-weight-bold mb-0">Planning de factures :</p>
                    <v-icon @click.native="addPlanningInvoice()" style="cursor: pointer">mdi-plus</v-icon>
                  </div>
                  <div v-for="(item,i) in form.invoicePlanning" :key="i" :class="i == form.invoicePlanning.length - 1 ? '' : 'mb-4'" class="grey lighten-4 rounded-lg ml-5 mr-5 pa-4">
                    <v-row>
                      <v-col cols="2" class="pb-0">
                        <span class="font-weight-bold text-decoration-underline">Facture {{i+1}}</span>
                      </v-col>
                      <v-col cols="9" class="d-flex justify-center align-center">
                        <span class="font-weight-bold">Dates du séjour</span>
                      </v-col>
                      <v-col cols="2">
                        <v-text-field hide-details style="width: 50%" v-model="item.dayOfMonth" type="number" label="Jours du mois"></v-text-field>
                      </v-col>
                      <v-col cols="9" style="border: solid 1px #BDBDBD" class="rounded-lg">
                        <v-row>
                          <v-col cols="1" class="d-flex align-center justify-center">
                            <span class="font-weight-bold">Début</span>
                          </v-col>
                          <v-col cols="1">
                            <v-text-field v-model="item.start.dayOfMonth" type="number" label="Jour du mois"></v-text-field>
                          </v-col>
                          <v-col cols="3">
                            <v-select
                                label="Mois"
                                :items="month"
                                v-model="item.start.month">
                            </v-select>
                          </v-col>
                          <v-col cols="1" offset="1" class="d-flex align-center justify-center">
                            <span class="font-weight-bold">Fin</span>
                          </v-col>
                          <v-col cols="1">
                            <v-text-field v-model="item.stop.dayOfMonth" type="number" label="Jour du mois"></v-text-field>
                          </v-col>
                          <v-col cols="3">
                            <v-select
                                label="Mois"
                                :items="month"
                                v-model="item.stop.month">
                            </v-select>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="1" class="d-flex align-center justify-center">
                        <v-icon @click.native="deletePlanningInvoice(i)" style="cursor: pointer">mdi-delete</v-icon>
                      </v-col>
                    </v-row>
                  </div>
                </v-col>
              </v-row>
            </v-form>
            <div class="d-flex justify-end mt-8">
              <smart-btn @click.native="PaymentTermAction()">
                Enregistrer
              </smart-btn>
            </div>
          </div>
        </smart-dialog>
      </v-container>
    </template>
  </layout>
</template>
<script>
import Layout from "@/views/Layout";
import { get, post } from "@/utils/api";
import {EventBus} from "@/components/commons/event-bus";

export default {
  name: "Payment-Terms",
  components: {
    Layout
  },
  data(){
    return {
      headers: [
        {
          text:"Id",
          value:"id",
        },
        {
          text:"Nom",
          value:"name",
        },
        {
          text:"Mail",
          value:"mail",
        },
        {
          text:"Type",
          value:"termType",
        },
        {
          text:"Agences ratachées",
          value:"countAgency",
        }
      ],
      items:[],
      dialogConfirmDelete: false,
      dialogAction: false,
      form: {
        name: null,
        mail: null,
        termType: null,
        account: null,
        paymentType: null,
        invoicePlanning: null,
      },
      termType:[
        {
          text: "Solde avant départ",
          value : "before_start"
        },
        {
          text: "Solde avant frais",
          value : "before_fees"
        },
        {
          text: "En compte",
          value : "in_account"
        }
      ],
      paymentMethod:[
        {
          text: "Carte Bancaire",
          value: "CB"
        },
        {
          text: "Virement",
          value: "VIR"
        },
        {
          text: "Prélèvement",
          value: "PREV"
        },
        {
          text: "En compte",
          value: "ACCOUNT"
        }
      ],
      month:[
        {
          text:"Mois précédent",
          value: -1,
        },
        {
          text:"Mois courant",
          value: 0,
        },
        {
          text:"Mois suivant",
          value: 1,
        }
      ],
      formError: false,
      formAction: null,
      search: null
    }
  },
  created() {
    this.init()
  },
  methods:{
    init(){
      const url = "/api/paymentterms/all"
      get(url)
          .badRequest((br) => {
            console.log("Error: ", br)
          })
          .json((data) => {
            this.items = data
          })
    },
    openDialogDelete(item){
      this.form = {
        ...item,
      }
      this.dialogConfirmDelete = true
    },
    async deletePaymentTerm(){
      const url = "/api/paymentterms/delete"
      await post(url, this.form)
          .badRequest((br) => {
            EventBus.$emit("toaster-error", "Erreur lors de la suppression de la condition");
            console.log("Error: ", br)
          })
          .res((res) => {
            EventBus.$emit("toaster-msg", "Condition supprimé");
          })
      this.dialogConfirmDelete = false
      this.init()
    },
    opendialogEdit(item){
      this.formAction = "edit"
      this.formError = false
      this.form = {
        id: item.id,
        name: item.name,
        mail: item.mail,
        paymentType: item.paymentType,
        account: item.account,
        invoicePlanning: item.invoicePlanning,
        termType: item.termType,
      }
      this.dialogAction = true
    },
    addPlanningInvoice(){
      this.form.invoicePlanning.push(
          {
            "dayOfMonth": null,
            "start": { "dayOfMonth": null, "month": null },
            "stop": { "dayOfMonth": null, "month": null }
          }
      )
    },
    addPaymentTerm(){
      this.form.paymentType.push(
          {
            "paymentMethod": null,
            "daysBeforeCheckin": null,
            "daysBeforeBalance": null
          }
      )
    },
    deletePlanningInvoice(index){
      this.form.invoicePlanning.splice(index,1)
    },
    deletePaymentType(index){
      this.form.paymentType.splice(index,1)
    },
    async PaymentTermAction(){
      if(this.verifForm()){
        this.formError = false
        const url = this.formAction == 'new' ? "/api/paymentterms/new" : "/api/paymentterms/edit"
        await post(url,this.form)
            .badRequest((br) => {
              EventBus.$emit("toaster-error", this.formAction == 'new' ? "Erreur lors de la création" : "Erreur lors de l'édition");
              console.log("Error: ", br)
            })
            .res((res) => {
              EventBus.$emit("toaster-msg", this.formAction == 'new' ? "Condition créé" : "Condition édité");
            })
        this.init()
        this.dialogAction = false
      } else{
        this.formError = true
      }
    },
    verifForm(){
      if(!!this.form.name && !!this.form.mail && !!this.form.termType && (!!this.form.account || this.formAction == "edit")){
        return this.verifPaymentType() && this.verifPlanning();
      } else {
        return false
      }
    },
    verifPlanning(){
      return this.form.invoicePlanning.find(x =>
          ((x.dayOfMonth > 0 && x.dayOfMonth <= 31) && (x.start.dayOfMonth > 0 && x.start.dayOfMonth <= 31) && x.start.month !== null &&
              (x.stop.dayOfMonth > 0 && x.stop.dayOfMonth <= 31) && x.stop.month !== null) === false
      ) === undefined
    },
    verifPaymentType(){
      return this.form.paymentType.find(x =>
          (x.paymentMethod !== null && (x.daysBeforeCheckin !== null && x.daysBeforeCheckin !== "" )
              && (x.daysBeforeBalance !== null && x.daysBeforeBalance !== "")) === false
      ) === undefined
    },
    openDialogNew(){
      this.formError = false
      this.formAction = "new"
      this.form = {
        id: 1,
        name: null,
        mail: null,
        paymentType: [],
        account: null,
        invoicePlanning: [],
        termType: null,
      }
      this.dialogAction = true
    }
  }
}
</script>
<style>
.v-text-field__details{
  display: none;
}
</style>
