import {get, post} from "@/utils/api"
import {EventBus} from "@/components/commons/event-bus";

export default {
	props: {
		booking: {type: Object},
		prestation: {type: Object}
	},
	data: () => ({
		disablePanel: false,
		canCancelStatus: ['booked', 'option'],
		canBookStatus: ['quotation'],
		canDeleteStatus: ['quotation'/*, 'cancelled'*/],
		canConfirmStatus: ['booked'],
		canConfirmSupplierStatus: ['ondemand'],
		canRefuseStatus: ['booked', 'cancelled'],
		canRefuseSupplierStatus: ['ondemand']
	}),
	methods: {
		deletePrestationGroup() {
			this.isLoading = !this.isLoading

					this.$root.$confirm(this.$t('delete-prestation'), "Souhaitez vous supprimer cette prestation ?", {width: 350})
						.then((confirm) => {
							if (!confirm) {
								return Promise.resolve()
							} else {
								return get(`/api/prestation-group/${this.prestation.prestationGroupId}/delete`)
									.badRequest(err => {
										EventBus.$emit('toaster-msg', err.toString())
									}).res(() => {
										EventBus.$emit('reloadbooking', {})
										EventBus.$emit('toaster-msg', "Suppression enregistrée")
									})
							}
						}).then(() => this.isLoading = !this.isLoading)

		},
		cancelPrestationGroup() {
			this.isLoading = !this.isLoading

			const url = `/api/prestation-group/${this.prestation.prestationGroupId}/cancelfees`
			get(url)
				.json(feesWithPurchase => {

					this.$root.$confirmcancel(this.$t('cancel-prestation'), "Souhaitez vous annuler cette prestation ?", {width: 350}, feesWithPurchase)
					.then(([confirm, amounts]) => {

						if (!confirm) {
							return Promise.resolve()
						} else {

							const data = amounts != null ? amounts : []
							return post(`/api/prestation-group/${this.prestation.prestationGroupId}/cancel`, data)
								.badRequest(err => {
									EventBus.$emit('toaster-msg', err.toString())
								}).res(() => {
									EventBus.$emit('reloadbooking', {})
									EventBus.$emit('toaster-msg', "Annulation enregistrée")
								})
						}
					}).then(() => this.isLoading = !this.isLoading)

				})

		},

		deletePrestation() {
			this.isLoading = !this.isLoading

					this.$root.$confirm(this.$t('delete-prestation'), "Souhaitez vous supprimer cette prestation ?", {width: 350})
						.then((confirm) => {
							if (!confirm) {
								return Promise.resolve()
							} else {
								return post(`/api/prestation/${this.prestation.id}/delete`)
									.badRequest(err => {
										EventBus.$emit('toaster-msg', JSON.parse(err.null).errors.map(e => JSON.parse(e)))
									}).res(() => {
										EventBus.$emit('reloadbooking', {})
										EventBus.$emit('toaster-msg', "Suppression enregistrée")
									})
							}
						}).then(() => this.isLoading = !this.isLoading)

		},

		cancelPrestation() {
			this.isLoading = !this.isLoading

			const url = `/api/prestation/${this.prestation.id}/cancelfees`
			get(url)
				.json(feesWithPurchase => {

					this.$root.$confirmcancel(this.$t('cancel-prestation'), "Souhaitez vous annuler cette prestation ?", {width: 350}, feesWithPurchase)
						.then(([confirm, amounts]) => {

							if (!confirm) {
								return Promise.resolve()
							} else {

								const data = amounts != null ? amounts : []
								return post(`/api/prestation/${this.prestation.id}/cancel`, data)
									.badRequest(err => {
										EventBus.$emit('toaster-msg', JSON.parse(err.null).errors.map(e => JSON.parse(e)))
									}).res(() => {
										EventBus.$emit('reloadbooking', {})
										EventBus.$emit('toaster-msg', "Annulation enregistrée")
									})
							}
						}).then(() => this.isLoading = !this.isLoading)
				})
		},

		reject() {
			this.isLoading = !this.isLoading

			this.$root.$confirm(this.$t('reject-prestation'), "Cela enverra l'email 'Prestation rejeté' au client. ", {width: 350})
				.then((confirm) => {
					if (!confirm) {
						return Promise.resolve()
					} else {
						return get(`/api/mail/sendreject/${this.booking.id}`)
							.badRequest(err => {
								EventBus.$emit('toaster-msg', JSON.parse(err.null).errors.map(e => JSON.parse(e)))
							}).res(() => {
								EventBus.$emit('reloadbooking', {})
								EventBus.$emit('toaster-msg', "Email envoyé")
							})
					}
				}).then(() => this.isLoading = !this.isLoading)
		},
	}
}
