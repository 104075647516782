<template>
  <widget-container
    :url="url"
    headlineclass
    cardclass="ma-0 pa-1"
    color="transparent"
    :bus="bus"
    :dark="false"
    :flat="true"
    :alwaysrender="true"
    @ready="setData"
  >
    <template slot="content">
      <div>
        <v-col v-if="data" align="center">
          <div class="mx-auto">{{ data.nbAgencies }} Agences</div>
          <div class="body-1">
            {{ data.nbBookings }}
            dossiers
          </div>
        </v-col>
        <v-col v-else> - </v-col>
      </div>
    </template>
  </widget-container>
</template>
<script>
import WidgetContainer from "./WidgetContainer";
export default {
  name: "WidgetNetworkAgencies",
  components: { WidgetContainer },
  props: {
    bus: {
      type: Object,
      required: true,
    },
    url: {
      type: String,
      required: false,
      default: "/api/widget/networkagency-resume",
    },
  },
  data() {
    return {
      data: null,
    };
  },
  created() {},
  destroyed() {
    this.bus.$off("search");
  },
  methods: {
    setData(data) {
      this.data = data;
    },
  },
};
</script>