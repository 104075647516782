<template>
  <layout :drawerShow="_isDesktop()" :drawerWidth="350">
    <template slot="drawer">
      <v-row dense>
        <v-col cols="12">
          <v-autocomplete
            v-model="searchform.supplierAccountings"
            :items="supplierAccounts"
            clearable
            chips
            color="blue-grey lighten-2"
            label="Fournisseurs"
            item-text="text"
            item-value="value"
            multiple
          >
            <template slot="selection" slot-scope="{ item, index }">
              <span>
                <v-chip small>{{ item.text }}</v-chip>
              </span>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="12" class>
          <v-subheader light class="pa-0 mb-0 mx-0 mt-2 form-subheader"
            >Dates de voyage</v-subheader
          >
          <v-divider></v-divider>
        </v-col>
        <v-col cols="6">
          <smart-picker
            v-model="searchform.startDate"
            :inputOpts="{ label: $t('begin') }"
            label="Début"
            :next-picker="$refs.stopDate"
            :stopDate="searchform.stopDate"
          />
        </v-col>
        <v-col cols="6">
          <smart-picker
            v-model="searchform.stopDate"
            :inputOpts="{ label: $t('end') }"
            label="Fin"
            :startDate="searchform.startDate"
            :min="searchform.startDate"
            ref="stopDate"
          />
        </v-col>

        <v-col cols="8" class="mb-0 pb-0">
          <v-subheader
            light
            class="pa-0 mb-0 mx-0 mt-4 form-subheader solo-subheader"
            >Etiquettes</v-subheader
          >
        </v-col>
        <v-col cols="4" class="mb-0 pb-0">
          <v-checkbox
            v-model="searchform.includeTags"
            label="Inclure"
            dense
            hide-details
          />
        </v-col>
        <v-col cols="12" class="mt-0 pt-0">
          <v-divider></v-divider>
        </v-col>

        <v-col cols="12">
          <v-select
            v-model="searchform.tags"
            :items="tags"
            item-text="name"
            item-value="id"
            clearable
            multiple
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="mt-4" cols="12">
          <smart-btn block primary @click.native="search" :loading="loading"
            >Rechercher</smart-btn
          >
        </v-col>
      </v-row>
    </template>
    <template slot="content">
      <v-container fluid>
        <v-row dense justify="space-between">
          <v-col cols="12">
            <v-data-table
              ref="tasktable"
              :loading="loading"
              :headers="listing.headers"
              :items="listing.items"
              :footer-props="{
                'items-per-page-options': [{ text: 'Tous', value: -1 }],
                'rows-per-page-options': 'Lignes par page',
              }"
              item-key="ref"
              class="tbl"
            >
              <template #body="{ items }">
                <tbody>
                  <tr v-for="(item, idx) in items" :key="idx">
                    <td class="text-left">
                      {{ getPrestationType(item.prestationType) }}
                    </td>
                    <td
                      v-for="(purchase, purchaseIndex) in item.sums"
                      :key="purchaseIndex"
                      class="text-left"
                    >
                      {{ purchase.sum }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </layout>
</template>

<script>
import Layout from "../Layout";
import { get, post } from "../../utils/api";
import ColorMixin from "../../components/mixins/ColorMixin";
import FormMixin from "../../components/mixins/FormMixin";
import { EventBus } from "@/components/commons/event-bus";

export default {
  name: "PurchaseStats",
  mixins: [ColorMixin, FormMixin],
  components: {
    Layout,
  },
  data() {
    return {
      valid: true,
      loading: false,
      supplierAccounts: [],
      searchform: {
        supplierAccountings: [],
        startDate: null,
        stopDate: null,
        includeTags: true,
        tags: [],
      },
      tags: [],
      tagsmode: [
        { value: true, text: "Inclure les étiquettes" },
        { value: false, text: "Exclure les étiquettes" },
      ],
      listing: {
        loading: false,
        rowsPerPageItems: [200, 500, { text: "Tous", value: -1 }],
        pagination: {
          rowsPerPage: 200,
        },
        headers: [
          {
            text: "Type de prestations",
            value: "prestationType",
            width: "60px",
          },
        ],
        items: [],
      },
    };
  },
  created() {
    get("/api/search/tags")
      .badRequest((err) => {
        EventBus.$emit("toaster-msg", err.toString());
      })
      .json((tags) => {
        this.tags = tags;
      });

    this.getSupplierAccounts();
  },
  methods: {
    search() {
      this.loading = true;
      const url = "/api/booking/purchasesum";

      let form = {
        ...this.searchform,
        ...{
          startDate: this._parseAndFormatDate(
            this.searchform.startDate,
            this.datePatternConfig.serverLocalDate,
            this.datePatternConfig.en.localDate
          ),
        },
        ...{
          stopDate: this._parseAndFormatDate(
            this.searchform.stopDate,
            this.datePatternConfig.serverLocalDate,
            this.datePatternConfig.en.localDate
          ),
        },
      };
      let headers = [
        {
          text: "Type de prestations",
          value: "prestationType",
          width: "100px !important",
        },
      ];
      post(url, form)
        .badRequest((error) => error)
        .json((data) => {
          this.listing.items = data.sums;
          data.months.map((m) => {
            headers.push({ text: `${m}`, width: "50px" });
          });
          this.listing.headers = [...new Set(headers)];
        })
        .finally(() => {
          this.loading = false;
        });
      //	}
    },
    getPrestationType(prestationType) {
      if (prestationType == "room") {
        return "Hotels";
      } else if (prestationType == "transfer") {
        return "Transferts";
      } else if (prestationType == "flight") {
        return "Vols";
      } else if (prestationType == "carrental") {
        return "Locations de véhicule";
      } else if (prestationType == "insurance") {
        return "Assurances";
      } else if (prestationType == "untyped") {
        return "Non typées";
      }
    },
    getSupplierAccounts() {
      const url = `/api/supplier/get/foraccounting`;

      get(url).json((r) => {
        let accounts = r.map((account) => {
          return { text: account.name, value: account.id };
        });

        this.supplierAccounts = accounts;
      });
    },
    remove(item) {
      const index = this.searchform.supplierAccountings.indexOf(item.text);
      if (index >= 0) this.searchform.supplierAccountings.splice(index, 1);
    },
  },
};
</script>

<style scoped></style>
