<template>
  <layout :drawerShow="_isDesktop()" :drawerWidth="350">
    <template slot="drawer">
      <v-form v-if="searchform" @submit.prevent="search()">
        <v-row dense class="mb-10">
          <v-col cols="12" class="mb-1">
            <v-subheader light class="pa-0 mb-0 mx-0 form-subheader"
            >Dates de départ
            </v-subheader
            >
            <v-divider></v-divider>
          </v-col>
          <v-col cols="6" class="">
            <smart-picker
                label="Début"
                v-model="searchform.startDate"
            ></smart-picker>
          </v-col>
          <v-col cols="6" class="">
            <smart-picker
                label="Fin"
                v-model="searchform.stopDate"
            ></smart-picker>
          </v-col>
        </v-row>
        <smart-btn class="mt-3"
                   :disabled="!formValid"
                   :loading="loading"
                   type="submit"
                   block
                   primary
        >Rechercher
        </smart-btn
        >
        <smart-btn
            block
            tertiary
            @click.native="
            clearSearchform();
          "
        >Réinitialiser la recherche
        </smart-btn
        >
      </v-form>
    </template>
    <template slot="content">
      <v-card style="max-width: 1024px; margin: auto">
        <v-data-table class="pa-3 mt-3"
                      :loading="loading"
                      :headers="headers"
                      :items="items"
                      no-data-text="Aucunes vente ce jour"
                      search-field="name"
                      item-key="name"
                      hide-default-footer
        >
          <template #item.sales="{item}">
            {{ item.sales | currency("EUR") }}
          </template>

          <template #footer>
            <v-divider class="mb-4"/>
            <v-row dense v-if="total.nb != null">
              <v-col cols="6">
                <span class="font-weight-bold ml-4">Total</span>
              </v-col>
              <v-col cols="1">
                <span class="font-weight-bold ml-9">{{total.nb}}</span>
              </v-col>
              <v-col cols="4" class="ml-1">
                <span class="font-weight-bold ml-16">{{total.ca | currency("EUR")}}</span>
              </v-col>
            </v-row>

          </template>
        </v-data-table>
      </v-card>
    </template>
  </layout>
</template>
<script>
import formMixin from "@/components/mixins/FormMixin";
import Layout from "@/views/Layout.vue";
import {post} from "@/utils/api";

export default {
  name: 'payment-term',
  components: {
    Layout,
  },

  mixin: [formMixin],
  data() {
    return {
      searchform: {
        startDate: null,
        stopDate: null
      },
      loading: false,
      items: [],
      total:{nb:null,ca:null},
      headers: [
        {text: "Mode de réglement", value: "termName", sortable: true},
        {text: "Vente", sortable: false, value: "count", align: "center"},
        {text: "Chiffre d'affaires", sortable: false, value: "sales"},
      ],
    }
  },
  computed: {
    formValid() {
      return this.searchform.startDate != null && this.searchform.stopDate != null
    }
  },
  methods: {
    search() {
      this.items = []
      this.loading = true
      const body = {
        startDate: this._parseAndFormatDate(this.searchform.startDate, 'dd/MM/yyyy', 'yyyy-MM-dd'),
        stopDate: this._parseAndFormatDate(this.searchform.stopDate, 'dd/MM/yyyy', 'yyyy-MM-dd'),
      }
      post(`/api/accounting/payments/term`, body).json((rs) => {
        this.items = rs
        this.loading = false
        let total = {nb:0,ca:0}
        for(let i = 0; i< rs.length; i++){
          total.nb += this.items[i].count
          total.ca += this.items[i].sales
        }

        this.total ={
          nb: total.nb,
          ca: total.ca,
        }
      })
    },
    clearSearchform() {
      this.searchform = {startDate: null, stopDate: null};
    }
  }

}
</script>
<script setup>
</script>