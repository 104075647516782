<template>
  <v-container>
    <v-card-text>
      <v-form ref="form" v-model="form" lazy-validation>
        <v-row>
          <v-col cols="9">
            <v-text-field
              v-model="prestation.name"
              label="Nom de la prestation"
              :rules="rules.nonEmpty()"
            />
          </v-col>
          <v-col cols="3">
            <v-select
              v-model="prestation.destination"
              :items="destinationList"
              label="Destination"
              item-value="id"
              item-text="name"
              :rules="rules.nonEmpty()"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2">
            <v-text-field
              reverse
              v-model="prestation.price"
              label="Prix"
              :prefix="booking.context.currency.code"
              :rules="rules.nonEmpty()"
            />
          </v-col>
          <v-col cols="2">
            <v-text-field
              reverse
              v-model="prestation.purchase"
              label="Prix d'achat"
              :rules="rules.nonEmpty()"
            />
          </v-col>
          <v-col cols="2">
            <v-select
              v-model="prestation.purchaseCurrency"
              :items="currencyList"
              label="Devise"
              :rules="rules.nonEmpty()"
            />
          </v-col>
          <v-col cols="3">
            <v-select
              v-model="prestation.status"
              :items="bookingStatusList"
              label="Statut"
              :rules="rules.nonEmpty()"
            >
              <template v-slot:selection="data">{{ $t(data.item) }}</template>
              <template v-slot:item="data">{{ $t(data.item) }}</template>
            </v-select>
          </v-col>
          <v-col cols="3">
            <v-select
              v-model="prestation.supplierStatus"
              :items="supplierStatusList"
              label="Statut Fournisseur"
              :rules="rules.nonEmpty()"
            >
              <template v-slot:selection="data">{{ $t(data.item) }}</template>
              <template v-slot:item="data">{{ $t(data.item) }}</template>
            </v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3">
            <smart-picker
              v-model="prestation.startDate"
              :next-picker="$refs.dateStopPrestationPicker"
              :startDate="prestation.startDate"
              :inputOpts="{
                label: 'Date de début',
                'prepend-inner-icon': 'mdi-calendar',
                rules: rules.nonEmpty(),
              }"
            />
          </v-col>
          <v-col cols="3">
            <smart-picker
              v-model="prestation.stopDate"
              :startDate="prestation.startDate"
              :min="prestation.startDate"
              ref="dateStopPrestationPicker"
              :inputOpts="{
                label: 'Date de fin',
                'prepend-inner-icon': 'mdi-calendar',
                rules: rules.nonEmpty(),
              }"
            />
          </v-col>
          <v-col cols="6">
            <booking-guest-repartition
              v-model="prestation.guests"
              :guests="booking.globalGuests"
            ></booking-guest-repartition>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6">
            <v-autocomplete
              v-model="prestation.supplier"
              :items="supplierList"
              label="Fournisseur"
              item-value="id"
              item-text="name"
              :rules="rules.nonEmpty()"
            />
          </v-col>
          <v-col cols="6">
            <v-autocomplete
              v-model="prestation.supplierAccounting"
              :items="supplierAccountingList"
              label="Fournisseur compta"
              item-value="id"
              item-text="name"
              :rules="rules.nonEmpty()"
            />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions class="pt-0">
      <v-btn color="primary " block :loading="loading" @click="add"
        >Ajouter</v-btn
      >
    </v-card-actions>
  </v-container>
</template>

<script>
import BookingGuestRepartition from "@/components/commons/BookingGuestRepartition";
import { EventBus } from "@/components/commons/event-bus";
import { get, post } from "@/utils/api";
import FormMixin from "@/components/mixins/FormMixin";

export default {
  name: "AddUntyped",
  mixins: [FormMixin],
  components: {
    BookingGuestRepartition,
  },
  props: {
    booking: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      error: false,
      form: false,
      prestation: {
        name: "",
        status: "",
        supplierStatus: "confirmed",
        startDate: "",
        stopDate: "",
        price: null,
        purchase: null,
        purchaseCurrency: "EUR",
        cancelFees: [],
        supplier: null,
        supplierAccounting: null,
        destination: null,
        guests: [],
      },
      supplierList: [],
      supplierAccountingList: [],
      bookingStatusList: ["quotation", "booked"],
      supplierStatusList: ["ondemand", "confirmed"],
      currencyList: ["EUR", "USD"],
      destinationList: [],
    };
  },
  watch: {
    "prestation.startDate": {
      handler(val) {
        let dateA = this._parseDate(val, "dd/MM/yyyy");
        let dateB = this._parseDate(this.prestation.stopDate, "dd/MM/yyyy");

        if (dateA.getTime() > dateB.getTime()) {
          this.prestation.stopDate = val;
        }
      },
    },
  },
  computed: {
    lastPrestationDate: function () {
      if (
        this.booking &&
        this.booking.prestations &&
        this.booking.prestations.length == 0
      ) {
        return this.booking.bookingDate.substring(0, 10);
      } else if (
        this.booking &&
        this.booking.prestations &&
        this.booking.prestations.length > 0
      ) {
        return this.booking.stopDate.substring(0, 10);
      }
      return null;
    },
  },
  created() {
    this.init();
    this.prestation.startDate = this.lastPrestationDate;
    this.prestation.stopDate = this.prestation.startDate;
  },
  methods: {
    init() {
      if (this.booking.status === "booked") {
        this.prestation.status = "booked";
        this.bookingStatusList = ["quotation", "booked"];
      } else {
        this.prestation.status = "quotation";
        this.bookingStatusList = ["quotation"];
      }

      this.prestation.guests = [...this.booking.globalGuests];
      get(`/api/search/destinations`).json(
        (lst) => (this.destinationList = lst)
      );
      get(`/api/supplier/get/forprestation`).json(
        (lst) => (this.supplierList = lst)
      );
      get(`/api/accounting/list-supplier`).json(
        (lst) => (this.supplierAccountingList = lst)
      );
    },
    add() {
      if (this.$refs.form.validate()) {
        const untypedPrestationWithTimes = {
          ...this.prestation,
          ...{ startDate: this.prestation.startDate + " 00:00:00" },
          ...{ stopDate: this.prestation.stopDate + " 00:00:00" },
        };

        const url = `/api/prestation/add/untyped?bookingId=${this.booking.id}`;
        post(url, untypedPrestationWithTimes)
          .badRequest((err) => {
            EventBus.$emit(
              "toaster-error",
              JSON.parse(err.message).errors.join(",")
            );
          })
          .res(() => {
            EventBus.$emit("toaster-msg", this.$t("untypedadded"));
            EventBus.$emit("reloadbooking", {});
            setTimeout(() => {
              EventBus.$emit("prestation-added", {});
            }, 500);
          })
          .then(() => {
            this.loading = false;
          });
      }
    },
  },
};
</script>

<style scoped></style>
