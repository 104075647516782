<template>
  <widget-container
    :url="url"
    headlineclass
    cardclass="ma-0 pa-1"
    color="transparent"
    :bus="bus"
    :dark="false"
    :flat="true"
    :alwaysrender="true"
    @ready="setData"
  >
    <template slot="content">
      <div>
        <v-row class="justify-center px-4">
          <v-col cols="12" md="4" class="pa-2 ma-0">
            <v-row class="mx-auto justify-center">
              <v-col class="text-center align-center"> CA {{ data.ca | currency("EUR") }} </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" md="4" class="pa-2 ma-0">
            <v-row class="mx-auto justify-center">
              <v-col class="text-center align-center"> {{ data.bookings }} Dossiers </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
    </template>
  </widget-container>
</template>
<script>
import WidgetContainer from "./WidgetContainer";
export default {
  name: "WidgetCrmAgencyResume",
  components: { WidgetContainer },
  props: {
    bus: {
      type: Object,
      required: true,
    },
    url: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      loading: false,
      data: [],
    };
  },
  created() {
    this.$nextTick(() => {
      this.bus.$on("search", () => {
        this.loading = true;
      });
    });
  },
  destroyed() {
    this.bus.$off("search");
  },
  methods: {
    setData(data) {
      this.data = data;
      this.loading = false;
    },
  },
};
</script>