<template>
  <widget-container
    :url="url"
    headlineclass=""
    cardclass="ma-0 pa-1"
    color="transparent"
    :bus="bus"
    :dark="false"
    :flat="false"
    :alwaysrender="true"
    @ready="setData"
  >
    <template slot="content" v-if="data">
      <v-data-table
        :loading="table.loading"
        :items="table.items"
        :footer-props="{
          'items-per-page-options': table.rowsPerPageItems,
          'items-per-page-text': 'Lignes par page',
        }"
        :headers="table.headers"
        no-data-text="Aucun résultats"
        class="pa-0 ma-0 tbl"
      >
        <template #item.ca="{ value }">{{
          value | currency("EUR", { maximumFractionDigits: 0 })
        }}</template>
        <template #item.ha="{ value }">{{
          value | currency("EUR", { maximumFractionDigits: 0 })
        }}</template>
        <template #item.taxs="{ value }">{{
          value | currency("EUR", { maximumFractionDigits: 0 })
        }}</template>
        <template #item.avgCA="{ value }">{{
          value | currency("EUR", { maximumFractionDigits: 0 })
        }}</template>
        <template #item.avgCATaxFree="{ value }">{{
          value | currency("EUR", { maximumFractionDigits: 0 })
        }}</template>
        <template #item.markup="{ value }"
          >{{
            (value * 100) | decimal({ maximumFractionDigits: 0 })
          }}%</template
        >
        <template #item.marketShare="{ value }"
          >{{ value | decimal({ maximumFractionDigits: 0 }) }}%</template
        >
      </v-data-table>
    </template>
  </widget-container>
</template>

<script>
import WidgetContainer from "@/components/commons/widgets/WidgetContainer";

export default {
  name: "WidgetFlightAirline",
  components: { WidgetContainer },
  mixins: [],
  props: {
    bus: {
      type: Object,
      required: true,
    },
    url: {
      type: String,
      required: false,
      default: "/api/widget/flights/airlines",
    },
  },
  data() {
    return {
      search: null,
      data: null,
      table: {
        rowsPerPageItems: [{ text: "Tous", value: -1 }],
        loading: false,
        headers: [
          {
            text: "Compagnie",
            value: "airlineCode",
            sortable: true,
            align: "left",
          },
          { text: "Pax", value: "pax", sortable: true, align: "center" },
          { text: "CA", value: "ca", sortable: true, align: "right" },
          { text: "HA", value: "ha", sortable: true, align: "right" },
          { text: "Taxes", value: "taxs", sortable: true, align: "right" },
          { text: "Moy. CA", value: "avgCA", sortable: true, align: "right" },
          {
            text: "Moy. CA HT",
            value: "avgCATaxFree",
            sortable: true,
            align: "right",
          },
          { text: "Marge", value: "markup", sortable: true, align: "right" },
          {
            text: "Part de marché",
            value: "marketShare",
            sortable: true,
            align: "right",
          },
        ],
        items: [],
        footer: [],
      },
    };
  },
  created() {
    this.$nextTick(() => {
      this.bus.$on("search", (searchform) => {
        this.table.loading = true;
        this.$emit("loading", true);
      });
    });
  },
  destroyed() {
    this.bus.$off("search");
  },
  computed: {},
  methods: {
    setData(data) {
      this.data = data;
      this.table.items = this.data.data;
      this.table.loading = false;
      this.$emit("loading", false);
    },
  },
};
</script>

<style scoped></style>
