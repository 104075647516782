<template>
  <v-dialog
    v-model="localValue"
    :persistent="persistent"
    v-bind="options"
    @keydown.esc="close"
  >
    <template slot="activate">
      <slot name="activate"></slot>
    </template>

    <v-card :class="cardClass" class="pa-0 ma-0 bl-0">
      <v-card-title class="pa-0">
        <v-toolbar dense color="light-blue darken-3" class="elevation-0">
          <template v-if="hasTitleSlot">
            <slot name="title"></slot>
          </template>
          <template v-else>
            <v-toolbar-title class="white--text">{{ title }}</v-toolbar-title>
          </template>
          <v-spacer></v-spacer>
          <v-btn icon dark @click.native="close">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
      </v-card-title>
      <v-card-text class="pa-0 modal-content">
        <slot></slot>
      </v-card-text>
      <v-card-actions class="actions-btn" v-if="hasActionSlot">
        <slot name="prependAction"></slot>
        <v-spacer></v-spacer>
        <slot name="actions"></slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "SmartDialog",
  props: {
    value: { type: Boolean, default: false },
    title: { type: String, default: "" },
    loading: { type: Boolean, default: false },
    cardClass: { type: String, required: false },
    persistent: { type: Boolean, required: false, default: true },
  },
  data: () => ({
    localValue: false,
    options: { width: 700 },
  }),
  watch: {
    value: {
      immediate: true,
      handler(newVal) {
        this.localValue = newVal;
        if (!this.localValue) {
          this.$emit("close");
        }
      },
    },
    localValue(newVal) {
      this.$emit("input", newVal);
    },
    $attrs: {
      deep: true,
      immediate: true,
      handler(newVal) {
        this.options = { ...this.options, ...newVal };
      },
    },
  },
  computed: {
    hasActionSlot() {
      return !!this.$slots["actions"];
    },
    hasTitleSlot() {
      return !!this.$slots["title"];
    },
  },
  methods: {
    close() {
      this.localValue = false;
      this.$emit("input", false);
      this.$emit("close");
    },
  },
};
</script>
<style scoped>
>>> .actions-btn {
  background: #f5f5f5;
}

>>> .modal-content {
  height: 100%;
}

>>> .v-table__overflow {
  height: 99.5%;
  max-height: 99.5%;
  overflow-y: auto !important;
}

.v-card {
  border-left: 0;
}
</style>
