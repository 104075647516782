<template>
  <widget-container
    :url="url"
    headlineclass
    cardclass="ma-0 pa-1"
    color="transparent"
    :bus="bus"
    :dark="false"
    :flat="true"
    :alwaysrender="true"
    @ready="setData"
  >
   <template slot="content" v-if="data && data.nbBooking != 0">
			<div>{{$tc('booking', data.nbBooking, {size:data.nbBooking})}}</div>
			<div class="body-1">
				{{$tc('nights', data.nbNights, {size:data.nbNights})}}.
				Moy. {{data.amount / data.nbBooking | currency('EUR')}}
			</div>
		</template>
		<template slot="content" v-else>
			Aucun dossiers
		</template>
  </widget-container>
</template>
<script>
import WidgetContainer from "./WidgetContainer";
export default {
  name: "WidgetAgenciesBookingResume",
  components: { WidgetContainer },
  props: {
    bus: {
      type: Object,
      required: true,
    },
    url: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      loading: false,
      data: null,
    };
  },
  created() {
    this.$nextTick(() => {
      this.bus.$on("search", () => {
        this.loading = true;
      });
    });
  },
  destroyed() {
    this.bus.$off("search");
  },
  methods: {
    setData(data) {
      this.data = data;
      this.loading = false;
    },
  },
};
</script>