<template>
  <layout :drawerShow="_isDesktop()" :drawerWidth="350">
    <template slot="nav">
      <v-tabs>
        <v-tab :to="{ name: 'customer-search' }"
          >Rapport facturation prestations</v-tab
        >
      </v-tabs>
    </template>

    <template slot="drawer">
      <v-row>
        <v-col>
          <v-form v-on:submit.prevent="searchPrestation">
            <v-row dense>
              <v-col class="mb-0" cols="12">
                <v-subheader class="pa-0 ma-1" light
                  >Dates de départ</v-subheader
                >
              </v-col>

              <v-row class="pl-2">
                <v-col class="pr-1 my-2" cols="6">
                  <smart-picker
                    :inputOpts="{ label: $t('begin') }"
                    :next-picker="$refs.stopDate"
                    :stopDate="form.stopDate"
                    label="Début"
                    v-model="form.startDate"
                  ></smart-picker>
                </v-col>

                <v-col class="my-2" cols="6">
                  <smart-picker
                    :inputOpts="{ label: $t('end') }"
                    :startDate="form.startDate"
                    label="Fin"
                    ref="stopDate"
                    v-model="form.stopDate"
                  ></smart-picker>
                </v-col>
              </v-row>

              <v-col cols="12">
                <smart-multi-select
                  :all-is-same-as-empty="true"
                  :default-select-all="true"
                  :items="countries"
                  item-text="name"
                  item-value="value"
                  label="Pays"
                  v-model="form.country"
                >
                </smart-multi-select>
              </v-col>

              <v-col cols="12">
                <smart-multi-select
                  :all-is-same-as-empty="true"
                  :default-select-all="true"
                  :items="supplierAccounts"
                  item-text="text"
                  item-value="value"
                  label="Fournisseurs"
                  v-model="form.supplier"
                >
                </smart-multi-select>
              </v-col>

              <v-col cols="12">
                <smart-multi-select
                  :all-is-same-as-empty="true"
                  :default-select-all="true"
                  :items="currencies"
                  item-text="name"
                  item-value="value"
                  label="Devises"
                  v-model="form.currency"
                >
                </smart-multi-select>
              </v-col>
            </v-row>

            <smart-btn
              type="submit"
              :disabled="form.startDate === ''"
              block
              primary
              :loading="loading"
              >Rechercher
            </smart-btn>
          </v-form>
        </v-col>
      </v-row>
    </template>

    <template slot="content">
      <v-container fluid>
        <v-data-table
          :expanded.sync="listing.expanded"
          :footer-props="{
            'items-per-page-options': listing.rowsPerPageItems,
            'items-per-page-text': 'Lignes par page',
          }"
          :headers="listing.headers"
          :items="listing.items"
          :loading="loading"
          :no-data-text="listing.noDataSentence"
          :options.sync="listing.pagination"
          :single-expand="listing.singleExpand"
          class="elevation-1 tbl"
          item-key="bookingId"
          show-expand
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title
                >Rapport de paiement des prestations</v-toolbar-title
              >
            </v-toolbar>
          </template>
          <template v-slot:item.unpaidInvoices="{ item }">
            {{ item.unpaidInvoices == 0 ? "Ok" : item.unpaidInvoices }}
          </template>

          <template v-slot:item.bookingId="{ item }">
            <span @click="openBooking(item.bookingId)">{{
              item.bookingId
            }}</span>
          </template>

          <template v-slot:item.price="{ item }">
            {{ item.price.value | currency(item.price.currency) }}
          </template>

          <template v-slot:expanded-item="{ subheaders, item }">
            <td :colspan="listing.headers.length + 1" class="pa-5">
              <v-data-table
                :headers="listing.subheaders"
                :items="item.prestations"
                class="elevation-1 tbl"
                hide-default-footer
              >
                <template v-slot:item="prestation">
                  <tr>
                    <td>
                      {{ prestation.item.prestationId }}
                    </td>
                    <td>
                      {{ prestation.item.prestationName }}
                    </td>
                    <td>
                      {{
                        prestation.item.prestationPrice.value
                          | currency(prestation.item.prestationPrice.currency)
                      }}
                    </td>
                    <td>
                      {{ prestation.item.prestationStartDate }}
                    </td>
                    <td>
                      {{
                        prestation.item.supplierName
                          ? prestation.item.supplierName
                          : "-"
                      }}
                    </td>
                    <td>
                      {{
                        prestation.item.invoiceId
                          ? prestation.item.invoiceId
                          : "-"
                      }}
                    </td>
                    <td>
                      <span
                        v-if="
                          prestation.item.invoiceAmount &&
                          prestation.item.invoiceAmount.value > 0
                        "
                      >
                        {{
                          prestation.item.invoiceAmount.value
                            | currency(prestation.item.invoiceAmount.currency)
                        }}
                      </span>
                      <span v-else>-</span>
                    </td>
                    <td>
                      {{
                        prestation.item.invoiceStatus
                          ? $t(
                              "supplierinvoicestatus." +
                                prestation.item.invoiceStatus
                            )
                          : "-"
                      }}
                    </td>
                    <td>
                      <span
                        v-if="
                          prestation.item.prestationAmount &&
                          prestation.item.prestationAmount.value > 0
                        "
                      >
                        {{
                          prestation.item.prestationAmount.value
                            | currency(
                              prestation.item.prestationAmount.currency
                            )
                        }}
                      </span>
                      <span v-else>-</span>
                    </td>
                    <td>
                      <v-icon v-if="prestation.item.discord">mdi-alert</v-icon>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </td>
          </template>
        </v-data-table>
      </v-container>
    </template>
  </layout>
</template>

<script>
import Layout from "../Layout";
import { get, post } from "../../utils/api";
import SmartMultiSelect from "../../components/commons/SmartMultiSelect";

export default {
  name: "PrestationInvoiceReport",
  components: {
    Layout,
    SmartMultiSelect,
  },
  data() {
    return {
      loading: false,
      countries: [],
      supplierAccounts: [],
      currencies: [
        { name: "Euro", value: "EUR" },
        { name: "Dollar", value: "USD" },
      ],
      form: {
        startDate: null,
        stopDate: null,
        country: [],
        supplier: [],
        currency: [],
      },
      listing: {
        expanded: [],
        singleExpand: false,
        loading: false,
        rowsPerPageItems: [200, 500, { text: "Tous", value: -1 }],
        pagination: {
          rowsPerPage: 200,
        },
        headers: [
          {
            text: "Dossier",
            value: "bookingId",
            width: "100px",
            align: "left",
          },
          {
            text: "Client",
            value: "customerName",
            width: "250px",
            align: "left",
          },
          { text: "Prix", value: "price", align: "left" },
          {
            text: "Date de départ",
            value: "bookingStartDate",
            width: "150px",
            align: "left",
            sort: this.sortByDateTime,
          },
          {
            text: "Prestations non facturée",
            value: "unpaidInvoices",
            width: "250px",
            align: "center",
          },
          { text: "", value: "data-table-expand" },
        ],
        subheaders: [
          { text: "#", value: "prestationId" },
          { text: "Prestation", value: "prestationName" },
          { text: "Montant Prev", value: "prestationPrice" },
          {
            text: "Date de départ",
            value: "prestationStartDate",
            sort: this.sortByDateTime,
          },
          { text: "Fournisseur", value: "supplierName" },
          { text: "Facture", value: "invoiceId" },
          { text: "Montant de la facture", value: "invoiceAmount" },
          { text: "Statut", value: "invoiceStatus" },
          { text: "Montant de la prestation", value: "prestationAmount" },
          { text: "Désaccord", value: "discord" },
        ],
        items: [],
        noDataSentence: "Aucune prestation à afficher",
      },
    };
  },
  created() {
    this.form.startDate = this._formatDate(
      this._now(),
      this.datePatternConfig.serverLocalDate
    );
    //this.form.stopDate = this._formatDate(this._now(), this.datePatternConfig.serverLocalDate)

    let loadCountries = get("/api/search/countries?q=").json((countries) => {
      this.countries = countries.map((c) => {
        return {
          name: c.name,
          value: c.code,
        };
      });
    });

    this.getSupplierAccounts();
    this.form.country = this.countries.map((s) => s.value);
  },
  methods: {
    getSupplierAccounts() {
      const url = `/api/supplier/get/foraccounting`;

      get(url).json((r) => {
        let accounts = r.map((account) => {
          return { text: account.name, value: account.id };
        });

        this.supplierAccounts = accounts;
        this.form.supplier = this.supplierAccounts.map((s) => s.value);
      });
    },
    searchPrestation() {
      if (!this.loading) {
        const url = "/api/prestation/prestations-payment-report";
        this.loading = true;
        post(url, this.form)
          .json((data) => {
            let processedData = data.map((item) => {
              let prestations = item.prestations.map((prestation) => {
                return {
                  ...prestation,
                  ...{
                    prestationPrice: {
                      value: prestation.prestationPrice,
                      currency: prestation.prestationCurrency,
                    },
                    invoiceAmount: {
                      value: prestation.invoiceAmount,
                      currency: prestation.prestationCurrency,
                    },
                    prestationAmount: {
                      value: prestation.prestationAmount,
                      currency: prestation.prestationCurrency,
                    },
                  },
                };
              });

              return {
                ...item,
                ...{
                  price: {
                    value: item.price,
                    currency: item.bookingCurrency,
                  },
                  prestations: prestations,
                },
              };
            });

            this.listing.items = processedData;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    openBooking(id) {
      let route = this.$router.resolve({ name: "Booking", params: { id: id } });
      window.open(route.href, "_blank");
    },
    sortByDateTime(a, b) {
      return this._sortDates(a, b, this.datePatternConfig.serverLocalDateTime);
    },
  },
};
</script>

<style scoped></style>
