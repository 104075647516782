<template>
  <WidgetDatatableTabs :items="data.data" defaultTarget="count" :nbTabs="0"
                       showTotal>
    <template #title>{{ conf.title }}</template>
  </WidgetDatatableTabs>
</template>
<script>
import {post} from "@/utils/api";
import WidgetTile from "@/components/commons/widgets/WidgetTile.vue";
import {EventBus} from "@/components/commons/event-bus";
import WidgetDatatableTabs from "@/components/commons/widgets/WidgetDatatableTabs.vue";

export default {
  name: "ErrorPaymentCauseTable",
  components: {WidgetDatatableTabs},
  props: {
    form: {type: Object, required: true},
    conf: {type: Object, required: false, default: null}
  },
  data() {
    return {
      url: `/api/widget/dashboard/payment/refused`,
      data: {
        data: [],
        headers: [
          {
            text: "Compagnie",
            value: "name",
            align: "left",
            sortable: true,
          },
          {
            text: "Nombre",
            value: "count",
            align: "right",
            sortable: true,
            isPrice: false,
          }],
      },
      loading: true,
    }
  },
  computed: {},
  created() {
    EventBus.$on('searchDashboard', () => this.init());
    this.init();
  },
  methods: {
    init() {
      const searchForm = this._formatPayloadWidgetDashboard(this.conf,this.form)
      let tile = post(this.url, searchForm, {"Content-Type": "application/json"})
          .json((r) => {
            r.yearN.forEach(y => {
              y.isPrice = false
            })
            r.yearPast.forEach(y => {
              y.isPrice = false
            })
            this.data.data = this.formatDataTable(r.yearN, r.yearPast)
          })
      Promise.all([tile]).then(() => {
        this.loading = false
      })
    },
    formatDataTable(actual, old) {
      let toPushinActual = []
      old.forEach(data => {
        if (!actual.find(n => data.name === n.name)) {
          toPushinActual.push(
              {
                name: data.name,
                count: 0,
                sales: 0,
                purchases: 0,
                isPrice: data.isPrice != null ? data.isPrice : false,
                last: data,
                profits: 0
              }
          )

        }
      })

      actual.forEach(data => {
        if (!old.find(n => data.name === n.name)) {
          data.last = {
            name: data.name,
            count: 0,
            sales: 0,
            purchases: 0,
            isPrice: data.isPrice,
            profits: 0
          }
        }
      })
      actual.push(...toPushinActual)
      actual.forEach((sale) => {
        sale.last = old.find(oldSale => oldSale.name === sale.name)
        if (sale.last != null) {
          sale.last.profits = 0
        } else {
          sale.last = {profits: 0, sales: 0, purchases: 0, count: 0}
        }
        sale.profits = 0
      })
      return actual
    },
  },
}
</script>