<template>
  <layout :drawerShow="true" :drawerWidth="350">
    <template slot="nav"></template>
    <template slot="drawer">
      Etiquettes
      <v-col>
        <v-autocomplete
          v-model="ctx.tags"
          :items="tags"
          item-text="name"
          item-value="id"
          multiple
        />
      </v-col>

      <v-col>
        <v-row  justify="space-between">
          <v-col cols="5">
            <smart-picker v-model="ctx.fromDate" label="Date de début" placeholder=" "/>
          </v-col>
          <v-col cols="5">
            <smart-picker v-model="ctx.toDate" label="Date de fin" placeholder=" "/>
          </v-col>
        </v-row>
      </v-col>

      <v-col class="mt-5">
        <smart-btn @click.native="search" block :loading="loading">
          Rechercher
        </smart-btn>
      </v-col>

    </template>

    <template slot="content">

      <v-container>
        <v-card>
          <v-data-table
            :items="bookings"
            :headers="headers"
            :rows-per-page-items="[-1]"
          >
            <template slot="items" slot-scope="props">
              <tr>
                <td>{{props.item.id}}</td>
                <td>{{props.item.customerName}}</td>
                <td>
                  <span v-if="props.item.report">
                    {{props.item.report.status}}
                  </span>
                </td>
                <td>
                  <div v-if="props.item.report && props.item.report.status=='ok'">
                    {{props.item.report.result.start}} - {{props.item.report.result.stop}}
                  </div>
                </td>
                <td>
                  <div v-if="props.item.report && props.item.report.status=='ok'">
                    {{props.item.report.result.price}} ({{props.item.report.result.oldPrice}})
                  </div>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-container>

    </template>

  </layout>
</template>
<script>

  import Layout from "@/views/Layout"
  import {get, post} from "@/utils/api"

  export default {
    components: {Layout},
    data: () => ({
      tags:[],
      bookings:[],
      ctx:{
        tags:[],
        fromDate:null,
        toDate:null
      },
      headers:[
        { text: '#', sortable:false},
        { text: 'client', sortable:false},
        { text: 'status', sortable:false},
        { text: 'dates', sortable:false},
        { text: 'prix', sortable:false},
      ],
      loading:false
    }),
    created() {
      get('/api/search/tags')
      .json(data => this.tags = data)
    },
    methods:{
      search(){
        this.loading = true
        this.bookings = []
        post('/api/booking-report', this.ctx)
          .json(data => {
            this.bookings = data
          })
        .finally(() => {
          this.buildReports()
        })
      },
      buildReports(){
        const promises = this.bookings.map( b => {
          return this.reportBooking(b.id)
        })

        Promise.all(promises)
          .then(() => {
            this.loading = false
          })
      },
      reportBooking(id){
        return post('/api/booking-report/' + id, this.ctx)
          .json(data => {
            const index = this.bookings.findIndex( b => b.id===id)
            this.bookings.splice(index, 1, {...this.bookings[index], ...{report: data}})
          })
      }
    }
  }

</script>