<template>
  <v-row dense class="content-pdf page block" v-if="voucher">
    <v-col cols="12">
      <v-row dense>
        <v-col class="text-center">
          <v-icon color="red" x-large>error</v-icon>
        </v-col>
        <v-col cols="11" v-html="$t('document.voucher.warn')"></v-col>
      </v-row>
      <v-row dense class="tile py-2 mt-2 mx-2">
        <v-col cols="12" class="head text-h6">{{ $t('document.voucher.bookingref') }}</v-col>
        <v-col cols="3" class="content body-2 pa-1 mt-1 grey--text text--darken-1">
          <v-row dense>
            <v-col cols="12">{{ $t('document.voucher.customer') }}:</v-col>
            <v-col cols="12">{{ $t('document.voucher.booking') }}:</v-col>
            <v-col cols="12">{{ $t('document.voucher.voucher') }}:</v-col>
          </v-row>
        </v-col>
        <v-col cols="6" class="content body-2 pa-1 mt-1">
          <v-row dense>
            <v-col cols="12">{{ voucher.customer }}</v-col>
            <v-col cols="12">{{ voucher.bookingId }}</v-col>
            <v-col cols="12">{{ voucher.voucherId }}</v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row dense class="tile py-2 mt-2 mx-2 bi-avoid" v-if="voucher.prestation">
        <v-col cols="12" class="head text-h6">{{ $t('document.voucher.bookedProduct') }}</v-col>

        <template v-if="voucher.prestation.kind == 'RoomVoucherDto'">
          <v-col cols="6" class="content body-2 pa-1 mt-1">
            <v-row dense>
              <v-col
                cols="12"
                class="grey--text text--darken-1"
              >{{ $t('document.voucher.hotel.accommodation') }}:</v-col>
              <v-col cols="12">{{ voucher.prestation.hotel }}</v-col>
              <v-col
                cols="12"
                class="grey--text text--darken-1"
              >{{ $t('document.voucher.hotel.room') }}:</v-col>
              <v-col cols="12">{{ voucher.prestation.room }}</v-col>
              <v-col
                cols="12"
                class="grey--text text--darken-1"
              >{{ $t('document.voucher.hotel.formula') }}:</v-col>
              <v-col cols="12">{{voucher.prestation.meal}}</v-col>
              <v-col
                cols="12"
                class="grey--text text--darken-1"
              >{{ $t('document.voucher.prestationsSuppliedBy') }}:</v-col>
              <v-col cols="12" v-html="voucher.prestation.supplierName.replace(/\n/g, '<br/>')"></v-col>
              <template v-if="clientOffers.length > 0">
                <v-col
                  cols="12"
                  class="grey--text text--darken-1"
                >{{ $t('document.voucher.hotel.offers') }}:</v-col>
                <v-col cols="12">
                  <ul>
                    <li v-for="(o,index) in clientOffers" :key="index">{{ o }}</li>
                  </ul>
                </v-col>
              </template>
              <template v-if="clientExtras.length > 0">
                <v-col
                  cols="12"
                  class="grey--text text--darken-1"
                >{{ $t('document.voucher.hotel.extras') }}:</v-col>
                <v-col cols="12">
                  <ul>
                    <li v-for="(o,index) in clientExtras" :key="index">{{ o }}</li>
                  </ul>
                </v-col>
              </template>
            </v-row>
          </v-col>
          <v-col cols="6" class="content body-2 pa-1 mt-1">
            <v-row dense>
              <v-col cols="12">
                <span class="grey--text text--darken-1">{{ $t('document.voucher.hotel.stay') }}:</span>
                {{
                  $t('document.voucher.hotel.dateFromTo',
                    {
                      from:_parseAndFormatDate(voucher.prestation.start, datePatternConfig.serverLocalDateTime, _prestationDatePattern(voucher.prestation.start)),
                      to:_parseAndFormatDate(voucher.prestation.stop, datePatternConfig.serverLocalDateTime, _prestationDatePattern(voucher.prestation.start))
                    })
                }}
                ({{
                  $tc('nights', _nbNights(
                    _parseDate(voucher.prestation.start, datePatternConfig.serverLocalDateTime),
                    _parseDate(voucher.prestation.stop, datePatternConfig.serverLocalDateTime)
                  ), {
                    size: _nbNights(
                      _parseDate(voucher.prestation.start, datePatternConfig.serverLocalDateTime),
                      _parseDate(voucher.prestation.stop, datePatternConfig.serverLocalDateTime)
                    )
                  }
                  )
                }})
              </v-col>
              <v-col cols="12">
                <span
                  class="grey--text text--darken-1"
                >{{ $t('document.voucher.hotel.availableFrom') }}:</span>
                {{ voucher.prestation.checkin }}h
              </v-col>
              <v-col cols="12">
                <span
                  class="grey--text text--darken-1"
                >{{ $t('document.voucher.hotel.toFreeBefore') }}:</span>
                {{ voucher.prestation.checkout }}h
              </v-col>
              <v-col
                cols="12"
                class="grey--text text--darken-1"
              >{{ $t('document.voucher.passangers') }}:</v-col>
              <v-col cols="12">
                <v-row dense v-for="(guest, i) in voucher.prestation.guests" :key="'guests-' + i">
                  <v-col cols="12">{{ guest }}</v-col>
                </v-row>
              </v-col>
              <v-col cols="12" class="mt-4 font-weight-bold text--darken-1">
                <v-row dense v-if="voucher.prestation.importantMessage" v-html="voucher.prestation.importantMessage"></v-row>
              </v-col>
              <!-- ici 
              -->
            </v-row>
          </v-col>
        </template>
        <template v-else-if="voucher.prestation.kind == 'TransferVoucherDto'">
          <v-col cols="6" class="content body-2 pa-1 mt-1">
            <v-row dense>
              <v-col
                cols="12"
                class="grey--text text--darken-1"
              >{{ $t('document.voucher.prestationType') }}:</v-col>
              <v-col cols="12">{{ voucher.prestation.transferType }}</v-col>
              <v-col
                cols="12"
                class="grey--text text--darken-1"
              >{{ $t('document.voucher.transfer.from') }}</v-col>
              <v-col cols="12">{{ voucher.prestation.from }}</v-col>
              <v-col
                cols="12"
                class="grey--text text--darken-1"
              >{{ $t('document.voucher.transfer.to') }}</v-col>
              <v-col cols="12">{{ voucher.prestation.to }}</v-col>
              <v-col
                cols="12"
                class="grey--text text--darken-1"
              >{{ $t('document.voucher.prestationsSuppliedBy') }} :</v-col>
              <v-col cols="12">{{ voucher.prestation.supplierName }}</v-col>
            </v-row>
          </v-col>
          <v-col cols="6" class="content body-2 pa-1 mt-1">
            <v-row dense>
              <v-col cols="6" class="grey--text text--darken-1">{{ $t('document.voucher.date') }}:</v-col>
              <v-col cols="6">{{ voucher.prestation.start }}</v-col>
              <v-col
                cols="12"
                class="grey--text text--darken-1"
              >{{ $t('document.voucher.passangers') }}:</v-col>
              <v-col cols="12">
                <v-row dense v-for="(guest, i) in voucher.prestation.guests" :key="'guests-' + i">
                  <v-col cols="12">{{ guest }}</v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="content body-2 pa-1 mt-1">
            <v-row dense>
              <v-col cols="12" class="grey--text text--darken-1">{{ $t('document.voucher.transfer.specificConditions') }} :</v-col>
              <v-col cols="12" v-html="voucher.prestation.conditions"></v-col>
            </v-row>
          </v-col>
        </template>
        <template v-else-if="voucher.prestation.kind == 'CarrentalVoucherDto'">
          <v-col cols="6" class="content body-2 pa-1 mt-1">
            <v-row dense>
              <v-col
                cols="12"
                class="grey--text text--darken-1"
              >{{ $t('document.voucher.prestationType') }}:</v-col>
              <v-col
                cols="12"
              >{{ voucher.prestation.name }} | {{ voucher.prestation.category != null && voucher.prestation.category != voucher.prestation.name ? voucher.prestation.category : '' }}</v-col>
              <v-col
                cols="12"
                v-if="voucher.prestation.options && voucher.prestation.options.length > 0"
                style="color:red;"
              >
                <span>{{ voucher.prestation.options.join(' - ') }}</span>
              </v-col>
              <v-col
                cols="12"
                class="grey--text text--darken-1"
              >{{ $t('document.voucher.prestationType') }}:</v-col>
              <v-col cols="12">{{ voucher.prestation.description }}</v-col>
              <v-col
                cols="12"
                class="grey--text text--darken-1"
              >{{ $t('document.voucher.carRental.specificConditions') }}</v-col>
              <v-col
                cols="12"
                v-if="voucher.prestation.conditions && voucher.prestation.conditions != ''"
                v-html="voucher.prestation.conditions"
              ></v-col>
              <v-col
                cols="12"
                class="grey--text text--darken-1"
              >{{ $t('document.voucher.prestationsSuppliedBy') }}:</v-col>
              <v-col cols="12">
                <div>{{ voucher.prestation.supplierName }}</div>
                <div>{{ voucher.prestation.supplierDesc }}</div>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="6" class="content body-2 pa-1 mt-1">
            <v-row dense>
              <v-col
                cols="6"
                class="grey--text text--darken-1"
              >{{ $t('document.voucher.carRental.from') }}:</v-col>
              <v-col cols="6">{{ voucher.prestation.start }}</v-col>
              <v-col
                cols="6"
                class="grey--text text--darken-1"
              >{{ $t('document.voucher.carRental.to') }}:</v-col>
              <v-col cols="6">{{ voucher.prestation.stop }}</v-col>

              <v-col
                cols="12"
                class="grey--text text--darken-1"
              >{{ $t('document.voucher.passangers') }}:</v-col>
              <v-col cols="12">
                <v-row dense v-for="(guest, i) in voucher.prestation.guests" :key="'guests-' + i">
                  <v-col cols="12">{{ guest }}</v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </template>
        <template v-else-if="voucher.prestation.kind == 'UntypedVoucherDto'">
          <v-col cols="6" class="content body-2 pa-1 mt-1">
            <v-row dense>
              <v-col
                cols="12"
                class="grey--text text--darken-1"
              >{{ $t('document.voucher.prestationType') }}:</v-col>
              <v-col cols="12">{{ voucher.prestation.name }}</v-col>
              <v-col
                cols="12"
                class="grey--text text--darken-1"
              >{{ $t('document.voucher.prestationsSuppliedBy') }}:</v-col>
              <v-col cols="12">
                <div>{{ voucher.prestation.supplierName }}</div>
              </v-col>
              <v-col cols="12" v-if="voucher.prestation.supplierDesc">
                <div>{{ voucher.prestation.supplierDesc }}</div>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="6" class="content body-2 pa-1 mt-1">
            <v-row dense>
              <v-col
                cols="6"
                class="grey--text text--darken-1"
              >{{ $t('document.voucher.untyped.from') }}:</v-col>
              <v-col cols="6">{{ voucher.prestation.start }}</v-col>
              <v-col
                cols="6"
                class="grey--text text--darken-1"
              >{{ $t('document.voucher.untyped.from') }}:</v-col>
              <v-col cols="6">{{ voucher.prestation.stop }}</v-col>

              <v-col
                cols="12"
                class="grey--text text--darken-1"
              >{{ $t('document.voucher.passangers') }}:</v-col>
              <v-col cols="12">
                <v-row dense v-for="(guest, i) in voucher.prestation.guests" :key="'guests-' + i">
                  <v-col cols="12">{{ guest }}</v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </template>
      </v-row>

      <v-row dense class="tile py-2 mt-2 mx-2 bi-avoid block" v-if="voucher.onPlaceAgent">
        <v-col cols="12" class="head text-h6">{{ $t('document.voucher.representative') }}</v-col>
        <v-col cols="12" class="content body-2 pa-1 mt-1 grey--text text--darken-1">
          {{ $t('document.voucher.coordinatesLabel') }}
          :
        </v-col>
        <v-col cols="12" class="content body-2 pa-1 mt-1">
          <v-row dense>
            <v-col cols="6" v-html="voucher.onPlaceAgent.name"></v-col>
            <v-col cols="6" v-html="voucher.onPlaceAgent.assistance"></v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>

    <v-row dense class="tile py-2 mt-2 mx-2 bi-avoid block" v-if="voucher.prestation.contract">
      <v-col cols="12" class="head text-h6">{{ $t('document.voucher.invoice') }}</v-col>
      <v-col
        cols="12"
        class="content body-2 mt-1 grey--text text--darken-1"
      >{{ $t('document.voucher.invoice') }}</v-col>
      <v-col cols="12" class="content body-2 mt-1">{{ voucher.prestation.contract }}</v-col>

      <template v-if="offers.length > 0">
        <v-col
          cols="12"
          class="content body-2 pa-1 pt-3 mt-1 grey--text text--darken-1"
        >{{ voucher.prestation.offers }}:</v-col>
        <v-col cols="12" class="content body-2 pa-1 mt-1">
          <ul>
            <li v-for="(o,index) in offers" :key="index">{{ o }}</li>
          </ul>
        </v-col>
      </template>
      <template v-if="extras.length > 0">
        <v-col
          cols="12"
          class="content body-2 pa-1 pt-3 mt-1 grey--text text--darken-1"
        >{{ voucher.prestation.extras }}:</v-col>
        <v-col cols="12" class="content body-2 pa-1 mt-1">
          <ul>
            <li v-for="(e,index) in extras" :key="index">{{ e }}</li>
          </ul>
        </v-col>
      </template>
    </v-row>

    <span id="loaded"></span>
  </v-row>
</template>

<script>
import { get } from "@/utils/api";
import GuestsMixin from "@/components/mixins/GuestsMixin";

export default {
  name: "Voucher",
  mixins: [GuestsMixin],
  props: {
    voucherid: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      voucher: null,
    };
  },
  created() {
    this.init();
  },
  watch: {},
  computed: {
    offers() {
      return this.splitList(this.voucher.prestation.offers);
    },
    extras() {
      return this.splitList(this.voucher.prestation.extras);
    },
    clientOffers() {
      return this.splitList(this.voucher.prestation.clientOffers);
    },
    clientExtras() {
      return this.splitList(this.voucher.prestation.clientExtras);
    },

  },
  methods: {
    init() {
      const url = `/api/document/voucher/${this.voucherid}`;

      return get(url)
        .badRequest((err) => {
          console.error(err);
        })
        .json((data) => {
          this.voucher = data;
          this.$locale = this.voucher.lang || 'fr'
        });
    },
    splitList(list) {
      if (list && list.trim() !== "") {
        return list.split("\n");
      }
      return [];
    }
  },
};
</script>

<style scoped src="@/assets/pdf/css/default.less" lang="less"></style>
<style scoped lang="less">
.tile {
  border: 1px solid black;
  .head {
    border-bottom: 1px dotted black;
  }
}

.content-pdf {
  height: 100%;
}
</style>
