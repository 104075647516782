<template>
  <widget-container
    :url="url"
    headlineclass
    cardclass="ma-0 pa-1"
    color="transparent"
    :bus="bus"
    :dark="false"
    :flat="true"
    :alwaysrender="true"
    @ready="setData"
  >
   <template slot="content" v-if="data && data.ca != 0 ">
			<div>{{data.ca | currency('EUR')}}</div>
			<div class="body-1">
				HA {{data.ha | currency('EUR')}} | Marge = {{data.markup*100 | decimal({maximumFractionDigits:2}) }} %
			</div>
		</template>
    <template slot="content" v-else>
			Aucune agence
		</template>
  </widget-container>
</template>
<script>
import WidgetContainer from "./WidgetContainer";
export default {
  name: "WidgetAgenciesResume",
  components: { WidgetContainer },
  props: {
    bus: {
      type: Object,
      required: true,
    },
    url: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      loading: false,
      data: null,
    
    };
  },
  created() {
    this.$nextTick(() => {
      this.bus.$on("search", () => {
        this.loading = true;
      });
    });
  },
  destroyed() {
    this.bus.$off("search");
  },
  methods: {
    setData(data) {
      this.data = data;
      this.loading = false;
    },
  },
};
</script>