<template>

  <v-container>
    <table>
      <tr>
        <th class="bad text-center">
          Voyageur à supprimer
          <div>#{{oldGuest.id}}</div>
        </th>
        <th style="width:10%"></th>
        <th class="good text-center" style="width:45%">
          <div>Nouveau Voyageur</div>
          <v-select v-model="selected" :items="others" label="Fusionner avec" hide-details @change="selectGuest">
            <template v-slot:item="data">
              <div>#{{data.item.id}}. {{data.item.firstName}} {{data.item.lastName}} <span v-if="data.item.birthDate">{{data.item.birthDate}}</span></div>
            </template>
            <template v-slot:selection="data">
              <div>#{{data.item.id}}. {{data.item.firstName}} {{data.item.lastName}} <span v-if="data.item.birthDate">{{data.item.birthDate}}</span></div>
            </template>
          </v-select>
        </th>
      </tr>
      <tr>
        <td class="bad">
          <v-text-field readonly hide-details
              v-model="oldGuest.ageClassType"
              label="Classe d'age"
              append-outer-icon="mdi-arrow-right-circle-outline"
              @click:append-outer="mergedGuest.ageClassType=oldGuest.ageClassType"
          ></v-text-field>
        </td>
        <td></td>
        <td class="good" ><v-text-field v-model="mergedGuest.ageClassType" disabled label="Classe d'age" hide-details></v-text-field></td>
      </tr>
      <tr>
        <td class="bad">
          <v-text-field readonly hide-details
                        v-model="oldGuest.firstName"
                        label="Prénom"
                        append-outer-icon="mdi-arrow-right-circle-outline"
                        @click:append-outer="mergedGuest.firstName=oldGuest.firstName"
          ></v-text-field>
        </td>
        <td></td>
        <td class="good" ><v-text-field v-model="mergedGuest.firstName" label="Prénom" hide-details></v-text-field></td>
      </tr>
      <tr>
        <td class="bad">
          <v-text-field readonly hide-details
                        v-model="oldGuest.lastName"
                        label="Nom"
                        append-outer-icon="mdi-arrow-right-circle-outline"
                        @click:append-outer="mergedGuest.lastName=oldGuest.lastName"
          ></v-text-field>
        </td>
        <td></td>
        <td class="good" ><v-text-field v-model="mergedGuest.lastName" label="Nom" hide-details></v-text-field></td>
      </tr>
      <tr>
        <td class="bad">
          <v-text-field readonly hide-details
                        v-model="oldGuest.birthDate"
                        label="Date de naissance"
                        append-outer-icon="mdi-arrow-right-circle-outline"
                        @click:append-outer="mergedGuest.birthDate=oldGuest.birthDate"
          ></v-text-field>
        </td>
        <td></td>
        <td class="good" ><v-text-field v-model="mergedGuest.birthDate" label="Date de naissance" hide-details></v-text-field></td>
      </tr>
    </table>

  </v-container>
</template>
<script>
  export default {
    name: "BookingGuestMerge",
    props: {
      value: {
        required: true
      },
      guest: {
        type: Object, required: true
      },
      guests: {
        type: Array, required: true
      }
    },
    data() {
      return {
        selected:{},
        oldGuest:{},
        mergedGuest:{
          id:null,
          ageClassType:null,
          firstName:null,
          lastName:null,
          birthDate:null
        }
      }
    },
    computed:{
      others(){
        return this.guests.filter(g => g.id !== this.guest.id)
      }
    },
    watch:{
      mergedGuest:{
        deep:true, handler(newVal){
          this.$emit('input', newVal)
        }
      }
    },
    created(){
      this.oldGuest = {...this.guest}
    },
    methods:{
      selectGuest(){
        this.mergedGuest = {...this.selected}
      }
    }
  }
</script>
<style lang="less" scoped>
  table {
    width:100%;
    border-spacing: 0;
    border-collapse: collapse;
    border:0;
  }
  th {
    vertical-align:top;
    font-weight:normal
  }
  td,th {
    padding:10px 20px;
  }
  .bad {
    background-color:#FFEBEE;
  }
  .good {
    background-color:#E8F5E9;
  }
</style>
