<template>
  <widget-container
    :url="url"
    headlineclass
    cardclass="ma-0 pa-1"
    color="transparent"
    :sort-by="['ca']"
    :bus="bus"
    :dark="false"
    :flat="true"
    :alwaysrender="true"
    @ready="setData"
  >
    <template slot="content">
      <div class>
        <v-row align="center">
          <v-col>
            <v-row dense justify="space-between">
              <v-col
                v-if="this.items.length > 0"
                class="offset-md-11 text-right"
                cols="1"
              >
                <v-icon small @click="copy" class="pl-2"
                  >mdi-clipboard-text-outline</v-icon
                >
              </v-col>
              <v-col cols="12">
                <v-data-table
                  :headers="headers"
                  :items="items"
                  :footer-props="footerprops"
                  :disable-sort="true"
                  :loading="loading"
                  no-data-text="Aucun resultats"
                  hide-default-footer
                  class="tbl elevation-1"
                >
                </v-data-table>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
    </template>
  </widget-container>
</template>
<script>
import WidgetContainer from "./WidgetContainer";
import clipboard from "@/utils/clipboard";
export default {
  name: "WidgetTravelBookingStats",
  components: { WidgetContainer },
  props: {
    bus: {
      type: Object,
      required: true,
    },
    url: {
      type: String,
      required: false,
      default: "",
    },
    searchform: {
      type: Object,
      required: true,
    },
    selectedContext: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      rawData: [],
      loading: false,

      footerprops: {
        "items-per-page-options": [{ text: "Tous", value: -1 }],
        "items-per-page-text": "Lignes par page",
      },
    };
  },
  created() {
    this.$nextTick(() => {
      this.bus.$on("search", () => {
        this.loading = true;
        this.$emit("loading", true);
      });
      this.bus.$on("ready", () => {
        this.loading = false;
        this.$emit("loading", false);
      });
    });
  },
  destroyed() {
    this.bus.$off("search");
  },
  computed: {
    headers() {
      if (
        !this.searchform ||
        !this.searchform.travelStartDate ||
        !this.searchform.travelStopDate
      )
        return [];

      const stop = this._parseDate(
        this.searchform.travelStopDate,
        "dd/MM/yyyy"
      );
      const start = this._parseDate(
        this.searchform.travelStartDate,
        "dd/MM/yyyy"
      );
      const monthsSize = this._differenceInMonths(stop, start);
      let months = [start];
      for (let i = 0; i <= monthsSize - 1; i++) {
        months.push(this._addMonths(months[i], 1));
      }

      const headerElements = months.map((m, index, arr) => {
        return {
          ...{
            text: this._formatDate(m, "MMM yyyy"),
            value: this._formatDate(m, "yyyy-MM-dd"),
          },
        };
      });
      const firstElement = { text: "-", value: "monthbooking" };
      const lastElement = { text: "Total", value: "total" };

      return [firstElement, ...headerElements, lastElement];
    },
    items() {
      const isoFormat = "yyyy-MM-dd";
      const monthYears = "MMM yyyy";

      const itemsElement = this.rawData
        .reduce((prev, curr) => {
          if (
            prev.length == 0 ||
            !prev.find((p) => p.monthbooking == curr.monthbooking)
          ) {
            const item = {
              monthbooking: curr.monthbooking,
              [curr.monthtravel]:
                this.selectedContext == "ca"
                  ? this.$options.filters.currency(curr.data, "EUR")
                  : curr.data.toString(),
              [curr.monthtravel + "Raw"]: curr.data,
            };
            prev.push(item);
          } else {
            const cpt = prev.findIndex((object) => {
              return object.monthbooking === curr.monthbooking;
            });

            //prev[cpt][curr.monthtravel+'Raw'] + curr.data
            prev[cpt][curr.monthtravel + "Raw"] =
              (prev[cpt][curr.monthtravel + "Raw"] || 0) + curr.data;
            prev[cpt][curr.monthtravel] =
              this.selectedContext == "ca"
                ? this.$options.filters.currency(
                    prev[cpt][curr.monthtravel + "Raw"],
                    "EUR"
                  )
                : prev[cpt][curr.monthtravel + "Raw"].toString();
          }

          return prev;
        }, [])
        .sort(function (a, b) {
          return new Date(a.monthbooking) - new Date(b.monthbooking);
        })
        .map((t, index, arr) => {
          const total = () =>
            Object.values(t)
              .filter((x) => typeof x == "number")
              .reduce((acc, curr) => {
                return acc + curr;
              }, 0);

          const data =
            this.selectedContext == "ca"
              ? this.$options.filters.currency(total(), "EUR")
              : total();

          var totall = total();

          return {
            ...t,
            ...{
              monthbooking: this._parseAndFormatDate(
                t.monthbooking,
                isoFormat,
                monthYears
              ),
            },
            ...{ total: data },
            ...{ totalRaw: totall },
          };
        });

      if (itemsElement.length > 0) {
        const lastElement = this.headers.reduce((acc, curr) => {
          const f = (k) =>
            itemsElement.reduce((acc, curr) => {
              return acc + (curr[k + "Raw"] || 0);
            }, 0);

          const data =
            this.selectedContext == "ca"
              ? this.$options.filters.currency(f(curr.value), "EUR")
              : f(curr.value);
          return {
            ...acc,
            ...{ [curr.value]: data },
            ...{ [curr.value + "Raw"]: f(curr.value) },
          };
        }, {});
        lastElement.monthbooking = "Total";
        return [...itemsElement, lastElement];
      }
      return [];
    },
  },
  methods: {
    setData(data) {
      this.rawData = data;
    },
    copy() {
      var defaultSeparator = "\t";
      const headers = this.headers
        .map((h) => {
          return `${h.text}${defaultSeparator}`;
        })
        .join(" ");
      const items = this.items.map((r) => {
        var rawObj = {};
        var l = [];
        for (var j = 1; j < this.headers.length; j++) {
          l.push(this.headers[j].value);
        }
        for (var i = 1; i <= l.length; i++) {
          if (r[l[i - 1]] != null) {
            rawObj[i] = r[l[i - 1] + "Raw"];
          } else {
            rawObj[i] = "";
          }
        }
        rawObj[0] = r.monthbooking;
        return `${Object.values(rawObj).join(defaultSeparator)}`;
      });
      const allRows = [headers].concat(items);
      clipboard.copyTextToClipboard(allRows.join("\n"));
    },
  },
};
</script>
