<template>
  <layout :drawerShow="true" :drawerWidth="350">
    <template slot="nav">
      <v-tabs>
        <v-tab>BigQuery compare</v-tab>
      </v-tabs>
    </template>
    <template slot="nav-tools">
      <v-btn
          :value="seeSames"
          @click="toggleDiff()"
          class="mr-3"
      >
        <v-icon round class="mr-2">
          {{ seeSames ? 'mdi-eye-off-outline' : 'mdi-eye-outline' }}
        </v-icon>
        {{ seeSames ? 'Cacher identiques' : 'Voir identiques' }}
      </v-btn>
      <smart-btn
          @click.native="updateSelected()"
          :disabled="canUpdate"
          class="mr-3"
          primary
      >
        <v-icon round class="mr-2">
          mdi-cloud-upload
        </v-icon>
        Mettre à jour
      </smart-btn>
    </template>
    <template slot="drawer">
      <div>
        <v-form ref="searchform" v-if="searchform"  v-on:submit.prevent="valid">
          <v-row dense>
            <v-col cols="12" class="pr-1 mt-2">
              <v-select
                  v-model="searchform.selectedSites"
                  :rules="rules.nonEmpty()"
                  :error="!rules.nonEmpty()"
                  :items="sites"
                  item-value="id"
                  item-text="name"
                  label="Site"
                  dense
                  hide-details
                  placeholder=""
              />
            </v-col>
            <v-col cols="12">
              <v-subheader light class="pa-0 ml-1">Dates Réservation</v-subheader>
            </v-col>
            <v-col class="pr-1" cols="6">
              <smart-picker
                  v-model="searchform.bookingDateStart"
                  :stopDate="searchform.bookingDateStop"
                  :inputOpts="{ label: $t('begin') }"
              />
            </v-col>
            <v-col cols="6">
              <smart-picker
                  v-model="searchform.bookingDateStop"
                  :startDate="searchform.bookingDateStart"
                  :inputOpts="{ label: $t('end') }"
              />
            </v-col>
            <v-col cols="6">
              <v-subheader light class="pa-0 ml-1">Statut</v-subheader>
              <v-checkbox
                  v-for="(bs, index) in bookingStatus"
                  :key="'bs-' + index"
                  v-model="searchform.bookingStatus"
                  :label="bs.label"
                  :value="bs.value"
                  :class="'pt-1 ' + (index == 0 ? 'mt-2' : 'mt-0')"
                  height="1"
              >
              </v-checkbox>
            </v-col>
            <v-col cols="12">
            <v-divider></v-divider>
                        <v-radio-group class=""
                            v-model="searchform.excludeOptions"
                            label="Options"
                            column
                            dense
                        >
                            <v-radio small label="Cacher les options" color="primary" value="hide" dense></v-radio>
                            <v-radio small label="Uniquement les options" color="primary" value="only" dense></v-radio>
                          <v-radio small label="Tous les dossiers" color="primary" value="show" dense></v-radio>
                        </v-radio-group>
            </v-col>
          </v-row>
          <smart-btn type="submit" block primary class="mt-2" :loading="loadingListing"
          >Rechercher
          </smart-btn
          >
          <smart-btn
              block
              tertiary
              @click.native="
            loadData();
          "
          >Réinitialiser la recherche
          </smart-btn
          >
        </v-form>
      </div>
    </template>
    <template slot="content">
      <v-container fluid class="m-4">
        <v-row justify="space-between">
          <v-col cols="12" class="mb-3">
            <v-card class="pa-0 mt-5 blue-grey lighten-1">
              <v-row justify="space-between">
                <v-col cols="12" md="3" class="d-flex flex-column justify-center pa-2 ma-0 ml-10 white--text">
                  <div class="text-h5">Dernier batch</div>
                  <div v-if="lastBatch" class="text-body-1">
                    <span>{{ dateBatch }}</span>
                  </div>
                  <div v-else>Aucune Donnée</div>
                </v-col>

                <v-col cols="12" md="3" class="pa-2 ma-0 ml-10 white--text">
                  <div v-if="totalPrice" class="d-flex flex-column">
                    <span class="text-h5">{{ totalPrice.sr.ca | currency("EUR", {maximumFractionDigits: 0}) }}</span>
                    <span class="text-body-1">HA: {{
                        totalPrice.sr.ha | currency("EUR", {maximumFractionDigits: 0})
                      }}</span>
                    <span class="text-body-2">Smartresas </span>
                  </div>
                  <div v-else>Aucune Donnée</div>
                </v-col>
                <v-col cols="12" md="3" class="pa-2 ma-0 ml-10 white--text">
                  <div v-if="totalPrice" class="d-flex flex-column">
                    <span class="text-h5">{{ totalPrice.bq.ca | currency("EUR", {maximumFractionDigits: 0}) }}</span>
                    <span class="text-body-1">HA: {{
                        totalPrice.bq.ha | currency("EUR", {maximumFractionDigits: 0})
                      }}</span>
                    <span class="text-body-2">BigQuery </span>
                  </div>
                  <div v-else>Aucune Donnée</div>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <v-row dense justify="space-between" align="baseline" class="mt-3">
          <v-col cols="5" class="ml-1 d-flex align-baseline justify-start dbName">
            <span class="grey--text text--darken-1">Smartrésas</span>
          </v-col>
          <v-col cols="5" class="mr-1 d-flex align-baseline justify-end dbName">
            <span class="grey--text text--darken-1">BigQuery</span>
          </v-col>
        </v-row>
        <v-row justify="space-between" v-if="!loadingData">
          <v-col cols="12">
            <v-data-table
                v-model="selectedBookings"
                item-key="id"
                show-select
                :loading="loadingListing"
                :headers="headers"
                :items="listingBookings"
                :options.sync="datatable.pagination"
                :hide-default-footer="true"
                no-data-text="Il n'y a pas de dossiers disponible"
                class="elevation-1 tbl"
                :footer-props="{
                  'items-per-page-options': datatable.rowsPerPageItems,
                  'items-per-page-text': '',
                }">
              <template v-slot:header.mismatch="{  }">
                <v-icon round>mdi-swap-horizontal</v-icon>
              </template>

              <template v-slot:item.data-table-select="{ item , isSelected}">
                <v-checkbox :value="isSelected"
                            label=""
                            height="1" :disabled="!isDiff(item)"
                            @change="updateSelectedList(item,isSelected)"
                />
              </template>
              <template v-slot:item.srBooking.id="{ item }">
                <div class="grey--text text--darken-1 caption">{{ item.srBooking.id }}</div>
              </template>
              <template v-slot:item.srBooking.status="{item}">
                <div :class="item.mismatch.includes('status') ?'red--text accent-3': ''">
                  <availability v-if="item.srBooking.status != '-'" v-model="item.srBooking.status"
                                kind="BookingStatus">
                  </availability>
                  <span v-else>{{ item.srBooking.status }}</span>
                </div>
              </template>
              <template v-slot:item.srBooking.euroPrice="{ item }">
                <div :class="item.mismatch.includes('euroPrice') ?'red--text accent-3': ''">
                  <span>{{ _formatCurrency(item.srBooking.euroPrice, "EUR", {maximumFractionDigits: 0}) }}</span>
                </div>
              </template>

              <template v-slot:item.srBooking.euroPurchasePrice="{ item }">
                <div :class="item.mismatch.includes('euroPurchasePrice') ?'red--text accent-3': ''">
                  <span>{{
                      _formatCurrency(item.srBooking.euroPurchasePrice, "EUR", {maximumFractionDigits: 0})
                    }}</span>
                </div>
              </template>


              <template v-slot:item.mismatch="{ item }">
                <v-icon round :color="isDiff(item) ? 'red' :'green'">
                  {{ isDiff(item) || item.bqBooking.count > 1 ? "mdi-close-circle-outline" : "mdi-checkbox-marked-circle-outline" }}
                </v-icon>
              </template>


              <template v-slot:item.bqBooking.id="{ item }">
                <div class="caption" :class="item.bqBooking.count > 1 ? 'red--text accent-3' : 'grey--text text--darken-1 '">
                  {{ item.bqBooking.id }}
                </div>
              </template>
              <template v-slot:item.bqBooking.status="{item}">
                <div :class="item.mismatch.includes('status') ?'red--text accent-3': ''">
                  <availability v-if="item.bqBooking.status != '-'" v-model="item.bqBooking.status"
                                kind="BookingStatus">
                  </availability>
                  <span v-else>{{ item.bqBooking.status }}</span>
                </div>
              </template>
              <template v-slot:item.bqBooking.euroPrice="{ item }">
                <div :class="item.mismatch.includes('euroPrice') ?'red--text accent-3': ''">
                  <span>{{ _formatCurrency(item.bqBooking.euroPrice, "EUR", {maximumFractionDigits: 0}) }}</span>
                </div>
              </template>

              <template v-slot:item.bqBooking.euroPurchasePrice="{ item }">
                <div :class="item.mismatch.includes('euroPurchasePrice') ?'red--text accent-3': ''">
                  <span>{{
                      _formatCurrency(item.bqBooking.euroPurchasePrice, "EUR", {maximumFractionDigits: 0})
                    }}</span>
                </div>
              </template>

            </v-data-table>
          </v-col>
        </v-row>
        <div v-else>
          <v-skeleton-loader
              class
              height="100%"
              type="list-item-avatar-two-line, list-item-three-line@7"
          />
        </div>
      </v-container>
    </template>
  </layout>
</template>
<script>
import Layout from "@/views/Layout";
import {get, post} from "@/utils/api";
import Vue from "vue";
import Availability from "@/components/commons/Availability.vue";
import {EventBus} from "@/components/commons/event-bus";
import FormMixin from "@/components/mixins/FormMixin";

export default {
  name: "BigQueryAnalytics",
  components: {
    Layout, Availability
  },
  mixins: [FormMixin],
  data() {
    return {
      datatable: {
        rowsPerPageItems: [{text: "Tous", value: -1}],
        pagination: {
          options: {
            page: 1,
            sortBy: [],
            sortDesc: [false],
          },
        },
      },
      seeSames: true,
      loadingSearch:false,
      loadingSites: true,
      loadingListing: false,
      loadingData: false,
      showDurationError: false,
      searchform: null,
      sites: [],
      selectedBookings: [],
      defaultSearchform: {
        selectedSites: null,
        selectedVirtualSites: [],
        selectedNetworks: [],
        selectedAgencies: [],
        selectedChains: [],
        selectedHotels: [],
        selectedDestinations: [],
        client: null,
        ref: null,
        bookingDateStart: null, //this._formatDate(new Date().setDate(1), "dd/MM/yyyy"),
        bookingDateStop: null,  //this._formatDate(this._subDays(new Date(), 1), "dd/MM/yyyy"),
        tripDateStart: null,
        tripDateStop: null,
        bookingStatus: ["booked"],
        supplierStatus: [],
        paymentStatus: null,
        excludeOptions: 'hide',
        tags: [],
      },
      listing: [],
      rowsData: [],
      bookingStatus: [
        {value: "quotation", label: "Devis"},
        {value: "booked", label: "Réservé"},
        {value: "cancelled", label: "Annulé"},
      ],
      widgetBus: new Vue(),
      lastBatch: null,
      totalPrice: null,
    }
  },
  created() {
    const loadVirtualSites = get("/api/search/sites").json((rs) => {
      this.sites = rs;
    });
    Promise.all([
      loadVirtualSites,
    ]).then(() => {
      const today = new Date()
      if (today.getDate() === 1) {
        this.defaultSearchform.bookingDateStart = this._formatDate(today, "dd/MM/yyyy")
        this.defaultSearchform.bookingDateStop = this._formatDate(today, "dd/MM/yyyy")
      } else {
        this.defaultSearchform.bookingDateStart = this._formatDate(new Date().setDate(1), "dd/MM/yyyy")
        this.defaultSearchform.bookingDateStop = this._formatDate(this._subDays(today, 1), "dd/MM/yyyy")
      }
      this.loadingSites = false
      this.clearSearchform()
      this.getWidgets()
    });

  },
  watch: {},
  computed: {
    headers() {
      return [
        {
          text: "Ref.",
          value: "srBooking.id",
          align: "left",
        },
        {
          text: "Status",
          value: "srBooking.status",
          align: "left",
          sortable: false
        },
        {
          text: "Montant",
          value: "srBooking.euroPrice",
          align: "left",
        },
        {
          text: "HA",
          value: "srBooking.euroPurchasePrice",
          align: "left",
        },
        {
          text: "",
          value: "mismatch",
          align: "center",
          sortable: false
        },
        {
          text: "Ref.",
          value: "bqBooking.id",
          align: "right",
        },
        {
          text: "Status",
          value: "bqBooking.status",
          align: "right",
          sortable: false
        },
        {
          text: "Montant",
          value: "bqBooking.euroPrice",
          align: "right"
        },
        {
          text: "HA",
          value: "bqBooking.euroPurchasePrice",
          align: "right",
        },
      ];
    },
    checkDurationDates() {
      return this._duration(this.searchform.bookingDateStart, this.searchform.bookingDateStop, "days") <= 31
    },
    dateBatch() {
      return this._formatDate(new Date(this.lastBatch.date), 'dd-MM-yyyy hh:mm:ss')
    },
    canUpdate() {
      return this.selectedBookings.length == 0
    },
    listingBookings() {
      if (this.seeSames) {
        return this.formatDatas(this.rowsData)
      } else {
        return this.formatDatas(this.rowsData).filter(b => this.seeSames ? true : this.isDiff(b))
      }
    }
  },
  methods: {
    toggleDiff() {
      this.seeSames = !this.seeSames
      if (this.seeSames) {
        this.listing = this.formatDatas(this.rowsData)
      } else {
        this.listing = this.listing.filter(b => this.seeSames ? true : this.isDiff(b))
      }
    },
    isDiff(item) {
      const bqBook = this.rowsData.find(d => d.srBooking.id === item.srBooking.id).bqBooking
      const srBook = this.rowsData.find(d => d.bqBooking.id === item.bqBooking.id).srBooking
      return item.mismatch.length != 0 ||
          (item.mismatch.length == 0 && bqBook.id == "-") ||
          (item.mismatch.length == 0 && srBook.id == "-")
    },
    getHeader(value) {
      return this.headers.find((x) => x.value === value) != null;
    },
    clearSearchform() {
      this.searchform = {...this.defaultSearchform};
    },
    updateSelectedList(item, isSelected) {
      if (this.selectedBookings.some(b => b.id === item.id)) {

        this.selectedBookings = this.selectedBookings.filter(sb => sb.id != item.id)
      } else {
        this.selectedBookings.push(item)
      }
      isSelected = !isSelected
    },
    updateSelected() {
      this.loadingData = true
      EventBus.$emit('toaster-msg', "Mise à jour en cours. Les données seront bientôt intégrées à BigQuery");
      let promiseData = this.selectedBookings.map(booking => {
        if (booking.bqBooking.id == "-") {
          return get(`/api/bigquery/booking/${booking.srBooking.id}/import`).res()
        }else if (booking.bqBooking.count > 1){
          return get(`/api/bigquery/booking/${booking.srBooking.id}/duplicated`).res()
        } else {
          return get(`/api/bigquery/booking/${booking.srBooking.id}/refresh`)
        }
      })
      Promise.all(promiseData).then((rs) => {
        this.loadingData = false
        this.selectedBookings.value = []
        EventBus.$emit('toaster-msg', "Mise à jour terminée");
        this.loadData()
      })
    },
    loadData() {
      this.selectedBookings.value = [];
      this.loadingListing = true
      if (this.checkDurationDates) {
        this.getBookings(this.searchform);
      } else if (!this.checkDurationDates) {
        this.showDurationError = true
      }
    },
    formatDatas(array) {
      array.forEach((r, index) => {

        if (!r.bqBooking) {
          r.bqBooking = {
            id: "-",
            status: "-",
            euroPrice: "-",
            euroPurchasePrice: "-"
          }
        } else if(r.bqBooking.count >1){
          r.bqBooking.id = `${r.bqBooking.id} (x${r.bqBooking.count})`
        }

        if (!r.srBooking) {
          r.srBooking = {
            id: "-",
            status: "-",
            euroPrice: "-",
            euroPurchasePrice: "-"
          }
        }
        r.id = index
      })
      return array
    },
    getBookings(searchForm) {
      post("/api/bigquery/total", {...searchForm, selectedSites: [searchForm.selectedSites]}).json((res) => {
        this.totalPrice = res
      })
      post("/api/bigquery/bookings", {...searchForm, selectedSites: [searchForm.selectedSites]}).json((res) => {
        this.rowsData = res
        this.loadingListing = false
      })
    },
    getWidgets() {
      get("/api/bigquery/batch/last").json(rs => {
        if (rs.length > 0) {
          this.lastBatch = {
            id: rs[0].id,
            date: rs[0].date
          }
        }
      })
    },
    valid() {
      if(this.$refs.searchform.validate()){
        this.loadData()
      }
    },
  }
}
</script>
<style scoped>
.dbName {
  border-bottom: solid 2px lightgray;
}
</style>



