<template>
  <layout :drawerShow="_isDesktop()" :drawerWidth="350">
    <template slot="nav">
      <v-tabs>
        <v-tab :to="{ name: 'listleads' }">Leads</v-tab>
      </v-tabs>
    </template>
    <template slot="nav-tools">
      <smart-btn class="mr-3"
                 @click.native="createLead()"
      >
        <v-icon>mdi-folder-plus</v-icon>
        <span class="pl-1 d-none d-sm-flex">Nouveau lead</span>
      </smart-btn>
    </template>
    <template slot="drawer">
      <v-form ref="searchform" v-if="searchform" @submit.prevent="search">
        <v-row no-gutters class="pt-2 pr-1">

          <v-col cols="12" class="pr-1 mt-2">
            <v-select
                v-model="searchform.site"
                :items="sites"
                item-value="id"
                item-text="name"
                label="Site"
                dense
                hide-details
                placeholder=""
            />
          </v-col>
          <v-subheader light class="pa-0 mt-3 mb-0 mx-0 form-subheader">Date de création</v-subheader>
          <v-divider class="mb-1"></v-divider>
          <v-col class="pr-1 " cols="6">
            <smart-picker
                v-model="searchform.startDate"
                :stopDate="searchform.stopDate"
                :inputOpts="{ label: $t('begin') }"
            />
          </v-col>
          <v-col cols="6" class="">
            <smart-picker
                v-model="searchform.stopDate"
                :startDate="searchform.startDate"
                :inputOpts="{ label: $t('end') }"
            />
          </v-col>

        </v-row>
        <v-row no-gutters class="pt-2 pr-1">
          <v-col cols="12" class="mt-2">
            <v-subheader light class="pa-0 mb-1 mx-0 form-subheader"
            >Statut de lead
            </v-subheader
            >
            <v-divider class="mb-1"></v-divider>
          </v-col>
          <v-col cols="6">
            <v-checkbox
                v-for="(bs, index) in leadStatus"
                :key="'bs-' + index"
                v-model="searchform.status"
                :label="bs.label"
                :value="bs.value"
                :class="'pt-1 ' + (index == 0 ? 'mt-2' : 'mt-0')"
                height="1"
                multiple
            >
            </v-checkbox>
          </v-col>
        </v-row>
        <smart-btn class="mt-3"
                   :disabled="!formValid || loading"
                   :loading="loading"
                   type="submit"
                   block
                   primary
        >Rechercher
        </smart-btn
        >
        <smart-btn
            block
            tertiary
            @click.native="resetForm"
        >Réinitialiser la recherche
        </smart-btn
        >
      </v-form>
    </template>
    <template slot="content">
      <div class="pa-3">
        <v-data-table class="mt-3 elevation-1 tbl"
                      dense
                      :loading="loading"
                      :headers="headers"
                      :items="leads"
                      :items-per-page="-1"
                      search-field="name"
                      item-key="name"
                      hide-default-footer>

          <template #item="{item}">
            <tr
                @click.ctrl="toLead(item, true)"
                @click.meta="toLead(item, true)"
                @click.exact="toLead(item)">
              <td v-if="getHeader('site')">
                <div class="d-flex flex-column">
                  <span class="caption grey--text text-lighten-2">#{{ item.id }}</span>
                  <site-label :sitename="item.virtualSite"/>
                </div>
              </td>

              <td v-if="getHeader('score')">
                <score-lead :score="item.score"/>
              </td>

              <td v-if="getHeader('customer')">
                <div class="d-flex flex-column">
                  <span class="body-2">{{ item?.customer || "-" }}</span>
                  <span>
                    <v-chip class="mb-1" color="green" text-color="white" x-small v-if="item.status == 'close' && item.converted">Converti</v-chip>
                <v-chip class="mb-1" text-color="white" x-small v-else
                        :color="colorTag(item)">{{
                    $t('lead.status.' + item.status)
                  }}</v-chip>
                    <span v-if="item.status == 'close' && item.failure" class="grey--text lighten-2 caption" >
                      {{
                        $t('lead.fail.' + item.failure)
                      }}</span>

                    <span class=" ml-1 caption grey--text lighten-2">{{item.converted}}</span>

              </span>
                </div>
              </td>
              <td v-if="getHeader('creationDate')">
                <div class="d-flex flex-column">
              <span>{{
                  _parseAndFormatDate(item.creationDate, datePatternConfig.serverLocalDateTime, "dd/MM/yyyy")
                }}</span>
                  <span class="caption grey--text ">{{
                      _parseAndFormatDate(item.creationDate, datePatternConfig.serverLocalDateTime, "hh'h'mm")
                    }}</span>
                </div>
              </td>
              <td v-if="getHeader('source')">
                <v-chip small label>{{ $t('lead.source.' + item.source) }}</v-chip>
              </td>
              <td v-if="getHeader('quotes')">
                <div class="d-flex justify-start caption">
                  {{ item.quotes }}
                </div>
              </td>
              <td v-if="getHeader('destinations')">
                <div class="d-flex justify-start body-2">
                  {{ item.destinations.join(", ") || "-" }}
                </div>
              </td>
              <td v-if="getHeader('budget')">
                {{ item.budget |currency('EUR') }}
              </td>
            </tr>
          </template>

        </v-data-table>
        <smart-dialog
            v-model="newLeadModal"
            title="Nouveau Lead">
          <LeadCreationDialog @done="(item) => closeNewLeadModal(item)"/>
          <template title="actions">
            <v-card-actions class="actions-btn mt-4">
              <v-spacer/>
              <smart-btn type="submit" form="creation-lead">
                <v-icon small class="mr-1">mdi-content-save</v-icon>
                Créer
              </smart-btn>
            </v-card-actions>
          </template>
        </smart-dialog>

      </div>
    </template>
  </layout>
</template>
<script>
import Layout from "@/views/Layout.vue";
import {get, post} from "@/utils/api";
import FormMixin from "@/components/mixins/FormMixin";
import SiteLabel from "@/components/commons/SiteLabel.vue";
import SmartSelect from "@/components/commons/SmartSelect.vue";
import ScoreLead from "@/components/booking/ScoreLead.vue";
import LeadCreationDialog from "@/views/lead/LeadCreationDialog.vue";
import {EventBus} from "@/components/commons/event-bus";

export default {
  components: {
    ScoreLead,
    SmartSelect,
    SiteLabel,
    Layout,
    LeadCreationDialog
  },
  mixins: [FormMixin],
  data() {
    return {
      searchform: {
        startDate: null,
        stopDate: null,
        destinations: [],
        site: 1,
        status: [],
      },
      defaultSearchform: {
        startDate: this._formatDate(new Date(), "dd/MM/yyyy"),
        stopDate: this._formatDate(new Date(), "dd/MM/yyyy"),
        destinations: [],
        site: 1,
        status: ["open"],
      },
      leadStatus: [
        {label: "Ouvert", value: 'open'},
        {label: "Clos", value: 'close'},
        {label: "Attente client", value: 'pending'}
      ],
      sites: [],
      loading: false,
      newLeadModal: false,
      leads: [],
      headers: [
        {
          text: "Site",
          value: "site",
          align: "left",
          sortable: true,
        },
        {
          text: "Score",
          value: "score",
          align: "left",
          sortable: true,
        },
        {
          text: "Client",
          value: "customer",
          align: "left",
          sortable: true,
          sort: (x, y) => {
            if (!x && y) {
              return 1
            } else if(x && !y) {
              return -1
            }

            if (x < y) {
              return -1;
            } else if (x > y) {
              return 1;
            } else return 0;
          }

        },
        {
          text: "Création",
          value: "creationDate",
          align: "left",
          sortable: true,
          sort: this.sortByDateTime
        }
        ,
        {
          text: "Source",
          value:
              "source",
          align:
              "left",
          sortable:
              true,
        },
        {
          text: "Devis",
          value: "quotes",
          align: "left",
          sortable: true,
        },
        {
          text: "Destinations",
          value:
              "destinations",
          align:
              "left",
          sortable:
              false,
        }
        ,
        {
          text: "Budget",
          value:
              "budget",
          align:
              "left",
          sortable:
              true,
        }
      ],
    }
  },
  mounted() {
    this.search()
    EventBus.$on("reloadListLead", () => {
      this.search()
    });
  },
  created() {
    this.initSearchform()
    get("/api/search/sites").json((rs) => {
      this.sites = rs;
    });
  },
  destroyed() {
    EventBus.$off("reloadListLead");
  },
  computed: {
    formValid() {
      return this.searchform.startDate != null && this.searchform.stopDate != null && this.searchform.status.length > 0
    }
  },
  methods: {
    initSearchform() {
      const qs = new URLSearchParams(window.location.search);
      const search = (() => {
        try {
          return JSON.parse(qs.get("search"));
        } catch (e) {
          return null;
        }
      })();

      if (search) {
        this.searchform = search;
      } else this.clearSearchform();
    },
    clearSearchform() {
      this.searchform = { ...this.defaultSearchform };
      },
    getHeader(value) {
      return this.headers.find((x) => x.value === value) != null;
    }
    ,
    search() {
      window.history.pushState(
          "",
          "",
          "?search=" + JSON.stringify(this.searchform)
      );
      this.loading = true
      this.leads = []
      const body = {
        startDate: this._parseAndFormatDate(this.searchform.startDate, "dd/MM/yyyy", "yyyy-MM-dd"),
        stopDate: this._parseAndFormatDate(this.searchform.stopDate, "dd/MM/yyyy", "yyyy-MM-dd"),
        destinations: [],
        site: this.searchform.site,
        status: this.searchform.status
      }
      post('/api/lead/list', body)

          .json(data => {
            this.leads = data.map(d => {
              return {
                ...d,
                customer: d.customer ? `${d.customer.lastname} ${d.customer.firstname}` : null
              }
            })
          })
          .finally(() => {
            this.loading = false
          })
    }
    ,
    createLead() {
      this.newLeadModal = true
    }
    ,
    toLead(lead, newTab) {
      if (newTab) {
        let route = this.$router.resolve({
          name: "lead",
          params: {id: lead.id},
        });
        window.open(route.href, "_blank");

      } else {
        this.$router.push({name: "lead", params: {id: lead.id}});
      }
    }
    ,
    resetForm() {
      this.searchform = this.defaultSearchform
    }
    ,
    getDestiString(item) {
      return item.destinations.length > 0 ? item.destinations.map(i => i.name).join(', ') : "-"
    },
    closeNewLeadModal(lead) {
      this.newLeadModal = false
      this.toLead(lead)
    },
    sortByDateTime(a, b) {
      return this._sortDates(a, b, this.datePatternConfig.serverLocalDateTime);
    },
    colorTag(item) {
      return item.status == 'open' || (item.status == 'close' && item.converted)
          ? 'green'
          :
          item.status == 'close'
              ? 'red'
              :'orange'
    },

  }

}
</script>