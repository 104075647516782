<template>
	<v-row dense>

		<v-col
			v-if="isAgency"
			cols="12"
			class="ma-0 pa-0"
		>
			<v-text-field
				class="pa-1"
				:label="`Nom Agence¨`"
				hide-details
				autocomplete="new-password"
				v-model="customer.name"
				:rules="ready ? rules.nonEmpty() : []"
			></v-text-field>
		</v-col>

		<v-col
			v-if="customer.civility"
			cols="2"
		>
			<v-select
				class="pa-1"
				hide-details
				:items="civilities"
				v-model="customer.civility"
				label="Civilité"
			></v-select>
		</v-col>
		<v-col :cols="isAgency?6:5">
			<v-text-field
				class="pa-1"
				:label="`Nom${appendLabelAgent}*`"
				hide-details
				autocomplete="new-password"
				v-model="customer.lastname"
				:rules="ready ? rules.nonEmpty() : []"
			></v-text-field>
		</v-col>
		<v-col :cols="isAgency?6:5">
			<v-text-field
				class="pa-1"
				:label="`Prénom${appendLabelAgent}*`"
				hide-details
				autocomplete="new-password"
				:rules="ready ? rules.nonEmpty() : []"
				v-model="customer.firstname"
			></v-text-field>
		</v-col>
		<v-col cols="6">
			<v-text-field
				class="pa-1"
				:label="`E-Mail${appendLabelAgency}*`"
				hide-details
				autocomplete="new-password"
				:rules="ready ? [...rules.nonEmpty(), ...rules.email('')] : []"
				v-model="customer.email"
			></v-text-field>
		</v-col>
		<v-col cols="6">
			<v-row dense>

				<v-col
					cols="12"
					class="ma-0 pa-0"
				>
					<v-text-field
						class="pa-1"
						:label="`Téléphone${appendLabelAgency}*`"
						autocomplete="new-password"
						hide-details
						v-model="customer.fixe"
					>
						<template slot="prepend">
							<v-select
								label=""
								append-icon=""
								hide-details
								class="selectCountriesForNumber"
								v-model="customer.selectedCountriesForNumber"
								:items="countriesForNumber"
								item-text="name"
								item-value="code"
								dense
							>
								<template
									slot="selection"
									slot-scope="{ item }"
								>
									<span>{{ item.code }}</span>
								</template>
								<template slot="append-item">
									<v-list-item @click="loadOtherCountriesForNumber">
										<v-list-item-content>
											<v-list-item-title>Afficher plus</v-list-item-title>
										</v-list-item-content>
									</v-list-item>
								</template>
							</v-select>
						</template>
					</v-text-field>
				</v-col>
			</v-row>
		</v-col>
		<v-col cols="12">
			<v-combobox
				v-model="customer.otherPhones"
				class="pa-1"
				label="Autres numéros"
				autocomplete="new-password"
				small-chips
				hide-details
				hide-no-data
				hide-selected
				multiple
				hint="'Entrée' ou 'Tab' pour ajouter un numéro a la liste"
			></v-combobox>
		</v-col>

	</v-row>
</template>

<script>
import { get } from '@/utils/api'
import FormMixin from "@/components/mixins/FormMixin";

export default {
	name: "CustomerInfos",
	props: {
		value: {
			type: Object,
			required: true
		},
		isAgency: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	mixins: [FormMixin],
	data() {
		return {
			countriesForNumber: [
				{ code: 'FR', name: 'France' },
				{ code: 'RE', name: 'Réunion' },
				{ code: 'CH', name: 'Suisse' },
				{ code: 'BE', name: 'Belgique' },
				{ code: 'LX', name: 'Luxembourg' },
				{ code: 'MQ', name: 'Martinique' },
				{ code: 'GP', name: 'Guadeloupe' },
				{ code: 'YT', name: 'Mayotte' }
			], //default list
			civilities: [
				{ value: 'Mister', text: 'M.' },
				{ value: 'Madam', text: 'Mme' },
				{ value: 'Miss', text: 'Mlle' },
			],
			customer: {},

			ready: false,
		}
	},
	created() {
	},
	watch: {
		value: {
			deep: true, immediate: true, handler(newVal) {
				this.init(newVal)
			}
		},
		customer: {
			deep: true, handler(newVal) {
				this.$emit('input', newVal)
			}
		}
	},
	computed: {
		appendLabelAgent() {
			return this.isAgency ? ' agent' : ''
		},
		appendLabelAgency() {
			return this.isAgency ? ' agence' : ''
		}
	},
	methods: {
		init(val) {
			if (val != null)
				this.customer = val

			this.$nextTick(() => this.ready = true)
		},
		loadOtherCountriesForNumber() {
			const url = `/api/search/countries?q=`

			get(url)
				.json((r) => {
					this.countriesForNumber = r.reduce((acc, curr) => {
						if (acc.indexOf(curr) > 0) {
							return acc
						} else {
							return acc.concat(curr)
						}
					}, this.countriesForNumber)
				})
		}

	}
}
</script>

<style scoped>
>>>.selectCountriesForNumber {
	width: 30px;
}

>>>.v-select__slot {
	right: -5px;
}
</style>
