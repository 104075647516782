<template>
  <layout :drawer="true" :drawerShow="_isDesktop()" :drawerWidth="300">
    <template slot="drawer">
      <v-row>
        <v-col>
          <v-form
              ref="searchform"
              v-if="searchform"
              v-on:submit.prevent="search()"
          >
            <v-row no-gutters class="pt-2 pr-1">
              <v-col cols="12" class="pr-1">
                <v-text-field
                    v-model="searchform.postalCode"
                    prepend-inner-icon="mdi-magnify"
                    label="Code Postal"
                    placeholder="Rechercher un code postal"
                ></v-text-field>
              </v-col>

              <v-col cols="12" class="pb-0">
            <v-subheader light class="pa-0 mb-0 mx-0 form-subheader"
            >Status</v-subheader>

          </v-col>
          <v-col cols="12">
            <v-checkbox
                v-for="(bs, index) in LogStatus"
                :key="'bs-' + index"
                v-model="searchform.logStatus"
                :label="bs.label"
                :value="bs.value"
                :class="'pt-1 ' + (index == 0 ? 'mt-2' : 'mt-0')"
                height="1"
            ></v-checkbox>
          </v-col>

            </v-row>

            <smart-btn
                :loading="listing.loading"
                type="submit"
                block
                primary
                @click="search"
                :disabled="searchable">
              Rechercher
            </smart-btn>
          </v-form>
          </v-col>
      </v-row>
    </template>
    <template slot="content">
      <v-container fluid>
        <div class="mt-1" style="max-width:1024px;margin:auto">
          <v-row>
            <v-col cols="12" class="">
              <v-data-table
                  :header-props="{ sortByText: 'Trier par' }"
                  :headers="listing.headers"
                  :loading="listing.loading"
                  :items="listing.items"
                  :options.sync="listing.pagination"
                  :hide-default-footer="true"
                  class="elevation-1 tbl"
                  no-data-text="Il n'y a pas d'agences à afficher"
              >
                <template #item.agencyName="{ item }">
                  <div>{{ item.agencyName }}</div>
                  <span class="caption grey--text text--darken-1">
                {{ item.address }}
              </span>
                </template>
                <template #item.lastEventDate="{item, value}">
                  <div>
                    {{ _parseAndFormatDate(value, "yyyy-MM-dd", "dd/MM/yyyy") }}
                  </div>
                </template>
                <template #item.lastEventStatus="{item, value}">
                  <div>
                    <v-chip label small :class="backGroundStatus(value).bgAndText"
                            class="d-flex justify-center align-center"
                            style="width: 80px"
                            :style="'border:solid 1px ' + backGroundStatus(value).border + '!important'">
                      {{ $t(`logStatus.${item.lastEventStatus}`) }}
                    </v-chip>
                  </div>
                </template>

                <template #item.ratio="{item}">
                  <span>{{ item.agentWithDemo }}/{{ item.nbAgent }}</span>
                </template>
                <template #item.actions="{item}">
                  <v-tooltip bottom>
                    <template #activator="{ on }">
                      <v-btn dense v-on="on" color="blue lighten-1" class="buttonNewEvent d-flex align-center pa-2"
                             @click.stop="setEventEditor(item.agencyId)">
                        <v-icon color="white">mdi-folder-plus</v-icon>
                      </v-btn>
                    </template>
                    <span>Ajouter un évènement</span>
                  </v-tooltip>
                </template>
              </v-data-table>
              <smart-dialog
                  v-if="openEventEditor"
                  title="Créer un évènement"
                  v-model="openEventEditor"
                  :width="900"
                  @close="onClose()"
              >
                <CrmNewEvent @closeEvent="openEventEditor = false" :selectorAgents="selectorAgents"
                             :agencyId="agencyId"/>
              </smart-dialog>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </template>
  </layout>
</template>
<script>
import Layout from "@/views/Layout.vue";
import CrmNewEvent from "@/components/commons/CrmNewEvent.vue";
import {get, post} from "@/utils/api";

export default {
  name: "SuiviAgence",
  components: {
    CrmNewEvent,
    Layout,
  },
  props: {},
  data() {
    return {
      LogStatus: [
        { value: "toreview", label: "Review" },
        { value: "validated", label: "Validé" },
        { value: "pending", label: "En attente" },
      ],
      searchform: {
        postalCode: null,
        logStatus:["toreview"]
      },
      listing: {
        rowsPerPageItems: [{text: "Tous", value: -1}, 50, 200],
        pagination: {
          options: {
            page: 1,
            itemsPerPage: 200,
            sortBy: [],
            sortDesc: [false],
          },
        },
        loading: false,
        headers: [
          {
            text: "Agence",
            value: "agencyName",
          },
          {
            text: "Réseau",
            value: "networkName",
          },
          {
            text: "Tel",
            value: "phone",
          },
          {
            text: "Dernier évènement",
            value: "lastEventDate",
          },
          {
            text: "Statut",
            value: "lastEventStatus",
          },
          {
            text: "Démo",
            value: "ratio"
          },
          {
            text: "",
            value: "actions",
            sortable: false
          }

        ],
        items: [],
      },
      agents: [],
      selectorAgents: [],
      marketingAreas: [],
      networks: [],
      openEventEditor: false,
    }
  },
  created() {
    this.init();
  },
  computed:{
    searchable(){
      return this.listing.loading || (this.searchform.postalCode == null || this.searchform.postalCode == "")
    }
  },
  methods: {
    init() {
      /* FUTURE
       await this.getMarketingAreas();
       await this.getNetworks();
       */
    },
    search() {

      const url = "/api/agency/tracking/search";

      this.listing.items = [];
      this.listing.loading = true;
      post(url, this.searchform)
          .json((data) => {
            this.listing.items = data;
            this.listing.loading = false;
          })

    },
    async getMarketingAreas() {
      const url = "/api/search/marketingarea";
      return get(url).json((data) => {
        this.marketingAreas = data;
      });
    },
    async getNetworks() {
      const url = "/api/search/networks";
      return get(url).json((data) => {
        this.networks = data;
      });
    },
    getAgents(id) {
      const url = `/api/agency/${id}/agents`;

      return get(url)
          .badRequest((br) => console.log("Error: ", br))
          .json((data) => {
            return data.map((agent) => {
              return {
                id: agent.id,
                lastname: agent.lastname ? agent.lastname : "-",
                firstname: agent.firstname ? agent.firstname : "-",
                email: agent.email ? agent.email : "-",
                login: agent.login ? agent.login : "-",
                password: null,
                phone: agent.phone ? agent.phone : "-",
                active: agent.active || agent.active == null,
                isFromSmartresas: agent.isFromSmartresas,
                agencyName: agent.agencyName ? agent.agencyName : null,
                allowIncentive: agent.allowIncentive
              };
            });
          })
          .then((r) => {
            this.agents = r;
            this.selectorAgents = r.filter(agent => agent.active).map((item) => {
              return {
                id: item.id,
                name: item.firstname + " " + item.lastname
              };
            });
          });
    },
    onClose(){
      this.openEventEditor = false
      this.int()
    },
    setEventEditor(id) {
      this.openEventEditor = true
      this.agencyId = id
      this.getAgents(id)

    },
    backGroundStatus(value) {
      switch (value) {
        case 'toreview':
          return {bgAndText: "blue lighten-4 blue--text text--darken-2", border: "#64B5F6"}
        case 'validated':
          return {bgAndText: "green lighten-4 green--text text--darken-2", border: "#81C784"}
        case 'pending':
          return {bgAndText: "yellow lighten-4 yellow--text text--darken-4", border: "#FDD835"}
      }
    },
  }
  ,


}
</script>
