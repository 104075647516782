<template>
  <layout :drawerShow="_isDesktop()" :drawerWidth="350">
    <template slot="nav-tools">
      <smart-btn class="mr-1" @click.native="createDialog = true">
        <v-icon>mdi-folder-plus</v-icon>
        <span class="pl-1">Créer une action</span>
      </smart-btn>
    </template>

    <template slot="drawer">
      <v-form ref="searchform" v-if="searchform" @submit.prevent="getList">
        <v-row no-gutters class="pt-2 pr-1">
          <v-col cols="12" class="pr-1 mt-4">
            <smart-multi-select
              v-model="searchform.usersIds"
              label="Responsable"
              :items="users"
              :all-is-same-as-empty="true"
              :default-select-all="true"
              item-text="name"
              item-value="id"
            >
            </smart-multi-select>
          </v-col>

          <v-col cols="12" class="mt-2">
            <v-subheader light class="pa-0 mb-0 mx-0 form-subheader"
              >Dates limite</v-subheader
            >
            <v-divider></v-divider>
          </v-col>
          <v-col class="pr-1" cols="6">
            <smart-picker
              v-model="searchform.taskExpirationStartDate"
              :stopDate="searchform.taskExpirationStopDate"
              :inputOpts="{
                label: $t('begin'),
                clearable: true,
                placeholder: ' ',
              }"
            ></smart-picker>
          </v-col>
          <v-col cols="6" class="pr-1">
            <smart-picker
              v-model="searchform.taskExpirationStopDate"
              :startDate="searchform.taskExpirationStartDate"
              :inputOpts="{
                label: $t('end'),
                clearable: true,
                placeholder: ' ',
              }"
            ></smart-picker>
          </v-col>
          <v-col cols="12" class="pr-1 mt-8"></v-col>
          <smart-multi-select
            v-model="searchform.types"
            label="Niveaux"
            :items="types"
            :all-is-same-as-empty="true"
            :default-select-all="true"
          ></smart-multi-select>

          <smart-multi-select
            class="mt-4"
            v-model="searchform.groups"
            label="Groupe"
            :items="groups"
            :all-is-same-as-empty="true"
            :default-select-all="true"
          ></smart-multi-select>
        </v-row>
        <smart-btn :loading="listing.loading" type="submit" block primary
          >Rechercher</smart-btn
        >

        <v-col cols="12" class="mt-2">
          <v-subheader light class="pa-0 mb-0 mx-0 form-subheader"
            >Légende</v-subheader
          >
          <v-divider></v-divider>
        </v-col>

        <v-col cols="12" class>
          <v-row class="pastTask" dense>
            <small>Expiration passé</small>
          </v-row>
          <v-row class="todoTask mt-1" dense>
            <small>Expiration aujourd'hui</small>
          </v-row>
        </v-col>
      </v-form>
    </template>

    <template slot="content">
      <v-container fluid>
        <v-row>
          <v-col cols="12">
            <v-data-table
              ref="tasktable"
              :loading="listing.loading"
              :headers="listing.headers"
              :items="listing.items"
              :options.sync="listing.pagination"
              :footer-props="{
                'items-per-page-options': listing.rowsPerPageItems,
                'items-per-page-text': 'Lignes par page',
              }"
              :expand="true"
              item-key="ref"
              class="tbl"
              no-data-text="Il n'y a pas d'actions disponibles"
            >
              <template #item.site="{ item, value }">
                <div
                  style="line-height: 48px; vertical-align: middle"
                  :class="priorityColor(item)"
                >
                  <site-label :sitename="value"></site-label>
                </div>
              </template>
              <template #item.bookingId="{ value }">
                <span
                  @click.ctrl="goto(value, true)"
                  @click.meta="goto(value, true)"
                  @click.exact="goto(value)"
                >
                  {{ value }}
                </span>
              </template>
              <template #item.bookingDate="{ item, value }">
                {{
                  _parseAndFormatDate(
                    value,
                    datePatternConfig.serverLocalDateTime,
                    datePatternConfig.serverLocalDate
                  )
                }}
              </template>
              <template #item.bookingStartDate="{ item, value }">
                {{
                  _parseAndFormatDate(
                    value,
                    datePatternConfig.serverLocalDateTime,
                    datePatternConfig.serverLocalDate
                  )
                }}
              </template>
              <template #item.expirationDate="{ item, value }">
                {{
                  _parseAndFormatDate(
                    value,
                    datePatternConfig.serverLocalDateTime,
                    datePatternConfig.serverLocalDate
                  )
                }}
              </template>
              <template #item.task.action="{ item, value }">
                <v-chip
                  label
                  small
                  outlined
                  :class="
                    taskTextColor(item.task.level) +
                    ' ' +
                    taskColor(item.task.level)
                  "
                >
                  {{ $t("tasklevel." + item.level) }}</v-chip
                >
                <span :class="colorsTaskType(item)">
                  <v-icon
                    small
                    v-if="item.prestationType && item.prestationName"
                    class="ml-1"
                    :class="colorsTaskType(item)"
                    >mdi-{{ icon(item.prestationType) }}</v-icon
                  >
                  {{ item.message }} {{ item.prestationName }}
                </span>
              </template>
              <template #item.edition="{ item, value }">
                <v-icon @click.native="item.task.editDialog = true"
                  >mdi-playlist-edit</v-icon
                >
                <v-icon @click.native="item.task.attributeDialog = true"
                  >mdi-swap-horizontal</v-icon
                >

                <todo-item :task="item.task" @save="getList()"></todo-item>
                <attribute-booking-task
                  :task="item.task"
                  :users="users"
                  :show.sync="item.task.attributeDialog"
                  @attributed="
                    item.task.attributeDialog = false;
                    getList();
                  "
                ></attribute-booking-task>
              </template>
            </v-data-table>
            <smart-dialog v-model="createDialog" title="Création">
              <task-edit
                @saved="
                  createDialog = false;
                  getList();
                "
                @change="change"
              ></task-edit>
            </smart-dialog>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </layout>
</template>

<script>
import { get, post } from "../../utils/api";
import Layout from "@/views/Layout";
import SiteLabel from "@/components/commons/SiteLabel";
import TaskItem from "../../components/commons/TaskItem";
import TaskEdit from "@/components/commons/TaskEdit";
import TodoItem from "./TodoItem";
import ColorMixin from "../../components/mixins/ColorMixin";
import SmartMultiSelect from "../../components/commons/SmartMultiSelect";
import SmartSelect from "../../components/commons/SmartSelect";
import _ from "lodash";
import AttributeBookingTask from "./AttributeBookingTask.vue";

export default {
  name: "TodoTask",
  components: {
    Layout,
    SiteLabel,
    TaskItem,
    TaskEdit,
    TodoItem,
    SmartMultiSelect,
    SmartSelect,
    AttributeBookingTask,
  },
  mixins: [ColorMixin],
  data() {
    return {
      createDialog: false,
      listing: {
        loading: false,
        rowsPerPageItems: [200, 500, { text: "Tous", value: -1 }],
        headers: [
          { text: "Site", value: "site", align: "left", sortable: false },
          { text: "Dossier", value: "bookingId", align: "left" },
          {
            text: "Résa",
            value: "bookingDate",
            align: "left",
            sort: this.sortByDateTime,
          },
          {
            text: "Départ",
            value: "bookingStartDate",
            align: "left",
            sort: this.sortByDateTime,
          },
          { text: "Client", value: "customerName", align: "left" },
          {
            text: "Destination",
            value: "destinationName",
            align: "left",
            sortable: false,
          },
          {
            text: "Action",
            value: "task.action",
            align: "left",
            width: "30%",
            sort: this.actionSort,
          },
          {
            text: "Date d'expi",
            value: "expirationDate",
            align: "left",
            sort: this.sortByDateTime,
          },
          {
            text: "Utilisateur",
            align: "right",
            value: "user",
            width: "50px",
            sortable: false,
          },
          {
            text: "",
            align: "right",
            value: "edition",
            width: "50px",
            sortable: false,
          },
        ],
        items: [],
      },

      types: [
        { name: "Urgence absolue", id: "AbsoluteEmergency" },
        { name: "Urgence", id: "Emergency" },
        { name: "Normal", id: "Normal" },
        { name: "Contrôle", id: "Control" },
      ],
      groups: [
        { id: 1, name: "Billeterie" },
        { id: 2, name: "Request" },
        { id: 3, name: "Résa" },
        { id: 4, name: "Compta" },
      ],
      users: [],

      searchform: {
        types: [],
        taskExpirationStartDate: null,
        taskExpirationStopDate: null,
        groups: [],
        usersIds: [],
      },
    };
  },
  created() {
    this.init();
  },
  computed: {},
  methods: {
    async init() {
      this.searchform.types = this.types;
      this.searchform.groups = this.groups;
      await this.getUsers();

      this.getList();
    },
    async getUsers() {
      const url = "/api/search/users";
      return get(url).json((r) => {
        this.users = r.filter((u) => {
          return (
            u.active &&
            _.intersection(
              u.groups.map((g) => g.id),
              this.groups.map((g) => g.id)
            ).length > 0
          );
        });

        const currentUser = this.users.find(
          (u) => u.email == this.currentUser.email
        );
        this.searchform.usersIds = currentUser
          ? [currentUser.id]
          : this.users.map((u) => u.id);
      });
    },
    getList() {
      if (!this.listing.loading) {
        this.listing.loading = true;
        const url = "/api/task/search";

        const params = {
          ...this.searchform,
          ...{ types: this.searchform.types.map((t) => t.id || t) },
          ...{ groups: this.searchform.groups.map((t) => t.id || t) },
        };

        post(url, params).json((r) => {
          r.forEach((item) => {
            //awaiting https://github.com/vuetifyjs/vuetify/issues/2890 to be close, we use that as a temp solution
            this.$set(this.$refs.tasktable.expanded, item.ref, false);
          });
          this.listing.items = r.map((d) => {
            return {
              ...d,
              task: {
                id: d.id,
                bookingId: d.bookingId,
                level: this.taskLevelIndex[d.level],
                levelId: d.level,
                groupId: d.groupId,
                expirationDate: this._parseAndFormatDate(
                  d.expirationDate,
                  this.datePatternConfig.serverLocalDateTime,
                  this.datePatternConfig.serverLocalDate
                ),
                action: { message: d.message, level: d.level },
                closed: d.closed,
                editDialog: false,
                attributeDialog: false,
              },
              ...{ over: false },
            };
          });
          this.listing.loading = false;
        });
      }
    },
    openBooking(id) {
      let route = this.$router.resolve({ name: "Booking", params: { id: id } });
      window.open(route.href, "_blank");
    },
    change() {
      this.$emit("change", {});
    },
    priorityColor(task) {
      const date = this._parseDate(
        task.expirationDate,
        this.datePatternConfig.serverLocalDateTime
      );

      if (this._isSame(date, this._now(), "day")) {
        return "todoTask";
      } else if (this._isBefore(date, this._now())) {
        return "pastTask";
      } else {
        return "";
      }
    },
    colorsTaskType(task) {
      if (task.level == 1) {
        return this.colorsTaskList.AbsoluteEmergency.text;
      } else if (task.level == 2) {
        return this.colorsTaskList.Emergency.text;
      } else if (task.level == 3) {
        return this.colorsTaskList.Normal.text;
      } else if (task.level == 4) {
        return this.colorsTaskList.Control.text;
      }
    },
    customSort(items, index, isDesc) {
      const formatPattern = "yyyyMMdd";

      if (isDesc !== null) {
        items.sort((a, b) => {
          let aEl = a[index];
          let bEl = b[index];

          if (index === "expirationDate") {
            aEl = this._parseAndFormatDate(
              a[index],
              this.datePatternConfig.serverLocalDateTime,
              formatPattern
            );
            bEl = this._parseAndFormatDate(
              b[index],
              this.datePatternConfig.serverLocalDateTime,
              formatPattern
            );
          }
          return !isDesc ? (aEl < bEl ? -1 : 1) : bEl < aEl ? -1 : 1;
        });
      }
      return items;
    },
    sortByDateTime(a, b) {
      return this._sortDates(a, b, this.datePatternConfig.serverLocalDateTime);
    },

    actionSort(a, b) {
      return a.level - b.level < 0 ? -1 : 1;
    },

    icon(type) {
      switch (type) {
        case "room":
          return "hotel";
        case "transfer":
          return "bus";
        case "carrental":
          return "car";
        case "flight":
          return "airplane";
        default:
          return "mdi-help";
      }
    },
    goto(bookingId, newTab) {
      if (newTab) {
        let route = this.$router.resolve({
          name: "Booking",
          params: { id: bookingId },
        });
        window.open(route.href, "_blank");
        return false;
      } else {
        this.$router.push({ name: "Booking", params: { id: bookingId } });
      }
    },
  },
};
</script>

<style scoped>
>>> table tr td:first-child {
  padding-left: 0 !important;
}

>>> table tr td:first-child > div {
  padding-left: 5px;
}

.todoTask {
  height: 100%;
  border-left: solid 10px #d4e157;
}

>>> .pastTask {
  height: 100%;
  border-left: solid 10px #ff9100;
}
</style>
