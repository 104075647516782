<template>
  <prestation
    kind="prestationgroup"
    ref="prestationcpnt"
    :booking="booking"
    :prestation="prestation"
    :index="index"
    :loading="disablePanel"
    :renderfab="renderfab"
  >
    <div slot="header-sup">{{prestation.start}} - {{prestation.stop}}</div>

    <div slot="header-title">
      <div class="subtitle-1 font-weight-medium">
        {{prestation.name}}
        <availability v-model="prestation.availability" kind="Stock" :chip="false" class="caption" />
      </div>
    </div>

    <div slot="header-status">
      <availability v-model="prestation.status" kind="BookingStatus" />
      <availability v-model="prestation.supplierStatus" kind="SupplierStatus" />
    </div>

    <div slot="header-price">
      <div
        class="headline font-weight-regular"
      >{{prestation.price}} {{$t(prestation.currency+"-symbol")}}</div>
    </div>

    <div slot="content">
      <!-- ----------------------------- dialogs ----------------------------- -->

      <v-row v-if="prestation.oldPrestationGroup" class="align-center">
        <v-col cols="8" class="pa-1 elevation-1 grey-lighten-5" style="background:#FAFAFA;">
          <v-icon size="14">mdi-timer-sand</v-icon>
          Remplace la prestation
          {{prestation.oldPrestationGroup.name}}
          <availability v-model="prestation.oldPrestationGroup.status" kind="BookingStatus" />
          <availability
            v-model="prestation.oldPrestationGroup.supplierStatus"
            kind="SupplierStatus"
          />

          <b>{{prestation.oldPrestationGroup.price | currency(prestation.oldPrestationGroup.currency)}}</b>

          <v-icon size="14" @click="showOldPrestationGroup = !showOldPrestationGroup">mdi-eye</v-icon>
        </v-col>
      </v-row>

      <smart-dialog
        v-if="prestation.oldPrestationGroup"
        v-model="showOldPrestationGroup"
        :width="900"
        :title="prestation.oldPrestationGroup.name"
      >
        <template>
          <prestation-untyped
            :booking="booking"
            :prestation="prestation.oldPrestationGroup"
            :index="0"
            :renderfab="false"
          ></prestation-untyped>
        </template>
        <v-btn
          dark
          icon
          absolute
          top
          right
          style="top:6px;right:70px;"
          @click="addPrestationRecap=true"
        >
          <v-icon>mdi-information-outline</v-icon>
        </v-btn>

        <v-navigation-drawer
          v-model="addPrestationRecap"
          absolute
          right
          temporary
          style="margin-top: 48px;height:calc(100% - 48px)"
        >
          <booking-drawer-content v-if="booking" :booking="booking"></booking-drawer-content>
        </v-navigation-drawer>
      </smart-dialog>
    </div>
  </prestation>
</template>
<script>
import GuestList from "@/components/commons/GuestList";
import CollapsePanel from "@/components/commons/CollapsePanel";
import BookingPriceComputing from "@/components/commons/BookingPriceComputing";
import BookingPrestaInvoices from "@/components/commons/BookingPrestaInvoices";
import BookingPrestaVoucher from "@/components/commons/BookingPrestaVoucher";
import PrestationMixin from "@/components/mixins/PrestationMixin";
import GuestsMixin from "@/components/mixins/GuestsMixin";
import Availability from "@/components/commons/Availability";
import AddCarrental from "@/components/booking/carrental/AddCarrental";

import BookingPrestaCancelfees from "../commons/BookingPrestaCancelfees";
import Prestation from "@/components/booking/Prestation";
import BookingDrawerContent from "../commons/BookingDrawerContent";
import MailComposer from "../commons/MailComposer";

export default {
  name: "PrestationUntyped",
  props: ["index", "renderfab"],
  mixins: [PrestationMixin, GuestsMixin],
  components: {
    MailComposer,
    BookingDrawerContent,
    BookingPrestaCancelfees,
    AddCarrental,
    CollapsePanel,
    Availability,
    BookingPrestaVoucher,
    BookingPrestaInvoices,
    BookingPriceComputing,
    GuestList,
    Prestation,
  },

  data() {
    return {
      addPrestationRecap: false,
      guests: this.prestation.guests,
      panel: 1, //default open
      editDialog: false,

      editOptionsDialog: false,
      showOldPrestationGroup: false,
      carRentDetails: null,

      loading: false,

      evtShow: this.prestation.prestationLink
        ? "over-" +
          this.prestation.kind +
          "-" +
          this.prestation.prestationLink.fromPrestationLinkId
        : null,

      tab: "",
      dialogs: {
        email: false,
      },
      supplierEmails: [],
      mailLoading: false,
    };
  },

  methods: {
    remove() {
      this.deletePrestationGroup();
    },
    cancel() {
      this.cancelPrestationGroup();
    },
  },
};
</script>

<style scoped>
</style>
