<template>
  <v-card>
    <v-card-title>
      <v-icon>account-group</v-icon>
      Détails par passager
    </v-card-title>
    <v-card-text>
      <v-data-table
          :headers="table.headers"
          :footer-props="{'items-per-page-options':[{text: 'Tous', value: -1}]}"
          :items="items"
          hide-default-footer
          dense
      >
        <template #body="{items}">
          <tbody>
          <tr v-for="(item, idx) in items" :key="idx">
            <td>
                <span
                    v-if="item.passenger.firstname && item.passenger.lastname"
                >{{ item.passenger.firstname }} {{ item.passenger.lastname }}</span>
              <span v-else>Aucun nom</span>
            </td>
            <td>{{ item.passenger.ageClassType }}</td>
            <td>{{ item.passenger.withInfant ? "Oui" : "Non" }}</td>
            <td>{{ item.passenger.withBaby ? "Oui" : "Non" }}</td>
            <td>
              <v-chip
                  class="taxChips"
                  label
                  small
                  outlined
                  v-for="(tax, taxIndex) in item.taxes"
                  :key="taxIndex"
              >
                <span>{{ tax.code }} {{ tax.amount }} €</span>
              </v-chip>
            </td>
            <td>{{item.passenger.touristTax}}</td>
            <td>
              <v-btn small text @click="openFareRules(item.passengerSegments)">Règles tarifaires</v-btn>
            </td>
          </tr>
          </tbody>
        </template>
      </v-data-table>
    </v-card-text>

    <smart-dialog v-model="dialog.openFareRules" title="FareRules" max-width="900">
      <v-card>
        <v-card-text class="pt-2">
          <v-tabs>
            <v-tab
                v-for="(f,i) in dialog.content"
                :key="i"
            >{{ f.segmentId ? segmentTitle(f.segmentId) : "" }}
            </v-tab>
            <v-tab-item v-for="(f,i) in dialog.content" :key="i">
              <div class="py-4 text-center">{{ f.description }}</div>
              <v-tabs>
                <v-tab
                    v-for="(k,kIndex) in ['informations', 'ticketEndorsements', 'penalties']"
                    :key="kIndex"
                >{{ k }}
                </v-tab>
                <v-tab-item
                    v-for="(k,kIndex) in ['informations', 'ticketEndorsements', 'penalties']"
                    :key="kIndex"
                >
                  <v-list>
                    <v-list-item
                        v-for="(item, itemIndex) in f[k]"
                        :key="itemIndex"
                        v-if="f[k].length > 0"
                    >{{ itemIndex }} {{ item }}
                    </v-list-item>
                    <v-divider :key="itemIndex+'divider'" v-if="f[k].length > 0"/>
                  </v-list>
                </v-tab-item>
              </v-tabs>
            </v-tab-item>
          </v-tabs>
        </v-card-text>
      </v-card>
    </smart-dialog>
  </v-card>
</template>

<script>
export default {
  name: "BookingPassengersPriceDetails",
  props: {
    prestation: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      items: [],
      table: {
        headers: [
          {
            text: "Nom",
            value: "name",
            sortable: true,
            width: 30,
          },
          {
            text: "Classe d'age",
            value: "ageClassType",
            sortable: true,
            width: 30,
          },
          {
            text: "Enfant(s)",
            value: "withInfant",
            sortable: false,
            width: 30,
          },
          {
            text: "Bébé(s)",
            value: "withBaby",
            sortable: false,
            width: 30,
          },
          {
            text: "Taxes",
            value: "taxes",
            sortable: false,
            width: 30,
          },
          {
            text: "Taxes de séjour",
            value: "touristTax",
            sortable: false,
            width: 30,
          },
          {
            text: "Tarifs",
            value: "fares",
            sortable: false,
            width: 30,
          },
        ],
        items: [],
      },
      dialog: {
        openFareRules: false,
        content: {},
        subHeaders: ["Informations", "TicketEndorsements", "Penalties"],
      },
    };
  },
  created() {
    if (this.prestation.details && this.prestation.details.passengers) {
      this.items = this.prestation.details.passengers;
    }
  },
  methods: {
    openFareRules(segments) {
      this.dialog.openFareRules = true;

      let fareRules = [];
      segments.map((segment) => {
        if (segment.fareRules != null) {
          fareRules.push(segment.fareRules);
        }
      });

      this.dialog.content = fareRules;
    },
    segmentTitle(segmentId) {
      let item = this.prestation.details.segments.find(
          (segment) => segment.id == segmentId
      );
      return item.from + "-" + item.to;
    },
  },
};
</script>

<style scoped>
.taxChips {
  height: 15px !important;
  max-width: 67px !important;
  font-size: 10px;
  color: #2b3b4e !important;
}
</style>
