<template>
  <layout :drawerShow="_isDesktop()" :drawerWidth="350">
    <template slot="drawer">
      <v-form ref="searchform" v-if="searchform" @submit.prevent="loadData">
        <v-row dense>
          <v-col cols="12" class="">
            <v-subheader light class="pa-0 mb-0 mx-0 form-subheader"
              >Réservation</v-subheader
            >
            <v-divider></v-divider>
          </v-col>

          <v-col class="pr-1" cols="6">
            <smart-picker
              v-model="searchform.bookingDateStart"
              :stopDate="searchform.bookingDateStop"
              :inputOpts="{ label: $t('begin') }"
            ></smart-picker>
          </v-col>
          <v-col cols="6">
            <smart-picker
              v-model="searchform.bookingDateStop"
              :startDate="searchform.bookingDateStart"
              :inputOpts="{ label: $t('end') }"
            ></smart-picker>
          </v-col>

          <v-col cols="12" class="">
            <v-subheader light class="pa-0 mb-0 mx-0 mt-2 form-subheader"
              >Dates de voyage</v-subheader
            >
            <v-divider></v-divider>
          </v-col>
          <v-col class="pr-1" cols="6">
            <smart-picker
              v-model="searchform.tripDateStart"
              :stopDate="searchform.tripDateStop"
              :inputOpts="{ label: $t('begin') }"
            ></smart-picker>
          </v-col>
          <v-col cols="6">
            <smart-picker
              v-model="searchform.tripDateStop"
              :startDate="searchform.tripDateStart"
              :inputOpts="{ label: $t('end') }"
            ></smart-picker>
          </v-col>

          <v-col cols="8" class="mb-0 pb-0">
            <v-subheader
              light
              class="pa-0 mb-0 mx-0 mt-4 form-subheader solo-subheader"
              >Etiquettes</v-subheader
            >
          </v-col>
          <v-col cols="4" class="mb-0 pb-0">
            <v-checkbox
              v-model="searchform.excludeTags"
              label="Exclure"
              dense
              hide-details
            />
          </v-col>
          <v-col cols="12" class="mt-0 pt-0">
            <v-divider></v-divider>
          </v-col>
          <v-col cols="12">
            <smart-multi-select
              v-model="searchform.tags"
              :items="tags"
              :all-is-same-as-empty="false"
              :default-select-all="false"
              item-value="id"
              item-text="name"
            ></smart-multi-select>
          </v-col>
        </v-row>
        <smart-btn type="submit" :loading="loading" block primary class="mt-4"
          >Rechercher</smart-btn
        >
        <smart-btn
          block
          tertiary
          @click.native="
            clearSearchform();
            loadData();
          "
          >Réinitialiser la recherche</smart-btn
        >
      </v-form>
    </template>
    <template slot="content">
      <v-container fluid>
        <v-row justify="space-between">
          <v-col cols="12" class="mb-3">
            <v-card class="pa-0 ma-0 blue-grey lighten-1">
              <v-row justify="space-between">
                <v-col cols="12" md="3" class="px-1">
                  <widget-booking-size
                    :bus="widgetBus"
                    url="/api/widget/bookingsize"
                  ></widget-booking-size>
                </v-col>
                <v-col cols="12" md="3" class="px-1">
                  <widget-booking-passenger
                    :bus="widgetBus"
                    url="/api/widget/passangerSize"
                  ></widget-booking-passenger>
                </v-col>
                <v-col cols="12" md="3" class="px-1">
                  <widget-booking-ca
                    :bus="widgetBus"
                    url="/api/widget/bookingCa"
                  ></widget-booking-ca>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col cols="12" class="mb-3">
            <widget-booking-resume
              :bus="widgetBus"
              :render-rows="true"
              url="/api/widget/bookingresume"
            ></widget-booking-resume>
          </v-col>

          <v-col cols="12" class="mb-3">
            <widget-booking-resume-with-flights
              @loading="setLoading"
              :bus="widgetBus"
              :render-rows="true"
            ></widget-booking-resume-with-flights>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </layout>
</template>

<script>
import BookingDetails from "../../components/booking/BookingDetails";
import BookingCustomerDetails from "../../components/booking/BookingCustomerDetails";
import BookingPayments from "../../components/booking/BookingPayments";
import WidgetBookingSize from "../../components/commons/widgets/WidgetBookingSize";
import WidgetBookingPassenger from "../../components/commons/widgets/WidgetBookingPassenger";
import WidgetBookingResume from "../../components/commons/widgets/WidgetBookingResume";
import Vue from "vue";
import Layout from "../Layout";
import WidgetBookingCa from "../../components/commons/widgets/WidgetBookingCa";
import { get } from "@/utils/api";
import WidgetBookingResumeWithFlights from "../../components/commons/widgets/WidgetBookingResumeWithFlights";
import SmartMultiSelect from "../../components/commons/SmartMultiSelect";
import SmartSelect from "../../components/commons/SmartSelect";

export default {
  name: "BookingStats",
  components: {
    SmartSelect,
    SmartMultiSelect,
    WidgetBookingResumeWithFlights,
    WidgetBookingCa,
    Layout,
    WidgetBookingResume,
    WidgetBookingPassenger,
    WidgetBookingSize,
    BookingPayments,
    BookingCustomerDetails,
    BookingDetails,
  },
  mixins: [],
  data() {
    return {
      widgetBus: new Vue(),

      //searchform
      defaultSearchform: {
        selectedSites: [],
        selectedNetworks: [],
        selectedAgencies: [],
        selectedChains: [],
        selectedHotels: [],
        selectedDestinations: [],
        client: null,
        ref: null,
        bookingDateStart: this._nowFormatted("dd/MM/yyyy"),
        bookingDateStop: this._nowFormatted("dd/MM/yyyy"),
        tripDateStart: null,
        tripDateStop: null,
        bookingStatus: ["booked", "cancelled"],
        supplierStatus: [],
        paymentStatus: null,
        excludeTags: true,
        tags: [],
      },
      searchform: {
        tags: [],
      },
      tags: [],
      loading: false,
    };
  },
  created() {
    this.init();
  },
  watch: {},
  computed: {},
  methods: {
    init() {
      setTimeout(() => {
        get("/api/search/tags")
          .json((tags) => {
            this.tags = [...tags];
            this.defaultSearchform.tags = [...tags.map((t) => t.id)];
          })
          .then(() => {
            this.clearSearchform();
            this.loadData();
          });
      }, 1);
    },

    loadData() {
      if (!this.loading) {
        this.widgetBus.$emit("search", this.searchform);
      }
    },
    clearSearchform() {
      this.searchform = { ...this.defaultSearchform };
    },
    setLoading(loading) {
      this.loading = loading;
    },
  },
};
</script>

<style scoped>
.v-subheader {
  width: 100%;
  height: 20px;
  color: #616161;
}
.solo-subheader {
  top: 7px;
  position: relative;
}
</style>
