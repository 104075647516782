<template>
  <div class="white">
    <v-expansion-panels dense>
      <v-expansion-panel v-for="(room, index) in item" :key="index">
        <v-expansion-panel-header>
          Chambre {{index+1}} -
          <small>{{totalGuests(room)}}</small>
          <v-btn v-if="item.length>1" text icon dense small @click="removeRoom(room, index)">
            <v-icon>close</v-icon>
          </v-btn>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <room-repartition
            v-model="item[index]"
            :guests="guests"
            :fixedGuests="fixedGuests"
            :removable="item.length > 1"
            :key="'room '+index"
            :index="index"
          ></room-repartition>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-col cols="12" class="text-right">
      <v-btn small fab right icon @click.native="addRoom">
        <v-icon>add</v-icon>
      </v-btn>
    </v-col>
  </div>
</template>
<script>
import RoomRepartition from "@/components/commons/RoomRepartition";
import GuestsMixin from "@/components/mixins/GuestsMixin";

export default {
  name: "RoomsRepartitionList",
  components: {
    RoomRepartition,
  },
  mixins: [GuestsMixin],
  props: {
    value: { type: Array, default: () => [] },
    guests: { type: Array, default: () => [] },
    fixedGuests: { type: Boolean, default: false },
  },
  data() {
    return {
      item: [],
    };
  },
  watch: {
    item(newVal) {
      this.item = newVal;
      this.$emit("input", this.item);
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.item = this.value;
    },
    addRoom() {
      this.item.push({ guests: [], repartition: "SAMEROOM" });
      this.$emit("input", this.item);
    },
    removeRoom(room, roomIndex) {
      this.item.splice(roomIndex, 1);

      this.$emit("input", this.item);
    },
    totalGuests(room) {
      return this.buildTotalGuestsForRoom(room.guests);
    },
  },
};
</script>
