<template>
  <v-card flat>
    <section class="fees">
      <div
        class="fee"
        v-for="(fee, i) in items"
        :key="i"
        :class="{'font-weight-medium blue--text current': fee.current, 'grey--text text--lighten-1': fee.expired}"
      >
        <div class="fee-date grey--text">{{_parseAndFormatDate(fee.start, 'dd/MM/yyyy', 'dd MMM')}}</div>
        <div class="fee-price">
          <template v-if="editable">
            <v-text-field
              v-model="items[i].price"
              prefix="€"
              hide-details
              single-line
              class="caption text-center mt-0 pa-0"
              style="max-width:50px"
              reverse
              @change="change"
            ></v-text-field>
          </template>
          <template v-else>{{items[i].price | currency("EUR")}}</template>
        </div>
      </div>
    </section>
  </v-card>
</template>
<script>
export default {
  name: "CancelFees",
  props: {
    value: { type: Array, required: true },
    editable: { type: Boolean, default: false },
  },
  data: () => ({}),
  computed: {
    items() {
      const now = new Date();
      return this.value.map((f) => {
        f.expired = this._parseDate(f.stop, "dd/MM/yyyy") < now;
        f.current =
          this._parseDate(f.start, "dd/MM/yyyy") < now &&
          this._parseDate(f.stop, "dd/MM/yyyy") > now;
        return f;
      });
    },
  },
  methods: {
    change() {
      this.$emit("input", this.value);
    },
  },
};
</script>
<style scoped>
.fees {
  display: flex;
  min-width: 100%;
  min-height: 70px;
  overflow-x: auto;
}
.fee {
  min-width: 50px;
  margin: 5px;
}
.fee-date {
  font-size: 10px;
  text-align: center;
}
.fee-price {
  text-align: center;
}
.current .fee-price >>> input {
  color: #1976d2;
}
</style>
