export default {
  data: function() {
    return {
      emailRegex: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      rules: {
        nonEmptyArray: (msg) => [
          (v) =>
            (v !== null &&
              v.length > 0 &&
              v.every((i) => i !== void 0 && i !== null && i.trim() !== "")) ||
            msg ||
            "Champs requis",
        ],
        nonEmpty: (msg) => [
          (v) => (v !== void 0 && v !== null && v !== "") || "Champs requis",
        ],
        maxLength: (maxLength, msg) => [
          (v) => (v.length <= maxLength) || "Limite depassée",
        ],
        number: (msg) => [
          ...this.rules.nonEmpty(msg),
          (v) => !isNaN(v) || "doit être un nombre",
        ],
        abs: (msg) => [
          ...this.rules.number(msg),
          (v) => v > 0 || msg || "doit être > 0",
        ],
        regex: (r, msg) => [(v) => r.test(v) || msg],
        email: (msg) => [
          ...this.rules.regex(this.emailRegex, msg || "Email valide requis"),
        ],
        emailArray: (msg) => [
          (v) =>
            v.every((i) => this.emailRegex.test(i.trim())) ||
            msg ||
            "Email valide requis",
        ],
        time: (msg) => [
          (v) => {
            let valid = true;
            try {
              const [hoursStr, minutesStr] = v.split(":");
              const hours = parseInt(hoursStr);
              const minutes = parseInt(minutesStr);

              valid =
                hoursStr.length == 2 &&
                minutesStr.length == 2 &&
                !Number.isNaN(hours) &&
                !Number.isNaN(minutes) &&
                hours >= 0 &&
                hours <= 23 &&
                minutes >= 0 &&
                minutes <= 59;
            } catch (e) {
              valid = false;
            }
            return valid || msg || "Format hh:mm";
          },
        ],
      },
    };
  },
};
