import _ from "lodash";
export default {
  data: function() {
    return {
      table: {
        rowsPerPageItems: [{ text: "Tous", value: -1 }],
        loading: false,
        headers: [],
        items: [],
        selectedHeaders: [],
        total: null,
      },
    };
  },
  methods: {
    _tableWidth(selectedHeaders) {
      let selectH = selectedHeaders
        ? selectedHeaders
        : this.table.selectedHeaders;

      const width = this.table.headers
        .filter((h) => {
          return selectH.length <= 0 ? true : this.doShowColumn(h.value);
        })
        .map((h) => (this.withLastYear ? h.fullSize : h.smallSize));

      return width.length > 0 ? width.reduce((acc, curr) => acc + curr) : 0;
    },
    _doShowColumn(headerValue, selectedHeaders) {
      let selectH = selectedHeaders
        ? selectedHeaders
        : this.table.selectedHeaders;
      return selectH.length <= 0 ? true : selectH.indexOf(headerValue) > -1;
    },
    _widthFor(key) {
      const widths = this.table.headers
        .filter((h) => h.value == key)
        .map((h) => (this.withLastYear ? h.fullSize : h.smallSize));

      return widths.length > 0 ? widths[0] : 220;
    },
    _widthStyleFor(key) {
      const width = this._widthFor(key);
      return "width:" + width + "px;max-width:" + width + "px;";
    },
    _sort(header) {
      this.table.headers.map((h) => {
        h.sorted = h.value != header.value ? null : h.sorted;
        h.sub =
          h.sub != null
            ? h.sub.map((s) => {
                s.sorted = null;
                return s;
              })
            : null;
        return h;
      });

      const asc = "asc";
      const desc = "desc";

      header.sorted =
        header.sorted == null ? asc : header.sorted == asc ? desc : null;

      if (header.sorted) {
        const isDescending = header.sorted == "desc";
        const items = _.cloneDeep(this.table.items);
        items.sort((a, b) => {
          const aValue =
            typeof a[header.value] == "string"
              ? a[header.value]
              : a[header.value].value;
          const bValue =
            typeof b[header.value] == "string"
              ? b[header.value]
              : b[header.value].value;

          if (typeof aValue == "number" && typeof bValue == "number")
            return isDescending ? bValue - aValue : aValue - bValue;
          else
            return isDescending
              ? bValue.localeCompare(aValue)
              : aValue.localeCompare(bValue);
        });
        this.table.items = items;
      }
    },

    sortSub(header, sub) {
      this.table.headers.map((h) => {
        h.sorted = null;

        h.sub = h.sub
          ? h.sub.map((s) => {
              s.sorted = s.value != sub.value ? null : s.sorted;
              return s;
            })
          : null;
        return h;
      });

      const asc = "asc";
      const desc = "desc";

      sub.sorted = sub.sorted == null ? asc : sub.sorted == asc ? desc : null;

      if (sub.sorted) {
        const isDescending = sub.sorted == "desc";

        const items = _.cloneDeep(this.table.items);
        items.sort((a, b) => {
          const aValue = a[header.value][sub.value];
          const bValue = b[header.value][sub.value];

          return isDescending ? bValue - aValue : aValue - bValue;
        });
        this.table.items = items;
      }
    },
  },
};
