<template>
  <layout :drawerShow="_isDesktop()" :drawerWidth="350">
    <template slot="drawer">
      <v-form ref="searchform" v-if="searchform" @submit.prevent="loadData">
        <v-row dense>
          <v-col cols="12">
            <smart-multi-select
              v-model="searchform.selectedSites"
              label="Sites"
              :items="sites"
              :all-is-same-as-empty="true"
              :default-select-all="true"
            >
            </smart-multi-select>
          </v-col>

          <v-col cols="12">
            <smart-multi-select
              v-model="searchform.selectedCategories"
              label="Catégorie"
              item-value-name="value"
              item-value="value"
              item-text="text"
              :items="categories"
              :all-is-same-as-empty="true"
              :default-select-all="true"
            >
            </smart-multi-select>
          </v-col>
          <v-col cols="12">
            <smart-multi-select
              v-model="searchform.selectedDestinations"
              label="Destination"
              :items="destinations"
              :all-is-same-as-empty="true"
              :default-select-all="true"
            >
            </smart-multi-select>
          </v-col>

          <v-col cols="6">
            <smart-select
              v-model="searchform.selectedChains"
              item-text="name"
              item-value="id"
              label="Chaines"
              :default-select-all="true"
              :all-is-same-as-empty="true"
              :loading="loadingChains"
              :items="chains"
              :chip-style="{ width: '120px' }"
            ></smart-select>
          </v-col>

          <v-col cols="6" class="pl-1 pr-1">
            <smart-select
              v-model="searchform.selectedHotels"
              item-text="name"
              item-value="id"
              label="Hotels"
              :default-select-all="true"
              :all-is-same-as-empty="true"
              :loading="loadingHotels"
              :items="hotels"
              :chip-style="{ width: '120px' }"
            ></smart-select>
          </v-col>
          <v-col cols="12" class="">
            <smart-select
              v-model="searchform.selectedCountries"
              item-text="name"
              item-value-name="value"
              label="Pays d'origine"
              :default-select-all="true"
              :all-is-same-as-empty="true"
              :loading="loadingHotels"
              :items="countries"
              :chip-style="{ width: '120px' }"
            ></smart-select>
          </v-col>

          <v-col cols="9" class="mb-0 pb-0">
            <v-subheader
              light
              class="pa-0 mb-0 mx-0 mt-4 form-subheader solo-subheader"
            >
              {{
                isBookingDate ? "Date de réservation" : "Date de voyage"
              }}</v-subheader
            >
          </v-col>
          <v-col cols="3" class="mb-0 pb-0 text-right">
            <v-switch
              dense
              hide-details
              v-model="isBookingDate"
              label
            ></v-switch>
          </v-col>

          <v-col cols="12" class="mt-0 pt-0">
            <v-divider></v-divider>
          </v-col>

          <v-col class="pr-1" v-show="isBookingDate" cols="6">
            <smart-picker
              v-model="searchform.bookingDateStart"
              :next-picker="$refs.bookingStopDatePicker"
              :stopDate="searchform.bookingDateStop"
              :inputOpts="{ label: $t('begin') }"
            ></smart-picker>
          </v-col>
          <v-col v-show="isBookingDate" cols="6">
            <smart-picker
              ref="bookingStopDatePicker"
              v-model="searchform.bookingDateStop"
              :startDate="searchform.bookingDateStart"
              :inputOpts="{ label: $t('end') }"
            ></smart-picker>
          </v-col>

          <v-col class="pr-1" v-show="!isBookingDate" cols="6">
            <smart-picker
              v-model="searchform.tripDateStart"
              :next-picker="$refs.tripStopDatePicker"
              :stopDate="searchform.tripDateStop"
              :inputOpts="{ label: $t('begin') }"
            ></smart-picker>
          </v-col>
          <v-col v-show="!isBookingDate" cols="6">
            <smart-picker
              ref="tripStopDatePicker"
              v-model="searchform.tripDateStop"
              :startDate="searchform.tripDateStart"
              :inputOpts="{ label: $t('end') }"
            ></smart-picker>
          </v-col>

          <v-col cols="6">
            <v-subheader light class="pa-0 mt-5 mb-0 mx-0 form-subheader"
              >Statut Dossier</v-subheader
            >
            <v-divider></v-divider>
          </v-col>
          <v-col cols="6">
            <v-subheader light class="pa-0 mt-5 mb-0 mx-0 form-subheader"
              >Statut Fournisseur</v-subheader
            >
            <v-divider></v-divider>
          </v-col>
          <v-col cols="6">
            <v-checkbox
              v-for="(bs, index) in bookingStatus"
              :key="'bs-' + index"
              v-model="searchform.bookingStatus"
              :label="bs.label"
              :value="bs.value"
              :class="'pt-1 ' + (index == 0 ? 'mt-2' : 'mt-0')"
              height="1"
            ></v-checkbox>
          </v-col>
          <v-col cols="6">
            <v-checkbox
              v-for="(s, index) in supplierStatus"
              :key="'s-' + index"
              v-model="searchform.supplierStatus"
              :label="s.label"
              :value="s.value"
              :class="'pt-1 ' + (index == 0 ? 'mt-2' : 'mt-0')"
              height="1"
            ></v-checkbox>
          </v-col>

          <v-col cols="12">
            <v-checkbox
              v-model="searchform.withLastYear"
              label="Comparer avec n-1"
            ></v-checkbox>
          </v-col>
        </v-row>

        <smart-btn type="submit" :loading="loading" block primary
          >Rechercher</smart-btn
        >
        <smart-btn
          block
          tertiary
          @click.native="
            clearSearchform();
            loadData();
          "
          >Réinitialiser la recherche</smart-btn
        >
      </v-form>
    </template>
    <template slot="content">
      <v-container fluid>
        <v-row justify="space-between">
          <v-col cols="12" class="mb-3">
            <v-tabs
              v-model="tabs.active"
              background-color="blue-grey darken-1"
              dark
              slider-color="yellow"
            >
              <v-tab
                v-for="(header, i) in tabs.headers"
                :key="i"
                :href="`#tab-${i}`"
                >{{ header }}</v-tab
              >

              <v-tab-item
                eager
                :transition="false"
                :reverse-transition="false"
                value="tab-0"
              >
                <widget-hotel-stat
                  @loading="setLoading"
                  v-if="searchform"
                  :bus="widgetBus"
                  :url="
                    '/api/widget/hotelstats?withLastYear=' +
                    searchform.withLastYear
                  "
                  :with-last-year="searchform.withLastYear || false"
                ></widget-hotel-stat>
              </v-tab-item>
              <v-tab-item
                eager
                :transition="false"
                :reverse-transition="false"
                value="tab-1"
              >
                <widget-nights-per-month
                  v-if="searchform"
                  :url="`/api/widget/hotelnightpermonth?withLastYear=${searchform.withLastYear}&byBookingDate=true`"
                  :bus="widgetBus"
                  :with-last-year="searchform.withLastYear || false"
                ></widget-nights-per-month>
              </v-tab-item>
              <v-tab-item
                eager
                :transition="false"
                :reverse-transition="false"
                value="tab-2"
              >
                <widget-nights-per-month
                  v-if="searchform"
                  :url="`/api/widget/hotelnightpermonth?withLastYear=${searchform.withLastYear}&byBookingDate=false`"
                  :bus="widgetBus"
                  :with-last-year="searchform.withLastYear || false"
                ></widget-nights-per-month>
              </v-tab-item>
            </v-tabs>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </layout>
</template>

<script>
import Layout from "../Layout";
import { EventBus } from "@/components/commons/event-bus";
import Vue from "vue";
import { get, post } from "@/utils/api";
import SmartMultiSelect from "../../components/commons/SmartMultiSelect";
import SmartSelect from "../../components/commons/SmartSelect";
import HotelStatsCell from "./HotelStatsCell";
import WidgetHotelStat from "../../components/commons/widgets/WidgetHotelStat";
import WidgetNightsPerMonth from "../../components/commons/widgets/WidgetNightsPerMonth";

export default {
  name: "HotelStats",
  components: {
    WidgetNightsPerMonth,
    WidgetHotelStat,
    HotelStatsCell,
    Layout,
    SmartMultiSelect,
    SmartSelect,
  },
  mixins: [],
  data() {
    return {
      tabs: {
        headers: [
          "Ventes par hôtel",
          "Nuités par mois par date de réservation",
          "Nuités par mois par date de voyage",
        ],
        active: 0,
      },
      bookingStatus: [
        { value: "quotation", label: "Devis" },
        { value: "booked", label: "Réservation" },
        { value: "cancelled", label: "Annulé" },
      ],
      supplierStatus: [
        { value: "ondemand", label: "En attente" },
        { value: "confirmed", label: "Confirmé" },
        { value: "refused", label: "Refusé" },
      ],
      defaultSearchform: {
        selectedCategories: [],
        selectedSites: [],
        selectedNetworks: [],
        selectedDestinations: [],
        selectedAgencies: [],
        selectedChains: [],
        selectedHotels: [],
        selectedCountries: [],
        client: null,
        ref: null,
        bookingDateStart: this._nowFormatted("dd/MM/yyyy"),
        bookingDateStop: this._nowFormatted("dd/MM/yyyy"),
        tripDateStart: null,
        tripDateStop: null,
        bookingStatus: ["booked"],
        supplierStatus: [],
        paymentStatus: null,
        withLastYear: false,
        tags: [],
      },
      searchform: null,

      isBookingDate: true,

      sites: [],
      chains: [],
      countries: [],
      destinations: [],
      categories: [
        { text: "1*", value: 1 },
        { text: "2*", value: 2 },
        { text: "3*", value: 3 },
        {
          text: "4*",
          value: 4,
        },
        { text: "5*", value: 5 },
      ],

      loadingChains: false,
      loadingHotels: false,
      loadingDestinations: false,

      startDate: this._nowFormatted("dd/MM/yyyy"),
      stopDate: this._nowFormatted("dd/MM/yyyy"),

      loading: false,
      //------
      widgetBus: new Vue(),
    };
  },
  created() {
    const loadChains = get("/api/search/chains").json((chains) => {
      this.chains = chains;
    });

    const loadHotels = get("/api/search/hotels").json((hotels) => {
      this.hotels = hotels;
    });

    const loadSites = get("/api/search/sites").json((sites) => {
      this.sites = sites;
      this.defaultSearchform.selectedSites = this.sites.map((s) => s.id);
    });

    const loadDesti = get("/api/search/destinations").json((destinations) => {
      this.destinations = destinations;
      this.defaultSearchform.selectedDestinations = this.destinations.map(
        (s) => s.id
      );
    });

    const loadCountries = get("/api/search/countries?q=").json((countries) => {
      this.countries = countries.map((c) => {
        return {
          name: c.name,
          value: c.code,
        };
      });
      //this.defaultSearchform.selectedCountries = this.countries.map(c => c.value)
    });

    Promise.all([
      loadSites,
      loadChains,
      loadHotels,
      loadDesti,
      loadCountries,
    ]).then(() => {
      this.defaultSearchform.selectedCategories = this.categories.map(
        (s) => s.value
      );
      this.initSearchform();
      this.loadData();
    });
  },
  watch: {
    "searchform.withLastYear"(newVal) {
      this.$nextTick(() => {
        this.loadData();
      });
    },
    isBookingDate(newVal) {
      this.searchform.bookingDateStart = newVal
        ? this.searchform.bookingDateStart
        : null;
      this.searchform.bookingDateStop = newVal
        ? this.searchform.bookingDateStop
        : null;
      this.searchform.tripDateStart = !newVal
        ? this.searchform.tripDateStart
        : null;
      this.searchform.tripDateStop = !newVal
        ? this.searchform.tripDateStop
        : null;
    },
  },
  computed: {},
  methods: {
    loadData() {
      if (!this.loading) {
        window.history.pushState(
          "",
          "",
          "?search=" + JSON.stringify(this.searchform)
        );
        this.$nextTick(() => {
          this.widgetBus.$emit("search", this.searchform);
        });
      }
    },
    initSearchform() {
      const qs = new URLSearchParams(window.location.search);
      const search = (() => {
        try {
          return JSON.parse(qs.get("search"));
        } catch (e) {
          return null;
        }
      })();

      if (search) {
        this.searchform = search;
      } else this.clearSearchform();
    },
    clearSearchform() {
      this.searchform = { ...this.defaultSearchform };
      // /!\ black magic /!\ do not remove /!\
    },
    setLoading(loading) {
      this.loading = loading;
    },

    //---
  },
};
</script>

<style scoped>
>>> .v-data-table tbody tr:not(:last-child) td:not(.v-data-table__mobile-row) {
  border-bottom: 0;
}
</style>
