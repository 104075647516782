var render = function render(){var _vm=this,_c=_vm._self._c;return _c('layout',{attrs:{"drawer":true,"drawerShow":_vm._isDesktop(),"drawerWidth":300}},[_c('template',{slot:"drawer"},[_c('v-form',{ref:"searchform",on:{"submit":function($event){$event.preventDefault();return _vm.search.apply(null, arguments)}}},[_c('v-subheader',{staticClass:"pa-0 mb-0 mt-4 mx-0 form-subheader",attrs:{"light":""}},[_vm._v("Connexions réalisées entre: ")]),_c('v-divider'),_c('v-row',{attrs:{"justify":"start"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('smart-picker',{attrs:{"next-picker":_vm.$refs.stopDateLog,"format":"dd/MM/yyyy","inputOpts":{ label: _vm.$t('begin') }},model:{value:(_vm.form.startDateLog),callback:function ($$v) {_vm.$set(_vm.form, "startDateLog", $$v)},expression:"form.startDateLog"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('smart-picker',{ref:"stopDateLog",attrs:{"format":"dd/MM/yyyy","inputOpts":{ label: _vm.$t('end') }},model:{value:(_vm.form.stopDateLog),callback:function ($$v) {_vm.$set(_vm.form, "stopDateLog", $$v)},expression:"form.stopDateLog"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pr-1",attrs:{"cols":"12"}},[_c('v-select',{attrs:{"label":"Site","item-value":"value","item-text":"text","items":_vm.countries,"return-object":false},model:{value:(_vm.form.selectedCountry),callback:function ($$v) {_vm.$set(_vm.form, "selectedCountry", $$v)},expression:"form.selectedCountry"}})],1)],1),_c('smart-btn',{staticClass:"mt-2",attrs:{"loading":_vm.listing.loading,"type":"submit","block":"","primary":""},on:{"click":_vm.search}},[_vm._v("Rechercher")])],1)],1),_c('template',{slot:"content"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-data-table',{staticClass:"elevation-1 tbl",attrs:{"headers":_vm.listing.headers,"loading":_vm.listing.loading,"items":_vm.listing.items,"sort-by":'countLog',"sort-desc":true,"options":_vm.listing.pagination,"hide-default-footer":true,"footer-props":{
          'items-per-page-options': _vm.listing.rowsPerPageItems,
          'items-per-page-text': 'Lignes par page',
        },"no-data-text":"Il n'y a pas d'agences à afficher"},on:{"update:options":function($event){return _vm.$set(_vm.listing, "pagination", $event)}},scopedSlots:_vm._u([{key:"item.agencyName",fn:function({ item, value }){return [_c('v-row',{staticClass:"agencyName"},[_c('v-col',{attrs:{"cols":"6"}},[_vm._v(_vm._s(value))]),_c('v-col',{staticClass:"button"},[_c('router-link',{attrs:{"to":{
                  name: 'CrmAgencyDetails',
                  params: { id: item.agencyId },
                },"target":"_blank"}},[_c('v-chip',{attrs:{"x-small":"","color":"green","link":"","outlined":""}},[_vm._v(" Voir ")])],1)],1)],1)]}}])})],1)],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }