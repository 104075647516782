<template>
  <layout :drawer="true" :drawerShow="_isDesktop()">
    <template slot="nav">
      <v-tabs>
        <v-tab>Pledg</v-tab>
      </v-tabs>
    </template>

    <template slot="drawer">
      <v-form ref="searchform" v-if="searchform" @submit.prevent="search">

        <v-row dense class="mb-10">
          <v-col cols="12" class="mb-1">
            <v-subheader light class="pa-0 mb-0 mx-0 form-subheader"
            >Dates de réservation
            </v-subheader
            >
            <v-divider></v-divider>
          </v-col>
          <v-col cols="6" class="">
            <smart-picker
                label="Début"
                v-model="searchform.startDate"
            ></smart-picker>
          </v-col>
          <v-col cols="6" class="">
            <smart-picker
                label="Fin"
                v-model="searchform.stopDate"
            ></smart-picker>
          </v-col>
        </v-row>

        <smart-btn :loading="loading" type="submit" block primary>Rechercher</smart-btn>

        <smart-btn
            block
            tertiary
            @click.native="
            clearSearchform();
            search();
          "
        >Réinitialiser la recherche
        </smart-btn>
      </v-form>
    </template>

    <template slot="content">

      <v-container fluid>
        <v-card style="max-width: 1024px; margin: auto">
          <v-data-table
              :headers="headers"
              :items="items"
              :footer-props="{
                'items-per-page-options': [{ text: 'Tous', value: -1 }],
                'rows-per-page-options': 'Lignes par page',
              }"
              hide-default-footer
          >

            <template v-for="h in headers.filter(h => h.meta.isSite)"
                      v-slot:[`item.${h.value}`]="{item}"

            >
              <div dense :key="h.value" class="flex ">
                  <span class="font-weight-regular">{{ item[h.value].price | currency('eur') }}</span><br/>
                  <span class="caption grey--text text--darken-1">{{ item[h.value].size }} dossiers</span>
              </div>
            </template>

            <template #item.total="{item}">
              <div dense  class="flex ">
                <span class="font-weight-regular">{{ item.total.price | currency('eur') }}</span><br/>
                <span class="caption grey--text text--darken-1">{{ item.total.size }} dossiers</span>
              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-container>
    </template>
  </layout>
</template>
<script>
import Layout from "@/views/Layout";
import {EventBus} from "@/components/commons/event-bus";
import {get} from "@/utils/api";


export default {
  components: {
    Layout,
  },

  data: (self) => ({
    headers: [

      {
        text: "",
        value: "slices",
        align: "right",
        sortable: false,
        meta: {
          isSite: false
        },
        //sort: (a, b) => a.name.localeCompare(b.name)
      },
      {
        text: "Hotelissima",
        value: "Hotelissima",
        align: "center",
        sortable: false,
        meta: {
          isSite: true
        },
      },
      {
        text: "Hotelissima RE",
        value: "HotelissimaRE",
        align: "center",
        sortable: false,
        meta: {
          isSite: true
        },
      },
      {
        text: "Zil Maurice",
        value: "ZilMaurice",
        align: "center",
        sortable: false,
        meta: {
          isSite: true
        },
      },
      {
        text: "Total",
        value: "total",
        align: "center",
        sortable: true,
        meta: {
          isSite: false
        },
      }
    ],
    items: [],
    loading: false,
    rowsPerPageItems: [{text: "Tous", value: -1}, 50, 200],

    searchform: {
      startDate: self._formatDate(self._subDays(new Date(), 120), 'dd/MM/yyyy'),
      stopDate: self._formatDate(new Date(), 'dd/MM/yyyy'),
    },
  }),

  async created() {
    this.search();
  },
  methods: {
    search() {
      if (!this.loading) {
        this.loading = true;
        const start = this._parseAndFormatDate(this.searchform.startDate, 'dd/MM/yyyy', "yyyy-MM-dd")
        const stop = this._parseAndFormatDate(this.searchform.stopDate, 'dd/MM/yyyy', "yyyy-MM-dd")
        const url = `/api/accounting/payments/pledg?startdate=${start}&stopdate=${stop}`;

        get(url)
        .json((data) => {

          const sites = Object.keys(data)


          const items = Array.from(Array(3).keys()).map(slice => {
            return sites.reduce((prev, site) => {

              return {
                ...prev,
                ...{
                  [site]: {
                    price: data[site][0][slice + 4],
                    size: data[site][0][slice + 1]
                  }
                }
              }
            }, {Hotelissima: 0, HotelissimaRE: 0, ZilMaurice: 0})
          }).map((row, index) => {

            let slice = '0 - 2999'
            if (index === 1)
              slice = '3000 - 5999'
            else if (index === 2) {
              slice = '6000 - 10 000'
            }

            const totalSize = Object.values(row).reduce((a, b) => a + b.size, 0)
            const totalPrice = Object.values(row).reduce((a, b) => a + b.price, 0)

            return {
              slices: slice,
              ...row,
              total: {
                price: totalPrice,
                size: totalSize
              }
            }
          })

          const totalSites = this.headers.filter(h => h.meta.isSite).map( h => {
            return {
              [h.value]:{
                price:items.reduce((prev, curr) => prev + curr[h.value].price, 0),
                size:items.reduce((prev, curr) => prev + curr[h.value].size, 0)
              }
            }
          })

          const rowTotal = {
            slices: 'Total',
            total: {
              price:items.reduce((prev, curr) => prev + curr.total.price, 0),
              size:items.reduce((prev, curr) => prev + curr.total.size, 0)
            },
            ...Object.assign({}, ...totalSites)
          }

          this.items = [...items, rowTotal]

        })
        .catch((e) => {
          EventBus.$emit("toast", {
            color: "red",
            text: "Une erreur est survenue",
            exception: e,
          });
        })
        .then(() => {
          this.loading = false;
        });
      }
    },

    clearSearchform() {
      this.searchform = {
        startDate: this._formatDate(new Date(), 'dd/MM/yyyy'),
        stopDate: this._formatDate(new Date(), 'dd/MM/yyyy'),
      };
    },
    sortDates(a, b, pattern) {
      return this._sortDates(a, b, pattern)
    },
  },
  computed: {
    siteids() {
      return this.sites.map((s) => s.id);
    },
  },
};
</script>