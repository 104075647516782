<template>
  <layout drawerShow :drawerWidth="400">
    <template slot="nav">
      <nav-exports />
    </template>

    <template slot="content">
      <v-container>
        <v-card>
          <v-data-table
            :headers="headers"
            :items="items"
            :loading="loading"
            no-data-text="-"
            hide-default-footer
            hide-default-header
            :footer-props="{
              'items-per-page-options': rowsPerPageItems,
              'items-per-page-text': 'Lignes par page',
            }"
            class="tbl"
          >
            <template #header="{props: {headers}}">
              <thead class="v-data-table-header">
                <tr>
                  <th
                    v-for="header in headers"
                    :key="header.value"
                    :class="[
                      'column sortable',
                      pagination.descending ? 'desc' : 'asc',
                      header.value === pagination.sortBy ? 'active' : '',
                      header.value == 'price' ||
                      header.value == 'priceWithSupplierInvoice'
                        ? 'bl'
                        : '',
                      'text-xs-' + (header.align || 'left'),
                    ]"
                  >
                    {{ header.text }}
                  </th>
                </tr>
              </thead>
            </template>

            <template #item.ecriture_vt="{item}"
              ><span :class="{ 'red--text': item.vt != item.ecriture_vt }">{{
                item.ecriture_vt
              }}</span></template
            >
            <template #item.ecriture_ha="{item}">
              <span :class="{ 'red--text': item.ha != item.ecriture_ha }">{{
                item.ecriture_ha
              }}</span>
            </template>
          </v-data-table>
        </v-card>
      </v-container>
    </template>
  </layout>
</template>
<script>
import Layout from "@/views/Layout";
import NavExports from "./NavExports";
import { EventBus } from "@/components/commons/event-bus";
import { get, post } from "@/utils/api";

export default {
  components: {
    Layout,
    NavExports,
  },
  created: function() {
    this.search();
  },
  data: () => ({
    headers: [
      { text: "#", value: "bookingId", width: "55px" },
      { text: "Date résa", value: "bookingDate", width: "55px" },
      { text: "Vente", value: "vt", width: "55px" },
      { text: "Ecriture de vente", value: "ecriture_vt", width: "55px" },
      { text: "Achat", value: "ha", width: "55px" },
      { text: "Ecriture d'achat", value: "ecriture_ha", width: "55px" },
    ],
    items: [],
    loading: false,
    searchCtx: {
      siteId: null,
      destinationId: null,
      bookingDateStart: null,
      bookingDateStop: null,
      stayDateStart: null,
      stayDateStop: null,
    },
    sites: [],
    destinations: [],
    rowsPerPageItems: [{ text: "Tous", value: -1 }, 50, 200],
    pagination: {
      sortBy: "bookingDate",
    },
  }),
  methods: {
    search() {
      this.loading = true;
      post("/api/accounting/export/check")
        .json((data) => {
          this.items = data;
        })
        .catch((e) => {
          EventBus.$emit("toast", {
            color: "red",
            text: "Une erreur est survenue",
            exception: e,
          });
        })
        .then(() => {
          this.loading = false;
        });
    },
  },
};
</script>
