<template>
  <widget-container
    :url="url"
    :bus="bus"
    :dark="false"
    :alwaysrender="true"
    headlineclass
    cardclass="ma-0 pa-1"
    color="transparent"
    @ready="setData"
  >
    <template slot="content" v-if="data && table.headers.length > 0">
      <v-simple-table class="pa-0 ma-0 tbl">
        <template #default>
          <thead>
            <tr>
              <th class="default-cell">
                <div>Desti</div>
              </th>
              <th
                v-for="(header, index) in table.headers"
                :key="index"
                class="default-cell"
              >
                <div class="text-center">{{ header.text }}</div>
              </th>
              <th class="default-cell body-2 font-weight-medium">
                <div class="text-center">Total</div>
              </th>
              <th class="text-center cell-icon">
                <v-icon small @click="copy" class="pl-2"
                  >mdi-clipboard-outline</v-icon
                >
              </th>
            </tr>

            <tr class="subrow">
              <th class="subrow-cell default-cell"></th>
              <th
                v-for="(header, index) in table.headers"
                :key="index"
                class="default-cell"
              >
                <div class="text-center subrow-cell-content sub-cell">Dos.</div>
                <div class="text-right subrow-cell-content sub-cell">CA</div>
                <div class="text-right subrow-cell-content sub-cell">%</div>
              </th>
              <th class="default-cell">
                <div class="text-center subrow-cell-content sub-cell">Dos.</div>
                <div class="text-right subrow-cell-content sub-cell">CA</div>
                <div class="text-right subrow-cell-content sub-cell">%</div>
              </th>
              <th class="cell-icon"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in table.items" :key="index">
              <td class="text-center subrow-cell default-cell">
                {{ item.destiName }}
              </td>
              <td
                class="subrow-cell default-cell"
                v-for="(header, index) in table.headers"
                :key="index"
              >
                <div
                  class="text-center subrow-cell-content sub-cell"
                  :class="
                    getSize(header.value, item.cells) == 0
                      ? 'grey--text lighten-3--text'
                      : ''
                  "
                >
                  {{ getSize(header.value, item.cells) }}
                </div>
                <div
                  class="text-right subrow-cell-content sub-cell"
                  :class="
                    getCa(header.value, item.cells) == 0
                      ? 'grey--text lighten-3--text'
                      : ''
                  "
                >
                  {{
                    getCa(header.value, item.cells)
                      | currency("EUR", { maximumFractionDigits: 0 })
                  }}
                </div>
                <div
                  class="text-right subrow-cell-content sub-cell"
                  :class="
                    getPercentage(header.value, item.cells) == 0
                      ? 'grey--text lighten-3--text'
                      : ''
                  "
                >
                  {{
                    getPercentage(header.value, item.cells)
                      | decimal({ maximumFractionDigits: 0 })
                  }}
                  %
                </div>
              </td>
              <td class="subrow-cell default-cell">
                <div
                  class="text-center subrow-cell-content sub-cell body-2 font-weight-medium"
                >
                  {{ getRowTotalSize(item.cells) }}
                </div>
                <div
                  class="text-right subrow-cell-content sub-cell body-2 font-weight-medium"
                >
                  {{
                    getRowTotalCa(item.cells)
                      | currency("EUR", { maximumFractionDigits: 0 })
                  }}
                </div>
                <div
                  class="text-right subrow-cell-content sub-cell body-2 font-weight-medium"
                >
                  {{
                    computePercentage(getRowTotalCa(item.cells), getTotalCa())
                      | decimal({ maximumFractionDigits: 0 })
                  }}
                  %
                </div>
              </td>
              <td class="cell-icon"></td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td
                class="text-center body-2 font-weight-medium subrow-cell default-cell"
              >
                Total
              </td>
              <td
                class="body-2 font-weight-medium subrow-cell default-cell"
                v-for="(header, index) in table.headers"
                :key="index"
              >
                <div class="text-center subrow-cell-content sub-cell">
                  {{ getSize(header.value, table.footer) }}
                </div>
                <div class="text-right subrow-cell-content sub-cell">
                  {{
                    getCa(header.value, table.footer)
                      | currency("EUR", { maximumFractionDigits: 0 })
                  }}
                </div>
                <div class="text-right subrow-cell-content sub-cell">
                  {{
                    getPercentage(header.value, table.footer)
                      | decimal({ maximumFractionDigits: 0 })
                  }}
                  %
                </div>
              </td>
              <td class="body-2 font-weight-medium subrow-cell default-cell">
                <div
                  class="text-center subrow-cell-content sub-cell body-2 font-weight-medium"
                >
                  {{ getTotalSize() }}
                </div>
                <div
                  class="text-right subrow-cell-content sub-cell body-2 font-weight-medium"
                >
                  {{
                    getTotalCa() | currency("EUR", { maximumFractionDigits: 0 })
                  }}
                </div>
                <div
                  class="text-right subrow-cell-content sub-cell body-2 font-weight-medium"
                >
                  100%
                </div>
              </td>
              <td class="cell-icon"></td>
            </tr>
          </tfoot>
        </template>
      </v-simple-table>
    </template>
  </widget-container>
</template>

<script>
import WidgetContainer from "./WidgetContainer";
import clipboard from "../../../utils/clipboard";
import _ from "lodash";
export default {
  name: "WidgetActivitySiteDesti",
  components: { WidgetContainer },
  mixins: [],
  props: {
    bus: {
      type: Object,
      required: true,
    },
    url: {
      type: String,
      required: false,
      default: "/api/widget/activitysitedesti",
    },
  },
  data() {
    return {
      data: null,
      table: {
        loading: false,
        headers: [],
        items: [],
        footer: [],
      },
    };
  },
  created() {
    this.$nextTick(() => {
      this.bus.$on("search", (searchform) => {
        this.table.loading = true;
        this.$emit("loading", true);
      });
    });
  },
  destroyed() {
    this.bus.$off("search");
  },
  watch: {},
  computed: {},
  methods: {
    setData(data) {
      this.data = data.items;
      this.buildHeaders();
      this.buildRows();
      this.buildFooter();
      this.table.loading = false;
      this.$emit("loading", false);
    },
    buildHeaders() {
      this.table.headers = this.data
        .map((item) => {
          return {
            text: item.siteName,
            value: item.siteId,
            class: "text-center",
            icon: "",
          };
        })
        .reduce((acc, curr) => {
          return acc.map((a) => a.value).indexOf(curr.value) < 0
            ? acc.concat(curr)
            : acc;
        }, [])
        .sort((a, b) => b.siteId - a.siteId);
    },
    buildRows() {
      const grouped = _.groupBy(this.data, "destinationId");
      const items = Object.keys(grouped)
        .map((key) => {
          const destiName = grouped[key][0].destiName;
          return {
            destiName: destiName,
            cells: grouped[key],
          };
        })
        .sort((a, b) => a.destiName.localeCompare(b.destiName));

      this.table.items = items;
    },
    buildFooter() {
      const grouped = _.groupBy(this.data, "siteId");
      const footer = Object.keys(grouped).map((key) => {
        return grouped[key].reduce((acc, curr) => {
          return {
            siteId: curr.siteId,
            bookingSize: curr.bookingSize + (acc ? acc.bookingSize : 0),
            ca: curr.ca + (acc ? acc.ca : 0),
          };
        }, null);
      });

      this.table.footer = footer;
    },
    getCa(siteId, items) {
      return this.getValue(siteId, items, "ca");
    },
    getSize(siteId, items) {
      return this.getValue(siteId, items, "bookingSize");
    },
    getValue(siteId, items, propertyName) {
      const index = items.map((i) => i.siteId).indexOf(siteId);
      const item = items[index];
      if (item && item[propertyName]) return item[propertyName];
      else return 0;
    },
    getPercentage(siteId, items) {
      const ca = this.getCa(siteId, items);
      const total = this.getRowTotalCa(items);

      return this.computePercentage(ca, total);
    },
    getRowTotalSize(items) {
      return this.getRowTotalValue(items, "bookingSize");
    },
    getRowTotalCa(items) {
      return this.getRowTotalValue(items, "ca");
    },
    getRowTotalValue(items, property) {
      let defaultObj = {};
      defaultObj[property] = 0;
      return items.reduce((acc, curr) => {
        acc[property] = acc[property] + curr[property];
        return acc;
      }, defaultObj)[property];
    },
    getColTotalValue(property) {
      return this.table.items.reduce((prev, acc) => {
        return prev + acc.cells.reduce((a, b) => a + b[property], 0);
      }, 0);
    },
    getTotalSize() {
      return this.getTotalValue("bookingSize");
    },
    getTotalCa() {
      return this.getTotalValue("ca");
    },
    getTotalValue(property) {
      return this.table.items
        .map((i) => i.cells.map((c) => c[property]))
        .reduce((acc, curr) => acc.concat(curr), [])
        .reduce((a, b) => a + b);
    },
    computePercentage(ca, total) {
      return (ca * 100) / (total || 1);
    },
    copy() {
      const defaultSeparator = "\t";
      const decimalOpts = { maximumFractionDigits: 0, useGrouping: false };

      const header = this.table.headers
        .map((header) => {
          return header.text;
        })
        .concat("Total")
        .join(defaultSeparator + defaultSeparator + defaultSeparator);

      const subHeader = [...Array(this.table.headers.length + 1).keys()]
        .map(() => {
          return `Dossiers${defaultSeparator}CA${defaultSeparator}%`;
        })

        .join(defaultSeparator);

      const itemRows = this.table.items.map((item) => {
        return [
          item.cells[0].destiName,
          ...this.table.headers.map((header) => {
            return `${this.getSize(
              header.value,
              item.cells
            )}${defaultSeparator}${this.$options.filters.decimal(
              this.getCa(header.value, item.cells),
              decimalOpts
            )}${defaultSeparator}${this.$options.filters.decimal(
              this.getPercentage(header.value, item.cells),
              decimalOpts
            )}`;
          }),
          this.getRowTotalSize(item.cells),
          this.getRowTotalCa(item.cells),
          this.$options.filters.decimal(
            this.computePercentage(
              this.getRowTotalCa(item.cells),
              this.getTotalCa()
            ),
            decimalOpts
          ),
        ].join(defaultSeparator);
      });

      const rowTotal = this.table.headers
        .map((header) => {
          return `${this.getSize(
            header.value,
            this.table.footer
          )}${defaultSeparator}${this.$options.filters.decimal(
            this.getCa(header.value, this.table.footer),
            decimalOpts
          )}${defaultSeparator}${this.$options.filters.decimal(
            this.getPercentage(header.value, this.table.footer),
            decimalOpts
          )}`;
        })
        .concat([this.getTotalSize(), this.getTotalCa(), 100])
        .join(defaultSeparator);

      const rows = _.flatten([
        `Destination${defaultSeparator}${header}`,
        `${defaultSeparator}${subHeader}`,
        itemRows,
        `Total${defaultSeparator}${rowTotal}`,
      ]);

      clipboard.copyTextToClipboard(rows.join("\n"));
    },
  },
};
</script>

<style scoped>
.subrow {
  height: 25px;
}

.subrow-cell {
  padding: 0 !important;
  margin: 0;
}

.subrow-cell-content {
  width: 32%;
  float: left;
}

.default-cell {
  width: 200px;
  min-width: 200px;
  border-left: 1px solid #d1d1d1;
  padding-right: 10px !important;
}

.cell-icon {
  width: 20px;
  border-left: 1px solid #d1d1d1;
}

>>> .v-table__overflow {
  width: auto;
  min-width: 0;
  display: table;
  margin: auto;
}
</style>
