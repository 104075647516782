<template>
  <v-container class="pb-0">
    <v-form
        v-model="valid"
        ref="mergeForm"
        @submit.prevent="save"
        autocomplete="off"
    >
      <v-row class="mx-8">
        <v-checkbox v-model="renew" label="Renouveller le mot de passe"/>
      </v-row>
      <v-row class="d-flex justify-space-between">

        <div class="d-flex justify-space-around" v-for="(agent,i) in agents" :key="agent.id">
          <v-col class="lighten-3 grey mx-3" v-if="!load">
          <v-row class="justify-center">
            <v-col cols="12" class="d-flex justify-space-between">
              <span>Agent à {{i == 1 ? 'conserver' : 'remplacer'}}</span>

            </v-col>

          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                  v-model="agent.id"
                  label="Identifiant"
                  dense
                  readonly
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                  v-model="agent.firstname"
                  label="Prénom"
                  dense
                  append-outer-icon="mdi-arrow-right-circle-outline"
                  @click:append-outer="result.firstname = agent.firstname"
                  readonly
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                  v-model="agent.lastname"
                  label="Nom"
                  dense
                  append-outer-icon="mdi-arrow-right-circle-outline"
                  @click:append-outer="result.lastname = agent.lastname"
                  readonly
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                  v-model="agent.login"
                  label="login"
                  dense
                  append-outer-icon="mdi-arrow-right-circle-outline"
                  @click:append-outer="result.login = agent.login"
                  readonly
              ></v-text-field>
            </v-col>
          </v-row>
            <v-row>
              <v-col>
                <v-text-field
                    v-model="agent.email"
                    label="email"
                    dense
                    append-outer-icon="mdi-arrow-right-circle-outline"
                    @click:append-outer="result.email = agent.email"
                    readonly
                ></v-text-field>
              </v-col>
            </v-row>
          <v-row>
            <v-col>
              <v-text-field
                  v-model="agent.phone"
                  label="Téléphone"
                  dense
                  append-outer-icon="mdi-arrow-right-circle-outline"
                  @click:append-outer="result.phone = agent.phone"
                  readonly
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
          <smart-btn v-if="i != 1" @click.native="switchAgents()" secondary class=""><v-icon>mdi-swap-horizontal</v-icon></smart-btn>
        </div>
        <v-divider vertical></v-divider>
        <!-- Result -->
        <v-col cols="" class="good mx-4">
          <v-row class="justify-center">
            <v-col> Résultat</v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                  v-model="result.id"
                  label="Identifiant"
                  dense
                  readonly
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                  v-model="result.firstname"
                  label="Prénom"
                  dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                  v-model="result.lastname"
                  label="Nom"
                  dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                  v-model="result.login"
                  label="login"
                  dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                  v-model="result.email"
                  label="email"
                  dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                  v-model="result.phone"
                  label="Téléphone"
                  dense
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="justify-end mt-4 grey lighten-4">
        <v-col cols="2">
          <smart-btn type="submit" class="mb-3" primary>Fusionner</smart-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>
<script>
import {post} from "@/utils/api";
import {EventBus} from "@/components/commons/event-bus";

export default {
  name: "AgentMergeTool",
  props: {
    selectedAgents: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      agents:[],
      keep: {left: false, right: true},
      load: false,
      renew: false,
      valid: true,
      result: null,
    };
  },

  created() {
    this.agents = JSON.parse(JSON.stringify(this.selectedAgents.sort((a, b) => {
      return a.id - b.id;
    })));

    this.result = {
      ...this.agents[1],
      oldId: this.agents[0].id,
      firstname: this.agents[1].firstname ? this.agents[1].firstname : this.agents[0].firstname,
      lastname: this.agents[1].lastname ? this.agents[1].lastname : this.agents[0].lastname,
      login: this.agents[1].login ? this.agents[1].login : this.agents[0].login,
      phone: this.agents[1].phone ? this.agents[1].phone : this.agents[0].phone,
    };
  },
  methods: {
    switchAgents(){
      const copyA = this.agents[0]
      const copyB = this.agents[1]
      this.agents=null
      this.agents = [copyB,copyA]

      this.result = this.agents[1]
    },
    save() {
      const url = `/api/agency/agent/merge?renew=${this.renew}`;

      post(url, this.result)
          .badRequest((br) => {
            console.log("Error: ", br);
          })
          .json((data) => {

            this.$emit("success", {});
            EventBus.$emit("toaster-msg", "Fusion effectuée avec succès");
          });
    },
  },
};
</script>
<style lang="less" scoped>
table {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
  border: 0;
}

th {
  vertical-align: top;
  font-weight: normal;
}

td,
th {
  padding: 10px 20px;
}

.bad {
  background-color: #ffebee;
}

.good {
  background-color: #e8f5e9;
}
</style>
