<template>
	<div>

		<v-expansion-panels
			popout
			multiple
			v-model="panel"
		>
			<v-expansion-panel
				ma-2
				dense
				hide-actions
				v-for="(fly, i) in sortedFlies"
				:key="i"
			>
				<v-expansion-panel-header hide-actions class="pa-0 px-2">
					<v-row dense align="center" justify="space-around">
						<v-col cols="1">
							<img class="logos-square" :src="'/airlines/square/'+fly.prices.details[0].validatingAirline+'.png'">
						</v-col>
						<v-col cols="2">
							<div class="font-weight-medium">
								<v-tooltip bottom>
									<template #activator="{on}">
										<span
											v-on="on">{{_parseAndFormatLocalDateTime(fly.departureDate, datePatternConfig.fr.time)}}</span>
									</template>
									<span>{{_parseAndFormatLocalDateTime(fly.departureDate)}}</span>
								</v-tooltip>
								-
								<v-tooltip bottom>
									<template #activator="{on}">
										<span v-on="on">
												{{_parseAndFormatLocalDateTime(fly.arrivalDate, datePatternConfig.fr.time)}}
											<sup v-html="renderSupDays(fly)"></sup>
										</span>
									</template>
									<span>{{_parseAndFormatLocalDateTime(fly.arrivalDate)}}</span>
								</v-tooltip>
							</div>
							<div class="caption font-weight-medium grey--text text--lighten-1">
								{{fly.segments[0].marketingAirline.name}}
							</div>
						</v-col>
						<v-col cols="2">
							{{$t('duration')}}: <span class="font-weight-medium">
								{{_durationFormatted(
									_parseDate(fly.departureDate, datePatternConfig.serverLocalDateTime),
									 _parseDate(fly.arrivalDate, datePatternConfig.serverLocalDateTime), 
									 _datePattern.time)
									 }}</span>
							<div class="caption font-weight-medium grey--text text--lighten-1">{{fly.from.code}} -
								{{fly.to.code}}
							</div>
						</v-col>
						<v-col cols="2">
							<div v-if="fly.segments.length==1">Sans escales <!--{{fly.hash}}--></div>
							<div v-else>
								<div>
									{{$tc('flystops', (fly.segments.length - 1), {size:(fly.segments.length - 1)})}}
								</div>
								<div class="caption font-weight-medium grey--text text--lighten-1">
									{{flyStopsCodes(fly.segments)}}
								</div>
							</div>
						</v-col>
						<v-col cols="3">

							<div class="font-weight-medium text-center subheading" :class="priceColor(fly.pricing.price)">
								<price-diff v-if="selectedfly"
														:new-price="fly.pricing.price"
														:base-price="selectedfly.pricing.price"></price-diff>
								<span v-else>
								{{fly.pricing.price}}
								{{$t('EUR-symbol')}}
							</span>
							</div>
						</v-col>
						<v-col cols="2" v-if="isForSelection">
							<smart-btn secondary @click.native.stop="chose(fly)">
								{{$t('add')}}
							</smart-btn>
						</v-col>
					</v-row>
				</v-expansion-panel-header>
				<v-expansion-panel-content>
					<v-card flat>
						<v-card-text class="pa-0 ma-0">
							<flight-segment :fly="fly"></flight-segment>
						</v-card-text>
					</v-card>
				</v-expansion-panel-content>

			</v-expansion-panel>
		</v-expansion-panels>
	</div>
</template>

<script>
	import PriceDiff from "@/components/commons/PriceDiff";
	import FlightSegment from "@/components/booking/flight/FlightSegment";
	import FlightsMixin from "@/components/mixins/FlightsMixin";
	import _ from 'lodash'

	export default {
		name: "FlightPriceDetails",
		components: {FlightSegment, PriceDiff},
		mixins: [FlightsMixin],
		props: {
			isForSelection: {
				type: Boolean,
				required: false,
				default: true
			},
			flies: {
				type: Array,
				required: true
			},
			selectedfly: {
				type: Object,
				default: null
			},
			sort: {
				type: String,
				default: "price"
			},
			filter: {
				type: Object
			}
		},
		data: function () {
			return {
				panel: !this.isForSelection?[0]:[],
				filteredFlies: this.flies,
				sortedFlies: this.flies
			}
		},
		created() {
		},
		watch: {
			sort(newVal) {
				this.loadSortedFlies(newVal)
			},
			filter: {
				handler() {
					this.runFilter()
				},
				deep: true
			},
			flies: {
				handler() {
					//close all opened panels
					this.panel = []
					this.runFilter()
				},
				deep: true
			}
		},
		computed: {

			allPrices() {
				return this.flies.map(f => f.pricing.price)
			},
			maxBestsPrice() {
				return this.allPrices[Math.round(this.allPrices.length / 3)]
			},
			minWorstsPrice() {
				return this.allPrices.slice(this.allPrices.length - Math.round(this.allPrices.length / 3))[0]
			},
			sortPrice() {
				return [...this.filteredFlies].sort((f1, f2) => {
					return f1.pricing.price - f2.pricing.price
				})
			},
			sortArrival() {
				return [...this.filteredFlies].sort((f1, f2) => {
					return this._parseDate(f1.arrivalDate, "dd/MM/yyyy hh:mm:ss").getTime() - this._parseDate(f2.arrivalDate, "dd/MM/yyyy hh:mm:ss").getTime()
				})
			},
			sortDeparture() {
				return [...this.filteredFlies].sort((f1, f2) => {
					return this._parseDate(f1.arrivalDate, "dd/MM/yyyy hh:mm:ss").getTime() - this._parseDate(f2.arrivalDate, "dd/MM/yyyy hh:mm:ss").getTime()
				})

			},
			sortDuration() {
				return [...this.filteredFlies].sort((f1, f2) => {

					return this._duration(
						f2.departureDate, f2.arrivalDate, "milliseconds"
					) - this._duration(
						f1.departureDate, f1.arrivalDate, "milliseconds"
					)
				})
			}
		},
		methods: {

			runFilter() {

				this.filteredFlies = _.intersection(
					this.filterByStops(),
					this.filterByMarketingAirline(),
					this.filterByValidatingAirline(),
					this.filterByHour('departureDate', 'hours'),
					this.filterByHour('arrivalDate', 'hoursArrival')
				);
				this.loadSortedFlies(this.sort)
			},

			filterByStops() {
				if (this.filter.stops.code == null || this.filter.stops.code == "all") {
					return this.flies
				} else {
					return this.flies.reduce((acc, trip) => {
						const segmentSize = (trip.segments.length - 1)
						if (segmentSize == this.filter.stops.code) {
							acc.push(trip)
						}

						return acc
					}, [])
				}
			},

			filterByMarketingAirline() {
				if (this.filter.selectedMarketingAirline.code == null || this.filter.selectedMarketingAirline.code == "all") {
					return this.flies
				} else {
					return this.flies.reduce((acc, trip) => {

						const exists = trip.segments.map(segment => {

							return segment.marketingAirline.code == this.filter.selectedMarketingAirline.code
						})

						const exist = this.flatten(exists).some(x => x)
						if (exist) {
							acc.push(trip)
						}

						return acc
					}, []);
				}
			},

			filterByValidatingAirline() {
				if (this.filter.selectedValidatingAirline.code == null || this.filter.selectedValidatingAirline.code == "all") {
					return this.flies
				} else {
					return this.flies.reduce((acc, trip) => {
						const exists = trip.prices.details.map(detail => {
							return detail.validatingAirline == this.filter.selectedValidatingAirline.code
						})

						const exist = this.flatten(exists).some(x => x)
						if (exist) {
							acc.push(trip)
						}

						return acc
					}, []);
				}
			},

			filterByHour(key, hourKey) {
				return this.flies.reduce((acc, flyTrip) => {
					let departure = flyTrip[key].substr(0, 10)
					const minSelected = this._parseDate(departure + " " + this.filter[hourKey][0] + ':00:00', "dd/MM/yyyy HH:mm:ss")
					const maxSelected = this._parseDate(departure + " " + this.filter[hourKey][1] + (this.filter[hourKey][1] == 23 ? ':59:00' : ':00:00'), "dd/MM/yyyy HH:mm:ss")
					departure = this._parseDate(departure, "dd/MM/yyyy")

					if (this._isBetween(minSelected, maxSelected, departure) == true) {
						acc.push(flyTrip)
					}
					return acc
				}, [])
			},

			flatten: function (list) {
				return list.reduce(
					(a, b) => a.concat(Array.isArray(b) ? this.flatten(b) : b), []
				);
			},

			loadSortedFlies(sort) {
				switch (sort) {
					case 'price':
						this.sortedFlies = this.sortPrice
						break;
					case 'arrival-time':
						this.sortedFlies = this.sortArrival
						break;
					case 'departure-time':
						this.sortedFlies = this.sortDeparture
						break;
					case 'duration':
						this.sortedFlies = this.sortDuration
						break;
				}
			},

			chose(fly) {
				this.$emit('flychosen', fly)
			},

			priceColor(p) {
				if (p <= this.maxBestsPrice)
					return "green--text text--darken-1"
				else if (p < this.minWorstsPrice) {
					return "amber--text text--accent-4"
				}
			}

		}
	}
</script>

<style>
	.v-expansion-panel__header {
		height: 60px;
	}

	.logos-square {
		height: 60px;
	}

	.v-label {
		font-size: 14px;
	}

	.v-card {
		font-size: 14px !important;
		font-weight: normal !important;
	}

	>>> .v-card__text{
		color:#000000de!important;
	}

</style>
