<template>
  <v-row dense>
    <v-col cols="12" v-for="(groupedItems, groupedItemsIndex) in group()" :key="groupedItemsIndex">
      <v-row dense column class="mr-2">
        <v-col cols="3" v-for="(item, itemIndex) in groupedItems" :key="itemIndex">
          <slot v-bind:item="item"></slot>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import FileItem from "./FileItem";
import _ from "lodash";

export default {
  name: "FileItems",
  components: { FileItem },
  props: {
    items: {
      type: Array,
      required: true,
    },
    groupSize: {
      type: Number,
      required: false,
      default: 4,
    },
  },
  mixins: [],
  data() {
    return {};
  },
  created() {},
  watch: {},
  computed: {},
  methods: {
    group() {
      return _.chunk(this.items, this.groupSize);
    },
  },
};
</script>

<style scoped>
</style>
