<template>
  <prestation
    kind="prestationgroup"
    ref="prestationcpnt"
    :booking="booking"
    :prestation="prestation"
    :index="index"
    :loading="disablePanel"
    :renderfab="renderfab"
  >
    <div slot="header-sup">{{ prestation.start }} - {{ prestation.stop }}</div>

    <div slot="header-title">
      <div class="subtitle-1 font-weight-medium">
        {{ prestation.name }}
        <span
          v-if="prestation.contractId"
        >- contrat: {{ prestation.contractId }}</span>
      </div>
      <div class="subheading">
        <small>N° devis April: {{ prestation.quotationId }} -</small>
        <availability v-model="prestation.availability" kind="Stock" :chip="false" class="caption" />
        <small v-if="prestation.insuranceId">| Assu. #{{ prestation.insuranceId }}</small>
        <small v-if="prestation.formulaId">| Form. #{{ prestation.formulaId }}</small>
        <small v-if="prestation.optionId">| Opt. #{{ prestation.optionId }}</small>
      </div>
      <div class="small" v-if="prestation.options && prestation.options.length > 0">
        Options:
        <span v-for="(opts, index) in prestation.options" :key="index">
          {{ opts.name }}
          <span v-if="opts.payOnSite">A payer sur place</span>
          <span>{{ opts.price }} {{ $t(prestation.currency + "-symbol") }}</span>
          |
        </span>
      </div>
    </div>

    <div slot="header-status">
      <availability v-model="prestation.status" kind="BookingStatus" />
      <availability v-model="prestation.supplierStatus" kind="SupplierStatus" />
    </div>

    <div slot="header-price">
      <div
        class="headline font-weight-regular"
      >{{ prestation.price }} {{ $t(prestation.currency + "-symbol") }}</div>
    </div>

    <div slot="content">
      <div v-if="warnPrice && newPrice">
        <v-icon color="warning">warning</v-icon>Attention, le montant du dossier
        a évolué, le prix de l'assurance n'est plus cohérent
        <br />
        <v-icon color="warning">warning</v-icon>
        Le nouveau prix calculé est de
        {{ newPrice.price | currency(newPrice.currency.code) }}. Veuillez
        modifier l'assurance.
      </div>

      <v-row v-if="prestation.oldPrestationId" class="align-center">
        <v-col cols="8" class="pa-1 elevation-1 grey-lighten-5" style="background: #fafafa">
          <v-icon size="14">mdi-timer-sand</v-icon>
          Remplace la prestation
          {{ prestation.oldPrestation.name }}
          <availability v-model="prestation.oldPrestation.status" kind="BookingStatus" />
          <availability v-model="prestation.oldPrestation.supplierStatus" kind="SupplierStatus" />

          <b>
            {{
              prestation.oldPrestation.price
                | currency(prestation.oldPrestation.currency)
            }}
          </b>

          <v-icon size="14" @click="oldPrestation = !oldPrestation">mdi-eye</v-icon>
        </v-col>
      </v-row>

      <smart-dialog v-model="editDialog" :fullscreen="false" persistent :title="$t('insurance')">
        <template>
          <add-insurance :booking="booking" :sumPrestation="true" :insurance-selected="prestation"></add-insurance>
        </template>
      </smart-dialog>

      <smart-dialog v-model="modifyDatesDialog.show" max-width="650" title="Modifier Dates">
        <v-row class="pa-4" v-if="modifyDatesDialog.show">
          <v-col cols="12">
            <v-icon color="warning">warning</v-icon>Attention il est obligatoire d'avoir validé le changement de dates
            d'assurance auprès du fournisseur
          </v-col>
          <v-col cols="12">
            <v-row>
              <v-col cols="4" class="px-3" align-self="center">
                <smart-picker
                  v-model="modifyDatesDialog.start"
                  :startDate="
                    _parseAndFormatLocalDateTime(prestation.start, 'dd/MM/yyyy')
                  "
                  :inputOpts="{ label: $t('begin') }"
                ></smart-picker>
              </v-col>
              <v-col cols="4" class="px-3" align-self="center">
                <smart-picker
                  v-model="modifyDatesDialog.stop"
                  :startDate="
                    _parseAndFormatLocalDateTime(prestation.stop, 'dd/MM/yyyy')
                  "
                  :inputOpts="{ label: $t('end') }"
                ></smart-picker>
              </v-col>
              <v-col cols="4" class="px-3" align-self="center">
                <v-btn @click="updateDates" elevation="2" plain raised>Enregistrer</v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </smart-dialog>
    </div>

    <div slot="menu-top">
      <!--
				modify insurance in with booked booking isn't allowed,
				modifierVoucher endpoint returns notSellingProduct => Le client n’est pas autorisé à vendre ce produit
      -->

      <v-list-item @click="editDialog = !editDialog" :disabled="this.booking.status == 'booked'">
        <v-list-item-title>
          <v-icon small>mdi-swap-horizontal</v-icon>
          {{ $t("modify-insurance") }}
        </v-list-item-title>
      </v-list-item>

      <v-list-item @click="
        modifyDatesDialog.show = true;
      isLoading = true;
      ">
        <v-list-item-title>
          <v-icon small>mdi-clock-outline</v-icon>Modifier dates
        </v-list-item-title>
      </v-list-item>
    </div>
  </prestation>
</template>

<script>
import { get, post } from "@/utils/api";
import GuestList from "@/components/commons/GuestList";
import CollapsePanel from "@/components/commons/CollapsePanel";
import BookingPriceComputing from "@/components/commons/BookingPriceComputing";
import BookingPrestaInvoices from "@/components/commons/BookingPrestaInvoices";
import BookingPrestaVoucher from "@/components/commons/BookingPrestaVoucher";
import PrestationMixin from "@/components/mixins/PrestationMixin";
import GuestsMixin from "@/components/mixins/GuestsMixin";
import Availability from "@/components/commons/Availability";
import AddCarrental from "@/components/booking/carrental/AddCarrental";
import { EventBus } from "@/components/commons/event-bus";
import BookingPrestaCancelfees from "../commons/BookingPrestaCancelfees";
import Prestation from "@/components/booking/Prestation";
import BookingDrawerContent from "../commons/BookingDrawerContent";
import MailComposer from "../commons/MailComposer";
import AddInsurance from "./insurance/AddInsurance";

export default {
  name: "PrestationInsurance",
  props: ["index", "renderfab", "prestation"],
  mixins: [PrestationMixin, GuestsMixin],
  components: {
    AddInsurance,
    MailComposer,
    BookingDrawerContent,
    BookingPrestaCancelfees,
    AddCarrental,
    CollapsePanel,
    Availability,
    BookingPrestaVoucher,
    BookingPrestaInvoices,
    BookingPriceComputing,
    GuestList,
    Prestation,
  },
  data() {
    return {
      guests: this.prestation.guests,
      editDialog: false,
      warnPrice: false,
      newPrice: null,
      showOldPrestation: false,
      modifyDatesDialog: {
        show: false,
        start: null,
        stop: null,
      },
    };
  },
  created() {
    this.checkPrice();

    /*this.modifyDatesDialog = {
      ...this.modifyDatesDialog,
      ...{
        start: this._parseAndFormatLocalDateTime(
          this.prestation.start,
          this.datePatternConfig.fr.localDate
        ),
        stop: this._parseAndFormatLocalDateTime(
          this.prestation.stop,
          this.datePatternConfig.fr.localDate
        ),
      },
    };
*/
    EventBus.$on("reloadbooking", (msg) => {
      this.$nextTick(() => {
        this.checkPrice();
      });
    });
  },
  beforeDestroy() {
    //EventBus.$off('reloadbooking');
  },
  watch: {},
  computed: {},
  methods: {
    checkPrice() {
      if ((this.prestation.insuranceId == 7992
        && this.prestation.formulaId == 33980
        && this.prestation.price == 0)
        || this.prestation.status == "cancelled"
        || this.prestation.status == "booked"
        ) return;

      const url = `/api/prestation/verify/insurance?prestationId=${this.prestation.id}`;

      return get(url)
        .json((data) => {
          if (this.booking.status == "quotation") {
            this.newPrice = data.pricing.prices;
            this.warnPrice = data.pricing.prices.price != this.prestation.price;
          }
        })
        .then(() => {
          this.loading = false;
        });
    },
    updateDates() {
      const url = `/api/prestation/update-dates?prestationId=${this.prestation.id}`

      this.loading = true;

      return post(url, this.modifyDatesDialog)
        .res(() => {
          this.loading = false;
          this.modifyDatesDialog.show = false;
          EventBus.$emit("reloadbooking");
        })

    }
  },
};
</script>

<style scoped>
</style>
