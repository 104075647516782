<template>
  <v-card class="px-2">
    <v-row justify="center" class="px-2 pt-2 pb-3">
      <v-card xs12 style="background-color:#89a" width="100%" class="align-center">
        <v-col class="align-center">
          <v-row justify="space-around" class="align-center" style="height: 70px !important">
            <v-col class="xs4 text-center headline white--text">
              <div>{{usedKagnots ? usedKagnots.amount : 0}} € dépensés</div>
              <div class="subheading">{{usedKagnots ? usedKagnots.size : 0}} Kagnots dépensées</div>
            </v-col>
            <v-col class="xs4 text-center headline white--text">
              <div>{{availableKagnots ? availableKagnots.amount : 0}} € à dépenser</div>
              <div
                class="subheading"
              >{{availableKagnots ? availableKagnots.size : 0}} Kagnots à dépenser</div>
            </v-col>
            <v-col class="xs4 text-center align-center headline white--text">
              <div>{{lostKagnots ? lostKagnots.amount : 0}} € perdus</div>
              <div class="subheading">{{lostKagnots ? lostKagnots.size : 0}} Kagnots perdues</div>
            </v-col>
          </v-row>
        </v-col>
      </v-card>
    </v-row>
    <v-data-table
      class="mt-3 mx-2 elevation-1"
      :loading="kagnotListing.loading"
      :headers="kagnotListing.headers"
      :items="kagnotListing.items"
      :footer-props="{'items-per-page-options':kagnotListing.rowsPerPageItems, 'rows-per-page-options':'Lignes par page'}"
      item-key="ref"
      no-data-text="Il n'y a pas d'actions disponibles"
    >
      <template #body="{items}">
        <tbody>
          <tr v-for="(item, idx) in items" :key="idx">
            <td class="text-left">{{item.id}}</td>
            <td class="text-center">{{item.originBookingId}}</td>
            <td class="text-center">{{item.bookingId ? item.bookingId : "Non rattachée"}}</td>
            <td class="text-center">
              <v-chip
                small
                outlined
                :color="kagnotStatusType(item.status).color"
              >{{kagnotStatusType(item.status).text}}</v-chip>
            </td>
            <td class="text-center">
              <v-chip small label outlined>{{item.kagnotType}}</v-chip>
            </td>
            <td class="text-left">{{item.amount}} €</td>
            <td class="text-left">{{item.creationDate}}</td>
            <td class="text-left">{{item.expireDate}}</td>
            <td class="text-left">{{item.cancelDate ? item.cancelDate : "non annulée"}}</td>
            <td class="text-left">{{item.useDate ? item.useDate : "non utilisée"}}</td>
            <td>
              <v-btn icon small @click="openEditDialog(item)">
                <v-icon small>mdi-pencil</v-icon>
              </v-btn>
              <v-btn icon small :disabled="item.status != 'available'">
                <v-icon small @click="openRefundDialog(item)">mdi-cash-refund</v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>

    <smart-dialog title="Remboursement de la kagnot" v-model="kagnotRefundDialog">
      <div class="pa-5">Etes-vous sur de vouloir rembourser la kagnot au client ?</div>
      <template #actions>
        <smart-btn primary @click.native="refundKagnot">Rembouser la kagnot</smart-btn>
      </template>
    </smart-dialog>
    <smart-dialog
      :title="kagnotDialog.type == 'edit' ? 'Éditer la kagnot' : 'Créer une kagnot'"
      v-model="kagnotDialog.open"
      :width="900"
      @close="closeDial()"
    >
      <v-card>
        <v-form ref="form" v-model="valid" @submit.prevent="saveKagnot">
          <v-row dense justify="space-around">
            <v-col cols="3">
              <v-select
                readonly
                disabled
                v-model="form.status"
                label="Statut"
                :items="kagnotStatus"
                item-value="value"
                item-text="label"
                :rules="rules.nonEmpty()"
                @keypress.enter="saveKagnot"
                required
              ></v-select>
            </v-col>

            <v-col cols="3">
              <v-text-field
                readonly
                disabled
                append-icon="mdi-euro"
                label="Montant"
                v-model="form.amount"
                :rules="rules.abs()"
                @keypress.enter="saveKagnot"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="3" v-if="kagnotDialog.type == 'create'">
              <v-text-field
                readonly
                label="Identifiant dossier d'origine"
                v-model="form.originBookingId"
                :rules="rules.number()"
                @keypress.enter="saveKagnot"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="3" v-else>
              <smart-picker
                v-model="form.expireDate"
                label="Date d'expiration"
                :inputOpts="{rules:rules.nonEmpty('La date d\'expiration obligatoire'), readonly:true}"
                @keypress.enter="saveKagnot"
                required
              ></smart-picker>
            </v-col>
          </v-row>

          <v-card-actions class="actions-btn mt-2">
            <v-spacer></v-spacer>
            <v-btn type="submit" color="primary" >Enregistrer</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </smart-dialog>
  </v-card>
</template>

<script>
import { get, post } from "@/utils/api";
import FormMixin from "@/components/mixins/FormMixin";
import { EventBus } from "@/components/commons/event-bus";

export default {
  name: "KagnotList",
  mixins: [FormMixin],
  props: {
    customerId: {
      required: true,
      type: String
    },
    create: {
      required: true,
      type: Boolean
    }
  },
  data() {
    return {
      kagnotListing: {
        loading: false,
        rowsPerPageItems: [200, 500, { text: "Tous", value: -1 }],
        pagination: {
          rowsPerPage: 200
        },
        headers: [
          { text: "id", value: "id", align: "left" },
          { text: "Dos. Origine", value: "originBookingId", align: "center" },
          { text: "Dos.", value: "booking", align: "center" },
          { text: "Statut", value: "status", align: "center" },
          { text: "Type", value: "kagnotType", align: "center" },
          { text: "Montant", value: "amount", align: "left" },
          { text: "Date de création", value: "creationDate", align: "left" },
          { text: "Date d'expiration", value: "expireDate", align: "left" },
          {
            text: "Date d'annulation",
            value: "cancelDate",
            align: "left",
            sortable: false
          },
          {
            text: "Date d'utilisation",
            value: "useDate",
            align: "left",
            sortable: false
          },
          { text: "", value: "", sortable: false }
        ],
        items: []
      },
      valid: true,
      usedKagnots: null,
      lostKagnots: null,
      availableKagnots: null,
      kagnotDialog: {
        open: false,
        type: ""
      },
      kagnotRefundDialog: false,
      form: {
        customerId: this.customerId,
        id: null,
        originBookingId: null,
        bookingId: null,
        status: null,
        amount: null,
        expireDate: null,
        useDate: null,
        logs: []
      },
      kagnotStatus: [
        { value: "available", label: "Disponible" },
        { value: "used", label: "Utilisée" },
        { value: "cancelled", label: "Annulée" },
        { value: "to_validate", label: "À valider" }
      ]
    };
  },
  created() {
    this.loadKagnots();
  },
  watch: {
    create: {
      immediate: true,
      handler(newVal) {
        if (newVal == true) {
          this.openCreateDialog();
        }
      }
    }
  },
  methods: {
    loadKagnots() {
      if(this.customerId) {
        const url = `/api/customer/${this.customerId}/allkagnots`;
  
        get(url).json(res => {
          this.kagnotListing.items = res.kagnots;
          this.usedKagnots = res.usedKagnots;
          this.lostKagnots = res.lostKagnots;
          this.availableKagnots = res.availableKagnots;
        });
      }
    },
    kagnotStatusType(status) {
      let result;

      if (status == "available") {
        result = { color: "green", text: "Disponible" };
      } else if (status == "to_use") {
        result = { color: "blue", text: "À utiliser" };
      } else if (status == "used") {
        result = { color: "grey", text: "Utilisée" };
      } else if (status == "cancelled") {
        result = { color: "red", text: "Annulée" };
      } else if (status == "to_validate") {
        result = { color: "orange", text: "À valider" };
      }
      return result;
    },
    openEditDialog(kagnot) {
      this.form = {
        ...kagnot,
        ...{
          cancelDate: kagnot.cancelDate
            ? kagnot.cancelDate.substring(0, 10)
            : null
        },
        ...{
          expireDate: kagnot.expireDate
            ? kagnot.expireDate.substring(0, 10)
            : null
        }
      };

      this.kagnotDialog = {
        ...this.kagnotDialog,
        ...{ open: true },
        ...{ type: "edit" }
      };
    },
    openRefundDialog(kagnot) {
      this.form = {
        ...kagnot,
        ...{
          cancelDate: kagnot.cancelDate
            ? kagnot.cancelDate.substring(0, 10)
            : null
        },
        ...{
          expireDate: kagnot.expireDate
            ? kagnot.expireDate.substring(0, 10)
            : null
        }
      };
      this.kagnotRefundDialog = true;
    },
    openCreateDialog() {
      this.form = {
        ...this.form,
        ...{ amount: null },
        ...{ status: "available" },
        ...{ expireDate: null },
        ...{ originBookingId: null }
      };

      this.valid = true;
      this.kagnotDialog = {
        ...this.kagnotDialog,
        ...{ open: true },
        ...{ type: "create" }
      };
      this.$nextTick(() => {
        this.$refs.form.resetValidation();
      });
    },
    refundKagnot() {
      post("/api/customer/kagnot/" + this.form.id + "/refund")
        .badRequest(err => {
          EventBus.$emit("toaster-error", err.message);
        })
        .res(() => {
          this.loadKagnots();
          this.kagnotRefundDialog = false;
        });
    },
    saveKagnot() {
      if (this.$refs.form.validate()) {
        let url = "/api/customer/kagnot/";
        let data = {};
        let msg = "";
        if (this.kagnotDialog.type == "create") {
          url = url + "create";
          data = {
            ...this.form,
            ...{ logs: [] }
          };

          msg = "Kagnot créée avec succès";
        } else {
          url = url + "edit";
          data = {
            ...this.form,
            ...{
              cancelDate: this.form.cancelDate
                ? this._parseAndFormatDate(
                    this.form.cancelDate,
                    this.datePatternConfig.serverLocalDate,
                    this.datePatternConfig.serverLocalDateTime
                  )
                : null
            },
            ...{
              expireDate: this.form.expireDate
                ? this._parseAndFormatDate(
                    this.form.expireDate,
                    this.datePatternConfig.serverLocalDate,
                    this.datePatternConfig.serverLocalDateTime
                  )
                : null
            }
          };

          msg = "Kagnot éditée avec succès";
        }

        post(url, data)
          .badRequest(err => {
            EventBus.$emit("toaster-error", err.message);
          })
          .json(response => {
            this.loadKagnots();
            this.kagnotDialog.open = false;
            this.$emit("dialogClosed");
            EventBus.$emit("toaster-msg", msg);
          });
      }
    },
    closeDial() {
      this.$emit("dialogClosed");
    }
  }
};
</script>

<style scoped>
</style>
