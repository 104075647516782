<template>
  <layout drawerShow :drawerWidth="370">
    <template slot="nav-tools">
      <v-btn text @click="addDialog = true" :color="btnColor">
        <v-icon>mdi-folder-plus</v-icon>
        <span class="pl-1">
          {{ project.propositions.length }} dossier
          <span v-if="project.propositions.length > 1">s</span>
        </span>
      </v-btn>
    </template>

    <template slot="drawer">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-container class="pa-0">
          <v-row>
            <v-col cols="12">
              <v-autocomplete
                v-model="searchSelect"
                :items="searchItems"
                hide-no-data
                item-text="name"
                item-value="id"
                placeholder="Hotels, Iles, Destinations"
                :append-icon="searchSelect ? '' : 'search'"
                :clearable="searchSelect != ''"
                :search-input.sync="searchTerm"
                return-object
                dense
                no-filter
                hide-details
                :rules="rules.nonEmpty('Lieux ou Hotel obligatoire')"
              >
                <template slot="item" slot-scope="data">
                  <v-list-item-action>
                    <v-icon>{{ searchTermIcon(data.item.kind) }}</v-icon>
                  </v-list-item-action>
                  <v-list-item-content
                    v-text="data.item.name"
                  ></v-list-item-content>
                </template>
                <template slot="selection" slot-scope="data">
                  <v-icon>{{ searchTermIcon(data.item.kind) }}</v-icon>
                  <span class="ml-2">{{ data.item.name }}</span>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="6">
              <smart-picker
                v-model="startDate"
                format="dd/MM/yyyy"
                :next-picker="$refs.calendarStopDatePicker"
                :inputOpts="{ label: $t('begin') }"
                :pickerOpts="startDatePickerConfig"
                :events="stayDates"
              ></smart-picker>
            </v-col>
            <v-col cols="6">
              <smart-picker
                v-model="stopDate"
                format="dd/MM/yyyy"
                ref="calendarStopDatePicker"
                :inputOpts="{ label: $t('begin') }"
                :pickerOpts="stopDatePickerConfig"
                :events="stayDates"
              ></smart-picker>
            </v-col>
            <v-col cols="3">
              <v-select
                v-model="nbNights"
                :items="nights"
                label="Nuits"
                hide-details
                dense
              ></v-select>
            </v-col>
            <v-col cols="9">
              <v-select
                v-model="env.priceClassTypeId"
                :items="priceClassTypes"
                label="PriceClass"
                item-value="id"
                item-text="name"
                hide-details
                dense
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-select
                v-model="mealPlanTypeIds"
                :items="mealPlanTypes"
                label="Plan Repas"
                item-value="id"
                item-text="name"
                hide-details
                multiple
                dense
              ></v-select>
            </v-col>
            <v-col cols="6">
              <v-autocomplete
                v-model="airport"
                :items="airports"
                item-value="code"
                :item-text="airportName"
                label="Vol depuis"
                hide-details
                hide-no-data
                clearable
              ></v-autocomplete>
            </v-col>
            <v-col cols="6">
              <v-select
                v-model="transfer"
                :items="transfers"
                item-value="value"
                item-text="label"
                label="Transferts"
                hide-details
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-subheader light class="form-subheader">Voyageurs</v-subheader>
              <v-divider></v-divider>
            </v-col>
            <v-col cols="12">
              <rooms-repartition-list
                v-model="roomsRepartition"
              ></rooms-repartition-list>
            </v-col>

            <v-col cols="12" class="text-center">
              <v-divider></v-divider>
              <v-btn :loading="loading" @click="search" class="mt-3">
                <v-icon>search</v-icon>Rechercher
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </template>

    <template slot="content">
      <v-container fluid>
        <div
          class="calendar-container elevation-2 white"
          style="min-width: 100%"
        >
          <div :style="{ width: totalWidth + 'px' }">
            <div v-for="(hotel, i) in hotels" :key="i">
              <calendar-hotel
                :hotel="hotel"
                :withFlights="airport != null"
                :withTransfers="withTransfers"
                :flightsLoading="flightsLoading"
                :flights="flights"
                :roomsRepartition="roomsRepartition"
                :mealPlanTypeIds="mealPlanTypeIds"
                :env="env"
                :startDate="startDate"
                :stopDate="stopDate"
                :nbNights="nbNights"
                :hideheaders="i > 0"
                @select="selectHotel"
                @loading="setLoading"
              ></calendar-hotel>
            </div>
          </div>
        </div>
      </v-container>

      <smart-dialog
        v-model="addDialog"
        title="Créer un projet de voyage"
        scrollable
      >
        <v-card flat>
          <v-card-text>
            <v-container class="pa-0" fluid grid-list-md>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    v-model="project.name"
                    label="Nom du projet de voyage"
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-select
                    label="Site"
                    v-model="project.siteId"
                    :items="sites"
                    item-value="id"
                    item-text="name"
                  ></v-select>
                </v-col>
                <v-col cols="3">
                  <v-select
                    label="Langue"
                    v-model="project.langId"
                    :items="langs"
                    item-value="id"
                    item-text="name"
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <v-data-table
                    v-model="selected"
                    hide-default-footer
                    :items="project.propositions"
                    :headers="addDialogHeaders"
                    show-select
                    class="tbl elevation-1 my-2"
                  >
                    <template #item="{ item, index }">
                      <tr>
                        <td class="px-1">
                          <v-checkbox
                            :input-value="item.selected"
                            primary
                            hide-details
                          ></v-checkbox>
                        </td>
                        <td>
                          <div>
                            <b>{{ item.hotelInfos.name }}</b>
                            <small class="ml-3">
                              {{ item.hotelStart }} -
                              {{ item.hotelStop }}
                            </small>
                          </div>
                          <div>
                            {{ item.hotel.rooms[0].name }} •
                            {{ item.hotel.meals[0].name }}
                          </div>
                        </td>
                        <td>{{ item.total | currency("EUR") }}</td>
                        <td>
                          <v-btn
                            small
                            icon
                            @click="removeSelection(index)"
                            class="ma-0"
                          >
                            <v-icon small>close</v-icon>
                          </v-btn>
                        </td>
                      </tr>
                    </template>
                    <!--<template slot="items" slot-scope="props">
											<tr>
												<td class="px-1">
													<v-checkbox
															:input-value="props.item.selected"
															primary
															hide-details
													></v-checkbox>
												</td>
												<td>
													<div><b>{{props.item.hotelInfos.name}}</b> <small class="ml-3">{{props.item.hotelStart}} - {{props.item.hotelStop}}</small></div>
													<div>{{props.item.hotel.rooms[0].name}} • {{props.item.hotel.meals[0].name}}</div>
												</td>
												<td>{{props.item.total | currency('EUR')}}</td>
												<td class="px-1">
													<v-btn small icon @click="removeSelection(i)" class="ma-0"><v-icon small>close</v-icon></v-btn>
												</td>
											</tr>
                    </template>-->
                  </v-data-table>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-text>
            <div v-if="project.customer">
              <span class="font-weight-medium subheading">Client</span>
              <smart-btn secondary disabled>
                <v-icon small>mdi-account</v-icon>
                {{ project.customer.name }}
              </smart-btn>

              <v-btn icon small @click="linkCustomerStep = true">
                <v-icon small>edit</v-icon>
              </v-btn>
              <v-btn icon small @click="clearCustomer">
                <v-icon small>close</v-icon>
              </v-btn>
            </div>
            <div
              v-if="!linkCustomerStep && !project.customer"
              class="text-center"
            >
              <div class="grey--text">Aucun client associé</div>
              <smart-btn
                secondary
                @click.native="
                  isAgency = false;
                  linkCustomerStep = true;
                "
                >Associer un client</smart-btn
              >
              <smart-btn
                secondary
                @click.native="
                  isAgency = true;
                  linkCustomerStep = true;
                "
                >Associer une agence</smart-btn
              >
            </div>
            <div v-if="linkCustomerStep">
              <div class="text-right">
                <v-btn icon small @click="linkCustomerStep = false">
                  <v-icon small>close</v-icon>
                </v-btn>
              </div>
              <booking-customer-create
                @linkCustomer="linkCustomer"
                @linkAgency="linkAgency"
                :isAgency="isAgency"
              ></booking-customer-create>
            </div>
          </v-card-text>
        </v-card>

        <template slot="actions">
          <smart-btn
            primary
            dark
            :loading="project.loading"
            @click.native="createProject"
            >Créer le projet de voyage</smart-btn
          >
        </template>
      </smart-dialog>
    </template>
  </layout>
</template>
<script>
import _ from "lodash";
import { get, post } from "@/utils/api";

import FormMixin from "@/components/mixins/FormMixin";
import { EventBus } from "@/components/commons/event-bus";
import RoomsRepartitionList from "@/components/commons/RoomsRepartitionList";
import CalendarHotel from "./CalendarHotel";
import Layout from "@/views/Layout";

import BookingCustomerCreate from "@/components/booking/BookingCustomerCreate";

export default {
  name: "CalendarSearch",
  components: {
    BookingCustomerCreate,
    Layout,
    RoomsRepartitionList,
    CalendarHotel,
  },
  mixins: [FormMixin],
  props: {},
  data: () => ({
    content: "",
    valid: false,
    searchSelect: "",
    searchTerm: "",
    searchItems: [],
    hotels: [],
    startDate: "",
    startDatePickerConfig: {
      eventColor: "blue lighten-1",
      min: "",
    },
    stopDate: "",
    stopDatePickerConfig: {
      eventColor: "blue lighten-1",
      min: "",
    },
    roomsRepartition: [
      {
        guests: [
          { ageClassType: "Adult", id: 1 },
          { ageClassType: "Adult", id: 2 },
        ],
        repartition: "SAMEROOM",
      },
    ],
    env: {
      siteId: 1,
      langId: 1,
      priceClassTypeId: 2,
      strokePriceClassTypeId: 3,
      clientCountry: "FR",
      debug: false,
      withCache: false,
    },
    loading: false,
    priceClassTypes: [],
    mealPlanTypeIds: [],
    mealPlanTypes: [],
    ratingValues: [],
    ratings: [],
    nights: Array.from({ length: 23 }, (value, key) => key + 3),
    nbNights: 7,
    airport: null,
    airports: [],
    transfer: "none",
    transfers: [
      { value: "none", label: "Aucun transfert" },
      { value: "transfer", label: "Avec transfert" },
    ],
    flights: {},
    flightsLoading: false,
    selected: [],
    sites: [],
    langs: [],
    project: {
      name: "",
      siteId: 1,
      langId: 1,
      propositions: [],
      customer: null,
      loading: false,
    },
    isAgency: false,
    linkCustomerStep: false,
    addDialog: false,
    addDialogStep: null,
    addDialogHeaders: [
      { text: "Proposition", value: "hotelInfos", sortable: false },
      { text: "Prix", value: "total", sortable: false },
      { text: "", value: "edit", sortable: false },
    ],
  }),
  created() {
    this.startDate = this._formatDate(
      this._addDays(this._now(), 5),
      "dd/MM/yyyy"
    );
    this.startDatePickerConfig.min = this._formatDate(
      this._subDays(this._now(), 1),
      "dd/MM/yyyy"
    );
    this.stopDatePickerConfig.min = this._formatDate(
      this._addDays(this._now(), 5),
      "dd/MM/yyyy"
    );
    this.stopDate = this._formatDate(
      this._addDays(this._now(), 12),
      "dd/MM/yyyy"
    );
    this.init();
  },
  watch: {
    searchTerm(val) {
      val && val !== this.searchSelect && this.doSearchTerm(val);
    },
    startDate(newVal, oldVal) {
      const start = this._parseDate(oldVal, "dd/MM/yyyy");
      const stop = this._parseDate(this.stopDate, "dd/MM/yyyy");
      const newStart = this._parseDate(newVal, "dd/MM/yyyy");
      if (this._isAfter(newStart, stop)) {
        let diff = this._nbDays(start, stop);
        let clone = new Date(newStart.getTime());
        this.stopDate = this._formatDate(
          this._addDays(clone, diff),
          "dd/MM/yyyy"
        );
      }
    },
  },
  computed: {
    withTransfers() {
      return this.transfer !== "none";
    },
    stayDates() {
      const stop = this._formatDate(
        new Date(this._toDate(this._parseDate(this.stopDate)).getTime()),
        "dd/MM/yyyy"
      );
      const start = this._formatDate(
        new Date(this._toDate(this._parseDate(this.startDate)).getTime()),
        "dd/MM/yyyy"
      );

      const duration = this._nbDays(start, stop);

      const dates = [];
      let clone = this._formatDate(
        new Date(this._toDate(this._parseDate(start)).getTime()),
        "dd/MM/yyyy"
      );
      for (var i = 0; i < duration; i++) {
        dates.push(this._formatDate(this._addDays(clone, i)).substr(0, 10));
      }
      return dates;
    },
    nbdays() {
      const start = this._parseDate(this.startDate, "dd/MM/yyyy");
      const stop = this._parseDate(this.stopDate, "dd/MM/yyyy");

      return this._nbDays(start, stop);
    },

    totalWidth() {
      return 300 + 60 * this.nbdays + 1;
    },
    btnColor() {
      return this.project.propositions.length > 0
        ? "blue text--darken-1"
        : "grey";
    },
  },
  methods: {
    init() {
      get(`/api/search/sites`).json((data) => {
        this.sites = data;
      });

      get(`/api/search/langs`).json((data) => {
        this.langs = data;
      });

      get(`/api/search/mealplantypes`).json((data) => {
        this.mealPlanTypes = data;
      });

      get(`/api/search/ratings`).json((data) => {
        this.ratings = data;
      });

      get(`/api/search/priceclasstypes`).json((data) => {
        this.priceClassTypes = data;
      });

      get(`/api/prestation/add/search-airports`)
        .badRequest((err) => {
          EventBus.$emit(
            "toaster-msg",
            JSON.parse(err.null).errors.map((e) => JSON.parse(e))
          );
        })
        .json((data) => {
          this.airports = data;
        });
    },
    searchTermIcon(kind) {
      switch (kind) {
        case "destination":
          return "mdi-flag-variant";
        case "hotel":
          return "hotel";
        case "tag":
          return "place";
        case "island":
          return "mdi-beach";
        default:
          return "adb";
      }
    },
    doSearchTerm(term) {
      get(`/api/prestation/add/search-term?term=` + term)
        .badRequest((err) => {
          EventBus.$emit(
            "toaster-msg",
            JSON.parse(err.null).errors.map((e) => JSON.parse(e))
          );
        })
        .json((data) => {
          this.searchItems = data;
        });
    },
    airportName(airport) {
      return airport.code + " - " + airport.name;
    },
    search() {
      this.loading = true;
      if (this.$refs.form.validate()) {
        this.toAirports = [];
        this.hotels = [];
        post(`/api/search`, {
          search: this.searchSelect,
          ratings: this.ratingValues,
        })
          .badRequest((err) => {
            EventBus.$emit(
              "toaster-msg",
              JSON.parse(err.null).errors.map((e) => JSON.parse(e))
            );
          })
          .json((data) => {
            this.toAirports = data.airports;
            this.hotels = data.hotels;
          })
          .then(() => {
            if (this.airport) {
              this.buildFlights();
            }
          });
      }
    },
    buildFlights() {
      var start = this._parseDate(this.startDate, "dd/MM/yyyy");
      var stop = this._parseDate(this.stopDate, "dd/MM/yyyy");

      var days = this._duration(start, stop, "days");
      this.flights = {};
      var promises = [];
      this.flightsLoading = true;

      let cloneStart1 = new Date(start.getTime());
      let cloneStart2 = new Date(start.getTime());

      for (var i = 0; i < days; i++) {
        const startdate = this._formatDate(
          this._addDays(cloneStart1, i),
          "dd/MM/yyyy"
        );
        const stopdate = this._formatDate(
          this._addDays(cloneStart2, i + this.nbNights),
          "dd/MM/yyyy"
        );

        var p = post(`/api/search/flights`, {
          startDate: startdate,
          stopDate: stopdate,
          fromAirport: this.airport,
          toAirports: this.toAirports,
          guests: _.flatMap(this.roomsRepartition, (r) => r.guests),
          env: {
            siteId: 1,
            priceClassTypeId: 16,
          },
        })
          .badRequest((err) => {
            EventBus.$emit(
              "toaster-msg",
              JSON.parse(err.null).errors.map((e) => JSON.parse(e))
            );
          })
          .json((data) => {
            var obj = {};
            obj[startdate] = data;
            return obj;
          });
        promises.push(p);
      }

      Promise.all(promises)
        .then((flights) => {
          var obj = {};
          flights.map((f) => {
            obj = Object.assign({}, obj, f);
          });
          this.flights = obj;
          this.flightsLoading = false;
        })
        .catch((e) => {
          this.flightsLoading = false;
        });
    },
    setLoading(loading) {
      this.loading = loading;
    },
    selectHotel(obj) {
      obj.selected = true;
      this.project.propositions.push(obj);
      this.snackbar = true;
    },
    removeSelection(index) {
      this.project.propositions.splice(index, 1);
    },
    clearCustomer() {
      this.project.customer = null;
      this.linkCustomerStep = false;
    },
    linkCustomer(customer) {
      this.project.customer = {
        customerId: customer.id,
        customerType: "client",
        name: customer.name,
      };
      this.linkCustomerStep = false;
    },
    linkAgency({ agency, agent }) {
      this.project.customer = {
        customerId: agency.id,
        customerType: "agency",
        agentId: agent.id,
        name: agency.name,
      };
      this.linkCustomerStep = false;
    },
    createProject() {
      this.project.loading = true;

      post(`/api/calendar/create`, this.project).json((data) => {
        this.project.loading = false;
        this.addDialog = false;
        let route = this.$router.resolve({
          name: "booking-project",
          params: { id: data.bookingProjectId },
        });
        window.open(route.href, "_blank");
        self.focus();
      });
    },
  },
};
</script>
<style scoped>
.calendar-container {
  overflow-x: auto;
}

>>> .v-table__overflow {
  overflow-x: hidden;
}
</style>
