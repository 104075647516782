<template>
  <layout drawerShow :drawerWidth="400">
    <template slot="nav">
      <v-tabs>
        <v-tab :to="{ name: 'supplier-invoice-list' }"
        >Liste des Factures</v-tab
        >
        <v-tab :to="{ name: 'supplier-invoice-imports' }">Imports</v-tab>
        <v-tab :to="{ name: 'supplier-invoice-payment' }">Liste des paiements</v-tab>
      </v-tabs>
    </template>
    <template slot="drawer">
      <v-container fluid class="pa-0">
        <v-row>
          <v-col cols="12">
            <v-autocomplete
                v-model.number="searchCtx.supplierAccountingId"
                :items="listSuppliers"
                item-value="id"
                item-text="name"
                label="Fournisseur"
                clearable
            />
          </v-col>

          <v-col cols="12">
            <v-divider />
            <div class="caption mt-2">Date de paiement</div>
          </v-col>
          <v-col cols="6">
            <smart-picker
                v-model="searchCtx.paymentDateStart"
                label="Début"
                placeholder=" "
                :inputOpts="pickerOpts"
            />
          </v-col>
          <v-col cols="6">
            <smart-picker
                v-model="searchCtx.paymentDateStop"
                label="Fin"
                placeholder=" "
                :inputOpts="pickerOpts"
            />
          </v-col>

          <v-col cols="12">
            <v-divider />
            <div class="caption mt-2">Montant</div>
          </v-col>
          <v-col cols="6">
            <v-text-field
                v-model="searchCtx.minAmount"
                label="Montant min"
                class="mt-0 pt-0"
                hide-details
                single-line
                type="number"
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
                v-model="searchCtx.maxAmount"
                label="Montant max"
                class="mt-0 pt-0"
                hide-details
                single-line
                type="number"
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="text-right">
            <v-divider class="mb-3" />
            <v-btn class="primary" :loading="loading" @click="search">
              <v-icon>search</v-icon>Rechercher
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </template>
    <template slot="content">
      <v-container>
        <v-card>
          <v-data-table
              :headers="headers"
              :items="items"
              :loading="loading"
              :footer-props="{
              'items-per-page-options': rowsPerPageItems,
              'items-per-page-text': 'Lignes par page',
            }"
              no-data-text="-"
              class="tbl"
          >
          </v-data-table>
        </v-card>
      </v-container>
    </template>
  </layout>
</template>
<script>
import Layout from "@/views/Layout";
import AccountingNav from "@/views/accounting/AccountingNav";
import { get, post } from "@/utils/api";
import { EventBus } from "@/components/commons/event-bus";
import _ from "lodash";
import {format, parse, parseISO} from "date-fns";

export default {
  name: "SupplierInvoicePayment",

  props: [],
  components: {
    Layout,
    AccountingNav,
    EventBus,
  },
  mixins: [],
  data() {
    return {
      rowsPerPageItems: [{ text: "Tous", value: -1 }, 50, 200],
      selected: [],
      headers: [
        { text: "#", value: "id", sortable: false },
        {
          text: "Date création",
          value: "creation_date",
          sort: this.sortByDateTime,
        },
        { text: "Fournisseurs", value: "supplier_id" },
        { text: "Montant", value: "price" },
        { text: "Devise", value: "currency" },
      ],
      searchCtx: {
        supplierAccountingId: null,
        maxAmount: null,
        minAmount: null,
        paymentDateStart: null,
        paymentDateStop: null,
      },
      loading: false,
      items: [],
      listSuppliers: [],
      pickerOpts: {
        class: "pt-0",
        clearable: true,
      }
    };
  },
  mounted() {
    this.init()
  },
  methods: {
    async init() {
      await get("/api/accounting/list-supplier").json(
          (data) => (this.listSuppliers = data)
      );

      this.search();
    },
    search() {
      this.loading = true;
      this.items = [];
      this.selected = [];

      this.searchCtx.minAmount = this.searchCtx.minAmount || null;
      this.searchCtx.maxAmount = this.searchCtx.maxAmount || null;

      post("/api/accounting/supplier-invoice-payment/list", this.searchCtx)
          .json((data) => {
            this.items = data.map(x => {
              return {
                id:x.id,
                price:x.price,
                currency:x.currency,
                creation_date:format(parseISO(x.creation_date),"dd/MM/yyyy"),
                supplier_id: this.listSuppliers.find(supplier => supplier.id == x.supplier_id).name
              }
            });
          })
          .catch((e) => {
            EventBus.$emit("toast", {
              color: "red",
              text: "Une erreur est survenue",
              exception: e,
            });
          })
          .then(() => {
            this.loading = false;
          });
    },
    sortByDateTime(a, b) {
      const start = a != null ? a : this._formatDate(new Date(null), this.datePatternConfig.serverLocalDateTime)
      const stop = b != null ? b :  this._formatDate(new Date(null), this.datePatternConfig.serverLocalDateTime)
      return this._sortDates(start, stop, this.datePatternConfig.serverLocalDateTime);
    },
  },
};
</script>

