<template>
  <div class="d-flex flex-column mt-2" v-if="currentUser.id == item.recipientCommercialId || currentUser.id == item.userId">
    <div style="border: 1px lightgray solid; max-height: 400px; overflow-y: scroll" class="pa-2" id="chatMessages">
      <div class="mb-2 d-flex flex-column" style="gap: 4px">
        <div v-for="(message,i) in item.messages.messages" :key="i">
          <div v-if="checkNewMessage(message) && alreadyShow(message)" class="d-flex align-center justify-space-between my-2">
            <div style="width: 100%; height: 1px" class="grey lighten-2"></div>
            <p style="line-clamp: 1; width: 300px; font-size: 10px" class="mb-0 text-center">Nouveaux messages</p>
            <div style="width: 100%; height: 1px" class="grey lighten-2"></div>
          </div>
          <div style="width: 100%" :class="currentUser.id == message.senderId ? 'text-right' : ''">
            <div class="pa-2 rounded d-inline-block grey lighten-2" :class="currentUser.id == message.senderId ? 'text-right' : ''" style="max-width: 50%">
              <div style="width: 100%;" class="d-inline-block">
                {{message.message}}
                <p class="text-right mb-0 grey--text lighten-4--text mt-2" style="font-size: 8px">{{_formatDate(_parseDateISO(message.creationDate),"dd/MM/yyyy HH'h'mm")}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex align-end">
        <v-textarea
            filled
            auto-grow
            v-model="message">
        </v-textarea>
        <v-btn
            @click="sendMessage(item)"
            class="mx-2 mb-2"
            fab
            dark
            x-small
            color="primary"
        >
          <v-icon dark>
            mdi-send
          </v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>
<script>
import {compareAsc, format, parseISO} from "date-fns";
import {post} from "@/utils/api";
import {EventBus} from "@/components/commons/event-bus";

export default {
  props:{
    item: {type:Object,required:true}
  },

  data() {
    return {
      message: "",
      oldestNewMessage: null
    };
  },
  mounted() {
    EventBus.$on('scrollDownMessages',this.scrollDownMessages)
  },
  destroyed() {
    EventBus.$off('scrollDownMessages')
  },
  methods:{
    async sendMessage(commercialLog){
      const url = `/api/agency/crm/message/create`

      const body = {
        senderId: this.currentUser.id,
        message: this.message,
        creationDate: format(new Date(),"yyyy-MM-dd'T'HH:mm:ss"),
        commercialLogId: commercialLog.id
      }

      await post(url,body)
          .badRequest((br) => {
            EventBus.$emit('toaster-error', "erreur lors de l'envoie du message")
            console.log("Error: ", br);
          })
          .res(() => {
            this.$emit('newMessage',{senderId: body.senderId, message: body.message, creationDate: body.creationDate})
            this.$emit('updateView',{item:this.item})
            this.$nextTick(() => {
              this.scrollDownMessages()
            })
            this.message = ""
          })
    },
    scrollDownMessages(){
      const chatMessages = document.getElementById("chatMessages")
      chatMessages.scrollTop = chatMessages.scrollHeight
    },
    checkNewMessage(message) {
      if (message.senderId != this.currentUser.id) {
        const compareRecipient = compareAsc(parseISO(message.creationDate), parseISO(this.item.messages.lastViewRecipient)) != -1
        const compareUser = compareAsc(parseISO(message.creationDate), parseISO(this.item.messages.lastViewUser)) != -1
        if((compareUser || compareRecipient) && this.oldestNewMessage == null){
          this.oldestNewMessage = parseISO(message.creationDate)
        }
        if((compareRecipient || compareUser) && this.oldestNewMessage != null && compareAsc(parseISO(message.creationDate),this.oldestNewMessage) == -1){
          this.oldestNewMessage = parseISO(message.creationDate)
        }
        return compareUser || compareRecipient
      } else {
        return false
      }
    },
    alreadyShow(message){
      return compareAsc(this.oldestNewMessage,parseISO(message.creationDate)) != -1
    }
  },
}
</script>
