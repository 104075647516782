<template>
  <v-card flat>
    <v-col cols="12" v-if="bookingId">
      <v-card-text>
        <v-row>
          <v-col cols="4">
            <v-text-field
              v-model="project.name"
              label="Nom du projet de voyage"
              :rules="rules.nonEmpty()"
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-select
              label="Site"
              v-model="project.siteId"
              :items="data.sites"
              item-value="id"
              item-text="name"
            ></v-select>
          </v-col>
          <v-col cols="3">
            <v-select
              label="Langue"
              v-model="project.langId"
              :items="data.langs"
              item-value="id"
              item-text="name"
            ></v-select>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions class="actions-btn" xs12>
        <v-spacer></v-spacer>
        <smart-btn primary dark @click.native="createProject">Créer le projet de voyage</smart-btn>
      </v-card-actions>
    </v-col>

    <v-col cols="12" v-else-if="bookingProjectId">
      <v-form v-model="bookingForm.valid" ref="form" v-on:submit.prevent="createBooking">
        <v-row>
          <v-col cols="12" class="grey--text mt-3 mb-2 pl-1">Dossier</v-col>
          <v-col cols="4">
            <v-select
              v-model="bookingForm.siteId"
              :rules="rules.nonEmpty()"
              :items="data.sites"
              item-text="name"
              item-value="id"
              label="Site"
              class="pa-1"
            ></v-select>
          </v-col>
          <v-col cols="4">
            <v-select
              v-model="bookingForm.priceClassTypeId"
              :rules="rules.nonEmpty()"
              :items="data.priceclass"
              item-text="name"
              item-value="id"
              label="Classe de prix"
              class="pa-1"
            ></v-select>
          </v-col>
          <v-col cols="4">
            <v-select
              v-model="bookingForm.currencyCode"
              :rules="rules.nonEmpty()"
              :items="data.currencies"
              item-text="name"
              item-value="code"
              label="Vente"
              class="pa-1"
            ></v-select>
          </v-col>

          <v-col cols="12" class="grey--text mb-2 pl-1">Client</v-col>
          <v-col cols="4">
            <v-select
              v-model="bookingForm.countryCode"
              :rules="rules.nonEmpty()"
              :items="data.countries"
              item-text="name"
              item-value="code"
              label="Pays"
              class="pa-1"
            ></v-select>
          </v-col>
          <v-col cols="4">
            <v-select
              v-model="bookingForm.langId"
              :rules="rules.nonEmpty()"
              :items="data.langs"
              item-text="name"
              item-value="id"
              label="Langue"
              class="pa-1"
            ></v-select>
          </v-col>
          <v-col cols="4">
            <v-select
              v-model="bookingForm.declarations"
              :items="data.declarations"
              label="PACS / Voyage de noces"
              class="pa-1"
              clearable
              multiple
              @change="declarationsChange"
            ></v-select>
          </v-col>

          <v-col cols="12" class="grey--text mt-3 mb-2 pl-1">Voyageurs</v-col>
          <v-col cols="12">
            <booking-guest-creation
              v-model="bookingForm.guests"
              :booking-status="defaultBookingStatus"
            ></booking-guest-creation>
          </v-col>
        </v-row>
        <v-card-actions class="actions-btn">
          <v-spacer></v-spacer>
          <smart-btn primary dark @click.native="createBooking">Créer le dossier</smart-btn>
        </v-card-actions>
      </v-form>
    </v-col>
  </v-card>
</template>

<script>
import { get, post } from "../../utils/api";
import BookingCustomerCreate from "../booking/BookingCustomerCreate";
import BookingGuestCreation from "../booking/BookingGuestCreation";
import FormMixin from "../mixins/FormMixin";
import { EventBus } from "@/components/commons/event-bus";

export default {
  name: "CreateAndAssociateBookingToProject",
  mixins: [FormMixin],
  components: {
    BookingCustomerCreate,
    BookingGuestCreation,
  },
  props: {
    bookingId: {
      type: Number,
      required: false,
    },
    bookingProjectId: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      defaultBookingStatus: "quotation",
      data: {
        sites: [],
        priceclass: [],
        langs: [{ id: 1, locale: "fr_FR", name: "Français" }],
        countries: [
          { code: "FR", name: "France" },
          { code: "RE", name: "Réunion" },
        ],
        currencies: [{ id: 1, code: "EUR", name: "Euros" }],
        declarations: ["Voyage de Noces", "PACS"],
      },
      selected: [],
      project: {
        name: "",
        siteId: 1,
        langId: 1,
        propositions: [],
        customer: null,
        loading: false,
      },
      bookingForm: {
        siteId: null,
        langId: 1,
        priceclassTypeId: null,
        countryCode: "FR",
        currencyCode: "EUR",
        declarations: [],
        guests: [],
        valid: false,
      },
      manuallyAddedBookings: [],
      linkCustomerStep: false,
    };
  },
  created() {
    this.init();
    this.project.propositions = this.propositions;
  },
  watch: {
    "form.siteId"() {
      if (this.form.siteId == 4) {
        this.form.countryCode = "RE";
      }
    },
  },
  computed: {},
  methods: {
    init() {
      get("/api/search/priceclasstypes").json((priceclass) => {
        this.data.priceclass = priceclass;
      });
      get("/api/search/sites").json((sites) => {
        this.data.sites = sites;
      });
    },
    toggle() {
      this.dialog = !this.dialog;
    },
    declarationsChange() {
      if (this.bookingForm.declarations.length > 1) {
        this.bookingForm.declarations = this.form.declarations.slice(-1);
      }
    },
    createBooking() {
      if (this.$refs.form.validate()) {
        let form = {
          ...this.bookingForm,
          ...{ bookingProjectId: this.bookingProjectId },
        };
        post("/api/booking/create", form)
          .badRequest((err) => {
            EventBus.$emit("toaster-msg", err);
          })
          .text((data) => {
            this.$router.push({ name: "Booking", params: { id: data } });
          });
      }
    },
    linkCustomer(customer) {
      this.project.customer = {
        customerId: customer.id,
        customerType: "client",
        name: customer.name,
      };
      this.linkCustomerStep = false;
    },
    clearCustomer() {
      this.project.customer = null;
      this.linkCustomerStep = false;
    },
    linkAgency({ agency, agent }) {
      this.project.customer = {
        customerId: agency.id,
        customerType: "agency",
        agentId: agent.id,
        name: agency.name,
      };
      this.linkCustomerStep = false;
    },
    createProject() {
      if (this.bookingId) {
        const url = `/api/booking/${this.bookingId}/bookingproject/create-associate`;

        post(url, this.project)
          .badRequest((err) => {
            EventBus.$emit("toaster-msg", err);
          })
          .json((response) => {
            this.$emit("success", {});
            EventBus.$emit("toaster-msg", "Projet de voyage créé avec succès");

            let route = this.$router.resolve({
              name: "booking-project",
              params: { id: response.bookingProjectId },
            });
            window.open(route.href, "_blank");
          });
      }
    },
  },
};
</script>

<style scoped>
</style>