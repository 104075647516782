<template>
  <layout :drawer="true" :drawerShow="_isDesktop()">
    <template slot="nav">
      <v-tabs>
        <v-tab>Dossiers impayés</v-tab>
      </v-tabs>
    </template>

    <template slot="drawer">
      <v-form ref="searchform" v-if="searchform" @submit.prevent="search">
        <v-row dense>
          <v-col cols="12" class="mt-2">
            <smart-multi-select
                v-model="searchform.selectedSites"
                label="Sites"
                :items="sites"
                :all-is-same-as-empty="true"
                :default-select-all="true"
            >
            </smart-multi-select>
          </v-col>
          <v-col cols="12">
            <v-checkbox v-model="withoutInAccount" label="Masquer En compte"></v-checkbox>
          </v-col>
        </v-row>
        <smart-btn :loading="loading" type="submit" block primary
        >Rechercher
        </smart-btn
        >
        <smart-btn
            block
            tertiary
            @click.native="
            clearSearchform();
            search();
          "
        >Réinitialiser la recherche
        </smart-btn
        >
      </v-form>
    </template>

    <template slot="content">
      <v-container fluid>
        <v-card style="max-width: 1024px; margin: auto">
          <v-data-table
              :headers="headers"
              :items="items"
              :sort-by="['bookingDate']"
              :sort-asc="[true]"
              :loading="loading"
              no-data-text="-"
              hide-default-footer
              :footer-props="{
              'items-per-page-options': rowsPerPageItems,
              'items-per-page-text': 'Lignes par page',
            }"
              class="tbl"
              @click:row="openBooking"
          >
            <template #item.siteName="{ item }">
              <span class="caption grey--text text--darken-1">{{
                  item.id
                }}</span>
              <site-label :sitename="item.siteName"></site-label>
            </template>

            <template #item.customer="{ item }">
              <span class="font-weight-regular">{{ item.customer.name }}</span>
              <br/>
              <span
                  class="caption grey--text text--darken-1"
                  v-if="item.customer.phone"
              >{{ item.customer.phone }}</span
              >
              <br/>
              <span
                  class="caption grey--text text--darken-1"
                  v-if="item.customer.email"
              >{{ item.customer.email }}</span
              >
            </template>
            <template #item.toPaid="{ item }">
              <span class="text-right body-2 font-weight-medium">{{
                  item.toPaid | currency("EUR")
                }}</span>
            </template>

            <template #item.bookingDate="{ item }">
              <div class="font-weight-regular">
                {{
                  _parseAndFormatDate(
                      item.bookingDate.substring(0, 18),
                      "yyyy-MM-dd'T'HH:mm:ss",
                      "dd/MM/yyyy"
                  )
                }}
              </div>
            </template>
            <template #item.startDate="{ item }">
              <div class="font-weight-regular">
                {{
                  _parseAndFormatDate(
                      item.startDate,
                      "yyyy-MM-dd'T'HH:mm:ss",
                      "dd/MM/yyyy"
                  )
                }}
              </div>
            </template>
            <template #item.balanceDate="{ item }">
              <div class="font-weight-regular">
                {{
                  _parseAndFormatDate(
                      item.balanceDate,
                      "yyyy-MM-dd",
                      "dd/MM/yyyy"
                  )
                }}
              </div>
            </template>

            <template #item.status="{ item }">
              <availability v-model="item.status" kind="BookingStatus"/>
            </template>
          </v-data-table>
        </v-card>
      </v-container>
    </template>
  </layout>
</template>
<script>
import Layout from "@/views/Layout";
import SiteLabel from "@/components/commons/SiteLabel";
import Availability from "@/components/commons/Availability";
import {EventBus} from "@/components/commons/event-bus";
import {get, post} from "@/utils/api";
import SmartMultiSelect from "../../components/commons/SmartMultiSelect";

export default {
  components: {
    Layout,
    SiteLabel,
    Availability,
    SmartMultiSelect,
  },

  data: (self) => ({
    headers: [
      {text: "Site", value: "siteName", sortable: false},
      {text: "Client", value: "customer", sortable: true, width: "300px", sort: (a, b) => a.name.localeCompare(b.name)},
      {text: "Date résa", value: "bookingDate", sortable: true, sort: (a, b) => self.sortDates(a,b, "yyyy-MM-dd'T'HH:mm:ss.SSS")},
      {text: "Date départ", value: "startDate", sortable: true, sort: (a, b) => self.sortDates(a,b,"yyyy-MM-dd'T'HH:mm:ss")},
      {text: "Date solde", value: "balanceDate", sortable: true, sort: (a, b) => self.sortDates(a,b,"yyyy-MM-dd")},
      {text: "Reste à payer", value: "toPaid", sortable: true, align: "end"},
      {
        text: "",
        value: "status",
        sortable: false,
        width: "150px",
        align: "center",
      },
    ],
    items: [],
    loading: false,
    sites: [],
    withoutInAccount: true,
    rowsPerPageItems: [{text: "Tous", value: -1}, 50, 200],
    searchform: {
      selectedSites: [],
    },
  }),

  async created() {
    await this.loadSites();
    this.search();
  },
  methods: {
    loadSites() {
      return get("/api/search/sites").json((sites) => {
        this.sites = sites;
        this.searchform.selectedSites = this.siteids;
      });
    },
    search() {
      if (!this.loading) {
        this.loading = true;
        const url = "/api/accounting/unpaid-bookings";

        if (this.searchform.selectedSites.length == 0) {
          this.searchform.selectedSites = this.siteids;
        }

        post(url + "?withoutInAccount=" + this.withoutInAccount, this.searchform.selectedSites)
            .json((data) => {
              this.items = data;
            })
            .catch((e) => {
              EventBus.$emit("toast", {
                color: "red",
                text: "Une erreur est survenue",
                exception: e,
              });
            })
            .then(() => {
              this.loading = false;
            });
      }
    },
    openBooking(item) {
      let route = this.$router.resolve({
        name: "Booking",
        params: {id: item.id},
      });
      window.open(route.href, "_blank");
      return false;
    },
    clearSearchform() {
      this.searchform = {
        selectedSites: this.siteids,
      };
    },
    sortDates(a,b, pattern){
    return this._sortDates(a,b, pattern)

    },
  },
  computed: {
    siteids() {
      return this.sites.map((s) => s.id);
    },
  },
};
</script>
