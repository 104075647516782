<template>
  <WidgetDatatableTabs :items="data.data.length > 0 ? data.data : []"  :nbHeaders="1" :nbTabs="0" :headersList="headers"
                                     defaultTarget="date">
    <template #title>{{ conf.title }}</template>
  </WidgetDatatableTabs>
</template>
<script>
import {post} from "@/utils/api";
import WidgetTile from "@/components/commons/widgets/WidgetTile.vue";
import {EventBus} from "@/components/commons/event-bus";
import WidgetDatatableTabs from "@/components/commons/widgets/WidgetDatatableTabs.vue";

export default {
  name: "EvolutionLoginsTable",
  components: {WidgetDatatableTabs},
  props: {
    form: {type: Object, required: true},
    conf: {type: Object, required: false, default: null}
  },
  data() {
    return {
      url: `/api/widget/hl/dashboard/evolutionLogin`,
      data: {
        data: [],
      },
      headers:[
        {
          text: "Agence",
          value: "name",
          align: "left",
          sortable: true,
        },
        {
          text: "Nombre",
          value: "count",
          align: "right",
          sortable: true,
        }
      ],
      loading: true,
    }
  },
  computed: {},
  created() {
    EventBus.$on('searchDashboard', () => this.init());
    this.init();
  },
  methods: {
    init() {
      const searchForm = this._formatPayloadWidgetDashboard(this.conf,this.form)

      post(this.url, searchForm, {"Content-Type": "application/json"})
          .json(r => {
            this.data.data = r.map(r => {
              return {name: r.name, date: r.lastVisit, evol: this.getDiffPercent(r.countBefore, r.countAfter)}
            })
          })

    },
    formatDataTable(actual, old) {
      let toPushinActual = []
      old.forEach(data => {
        if (!actual.find(n => data.name === n.name)) {
          toPushinActual.push(
              {
                name: data.name,
                count: 0,
                sales: 0,
                purchases: 0,
                isPrice: data.isPrice != null ? data.isPrice : false,
                last: data,
                profits: 0
              }
          )

        }
      })

      actual.forEach(data => {
        if (!old.find(n => data.name === n.name)) {
          data.last = {
            name: data.name,
            count: 0,
            sales: 0,
            purchases: 0,
            isPrice: data.isPrice,
            profits: 0
          }
        }
      })
      actual.push(...toPushinActual)
      actual.forEach((sale) => {
        sale.last = old.find(oldSale => oldSale.name === sale.name)
        if (sale.last != null) {
          sale.last.profits = sale.last.sales - sale.last.purchases
        } else {
          sale.last = {profits: 0, sales: 0, purchases: 0, flights: 0}
        }
        sale.profits = sale.sales - sale.purchases
      })
      return actual
    },
    getDiffPercent(actual, old, rounded = false) {
      if (old === 0) {
        return "+0%"
      } else if (old == null) {
        return "+∞%"
      }
      const percent = rounded
          ? Math.round(((actual - old) / old) * 100)
          : ((actual - old) / old) * 100;
      const result = this._formatDecimal(percent) + "%";
      if (isNaN(percent)) {
        return null;
      }
      return result != null && result[0] == "-" ? result : "+" + result;
    }
  },
}
</script>