<template>
    <v-menu :close-on-content-click="false" lazy >
        <template #activator="{on}">
            <v-btn v-on="on">
                {{totalGuests()}}
                <v-icon right color="grey">supervisor_account</v-icon>
            </v-btn>
        </template>
        <v-card>
            <v-row align="center" justify="space-around">
                <v-col cols="3">
                    <v-label>{{$t('adults')}}</v-label>
                </v-col>
                <v-col cols="1">
                    <v-icon @click="handleCount(-1, 'adults')">remove_circle_outlined</v-icon>
                </v-col>
                <v-col cols="2">
                    <v-text-field
                            dense
                            v-model="adults"
                    ></v-text-field>
                </v-col>
                <v-col cols="2">
                    <v-icon @click="handleCount(1, 'adults')">add_circle_outlined</v-icon>
                </v-col>
            </v-row>
            <v-row align="center" justify="space-around">
                <v-col cols="3">
                    <v-label>{{$t('teenager')}}</v-label>
                </v-col>
                <v-col cols="1">
                    <v-icon @click="handleCount(-1, 'teenager')">remove_circle_outlined</v-icon>
                </v-col>
                <v-col cols="2">
                    <v-text-field
                            dense
                            v-model="teenager"
                    ></v-text-field>
                </v-col>
                <v-col cols="2">
                    <v-icon @click="handleCount(1, 'teenager')">add_circle_outlined</v-icon>
                </v-col>
            </v-row>
            <v-row align="center" justify="space-around">
                <v-col cols="3">
                    <v-label>{{$t('child')}}</v-label>
                </v-col>
                <v-col cols="1">
                    <v-icon @click="handleCount(-1, 'child')">remove_circle_outlined</v-icon>
                </v-col>
                <v-col cols="2">
                    <v-text-field
                            dense
                            v-model="child"
                    ></v-text-field>
                </v-col>
                <v-col cols="2">
                    <v-icon @click="handleCount(1, 'child')">add_circle_outlined</v-icon>
                </v-col>
            </v-row>
            <v-row align="center" justify="space-around">
                <v-col cols="3">
                    <v-label>{{$t('baby')}}</v-label>
                </v-col>
                <v-col cols="1">
                    <v-icon @click="handleCount(-1, 'baby')">remove_circle_outlined</v-icon>
                </v-col>
                <v-col cols="2">
                    <v-text-field
                            dense
                            v-model="baby"
                    ></v-text-field>
                </v-col>
                <v-col cols="2">
                    <v-icon @click="handleCount(1, 'baby')">add_circle_outlined</v-icon>
                </v-col>
            </v-row>
        </v-card>

    </v-menu>
</template>

<script>
	export default {
		name: "GuestRepartition",
		props: ['value'],
		data: () => ({

			adults: 2,
			teenager: 0,
			child: 0,
			baby: 0,

			guestRepartition: null
		}),
		created() {
          this.updateGuestsRepartition()
		},
		watch: {
			'adults'() {
				this.updateGuestsRepartition()
			},
			'teenager'() {
				this.updateGuestsRepartition()
			},
			'child'() {
				this.updateGuestsRepartition()
			},
			'baby'() {
				this.updateGuestsRepartition()
			}
		},
		computed: {},
		methods: {
			handleCount(count, model) {
				let adults = this[model]
				adults += (count)
				adults = adults >= 0 ? adults : 0
				this[model] = adults
			},
			totalGuests() {
				const ch = this.teenager + this.child + this.baby
				return `${this.adults} AD` + (ch > 0 ? `, ${ch} CH` : "")
			},
			updateGuestsRepartition() {
				this.guestRepartition = [
					...this.buildGuest(this.adults, "Adult"),
					...this.buildGuest(this.teenager, "Teenager"),
					...this.buildGuest(this.child, "Child"),
					...this.buildGuest(this.baby, "Baby")
				]

        this.guestRepartition.map( (g,i) => {
          g.id = i+1
          return g;
        })

				this.$emit('input', this.guestRepartition)
			},
			buildGuest(nb, kind) {
				let guest = []
				for (let i = 0; i < nb; i++) {
					guest.push({ageClassType: kind})
				}
				return guest
			}
		}
	}
</script>

<style scoped>

</style>
