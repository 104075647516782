<template>
    <smart-dialog v-model="open" title="Attribuer le dossier" width="600">
        <v-row class="ma-2 pa-2">
            <v-col cols="4">Attribuer le dossier et toute ses actions à:</v-col>
            <v-col cols="8">
                <v-select v-model="user" :items="users" item-value="id" item-text="name"></v-select>
            </v-col>
        </v-row>
        <template slot="actions">
            <smart-btn primary :disabled="user == null" @click.native="attribute">Appliquer</smart-btn>
        </template>
    </smart-dialog>
</template>

<script>
import { get, post } from "../../utils/api";
export default {
    props: {
        show: {
            type: Boolean, required: true
        },
        task: {
            type: Object, required: true
        },
        users: {
            type: Array, required: true
        }
    },
    data() {
        return {
            user: null
        }
    },
    computed: {
        open: {
            get: function () { return this.show },
            set: function (v) {
                this.$emit('update:show', v);
            }
        }
    },
    created() {
    },
    methods: {
        attribute() {
            //
            get(`/api/task/attribute-booking/${this.task.bookingId}/user/${this.user}`, {})
                .res(() => {
                    this.$emit('attributed')
                })
        }
    }
}

</script>