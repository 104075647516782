<template>
  <v-card ref="vCardRef" :elevation="1"
          class="pa-4"
          style="height:100%; width:100%; overflow: hidden">
    <div class="d-flex flex-row justify-space-between mb-10">
      <span class="font-weight-bold">
        {{ conf.title }}</span>
      <div>
        <span @click="viewBy('day')"
              :class="actualView == 'day'
                ? 'font-weight-black pointer-event-none'
                : 'text-decoration-underline cursor-pointer'">Jour</span> |
        <span @click="viewBy('week')"
              :class="actualView == 'week'
                ? 'font-weight-black pointer-event-none'
                : 'text-decoration-underline cursor-pointer'">Semaine</span> |
        <span @click="viewBy('month')"
              :class="actualView == 'month'
              ? 'font-weight-black pointer-event-none'
              : 'text-decoration-underline cursor-pointer'">Mois</span>
      </div>

    </div>
    <span class="ml-4 text-decoration-underline">Nombre de dossiers</span>
    <apexchart
        ref="apexchartsOne"
        type="area"
        :height="series[0].length > 0 ? chartHeight : 0"
        :options="options"
        :series="series[0]"
        v-if="series[0].length > 0"
    ></apexchart>
    <span class="ml-4 text-decoration-underline">Chiffre d'affaire</span>
    <apexchart
        ref="apexchartsTwo"
        type="area"

        :height="series[1].length > 0 ? chartHeight : 0"
        :options="options2"
        :series="series[1]"
        v-if="series[1].length > 0"

    ></apexchart>
    <span class="ml-4 text-decoration-underline">Marge Brute</span>
    <apexchart
        ref="apexchartsThree"
        type="area"
        id="apexchartsThree"
        :height="series[2].length > 0 ? chartHeight : 0"
        :options="options3"
        :series="series[2]"
        v-if="series[2].length > 0"
    ></apexchart>

  </v-card>
</template>
<script>
import {getDayOfYear, getDaysInYear, isValid} from "date-fns";
import _ from "lodash";
import {post, get} from "@/utils/api";
import {EventBus} from "@/components/commons/event-bus";
import {ref} from "vue";

export default {
  name: "HotelFlightsCombinedChart",
  props: {
    form: {type: Object, required: true},
    conf: {type: Object, required: false, default: null}
  },
  data() {
    return {
      vCardRef: ref(null),
      url: `/api/widget/dashboard/date/sales/hotel/flights`,
      actualView: 'month',
      importantsDate: [],
      options: {
        colors: ['rgb(90, 158, 245)', '#E91E63'],
        series: [],
        stroke: {
          show: true,
          curve: 'smooth',
          lineCap: 'butt',
          width: 3,
          dashArray: 0,
        },
        chart: {
          group: "social",
          id: "chart1",
          defaultLocale: 'fr',
          datetimeUTC: false,
          type: "area",
          stacked: false,
          height: 350,
          zoom: {
            enabled: false,
            autoScaleYaxis: true,
          },
          locales: [{
            name: "fr",
            options: {
              months: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Aout", "Septembre", "Octobre", "Novembre", "Décembre"],
              shortMonths: ["Jan", "Fev", "Mar", "Avr", "Mai", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
              days: ["Dimanche", "Lundi", "Merdi", "Mercredi", "Jeudi", "Vendredi", "Samedi"],
              shortDays: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
              toolbar: {
                exportToSVG: "Télécharger SVG",
                exportToPNG: "Télécharger PNG",
                menu: "Menu",
                selection: "Selection",
                selectionZoom: "Selection Zoom",
                zoomIn: "Zoom In",
                zoomOut: "Zoom Out",
                pan: "Panning",
                reset: "Reset Zoom"
              }
            }
          }],
        },
        yaxis: {
          type: 'numeric',
          decimalsInFloat: false,
          labels: {
            minWidth: 40
          }
        },
        xaxis: {
          floating: false,
          position: 'bottom',
          axisTicks: {
            show: true,
          },
          min: new Date(`${new Date().getFullYear()}-01-01`),
          max: new Date(`${new Date().getFullYear()}-12-31`),
          type: 'datetime',
          tickAmount: 'dataPoints',
          labels: {
            datetimeUTC: false,
            show: true,
            datetimeFormatter: {
              year: 'yyyy',
              month: 'MMMM ',
              day: 'dd MMMM',
              hour: 'HH:mm'
            },
            rotate: -45,
            rotateAlways: false,
            hideOverlappingLabels: true,
            showDuplicates: false,
            trim: true,
            minHeight: undefined,
            maxHeight: 120,
            style: {
              fontSize: '12px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 400,
              cssClass: 'apexcharts-xaxis-label',
            },
          }
        },
        dataLabels: {
          enabled: false
        },
        tooltip: {
          x: {
            show: true,
            format: '',
            formatter: undefined,
          },
          fixed: {
            enabled: false,
            position: 'topRight',
            offsetX: 0,
            offsetY: 0,
          },

        },
        fill: {
          type: 'gradient',
        },
        legend: {position: 'bottom'},
        annotations: {
          xaxis: [],
        },
      },
      options2: {
        colors: ['rgb(90, 158, 245)', '#E91E63'],
        series: [],
        stroke: {
          show: true,
          curve: 'smooth',
          lineCap: 'butt',
          width: 3,
          dashArray: 0,
        },
        chart: {
          group: "social",
          id: "chart2",
          defaultLocale: 'fr',
          datetimeUTC: false,
          type: "area",
          stacked: false,
          height: 350,
          zoom: {
            enabled: false,
            autoScaleYaxis: true,
          },
          locales: [{
            name: "fr",
            options: {
              months: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Aout", "Septembre", "Octobre", "Novembre", "Décembre"],
              shortMonths: ["Jan", "Fev", "Mar", "Avr", "Mai", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
              days: ["Dimanche", "Lundi", "Merdi", "Mercredi", "Jeudi", "Vendredi", "Samedi"],
              shortDays: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
              toolbar: {
                exportToSVG: "Télécharger SVG",
                exportToPNG: "Télécharger PNG",
                menu: "Menu",
                selection: "Selection",
                selectionZoom: "Selection Zoom",
                zoomIn: "Zoom In",
                zoomOut: "Zoom Out",
                pan: "Panning",
                reset: "Reset Zoom"
              }
            }
          }],
        },
        yaxis: {
          type: 'numeric',
          decimalsInFloat: false,
          labels: {
            minWidth: 40
          }
        },
        xaxis: {
          floating: false,
          position: 'bottom',
          axisTicks: {
            show: true,
          },
          min: new Date(`${new Date().getFullYear()}-01-01`),
          max: new Date(`${new Date().getFullYear()}-12-31`),
          type: 'datetime',
          tickAmount: 'dataPoints',
          labels: {
            datetimeUTC: false,
            show: true,
            datetimeFormatter: {
              year: 'yyyy',
              month: 'MMMM ',
              day: 'dd MMMM',
              hour: 'HH:mm'
            },
            rotate: -45,
            rotateAlways: false,
            hideOverlappingLabels: true,
            showDuplicates: false,
            trim: true,
            minHeight: undefined,
            maxHeight: 120,
            style: {
              fontSize: '12px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 400,
              cssClass: 'apexcharts-xaxis-label',
            },
          }
        },
        dataLabels: {
          enabled: false
        },
        tooltip: {
          x: {
            show: true,
            format: '',
            formatter: undefined,
          },
          fixed: {
            enabled: false,
            position: 'topRight',
            offsetX: 0,
            offsetY: 0,
          },

        },
        fill: {
          type: 'gradient',
        },
        legend: {position: 'bottom'},
        annotations: {
          xaxis: [],
        },
      },
      options3: {
        colors: ['rgb(90, 158, 245)', '#E91E63'],
        series: [],
        stroke: {
          show: true,
          curve: 'smooth',
          lineCap: 'butt',
          width: 3,
          dashArray: 0,
        },
        chart: {
          group: "social",
          id: "chart3",
          defaultLocale: 'fr',
          datetimeUTC: false,
          type: "area",
          stacked: false,
          height: 350,
          zoom: {
            enabled: false,
            autoScaleYaxis: true,
          },
          locales: [{
            name: "fr",
            options: {
              months: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Aout", "Septembre", "Octobre", "Novembre", "Décembre"],
              shortMonths: ["Jan", "Fev", "Mar", "Avr", "Mai", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
              days: ["Dimanche", "Lundi", "Merdi", "Mercredi", "Jeudi", "Vendredi", "Samedi"],
              shortDays: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
              toolbar: {
                exportToSVG: "Télécharger SVG",
                exportToPNG: "Télécharger PNG",
                menu: "Menu",
                selection: "Selection",
                selectionZoom: "Selection Zoom",
                zoomIn: "Zoom In",
                zoomOut: "Zoom Out",
                pan: "Panning",
                reset: "Reset Zoom"
              }
            }
          }],
        },
        yaxis: {
          type: 'numeric',
          decimalsInFloat: false,
          labels: {
            minWidth: 40
          }
        },
        xaxis: {
          floating: false,
          position: 'bottom',
          axisTicks: {
            show: true,
          },
          min: new Date(`${new Date().getFullYear()}-01-01`),
          max: new Date(`${new Date().getFullYear()}-12-31`),
          type: 'datetime',
          tickAmount: 'dataPoints',
          labels: {
            datetimeUTC: false,
            show: true,
            datetimeFormatter: {
              year: 'yyyy',
              month: 'MMMM ',
              day: 'dd MMMM',
              hour: 'HH:mm'
            },
            rotate: -45,
            rotateAlways: false,
            hideOverlappingLabels: true,
            showDuplicates: false,
            trim: true,
            minHeight: undefined,
            maxHeight: 120,
            style: {
              fontSize: '12px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 400,
              cssClass: 'apexcharts-xaxis-label',
            },
          }
        },
        dataLabels: {
          enabled: false
        },
        tooltip: {
          x: {
            show: true,
            format: '',
            formatter: undefined,
          },
          fixed: {
            enabled: false,
            position: 'topRight',
            offsetX: 0,
            offsetY: 0,
          },

        },
        fill: {
          type: 'gradient',
        },
        legend: {position: 'bottom'},
        annotations: {
          xaxis: [],
        },
      },
      series: [[], [], []],
      raws: {
        rawDataTableNumber: [],
        rawDataTableCA: [],
        rawDataTableMarkUp: [],
      },
    }
  },
  created() {
    EventBus.$on('editMode', () => this.updateSize())
    EventBus.$on('searchDashboard', () => this.init());
    this.init()
  },
  computed: {
    chartHeight() {
      return this.vCardRef != null ? (this.$refs.vCardRef.$el.clientHeight - 180) / 3 + 'px' : 0;
    },
  },
  mounted() {
    this.vCardRef = this.$refs.vCardRef.$el.clientHeight
    if (this.conf.chartOptions == null) {
      this.conf.chartOptions = this.options
      delete this.conf.chartOptions.xaxis.min
      delete this.conf.chartOptions.xaxis.max
    } else {
      this.options = {...this.options, ...this.conf.chartOptions}
    }
    if (this.conf.chartOptions2 == null) {
      this.conf.chartOptions2 = this.options2
      delete this.conf.chartOptions2.xaxis.min
      delete this.conf.chartOptions2.xaxis.max
    } else {
      this.options2 = {...this.options2, ...this.conf.chartOptions2}
    }

    if (this.conf.chartOptions3 == null) {
      this.conf.chartOptions3 = this.options3
      delete this.conf.chartOptions3.xaxis.min
      delete this.conf.chartOptions3.xaxis.max
    } else {
      this.options3 = {...this.options3, ...this.conf.chartOptions3}
    }
  },
  methods: {
    init() {
      this.series = [[], [], []]
      let periods = {act: this._calculateDates(this.conf.data.act), old: this._calculateDates(this.conf.data.old)}
      if (this.conf.data.act.type != null) {
        periods.act = this._calculateDates(this.conf.data.act)
      }
      if (this.conf.data.old.type != null) {
        periods.old = this._calculateDates(this.conf.data.old)
      }

      const formProps = JSON.parse(JSON.stringify(this.form))
      let searchForm = {
        startDateAct: periods.act != null && periods.act.start ? periods.act.start : formProps.startDateAct,
        stopDateAct: periods.act != null && periods.act.stop ? periods.act.stop : formProps.stopDateAct,
        startDateOld: periods.old != null &&  periods.old.start? periods.old.start : new Date(this.form.startDateOld).getFullYear() + "-01-01",
        stopDateOld: periods.old != null && periods.old.start? periods.old.stop : new Date(this.form.stopDateOld).getFullYear() + "-12-31",
        destinations: this.conf.data.destinations.length > 0 ? this.conf.data.destinations : formProps.destinations,
        sites: this.conf.data.sites.length > 0 ? this.conf.data.sites : formProps.sites,
        commercialIds: [],
        allAgencies: false
      }
      post(this.url, searchForm, {"Content-Type": "application/json"})
          .json(rs => {

            this.raws.rawDataTableNumber = rs.map(r => r[0])
            this.raws.rawDataTableCA = rs.map(r => r[1])
            this.raws.rawDataTableMarkUp = rs.map(r => r[2])
            if (this.conf.view != null) {
              switch (this.conf.view) {
                case 'month':
                  this.viewBy('month')
                  this.actualView = "month"
                  break;
                case "day":
                  this.viewBy('day')
                  this.actualView = "day"
                  break;
                case "week" :
                  this.viewBy('week')
                  this.actualView = "week"
                  break;
              }
            } else {
              this.viewBy('week')
            }

            this.options.xaxis.min = this.raws.rawDataTableNumber.length > 0 ? this.raws.rawDataTableNumber[0].date : new Date(`${new Date(this.form.startDateAct).getFullYear()}-01-01`).toString()
            this.options.xaxis.max = new Date(`${new Date(this.form.startDateAct).getFullYear().toString()}-12-31`).toString()
            let annotationLabel = null
            this.importantsDate = this._getHotelissimaImportantDates()
            this.importantsDate.forEach(data => {
              const dateShowed = new Date(data.date).setFullYear(new Date(data.date).getFullYear() - 1)
              this.options.annotations.xaxis.push({
                x: dateShowed,
                borderColor: '#999',
                yAxisIndex: 0,
                label: {

                  show: true,
                  textAnchor: "middle",

                  orientation: "horizontal",
                  text: "*",
                  mouseEnter: (o, n) => {

                    const rect = self.$refs.apexcharts.$el.getBoundingClientRect()
                    const y = n.y - rect.y + 25
                    const x = n.x - rect.x + 25

                    annotationLabel = document.createElement("div")

                    annotationLabel.style.position = 'absolute';
                    annotationLabel.style.background = '#775DD0';
                    annotationLabel.style.padding = "5px"
                    annotationLabel.style.color = "#fff"
                    annotationLabel.style.top = y + "px"
                    annotationLabel.style.left = x + "px"
                    annotationLabel.style.transform = "translateX(-50%)"
                    annotationLabel.appendChild(document.createTextNode(this._formatDate(new Date(data.date), "dd/MM/yyyy") + " : " + data.name))

                    self.$refs.apexcharts.$el.appendChild(annotationLabel)

                  },
                  mouseLeave: () => {
                    self.$refs.apexcharts.$el.removeChild(annotationLabel)
                  },
                  style: {
                    color: "#fff",
                    background: '#775DD0'
                  }
                }
              })
            })
          })
      const self = this
      this.options.tooltip.custom = function ({series, seriesIndex, dataPointIndex, w}) {
        return self.formatTooltip({series, seriesIndex, dataPointIndex, w}, false)
      }
      this.options2.tooltip.custom = function ({series, seriesIndex, dataPointIndex, w}) {
        return self.formatTooltip({series, seriesIndex, dataPointIndex, w}, true)
      }
      this.options3.tooltip.custom = function ({series, seriesIndex, dataPointIndex, w}) {
        return self.formatTooltip({series, seriesIndex, dataPointIndex, w}, true)
      }
},
chartByMonth()
{
  this.series = [[], [], []]
  let hotelOnly = []
  let hotelFlight = []
  Object.keys(this.raws).forEach((raw, index) => {
    const r = JSON.parse(JSON.stringify(this.raws[raw]))
    hotelOnly = this.groupByMonth(r, "secValue")
    hotelFlight = this.groupByMonth(r, "value")
    this.series[index].push(
        {name: "Hotels Seuls", data: hotelOnly},
        {name: "Hotels + Vols", data: hotelFlight}
    )
  })
}
,
chartByDay()
{
  this.series = [[], [], []]
  Object.keys(this.raws).forEach((raw, index) => {
    const r = JSON.parse(JSON.stringify(this.raws[raw]))

    const dataSec = Object.keys(r).map((key) => {
      const date = this._parseDate(r[key].date, "yyyy-MM-dd")
      return [date, r[key].secValue]
    }).sort((a, b) => {
      return a[0] - b[0]
    })

    const dataFlight = Object.keys(r).map((key) => {
      const date = this._parseDate(r[key].date, "yyyy-MM-dd")
      return [date, r[key].value]
    }).sort((a, b) => {
      return a[0] - b[0]
    })


    this.series[index].push(
        {name: "Hotels Seuls", data: dataSec},
        {name: "Hotels + Vols", data: dataFlight})
  })
}
,
chartByWeek()
{
  this.series = [[], [], []]
  let hotelOnly = []
  let hotelFlight = []
  Object.keys(this.raws).forEach((raw, index) => {
    const r = JSON.parse(JSON.stringify(this.raws[raw]))
    hotelOnly = this.groupByWeek(r, "secValue")
    hotelFlight = this.groupByWeek(r, "value")
    this.series[index].push(
        {name: "Hotels Seuls", data: hotelOnly},
        {name: "Hotels + Vols", data: hotelFlight}
    )
  })
}
,
groupByMonth(list, itemKey)
{
  const yearData = _.groupBy(list, e => {
    return this._parseAndFormatDate(e.date, "yyyy-MM-dd", 'MM')
  })
  return Object.keys(yearData).map((key) => {
    const date = this._parseDate(yearData[key][0].date, "yyyy-MM-dd")
    return [date, _.sumBy(yearData[key], (v) => parseFloat(v[itemKey]))]
  }).sort((a, b) => {
    return a[0] - b[0]
  })
}
,
groupByWeek(list, itemKey)
{
  const yearData = _.groupBy(list, e => {
    const d = this._parseDate(e.date, "yyyy-MM-dd")
    return Math.ceil(
        (getDayOfYear(d) * 52) / getDaysInYear(d)
    )
  })
  return Object.keys(yearData).map((key) => {
    const date = this._parseDate(yearData[key][0].date, "yyyy-MM-dd")
    return [date, _.sumBy(yearData[key], (v) => parseFloat(v[itemKey]))]
  }).sort((a, b) => {
    return a[0] - b[0]
  })
}
,
viewBy(timeUnit)
{
  this.options.tooltip.x.format = null
  this.options.series = []
  this.actualView = timeUnit;
  switch (timeUnit) {
    case 'day': {
      this.options.tooltip.x.format = 'dd MMMM'
      this.chartByDay()
      break;
    }
    case 'month': {
      this.options.tooltip.x.format = 'MMMM'
      this.chartByMonth()
      break;
    }
    case 'week': {
      this.options.tooltip.x.format = 'dd MMMM'
      this.chartByWeek()
      break;
    }

  }
}
,
updateSize()
{
  this.vCardRef = this.$refs.vCardRef != null ? this.$refs.vCardRef.$el.clientHeight : 0
}
,
formatTooltip({series, seriesIndex, dataPointIndex, w}, isPrice)
{
  const valueN = isPrice ? this._formatCurrency(series[0][dataPointIndex], 'EUR') : series[0][dataPointIndex]
  const valueO = isPrice ? this._formatCurrency(series[1][dataPointIndex], 'EUR') : series[1][dataPointIndex]
  const date = seriesIndex == 1
      ? new Date(w.globals.seriesX[seriesIndex][dataPointIndex]).setFullYear(new Date(this.form.startDateAct).getFullYear())
      : new Date(w.globals.seriesX[seriesIndex][dataPointIndex])

  const color = ["rgb(200, 200, 200)", "rgb(90, 158, 245)"]
  if (date !== null && isValid(date) && w.config.series[seriesIndex] != null && series[seriesIndex] != null) {
    let firstLine = ""
    let secondLine = ""
    if (valueN != null && valueN != "") {
      firstLine = `<div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;" >
                  <span class="apexcharts-tooltip-marker" style="background-color: ${color[0]}"></span>
                    <div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
                      <div class="apexcharts-tooltip-y-group d-flex flex-row justify-space-between">
                        <span class="apexcharts-tooltip-text-y-label">${w.config.series[0].name} </span>
                        <span class="apexcharts-tooltip-text-y-value">${valueN}</span>
                      </div>
                    </div>
                  </div>`
    }

    if (valueO != null && valueO != "") {
      secondLine = `  <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">
                  <span class="apexcharts-tooltip-marker" style="background-color: ${color[1]}"></span>
                    <div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
                      <div class="apexcharts-tooltip-y-group d-flex flex-row justify-space-between">
                        <span class="apexcharts-tooltip-text-y-label">${w.config.series[1].name} </span>
                        <span class="apexcharts-tooltip-text-y-value">${valueO}</span>
                      </div>
                    </div>`
    }
    return `
                <div style="left: 663.205px; top: 107.287px;">
                  <div class="apexcharts-tooltip-title" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
                  ${this._formatDate(date, "eee dd MMMM yyyy")}
                  </div>
                  ${firstLine}
                  ${secondLine}
                  </div>
                </div>`
  } else {
    return ""
  }
}
}
}
</script>
<style scoped>
.cursor-pointer {
  cursor: pointer;
}

.pointer-event-none {
  pointer-events: none;
}
</style>
