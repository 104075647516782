<template>
  <layout :drawer="false">
    <template slot="nav-tools">
      <smart-btn @click.native="openDialogNew()" class="mr-1">
        <v-icon>mdi-folder-plus</v-icon>
        <span class="pl-1">Nouveau réseau</span>
      </smart-btn>
    </template>
    <template slot="content">
      <v-container fluid>
        <v-card fluid style="max-width: 800px; margin: auto ">
          <div class="d-flex justify-space-between align-center mx-4 py-6" style="gap: 16px">
            <p style="width: 70%" class="mb-0 font-weight-bold text-h5">Réseaux</p>
            <v-text-field
                style="width: 30%"
                v-model="search"
                append-icon="search"
                class="mt-0 pt-0"
                label="Recherche (Nom)"
                hide-details>
            </v-text-field>
          </div>
          <v-data-table
              :headers="headers"
              :items="items"
              :search="search"
              :sort-by="['name']"
              :disable-pagination="true"
              :hide-default-footer="true">
            <template #item.countByAgency="{ item }">
              <div class="d-flex justify-space-between">
                <div class="d-flex align-center">
                  <span class="mr-1">{{item.countByAgency}}</span>
                  <v-chip @click="openCrm(item)" x-small style="cursor: pointer" v-if="item.countByAgency > 0">
                    <v-icon size="10">mdi-open-in-new</v-icon>
                  </v-chip>
                </div>
                <div class="d-flex align-center">
                  <v-icon @click.native="openDialogEdit(item)" size="16" style="cursor: pointer">mdi-pencil</v-icon>
                  <v-icon @click.native="openDialogConfirmDelete(item)" size="16" style="cursor: pointer">mdi-delete</v-icon>
                </div>
              </div>
            </template>
          </v-data-table>
        </v-card>
        <smart-dialog
            v-model="dialogConfirmDelete"
            title="Supprimer le réseau"
            :width="400">
          <div class="mx-4 mt-4 pb-4">
            <span>Voulez vous supprimer le réseau ?</span>
            <p v-if="countAgency > 0" class="font-weight-bold">{{countAgency}} agence{{countAgency > 1 ? 's seront détachées' : ' sera détachée'}} de ce réseau</p>
            <div class="d-flex justify-end">
              <smart-btn @click.native="deleteNetwork()">
                Confirmer
              </smart-btn>
            </div>
          </div>
        </smart-dialog>
        <smart-dialog
            v-model="dialogEdit"
            title="Editer le réseau"
            :width="400">
          <div class="mx-4 mt-4 pb-4">
            <v-text-field
                v-model="editItemName"
                :error="errorEditInput">
            </v-text-field>
            <div class="d-flex justify-end">
              <smart-btn @click.native="editNetwork()">
                Enregister
              </smart-btn>
            </div>
          </div>
        </smart-dialog>
        <smart-dialog
            v-model="dialogNew"
            title="Ajouter un réseau">
          <div class="mx-4 mt-4 pb-4">
            <div class="d-flex mb-4" style="gap: 16px" v-if="errorFormNew">
              <v-icon color="red" size="16">mdi-alert-circle</v-icon>
              <span class="red--text font-weight-bold">Veuillez remplir correctement les champs</span>
            </div>
            <div class="d-flex" style="gap: 16px">
              <v-text-field
                  v-model="formNew.name"
                  style="width: 50%"
                  label="Nom du réseau">
              </v-text-field>
              <v-select
                  v-model="formNew.langId"
                  label="Langue du réseau"
                  :items="networkOrigin"
                  style="width: 50%">
              </v-select>
            </div>
            <div class="d-flex justify-end">
              <smart-btn @click.native="newNetwork()">
                Ajouter
              </smart-btn>
            </div>
          </div>
        </smart-dialog>
      </v-container>
    </template>
  </layout>
</template>
<script>
import Layout from "@/views/Layout";
import { get, post } from "@/utils/api";
import {EventBus} from "@/components/commons/event-bus";


export default {
  name: "Networks",
  components: {
    Layout
  },
  data(){
    return {
      headers: [
        {
          text:"Nom",
          value:"name",
          width: "50%",
        },
        {
          text:"Nombre d'agence",
          value:"countByAgency",
          width: "50%"
        }
      ],
      items:[],
      search: "",
      dialogConfirmDelete: false,
      dialogEdit: false,
      editItemName: "",
      editItem: null,
      errorEditInput: false,
      countAgency: null,
      dialogNew: false,
      networkOrigin: [
        {
          text: "Français",
          value: 1,
        },
        {
          text: "Italien",
          value: 4,
        }
      ],
      formNew: {
        name: null,
        langId : null
      },
      errorFormNew: false
    }
  },
  created() {
    this.init()
  },
  methods: {
    init(){
      const url = "/api/networks/allAndAgencyByNetworks"
      get(url)
          .badRequest((br) => {
            console.log("Error: ", br)
          })
          .json((data) => {
            this.items = data
          })
    },
    openDialogConfirmDelete(item){
      this.setItem(item)
      this.dialogConfirmDelete = true
    },
    async deleteNetwork(){
      const url = "/api/networks/delete"
      await post(url, this.editItem)
          .badRequest((br) => {
            EventBus.$emit("toaster-error", "Erreur lors de la suppression du réseau");
            console.log("Error: ", br)
          })
          .res((res) => {
            EventBus.$emit("toaster-msg", "Réseau supprimé");
          })
      this.dialogConfirmDelete = false
      this.init()
    },
    openDialogEdit(item){
      this.setItem(item)
      this.errorEditInput = false
      this.editItemName = this.editItem.name
      this.dialogEdit = true
    },
    async editNetwork(){
      const url = "/api/networks/edit"
      if(this.editItemName != ""){
        this.errorEditInput = false
        await post(url, this.editItem)
            .badRequest((br) => {
              EventBus.$emit("toaster-error", "Erreur lors de la modification du réseau");
              console.log("Error: ", br)
            })
            .res((res) => {
              EventBus.$emit("toaster-msg", "Réseau modifié");
            })
        this.dialogEdit = false
        this.init()
      } else {
        this.errorEditInput = true
      }
    },
    setItem(item){
      this.editItem = {
        id: item.id,
        name:item.name
      }
      this.countAgency = item.countByAgency
    },
    openCrm(item){
      const crmSearch = {
        "search":null,
        "searchByMailAndLogin":null,
        "regions":[],
        "networks":[item.id],
        "status":"validated",
        "withFlight":false,
        "potential":[]
      }
      this.$router.push({name: 'Crm', query:{search:JSON.stringify(crmSearch)}})
    },
    openDialogNew(){
      this.formNew.name = null
      this.formNew.langId = null
      this.dialogNew = true
    },
    async newNetwork(){
      if(this.formNew.name != null && this.formNew.name != "" && this.formNew.langId != null){
        this.errorFormNew = false
        const url = "/api/networks/new"
        await post(url, this.formNew)
            .badRequest((br) => {
              EventBus.$emit("toaster-error", "Erreur lors de la création du réseau");
              console.log("Error: ", br)
            })
            .res((res) => {
              EventBus.$emit("toaster-msg", "Réseau créé");
            })
        this.dialogNew = false
        this.init()
      } else {
        this.errorFormNew = true
      }
    }
  },
  watch: {
    editItemName: {
      immediate: true,
      handler(newVal) {
        if(newVal){
          this.editItem.name = newVal
        }
      }
    }
  }
}
</script>