<template>
  <v-row dense class="content-pdf" v-if="contrat">
    <v-col cols="12" class="bi-avoid">
      <v-row dense class="page header">
        <v-col class="pr-1" cols="6">
          <div class="tile">
            <div class="tile-title">{{ $t('document.bulletin.header.label') }}</div>
            <div class="tile-content">
              {{ $t('document.bulletin.header.name') }}
              <br/>
              {{ $t('document.bulletin.header.address') }}
              <br/>
              {{ contrat.sitePhoneNumber }}
              <br/>
              {{ contrat.siteEmail }}
            </div>
          </div>
        </v-col>
        <v-col cols="6">
          <div class="tile">
            <div class="tile-title">{{ $t('document.bulletin.customer.label') }}</div>
            <div class="tile-content">
              {{ contrat.customer.customerName }}
              <br/>
              {{ contrat.customer.customerAddress }} {{ contrat.customer.customerPostalCode }}
              {{ contrat.customer.customerCity }}
              <br/>
              {{ contrat.customer.customerPhone }}
              <br/>
              {{ contrat.customer.customerEmail }}
            </div>
          </div>
        </v-col>
        <v-col cols="12" class="bi-avoid">
          <div class="tile">
            <div class="tile-title">{{ $t('document.bulletin.details.label') }}</div>
            <div class="tile-content">
              <span class="tile-label">{{ $t('document.bulletin.details.organisator') }} :</span>HOTELISSIMA
              <br/>
              <v-row dense>
                <v-col cols="4">
                  <span class="tile-label">{{ $t('document.bulletin.details.startDate') }} :</span>
                  {{ _parseAndFormatDate(contrat.startdate, 'dd/MM/yyyy HH:mm:ss', _datePattern.localDate) }}
                </v-col>
                <v-col cols="4">
                  <span class="tile-label">{{ $t('document.bulletin.details.stopDate') }} :</span>
                  {{
                    _parseAndFormatDate(contrat.stopdate, 'dd/MM/yyyy HH:mm:ss', _datePattern.localDate)
                  }}
                </v-col>
                <v-col
                    cols="12"
                    v-for="(agent, i) in contrat.onPlaceAgent"
                    :key="'agent-head-' + i"
                >
                  <span class="tile-label">{{ $t('document.bulletin.details.destination') }} :</span>
                  {{ agent.destinationName }}
                  <br/>
                  <span class="tile-label">{{ $t('document.bulletin.details.inplaceAgent') }} :</span>
                  {{ agent.name }} {{ agent.tel }}
                </v-col>
              </v-row>
            </div>
          </div>
        </v-col>
        <v-col cols="12" class="bi-avoid">
          <div class="tile">
            <div class="tile-title">{{ $t('document.bulletin.guests.label') }}</div>
            <div xs12 class="tile-content">
              <v-row dense>
                <v-col cols="12" class="bi-avoid">
                  <v-row dense>
                    <v-col cols="4">
                      <span class="tile-label">{{ $t('document.bulletin.guests.lastname') }}</span>
                    </v-col>
                    <v-col cols="4">
                      <span class="tile-label">{{ $t('document.bulletin.guests.firstname') }}</span>
                    </v-col>
                    <v-col cols="4">
                      <span class="tile-label">{{ $t('document.bulletin.guests.birthdate') }}</span>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" class="bi-avoid">
                  <v-row
                      dense
                      v-for="(guest, i) in contrat.globalGuests"
                      :key="'globalGuests-' + i"
                  >
                    <v-col cols="4">{{ guest.lastName }}</v-col>
                    <v-col cols="4">{{ guest.firstName }}</v-col>
                    <v-col
                        cols="4"
                        v-if="guest.birthDate"
                    >{{ _parseAndFormatDate(guest.birthDate, 'dd/MM/yyyy', _datePattern.localDate) }}
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="12" class="bi-avoid content-sup">
                  <br/>
                  {{ $t('document.bulletin.guests.details') }}
                </v-col>
              </v-row>
            </div>
          </div>
        </v-col>
      </v-row>

      <v-row dense class="page block">
        <v-col cols="12" class="bi-avoid">
          <div class="tile">
            <div class="tile-title">Vols</div>
            <div class="tile-content">
              <v-row dense v-for="(flight, i) in contrat.flights" :key="'flight-' + i">
                <v-col cols="12" class="bi-avoid" v-if="i == 0">{{ $t('document.bulletin.flights.go') }}</v-col>
                <v-col
                    cols="12"
                    v-else-if="i == contrat.flights.length - 1"
                >{{ $t('document.bulletin.flights.back') }}
                </v-col>
                <v-col cols="12" class="bi-avoid" v-else>{{ $t('document.bulletin.flights.betweenIslands') }}</v-col>

                <v-col cols="12" class="bi-avoid">
                  <span
                      class="tile-label"
                  >{{ _parseAndFormatDate(flight.startDate, _datePattern.localDateTime, _datePattern.full) }}</span>
                  <br/>
                  {{ $t('document.bulletin.flights.start') }}:
                  {{ _parseAndFormatDate(flight.startDate, datePatternConfig.serverLocalDateTime, _datePattern.time) }}
                  {{ $t('document.bulletin.flights.from') }} {{ flight.fromCity }}
                  ({{ flight.fromAirportCode }})
                  -
                  {{ $t('document.bulletin.flights.arrival') }}:
                  {{ _parseAndFormatDate(flight.stopDate, datePatternConfig.serverLocalDateTime, _datePattern.time) }}
                  {{ $t('document.bulletin.flights.from') }} {{ flight.toCity }}
                  ({{ flight.toAirportCode }})
                  -
                  {{ $t('document.bulletin.flights.flight') }} {{ flight.flightNumber }} - {{ flight.cie }}
                  <span
                      v-i="flight.travelClass"
                  >- {{ $t('document.bulletin.flights.class') }}: {{ $t(flight.travelClass) }}</span>
                  <br/>
                  <br/>
                </v-col>

                <v-col
                    cols="12"
                    class="content-sup"
                    v-if="i == contrat.flights.length - 1"
                >{{ $t('document.bulletin.flights.details') }}
                </v-col>
              </v-row>
              <v-row dense v-if="contrat.flights.length == 0">
                <v-col cols="12" class="bi-avoid">{{ $t('document.bulletin.flights.empty') }}</v-col>
              </v-row>
            </div>
          </div>
        </v-col>

        <v-col cols="12" class="bi-avoid" v-for="(hotel, i) in contrat.hotels" :key="'hotels-' + i">
          <v-row dense>
            <v-col cols="12" class="bi-avoid" v-for="(room, i) in hotel.rooms" :key="'room-' + i">
              <div class="tile">
                <div class="tile-title">{{ $t('document.bulletin.accomodation.label') }}</div>
                <div class="tile-content">
                  <span class="tile-label">{{ $t('document.bulletin.accomodation.name') }} :</span>
                  {{ hotel.name }} {{ hotel.destination }}
                  <br/>
                  <v-row dense>
                    <v-col cols="4">
                      <span class="tile-label">{{ $t('document.bulletin.accomodation.start') }} :</span>
                      {{
                        _parseAndFormatDate(hotel.hotelStartDate,
                            datePatternConfig.serverLocalDateTime,
                            _datePattern.full)
                      }}
                    </v-col>
                    <v-col cols="4">
                      <span class="tile-label">{{ $t('document.bulletin.accomodation.end') }} :</span>
                      {{
                        _parseAndFormatDate(hotel.hotelStopDate,
                            datePatternConfig.serverLocalDateTime,
                            _datePattern.full)
                      }}
                    </v-col>
                    <v-col cols="4">
                      <span class="tile-label">{{ $t('document.bulletin.accomodation.nbNights') }} :</span>
                      {{
                        _nbNights(_parseDate(hotel.hotelStartDate, datePatternConfig.serverLocalDateTime),
                            _parseDate(hotel.hotelStopDate, datePatternConfig.serverLocalDateTime))
                      }}
                      <br/>
                    </v-col>
                  </v-row>

                  <span class="tile-label">{{ $t('document.bulletin.accomodation.room') }} :</span>
                  {{ room.room }} {{ $t('document.bulletin.accomodation.for') }}
                  {{ $tc('Adult-count', countAdult(room.guests), {size: countAdult(room.guests)}) }}
                  <span
                      v-if="countChild(room.guests) > 0"
                  >{{ $tc('Child-count', countChild(room.guests), {size: countChild(room.guests)}) }}</span>
                  <br/>
                  <span class="tile-label">{{ $t('document.bulletin.accomodation.meal') }} :</span>
                  {{ $t('document.meal.' + slugify(room.meal)) }}
                  <br/>
                  <div class="content-sup" v-html="room.mealDescription"></div>
                  <br/>
                  <div v-if="room.extras.length > 0">
                    <span class="tile-label">{{ $t('document.bulletin.accomodation.extra') }} :</span>
                    <br/>
                    <div class="content-sup" v-for="extra in room.extras" :key="extra">
                      {{ extra }}
                      <br/>
                    </div>
                  </div>

                </div>
              </div>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" class="bi-avoid">
          <div class="tile">
            <div class="tile-title">{{ $t('document.bulletin.transfer.label') }}</div>
            <v-row dense class="tile-content" v-if="contrat.transfers.length > 0">
              <v-col cols="12" class="bi-avoid">
                <v-row dense>
                  <v-col cols="6">
                    <span class="tile-label">{{ $t('document.bulletin.transfer.type') }}</span>
                  </v-col>
                  <v-col cols="3">
                    <span class="tile-label">{{ $t('document.bulletin.transfer.departure') }}</span>
                  </v-col>
                  <v-col cols="3">
                    <span class="tile-label">{{ $t('document.bulletin.transfer.arrival') }}</span>
                  </v-col>
                </v-row>
                <v-row dense v-for="(transfer, i) in contrat.transfers" :key="'transfers-' + i">
                  <v-col cols="6">{{ transfer.name }}</v-col>
                  <v-col
                      cols="3"
                  >{{ _parseAndFormatDate(transfer.checkin, datePatternConfig.serverLocalDateTime, _datePattern.full) }}
                  </v-col>
                  <v-col
                      cols="3"
                  >{{
                      _parseAndFormatDate(transfer.checkout, datePatternConfig.serverLocalDateTime, _datePattern.full)
                    }}
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <div v-else>{{ $t('document.bulletin.transfer.details') }}</div>
          </div>
        </v-col>

        <v-col cols="12" class="bi-avoid">
          <div class="tile">
            <div class="tile-title">{{ $t('document.bulletin.carrental.label') }}</div>
            <v-row dense class="tile-content" v-if="contrat.carrentals.length > 0">
              <v-col cols="12" class="bi-avoid">
                <v-row dense>
                  <v-col cols="6">
                    <span class="tile-label">{{ $t('document.bulletin.transfer.type') }}</span>
                  </v-col>
                  <v-col cols="3">
                    <span class="tile-label">{{ $t('document.bulletin.transfer.departure') }}</span>
                  </v-col>
                  <v-col cols="3">
                    <span class="tile-label">{{ $t('document.bulletin.transfer.arrival') }}</span>
                  </v-col>
                </v-row>
                <v-row dense v-for="(carrental, i) in contrat.carrentals" :key="'transfers-' + i">
                  <v-col cols="6">
                    {{ carrental.name }}
                    <br/>
                    <span
                        v-if="carrental.options && carrental.options != ''"
                    >{{ $t('document.bulletin.carrental.options') }}: {{ carrental.options }}</span>
                  </v-col>
                  <v-col
                      cols="3"
                  >{{
                      _parseAndFormatDate(carrental.checkin, datePatternConfig.serverLocalDateTime, _datePattern.full)
                    }}
                  </v-col>
                  <v-col
                      cols="3"
                  >{{
                      _parseAndFormatDate(carrental.checkout, datePatternConfig.serverLocalDateTime, _datePattern.full)
                    }}
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <div v-else>{{ $t('document.bulletin.carrental.details') }}.</div>
          </div>
        </v-col>

        <v-col cols="12" class="bi-avoid" v-if="contrat.flights.length > 0">
          <div class="tile">
            <div class="tile-title">{{ $t('document.bulletin.insurance.label') }}</div>
            <div class="tile-content">{{ $t('document.bulletin.insurance.infos') }}</div>
          </div>
        </v-col>

        <template v-if="contrat.untypeds">
          <v-col cols="12" class="bi-avoid" v-for="(untype, index) in contrat.untypeds" :key="index">
            <div class="tile">
              <div class="tile-title">{{ untype }}</div>
            </div>
          </v-col>
        </template>

        <v-col cols="12" class="bi-avoid">
          <div class="tile">
            <div class="tile-title">{{ $t('document.bulletin.insurance.label') }}</div>
            <v-row dense class="tile-content" v-if="contrat.insurances.length > 0">
              <v-col cols="12" class="bi-avoid">
                <v-row dense>
                  <v-col cols="12" class="bi-avoid">
                    <span class="tile-label">Type</span>
                  </v-col>
                </v-row>
                <v-row dense v-for="(insurance, i) in contrat.insurances" :key="'insurances-' + i">
                  <v-col cols="12" class="bi-avoid">
                    {{ insurance[0] }}
                    <br/>
                    {{ $t('document.bulletin.insurance.contract') }}
                    : {{ insurance[1] }}
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <div v-else>
              <v-col cols="12" class="bi-avoid">{{ $t('document.bulletin.insurance.details') }}</v-col>
            </div>
          </div>
        </v-col>

        <v-col cols="12" class="bi-avoid">
          <div class="tile">
            <div class="tile-title">{{ $t('document.cancelfees.label') }}</div>
            <div class="tile-content">
              <div>{{ $t('document.cancelfees.infos') }}</div>

              <ul>
                <li v-for="(fee, i) in cancelFees" :key="'fee-' + i">{{ fee }}</li>
              </ul>

              <div v-if="contrat.insurances.length>0">
                Les assurances souscrites ({{ contrat.insurances.reduce((acc, i) => acc + i[2], 0) }} €) ne sont pas
                remboursables
              </div>
            </div>
          </div>
        </v-col>

        <v-col cols="12" class="bi-avoid">
          <div class="tile" v-for="(agent, i) in contrat.onPlaceAgent" :key="'agent-' + i">
            <div
                class="tile-title"
            >{{ $t('document.bulletin.police.label') }} ({{ agent.destinationName }})
            </div>
            <div class="tile-content" v-html="agent.policy"></div>
          </div>
        </v-col>

        <v-col cols="12" class="bi-avoid">
          <div class="tile">
            <div class="tile-title">{{ $t('document.bulletin.price.label') }}</div>
            <div class="tile-content">
              <v-row dense>
                <v-col cols="9">
                  <span class="tile-label">{{ $t('document.bulletin.price.prestation') }}</span>
                </v-col>
                <v-col cols="3" class>
                  <span class="tile-label">{{ $t('document.bulletin.price.amount') }}</span>
                </v-col>
                <v-col cols="9">{{ buildPresta() }}</v-col>
                <v-col cols="3" class>{{ contrat.amountTTC }}</v-col>

                <v-col cols="4">
                  <span class="tile-label">{{ $t('document.bulletin.price.paymentType') }}</span>
                </v-col>
                <v-col cols="5">
                  <span class="tile-label">{{ $t('document.bulletin.price.payDate') }}</span>
                </v-col>
                <v-col cols="3" class>
                  <span class="tile-label">{{ $t('document.bulletin.price.amount') }}</span>
                </v-col>
              </v-row>
              <v-row dense v-for="(p, i) in contrat.payments" :key="'payment' + i">
                <v-col cols="4">{{ $t('paymentTypes.' + p.paymentType) }}</v-col>
                <v-col cols="5">{{ p.paymentDate }}</v-col>
                <v-col cols="3">{{ p.amount }}</v-col>
              </v-row>
              <v-row dense v-if="contrat.balancedPaymentDate">
                <v-col cols="12" class="bi-avoid">
                  <span class="tile-label">{{ $t('document.bulletin.price.balanceLimit') }}</span>
                  {{ contrat.balancedPaymentDate }}
                </v-col>
              </v-row>
            </div>
          </div>
        </v-col>

        <v-col cols="12" class="bi-avoid">
          <div class="tile">
            <div class="tile-title">{{ $t('document.bulletin.contact.label') }}</div>
            <div class="tile-content">
              <v-row dense class>
                <v-col cols="12" class="bi-avoid">{{ $t('document.bulletin.contact.details') }}</v-col>
              </v-row>
              <v-row dense class>
                <v-col cols="6">
                  <div>
                    <span class="tile-label">{{ $t('document.bulletin.contact.inplaceAgent') }}</span>
                  </div>

                  <div v-for="(agent, i) in contrat.onPlaceAgent" :key="'agent-' + i">
                    {{ agent.name }}
                    <br/>
                    {{ agent.tel }}
                  </div>
                </v-col>
                <v-col cols="6">
                  <div>
                    <span class="tile-label">{{ $t('document.bulletin.contact.infos.label') }}</span>
                  </div>
                  <div>
                    {{ $t('document.bulletin.contact.infos.name') }}
                    <br/>
                    {{ $t('document.bulletin.contact.infos.phone') }}
                    <br/>
                    {{ $t('document.bulletin.contact.infos.email') }}
                    <br/>
                  </div>
                </v-col>
              </v-row>
            </div>
          </div>
        </v-col>

        <v-col cols="12" class="bi-avoid">
          <div class="tile">
            <div class="tile-title">{{ $t('document.bulletin.pmr.label') }}</div>
            <div class="tile-content">
              <v-row dense>
                <v-col cols="12" class="bi-avoid">{{ $t('document.bulletin.pmr.details') }}</v-col>
                <v-col cols="12" class="bi-avoid tile-label">Vols :</v-col>
                <v-col cols="12" class="bi-avoid">{{ $t('document.bulletin.pmr.detailsFlights') }}</v-col>
                <v-col cols="12" class="bi-avoid" v-for="(hotel, i) in contrat.hotels" :key="'hotels-pmr-' + i">
                  <v-row dense>
                    <v-col cols="12" class="bi-avoid tile-label">Hotel {{ hotel.name }} :</v-col>
                    <v-col cols="12" class="bi-avoid" v-for="(room, i) in hotel.rooms" :key="'room-pmr-' + i">
                      <span v-if="room.isPmrReady">{{ $t('document.bulletin.pmr.pmrReady') }}</span>
                      <span v-else>{{ $t('document.bulletin.pmr.pmrNotReady', {'0': room.room}) }}</span>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" class="bi-avoid" v-for="(hotel, i) in contrat.hotels" :key="'hotels-transfer-' + i">
                  <v-row dense>
                    <v-col cols="12" class="bi-avoid tile-label">{{ $t('document.bulletin.pmr.transfer') }} :</v-col>
                    <v-col cols="12" class="bi-avoid">{{ $t('document.bulletin.pmr.detailsTransfer') }}</v-col>
                  </v-row>
                </v-col>
              </v-row>
            </div>
          </div>
        </v-col>

        <v-col cols="12" class="bi-avoid">
          <div class="tile">
            <div class="tile-title">{{ $t('document.bulletin.claim.label') }}</div>
            <div class="tile-content">
              <div class="row">
                <v-col cols="12" class="bi-avoid">{{ $t('document.bulletin.claim.details') }}</v-col>
              </div>
            </div>
          </div>
        </v-col>

        <v-col cols="12" class="bi-avoid with-bottom-margin">
          <div class="tile">
            <div class="tile-title">{{ $t('document.bulletin.guarant.label') }}</div>
            <div class="tile-content">
              <div class="row">
                <v-col cols="12" class="bi-avoid">
                  {{ $t('document.bulletin.guarant.name', {'0': guarantName}) }}
                  <br/>
                  {{ $t('document.bulletin.guarant.address', {'0': guarantContact}) }}
                </v-col>
              </div>
            </div>
          </div>
        </v-col>

        <v-col cols="12" class="bi-avoid">
          <div class="tile with-bottom-margin">
            <div class="tile-title">{{ $t('document.bulletin.contract.label') }}</div>
            <div class="tile-content">
              <div class="row">
                <v-col cols="12" class="bi-avoid">{{ $t('document.bulletin.contract.details') }}</v-col>
              </div>
            </div>
          </div>
        </v-col>

        <v-col cols="12" class="bi-avoid">
          <div class="tile">
            <div class="tile-title">{{ $t('document.bulletin.infos.label') }}</div>
            <div class="tile-content">
              <div class="row">
                <v-col cols="12" class="bi-avoid">
                  <p v-html="$t('document.bulletin.infos.details1')"></p>
                  <p>{{ $t('document.bulletin.infos.details2') }}</p>
                </v-col>
              </div>
            </div>
          </div>
        </v-col>

        <v-col cols="12" class="bi-avoid">
          <div class="tile">
            <div
                class="tile-content with-min-height"
            >{{ $t('document.bulletin.signature.sign', {'0': _nowFormatted(_datePattern.localDate)}) }}
            </div>
          </div>
        </v-col>
      </v-row>

      <v-row dense class="content cgv" v-if="contrat.cgvTitle && contrat.cgvContent">
        <v-col cols="12" class="bi-avoid">
          <h4>annexe 1: {{ contrat.cgvTitle }}</h4>
        </v-col>
        <v-col cols="12" class="bi-avoid " v-html="contrat.cgvContent"></v-col>
      </v-row>

      <span id="loaded"></span>
    </v-col>
  </v-row>
</template>

<script>
import {get} from "@/utils/api";
import GuestsMixin from "@/components/mixins/GuestsMixin";

export default {
  name: "Bulletin",
  mixins: [GuestsMixin],
  props: {
    bookingid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      contrat: null,
      cancelFees: [],
    };
  },
  created() {

    this.init();
  },
  watch: {},
  computed: {
    guarant() {
      return this.contrat.guarant ? JSON.parse(this.contrat.guarant) : {
        name: 'APST',
        contact: '15 Avenue Carnot, 75017 Paris'
      }
    },
    guarantName() {
      return this.guarant.name;
    },
    guarantContact() {
      return this.guarant.contact
    }
  },
  methods: {
    init() {
      const url = `/api/document/bulletin/${this.bookingid}`;

      return get(url)
          .badRequest((err) => {
            console.error(err);
          })
          .json((data) => {
            this.contrat = data;
            this.$locale = this.contrat.lang
            this.cancelFees = this.buildCancelFeesLabel(this.contrat.cancelFees);
          });
    },
    buildCancelFeesLabel(cancelFees) {
      return cancelFees.map((fee, i) => {
        const from = this._parseDate(fee.start, "dd/MM/yyyy");
        const to = this._parseDate(fee.stop, "dd/MM/yyyy");

        if (i == 0) {
          return this.$t(
              'document.cancelfees.from',
              {
                '0': this._formatDate(from, "dd/MM/yyyy"),
                '1': this._formatDate(to, "dd/MM/yyyy"),
                '2': fee.price
              }
          );
        } else {
          return this.$t(
              'document.cancelfees.between',
              {
                '0': this._formatDate(from, "dd/MM/yyyy"),
                '1': this._formatDate(to, "dd/MM/yyyy"),
                '2': fee.price
              }
          );
        }
      });
    },
    buildPresta() {
      const vol =
          this.contrat.flights && this.contrat.flights.length > 0 ? "Vol + " : "";
      const transfer =
          this.contrat.transfers && this.contrat.transfers.length > 0
              ? "Transfert + "
              : "";

      const hotelWithMeal = this.contrat.hotels
          .map((hotel) => {
            const nb = this._nbNights(
                this._parseDate(
                    hotel.hotelStartDate,
                    this._datePattern.localDateTime
                ),
                this._parseDate(
                    hotel.hotelStopDate,
                    this._datePattern.localDateTime
                )
            );

            const meal = hotel.rooms[0].meal || "";
            return `${this.$tc("nights", nb, {size: nb})} en ${meal}`;
          })
          .join(" et ");

      return `${vol} ${transfer} ${hotelWithMeal}`;
    },
  },
};
</script>

<style scoped src="@/assets/pdf/css/default.less" lang="less"></style>
<style scoped lang="less">
.content-pdf {
  height: 100%;
}


.flex,
.tile,
.tile-label,
.tile-content,
.tile-title {
  page-break-inside: avoid;
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
}

.tile {
  position: relative;
  border: 1px solid #afafaf;
  padding: 0;
  margin-bottom: 20px;
}

//.cgv {
//	display: block !important;
//	div {
//		display: block !important;
//	}
//}

.tile-title {
  position: relative;

  width: 100%;
  border-bottom: 1px solid #afafaf;
  text-transform: uppercase;
  padding: 5px;
  font-weight: bold;
  page-break-inside: avoid;
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
}

.tile-content {
  position: relative;
  font-size: 12px;
  padding: 5px;
  page-break-inside: avoid;
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
}

.tile-label {
  color: #5c5c5c;
  font-weight: bold;
}

.with-min-height {
  min-height: 100px;
}

.with-bottom-margin {
  margin-bottom: 5px;
}

.header {
  font-family: Arial, Helvetica, sans-serif;
  color: #5c5c5c;
  position: inherit;

  .logo {
    height: 70px;
    width: 100%;

    img {
      height: 70px;
    }
  }

  .title {
    font-size: 25px;
    text-transform: uppercase;
    font-weight: bold;
    padding-left: 15px;
  }

  .sub-title {
    text-align: center;
    margin: 10px;
    font-size: 16px;
  }
}

.content-sup {
  font-style: italic;
  font-size: 11px;
}

.cgv {
  page-break-before: always;
  page-break-after: avoid;
  font-size: 9px;
  color: #5c5c5c;

  h4 {
    text-transform: uppercase;
    font-weight: bold;
  }

  p {
    font-size: 8px;
    page-break-inside: avoid;
  }
}
</style>
