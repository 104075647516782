<template>
    <div v-if="editionForm">
      <v-form v-on:submit.prevent="saveAgent">
        <v-row class="px-4">
            <v-col cols="12">
                <v-row>
                    <v-col>
                        <v-radio-group
                            v-model="editionForm.active"
                            label="Statut"
                            dense
                            row
                            class="mx-auto"
                        >
                            <v-radio small label="Actif" color="primary" :value="true" dense></v-radio>
                            <v-radio small label="Inactif" color="primary" :value="false" dense></v-radio>
                        </v-radio-group>
                    </v-col>
                    <v-col>
                        <v-radio-group
                            v-model="editionForm.allowIncentive"
                            label="Autoriser les incentives"
                            dense
                            row
                            class="mx-auto"
                        >
                            <v-radio small label="Oui" color="primary" :value="true" dense></v-radio>
                            <v-radio small label="Non" color="primary" :value="false" dense></v-radio>
                        </v-radio-group>
                    </v-col>
                </v-row>
                <v-row class="justify-space-around">
                    <v-col cols="12" md="5">
                        <v-text-field
                            dense
                            label="Nom"
                            :error="checkLastname ? !editionForm.lastname : checkLastname"
                            :rules="rules.nonEmpty()"
                            v-model="editionForm.lastname"
                            autocomplete="off"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="5">
                        <v-text-field
                            dense
                            label="Prénom"
                            :error="checkFirstname ? !editionForm.firstname : checkFirstname"
                            :rules="rules.nonEmpty()"
                            v-model="editionForm.firstname"
                            autocomplete="off"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row class="justify-space-around">
                    <v-col cols="12" md="5">
                        <v-text-field
                            dense
                            label="Téléphone"
                            :error="checkPhone ? !editionForm.phone : checkPhone"
                            :rules="rules.nonEmpty()"
                            v-model="editionForm.phone"
                            type="phone"
                            autocomplete="off"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="5">
                        <v-text-field
                            dense
                            label="Email"
                            :error="checkEmail ? !editionForm.email : checkEmail"
                            :rules="rules.nonEmpty()"
                            v-model="editionForm.email"
                            autocomplete="off"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row class="justify-space-around">
                    <v-col cols="12" md="5">
                        <v-text-field
                            dense
                            label="Login"
                            :error="checkLogin ? !editionForm.login : checkLogin"
                            :rules="rules.nonEmpty()"
                            v-model="editionForm.login"
                            autocomplete="off"
                        ></v-text-field>
                    </v-col>
                  <v-col cols="12" md="5">
                    <v-text-field
                        type="password"
                        dense
                        :rules="editionForm.id > 0 ? [] : rules.nonEmpty()"
                        :error="editionForm.id > 0 ? false : checkPassword ? !editionForm.password : checkPassword"
                        label="Mot de passe"
                        v-model="editionForm.password"
                        autocomplete="off"
                    ></v-text-field>
                  </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row class="flex-wrap-reverse" :class="_isDesktop() ? 'justify-end' : 'justify-center'">
          <v-col cols="12" md="3" class="ml-4">
            <v-row>
              <smart-btn type="submit" class="mx-auto">Enregistrer</smart-btn>
            </v-row>
          </v-col>
        </v-row>
      </v-form>
    </div>
</template>
<script>
import { get, post } from "@/utils/api";
import { EventBus } from "@/components/commons/event-bus";
import FormMixin from "@/components/mixins/FormMixin";
export default {
    mixins: [FormMixin],
    props: {
        value: {
            type: Object, required: true
        }
    },
    data() {
        return {
          editionForm: null,
          checkFirstname:null,
          checkLastname:null,
          checkPhone:null,
          checkEmail:null,
          checkLogin:null,
          checkPassword:null
        }
    },
    created() {
        this.editionForm = this.value
    },
    computed: {
        urlSavingAgent() {
            return this.editionForm && this.editionForm.id && this.editionForm.id > 0
                ? `/api/agency/agent/${this.editionForm.id}/edit`
                : `/api/agency/agent/create`;
        },
    },
    methods: {
        saveAgent() {

          this.checkFirstname = !this.editionForm.firstname
          this.checkLastname = !this.editionForm.lastname
          this.checkPhone = !this.editionForm.phone
          this.checkEmail = !this.editionForm.email
          this.checkLogin = !this.editionForm.login
          this.checkPassword = !this.editionForm.password

          this.editionForm.password = this.editionForm.password == "" ? null : this.editionForm.password

          if(this.editionForm.id > 0 ? this.checkForm() : this.editionForm.password != null){
            const url = this.urlSavingAgent;
            post(url, this.editionForm)
                .badRequest((br) => {
                  console.log("Error: ", br);
                })
                .json((data) => {
                  this.editionForm = data;
                  this.openDialog = false;
                  this.$emit("success", {});
                  EventBus.$emit("toaster-msg", "Agent enregistré");
                });
          }
        },
        checkForm(){
          return !this.checkFirstname && !this.checkLastname && !this.checkPhone && !this.checkEmail
            && !this.checkLogin
        }
    }
}
</script>