<template>
  <v-text-field v-model="localvalue" v-bind="options"/>
</template>

<script>
  export default {
    name: "SmartNumber",
    mixins: [],
    props: {
      value: {
        required: true
      },
    },
    data: () => ({
      localvalue:0,
      options: {

      }
    }),
    watch:{
      '$attrs':{
        deep:true, immediate:true, handler(newVal){
          this.options = {...this.options, ...newVal}
        }
      },
      'value':{ immediate:true, handler(newVal){
          this.localvalue = newVal
      }},
      localvalue(newVal){
        if (newVal){
          this.$emit("input", newVal.toString().replace(/,/g, "."))
        }
      }
    },
    methods:{

    }
  }
</script>
