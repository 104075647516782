import Quotation from '@/views/pdf/Quotation.vue'
import Bulletin from '@/views/pdf/Bulletin.vue'
import Voucher from '@/views/pdf/Voucher.vue'
import Invoice from '@/views/pdf/Invoice.vue'
import Attestation from '@/views/pdf/Attestation.vue'
import AValoir from "@/views/pdf/AValoir";
import FlightTicket from "@/views/pdf/FlightTicket";

const documentRoutes = [
  {
    path: "/pdf/quotation/:bookingid",
    name: "pdf-quotation",
    component: Quotation,
    props: true,
    page: "document.html",
    meta: {
      plainLayout: true,
      unsecure: true,
    },
  },
  {
    path: "/pdf/bulletin/:bookingid",
    name: "pdf-bulletin",
    component: Bulletin,
    props: true,
    meta: {
      plainLayout: true,
      unsecure: true,
    },
  },
  {
    path: "/pdf/voucher/:voucherid",
    name: "pdf-voucher",
    component: Voucher,
    props: true,
    meta: {
      plainLayout: true,
      unsecure: true,
    },
  },
  {
    path: "/pdf/invoice/:bookingid",
    name: "pdf-invoice",
    component: Invoice,
    props: true,
    meta: {
      plainLayout: true,
      unsecure: true,
    },
  },
  {
    path: "/pdf/attestation/:bookingid",
    name: "pdf-invoice",
    component: Attestation,
    props: true,
    meta: {
      plainLayout: true,
      unsecure: true,
    },
  },
  {
    path: "/pdf/toworth/:bookingid",
    name: "pdf-to-worth",
    component: AValoir,
    props: true,
    meta: {
      plainLayout: true,
      unsecure: true,
    },
  },
  {
    path: "/pdf/flight-ticket/:pnr",
    name: "pdf-to-worth",
    component: FlightTicket,
    props: true,
    meta: {
      plainLayout: true,
      unsecure: true,
    },
  },
];

export default documentRoutes
