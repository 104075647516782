<template>
  <div>
    <v-data-table
      dense
      :headers="headers"
      :items="items"
      :loading="loading"
      no-data-text="-"
      hide-default-footer
      :footer-props="{
        'items-per-page-options': rowsPerPageItems,
        'items-per-page-text': 'Lignes par page',
      }"
      class="tbl"
    >
      <template #item.euroRate="{ item, value }">
        <span
          v-if="!hideEuroRate"
          :class="item.euroRate == 'Total' ? 'font-weight-medium' : ''"
        >
          {{ value }}
        </span>
      </template>

      <template
        v-for="m in headersMonths"
        v-slot:[`item.${m.value}`]="{ item, value }"
      >
        <div
          @click="cellClicked(item.euroRate, m.value, value)"
          :key="m.value"
          :class="item.euroRate == 'Total' ? 'font-weight-medium' : ''"
        >
          {{ formattedAmount(value) }}
        </div>
      </template>
      <template #item.totalRow="{ value }">
        <span class="font-weight-medium">{{ formattedAmount(value) }}</span>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  props: {
    rawData: {
      type: Array,
      required: true,
    },
    hideEuroRate: {
      type: Boolean,
      required: false,
      default: false,
    },
    loading: {
      type: Boolean,
      required: false,
    },
  },
  data: () => ({
    rowsPerPageItems: [{ text: "Tous", value: -1 }, 50, 200],
    pagination: {},
  }),
  created() {},
  computed: {
    headersMonths() {
      return this.rawData
        .map((raw) => new Date(raw.period).getTime())
        .filter((s, i, a) => a.indexOf(s) == i)
        .map((d) => {
          const value = this.buildDateHeader(d);
          return {
            text: value,
            value: value,
            sortable: false,
            divider: true,
            align: "right",
          };
        });
    },
    headers() {
      const euroRate = {
        text: "Euro Rate",
        value: "euroRate",
        sortable: false,
        divider: true,
        align: this.hideEuroRate ? " d-none" : "center",
      };

      const total = {
        text: "Total non reglé",
        value: "totalRow",
        sortable: false,
        divider: true,
        align: "right",
      };

      return [...[euroRate], ...[total], ...this.headersMonths];
    },
    euroRates() {
      return this.rawData
        .map((data) => data.euroRate)
        .filter((s, i, a) => a.indexOf(s) == i);
    },
    items() {
      const rows = this.euroRates
        .map((rate) => {
          let row = { euroRate: rate };
          this.headers
            .slice(1, this.headers.length)
            .map((period) => {
              const data = this.rawData.find((d) => {
                return (
                  this.buildDateHeader(d.period) == period.value &&
                  d.euroRate == rate
                );
              });
              return {
                [period.value]: data ? data.amount : "",
              };
            })
            .forEach((cell) => {
              Object.assign(row, cell);
            });

          row.totalRow = this.headersMonths.reduce((sum, h) => {
            return sum + (row[h.value] != "" ? row[h.value] : 0);
          }, 0);

          return row;
        })
        .sort((a, b) => a.euroRate - b.euroRate);

      if (!this.hideEuroRate) {
        const totalRow = {};
        totalRow["euroRate"] = "Total";
        for (const k of this.headersMonths) {
          totalRow[k.value] = rows.reduce(
            (sum, r) => sum + (r[k.value] != "" ? r[k.value] : 0),
            0
          );
        }
        totalRow["totalRow"] = rows.reduce(
          (sum, r) => sum + (r["totalRow"] != "" ? r["totalRow"] : 0),
          0
        );
        return [...rows, totalRow];
      } else {
        return rows;
      }
    },
  },
  methods: {
    buildDateHeader(d) {
      const date = new Date(d);
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      return `${month}-${year}`;
    },
    formattedAmount(amount) {
      return this.$options.filters
        .currency(amount, "EUR", { maximumFractionDigits: 0 })
        .replace("€", "$");
    },
    cellClicked(rate, period, value) {
      this.$emit("cellClicked", {
        rate: rate,
        period: period,
        value: value,
      });
    },
  },
};
</script>
