<template>
  <v-card :color="color" dense :flat="flat" :dark="dark">
    <v-card-text :class="cardclass">
      <v-progress-linear
        v-if="widgetData.loading && !alwaysrender"
        indeterminate
        :color="dark ? 'white' : 'primary'"
        class="mb-0"
      ></v-progress-linear>

      <div
        class="headline"
        :class="headlineclass"
        v-else-if="
          alwaysrender ||
          (widgetData.data && widgetData.data.ok && !widgetData.loading)
        "
      >
        <slot name="content"></slot>
      </div>

      <div class="headline" :class="headlineclass" v-else>
        <slot name="default"></slot>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { post } from "@/utils/api";

export default {
  name: "WidgetContainer",
  mixins: [],
  props: {
    alwaysrender: {
      type: Boolean,
      required: false,
      default: false,
    },
    cardclass: {
      type: String,
      required: false,
      default: "",
    },
    headlineclass: {
      type: String,
      required: false,
      default: "text-center",
    },
    flat: {
      type: Boolean,
      required: false,
      default: true,
    },
    dark: {
      type: Boolean,
      required: false,
      default: true,
    },
    color: {
      type: String,
      required: false,
      default: "white",
    },
    bus: {
      type: Object,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      widgetData: {
        loading: false,
        data: null,
      },
    };
  },
  created() {
    this.$nextTick(() => {
      this.bus.$on("search", (searchform) => {
        this.init(searchform);
      });
    });
  },
  destroyed() {
    this.bus.$off("search");
  },
  watch: {
    ok: {
      immediate: true,
      handler(newVal) {
        this.widgetData.ok = this.alwaysrender;
      },
    },
  },
  computed: {},
  methods: {
    init(searchform) {
      const to = setTimeout(() => {
        //we render progress only if query is too long
        this.widgetData.loading = true;
      }, 500);

      post(this.url, searchform)
        .badRequest((err) => {
          this.widgetData.data = null;
        })
        .json((res) => {
          this.widgetData.data = res;
        })
        .then(() => {
          this.$emit("ready", this.widgetData.data);
          this.bus.$emit("ready", this.widgetData.data);
          this.widgetData.loading = false;
          clearTimeout(to);
        });
    },
  },
};
</script>

<style scoped>
* {
  color: #fff;
}
</style>
