import "core-js/stable";

import Vue from "vue";
import vuetify from "./plugins/vuetify";

import "./utils/mixin";
import "./utils/user";

import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./i18n";
import "@/assets/global.css";
import SmartNumber from "@/components/commons/SmartNumber";
import SmartBtn from "@/components/commons/SmartBtn";
import SmartDialog from "@/components/commons/SmartDialog";
import SmartPicker from "@/components/commons/SmartPicker";
import VueTimeago from "vue-timeago";
import * as VueGoogleMaps from 'vue2-google-maps'


import VueApexCharts from "vue-apexcharts";
import { VueMaskDirective } from "v-mask";
Vue.directive("mask", VueMaskDirective);

Vue.use(VueTimeago, {
  name: "Timeago", // Component name, `Timeago` by default
  locale: "fr", // Default locale
  locales: {
    fr: require("vue-timeago/node_modules/date-fns/locale/fr"),
  },
});

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBsf_jJ4leu9u74xyPfmF3RXl_PL3l6bKY',
    libraries: 'places', // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)
 
    //// If you want to set the version, you can do so:
    // v: '3.26',
  },
 
  //// If you intend to programmatically custom event listener code
  //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  //// you might need to turn this on.
  // autobindAllEvents: false,
 
  //// If you want to manually install components, e.g.
  //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  //// Vue.component('GmapMarker', GmapMarker)
  //// then disable the following:
  // installComponents: true,
})

Vue.config.productionTip = false;

Vue.component("smart-number", SmartNumber);
Vue.component("smart-btn", SmartBtn);
Vue.component("smart-dialog", SmartDialog);
Vue.component("smart-picker", SmartPicker);
Vue.component("apexchart", VueApexCharts);

const appConf = {
  vuetify,
  router,
  store,
  i18n,
  render: (h) => h(App),
  created() {
    window._locale = this.$locale;
  },
};

Object.defineProperty(Vue.prototype, "$locale", {
  get: function () {
    return appConf.i18n.locale;
  },
  set: function (locale) {
    appConf.i18n.locale = locale;
    window._locale = locale;
  },
});

new Vue(appConf).$mount("#app");
