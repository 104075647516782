<template>
  <layout drawerShow :drawerWidth="400">
    <template slot="nav">
      <v-tabs>
        <v-tab :to="{ name: 'supplier-invoice-list' }"
          >Liste des Factures</v-tab
        >
        <v-tab :to="{ name: 'supplier-invoice-imports' }">Imports</v-tab>
        <v-tab :to="{ name: 'supplier-invoice-payment' }">Liste des paiements</v-tab>
      </v-tabs>
    </template>

    <template slot="nav-tools">
      <smart-btn
        class="mx-2 d-none d-sm-flex"
        primary
        :disabled="items.length === 0"
        @click.native="exportCsv"
      >
        <v-icon>mdi-file-check-outline</v-icon>
        <span class="ml-2">Exports</span>
      </smart-btn>
      <smart-btn
        class="mx-2 d-none d-sm-flex"
        primary
        :disabled="selected.length === 0"
        @click.native="dialogs.validate = true"
      >
        <v-icon>mdi-file-export</v-icon>
        <span class="ml-2">Valider les factures ({{ selected.length }})</span>
      </smart-btn>
      <smart-btn
        class="mx-2 d-none d-sm-flex"
        primary
        :disabled="selected.length === 0"
        @click.native="dialogs.pay = true"
      >
        <v-icon>mdi-file-check-outline</v-icon>
        <span class="ml-2">Payer les factures ({{ selected.length }})</span>
      </smart-btn>
      <smart-btn
        class="mx-2 d-none d-sm-flex"
        secondary
        :to="{ name: 'supplier-invoice', params: { id: 'create' } }"
      >
        <v-icon>mdi-file-plus</v-icon>
        <span class="ml-2">Nouvelle Facture</span>
      </smart-btn>
    </template>

    <template slot="drawer">
      <v-container fluid class="pa-0">
        <v-row>
          <v-col cols="12">
            <v-autocomplete
              v-model.number="searchCtx.supplierAccountingId"
              :items="listSuppliers"
              item-value="id"
              item-text="name"
              label="Fournisseur"
              clearable
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="searchCtx.ref"
              label="Réf. Fournisseur"
              placeholder=" "
              hide-details
            />
          </v-col>
          <v-col cols="6">
            <v-select
              v-model="searchCtx.status"
              :items="listStatus"
              label="Statuts"
              hide-details
            >
              <template #item="{ item }">{{
                $t("supplierinvoicestatus." + item)
              }}</template>
              <template #selection="{ item }">{{
                $t("supplierinvoicestatus." + item)
              }}</template>
            </v-select>
          </v-col>

          <v-col cols="12">
            <v-divider />
            <div class="caption mt-2">Date de facture</div>
          </v-col>
          <v-col cols="6">
            <smart-picker
              v-model="searchCtx.creationDateStart"
              label="Début"
              placeholder=" "
              :inputOpts="pickerOpts"
            />
          </v-col>
          <v-col cols="6">
            <smart-picker
              v-model="searchCtx.creationDateStop"
              label="Fin"
              placeholder=" "
              :inputOpts="pickerOpts"
            />
          </v-col>

          <v-col cols="12">
            <v-divider />
            <div class="caption mt-2">Date de paiement</div>
          </v-col>
          <v-col cols="6">
            <smart-picker
              v-model="searchCtx.paymentDateStart"
              label="Début"
              placeholder=" "
              :inputOpts="pickerOpts"
            />
          </v-col>
          <v-col cols="6">
            <smart-picker
              v-model="searchCtx.paymentDateStop"
              label="Fin"
              placeholder=" "
              :inputOpts="pickerOpts"
            />
          </v-col>

          <v-col cols="12">
            <v-divider />
            <div class="caption mt-2">Date limite de paiement</div>
          </v-col>
          <v-col cols="6">
            <smart-picker
              v-model="searchCtx.maxPaymentDateStart"
              label="Début"
              placeholder=" "
              :inputOpts="pickerOpts"
            />
          </v-col>
          <v-col cols="6">
            <smart-picker
              v-model="searchCtx.maxPaymentDateStop"
              label="Fin"
              placeholder=" "
              :inputOpts="pickerOpts"
            />
          </v-col>
          <v-col cols="12" class="text-right">
            <v-divider class="mb-3" />
            <v-btn class="primary" :loading="loading" @click="search">
              <v-icon>search</v-icon>Rechercher
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </template>

    <template slot="content">
      <v-container>
        <v-card>
          <v-data-table
            :headers="headers"
            :items="items"
            :loading="loading"
            :footer-props="{
              'items-per-page-options': rowsPerPageItems,
              'items-per-page-text': 'Lignes par page',
            }"
            no-data-text="-"
            class="tbl"
            show-select
            v-model="selected"
            @click:row="redirect"
          >
            <template #item.creationDate="{ value }">
              <div class="font-weight-regular">
                {{
                  _parseAndFormatDate(
                    value,
                    datePatternConfig.serverLocalDateTime,
                    _datePattern.localDate
                  )
                }}
              </div>
              <div class="caption grey--text text--darken-1">
                {{
                  _parseAndFormatDate(
                    value,
                    datePatternConfig.serverLocalDateTime,
                    _datePattern.time
                  )
                }}
              </div>
            </template>
            <template #item.maxPaymentDate="{ value }">
              <div class="font-weight-regular">
                {{
                  _parseAndFormatDate(
                    value,
                    datePatternConfig.serverLocalDateTime,
                    _datePattern.localDate
                  )
                }}
              </div>
            </template>
            <template #item.amount="{ item, value }">{{
              value | currency(item.currencyCode)
            }}</template>
            <template #item.status="{ item, value }">
              {{ $t("supplierinvoicestatus." + value) }}
              <div>
                <v-chip
                  v-if="item.hasPrestationCancelled"
                  color="red"
                  x-small
                  class="white--text"
                >
                  <v-icon class="mr-2" x-small>mdi-alert</v-icon>Prestation
                  annulé
                </v-chip>
              </div>
            </template>
            <template #item.discord="{ value }">
              <template v-if="value">
                <v-icon small color="red">mdi-alert-octagram-outline</v-icon>
              </template>
            </template>
          </v-data-table>
        </v-card>
      </v-container>
      <smart-dialog v-model="multiple" width="auto" :max-width="1000">
        <template slot="title">
          <v-toolbar-title class="white--text">Factures</v-toolbar-title>
        </template>

        <v-card class="ma-3">
          <v-data-table
            :headers="headers"
            :items="selected"
            class="tbl"
            hide-default-footer
            :footer-props="{
                          'items-per-page-options': rowsPerPageItems,
                          'items-per-page-text': 'Lignes par page',
                        }"
            @click:row="redirect"
          >
            <template #item.creationDate="{ value }">
              <div class="font-weight-regular">
                {{
                  _parseAndFormatDate(
                    value,
                    datePatternConfig.serverLocalDateTime,
                    _datePattern.localDate
                  )
                }}
              </div>
              <div class="caption grey--text text--darken-1">
                {{
                  _parseAndFormatDate(
                    value,
                    datePatternConfig.serverLocalDateTime,
                    _datePattern.time
                  )
                }}
              </div>
            </template>
            <template #item.maxPaymentDate="{ value }">
              <div class="font-weight-regular">
                {{
                  _parseAndFormatDate(
                    value,
                    datePatternConfig.serverLocalDateTime,
                    _datePattern.localDate
                  )
                }}
              </div>
            </template>
            <template #item.amount="{ item, value }">{{
              value | currency(item.currencyCode)
            }}</template>

            <template #item.status="{ value }">{{
              $t("supplierinvoicestatus." + value)
            }}</template>

            <template #item.discord="{ value }">
              <template v-if="value">
                <v-icon small color="red">mdi-alert-octagram-outline</v-icon>
              </template>
            </template>
          </v-data-table>
        </v-card>

        <v-row class="px-3 pb-2" justify="end">
          <v-col cols="8">
            <v-alert v-if="!isSameBank" dense outlined type="error">
              Les factures doivent avoir le même compte bancaire.
            </v-alert>
            <v-alert v-if="!isSameCurrency" dense outlined type="error">
              Les factures doivent avoir les même devise
            </v-alert>
          </v-col>
          <v-col col="4" class="text-right text-h6">
            <span>Total: {{ getSelectedInvoicesTotal() }} €</span>
          </v-col>
        </v-row>

        <template slot="actions">
          <smart-btn
            :disabled="disabled"
            primary
            @click.native="validate"
            v-if="dialogs && dialogs.validate"
          >
            <v-icon>mdi-file-check-outline</v-icon>
            <span class="ml-2">Valider</span>
          </smart-btn>
          <smart-btn primary @click.native="pay" v-if="dialogs && dialogs.pay" :disabled="disabled">
            <v-icon>mdi-file-check-outline</v-icon>
            <span class="ml-2">Payer</span>
          </smart-btn>
          <span></span>
        </template>
      </smart-dialog>
    </template>
  </layout>
</template>

<script>
import Layout from "@/views/Layout";
import AccountingNav from "@/views/accounting/AccountingNav";
import { get, post } from "@/utils/api";
import { EventBus } from "@/components/commons/event-bus";
import _ from "lodash";

export default {
  name: "SupplierInvoiceList",

  props: [],
  components: {
    Layout,
    AccountingNav,
    EventBus,
  },
  mixins: [],
  data() {
    return {
      rowsPerPageItems: [{ text: "Tous", value: -1 }, 50, 200],
      selected: [],
      headers: [
        { text: "#", value: "id", sortable: false },
        { text: "Fournisseur", value: "supplierAccountingName" },
        { text: "Ref", value: "ref" },
        {
          text: "Date création",
          value: "creationDate",
          sort: this.sortByDateTime,
        },
        { text: "Montant", value: "amount" },
        { text: "Statut", value: "status" },
        { text: "BANK", value: "bankAccount" },
        {
          text: "Date max paiement",
          value: "maxPaymentDate",
          sort: this.sortByDateTime,
        },
        { text: "", value: "discord", width:20 },
      ],
      searchCtx: {
        supplierAccountingId: null,
        ref: null,
        status: "waiting",
        creationDateStart: null,
        creationDateStop: null,
        maxPaymentDateStart: null,
        maxPaymentDateStop: null,
        paymentDateStart: null,
        paymentDateStop: null,
      },
      loading: false,
      items: [],
      listSuppliers: [],
      listStatus: [],
      pickerOpts: {
        class: "pt-0",
        clearable: true,
      },
      dialogs: {
        validate: false,
        pay: false,
      },
    };
  },
  mounted() {
    this.init();
  },
  computed: {
    multiple: {
      get() {
        return this.dialogs.validate || this.dialogs.pay;
      },
      set(newVal) {
        if (!newVal) {
          this.dialogs.validate = newVal;
          this.dialogs.pay = newVal;
        }
      },
    },
    disabled(){
      return !this.isSameBank || !this.isSameCurrency;
    },
    isSameBank(){
      return _.uniqBy(this.selected, "bankAccount").length == 1
    },
    isSameCurrency(){
      const currencyTab = this.selected.map(item => item.currencyCode)
      return currencyTab.every(str => str === currencyTab[0])
    }
  },
  methods: {
    init() {
      get("/api/accounting/list-supplier").json(
        (data) => (this.listSuppliers = data)
      );

      get("/api/accounting/list-status").json(
        (data) => (this.listStatus = data)
      );

      this.search();
    },
    search() {
      this.loading = true;
      this.items = [];
      this.selected = [];

      post("/api/accounting/supplier-invoice/list", this.searchCtx)
        .json((data) => {
          this.items = data;
        })
        .catch((e) => {
          EventBus.$emit("toast", {
            color: "red",
            text: "Une erreur est survenue",
            exception: e,
          });
        })
        .then(() => {
          this.loading = false;
        });
    },
    redirect(item) {
      this.$router.push({ name: "supplier-invoice", params: { id: item.id } });
    },
    pay() {
      post("/api/accounting/supplier-invoice/pay-multiple", {
        ids: this.selected.map((s) => s.id),
      }).res(() => {
        this.dialogs.pay = false;
        this.search();
      });
    },
    validate() {
      post("/api/accounting/supplier-invoice/validate-multiple", {
        ids: this.selected.map((s) => s.id),
      }).res(() => {
        this.dialogs.validate = false;
        this.search();
      });
    },
    getSelectedInvoicesTotal() {
      let total = 0;

      this.selected.map((item) => {
        total += item.amount;
      });

      return total;
    },
    sortByDateTime(a, b) {
      const start = a != null ? a : this._formatDate(new Date(null), this.datePatternConfig.serverLocalDateTime)
      const stop = b != null ? b :  this._formatDate(new Date(null), this.datePatternConfig.serverLocalDateTime)
      return this._sortDates(start, stop, this.datePatternConfig.serverLocalDateTime);
    },
    exportCsv() {

       let date;

      if (this.searchCtx.creationDateStart) {
        date = (
          this.searchCtx.creationDateStart +
          "_" +
          this.searchCtx.creationDateStop
        ).replace(/\//g, "-");
      } else {
        date = this._formatDate(
          this._now(),
          this.datePatternConfig.serverLocalDate
        ).replace(/\//g, "-");
      }

      let headers = this.headers.map((header) => header.text).join(";") + 'discord;dossiers' ;

      let data = this.items.map((invoice, index) => {

        const bookings = _.uniq(invoice.prestations.map( p => {
          return p.bookingId
        }))

        return [
          invoice.id,
          invoice.supplierAccountingName,
          invoice.ref,
          invoice.creationDate,
          invoice.amount,
          invoice.currencyCode,
          invoice.status,
          invoice.maxPaymentDate,
          invoice.discord ? "désaccord" : "",
          bookings.join(", ")
        ].join(";")

      }).join('\n');

      let csvContent = headers + '\n' + data;
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      var link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "export_" + date + ".csv";

      link.click();
      link.remove();
    },
  },
};
</script>

<style scoped>
</style>
