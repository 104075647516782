<template>
	<v-menu
		v-if="mealPlanTypeId != null"
		v-model="meal.descMenu"

		:max-width="900"
		top left

	>
		<template v-slot:activator="{ on }">
			<v-icon
				@click="getMealsPlans"
				small
				v-on="on"
			>mdi-information-outline
			</v-icon>
		</template>
		<v-card>
			<v-card-text
				v-if="meal.mealDesc != null"
				style="overflow: auto; white-space: normal; padding-bottom: 0; color:#aaa;"
				v-html="meal.mealDesc.shortdescription"
			>

			</v-card-text>
		</v-card>
	</v-menu>
</template>
<script>
	import {get} from "@/utils/api";

	export default {
		name: 'mealplan-details',
		props: {
			meals: {required: true},
			mealPlanTypeId: {type: Number},
			siteId:{type:Number, required:true}
		},
		data: () => ({
			meal: {
				descMenu: false,
				mealDesc: {shortdescription: null}
			}
		}),
		methods: {
			getMealsPlans() {
				let mealPlanId = this.meals.find((meal) => {
					return meal.mealPlanTypeId == this.mealPlanTypeId
				})

				if (mealPlanId !== undefined) {
					let url = `/api/search/mealplan/${mealPlanId.mealId}/${this.siteId}/desc`
					if (this.meal.mealDesc.shortdescription == null) {
						get(url).json(r => {
							this.meal.mealDesc = r.shortdescription == "" ? {shortdescription: "<p>Aucun résultat</p>"} : r
						})
					}
				} else {
					this.meal.mealDesc = {shortdescription: "<p>Aucun résultat</p>"}
				}
			}
		}
	}
</script>
<style scoped>
	.list-horizontal li {
		display: inline-block;
	}

	.list-horizontal li:before {
		content: '\00a0\2022\00a0\00a0';
		color: #999;
		color: rgba(0, 0, 0, 0.5);
		font-size: 11px;
	}

	.list-horizontal li:first-child:before {
		content: '';
	}


</style>
