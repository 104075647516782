export default {
  methods: {
    exportCsv(ref) {
      const table = this.$refs[ref].$el.querySelector("table");

      const headers = [...table.querySelectorAll("thead th")]
        .map((n) => n.innerText)
        .join(";");

      const data = [...table.querySelectorAll("tbody tr")]
        .map((tr) => {
          return [...tr.querySelectorAll("td")]
            .map((n) => '"' + n.innerText + '"')
            .join(";");
        })
        .join("\n");

      let csvContent = headers + "\n" + data;
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      var link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "export.csv";

      link.click();
      link.remove();
    },
  }
}