<template>
	<div>
		<v-chip dark small dense :color="color" text-color="white" class="pa-0 ma-0 caption">
			{{ $t('virtual-site.short.' + sitename).toUpperCase()}}
		</v-chip>
	</div>
</template>

<script>
	import ColorMixin from "@/components/mixins/ColorMixin";

	export default {
		name: "SiteLabel",
		mixins: [ColorMixin],
		props: {
			sitename: {
				type: String,
				required: true
			}
		},
		data() {
			return {
			}
		},
		watch: {},
		computed: {
			color() {
				return this.siteColor(this.sitename)
			},
			textColor() {
				return this.siteTextColor(this.sitename)
			}
		},
		created() {
		},
		methods: {
			setSiteName(){
				
			}
		}
	}
</script>

<style scoped>
	>>> .v-chip {
		height: 17px;
	}
	>>> .v-chip__content {
		padding: 1px 5px;
		font-size: 10px;
	}
</style>
