<template>
    <Dashboard class="full-height" style="height: 100%;"/>
</template>

<script>
import Dashboard from "@/components/commons/DashBoard.vue"

export default {
  components: {
    Dashboard
  },
  created() {
    if (!this.currentUser.roles.some(role => role.id == 1)) {
      this.$router.push("/hlagons/crm")
    }

  },
  data() {
    return {
      data: null,
    };
  },
  methods: {},
};
</script>
<style scoped>
>>> .full-height{
  height: 100%;
}
.full-height{
  height: 100%;
}

</style>