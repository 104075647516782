<template>
  <v-card>
    <v-card-text>
      <h3 class="text-center">{{ title }}</h3>
      <v-simple-table v-if="prices" dense>
        <template #default>
          <thead>
            <tr>
              <th>Noms</th>
              <th>Prix</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(p, i) in prices.prices" :key="i">
              <td>
                <span v-for="(item, itemIndex) in p.items" :key="itemIndex">
                  <template v-if="itemIndex > 0">, </template>
                  <a :href="item.link" target="_blank">{{ item.agent }}</a>
                </span>
              </td>
              <td>{{ p.price }}€</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  props: ["title","prices"],
  data() {
    return {

    }
  }
}
</script>