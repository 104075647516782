<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <span class="title text-left blue-grey--text text--darken-1">
      Répartition par site avec et sans vols
    </span>
    <widget-container
      :url="url"
      :bus="bus"
      :dark="false"
      cardclass="ma-0 pa-0 elevation-1 "
      @ready="setData"
    >
      <template slot="content">
        <v-data-table
          v-if="data"
          :loading="loading"
          :items="data"
          :footer-props="{
            'items-per-page-options': rowsPerPageItems,
            'rows-per-page-options': 'Lignes par page',
          }"
          :headers="headers"
          item-key="name"
          hide-default-footer
          no-data-text="Aucun résultats"
          class="pa-0 ma-0 tbl"
        >
          <template #header.copy="{ header }">
            <v-icon small @click="copy" class="pl-2"
              >mdi-clipboard-text-outline</v-icon
            >
          </template>

          <template #item.ca="{ value }">{{
            value | currency("EUR")
          }}</template>
          <template #item.sale="{ value }">{{
            value | currency("EUR")
          }}</template>
          <template #item.markup="{ value }"
            ><span v-if="value * 100 > 0"
              >{{
                (value * 100) | decimal({ maximumFractionDigits: 2 })
              }}%</span
            ></template
          >
          <template #item.averageBooking="{ value }">{{
            value | currency("EUR")
          }}</template>

          <template #body.append="{ items }">
            <tr v-if="total" class="body-2 font-weight-medium">
              <td class="text-center">Total</td>
              <td class="text-center">{{ total.bookingSize }}</td>
              <td class="text-center">{{ total.guestSize }}</td>
              <td class="text-right">{{ total.ca | currency("EUR") }}</td>
              <td class="text-right">{{ total.sale | currency("EUR") }}</td>
              <td class="text-right">
                {{
                  (total.markup * 100) | decimal({ maximumFractionDigits: 2 })
                }}
              </td>
              <td class="text-right">
                {{
                  total.averageBooking
                    | currency("EUR", { maximumFractionDigits: 2 })
                }}
              </td>
              <td></td>
            </tr>
          </template>
        </v-data-table>
      </template>
    </widget-container>
  </div>
</template>

<script>
import WidgetContainer from "@/components/commons/widgets/WidgetContainer";
import clipboard from "../../../utils/clipboard";

export default {
  name: "WidgetBookingResumeWithFlights",
  components: { WidgetContainer },
  mixins: [],
  props: {
    bus: {
      type: Object,
      required: true,
    },
    url: {
      type: String,
      required: false,
      default: "/api/widget/bookingresumeHandlingFlights",
    },
    renderRows: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      search: null,
      data: null,
      total: null,

      open: false,

      rowsPerPageItems: [{ text: "Tous", value: -1 }],

      headers: [
        { text: this.$t("site"), value: "site", align: "center" },
        { text: this.$t("bookingSize"), value: "bookingSize", align: "center" },
        { text: "Pax", value: "guestSize", align: "center" },
        { text: this.$t("ca"), value: "ca", align: "end" },
        { text: this.$t("sale"), value: "sale", align: "end" },
        { text: this.$t("markup"), value: "markup", align: "end" },
        {
          text: this.$t("averageBooking"),
          value: "averageBooking",
          align: "end",
        },
        { text: "", value: "copy", class: "text-center", sortable: false },
      ],
    };
  },
  created() {
    this.bus.$on("search", () => {
      this.loading = true;
      this.$emit("loading", this.loading);
      //this.open = this.renderRows
    });
  },
  destroyed() {
    this.bus.$off("search");
  },
  watch: {},
  computed: {},
  methods: {
    buildMarkup(price, purchase) {
      const zero = 0;
      let mkup = 0;
      if (price < purchase || price <= zero) mkup = zero;
      else if (price == zero && purchase == zero) mkup = zero;
      else {
        mkup = (price - purchase) / price;
      }

      return mkup;
    },
    buildAverage(ca, bookingSize) {
      let avg = 0;
      if (bookingSize <= 0) avg = 0;
      else avg = ca / bookingSize;
      return avg;
    },
    setData(data) {
      const total = {
        bookingSize: 0,
        guestSize: 0,
        ca: 0,
        sale: 0,
        markup: 0,
        averageBooking: 0,
      };

      let tmpTotal = data.items.reduce((previous, curr) => {
        return {
          bookingSize: previous.bookingSize + curr.bookingSize,
          guestSize: previous.guestSize + curr.guestSize,
          ca: previous.ca + curr.ca,
          sale: previous.sale + curr.sale,
          markup: 0,
          averageBooking: 0,
        };
      }, total);

      this.total = {
        ...tmpTotal,
        ...{
          markup: this.buildMarkup(tmpTotal.ca, tmpTotal.sale),
          averageBooking: this.buildAverage(tmpTotal.ca, tmpTotal.bookingSize),
        },
      };

      this.data = data.items;
      this.loading = false;
      this.$emit("loading", this.loading);
    },
    copy() {
      const defaultSeparator = "\t";
      const decimalOpts = { maximumFractionDigits: 0, useGrouping: false };

      const headers = this.headers
        .map((h) => {
          return `${h.text}${defaultSeparator}`;
        })
        .join(" ");

      const rows = this.data.map((i) => {
        const rowObj = {
          site: i.site,
          bookingSize: i.bookingSize,
          guestSize: i.guestSize,
          ca: i.ca,
          sale: this.$options.filters.decimal(i.sale, decimalOpts),
          markup: this.$options.filters.decimal(i.markup * 100, decimalOpts),
          averageBooking: this.$options.filters.decimal(
            i.averageBooking,
            decimalOpts
          ),
        };

        return `${Object.values(rowObj).join(defaultSeparator)}`;
      });

      const allRows = [headers].concat(rows);

      clipboard.copyTextToClipboard(allRows.join("\n"));
    },
  },
};
</script>

<style scoped>
>>> .big-footer {
  height: 57px !important;
}
@media screen and (max-width: 600px) {
  >>> .body-2 {
    display: none;
  }
}
</style>
