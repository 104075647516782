<template>
  <layout drawerShow :drawerWidth="400">
    <template slot="nav">
      <v-tabs>
        <v-tab>Centrale de paiement</v-tab>
      </v-tabs>
    </template>
    <template slot="content">
      <v-container>
        <v-row>
          <v-col cols="3">
            <v-card>
              <v-card-title>Import des paiements</v-card-title>
              <v-divider />
              <v-card-text>
                <document-uploader
                  url="/api/accounting/payments/import"
                  acceptedFiles=".csv"
                  @uploaded="uploaded"
                  @error="onError"
                />
              </v-card-text>
            </v-card> </v-col></v-row
      ></v-container>
      <smart-dialog
        v-model="dialog"
        title="Liste des paiements"
        scrollable
        :width="1200"
      >
        <template slot="actions">
          <v-btn small @click="dialog = false">Annuler</v-btn>
          <v-btn small primary :loading="loading" @click="submit"
            >Ajouter les paiements</v-btn
          >
        </template>
        <div class="pa-2">
          <v-text-field v-model="reference" label="Réference" />
          <v-data-table
            :headers="headers"
            :items="bookings"
            class="elevation-1"
            disable-pagination
            hide-default-footer
          >
            <template v-slot:item.rest="{ item }">{{
              item.totalPrice - item.totalPaid
            }}</template>
            <template v-slot:item.diff="{ item }">
              <template
                v-for="(scope, i) in [
                  { diff: item.payment - (item.totalPrice - item.totalPaid) },
                ]"
              >
                <span :key="'p' + i" v-if="scope.diff > 0" class="green--text"
                  >+{{ scope.diff }}</span
                >
                <span
                  :key="'n' + i"
                  v-else-if="scope.diff < 0"
                  class="red--text"
                  >{{ scope.diff }}</span
                >
              </template>
            </template>
          </v-data-table>
        </div>
      </smart-dialog>
    </template>
  </layout>
</template>
<script>
import Layout from "@/views/Layout";
import { EventBus } from "@/components/commons/event-bus";
import { get, post } from "@/utils/api";
import ExportMixin from "@/components/mixins/ExportMixin";
import DocumentUploader from "@/components/commons/DocumentUploader";

export default {
  components: {
    Layout,
    DocumentUploader,
  },
  mixins: [ExportMixin],
  data: () => ({
    loading: false,
    reference: "",
    headers: [
      { value: "line", text: "Ligne" },
      { value: "bookingId", text: "Dossier" },
      { value: "payment", text: "Paiement" },
      { value: "diff", text: "Différence" },
      { value: "rest", text: "Restant dû" },
      { value: "totalPrice", text: "Montant dossier" },
      { value: "totalPaid", text: "Montant payé" },
      { value: "customer", text: "Client" },
      { value: "status", text: "Statut" },
      { value: "bookingDate", text: "Réservé le" },
      { value: "startDate", text: "Départ le" },
    ],
    bookings: [],
    dialog: false,
  }),
  methods: {
    uploaded(resp) {
      this.bookings = JSON.parse(resp[0].xhr.response);
      this.dialog = true;
    },
    onError() {},
    submit() {
      this.loading = true;
      post(
        `/api/accounting/payments/add?reference=${this.reference}`,
        this.bookings
      )
        .res(() => {
          this.dialog = false;
          EventBus.$emit("toast", {
            color: "green",
            text: "Les paiements ont été ajoutés",
          });
        })
        .catch((e) => {
          EventBus.$emit("toast", {
            color: "red",
            text: "Une erreur est survenue",
            exception: e,
          });
        })
        .then(() => {
          this.loading = false;
        });
    },
  },
};
</script>
