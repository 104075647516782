<template>
  <smart-search :is-visible="showResults" :loading="loading" v-model="searchString">
    <v-tabs light v-if="result" v-model="tabModel" class="resultsTabs">
      <v-tab
        :disabled="isEmpty(items)"
        :key="key"
        v-for="(items, key) in result[0]"
      >{{ key == "bookings" ? "Dossiers" : "Projets" }} ({{ items.length }})</v-tab>

      <v-tab-item :key="key" v-for="(items, key) in result[0]">
        <v-row
          dense
          @click="browse(booking)"
          class="searchresults align-center py-1 px-2"
          v-bind:style="{ cursor: 'pointer' }"
          v-for="booking in items"
          :key="booking.id"
          v-show="key == 'bookings'"
        >
          <v-col cols="1">
            <span class="caption grey--text text--darken-1">{{ booking.bookingId }}</span>
            <site-label :sitename="booking.siteName" />
          </v-col>

          <v-col class="text-left" cols="2">
            <div
              class="font-weight-regular"
            >Le {{ _parseAndFormatDate(booking.bookingDate, datePatternConfig.serverLocalDateTime, "dd/MM/yyyy") }}</div>
            <div
              class="caption grey--text text--darken-1"
            >à {{ _parseAndFormatDate(booking.bookingDate, datePatternConfig.serverLocalDateTime, "HH:mm") }}</div>
          </v-col>

          <v-col class="text-left" cols="2">
            <div
              class="font-weight-regular"
            >Du {{ _parseAndFormatDate(booking.startDate, datePatternConfig.serverLocalDateTime, "dd/MM/yyyy") }}</div>

            <div class="caption grey--text text--darken-1">
              Au {{ _parseAndFormatDate(booking.stopDate, datePatternConfig.serverLocalDateTime, "HH:mm") }} •
              {{ booking.duration }}n
            </div>
          </v-col>

          <v-col class="text-left" cols="2">
            <div
              class="font-weight-regular"
              v-if="booking.customer && booking.customer != ''"
            >{{ booking.customer }}</div>
            <span
              :key="i + '-guest'"
              class="caption grey--text text--darken-1"
              v-for="(guest, i) in booking.guests"
            >{{ $tc(guest.ageClass + '-count-small', guest.size, { size: guest.size }) }}</span>
          </v-col>

          <v-col
            class="text-left font-weight-regular"
            cols="2"
          >{{ booking.hotelWithDesti.map(x => x[0]).join(', ') }}</v-col>

          <v-col class="text-right body-2 font-weight-medium" cols="1">
            <div class="black--text">{{ booking.ca | currency('EUR') }}</div>
            <div class="body-2 grey--text">
              {{ booking.purchase | currency('EUR') }}
              <!--<span
                class="caption ml-1 grey--text text--lighten-1"
              >{{ booking.markup | percent }}</span>-->
            </div>
          </v-col>

          <v-col class="text-right" cols="2">
            <v-row class="pl-2">
              <availability
                kind="BookingStatus"
                v-if="booking.bookingStatus"
                v-model="booking.bookingStatus"
              />
              <availability
                kind="SupplierStatus"
                v-if="booking.supplierStatus"
                v-model="booking.supplierStatus"
              />
            </v-row>
          </v-col>
        </v-row>

        <v-row
          dense
          @click="browse(project)"
          align="center"
          class="black--text searchresults pa-3"
          v-bind:style="{ cursor: 'pointer' }"
          v-for="(project, projetIndex) in items"
          :key="projetIndex"
          v-show="key == 'bookingProjects'"
        >
          <v-col class="text-left" cols="2">
            <span class="caption grey--text text--darken-1">{{ project.id }}</span>
            <site-label :sitename="project.siteName" v-if="project.siteName" />
          </v-col>
          <v-col class="font-weight-regular text-left fill-height" cols="2">{{ project.name }}</v-col>
          <v-col class="text-left" cols="2">
            <div
              class="font-weight-regular"
            >Le {{ _parseAndFormatDate(project.creationDate, datePatternConfig.serverLocalDateTime, datePatternConfig.serverLocalDate) }}</div>
            <div
              class="caption grey--text text--darken-1"
            >à {{ _parseAndFormatDate(project.creationDate, datePatternConfig.serverLocalDateTime, datePatternConfig.fr.time) }}</div>
          </v-col>
          <v-col class="font-weight-regular text-left" cols="3">{{ project.customerName }}</v-col>
          <v-col class="font-weight-regular text-left" cols="1">{{ project.bookingCount }} devis</v-col>
          <v-col class="font-weight-regular text-left" cols="2">par {{ project.authorName }}</v-col>
        </v-row>
      </v-tab-item>
    </v-tabs>
  </smart-search>
</template>

<script>
import { get } from "@/utils/api";
import SiteLabel from "./SiteLabel";
import Availability from "./Availability";
import SmartSearch from "./SmartSearch";
import {EventBus} from "@/components/commons/event-bus";

export default {
  name: "SmartBookingFinder",
  components: { SmartSearch, Availability, SiteLabel },
  mixins: [],
  data() {
    return {
      searchString: "",
      loading: false,
      v1: "booked",
      v2: "confirmed",
      tabModel: 0,
      result: null,
      showResults: false,
      requestsQueue: [],
      bookingdrawer:false
    };
  },
  created() {
    let self = this;

    window.addEventListener("click", function (e) {
      if (!self.$el.contains(e.target)) {
        self.showResults = false;
      } else if (
        self.result &&
        (self.result[0].bookingProjects.length > 0 ||
          self.result[0].bookings.length > 0)
      ) {
        self.showResults = true;
      }
    });
  },
  watch: {
    searchString: function (newVal) {
      return this.getBookingsAndProjects(newVal);
    },
  },
  methods: {
    getBookingsAndProjects(search) {
      const self = this;

      this.loading = true;
      const url = `/api/widget/multiSearchBookings?search=${search}`;

      const [controller, wretchInst] = get(url)
        .onAbort((_) => {
          self.requestsQueue.splice(0, self.requestsQueue.length - 1);
        })
        .controller();

      wretchInst
        .json((res) => {
          self.result = null;
          if(this.isCommercial || this.isSalma){
            self.result = [
            {
              bookings: res.bookings.filter(b => b.siteName == 'HLagons'),
              bookingProjects: [],
            },
          ];
          } else {

          self.result = [
            {
              bookings: res.bookings,
              bookingProjects: res.bookingProjects,
            },
          ];
          }
        })
        .then(() => {
          self.loading = false;
          self.showResults = true;
        });

      self.requestsQueue.push({ c: controller, w: wretchInst });

      if (self.requestsQueue.length > 1) {
        self.requestsQueue.map((item, idx) => {
          if (idx < self.requestsQueue.length - 1) {
            item.c.abort();
          }
        });
      }
    },
    isEmpty(list) {
      if (list.length > 0) {
        return false;
      } else return true;
    },
    browse(obj) {
      if(this.isCommercial || this.isSalma){
        EventBus.$emit('browse',obj)
      }else {

        if (obj.bookingId) {
          this.$router.push({name: "Booking", params: {id: obj.bookingId}});
        } else if (obj.id) {
          this.$router.push({name: "booking-project", params: {id: obj.id}});
        }
      }
      this.result = null;
    },
  },
};
</script>

<style scoped>
.searchresults {
  font-size: 13px;
}

.searchresults:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.v-autocomplete__content .v-select-list {
  height: auto;
}

.v-tabs {
  width: 936px;
}

.font-weight-regular {
  color: #1a1a1a;
}

>>> .v-window {
  overflow: scroll;
  max-height: 350px;
}
résultsTabs {
}
</style>
