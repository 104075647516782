<template>
  <v-container fluid>

    <v-row class="mt-2">
      <v-col>
        <GmapMap ref="map" :options="{isFractionalZoomEnabled:true}"
          :center="{ lat: 12.235830492975557, lng: 65.55924602472902 }" :zoom="3" 
          style="width: 100%; height: 800px; margin: auto;">
          <GmapMarker :key="index + '_' + m.position" v-for="(m, index) in markers" :position="m.position"
            :label="m.label" :title="m.title" :option="m.options" :icon="m.icon" :clickable="true"
            @click="toggleInfoWindow(m)">
          </GmapMarker>

          <GmapMarker :key="'point' + index" v-for="(m, index) in points" :position="m.position" :icon="m.icon">
          </GmapMarker>

          <gmap-info-window :options="infoWindow.options" :position="infoWindow.position" :opened="infoWindow.opened"
            @closeclick="closeInfoWindow()" />
        </GmapMap>
      </v-col>
      <v-col cols="3">
        <v-card class="pa-2" >

          <div class="my-2">{{ flights.length }} vols</div>
          <v-divider class="mb-2"/>
          <div class="px-4" style="height: 735px;overflow-y: scroll;">
            <v-card class="pa-3 mb-2 cursor-pointer" v-for="(flight, i) in flights" :key="i">
              <div class="d-flex align-center">
                <div class="flex-grow-1">
                  <span class="font-weight-medium">{{ flight.stats.departureAirport.iata }}</span> <span
                    class="text-caption grey--text">({{ flight.stats.departureAirport.city }})</span>
                </div>
                <div class="flex-grow-1 text-right">
                  <span class="text-caption grey--text">({{ flight.stats.arrivalAirport.city }})</span> <span
                    class="font-weight-medium">{{ flight.stats.arrivalAirport.iata }}</span>
                </div>
              </div>
              <div class="d-flex align-center">
                <div class="flex-grow-1 text-left">
                  <div class="text-h6 font-weight-bold">
                    {{ _formatDate(_parseDateISO(flight.stats.schedule.scheduledDeparture), "HH:mm") }}
                  </div>
                  <div class="text-caption grey--text">{{
                    _formatDate(_parseDateISO(flight.stats.schedule.scheduledDeparture), "dd MMM") }}</div>
                </div>
                <div class="pa-2 text-center">
                  <div>{{ flight.airline }}{{ flight.flight }}</div>
                  <div :class="[
                    colorStatus(flight.stats.status.status)
                  ]">{{ flight.stats.status.status }}
                    <span v-if="flight.stats.status.delayStatus.minutes > 0" class="text-caption">({{
                      flight.stats.status.delayStatus.wording }})</span>
                  </div>
                </div>
                <div class="flex-grow-1 text-right">
                  <div class="text-h6 font-weight-bold">
                    {{ _formatDate(_parseDateISO(flight.stats.schedule.scheduledArrival), "HH:mm") }}
                  </div>
                  <div class="text-caption grey--text">{{
                    _formatDate(_parseDateISO(flight.stats.schedule.scheduledArrival),
                      "dd MMM") }}</div>
                </div>
              </div>
              <div class="d-flex">
                <div class="flex-grow-1">{{ flight.paxs }} pax, {{ flight.bookings.length }} dossiers</div>
                <div><v-btn @click="selectFlight(flight)" x-small><v-icon x-small>search</v-icon></v-btn></div>
              </div>
            </v-card>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <div style="height: 0px;position: relative;">
      <canvas ref="canvas" width="77px" height="77px" style="position: absolute;left:-9999999px"></canvas>
    </div>

  </v-container>
</template>
<script>
import { get } from "@/utils/api";

export default {
  components: {

  },
  data: () => ({
    icon: null,
    timer: null,
    infoWindow: {
      opened: false,
      options: {},
      position: { lat: 0, lng: 0 }
    },
    flights: [],
    selectedFlight: null,
  }),
  created() {

    this.icon = new Image();
    this.icon.src = "/images/plane.png";


    this.refresh()
    this.timer = setInterval(() => {
      this.refresh()
    }, 2 * 60 * 1000);




  },
  mounted() {

  },
  methods: {
    refresh() {
      get('/api/live/flights') //?atDate=2023-12-08')
        .json((data) => {
          this.flights = data;
        });
    },
    selectFlight(flight) {
      this.selectedFlight = flight
    },
    toggleInfoWindow(marker) {

      this.selectFlight(this.flights[marker.index])

      this.infoWindow.position = marker.position
      this.infoWindow.options = {
        content: `<div style="margin-bottom:4px;font-weight:bold">${marker.title}</div>
         <div>Départ prévu le: ${this.selectedFlight.stats.schedule.scheduledDeparture}</div>
         <div>Arrivé prévu le: ${this.selectedFlight.stats.schedule.scheduledArrival}</div>
         <div>Départ estimé le: ${this.selectedFlight.stats.schedule.estimatedActualDeparture}</div>
         <div>Arrivé estimé le: ${this.selectedFlight.stats.schedule.estimatedActualArrival}</div>
        `
      }
      this.infoWindow.opened = true
    },
    closeInfoWindow() {

      this.selectedFlight = null
      this.infoWindow.opened = false
    },
    btn() {
      console.log(this.$refs.map.$mapObject.center.lat())
      console.log(this.$refs.map.$mapObject.center.lng())
    },
    colorStatus(status) {
      if (status == 'Cancelled') {
        return "red--text"
      } else if (status == 'Scheduled') {
        return "green--text"
      } else if (status == 'Departed') {
        return "blue--text"
      }
      return ""
    },
    getIcon(angle) {

      var imgwidth = this.icon.width;
      var imgheight = this.icon.height;

      const canvas = this.$refs.canvas
      const ctx = canvas.getContext("2d");
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.save();
      ctx.translate(imgwidth / 2, imgheight / 2);
      ctx.rotate(angle * Math.PI / 180);
      ctx.drawImage(this.icon, -1 * imgwidth / 2, -1 * imgheight / 2);
      ctx.restore()
      const iconUrl = canvas.toDataURL()

      return iconUrl
    }
  },

  computed: {
    markers() {
      return this.flights
        .map((f, i) => {
          return [f, i]
        })
        .filter(([f, i]) => {
          return f.stats.isTracking && f.stats.positional.flexTrack.positions.length > 0
        })
        .map(([f, i]) => {

          const lastPosition = f.stats.positional.flexTrack.positions[0]

          return {
            index: i,
            title: `${f.airline}${f.flight} • ${f.stats.departureAirport.iata}-${f.stats.arrivalAirport.iata} • ${f.paxs} pax`,
            position: { lat: lastPosition.lat, lng: lastPosition.lon },

            icon: {
              url: this.getIcon(lastPosition.course),
              scaledSize: { width: 32, height: 32 },
              anchor: { x: 16, y: 16 },

            },
          }
        })
    },
    points() {
      if (this.selectedFlight == null) return []

      return this.selectedFlight.stats.positional.flexTrack.positions.map(pos => {

        return {
          title: ``,
          position: { lat: pos.lat, lng: pos.lon },
          icon: {
            url: "/images/tracker_point.png",
            scaledSize: { width: 8, height: 8 },
            anchor: { x: 4, y: 4 }
          },
        }
      })
    }
  }
};
</script>