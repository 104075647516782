import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		drawerBookingSummary: false,
		drawerInfos:{
			booking:null
		}
	},
	mutations: {
		toggleDrawerBookingSummary(state, payload) {
			state.drawerBookingSummary = !state.drawerBookingSummary
			state.drawerInfos = payload
		}
	},
	actions: {}
})
