<template>
  <layout :drawer="false">
    <template slot="nav-tools">
      <smart-btn @click.native="newSupplier" class="mr-1">
        <v-icon>mdi-folder-plus</v-icon>
        <span class="pl-1">Nouveau fournisseur</span>
      </smart-btn>
    </template>

    <template slot="content">
      <template v-if="listing.suppliers.length == 0">
        <v-container fluid class="pa-3">
          <v-row class="fill-height">
            <v-col cols="12">
              <v-skeleton-loader
                class
                height="100%"
                type="list-item-avatar-two-line, list-item-three-line@7"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
        </v-container>
      </template>
      <template v-else>
        <v-container>
          <v-card>
            <v-card-title>
              <h3>Fournisseurs prestation</h3>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="listing.search"
                class="mb-2"
                append-icon="search"
                label="Recherche (Nom - emails)"
                hide-details
              ></v-text-field>
            </v-card-title>
            <v-data-table
              :loading="listing.loading"
              :headers="listing.headers"
              :items="listing.suppliers"
              :footer-props="{'items-per-page-options':listing.rowsPerPageItems, 'items-per-page-text':'Lignes par page'}"
              :search="listing.search"
              item-key="name"
              no-data-text="Aucun fournisseurs ! "
              class="elevation-1 tbl"
            >
              <template #item.email="{value}">
                <v-chip v-for="email in value" :key="email" class="mr-1" small>{{email}}</v-chip>
              </template>
              <template #item.edit="{item}">
                <v-row>
                  <v-icon small class="mr-2" @click="edit(item)">edit</v-icon>
                  <v-icon small @click="del(item)">delete</v-icon>
                </v-row>
              </template>
            </v-data-table>
          </v-card>
        </v-container>
      </template>

      <smart-dialog :title="edition.title" v-model="edition.dialog" :width="600">
        <v-container v-if="edition.supplier">
          <v-form v-if="edition.context != 'Delete'" v-on:submit.prevent="saveSupplier" ref="form" v-model="edition.valid">
            <v-row dense class="mt-1">
              <v-col cols="8">
                <v-text-field dense v-model="edition.supplier.name" label="Nom"></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-select
                  :items="langs"
                  dense
                  label="Langue d'échange"
                  v-model="edition.supplier.lang"
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-combobox
                  v-model="edition.supplier.email"
                  :rules="rules.emailArray()"
                  label="Emails"
                  chips
                  small-chips
                  deletable-chips
                  hide-details
                  multiple
                  @change="handleEditionContext()"
                ></v-combobox>
              </v-col>
            </v-row>
          </v-form>
          <div v-else>
            Suppression du fournisseur
          </div>
        </v-container>
         <prestations-suppliers-list
             v-if="edition.dialog && !edition.create"
             :supplier-id="edition.supplier.id"
             :suppliers="listing.suppliers"
             :context="edition.context"
             :supplierEmails="edition.supplierEmails"
             @canSave="(can) => edition.isDisabled = !can"
         />

        <template #actions>
          <smart-btn :disabled="edition.isDisabled" primary @click.native="edition.btnFn()">Enregistrer</smart-btn>
        </template>
      </smart-dialog>
    </template>
  </layout>
</template>

<script>
import Layout from "@/views/Layout";
import { get, post } from "@/utils/api";
import FormMixin from "@/components/mixins/FormMixin";
import { EventBus } from "@/components/commons/event-bus";
import PrestationsSuppliersList from "@/components/commons/PrestationsSuppliersList.vue";

export default {
  name: "SupplierPrestationEdit",
  components: {
    PrestationsSuppliersList,
    Layout,
  },
  mixins: [FormMixin],
  data() {
    return {
      listing: {
        search: "",
        rowsPerPageItems: [{ text: "Tous", value: -1 }, 50, 100],
        headers: [
          { text: "ID", value: "id", align: "left" },
          { text: "NOM", value: "name", align: "left" },
          { text: "EMAILS", value: "email", align: "left" },
          { text: "LANGUE", value: "lang", align: "left" },
          { text: "EDIT", value: "edit", align: "left" },
        ],

        suppliers: [],
      },
      edition: {
        supplier: null,
        dialog: false,
        create:false,
        valid: true,
        isDisabled:false,
        context:'Edit',
        btnFn:null,
        supplierEmails:[]
      },
      langs: ["fr_FR", "en_GB"],
    };
  },
  created() {
    this.init();
  },
  watch: {},
  computed: {},
  methods: {
    init() {
      const url = "/api/supplier/get/forprestation";

      get(url).json((r) => {
        this.listing.suppliers = r.map((s) => {
          const emails = s.email.split(",");
          return { ...s, ...{ email: emails } };
        });
      });
    },
    remove(supplier) {
      this.$root
        .$confirm(
          this.$t("confirm"),
          "Attention, la suppression est définitive ?",
          { width: 350 }
        )
        .then((confirm) => {
          if (!confirm) {
            return Promise.resolve();
          } else {
            return get(`/api/supplier/prestation/delete/${supplier.id}`)
              .badRequest((err) => {
                EventBus.$emit("toaster-msg", err.toString());
              })
              .res(() => {
                this.init();
                EventBus.$emit("toaster-msg", "Suppression enregistrée");
              });
          }
        });
    },
    del(supplier){
      this.edition.title = 'Suppression fournisseur'
      this.edition.supplier = { ...supplier };
      this.edition.emailsList = [...this.edition.supplier.email];
      this.edition.dialog = true;
      this.edition.create = false;
      this.edition.context='Delete'
      this.edition.isDisabled = false
      this.edition.btnFn = () => this.remove(supplier)
    },
    edit(supplier) {
      this.edition.title = 'Modifier fournisseur'
      this.edition.supplier = { ...supplier };
      this.edition.emailsList = [...this.edition.supplier.email];
      this.edition.dialog = true;
      this.edition.create = false;
      this.edition.context='Edit'
      this.edition.isDisabled = false
      this.edition.btnFn = () => this.saveSupplier(supplier)
    },
    newSupplier() {
      this.edition.title = 'Création fournisseur'
      this.edition.supplier = {};
      this.edition.dialog = true;
      this.edition.create = true;
      this.edition.btnFn = () => this.saveSupplier()
    },
    saveSupplier() {
      if (this.edition.valid && this.$refs.form.validate()) {
        const url = "/api/supplier/prestation/save";
        post(url, this.edition.supplier).res(() => {
          this.init();
          this.edition.dialog = false;
          EventBus.$emit("toaster-msg", "Fournisseur enregistré");
        });
      }
    },
    handleEditionContext(){
      this.edition.supplierEmails =this.edition.supplier.email.filter(x => x.trim().length > 0)
      this.edition.context = this.edition.supplierEmails.length > 0 ? 'Edit' : 'RemoveEmail'
    }
  },
};
</script>

<style scoped>
</style>

<script setup>
</script>