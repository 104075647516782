var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',[_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card-text',[_c('v-row',[(!_vm.form.bookingId && !_vm.bookingId)?_c('v-col',{staticClass:"pr-1",attrs:{"cols":"2"}},[_c('v-text-field',{attrs:{"label":"Dossier","hide-details":""},model:{value:(_vm.input.bookingId),callback:function ($$v) {_vm.$set(_vm.input, "bookingId", $$v)},expression:"input.bookingId"}})],1):_vm._e(),_c('v-col',{staticClass:"pr-1",attrs:{"cols":"6"}},[_c('v-select',{attrs:{"items":_vm.levels,"label":"Criticité","item-text":"label","item-value":"value","hide-details":""},model:{value:(_vm.form.levelId),callback:function ($$v) {_vm.$set(_vm.form, "levelId", $$v)},expression:"form.levelId"}})],1),_c('v-col',{staticClass:"pl-1",attrs:{"cols":"4"}},[_c('smart-picker',{attrs:{"label":"Date d'expiration","inputOpts":{
              label: 'Date d\'échéance',
              error: _vm.dateError,
              clearable: true,
              rules:
                _vm.form.levelId != 5
                  ? _vm.rules.nonEmpty('Date d\'échéance obligatoire')
                  : [],
            }},model:{value:(_vm.form.expirationDate),callback:function ($$v) {_vm.$set(_vm.form, "expirationDate", $$v)},expression:"form.expirationDate"}})],1),_c('v-col',{staticClass:"pt-1 pt-2",attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"label":"Message","hide-details":"","rules":_vm.rules.nonEmpty()},model:{value:(_vm.form.message),callback:function ($$v) {_vm.$set(_vm.form, "message", $$v)},expression:"form.message"}})],1),_c('v-col',{staticClass:"pr-1 pt-2",attrs:{"cols":"4"}},[_c('v-select',{attrs:{"items":_vm.groups,"label":"Groupe utilisateur","item-text":"name","item-value":"id","hide-details":""},model:{value:(_vm.form.groupId),callback:function ($$v) {_vm.$set(_vm.form, "groupId", $$v)},expression:"form.groupId"}})],1),_c('v-col',{staticClass:"pa-1 align-self-end xs"},[_c('v-checkbox',{staticClass:"justify-center mb-0",attrs:{"label":"Terminé","hide-details":""},model:{value:(_vm.form.closed),callback:function ($$v) {_vm.$set(_vm.form, "closed", $$v)},expression:"form.closed"}})],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('smart-btn',{staticClass:"justify-self-end mb-0",attrs:{"primary":"","disabled":!_vm.valid,"loading":_vm.loading,"type":"submit"}},[_vm._v(_vm._s(_vm.$t("save")))])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }