<template>
  <layout :drawerWidth="350" drawerName="Debugger">
    <template slot="nav">
      <v-tabs>
        <v-tab :to="{ name: 'Packages' }">Packages</v-tab>
      </v-tabs>
    </template>

    <template slot="nav-tools">
      <smart-btn
        @click.native="newPackage()"
        :disabled="id < 0 ? true : false"
        class="mr-2"
      >
        <v-icon>mdi-folder-plus</v-icon>Créer un package
      </smart-btn>
    </template>

    <template slot="drawer">
      <v-form
        v-model="debuggerForm.valid"
        ref="debuggerForm"
        v-on:submit.prevent="simulateDebug"
      >
        <v-row v-if="$route.params.packageId">
          <v-col cols="12" class="mt-4 mb-0 pb-0 mr-5 text-center">
            <v-subheader
              light
              class="pa-0 mb-0 mx-auto form-subheader solo-subheader"
              >Debugger</v-subheader
            >
          </v-col>
          <v-col>
            <v-row>
              <v-col cols="5">
                <smart-picker
                  label="Date de départ"
                  v-model="debuggerForm.startDate"
                  :startDate="debuggerForm.startDate"
                  :inputOpts="{
                    rules: rules.nonEmpty('Date de départ obligatoire'),
                  }"
                ></smart-picker>
              </v-col>
              <!-- Stopdate-->

              <v-col cols="5">
                <smart-picker
                  label="Date de retour"
                  v-model="debuggerForm.stopDate"
                  :min="debuggerForm.startDate"
                  :startDate="debuggerForm.startDate"
                  :inputOpts="{
                    rules: rules.nonEmpty('Date de retour obligatoire'),
                  }"
                ></smart-picker>
              </v-col>
            </v-row>
          </v-col>
          <!-- Startdate-->

          <!-- Mealplantype-->

          <v-col cols="12">
            <smart-multi-select
              label="Pensions"
              :items="meals"
              v-model="debuggerForm.mealPlanTypeIds"
              item-text="name"
              item-value="id"
              dense
              multiple
            ></smart-multi-select>
          </v-col>

          <v-col cols="12">
            <v-row>
              <v-col cols="12" class="pt-0 pb-0">
                <v-subheader
                  light
                  class="pa-0 mb-0 mx-0 form-subheader solo-subheader"
                  >Répartition des guests</v-subheader
                >
              </v-col>
              <v-col cols="12" class="pt-0 pb-0">
                <rooms-repartition-list
                  v-model="debuggerForm.roomsRepartition"
                ></rooms-repartition-list>
              </v-col>
            </v-row>
          </v-col>
          <!-- PriceClassType-->
          <v-col cols="12" class="pt-0">
            <v-select
              v-model="debuggerForm.priceClassTypes"
              :items="priceClassTypes"
              item-text="name"
              item-value="id"
              label="Classe de prix"
              dense
            ></v-select>
          </v-col>

          <!-- client country-->
          <v-col cols="12">
            <v-autocomplete
              label="Pays du client"
              :items="countries"
              item-text="name"
              item-value="code"
              v-model="debuggerForm.clientCountry"
              hide-details
              dense
              chips
              small-chips
              hide-no-data
              placeholder="Pays du client"
              :search-input.sync="countrySearchterm"
              :return-object="false"
              :filter="customFilter"
              :rules="rules.nonEmpty('Pays du client obligatoire')"
            ></v-autocomplete>
          </v-col>

          <!-- Airportfrom-->
          <v-col cols="12" class="mt-4">
            <v-autocomplete
              label="Aéroport de départ"
              :items="airports"
              item-text="name"
              item-value="code"
              v-model="debuggerForm.airportFrom"
              hide-details
              chips
              small-chips
              hide-no-data
              placeholder="Aéroport de départ"
              :search-input.sync="airportSearchterm"
              :return-object="false"
              :filter="customFilter"
            >
            </v-autocomplete>
          </v-col>

          <!-- itinéraire -->

          <v-col cols="12">
            <smart-btn
              primary
              type="submit"
              :disabled="
                !debuggerForm.valid || debuggerForm.clientCountry.length < 1
              "
              >Simuler</smart-btn
            >
          </v-col>
          <v-divider class="mt-4" v-if="debuggerResult"></v-divider>

          <!--  debugger result : Name, price, islands, nb hotels -->
          <v-col cols="12" class="px-6">
            <v-subheader
              light
              class="pa-0 mb-2 mx-0 form-subheader solo-subheader"
              v-if="debuggerResult"
              >Package</v-subheader
            >
            <v-row v-if="debuggerResult">
              <v-col cols="8" class="subtitle-2 pb-0">{{
                debuggerResult ? debuggerResult.name : ""
              }}</v-col>
              <v-col cols="2" class="subtitle-2 pb-0" v-if="debuggerResult">
                {{ debuggerResult.price.price | currency("EUR") }}
              </v-col>
              <v-col cols="8" class="subtitle-2 py-0">
                {{
                  debuggerResult
                    ? debuggerResult.islands.map((i) => i.name).join(", ")
                    : ""
                }}
              </v-col>

              <v-col cols="12" class="pb-0 pt-6">
                <v-subheader
                  light
                  class="pa-0 mb-0 mx-0 form-subheader solo-subheader"
                  >Prestations</v-subheader
                >
              </v-col>
              <v-col cols="12">
                <v-row
                  v-for="(prestation, key) in debuggerResult.prestations"
                  :key="key"
                >
                  <v-col cols="8" class="subtitle-2 py-0"
                    >{{ $t(prestation.prestationType) }}
                    {{ prestation.name }}</v-col
                  >
                  <v-col cols="2" class="subtitle-2 py-0">
                    {{ prestation.prices.price | currency("EUR") }}
                  </v-col>
                  <v-col cols="8" class="caption pt-0">
                    {{
                      _parseAndFormatDate(
                        prestation.startDate,
                        datePatternConfig.en.localDateTime,
                        datePatternConfig.serverLocalDate
                      )
                    }}
                    au
                    {{
                      _parseAndFormatDate(
                        prestation.stopDate,
                        datePatternConfig.en.localDateTime,
                        datePatternConfig.serverLocalDate
                      )
                    }}
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row v-else> </v-row>
          </v-col>
        </v-row>
      </v-form>
    </template>

    <template slot="content">
      <v-container fluid>
        <v-row
          :justify="packages.length > 0 ? 'space-around' : 'center'"
          class="pt-3 cols"
        >
          <v-col cols="12" sm="12" md="4">
            <v-card>
              <v-form
                v-model="form.valid"
                ref="form"
                v-on:submit.prevent="saveSkeleton"
              >
                <v-row class="pa-2" dense>
                  <v-col cols="5">
                    <h3>Groupe de packages</h3>
                  </v-col>
                  <v-col cols="5" class="text-right">
                    <v-row class="justify-end">
                      <v-col cols="5" class="pt-0">
                        <v-btn
                          x-small
                          outlined
                          color="blue"
                          @click="duplicateSkeleton"
                          >Dupliquer</v-btn
                        >
                      </v-col>
                      <v-col cols="5" class="pt-0">
                        <v-btn x-small outlined color="error" @click="remove"
                          >Supprimer</v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" v-if="id && id > 0">
                    <v-card>
                      <v-img
                        class="white--text align-end"
                        height="200px"
                        :src="imagePath"
                      >
                        <v-card-title class="mb-2">
                          <v-icon
                            @click="dialogs.image = true"
                            color="white"
                            class="edit-pen"
                            >mdi-lead-pencil</v-icon
                          >
                        </v-card-title>
                      </v-img>
                    </v-card>
                  </v-col>

                  <v-col class="dense mt-4" cols="12">
                    <v-text-field
                      label="Nom du package"
                      v-model="form.name"
                      :rules="rules.nonEmpty()"
                      dense
                      :disabled="displayEdit"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="5">
                    <v-text-field
                      dense
                      type="number"
                      label="Nb de nuits minimum"
                      :rules="rules.nonEmpty()"
                      v-model.number="form.nbNightsMin"
                      :disabled="displayEdit"
                      autocomplete="new-password"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="5">
                    <v-text-field
                      dense
                      type="number"
                      label="Nb de nuits maximum"
                      :rules="rules.nonEmpty()"
                      v-model.number="form.nbNightsMax"
                      :disabled="displayEdit"
                      autocomplete="new-password"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="2">
                    <v-checkbox
                      label="Actif"
                      v-model="form.active"
                      class="mt-0"
                      dense
                      :disabled="displayEdit"
                    ></v-checkbox>
                  </v-col>

                  <v-col cols="12" class="mt-0 justify">
                    <v-row
                      dense
                      justify="start"
                      align="center"
                      class="mt-0 cols"
                    >
                      <v-col cols="1" class="mt-4 mb-0 pb-0 mr-5">
                        <v-subheader
                          light
                          class="pa-0 mb-0 mx-0 form-subheader solo-subheader"
                          >Îles</v-subheader
                        >
                      </v-col>

                      <v-col cols="12" class="mt-0 pt-0">
                        <v-divider></v-divider>
                      </v-col>
                    </v-row>

                    <v-row
                      justify="space-between"
                      v-for="(item, idx) in form.islands"
                      dense
                      class="pl-2 mt-1 cols"
                      :key="idx"
                    >
                      <v-icon @click.native="removeRow('island', idx)" small
                        >mdi-minus-circle-outline</v-icon
                      >

                      <v-col cols="5">
                        <v-select
                          v-if="idx == 0"
                          :items="islandsList"
                          item-text="name"
                          item-value="id"
                          label="Îles"
                          v-model="item.id"
                          :rules="rules.nonEmpty()"
                          dense
                          :disabled="displayEdit"
                        ></v-select>

                        <v-select
                          v-else
                          :items="islandsList"
                          item-text="name"
                          item-value="id"
                          label="Îles"
                          v-model="item.id"
                          dense
                          :disabled="displayEdit"
                        ></v-select>
                      </v-col>
                      <v-col cols="2">
                        <v-checkbox
                          label="Arrivée"
                          class="mt-1"
                          v-model="item.arrival"
                          dense
                          :disabled="displayEdit"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="2">
                        <v-checkbox
                          label="Départ"
                          class="mt-1"
                          v-model="item.departure"
                          dense
                          :disabled="displayEdit"
                        ></v-checkbox>
                      </v-col>
                    </v-row>

                    <v-row @click="addRow('island')" class="add-island cols">
                      <v-icon small class="ml-1 mr-2 pl-3"
                        >mdi-plus-circle-outline</v-icon
                      >Ajouter une île
                    </v-row>
                  </v-col>

                  <v-col cols="12" class="mb-5 justify">
                    <v-row class="mb-1 cols" align="center">
                      <v-col cols="6" class="mb-0 pb-0">
                        <v-subheader
                          light
                          class="pa-0 mb-0 mx-0 mt-4 form-subheader solo-subheader"
                          >Mise en avant marketing</v-subheader
                        >
                      </v-col>
                      <v-col cols="12" class="mt-0 pt-0">
                        <v-divider></v-divider>
                      </v-col>
                    </v-row>
                    <v-row
                      class="cols"
                      justify="space-around"
                      v-for="(item, idx) in form.translations"
                      dense
                      :key="idx"
                    >
                      <v-icon
                        @click.native="removeRow('translation', idx)"
                        small
                        >mdi-minus-circle-outline</v-icon
                      >

                      <v-col cols="4">
                        <v-select
                          v-if="idx == 0"
                          :items="languages"
                          item-text="name"
                          item-value="value"
                          label="Langue"
                          v-model="item.lang"
                          :rules="rules.nonEmpty()"
                          dense
                          :disabled="displayEdit"
                        ></v-select>

                        <v-select
                          v-else
                          :items="languages"
                          item-text="name"
                          item-value="value"
                          label="Langue"
                          v-model="item.lang"
                          dense
                          :disabled="displayEdit"
                        ></v-select>
                      </v-col>

                      <v-col cols="6">
                        <v-text-field
                          v-if="idx == 0"
                          v-model="item.value"
                          hint="ex: Hôtel de luxe"
                          label="Nom"
                          dense
                          :rules="rules.nonEmpty()"
                          :disabled="displayEdit"
                        ></v-text-field>
                        <v-text-field
                          v-else
                          v-model="item.value"
                          hint="ex: Hôtel de luxe"
                          label="Nom"
                          dense
                          :disabled="displayEdit"
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row
                      class="cols"
                      v-if="form.translations.length < languages.length"
                      @click="addRow('translation')"
                    >
                      <v-icon small class="mr-2 pl-4"
                        >mdi-plus-circle-outline</v-icon
                      >Ajouter une traduction
                    </v-row>
                  </v-col>
                  <v-col class="align" cols="12">
                    <smart-btn
                      primary
                      type="submit"
                      :disabled="displayEdit && packageEdit"
                      >Enregistrer</smart-btn
                    >
                  </v-col>
                </v-row>
              </v-form>
            </v-card>
          </v-col>

          <v-col
            cols="12"
            md="8"
            class=""
            v-if="displayEdit || packages.length > 0"
          >
            <v-card>
              <PackagesRelated
                v-if="!displayEdit"
                :skeletonId="id"
                :skeleton="form"
                :pkglist="packages"
                :islands="packageIslands"
                :nights="{ min: form.nbNightsMin, max: form.nbNightsMax }"
                @selected="editPackage"
                @editing="form.valid = true"
                ref="packagesRelated"
              ></PackagesRelated>

              <router-view></router-view>
            </v-card>
          </v-col>

          <smart-dialog
            title="Ajouter une image"
            v-model="dialogs.image"
            :width="800"
          >
            <document-uploader
              @uploaded="uploaded"
              :url="`/api/file/upload/skeletonimage/${id}`"
              acceptedFiles=".jpg,.jpeg"
            />
          </smart-dialog>
        </v-row>
      </v-container>
    </template>
  </layout>
</template>
<script>
import PackagesRelated from "./PackagesRelated";
import Layout from "../Layout";
import SmartMultiSelect from "@/components/commons/SmartMultiSelect";
import { get, post } from "@/utils/api";
import { EventBus } from "@/components/commons/event-bus";
import FormMixin from "@/components/mixins/FormMixin";
import DocumentUploader from "@/components/commons/DocumentUploader";
import PackageMixin from "./PackageMixin";
import RoomsRepartitionList from "@/components/commons/RoomsRepartitionList";
import _ from "lodash";

export default {
  name: "Packages",
  props: ["id"],
  mixins: [FormMixin, PackageMixin],
  components: {
    DocumentUploader,
    Layout,
    SmartMultiSelect,
    PackagesRelated,
    RoomsRepartitionList,
  },
  data() {
    return {
      displayEdit: false,
      packageEdit: false,
      selectedPackage: null,
      islandsList: [],
      packageIslands: [],
      languages: ["fr", "en", "it"],
      packages: [],
      cardsPosition: "space-around",
      dialogs: { image: false },
      imagePath: "",
      meals: [],
      form: {
        valid: false,
        name: null,
        islands: [{ id: null, departure: false, arrival: true, name: "" }],
        translations: [{ lang: "fr", value: null }],
        active: true,
        nbNightsMin: null,
        nbNightsMax: null,
        imagePath: "packages/default.jpg",
      },
      airports: [],
      countries: [],
      airportSearchterm: null,
      countrySearchterm: null,
      priceClassTypes: [],
      debuggerForm: {
        valid: false,
        startDate: null,
        stopDate: null,
        siteId: null,
        clientCountry: "FR",
        priceClassTypes: [],
        mealPlanTypeIds: [],
        roomsRepartition: [
          {
            guests: [{ ageClassType: "Adult" }, { ageClassType: "Adult" }],
            repartition: "BESTPRICE",
          },
        ],
        airportFrom: "test",
        itinerary: null,
      },
      debuggerResult: null,
    };
  },
  watch: {
    $route: {
      immediate: true,
      deep: true,
      handler(to, from) {
        if (to.name == "Package") {
          this.displayEdit = true;
        } else {
          this.displayEdit = false;
        }
        if (to.name == "Package" && to.params.packageId > 0) {
          this.form.valid = false;
          this.packageEdit = true;
        }

        if (from && from.name == "Package") {
          this.init();
          this.displayEdit = false;
          this.packageEdit = false;
        }
      },
    },
    airportSearchterm: _.debounce(function (newVal) {
      this.searchAirport(newVal);
    }, 1000),
    countrySearchterm: _.debounce(function (newVal) {
      this.searchCountry(newVal);
    }, 1000),
  },
  created() {
    this.getMeals();
    this.init();
  },
  methods: {
    init() {
      this.loadSkeleton();
      this.getRelatedPackages();
      this.getIslands();
      this.getAirports();
      this.getCountries();
      this.getPriceClassTypes();
    },
    getMeals() {
      const url = "/api/search/mealplantypes";

      return get(url).json((data) => {
        this.meals = data;
      });
    },
    getIslands() {
      const url = "/api/search/islands";

      get(url).json((data) => {
        this.islandsList = data;

        this.packageIslands = this.form.islands;
      });
    },
    getRelatedPackages() {
      const url = `/api/packages/${this.id}`;

      get(url).json((data) => {
        this.packages = data;
      });
    },
    loadSkeleton() {
      if (this.id && parseInt(this.id) > 0) {
        const url = `/api/packages-skeleton/${this.id} `;

        get(url).json((data) => {
          this.form = data;
          this.imagePath = "/api/file/download/" + this.form.imagePath;
        });
      }
    },
    saveSkeleton() {
      const url =
        this.id && this.id > 0
          ? `/api/packages-skeleton/update/${this.id}`
          : "/api/packages-skeleton/create";

      const islands = this.form.islands.reduce((acc, curr) => {
        if (curr.id) {
          acc.push(curr);
        }
        return acc;
      }, []);

      const translationList = this.form.translations.reduce((acc, curr) => {
        if (curr.lang && curr.value) {
          acc.push(curr);
        }
        return acc;
      }, []);

      const form = {
        ...this.form,
        islands: islands.map((island) => {
          const name = this.islandsList.find((elt) => elt.id == island.id);

          return {
            name: name ? name.name : "",
            arrival: island.arrival,
            departure: island.departure,
            id: island.id,
          };
        }),
        translations: translationList.map((translation) => {
          return {
            lang: translation.lang,
            value: translation.value,
          };
        }),
      };

      post(url, form)
        .json((data) => {
          if (data) {
            EventBus.$emit("toaster-msg", "Type de package enregistré");
          } else {
            EventBus.$emit("toaster-msg", "Erreur lors de l'enregistrement");
          }
          return data;
        })
        .then((data) => {
          const processedPkg = this.packages.map((pack) => {
            const isValid = this.isPackageValid(
              this.packageIslands.map((p) => p.id),
              pack.items.map((i) => i.islandId)
            );

            const newPack = {
              ...pack,
              ...{ active: isValid },
              ...{
                items: pack.items.map((item) => {
                  return {
                    islandId: item.islandId,
                    meals: item.meals.map((m) => {
                      return m.id;
                    }),
                    hotels: item.hotels.map((h) => {
                      return h.id;
                    }),
                    ratings: item.ratings,
                    nbNightsMax: item.nbNightsMax,
                    nbNightsMin: item.nbNightsMin,
                  };
                }),
              },
            };
            return this.updatePack(newPack);
          });
          Promise.all(processedPkg).then((data) => {
            this.packages = data;
            this.init();
          });

          if (this.id < 0 && data) {
            this.$router.push({ name: "EditPackages", params: { id: data } });
          }
        });
    },
    addRow(type) {
      if (type == "island") {
        this.form.islands.push({
          id: null,
          departure: false,
          arrival: true,
        });
      } else if (type == "translation") {
        this.form.translations.push({
          lang: null,
          value: null,
        });
      }
    },
    removeRow(type, index) {
      if (type == "island" && this.form.islands.length > 1) {
        this.form.islands.splice(index, 1);
      } else if (type == "translation" && this.form.translations.length > 1) {
        this.form.translations.splice(index, 1);
      }
    },
    displayPackageForm() {
      this.selectedPackage = null;
      this.displayEdit = true;
    },
    editPackage(pkg) {
      this.displayEdit = true;
      this.selectedPackage = pkg;
    },
    uploaded(files) {
      EventBus.$emit("reloadbooking", {});
      EventBus.$emit("toaster-msg", "Image actualisée");
      this.dialogs.image = false;
      let img = files.map((file) => {
        let path = JSON.parse(file.xhr.responseText);
        return path.paths;
      })[0];

      this.form.imagePath = "/api/file/download/" + img[0];
    },
    packageCreated() {
      this.displayEdit = false;
      this.init();
    },
    remove() {
      this.$root
        .$confirm(
          "Suppression des packages",
          "Attention: la suppression du package type et de ses packages associés est définitive. ",
          {
            width: 400,
          }
        )
        .then((confirm) => {
          if (!confirm) {
            return Promise.resolve();
          } else {
            return get(`/api/packages-skeleton/delete/${this.id}`)
              .badRequest((err) => {
                EventBus.$emit("toaster-msg", err);
              })
              .res(() => {
                this.$router.push({ name: "Packages" });
              });
          }
        })
        .then(() => {});
    },
    newPackage() {
      this.$router.push({
        name: "Package",
        params: { packageId: -1 },
      });
    },
    getAirports() {
      const url = "/api/prestation/add/search-airports";

      get(url).json((data) => {
        this.airports = data;
      });
    },
    getCountries() {
      return get("/api/search/countries?q=").json((countries) => {
        this.countries = countries.map((c) => {
          return {
            name: c.name,
            code: c.code,
          };
        });
      });
    },
    getPriceClassTypes() {
      const url = "/api/search/priceclasstypes";

      get(url).json((data) => {
        this.priceClassTypes = data;
      });
    },
    customFilter(item, queryText) {
      const searchText = queryText.toLowerCase();
      const withName = searchText.length > 3;

      const codeFn = [(s) => item.code.toLowerCase().indexOf(s) > -1];

      const codeWithNameFns = [
        ...codeFn,
        ...[(s) => item.name.toLowerCase().indexOf(s) > -1],
      ];

      return (withName
        ? codeWithNameFns.map((f) =>
            f(searchText.substring(1, searchText.length))
          )
        : codeFn.map((f) => f(searchText))
      ).reduce((acc, curr) => acc || curr);
    },
    simulateDebug() {
      const skeletonId = this.$route.params.id;
      const packageId = this.$route.params.packageId;
      const url = `/api/packages/${skeletonId}/package/${packageId}/debug`;

      let search = {
        search: {
          kind: "destination",
          id: 2,
        },
        startDate: this.debuggerForm.startDate,
        stopDate: this.debuggerForm.stopDate,
        mealPlanTypes:
          this.debuggerForm.mealPlanTypeIds.length > 0
            ? this.debuggerForm.mealPlanTypeIds
            : [],
        roomsRepartition: this.debuggerForm.roomsRepartition,
        commission: 1,
        flightCtx: {
          airportFrom: this.debuggerForm.airportFrom
            ? this.debuggerForm.airportFrom
            : "",
          itineraries: [
            {
              travelClass: "ECONOMY",
            },
            {
              travelClass: "ECONOMY",
            },
          ],
        },
        env: {
          siteId: 1,
          langId: 1,
          priceClassTypeId:
            this.debuggerForm.priceClassTypes.length > 0
              ? this.debuggerForm.priceClassTypes[0]
              : 2,
          strokePriceClassTypeId:
            this.debuggerForm.priceClassTypes.length > 0
              ? this.debuggerForm.priceClassTypes[0]
              : 3,
          clientCountry: this.debuggerForm.clientCountry
            ? this.debuggerForm.clientCountry
            : "FR",
          debug: true,
        },
      };
      post(url, search)
        .badRequest(() => {
          EventBus.$emit("toaster-msg", "Aucun package trouvé");
          this.debuggerResult = null;
        })
        .json((data) => {
          let processedPrestations = data.prestations.map((prestation) => {
            return {
              ...prestation,
              startDate:
                prestation.startDate.substring(0, 10) +
                " " +
                prestation.startDate.substring(11, 19),
              stopDate:
                prestation.stopDate.substring(0, 10) +
                " " +
                prestation.stopDate.substring(11, 19),
            };
          });

          this.debuggerResult = { ...data, prestations: processedPrestations };
        });
    },
    duplicateSkeleton() {


      this.$root
        .$confirm(
          this.$t("Duplication"),
          "Souhaitez vous dupliquer ce groupe de packages ?",
          { width: 350 }
        )
        .then((confirm) => {
          if (!confirm) {
            return Promise.resolve();
          } else {
            const skeletonId = this.$route.params.id;

            const url = `/api/packages-skeleton/duplicate/${skeletonId}`;

            return get(url).json((data) => {
              return data;
            });
          }
        })
    },
  },
};
</script>
<style>
.edit-pen {
  filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));
}
.add-island:hover {
  cursor: pointer;
}
</style>