<template>
  <layout :drawer="true" :drawerShow="false" :drawerWidth="300">
    <template slot="drawer">
      <v-form class="ml-2" @submit.prevent="loadData">
        <v-row>
          <v-col cols="12" class="pb-0">
            <v-subheader light class="pa-0 mb-0 mx-0 form-subheader"
            >Status</v-subheader>
            <v-divider></v-divider>
          </v-col>
          <v-col cols="12">
            <v-checkbox
                v-for="(bs, index) in LogStatus"
                :key="'bs-' + index"
                v-model="searchform.LogStatus"
                :label="bs.label"
                :value="bs.value"
                :class="'pt-1 ' + (index == 0 ? 'mt-2' : 'mt-0')"
                height="1"
            ></v-checkbox>
          </v-col>
          <v-col cols="12" class="pb-0">
            <v-subheader light class="pa-0 mb-0 mx-0 form-subheader"
            >Créateur</v-subheader>
            <v-divider></v-divider>
          </v-col>
          <v-col cols="12" class="pt-0">
            <smart-multi-select
                :default-select-all="false"
                :items="selectorCommercial"
                style="margin-top: 22px"
                item-text="name"
                item-value="id"
                label="Créateur"
                v-model="searchform.selectorCommercial"
            >
            </smart-multi-select>
          </v-col>
          <v-col cols="12" class="pb-0">
            <v-subheader light class="pa-0 mb-0 mx-0 form-subheader"
            >Destinataire</v-subheader>
            <v-divider></v-divider>
          </v-col>
          <v-col cols="12" class="pt-0">
            <smart-select
                :allIsSameAsEmpty="true"
                class="pa-0"
                :items="selectorCommercial"
                style="margin-top: 22px"
                item-text="name"
                item-value="id"
                label="Destinataire"
                v-model="searchform.selectorRecipient"
            >
            </smart-select>
          </v-col>
          <v-col cols="12">
            <smart-btn type="submit" :loading="loading" block primary
            >Rechercher</smart-btn
            >
            <smart-btn
                @click.native="clearSearchForm()"
                block
                tertiary
            >Réinitialiser la recherche</smart-btn
            >
          </v-col>
        </v-row>

      </v-form>
    </template>
    <template slot="content">
      <v-container fluid>
        <div class="mt-1" style="max-width:1024px;margin:auto">
          <v-row >
            <v-col cols="12" class="">
              <v-data-table
                  @item-expanded="itemExpend"
                  :headers="listing.headers"
                  :loading="listing.loading"
                  :items="listing.items"
                  :sort-by="'countLog'"
                  :sort-desc="true"
                  show-expand
                  :options.sync="listing.pagination"
                  :hide-default-footer="true"
                  :footer-props="{
            'items-per-page-options': listing.rowsPerPageItems,
            'items-per-page-text': 'Lignes par page',
          }"
                  class="elevation-1 tbl"
                  no-data-text="Aucun évènement à afficher"
              >
                <template #item.agencyName="{ item, value }">
                  <div>{{ value }}</div>
                  <span class="caption grey--text text--darken-1">
                {{ item.agencyStreet }}
                {{ item.agencyPostalcode }}
                {{ item.agencyCity }}
              </span>
                </template>

                <template #item.creationDate="{item, value}">
                  <div>
                    {{ _parseAndFormatDate(value,"dd/MM/yyyy HH:mm:ss", "dd/MM/yyyy' à 'HH'h'mm") }}
                  </div>
                  <span class="caption">Par</span><span class="caption font-weight-black text--black"> {{ item.commercialName }}</span>
                </template>
                <template #item.recipient="{item}">
                  {{item.recipientCommercialName}}
                </template>

                <template #item.report="{item}">
                  <div class="report ">
                    {{ item.reportText }}
                  </div>
                  <!--
                   Dom: alors moi je dirait que l'on a pas de besoin des participant dans le tableau
                   ça permettrait d'afficher plus de rapport

                   <div class="caption grey--text text--darken-1 report">
                    Participants:
                    <span v-for="(agent, index) in item.agents" :key="agent">
                {{ index === 0 ? '' : '-' }}
                  {{ agent }}
              </span>
                  </div>-->
                </template>

                <template #item.logType="{item, value}">
                  <div style="position: relative">
                    <div v-if="item.newMessages > 0 && item.newMessage" class="indigo accent-2 rounded-xl pulseEffect"></div>
                    <v-chip small :color="setColorLogType(value)" text-color="white" label>
                      {{ $t(`crm.logType.${item.logType}`) }}
                    </v-chip>
                  </div>
                </template>

                <template #item.logStatus="{item, value}">
                  <div class="d-flex justify-center align-center">
                    <v-chip label small :class="backGroundStatus(value).bgAndText"
                            class="d-flex justify-center align-center"
                            style="width: 80px"
                            :style="'border:solid 1px ' + backGroundStatus(value).border + '!important'">
                      {{ $t("logStatus."+value)}}
                    </v-chip>
                  </div>
                </template>


                <template #expanded-item="{ headers, item }">
                  <td class="" :colspan="6">
                    <div class="content-expended">
                      <v-row class="text-body-1">
                        <v-col cols="10">
                          Rapport
                        </v-col>
                        <v-col  class="text-body-2">
                          Démo
                          <v-icon small v-if="item.demo" color="green">check</v-icon>
                          <v-icon small v-else color="red">cancel</v-icon>
                        </v-col>

                      </v-row>
                      <div class="" v-html="item.report"></div>

                      <div class="flex flex-wrap">
                        <span class="caption">Participants: </span>
                        <v-chip v-for="(agent, index) in item.agents" :key="agent+index" x-small class="mr-1">
                          {{ agent }}
                        </v-chip>
                        <span v-if="item.recipientCommercialId != null">| </span>
                        <v-chip v-if="item.recipientCommercialId != null" x-small color="primary">
                          @{{item.recipientCommercialName}}
                        </v-chip>
                      </div>

                      <MessagesCommercialLog v-if="item.messages" :item="item" @updateView="updateViewMessage" @newMessage="(newMessage) => item.messages.messages.push(newMessage)" />

                      <div class="d-flex justify-end mt-2">
                        <v-btn
                            secondary
                            class="ml-4"
                            text
                            small
                            :to="{ name: 'CrmAgencyDetails', params: { id: item.agencyId } }"
                            target="_blank"
                        >Voir l'agence</v-btn>
                        <smart-btn small @click.native="edit(item)">Editer</smart-btn>
                        <smart-btn small v-if="item.logStatus != 'validated'" @click.native="changeStatus('validated', item)" class="ml-2">Valider</smart-btn>
                        <smart-btn small v-if="item.logStatus != 'pending'" @click.native="changeStatus('pending', item)" class="ml-2">En attente</smart-btn>
                      </div>
                    </div>
                  </td>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </div>

      </v-container>
      <smart-dialog
          v-if="openEventEditor"
          title="Éditer l'évènement"
          v-model="openEventEditor"
          :width="900"
          @close="closeEvent(false)"
          :fullscreen="_isMobile()"
      >
        <CrmNewEvent @closeEvent="closeEvent(true)" :selectorAgents="selectorAgents" :dataReport="dataReport" />
      </smart-dialog>
      <smart-dialog
          v-if="openConfirmValidate"
          title="Confirmer"
          v-model="openConfirmValidate"
          width="300"
          @close="openConfirmValidate = false"
      >
        <p class="mt-4 mx-4 font-weight-medium">Confirmer.</p>
        <div class="d-flex justify-end pb-4 pr-4">
          <smart-btn @click.native="validate(statusToSet)">Valider</smart-btn>
        </div>
      </smart-dialog>
    </template>
  </layout>
</template>
<script>
import Layout from "@/views/Layout.vue";
import {get, post} from "@/utils/api";
import CrmNewEvent from "@/components/commons/CrmNewEvent.vue";
import {EventBus} from "@/components/commons/event-bus";
import SmartMultiSelect from "@/components/commons/SmartMultiSelect.vue";
import SmartSelect from "@/components/commons/SmartSelect.vue";
import MessagesCommercialLog from "@/components/commons/MessagesCommercialLog.vue";

export default {
  name: "CommercialLogList",
  components: {MessagesCommercialLog, SmartSelect, SmartMultiSelect, CrmNewEvent, Layout},
  data() {
    return {
      listing: {
        headers: [
          {
            text: "Agence",
            value: "agencyName",
            width: "200px"
          },
          {
            text: "Date",
            value: "creationDate",
            width: "150px"
          },
          {
            text: "Commentaire",
            value: "report",
            width: "300px"
          },
          {
            text: "Évènement",
            value: "logType",
            align: "center",
            width: '150px',
            sortable:false
          },
          {
            text: "Status",
            value: "logStatus",
            align: "center",
            width: '100px',
            sortable:false
          },
          {value: 'data-table-expand', width: '10px'}
        ],
        loading: false,
        items: [],
        rowsPerPageItems: [{text: "Tous", value: -1}, 50, 200],
        pagination: {
          options: {
            page: 1,
            itemsPerPage: 200,
            sortBy: [],
            sortDesc: [false],
          },
        }
      },
      openEventEditor:false,
      openConfirmValidate:false,
      statusToSet:null,
      selectorAgents:[],
      agents:[],
      dataReport:null,
      commercialLogId: null,
      LogStatus: [
        { value: "toreview", label: "Review" },
        { value: "validated", label: "Validé" },
        { value: "pending", label: "En attente" },
      ],
      searchform:{
        LogStatus:["toreview"],
        selectorCommercial: [],
        selectorRecipient:[]
      },
      selectorCommercial:[],
      countEvent:null,
      loading:false
    }
  },
  async created() {
    await this.init(true)
  },
  methods: {
    async init(setCommercial) {
      this.listing.loading = true;
      await this.getCommercials()
      if(setCommercial){
        this.setCommercial()
      }
      await this.loadData()
    },
    edit(dataReport){
      this.getAgents(dataReport.agencyId)
      this.dataReport = dataReport
      this.openEventEditor = true
    },
    changeStatus(status, item){
      this.commercialLogId = item.id;
      this.statusToSet = status
      this.openConfirmValidate = true;
      EventBus.$emit('updateVisite', {})
    },
    async validate(verb){
      const url = `/api/agency/crm/event/${this.commercialLogId}/${verb}`

      await post(url)
          .badRequest((br) => {
            EventBus.$emit('toaster-error', "erreur lors de la confirmation de l'évenement")
            console.log("Error: ", br);
          })
          .res(() => {
            EventBus.$emit('toaster-msg', "Evènement validé")
            this.openConfirmValidate = false
          })
      await this.loadData()
      EventBus.$emit('updateVisite', {})
    },
    getAgents(id) {
      const url = `/api/agency/${id}/agents`;

      return get(url)
          .badRequest((br) => {
            console.log("Error: ", br);
          })
          .json((data) => {
            return data.map((agent) => {
              return {
                id: agent.id,
                lastname: agent.lastname ? agent.lastname : "-",
                firstname: agent.firstname ? agent.firstname : "-",
                email: agent.email ? agent.email : "-",
                login: agent.login ? agent.login : "-",
                password: null,
                phone: agent.phone ? agent.phone : "-",
                active: agent.active || agent.active == null,
                isFromSmartresas: agent.isFromSmartresas,
                agencyName: agent.agencyName ? agent.agencyName : null,
                allowIncentive: agent.allowIncentive
              };
            });
          })
          .then((r) => {
            this.agents = r;
            this.selectorAgents = r.filter(agent => agent.active).map((item) => {
              return {
                id: item.id,
                name: item.firstname + " " + item.lastname
              };
            });
          });
    },
    mapResult(data){
      return data.map((d) => {
        const agents = d.agentsList != null ? d.agentsList.split("; ") : null

        return {
          ...d,
          agents: agents != null ? agents.map(a => {
            return a.charAt(0) + ". " + a.split(" ")[1]
          }) : null,
          reportText: new DOMParser().parseFromString(d.report, 'text/html').body.textContent,
          agentsIds: d.agentsIds != null ? d.agentsIds.split("; ").map(a => parseInt(a)) : [],
          newMessage: true,
          messages: null
        }
      });
    },
    clearSearchForm(){
      this.searchform.LogStatus = ["toreview"]
      this.setCommercial()
      this.loadData()
    },
    async loadData(){
      if(!this.loading){
        this.loading = true
        const url = "/api/agency/crm/event/all";
        this.searchform.currentUserId = this.currentUser.id
        await post(url,this.searchform).json((data) => {
          this.listing.items = this.mapResult(data)
          this.listing.loading = false;
        });
        this.loading = false
      }
    },
    backGroundStatus(value){
      switch (value){
        case 'toreview':
          return {bgAndText:"blue lighten-4 blue--text text--darken-2",border:"#64B5F6"}
        case 'validated':
          return {bgAndText:"green lighten-4 green--text text--darken-2",border:"#81C784"}
        case 'pending':
          return {bgAndText:"yellow lighten-4 yellow--text text--darken-4",border:"#FDD835"}
      }
    },
    setColorLogType(value){
      switch (value){
        case 'visit' :
          return 'primary'
        case 'call':
          return 'green'
        case 'mail':
          return 'orange'
        case 'other':
          return 'grey'
      }
    },
    setCommercial(){
      if(this.currentUser.roles.some(role => role.id == 3)){
        if(this.currentUser.id != 89){
          this.searchform.selectorCommercial = []
          this.searchform.selectorRecipient = []
          this.searchform.selectorCommercial.push(this.currentUser.id)
          this.searchform.selectorRecipient.push(this.currentUser.id)
        } else{
          this.searchform.selectorCommercial = []
          this.searchform.selectorRecipient = []
        }
      } else{
        this.searchform.selectorCommercial = []
        this.searchform.selectorRecipient = []
      }
    },
    closeEvent(search){
      this.openEventEditor = false
      if(search){
        this.init(false)
      }
    },
    async getCommercials(){
      await get("/api/search/commercials").json((data) => {
        this.selectorCommercial = data
      })
    },

    async itemExpend(commercialLog){

      commercialLog.item.newMessage = false

      if(this.currentUser.id == commercialLog.item.recipientCommercialId || this.currentUser.id == commercialLog.item.userId && commercialLog.value){
        await this.loadMessagesByCommercialLog(commercialLog)
        EventBus.$emit('scrollDownMessages')
      }
    },

    async loadMessagesByCommercialLog(commercialLog){
      const url = `/api/agency/crm/message/all/${commercialLog.item.id}`

      await get(url)
          .badRequest((br) => {
            EventBus.$emit('toaster-error', "erreur lors du chargement des messages")
            console.log("Error: ", br);
          })
          .json((res) => {
            commercialLog.item.messages = res
            this.updateViewMessage(commercialLog)
          })
    },


    async updateViewMessage(commercialLog){

      const url = this.currentUser.id == commercialLog.item.recipientCommercialId ? `/api/agency/crm/message/updateRecipient/${commercialLog.item.id}` :
          `/api/agency/crm/message/updateUser/${commercialLog.item.id}`

      await post(url)
        .badRequest((br) => {
          EventBus.$emit('toaster-error', "erreur lors du chargement des messages")
          console.log("Error: ", br);
        })
    },
  }
}
</script>

<style scoped>
.report {
  width: 300px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

>>> .v-data-table__expanded__content {
  box-shadow: none !important;
}
>>> .content-expended {
  border: 1px dashed slategrey;
  padding: 10px;
  margin:10px;
}

.pulseEffect{
  position: absolute;
  left: 0;
  top: 50%;
  width: 5px;
  height: 5px;
  transform: scale(1);
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(140, 158, 255, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 2px rgba(140, 158, 255, 1);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(140, 158, 255, 1);
  }
}
</style>

