<template>
  <v-tooltip top>
    <template #activator="{ on }">
      <v-chip
        v-on="on"
        v-if="chip"
        class="mx-1"
        :color="config.color"
        :style="{ border: 'solid 1px ' + config.border + '!important' }"
        small
        label
      >
        <span :class="config.text" class=" align-center"
          ><slot name="prefix" />{{ trad }}
          <span v-if="suffix">{{ suffix }}</span
          ><slot name="suffix"
        /></span>
      </v-chip>
      <span v-else v-on="on" class="mx-1">
        <span :class="[config.text, noFlex ? 'd-flex' : '']" class="align-center"
          >{{ trad }} <span v-if="suffix">{{ suffix }}</span
          ><slot name="suffix"
        /></span>
      </span>
    </template>

    <span class="px-1">{{ label }}</span>
  </v-tooltip>
</template>

<script>
import ColorMixin from "@/components/mixins/ColorMixin";

export default {
  name: "Availability",
  props: {
    value: { type: String, required: true },
    kind: { type: String, required: true },
    suffix: { type: String },
    chip: { type: Boolean, default: true },
    noFlex: {type:Boolean, default : true},
  },
  mixins: [ColorMixin],
  data: () => ({
    label: "",
    color: "",
    colorText: "",
    border: "",

    config: {
      color: "",
      text: "",
      border: "",
    },

    status: "Statut du client",
    supplierstatus: "Statut du fournisseur",
    availability: "Etat du stock",
    giftcardstatus: "Statut du chèque-cadeau",
  }),
  created() {
    this.init();
  },
  watch: {
    value() {
      this.init();
    },
  },
  computed: {
    kindAsLowerCase() {
      return this.kind.toLowerCase();
    },
    valueAsLowerCase() {
      return this.value.toLowerCase();
    },
    trad() {
      return this.$t(this.kindAsLowerCase + "." + this.valueAsLowerCase);
    },
  },
  methods: {
    init() {
      if (
        this.kind == "BookingStatus" &&
        this.colorsStatusList[this.valueAsLowerCase]
      ) {
        this.config = this.colorsStatusList[this.valueAsLowerCase];
        this.label = this.status;
      }

      if (
        this.kind == "SupplierStatus" &&
        this.colorsSupplierStatusList[this.valueAsLowerCase]
      ) {
        this.config = this.colorsSupplierStatusList[this.valueAsLowerCase];
        this.label = this.supplierstatus;
      }

      if (
        this.kind == "GiftcardStatus" &&
        this.colorsGiftcardStatusList[this.valueAsLowerCase]
      ) {
        this.config = this.colorsGiftcardStatusList[this.valueAsLowerCase];
        this.label = this.giftcardstatus;
      }

      if (this.colorsStockList[this.valueAsLowerCase]) {
        this.config = this.colorsStockList[this.valueAsLowerCase];
        this.label = this.availability;
      }

      if (this.colorsInvoicingList[this.valueAsLowerCase]) {
        this.config = this.colorsInvoicingList[this.valueAsLowerCase];
        this.label = this.availability;
      }

      this.color = this.config.color;
      this.colorText = this.config.text;
      this.border = this.config.border;
    },
  },
};
</script>

<style scoped>
.v-chip--label {
  border-radius: 5px;
}
</style>
