<template>
	<layout :drawer="false">
		<template slot="nav">
			<v-tabs>
				<v-tab :to="{name:'customer-search'}">Clients</v-tab>
			</v-tabs>
		</template>
		<template slot="nav-tools">

			<v-col>
				<smart-btn @click.native="addBookingDialog = true">
					<v-icon>mdi-folder-plus</v-icon>
					<span class="pl-1">
							Ajouter un dossier
						</span>
				</smart-btn>
			</v-col>
			<v-col>
				<smart-btn @click.native="duplicateAndAssociate" :disabled="this.selected.length == 0"
									 :loading="duplicateLoading">
					<v-icon>mdi-folder-plus</v-icon>
					<span class="pl-1">
							Dupliquer le dossier
						</span>
				</smart-btn>
			</v-col>
			<v-col>
				<smart-btn @click.native="renderMailComposer" :disabled="this.selected.length == 0">
					<v-icon>mdi-email-send-outline</v-icon>
					<span class="pl-1">
						Envoyer les devis
					</span>
				</smart-btn>
			</v-col>

		</template>
		<template slot="content">
			<v-container fluid>
				<v-row dense justify="center">
					<v-col cols="12" lg="8">
						<v-card class="mb-5">
							<v-card-text>
								<v-row dense justify="space-between">
									<span v-if="bookingProject.name"
												style="font-size: 15px; font-weight: bold">{{bookingProject.name}}</span>
									<span v-else class="grey--text text--darken-1" style="font-size: 15px; font-weight: bold">Aucun nom de projet</span>
									<v-icon @click="textEdit = true">mdi-pencil</v-icon>
								</v-row>
							</v-card-text>
						</v-card>
						<v-data-table
								v-model="selected"
								:loading="listing.loading"
								:headers="listing.headers"
								:items="bookingProject.bookingList"
								:footer-props="{'items-per-page-options':listing.rowsPerPageItems, 'items-per-page-text':'Lignes par page'}"
								item-key="bookingId"
								no-data-text="Il n'y a pas de dossiers disponible"
								class="elevation-1 tbl"
								show-select
								@click:row="goto"
						>


							<template #item.bookingDate="{item, value}">
								<div class="font-weight-regular">{{ _parseAndFormatDate(value, datePatternConfig.serverLocalDateTime,
									datePatternConfig.serverLocalDate) }}
								</div>
								<div class="caption grey--text text--darken-1">
									{{ _parseAndFormatDate(item.bookingDate,datePatternConfig.serverLocalDateTime, "dd/MM/yy") }} •
								</div>
							</template>
							<template #item.startDate="{item, value}">
								<div class="font-weight-regular">{{ _parseAndFormatDate(value, datePatternConfig.serverLocalDateTime,
									datePatternConfig.serverLocalDate) }}
								</div>
								<div class="caption grey--text text--darken-1">
									{{ _parseAndFormatDate(item.startDate,datePatternConfig.serverLocalDateTime, "dd/MM/yy") }} •
								</div>
							</template>
							<template #item.customer="{item, value}">
								<div class="font-weight-regular" v-if="item.customer && item.customer != ''">{{item.customer}}</div>
								<span class="caption grey--text text--darken-1" v-for="(guest, i) in item.guests" :key="i+'-guest'">
									{{$tc(guest.ageClass+'-count-small', guest.size, {size:guest.size})}}
								</span>
							</template>
							<template #item.hotelWithDesti="{item, value}">
								<div v-if="item.hotelWithDesti" v-html="renderHotelWithDesti(item.hotelWithDesti)"></div>
							</template>
							<template #item.airlineCodes="{item, value}">
								<span v-if="value.length>0">{{ value.join(" ") }}</span>
								<span v-else> - </span>
							</template>
							<template #item.transferName="{item, value}">
								<div class="" v-if="item.transferName">
									<v-tooltip top>
										<v-icon slot="activator" :size="16">mdi-bus-side</v-icon>
										<span>{{item.transferName}}</span>
									</v-tooltip>
								</div>
								<div class="" v-if="item.carrentName">
									<v-tooltip top>
										<v-icon slot="activator" :size="15">mdi-car-side</v-icon>
										<span>{{item.carrentName}}</span>
									</v-tooltip>

								</div>
								<div class="" v-if="!item.transferName && !item.carrentName"> -</div>
							</template>
							<template #item.ca="{item, value}">{{ item.ca | currency('EUR') }}</template>
							<template #item.bookingStatus="{item, value}">
								<availability v-model="item.bookingStatus" kind="BookingStatus"/>
								<availability v-model="item.supplierStatus" kind="SupplierStatus" v-if="item.supplierStatus"/>
							</template>
						</v-data-table>
					</v-col>
					<smart-dialog title="Message au client" v-model="email.dialog" :width="800" v-if="selected.length > 0">
						<mail-composer
								:tos="[customer.email]"
								:loading="email.loading"
								:subject="email.subject || 'réf. ' + booking.id"
								:message="email.message"
								@send="sendEmail"
								:default-internal-attachment="email.defaultInternalAttachment"
								showInternalAttachment
						></mail-composer>
					</smart-dialog>

					<smart-dialog title="Nom du projet de voyage" v-model="textEdit" :width="800">
						<v-row dense class="px-3">
							<v-text-field
									v-model="bookingProject.name"
									placeholder="Mettre à jour le nom du projet de voyage"
							></v-text-field>
						</v-row>
						<v-row dense class="px-3" justify="end">
							<v-col cols="2">
								<smart-btn block primary @click.native="editProject">Éditer</smart-btn>
							</v-col>
						</v-row>
					</smart-dialog>

					<smart-dialog v-model="customer.dialog" :width="590" title="Créer ou associer un client">
						<booking-customer-create
								@linkCustomer="attachCustomer"
								@linkAgency="attachAgency"
								@done="customer.dialog = false"
						></booking-customer-create>
					</smart-dialog>
					<smart-dialog v-model="addBookingDialog" :width="700" title="Associer un dossier">
						<add-to-booking-project :bookingProjectId="parseInt(id)"
																		@success="closeAddBookings()"></add-to-booking-project>
					</smart-dialog>

				</v-row>
			</v-container>
		</template>

	</layout>
</template>

<script>
	import Layout from "../Layout";
	import {EventBus} from "@/components/commons/event-bus";
	import {get, post} from '@/utils/api';
	import Availability from "@/components/commons/Availability";
	import MailComposer from "@/components/commons/MailComposer";
	import BookingCustomerCreate from "../../components/booking/BookingCustomerCreate";
	import AddToBookingProject from "@/components/booking/AddToBookingProject";
	import Confirm from "@/components/commons/Confirm";

	export default {
		name: "BookingProject",
		components: {
			BookingCustomerCreate,
			AddToBookingProject,
			Layout,
			Availability,
			MailComposer,
			Confirm
		},
		data() {
			return {
				id: null,
				textEdit: false,
				email: {
					dialog: false,
					loading: false,
					subject: "Devis de votre projet de voyage",
					message: "",
					defaultInternalAttachment: null,
					pdfList: []
				},
				duplicateLoading: false,
				addBookingDialog: false,
				bookingToAdd: [],
				bookingProject: {
					name: "",
					bookingList: [],
					context: {}
				},
				selected: [],
				listing: {
					loading: false,
					rowsPerPageItems: [200, 500, {text: 'Tous', value: -1}],
					pagination: {
						rowsPerPage: 200
					},
					headers: [
						{text: 'RÉSA', value: 'bookingDate', align: 'left',},
						{text: 'SÉJOUR', value: 'startDate', align: 'left',},
						{text: 'CLIENT', value: 'customer', align: 'left',},
						{text: 'DESTINATION', value: 'hotelWithDesti', align: 'left', sortable: false,},
						{text: 'VOLS', value: 'airlineCodes', align: 'left', sortable: false,},
						{text: 'TRSF.', value: 'transferName', align: 'left', sortable: false,},
						{text: 'MONTANT', value: 'ca', align: 'right', sortable: false,},
						{text: 'STATUTS', value: 'bookingStatus', align: 'left', sortable: false,},
						{text: ''}
					],
					checklist: []
				},
				customer: {
					dialog: false
				},
				duplicatedIds: []
			}
		},
		watch: {},
		created() {
			this.init()
		},
		methods: {
			init() {
				this.id = this.$route.params.id
				this.getBookingList(this.id)
			},
			getBookingList(id) {
				const url = `/api/bookingproject/${id}`

				get(url)
					.badRequest(err => {
						console.error(err)
					})
					.json(data => {
						this.bookingProject = {
							...data,
							...{
								bookingList: data.bookingList.map((booking) => {
									return {...booking, ...{selected: true}}
								})
							}
						}
						this.selected = this.bookingProject.bookingList
						this.customer = {...this.customer, ...this.bookingProject.customer}
					}).then(() => {
					this.duplicateLoading = false
				})
			},
			renderHotelWithDesti(hotelWithDesti) {
				return hotelWithDesti.map(hotel => {
					return `
						<div class="text-xs-left font-weight-regular">${hotel[0]}: ${hotel[1]}</div>
					`
				}).join("")
			},
			goto(item) {
				let route = this.$router.resolve({name: 'Booking', params: {id: item.bookingId}});
				window.open(route.href, "_blank");
			},
			duplicateAndAssociate() {

				this.duplicateLoading = true

				if (this.selected.length > 0) {
					const duplicatePromises = this.selected.map(booking => {
						return this.duplicate(booking)
					})

					Promise.all(duplicatePromises).then((ids) => {
						return ids.map((id) => {
							return this.associate(id)
						})
					}).then((associatedBookings) => {
						Promise.all(associatedBookings).then(() => {
							this.getBookingList(this.id)
						})
					})

				}
			},
			async duplicate(booking) {
				let urlDuplicate = `/api/booking/${booking.bookingId}/duplicate`

				return get(urlDuplicate)
					.badRequest(err => {
						console.error(err)
					})
					.json(id => {
						return id
					})

			},
			async associate(id) {
				let urlAssociate = `/api/bookingproject/${this.id}/booking/${id}`

				return get(urlAssociate)
					.badRequest(err => {
						console.error(err)
					})
					.json(response => {
						return response
					})
			},
			renderMailComposer() {
				//TODO check customer & show customer create if not exists

				if (!this.customer.id) {
					this.customer.dialog = true
				} else {
					this.openMailComposer()
				}
			},
			openMailComposer() {
				const content = this.buildContentsForEmail()

				this.email = {
					...this.email, ...{
						email: this.customer.email,
						defaultInternalAttachment: ['quotation'],
						subject: content.subject + ' réf. :' + this.id,
						message: content.message,
						dialog: true
					}
				}
			},
			buildContentsForEmail() {
				let msg = "Suite à votre création de projet de voyage sur notre site <a href=\"https://www.hotelissima.fr\">Hotelissima</a>, vous trouverez en pièce jointe la liste des devis correspondants.<br/><br/>" +
					"Le prix des hôtels ou des vols peuvent changer à tout moment en fonction du remplissage. Ne tardez pas à réserver pour profiter des meilleurs prix.<br/><br/>" +
					"Hotelissima vous offre la possibilité de consulter en haute définition le diaporama de ou des hébergements choisis. Il suffit de cliquer sur le(s) lien(s) ci-dessous :<br/>" +
					"<ul></ul> " +
					"N'hésitez pas à nous contacter si vous désirez plus d'informations sur ce devis ou pour une demande de séjour à la carte (en répondant à cet email ou en nous téléphonant)."
				return {
					subject: "Devis de votre projet de voyage ",
					message: msg,
				}
			},
			sendEmail(email) {
				let ids = this.selected.map(q => q.bookingId)
				const url = `/api/mail/sendBookingProject`
				let data = {email: email, bookingIds: ids}

				this.email.loading = true
				post(url, data)
					.json(r => {
						EventBus.$emit('toaster-msg', "Email envoyé")
						return r
					})
					.then(() => {
						this.email.loading = false
						this.email.dialog = false
					})
			},
			attachCustomer(customer) {
				this.customer = {...this.customer, ...customer}
				const path = (selectedBooking) => `/api/booking/attachcustomer/${selectedBooking.bookingId}/${this.customer.id}`
				this.attach(path)
			},
			attachAgency({agency, agent}) {
				this.customer = {...this.customer, ...agent}
				const path = (selectedBooking) => `/api/booking/attachagency/${selectedBooking}/${agency.id}/${agent.id}`
				this.attach(path)
			},
			attach(path) {
				const futEditedProject = this.editProject()
				const futAttachedCustomer = this.selected.map((selectedBooking) => {
					return get(path(selectedBooking))
						.badRequest(err => {
							EventBus.$emit('toaster-msg', JSON.parse(err.message).errors.join(', '))
						})
						.json(r => {
							return r
						})
						.then(() => {
							EventBus.$emit('toaster-msg', "Client correctement rattaché")
						})
				})

				Promise.all([futEditedProject, ...futAttachedCustomer]).then(() => {
					this.customer.dialog = false
					this.openMailComposer()
				})

			},
			editProject() {
				const url = `/api/bookingproject/${this.id}/edit`

				const project = {
					name: this.bookingProject.name,
					customerId: this.customer.id,
					customerType: this.customer.customerType
				}

				return post(url, project)
					.json(r => {
						EventBus.$emit('toaster-msg', "Projet mis à jour")
					})
					.then(() => {
						this.textEdit = false
					})
			},
			closeAddBookings() {
				this.addBookingDialog = false
				this.init()
			},
			removeBooking(bookingId) {

				this.$root.$confirm(this.$t('confirm'), "Attention, la suppression est définitive ?", {width: 350})
					.then((confirm) => {
						if (!confirm) {
							return Promise.resolve()
						} else {
							if (bookingId) {

								const url = `/api/bookingproject/removebooking/${bookingId}`

								get(url)
									.badRequest((err) => {
										EventBus.$emit('toaster-error', err.message)
									})
									.res(r => {
										EventBus.$emit('toaster-msg', `Dossier n°${bookingId} retiré du projet de voyage`)
										this.init()
									})
							}
						}
					})

			}
		}
	}
</script>

<style scoped>
	.checkbox-container {
		display: flex !important;
		flex-direction: column;
		justify-content: center;
	}


</style>
