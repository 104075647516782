<template>
  <div>
    <v-row v-for="(segment, i) in segments" :key="i">
      <v-col cols="12" class="ml-3">
        <v-row>
          <v-col cols="8">
            <ul class="details-segment">
              <li>
                {{
                  _parseAndFormatDate(
                    segment.start,
                    _datePattern.localDateTime,
                    _prestationDatePattern(segment.start)
                  )
                }}:
                {{ segment.fromName }}
              </li>
              <li
                v-if="
                  _prestationDatePattern(segment.start).length > 10 &&
                  _prestationDatePattern(segment.stop).length > 10
                "
              >
                Durée du trajet:
                {{
                  _durationTime(
                    _parseDate(
                      segment.start,
                      _prestationDatePattern(segment.start)
                    ),
                    _parseDate(
                      segment.stop,
                      _prestationDatePattern(segment.start)
                    )
                  )
                }}
              </li>
              <li>
                {{
                  _parseAndFormatDate(
                    segment.stop,
                    _datePattern.localDateTime,
                    _prestationDatePattern(segment.stop)
                  )
                }}:
                {{ segment.toName }}
              </li>
            </ul>
          </v-col>
          <v-col cols="4" class="pt-3">
            <div>
              <v-label>Véhicule:</v-label>
              {{ segment.vehicleType }}
              <availability
                v-model="segment.availability"
                kind="Stock"
                :chip="false"
                class="caption"
              />
            </div>
            <div class="text-right pr-15">
              <span class="title font-weight-regular"
                >{{ segment.price }}€</span
              >
              <span class="grey--text ml-5">{{ segment.purchase }} €</span>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Availability from "@/components/commons/Availability";

export default {
  name: "TransferSegment",
  mixins: [],
  components: {
    Availability,
  },
  props: {
    segments: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {};
  },
  created() {},
  watch: {},
  computed: {},
  methods: {},
};
</script>

<style scoped>
ul.details-segment {
  padding: 0px;
  margin: 0px;
  position: relative;
  list-style-type: none;
}

ul.details-segment::before {
  content: "";
  position: absolute;
  top: 5px;
  bottom: 5px;
  border-left: 4px dotted #d9d9d9;
}

ul.details-segment li {
  margin: 13px;
  padding: 0px 0px;
  position: relative;
  z-index: 1;
  background-color: white;
}

ul.details-segment li:first-child::before,
ul.details-segment li:last-child::before {
  content: "";
  position: absolute;
  bottom: 0;
  top: 3px;
  left: -19px;
  border-left: 15px dotted #d9d9d9;
}

.details-segment-flystops {
  border-bottom: 1px solid #e0e0e0;
  border-top: 1px solid #e0e0e0;
  font-size: 13px;
  padding: 16px 24px 16px 0;
}
</style>
