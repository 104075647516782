<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <v-card class="elevation-12">
          <v-toolbar dark color="primary" flat>
            <v-toolbar-title>Connexion</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form ref="form" v-model="valid" @submit.prevent="submit">
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    prepend-icon="person"
                    name="login"
                    label="Email"
                    placeholder="Email"
                    type="text"
                    v-model="form.login"
                    :rules="emailRules"
                    autocomplete="nope"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    prepend-icon="lock"
                    name="password"
                    label="Mot de passe"
                    placeholder="Mot de passe"
                    type="password"
                    v-model="form.password"
                    :rules="passwordRules"
                    :autocomplete="'nope'"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="text-right">
                  <v-btn
                    :loading="loading"
                    :disabled="!valid"
                    type="submit"
                    color="primary"
                  >Se connecter</v-btn>
                </v-col>
              </v-row>
              <v-alert :value="error" color="error" icon="check_circle" outlined>{{error}}</v-alert>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { emailValidator, requireValidator } from "@/utils/validators";
import { EventBus } from "@/components/commons/event-bus";
import { get, post } from "@/utils/api";

export default {
  data: () => ({
    loading: false,
    valid: true,
    error: null,
    form: {
      login: null,
      password: ""
    },
    emailRules: [v => emailValidator(v)],
    passwordRules: [v => requireValidator(v, "Le mot de passe est requis")]
  }),
  created() {
    this.removeToken();
  },
  methods: {
    submit() {
      this.loading = !this.loading;
      const url = "/api/auth/login";
      return post(url, this.form)
        .badRequest(err => {
          EventBus.$emit("toaster-error", err.message);
        })
        .json(data => {
          this.setToken(data.token);
        })
        .then(() => {
          this.loading = false;
        })
        .then(() => {

            const qs = window.location.search;

            if (qs != "") {
              const redirect = this.getParameterByName(
                "redirect",
                window.location.search
              );
              this.$router.push({ path: redirect });
            } else {
              this.$router.push({ name: this.defaultViewPerRole() });
            }

        });
    }
  }
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
>>> input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px white inset !important;
}
</style>
