<template>
  <widget-container
    :url="url"
    headlineclass
    cardclass="ma-0 pa-1"
    color="transparent"
    :bus="bus"
    :dark="false"
    :flat="true"
    :alwaysrender="true"
    @ready="setData"
  >
    <template slot="content">
      <div class>
        <v-row align="center">
          <v-col class="py-0">
            <v-subheader class="title"
              >Segment Actif - Se connecte mais ne réserve pas</v-subheader
            >
            <v-data-table
              class="mb-4"
              :headers="headers"
              :items="data"
              dense
              :loading="loading"
              :sort-desc="true"
              :hide-default-footer="false"
              :header-props="{ sortByText: 'Trier par' }"
              @click:row="openAgency"
            >
              <template #item.ca="a">
                {{ a.item.ca | currency("EUR") }}
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </div>
    </template>
  </widget-container>
</template>
<script>
import WidgetContainer from "./WidgetContainer";
export default {
  name: "WidgetAgencyActiveSegment",
  components: { WidgetContainer },
  props: {
    bus: {
      type: Object,
      required: true,
    },
    url: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      loading: false,
      data: [],
      headers: [
        { text: "Agence", value: "name", sortable: true },
        { text: "Adresse", value: "address", sortable: false },
      ],
    };
  },
  created() {
    this.$nextTick(() => {
      this.bus.$on("search", () => {
        this.loading = true;
      });
    });
  },
  destroyed() {
    this.bus.$off("search");
  },
  methods: {
    setData(data) {
      this.data = data;
      this.loading = false;
    },
    openAgency(row) {
      let route = this.$router.resolve({
        name: "CrmAgencyDetails",
        params: { id: row.id },
      });
      window.open(route.href, "_blank");
    },
  },
};
</script>
