<template>
  <layout :drawerShow="true" :drawerWidth="350">
    <template slot="nav-tools">
      <smart-btn @click.native="newSkeleton" class="mr-5">
        <v-icon>mdi-folder-plus</v-icon>Créer un package type
      </smart-btn>
    </template>
    <template slot="drawer">
      <v-form v-on:submit.prevent="getPackageSkeletons">
        <v-row>
          <v-col cols="12" class="mt-5">
            <smart-multi-select
              label="Destinations"
              :items="destinations.length > 0 ? destinations : []"
              v-model="form.destinationIds"
              dense
              multiple
            ></smart-multi-select>
          </v-col>
        </v-row>
        <smart-btn :loading="listing.loading" type="submit"
          >Rechercher</smart-btn
        >
      </v-form>
    </template>
    <template slot="content">
      <v-container fluid>
        <v-card>
          <v-data-table
            :headers="listing.headers"
            :loading="listing.loading"
            :items="listing.items"
            @click:row="goto"
            hide-default-footer
            class="row-pointer"
            :footer-props="{
              'items-per-page-options': [{ text: 'Tous', value: -1 }],
            }"
          >
            <template #item.islands="{ item }">
              <td>
                <span v-for="(island, idx) in item.islands" :key="idx">{{
                  island.name && item.islands.length - 1 > idx
                    ? island.name + " - "
                    : island.name
                }}</span>
              </td>
            </template>

            <template #item.keepOrdering="{ item }">{{
              item.keepOrdering == true ? "Oui" : "Non"
            }}</template>
            <template #item.nbNights="{ item }"
              >{{ item.nbNightsMin }} - {{ item.nbNightsMax }}</template
            >

            <template #item.active="{ item }">
              <v-icon v-if="item.active == true"
                >mdi-check-circle-outline</v-icon
              >
              <v-icon v-else>mdi-close</v-icon>
            </template>
          </v-data-table>
        </v-card>
      </v-container>
    </template>
  </layout>
</template>
<script>
import Layout from "../Layout";
import { post, get } from "@/utils/api";
import SmartMultiSelect from "@/components/commons/SmartMultiSelect";
import SmartBtn from "../../components/commons/SmartBtn.vue";

export default {
  name: "PackagesList",
  components: {
    Layout,
    SmartMultiSelect,
    SmartBtn,
  },
  data() {
    return {
      listing: {
        headers: [
          { text: "Nom", sortable: false, value: "name" },
          { text: "Îles", sortable: false, value: "islands" },
          {
            text: "Nombre de nuits",
            sortable: false,
            value: "nbNights",
            width: "150px",
          },
          { text: "Actif", sortable: false, value: "active", width: "50px" },
          { text: "Nombre de packages", value: "nbPackage" },
        ],
        items: [],
        loading: false,
      },
      destinations: [],
      form: {
        name: null,
        nbNights: null,
        islands: [],
        destinationIds: [],
        translations: [],
      },
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.getDestinations();
      this.getPackageSkeletons();
    },
    getDestinations() {
      get("/api/search/destinations").json((destinations) => {
        this.destinations = destinations;
      });
    },
    getPackageSkeletons() {
      if (!this.listing.loading) {
        this.listing.loading = true;
        const url = "/api/packages/searchpackageskeleton";

        post(url, this.form)
          .json((data) => {
            data.skeletons.sort((a, b) => a.name.localeCompare(b.name));
            this.listing.items = data.skeletons;
          })
          .then(() => {
            this.listing.loading = false;
          });
      }
    },
    goto(row) {
      this.$router.push({ name: "EditPackages", params: { id: row.id } });
    },
    newSkeleton() {
      this.$router.push({ name: "EditPackages", params: { id: -1 } });
    },
  },
};
</script>

<style scoped>
.clickableItem:hover {
  cursor: pointer;
}

.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
