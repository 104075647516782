<template>
  <collapse-panel :collapsable="false" :open="false" :loading="disablePanel">
    <div slot="title">
      <v-row align="center">
        <v-col class="caption grey--text lighten-1 font-weight-medium"
        >Paiements & Facturation
        </v-col
        >
      </v-row>
    </div>
    <div slot="header">
      <v-row dense>
        <v-col cols="7" class="text-right">
          <div class="title font-weight-regular">
            <span
                class="cancelledprice"
                v-if="booking.status === 'cancelled'"
            >{{ booking.cancelledPrice | currency(booking.currency) }}</span
            >
            <span
                v-if="showCommissionPrice && booking.commissionPrice"
                class="amber rounded pl-1 mr-1"
            >
              {{ booking.commissionPrice | currency(booking.currency) }}
            </span>
            {{ booking.price | currency(booking.currency) }}
          </div>
          <div class="body-2 font-weight-medium font-weight-medium grey--text">
            {{ booking.euroPurchasePrice | currency("EUR") }}
            <span class="ml-2 caption">{{ booking.markup | percent }}</span>
          </div>
        </v-col>
        <v-col
            class="caption grey--text text-right align-self-end"
            v-if="booking.commission"
        >Com. agence: {{ (booking.commission / 100) | percent }}
        </v-col
        >
      </v-row>
      <v-divider/>
      <v-container fluid class="pa-0 pb-2 mt-2">
        <v-row
            dense
            align="center"
            class="text-right body-2 blue--text font-italic"
            v-if="booking.payments.length === 0"
        >
          <v-col cols="5">Accompte (à payer)</v-col>
          <v-col cols="2">{{
              payment.amount | currency(booking.currency)
            }}
          </v-col>
        </v-row>

        <v-row
            dense
            align="center"
            class="text-right body-2 blue--text font-italic"
            v-if="showKagnot"
        >
          <v-col cols="5">Kagnot (à utiliser)</v-col>
          <v-col cols="2">{{
              kagnots.total | currency(booking.currency)
            }}
          </v-col>
        </v-row>

        <template v-if="booking.payments.length > 0">
          <v-row
              dense
              v-for="p in booking.payments"
              :key="p.id"
              class="body-2"
              align="baseline"
              :class="[
              p.status === 'refused' || p.status === 'cancelled'
                ? 'red--text text--lighten-3 refused'
                : p.status === 'pending'
                ? 'orange--text text--lighten-3 cancelled'
                : '',
            ]"
          >
            <v-col cols="1" class="small-text grey--text pl-2">
              <v-tooltip top>
                <template #activator="{ on }">
                  <span v-on="on">{{
                      _parseAndFormatLocalDateTime(p.creationDate, "d MMM")
                    }}</span>
                </template>
                {{
                  _parseAndFormatLocalDateTime(
                      p.creationDate,
                      "dd/MM/yyyy HH:mm:ss"
                  )
                }}
              </v-tooltip>
            </v-col>
            <v-col cols="4" class="text-right">
              {{ $t("paymentTypes." + p.paymentType) }}

              <v-icon v-if="p.shopId != null && (p.shopId == 4 || p.shopId == 5)" small>mdi-credit-card-multiple-outline</v-icon>
              <span v-if="p.status === 'refused'" class="caption">
                -
                <v-tooltip top>
                  <template #activator="{ on }">
                    <span v-on="on">
                      Refusé
                      <v-icon small>mdi-information-outline</v-icon>
                    </span>
                  </template>
                  {{ p.details.transactionErrorMsg }}
                </v-tooltip>
              </span>
              <span v-if="p.status === 'cancelled'" class="caption"
              >- Annulée</span
              >
              <span v-if="p.status === 'pending'" class="caption">
                - En attente
                <span v-if="p.paymentType === 'CB'"
                >(exp {{ expireDate(p) }})</span
                >
              </span>
            </v-col>
            <v-col cols="2" class="text-right amount">{{
                p.amount | currency(booking.currency)
              }}
            </v-col>
            <v-col
                class="small-text grey--text font-italic text-right relative"
            >
              <component :is="p.details.type" :payment="p"/>
            </v-col>
          </v-row>
        </template>

        <v-divider/>
        <v-row
            dense
            class="text-right body-2 font-weight-medium grey--text darken-2 my-1"
        >
          <v-col cols="5">Montant payé</v-col>
          <v-col cols="2">{{
              booking.paidAmount | currency(booking.currency)
            }}
          </v-col>
        </v-row>
        <v-divider/>
        <v-row
            dense
            class="text-right subheading font-weight-regular mb-2"
            align="center"
        >
          <v-col cols="5">Solde</v-col>
          <v-col cols="2">{{
              remainingAmount | currency(booking.currency)
            }}
          </v-col>

          <v-btn small text class="grey--text caption" @click="() => editBalanceDateDialog=!editBalanceDateDialog">
            <v-icon small class="mr-1">mdi-calendar-clock</v-icon>
            <span v-if="booking.balanceDate">{{
                booking.balanceDate
              }}</span>
            <span v-else>Aucune date</span>
          </v-btn>
          <v-col class="grey--text small-text">
            <smart-dialog
                v-model="editBalanceDateDialog"
                title="Date de prélèvement du solde"
                class="text-center"
            >


              <v-row justify="space-around" class="ma-3">
                <v-col cols="6" class="text-center">
                  <smart-picker
                      v-model="booking.balanceDate"
                      :max="booking.startDate.split(' ')[0]"
                      :menuOpts="{}"
                      :inputOpts="{ 'prepend-inner-icon': 'event' }"
                      picker-only
                  ></smart-picker>
                </v-col>
              </v-row>

              <template slot="actions">
                <smart-btn primary @click.native="changeBalanceDate"
                >Valider
                </smart-btn
                >
              </template>
            </smart-dialog>
          </v-col>
        </v-row>

        <!--
				<v-layout row wrap>
					<v-flex xs12 class="caption grey--text">Ce dossier générera {{7 | currency('EUR')}} de kagnott</v-flex>
				</v-layout>
        -->
        <v-row dense class="caption text-left grey--text">
          <v-col cols="12">
            <template v-if="booking.cancelfees.fees.length > 0">
              <v-btn
                  icon
                  small
                  @click="showCancelFees = !showCancelFees"
                  class="mx-0 my-0"
              >
                <v-icon small color="grey">{{
                    showCancelFees ? "mdi-eye-off" : "mdi-eye"
                  }}
                </v-icon>
              </v-btn
              >
              Frais d'annulation:
              <span class="ml-2">{{
                  cancelFee.price | currency(booking.currency)
                }}</span>
              <span class="ml-2" v-if="cancelFee.start > _now()"
              >(à partir du {{ booking.cancelfees.fees[0].start }})</span
              >
            </template>
            <template v-else>Aucun frais d'annulation</template>
          </v-col>
          <v-col
              cols="12"
              v-if="booking.status == 'quotation'"
              class="text-right"
          >
            <v-btn small text @click="maxfees">Appliquer 100% de frais</v-btn>
          </v-col>
          <v-col cols="12" v-if="showCancelFees" class="mb-2">
            <cancel-fees
                v-model="booking.cancelfees.fees"
                class="mt-2"
            ></cancel-fees>
          </v-col>
          <v-col cols="12" v-if="booking.invoices.length > 0">
            <div>
              <span>
                <v-btn
                    icon
                    small
                    @click="showInvoices = !showInvoices"
                    class="my-0 mx-0"
                >
                  <v-icon small color="grey">{{
                      showInvoices ? "mdi-eye-off" : "mdi-eye"
                    }}</v-icon>
                </v-btn>
              </span>

              <a
                  href="#"
                  class="mr-2 grey--text"
                  @click="downloadInvoice(booking.invoices[0].id)"
              >{{ booking.invoices[0].reference }}</a
              >
              {{ booking.invoices[0].name }} du
              {{ booking.invoices[0].invoiceDate }}
              <span class="ml-1"
              >à
                {{
                  booking.invoices[0].amount | currency(booking.currency)
                }}</span
              >
            </div>
            <div
                v-for="(invoice, idx) in booking.invoices"
                :key="idx"
                v-if="showInvoices"
                v-show="idx > 0"
            >
              <div class="grid">
                <v-row>
                  <v-col cols="3">
                    <a
                        href="#"
                        @click="downloadInvoice(invoice.id)"
                        class="mr-2 grey--text"
                    >{{ invoice.reference }}</a
                    >
                  </v-col>
                  <v-col cols="5">
                    <span>{{ invoice.name }} du {{ invoice.invoiceDate }} </span>
                  </v-col>
                  <v-col cols="2">
                    <span class="ml-1">{{
                        invoice.amount | currency(booking.currency)
                      }}</span>
                  </v-col>
                </v-row>
              </div>
            </div>
          </v-col>
          <v-col cols="12" v-if="booking.paymentOrders.length > 0">
            <div>
              <span>
                <v-btn
                    icon
                    small
                    @click="showOrders = !showOrders"
                    class="my-0 mx-0"
                >
                  <v-icon small color="grey">{{
                      showOrders ? "mdi-eye-off" : "mdi-eye"
                    }}</v-icon>
                </v-btn>
              </span>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <span v-on="on">
                    Email de paiement: Le
                    {{
                      _parseAndFormatDate(
                          booking.paymentOrders[0].creationDate,
                          datePatternConfig.serverLocalDateTime,
                          datePatternConfig.serverLocalDate
                      )
                    }}:
                    <b>{{
                        booking.paymentOrders[0].amount
                            | currency(booking.paymentOrders[0].currency)
                      }}</b>
                  </span>
                </template>
                <span>
                  Crée le {{ booking.paymentOrders[0].creationDate }}
                  <br/>
                  Expire le {{ booking.paymentOrders[0].expirationDate }}
                </span>
              </v-tooltip>
            </div>

            <div
                v-for="(order, idx) in booking.paymentOrders"
                :key="idx"
                v-if="showOrders"
                v-show="idx > 0"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <span v-on="on">
                    Le
                    {{
                      _parseAndFormatDate(
                          order.creationDate,
                          datePatternConfig.serverLocalDateTime,
                          datePatternConfig.serverLocalDate
                      )
                    }}:
                    <b>{{ order.amount | currency(order.currency) }}</b>
                  </span>
                </template>
                <span>
                  Crée le {{ order.creationDate }} Expire le
                  {{ order.expirationDate }}
                </span>
              </v-tooltip>
            </div>
          </v-col>
          <v-col cols="12" v-if="booking.otherFiles.length > 0">
            <div>
              <span>
                <v-btn
                    icon
                    small
                    @click="showOtherFiles = !showOtherFiles"
                    class="my-0 mx-0"
                >
                  <v-icon small color="grey">{{
                      showOtherFiles ? "mdi-eye-off" : "mdi-eye"
                    }}</v-icon>
                </v-btn>
              </span>
              <a
                  href="#"
                  class="mr-2 grey--text"
                  @click="downloadFile(booking.otherFiles[0])"
              >{{ otherFileName(booking.otherFiles[0]) }}</a
              >
            </div>
            <div
                v-for="(file, idx) in booking.otherFiles"
                :key="idx"
                v-if="showOtherFiles"
                v-show="idx > 0"
            >
              <a href="#" @click="downloadFile(file)" class="mr-2 grey--text">{{
                  otherFileName(file)
                }}</a>
            </div>
          </v-col>

          <v-col
              cols="12"
              v-if="
              booking.siteId === 4 &&
              booking.kagnotAmount &&
              booking.kagnotAmount > 0
            "
          >
            <div>
              <span>Kagnot générée par ce dossier:</span>
              {{ booking.kagnotAmount | currency("EUR") }}
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div slot="content">
      <smart-dialog
          v-model="dialogs.addPayment"
          max-width="850"
          title="Ajouter un paiement"
      >
        <v-container fluid>
          <v-row dense>
            <v-col cols="4">
              <v-text-field v-model="payment.reference" label="Référence"/>
            </v-col>
          </v-row>
          <v-row v-if="payment.paymentType == 'FIDZIL'">
            <div class="ml-3">
              kagnot disponible: {{ this.availableKagnots.amount }}€
            </div>
          </v-row>
          <v-row dense>
            <v-col cols="4">
              <v-select
                  v-model="payment.paymentType"
                  :items="paymentTypes"
                  label="Type de paiement"
                  @change="checkAmount"
              >
                <template slot="item" slot-scope="data">{{
                    data.item != "FIDZIL"
                        ? $t("paymentTypes." + data.item)
                        : "Kagnot (Covid ou FIDZIL)"
                  }}
                </template>
                <template slot="selection" slot-scope="data">{{
                    data.item != "FIDZIL"
                        ? $t("paymentTypes." + data.item)
                        : "Kagnot (Covid ou FIDZIL)"
                  }}
                </template>
              </v-select>
            </v-col>
            <v-col>
              <smart-number
                  v-model="payment.amount"
                  label="Montant"
                  :rules="
                  payment.paymentType == 'FIDZIL' ? rules.lessThanKagnot() : []
                "
              />
            </v-col>
            <v-col cols="2">
              <v-text-field v-model="payment.currency" label="Devise"/>
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions class="actions-btn">
          <v-spacer/>
          <smart-btn
              primary
              @click.native="addPayment"
              :loading="disablePanel"
              :disabled="!addPaymentValid"
          >Ajouter
          </smart-btn
          >
        </v-card-actions>
      </smart-dialog>

      <v-dialog v-model="dialogs.doCardPayment" max-width="600">
        <v-toolbar dense>
          <span class="title">Paiement par carte</span>
          <v-spacer/>
          <v-btn icon @click="closeDoCardPayment">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card>
          <v-card-text>
            <v-row justify="center" align="center">
              <div xs4 class="pa-1">
                <smart-number v-model="payment.amount" label="Montant"/>
              </div>
              <div xs2 class="pa-1">
                <v-text-field v-model="payment.currency" label="Devise"/>
              </div>
              <div xs3 class="pa-1">
                <v-checkbox v-model="payment.footprint" label="Empreinte"/>
              </div>
              <div xs3 class="pa-1">
                <v-btn @click="openCardPayment">Payer</v-btn>
              </div>
            </v-row>
            <v-row v-show="doPayment">
              <v-col class="pa-1">
                <v-divider class="mb-4"/>
                <payzen-payment-form
                    v-if="doPayment"
                    :payment="payment"
                    @paid="cardPaymentSuccess"
                ></payzen-payment-form>
              </v-col>
            </v-row>

            <v-row v-if="cardPaymentSucceed" justify="center">
              <v-col>
                <v-row justify="center">
                  <v-subheader class="subheading"
                  >Le dossier va se recharger dans :
                  </v-subheader
                  >
                </v-row>
                <v-row class="mt-2 mb-2" justify="center">
                  <span class="display-2">{{
                      cardPaymentReloadCounter >= 0 ? cardPaymentReloadCounter : 0
                    }}</span>
                </v-row>
              </v-col>
            </v-row>

            <!-- <iframe ref="payment" v-if="doPayment" :src="paymentFormUrl" width="100%" height="100%" style="min-height: 600px;" frameBorder="0"></iframe>-->
          </v-card-text>
        </v-card>
      </v-dialog>

      <smart-dialog
          v-model="dialogs.paymentDetails"
          max-width="900"
          title="Paiement par carte"
      >
        <v-card-text>
          <v-data-table
              :items="details"
              :rows-per-page-items="details_rows_per_page"
          >
            <template slot="items" slot-scope="props">
              <td style="width: 250px">{{ props.item.key }}</td>
              <td>{{ props.item.value }}</td>
            </template>
          </v-data-table>
        </v-card-text>
      </smart-dialog>

      <smart-dialog v-model="dialogs.notBookable" title="Paiement impossible">
        <v-card-text>
          <div class="subheading text-center">
            Le devis ne peux pas être transformé en réservation
          </div>
          <ul class="mt-3">
            <li v-for="(e, index) in notBookableErrors" :key="index">
              {{ $t(e) }}
            </li>
          </ul>
        </v-card-text>
        <template slot="actions">
          <smart-btn tertiary @click.native="dialogs.notBookable = false"
          >Fermer
          </smart-btn
          >
        </template>
      </smart-dialog>

      <smart-dialog
          title="Mise à jour du dossier"
          v-model="showRefreshDialog"
          :width="800"
          @close="loadingDetail = false"
      >
        <v-card-text>
          <div v-if="checkdata">
            <div>
              Le prix des prestations suivantes a changé:
              <ul>
                <li v-for="p in checkdata.badPrestations" :key="p.id">
                  {{ p.type }} {{ p.name }}:
                  {{ p.newPrice | currency(booking.currency) }} ({{
                    (p.newPrice - p.oldPrice)
                        | currency(booking.currency, {vary: true})
                  }})
                </li>
              </ul>
            </div>
          </div>
        </v-card-text>
        <template slot="actions">
          <smart-btn secondary @click.native="refreshBooking"
          >Mettre à jour le dossier
          </smart-btn
          >
          <smart-btn
              secondary
              @click.native="
              showRefreshDialog = false;
              dialogs[addPaymentType] = true;
            "
          >Continuer
          </smart-btn
          >
        </template>
      </smart-dialog>

      <smart-dialog
          title="Envoyer un lien de paiement"
          v-model="dialogs.paymentLink"
      >
        <v-form
            v-model="paymentLink.valid"
            ref="form"
            v-on:submit.prevent="sendPaymentLink()"
            class="pt-2"
        >
          <v-card-text>
            <v-row>
              <v-col cols="8" class="pa-1">
                <v-text-field
                    v-model="paymentLink.recipient"
                    :rules="rules.email()"
                    label="Destinataire - email"
                    clearable
                    prepend-inner-icon="mdi-email-outline"
                    autocomplete="nope"
                    required
                ></v-text-field>
              </v-col>
              <v-col cols="4" class="pa-1">
                <smart-number
                    v-model="paymentLink.amount"
                    :rules="rules.number()"
                    label="Montant"
                    clearable
                    prepend-inner-icon="mdi-currency-eur"
                    autocomplete="nope"
                    required
                ></smart-number>
              </v-col>
            </v-row>
            <v-col cols="4" class="pa-1">
              <smart-picker v-if="paymentLink.internal && booking.siteId !== 3"
                            v-model="paymentLink.expirationDate"
                            ref="expirationDatePicker"
                            :min="this._formatDate(new Date(),'dd/MM/yyyy')"
                            :inputOpts="{ label: `Date d'expiration du lien` }"
              ></smart-picker>
            </v-col>
          </v-card-text>
          <v-card-actions class="actions-btn">
            <v-spacer></v-spacer>
            <v-switch v-if="booking.siteId !== 3" dense v-model="paymentLink.internal"></v-switch>
            <smart-btn v-if="paymentLink.internal && booking.siteId !== 3" secondary type="submit" :disabled="!paymentLink.valid"
            >Envoyer un lien {{ booking.siteId === 4 ? 'ZilMaurice' : 'Hotelissima' }}
            </smart-btn
            >
            <smart-btn v-else secondary type="submit" :disabled="!paymentLink.valid"
            >Envoyer un lien Payzen
            </smart-btn
            >
          </v-card-actions>
        </v-form>
      </smart-dialog>

      <smart-dialog
          :width="500"
          title="Générer une attestation de paiement"
          v-model="dialogs.attestation"
      >
        <v-card-title class="mb-1">
          Sélectionner les passagers
        </v-card-title>
        <v-card-text>
          <v-row dense >
            <v-text-field v-model="titleForAttestation" label="Titre du document" class="pa-2"></v-text-field>
          </v-row>
          <v-row dense v-for="guest in booking.globalGuests" :key="guest.id">
            <v-col dense cols="8" class="pa-0">
              <v-checkbox
                  dense hide-details class=""
                  v-model="guestsForAttestation"
                  :label="`${guest.lastName} ${guest.firstName}`"
                  :value="guest.id"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="actions-btn">
          <v-spacer></v-spacer>
          <smart-btn secondary @click.native="downloadAttestation" :disabled="guestsForAttestation.length == 0"
          >Générer
          </smart-btn
          >
        </v-card-actions>

      </smart-dialog>
    </div>
    <div slot="menu">
      <v-list-item @click="openPayment('addPayment')">
        <v-list-item-title>
          <v-icon small>mdi-cash-100</v-icon>
          Ajouter un paiement
        </v-list-item-title>
      </v-list-item>
      <v-list-item @click="openPayment('doCardPayment')">
        <v-list-item-title>
          <v-icon small>mdi-credit-card-plus</v-icon>
          Payer par carte
        </v-list-item-title>
      </v-list-item>
      <v-list-item @click="openPayment('paymentLink')">
        <v-list-item-title>
          <v-icon small>mdi-email-send</v-icon>
          Envoyer un lien de paiement
        </v-list-item-title>
      </v-list-item>
      <v-divider/>
      <v-list-item
          :disabled="booking.invoices.length === 0"
          @click="createInvoice(false)"
      >
        <v-list-item-title>
          <v-icon small>mdi-credit-card-refund</v-icon>
          Générer facture
        </v-list-item-title>
      </v-list-item>
      <v-list-item
          :disabled="booking.invoices.length === 0"
          @click="createInvoice(true)"
      >
        <v-list-item-title>
          <v-icon small>mdi-credit-card-refund</v-icon>
          Générer facture avec prix
        </v-list-item-title>
      </v-list-item>
      <v-list-item
          :disabled="!booking.paid"
          @click="dialogs.attestation = true"
      >
        <v-list-item-title>
          <v-icon small>mdi-credit-card-refund</v-icon>
          Télécharger attestation résa
        </v-list-item-title>
      </v-list-item>
    </div>
  </collapse-panel>
</template>

<script>
import {get, post} from "@/utils/api";
import CollapsePanel from "@/components/commons/CollapsePanel";
import {EventBus} from "@/components/commons/event-bus";
import Availability from "@/components/commons/Availability";
import CancelFees from "@/components/commons/CancelFees";
import PaymentCardDetail from "@/components/booking/payments/PaymentCardDetail";
import PaymentKagnotDetail from "@/components/booking/payments/PaymentKagnotDetail";
import EmptyDetail from "@/components/booking/payments/EmptyDetail";
import PayzenPaymentForm from "./PayzenPaymentForm";
import FormMixin from "../mixins/FormMixin";

export default {
  name: "BookingPayments",
  props: {
    booking: {type: Object},
    kagnots: {type: Object},
  },
  mixins: [FormMixin],
  components: {
    PayzenPaymentForm,
    CollapsePanel,
    Availability,
    CancelFees,
    PaymentCardDetail,
    PaymentKagnotDetail,
    EmptyDetail,
  },
  data() {
    return {
      disablePanel: false,
      showCancelFees: false,
      showInvoices: false,
      showOtherFiles: false,
      showOrders: false,
      availableKagnots: null,
      editBalanceDateDialog: false,
      payment: {
        reference: "",
        bookingId: this.booking.id,
        kagnotId: null,
        kagnotType: null,
        paymentType: "CHECK",
        paymentSource: "backoffice",
        amount: 0,
        status: "confirmed",
        currency: "EUR",
        footprint: false,
        details: {
          type: "EmptyDetail",
        },
      },
      headers: [
        {text: "Date", value: "paymentDate", sortable: true},
        {text: "Type", sortable: false, value: "paymentType"},
        {text: "Statut", sortable: false, value: "paymentStatus"},
        {text: "Montant", sortable: false, value: "amount"},
        {text: "Détails", sortable: false},
      ],

      paymentTypes: [],
      dialogs: {
        addPayment: false,
        doCardPayment: false,
        paymentDetails: false,
        notBookable: false,
        paymentLink: false,
        attestation: false
      },
      notBookableErrors: [],
      doPayment: false,
      cardPaymentSucceed: false,
      cardPaymentReloadCounter: 5,
      details: {},
      details_rows_per_page: [
        10,
        25,
        50,
        {text: "$vuetify.dataIterator.rowsPerPageAll", value: -1},
      ],
      checkdata: null,
      showRefreshDialog: false,
      time: new Date().getTime(),
      reverseCount: null,

      paymentLink: {
        valid: true,
        email: "",
        amount: 0,
        expirationDate: null,
        internal: true
      },
      rules: {
        lessThanKagnot: () => [
          (v) => {
            return this.checkAmount()
                ? true
                : "Le montant saisi est trop élevé";
          },
        ],
      },
      addPaymentValid: true,
      showCommissionPrice: false,

      titleForAttestation:"Attestation de réservation de séjour",
      guestsForAttestation: []
    };
  },
  computed: {
    remainingAmount() {
      const kagnotAmount =
          this.showKagnot && this.booking.price > 0 ? this.kagnots.total : 0;
      return (
          Math.max(0, this.booking.price - kagnotAmount) - this.booking.paidAmount
      );
    },
    paymentFormUrl() {
      return (
          "/api/payment/iframe/form?payment=" +
          JSON.stringify(this.payment) +
          "&t=" +
          this.time
      );
    },
    cancelFee() {
      const now = new Date();
      let fee = this.booking.cancelfees.fees.find((f) => {
        return (
            this._parseDate(f.start, "dd/MM/yyyy") < now &&
            this._parseDate(f.stop, "dd/MM/yyyy") > now
        );
      });

      if (!fee && this.booking.cancelfees.fees.length > 0) {
        if (
            this._parseDate(this.booking.cancelfees.fees[0].start, "dd/MM/yyyy") >
            now
        ) {
          fee = this.booking.cancelfees.fees[0];
        } else {
          fee =
              this.booking.cancelfees.fees[
              this.booking.cancelfees.fees.length - 1
                  ];
        }
      }
      return fee;
    },
    showKagnot() {
      return this.booking.status === "quotation" && this.booking.customerId;
    },
  },
  watch: {
    booking: {
      deep: true,
      handler() {
        this.init();
      },
    },
    cardPaymentReloadCounter: {
      deep: true,
      handler() {
        if (this.cardPaymentReloadCounter == 0) {
          EventBus.$emit("reloadbooking", {});
          EventBus.$emit("toaster-msg", "Paiement enregistré");
          this.closeDoCardPayment();
        }
      },
    },
  },
  created: function () {
    this.paymentTypes = [
      "CHECK",
      "CHECK_HOLIDAY",
      "TRANSFER",
      "CREDIT",
      "FIDZIL",
      "CREDIT_COVID19",
      "COMMERCIAL_DISCOUNT",
      "FRAUD"
    ];
    this.init();
    window.addEventListener("message", this.message);

    EventBus.$on("toggleCommissionPrice", (payload) => {
      this.showCommissionPrice = payload;
    });
  },
  destroyed: function () {
    EventBus.$off("toggleCommissionPrice");
  },
  methods: {
    expireDate(payment) {
      return this._formatDate(
          this._addDays(
              this._parseDate(
                  payment.details.transactionDate,
                  this.datePatternConfig.serverLocalDateTime
              ),
              payment.details.captureDelay,
              "days"
          ),
          "dd/MM"
      );
    },
    message(e) {
      if (e.data.type === "paymentSuccess") {
        this.dialogs.doCardPayment = false;
        EventBus.$emit("reloadbooking", {});
      }
    },

    init() {
      if (this.booking.payments.length === 0) {
        get(`/api/booking/${this.booking.id}/deposit`).json(
            (data) => (this.payment.amount = data.deposit)
        );
      }

      this.initPaymentLink();
      this.getAvailableKagnots();
    },
    expirationDatePaymentLink() {
      return this._formatDate(this._addDays(new Date(), 3), "dd/MM/yyyy")
    },
    getAvailableKagnots() {
      if (this.booking.customerId) {
        const url = `/api/customer/${this.booking.customerId}/allkagnots`;

        get(url).json((res) => {
          this.availableKagnots = res.availableKagnots;
        });
      }
    },
    openPayment(paymentType) {
      this.addPaymentType = paymentType;
      if (this.booking.status === "quotation") {
        get(`/api/booking/${this.booking.id}/isbookable`)
            .json((data) => {
              if (!data.isbookable) {
                this.dialogs.notBookable = true;
                this.notBookableErrors = data.errors;
              }
              return data;
            })
            .then((data) => data.isbookable)
            .then((isok) => {
              if (isok) {
                return get(
                    `/api/booking/${this.booking.id}/check-price?withFlightDeltaPrice=false`
                )
                    .badRequest((err) => {
                      this.dialogs.notBookable = true;
                      this.notBookableErrors = [err];
                    })
                    .json((data) => {
                      this.checkdata = data;
                      if (!data.success) {
                        this.showRefreshDialog = true;
                      }
                      return data;
                    })
                    .then((data) => data.success);
              }

              return isok;
            })
            .then((isok) => {
              if (isok) {
                this.dialogs[paymentType] = true;
              }
            });
      } else {
        this.dialogs[paymentType] = true;
      }
    },
    addPayment() {
      this.disablePanel = true;

      if (this.payment.paymentType == "FIDZIL") {
        let form = {
          ...this.payment,
        };
        post("/api/payment/kagnot/add", form).res((data) => {
          this.dialogs.addPayment = false;
          this.disablePanel = false;
          EventBus.$emit("reloadbooking", {});
        });
      } else {
        post("/api/payment/add", this.payment).res((data) => {
          this.dialogs.addPayment = false;
          this.disablePanel = false;
          EventBus.$emit("reloadbooking", {});
        });
      }
    },
    closeDoCardPayment() {
      this.dialogs.doCardPayment = false;
      this.doPayment = false;
      this.cardPaymentSucceed = false;
      clearInterval(this.reverseCount);
    },
    openCardPayment() {
      const doPayment = () => {
        this.doPayment = true;
        this.time = new Date().getTime();
      };

      if (this.doPayment) {
        this.doPayment = false; //reset value to render another form with different amount
        this.$nextTick(() => {
          doPayment();
        });
      } else {
        doPayment();
      }
    },
    cardPaymentSuccess() {
      this.doPayment = false;
      this.cardPaymentSucceed = true;

      if (this.cardPaymentSucceed) {
        this.cardPaymentReloadCounter = 5;

        this.reverseCount = setInterval(() => {
          if (this.cardPaymentReloadCounter > 0) {
            this.cardPaymentReloadCounter--;
          }
        }, 1000);
        if (this.cardPaymentReloadCounter == 0)
          clearInterval(this.reverseCount);
      }
    },
    openPaymentDetail(payment) {
      this.details = Object.keys(payment.details.params).map((k) => {
        return {key: k, value: payment.details.params[k]};
      });
      this.dialogs.paymentDetails = true;
    },
    changeBalanceDate() {
      post(`/api/booking/${this.booking.id}/balance-date`, {
        date: this.booking.balanceDate,
      }).json(() => {
        EventBus.$emit("reloadbooking", {});
      });
    },
    downloadInvoice(id) {
      const url = `/document/invoice/${this.booking.id}?invoiceid=${id}`;
      const win = window.open(url, "_blank");
      win.focus();
    },
    downloadFile(file) {
      const win = window.open("/api/file/download/" + file, "_blank");
      win.focus();
    },
    refresh() {
      const promises = this.booking.payments.map((payment) => {
        return post(`/api/payment/iframe/${payment.id}/refresh`).json();
      });

      Promise.all(promises).then(() => {
        EventBus.$emit("reloadbooking", {});
      });
    },
    refreshBooking() {
      get(`/api/booking/${this.booking.id}/refresh-price`)
          .badRequest((err) => {
            EventBus.$emit("toaster-msg", err);
          })
          .res(() => {
            EventBus.$emit("toaster-msg", "Dossier mis à jour");
            EventBus.$emit("reloadbooking", {});
            this.showRefreshDialog = false;
          });
    },
    createInvoice(withPriceDetails) {
      get(
          `/api/booking/${this.booking.id}/create-invoice?withPriceDetails=${withPriceDetails}`
      )
          .badRequest((err) => {
            EventBus.$emit("toaster-msg", err);
          })
          .res(() => {
            EventBus.$emit("toaster-msg", "Dossier mis à jour");
            EventBus.$emit("reloadbooking", {});
          });
    },

    downloadAttestation() {
      const qs = this.guestsForAttestation.map(id => `guestId=${id}`).join('&')
      const url = `/document/attestation/${this.booking.id}?${qs}&label=${this.titleForAttestation}`

      const win = window.open(url, "_blank");
      this.dialogs.attestation = false
      win.focus();
    },

    initPaymentLink() {
      this.paymentLink = {
        site: this.booking.siteId,
        bookingId: this.booking.id,
        recipient: this.booking.customer.email,
        amount: this.remainingAmount,
        expirationDate: this.expirationDatePaymentLink(),
        internal: true
      };
    },
    sendPaymentLink() {
      if(this.booking.siteId === 3){
        this.paymentLink.internal = false
      }
      const url = `/api/payment/createPaymentOrder`;
      console.log(this)
      post(url, this.paymentLink)
          .badRequest((err) => {
            EventBus.$emit("toaster-msg", err);
          })
          .res((r) => {
            if (r.ok) {
              EventBus.$emit("reloadbooking", {});
              EventBus.$emit("toaster-msg", "Mail Envoyé");
              this.dialogs.paymentLink = false;
            }
          });
    },

    otherFileName(path) {
      const idx = path.lastIndexOf("/");
      return path.substring(idx + 1);
    },

    maxfees() {
      this.$root
          .$confirm(
              "",
              "Souhaitez vous appliquer 100% de frais aux prestations ?"
          )
          .then(() => {
            post(`/api/booking/${this.booking.id}/maxfees`)
                .badRequest((err) => {
                  EventBus.$emit("toaster-msg", err);
                })
                .res((r) => {
                  EventBus.$emit("toaster-msg", "Dossier mis à jour");
                  EventBus.$emit("reloadbooking", {});
                });
          });
    },
    checkAmount() {
      if (
          this.payment.paymentType == "FIDZIL" &&
          this.payment.amount > 0 &&
          this.payment.amount > this.availableKagnots.amount
      ) {
        this.addPaymentValid = false;
        return false;
      } else {
        this.addPaymentValid = true;
        return true;
      }
    },
  },
};
</script>

<style scoped>
.refused .amount {
  text-decoration: line-through;
}
</style>
