<template>
	<div>
		<v-row dense >

			<v-col cols="12">
				<v-autocomplete
					class="pa-1"
					label="Adresse"
					autocomplete="new-password"
					v-model="fullstreet"
					:items="streets"
					:search-input.sync="searchStreet"
					return-object
					item-text="label"
					item-value="streetName"
					auto-select-first
					hide-no-data
					hide-details
					no-filter
					@input="updateAddress"
				>
					<template
						slot="selection"
						slot-scope="{ item }"
					>
						<span>{{item.streetName}}</span>
					</template>

					<template
						slot="item"
						slot-scope="{ parent, item, tile }"
					>
						<v-list-item-content>
							<v-list-item-title dense>
								{{item.label}}
							</v-list-item-title>
						</v-list-item-content>

					</template>
				</v-autocomplete>

			</v-col>
			<v-col cols="12">
				<v-text-field
					class="pa-1"
					label="Complément d'addresse"
					autocomplete="new-password"
					v-model="address.streetcplmt"
					hide-details
				></v-text-field>
			</v-col>
			<v-col cols="4">
				<v-autocomplete
					class="pa-1"
					v-model="address.country"
					:loading="countryloading"
					:items="countries"
					:search-input.sync="searchCountries"
					:rules="ready ? rules.nonEmpty() : []"
					item-value="name"
					item-text="name"
					cache-items
					label="Pays*"
					autocomplete="new-password"
					required
				></v-autocomplete>
			</v-col>
			<v-col cols="4">
				<v-text-field
					class="pa-1"
					label="Ville"
					autocomplete="new-password"
					v-model="address.city"
				></v-text-field>
			</v-col>
			<v-col cols="4">
				<v-text-field
					class="pa-1"
					label="Code postal"
					autocomplete="new-password"
					v-model="address.postalCode"
				></v-text-field>
			</v-col>
			<!-- <v-flex xs12>
				<v-text-field
					class="pa-1"
					label="Adresse"
					autocomplete="new-password"
					v-model="address.street"
				></v-text-field>
			</v-flex> -->

		</v-row>
	</div>
</template>

<script>
	import {get} from "@/utils/api"
	import FormMixin from "@/components/mixins/FormMixin";

	export default {
		name: "CustomerAddress",
		props: {
			value: {
				type: Object,
				required: true
			}
		},
		mixins: [FormMixin],
		data() {
			return {

				userType: null,
				fullstreet:null,

				address: {
					country: '',
					city: null,
					street: null,
					streetcplmt: null,
					postalCode:null,
				},

				streets: [],
				searchStreet: String,


				countries: [],
				countryloading: false,
				searchCountries: null,

				ready: false,

			}
		},
		created() {
			this.getCountries("")
			this.emitForm()
		},
		watch: {
			searchStreet(val) {
				val && this.getAddress(val)
			},
			value: {
				immediate: true, handler(newVal) {
					this.init(newVal)
				}
			},
			address: {
				deep: true, immediate: true, handler(newVal) {
					this.$emit('input', newVal)
				}
			},
		},
		computed: {},
		methods: {
			init(val) {
				if (val != null) {
					this.address = val
					this.fullstreet = this.address.street
					this.searchStreet = this.address.street
				}
				this.$nextTick(() => {
					this.ready = true
				})
			},
			getAddress(query) {
				const url = `/api/searchaddress?q=${query}`
				get(url)
					.json((results) => {

						this.streets = results.map(r => {
								return {
									...{
										'label': `${r.streetName} - ${r.cityName} ${r.postalCode}`
									}, ...r
								}
							})
					})
			},
			getCountries(query) {
				this.countryloading = true
				const url = `/api/search/countries?q=${query}`
				get(url)
					.json((r) => {
						this.countries = r
						this.countryloading = false
					})
			},
			updateAddress(){
				if(this.fullstreet && this.fullstreet.streetName && this.fullstreet.streetName !== '')
					this.address = {
						id: this.address.id || null,
						country: this.address.country,
						city: this.fullstreet.cityName,
						street: this.fullstreet.streetName,
						streetcplmt: this.address.streetcplmt,
						postalCode: this.fullstreet.postalCode
					}
			},
			emitForm() {
				this.$emit("input", this.address)
			},
	}
	}
</script>

<style scoped>

</style>
