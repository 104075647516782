<template>
  <layout :drawer="true" :drawerShow="_isDesktop()" :drawerWidth="300">
    <template slot="drawer">
      <v-form ref="searchform" v-on:submit.prevent="search">
        <v-subheader light class="pa-0 mb-0 mt-4 mx-0 form-subheader"
          >Connexions réalisées entre:
        </v-subheader>
        <v-divider></v-divider>
        <v-row justify="start">
          <v-col cols="6">
            <smart-picker
              v-model="form.startDateLog"
              :next-picker="$refs.stopDateLog"
              format="dd/MM/yyyy"
              :inputOpts="{ label: $t('begin') }"
            >
            </smart-picker>
          </v-col>
          <v-col cols="6">
            <smart-picker
              ref="stopDateLog"
              v-model="form.stopDateLog"
              format="dd/MM/yyyy"
              :inputOpts="{ label: $t('end') }"
            >
            </smart-picker>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" class="pr-1">
            <v-select
              label="Site"
              item-value="value"
              item-text="text"
              :items="countries"
              v-model="form.selectedCountry"
              :return-object="false"
            />
          </v-col>
        </v-row>

        <smart-btn
          :loading="listing.loading"
          class="mt-2"
          type="submit"
          block
          primary
          @click="search"
          >Rechercher</smart-btn
        >
      </v-form>
    </template>

    <template slot="content">
      <v-container fluid>
        <v-data-table
          :headers="listing.headers"
          :loading="listing.loading"
          :items="listing.items"
          :sort-by="'countLog'"
          :sort-desc="true"
          :options.sync="listing.pagination"
          :hide-default-footer="true"
          :footer-props="{
            'items-per-page-options': listing.rowsPerPageItems,
            'items-per-page-text': 'Lignes par page',
          }"
          class="elevation-1 tbl"
          no-data-text="Il n'y a pas d'agences à afficher"
        >
          <template #item.agencyName="{ item, value }">
            <v-row class="agencyName">
              <v-col cols="6">{{ value }}</v-col>
              <v-col class="button">
                <router-link
                  :to="{
                    name: 'CrmAgencyDetails',
                    params: { id: item.agencyId },
                  }"
                  target="_blank"
                >
                  <v-chip x-small color="green" link outlined> Voir </v-chip>
                </router-link>
              </v-col>
            </v-row>
          </template>
        </v-data-table>
      </v-container>
    </template>
  </layout>
</template>

<script>
import Layout from "@/views/Layout";
import { get, post } from "@/utils/api";
import SmartMultiSelect from "../../components/commons/SmartMultiSelect";
import CrmAgencyInfo from "../../components/commons/CrmAgencyInfo.vue";
import _ from "lodash";

const countries = [
  { text: "France", value: "france" },
  { text: "Italie", value: "italy" },
];
export default {
  name: "AgentActivity",
  components: {
    Layout,
    SmartMultiSelect,
    CrmAgencyInfo,
  },
  props: {},
  data(vm) {
    return {
      form: {
        selectedCountry: countries[0].value,
        startDateLog: vm._formatDate(
          vm._getFirstMonthDay(new Date()),
          "dd/MM/yyyy"
        ),
        stopDateLog: vm._nowFormatted("dd/MM/yyyy"),
      },
      countries: countries,
      listing: {
        rowsPerPageItems: [{ text: "Tous", value: -1 }],
        pagination: {
          options: {
            page: 1,
            sortBy: [],
            sortDesc: [false],
          },
        },
        loading: false,
        headers: [
          {
            text: "Agence",
            value: "agencyName",
          },
          {
            text: "Agent",
            value: "agentName",
          },
          {
            text: "Connexions",
            value: "countLog",
            align: "center",
          },
          {
            text: "Nb paniers",
            value: "countCart",
            align: "center",
          },
          {
            text: "Nb Devis",
            value: "countQuotation",
            align: "center",
          },
          {
            text: "Nb Résas",
            value: "countBooked",
            align: "center",
          },
        ],
        items: [],
      },
    };
  },
  created() {
    this.search();
  },
  computed: {
    formatedForm() {
      return {
        ...this.form,
        ...{
          startDateLog: this._parseAndFormatDate(
            this.form.startDateLog,
            this.datePatternConfig.serverLocalDate,
            this.datePatternConfig.en.localDate
          ),
          stopDateLog: this._parseAndFormatDate(
            this.form.stopDateLog,
            this.datePatternConfig.serverLocalDate,
            this.datePatternConfig.en.localDate
          ),
        },
      };
    },
  },
  methods: {
    async search() {
      if (!this.listing.loading) {
        this.listing.items = await this.getData();
        this.listing.loading = false;
      }
    },
    async getData() {
      this.listing.loading = true;
      const url = "/api/agency/activities/log/agent";
      return post(url, this.formatedForm).json((data) => {
        return data.map((d) => {
          return {
            agentId: d["0"],
            agencyName: d["1"],
            agentName: d["2"],
            countLog: d["3"],
            countCart: d["4"],
            countQuotation: d["5"],
            countBooked: d["6"],
            ca: d["7"],
            agencyId: d["8"],
          };
        });
      });
    },
  },
};
</script>
<style scoped>
>>> .button {
  visibility: hidden;
}

.agencyName:hover > .button {
  visibility: initial;
}
</style>
