<template>
  <div>
    <v-form class="pa-4">
      <v-row>
        <v-col cols="6">
          <v-select
              v-model="action.type"
              :items="types"
              item-value="id"
              item-text="label"
              label="Type"
              :error="errorType"
              dense
              hide-details
              placeholder=""
          />
        </v-col>
        <v-col cols="6">
          <v-select v-model="action.user"
                    :items="users"
                    item-value="id"
                    item-text="fullName"
                    label="Agent Hotelissima "
                    dense
                    hide-details
                    placeholder=""/>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <p class="font-weight-medium">Commentaire</p>
          <v-textarea v-model="action.message"/>
        </v-col>
      </v-row>
    </v-form>
    <v-card-actions class="actions-btn">
      <v-spacer/>
      <smart-btn @click.native="save">
        <v-icon small class="mr-1">mdi-content-save</v-icon>
        Créer
      </smart-btn>
    </v-card-actions>

  </div>
</template>

<script>
import {get, post} from "@/utils/api";
import {EventBus} from "@/components/commons/event-bus";

export default {
  name: "LeadActionCreation",
  props: {
    lead: {type: Object, required: true},
  },
  data() {
    return {
      action: {
        creationDate: null,
        type: null,
        message: null,
        leadId: null,
        user: null,
        bookingId: null,
        phonecallId: null,
      },
      errorType:false,
      types: [],
      users: [],
    }
  },
  created() {
    const loadActionTyppe = get("/api/lead/actions/types").json((rs) => {
      this.types = rs.map((tp) => {
        return {id: tp, label: this.$t("lead.action." + tp)}
      })
    })

    const loadUsers = get("/api/search/users").json(rs => {
      this.users = rs.map(u => u = {...u, fullName: `${u.firstname} ${u.lastname}`})
    })

    Promise.all([
      loadActionTyppe, loadUsers
    ]).then(() => {
      this.loading = false
    });
    this.action.leadId = this.lead.id;
    this.action.user = this.lead.user.id;
  },
  methods: {
    save() {
      if (this.action.type != null) {
        this.action.user = this.users.find(u => this.action.user === u.id)
        post("/api/lead/add/action", this.action).json(() => {
          EventBus.$emit('toaster-msg', "Action créée");
          EventBus.$emit("reloadLead", {});
          this.$emit("done")

        })
      } else {
        this.errorType = true
      }
    }
  },
  watch:
      {
        action: {
          deep: true,
          handler(newVal) {
            if (newVal.type) {
              this.errorType = false
            }
          }
        }
      }
}
</script>