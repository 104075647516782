<template>
  <layout :drawerShow="_isDesktop()" :drawerWidth="350">
    <template slot="drawer">
      <v-form ref="searchform" v-if="searchform" @submit.prevent="search">
        <v-row no-gutters class="pt-2 pr-1">
          <v-col cols="12" class="pr-1 mt-2">
            <v-select
                v-model="searchform.site"
                :items="sites"
                item-value="id"
                item-text="name"
                label="Site"
                dense
                hide-details
                placeholder=""
            />
          </v-col>
          <v-col class="pr-1 pt-5" cols="6">
            <smart-picker
                v-model="searchform.startDate"
                :stopDate="searchform.stopDate"
                :inputOpts="{ label: $t('begin') }"
            />
          </v-col>
          <v-col cols="6" class="pt-5">
            <smart-picker
                v-model="searchform.stopDate"
                :startDate="searchform.startDate"
                :inputOpts="{ label: $t('end') }"
            />
          </v-col>
        </v-row>
        <smart-btn class="mt-3"
                   :disabled="!formValid"
                   :loading="loading"
                   type="submit"
                   block
                   primary
        >Rechercher
        </smart-btn
        >
        <smart-btn
            block
            tertiary
            @click.native="
            clearSearchform();
          "
        >Réinitialiser la recherche
        </smart-btn
        >
      </v-form>
    </template>
    <template slot="content">
      <div class="d-flex flex-column pa-4 " v-if="!loading">
        <div class="d-flex white mb-2 py-4 px-6 rounded-lg" v-if="groupedAllDatas != null" style="flex-wrap: nowrap;">
          <v-row class="d-flex flex-column w-full mr-4">
            <apexchart
                ref="apexcharts"
                type="line"
                :options="{...groupedAllOpt, title:{text:'Toutes destinations'}}"
                height="400"
                width="100%"
                :series="[groupedAllDatas.twentyDays, groupedAllDatas.week, groupedAllDatas.day]"
            />
          </v-row>
          <div style="width:15%;"
               class="  ml-auto blue-grey lighten-1 rounded-lg pa-2 white--text d-flex flex-column align-center justify-space-around">
            <div class="d-flex flex-column">
              <span class="body-1 mb-2">7 jours:</span>
              <span class="headline"> {{ getWidgets(groupedAllDatas, 'Toutes destinations').week }}</span>
            </div>
            <div class="d-flex flex-column">
              <span class="body-1 mb-2">20 Jours:</span>
              <span class="headline"> {{ getWidgets(groupedAllDatas, 'Toutes destinations').twentyDays }}</span>
            </div>
          </div>

        </div>
        <div class="d-flex white mb-2 py-4 px-6 rounded-lg" style="flex-wrap: nowrap;" v-for="desti in destiData"
             :key="desti">
          <v-row class="d-flex flex-column w-full mr-4">
            <apexchart
                ref="apexcharts"
                type="line"
                :options="{...options, title:{text:desti}}"
                height="350"
                width="100%"
                :series="[data[desti].twentyDays, data[desti].week, data[desti].day]"
            />
          </v-row>
          <div style="width:15%;"
               class="  ml-auto blue-grey lighten-1 rounded-lg pa-2 white--text d-flex flex-column align-center justify-space-around">
            <div class="d-flex flex-column">
              <span class="body-1 mb-2">7 jours:</span>
              <span class="headline"> {{ getWidgets(data[desti], desti).week }}</span>
            </div>
            <div class="d-flex flex-column">
              <span class="body-1 mb-2">20 Jours:</span>
              <span class="headline"> {{ getWidgets(data[desti], desti).twentyDays }}</span>
            </div>
          </div>

        </div>
      </div>
      <v-skeleton-loader v-else class="" height="100%"
                         type="list-item-avatar-two-line, list-item-three-line@7"></v-skeleton-loader>
    </template>
  </layout>
</template>
<script>
import Layout from "@/views/Layout";
import {get, post} from "@/utils/api";
import FormMixin from "@/components/mixins/FormMixin";
import {groupBy} from "lodash/collection";

export default {
  name: "RoiStats",
  components: {
    Layout
  },
  mixins: [FormMixin],
  data() {
    return {
      searchform: {
        site: null,
        startDate: null,
        stopDate: null,
      },
      defaultSearchform: {
        site: 1,
        startDate: this._formatDate(this._subDays(new Date(), 91), "dd/MM/yyyy"),
        stopDate: this._formatDate(this._subDays(new Date(), 1), "dd/MM/yyyy"),
      },
      sites: [],
      loading: false,
      loadingSites: true,
      destiData: [],
      data: null,
      groupedAllDatas: null,
      groupedAllOpt: null,
      options: {
        title: {text: null},
        colors: ['#3d85c5', '#6fa8dc', '#9ec5e8'],
        stroke: {
          show: true,
          curve: 'smooth',
          width: [4, 2, 1.5],
        },
        chart: {
          defaultLocale: 'fr',
          id: "area",
          datetimeUTC: false,
          type: "line",
          stacked: false,
          zoom: {
            enabled: true,
            autoScaleYaxis: false,
          },
          locales: [{
            name: "fr",
            options: {
              months: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Aout", "Septembre", "Octobre", "Novembre", "Décembre"],
              shortMonths: ["Jan", "Fev", "Mar", "Avr", "Mai", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
              days: ["Dimanche", "Lundi", "Merdi", "Mercredi", "Jeudi", "Vendredi", "Samedi"],
              shortDays: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
            }
          }],
          labels: {
            show: true,
          }
        },
        yaxis: {
          decimalsInFloat: true,
          floating: false,
          min: 0,
          labels: {
            show: true,
            formatter: (value) => Math.round(value * 100) / 100 + '%',
            minWidth: 0,
            maxWidth: 160,
            style: {
              fontSize: '14px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 400,
              cssClass: 'apexcharts-xaxis-label',
            },
          },
          tickAmount: 4,
          stepSize: 25,
        },
        xaxis: {
          floating: false,
          position: 'bottom',
          axisTicks: {
            show: true,
          },
          min: new Date(`${new Date().getFullYear()}-01-01`),
          max: new Date(`${new Date().getFullYear()}-12-31`),
          type: 'datetime',
          tickAmount: 'dataPoints',
          labels: {
            datetimeUTC: false,
            format: 'dd/MM',
            show: true,
            tickAmount: 12,
            datetimeFormatter: {
              year: 'yyyy',
              month: 'MMMM ',
              day: 'dd MMMM',
              hour: 'HH:mm'
            },
            rotate: -45,
            rotateAlways: false,
            hideOverlappingLabels: true,
            showDuplicates: false,
            trim: false,
            minHeight: 20,
            maxHeight: 120,
            style: {
              fontSize: '12px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 400,
              cssClass: 'apexcharts-xaxis-label',
            },
          }
        },
        dataLabels: {
          enabled: false
        },
        tooltip: {
          custom: {},
          shared: true,
          enabled: true
        },
        legend: {position: 'top'},
        annotations: {
          xaxis: [],
        },
      },
    }
  },
  created() {
      const loadSites = get("/api/search/sites").json((rs) => {
      this.sites = rs;
    });
    Promise.all([
      loadSites,
    ]).then(() => {

      this.loadingSites = false
      this.clearSearchform()
    });
  },
  computed: {
    formValid() {
      if (this.searchform.site != null) {
        return this.$refs.searchform.validate()
      }
      return false
    }
  },
  methods: {
    search() {
      this.loading = true;
      const body = {
        ...this.searchform,
        startDate: this._formatDate(this._subDays(this._parseDate(this.searchform.startDate, "dd/MM/yyyy"), 20), "yyyy-MM-dd"),
        stopDate: this._parseAndFormatDate(this.searchform.stopDate, "dd/MM/yyyy", "yyyy-MM-dd")
      }
      post(`/api/bigquery/stats/rois/${this.searchform.site}`, body).json((rs) => {

        const result = rs.reduce((prev, act) => {

          const date = this._parseDate(act.realDate, "yyyy-MM-dd")
          const y = act.revenuBooked <= 0 ? 0 : this.getAvgDay(act.spend, act.purchaseBooked, act.revenuBooked)
          if (!(y === 0 && prev[act.destinationAds] == null)) {

            if (prev[act.destinationAds] == null) {
              prev[act.destinationAds] = {
                day: {
                  name: "Journalier",
                  type: 'line',
                  data: [{
                    x: date,
                    y: y * 100,
                    spend: act.spend,
                    purchase: act.purchaseBooked,
                    revenu: act.revenuBooked
                  }]
                },
                week: {name: "7 jours", type: 'line', data: []},
                twentyDays: {name: "20 jours", type: 'line', data: []},
              }
            } else {
              prev[act.destinationAds].day.data.push({
                x: date,
                y: y * 100,
                spend: act.spend,
                purchase: act.purchaseBooked,
                revenu: act.revenuBooked
              })
            }
          }


          return prev
        }, {})
        this.destiData = Object.keys(result).filter(k => k != "")
        this.groupAll(rs)

        this.destiData.forEach(desti => {
          result[desti].day.data.forEach((jour, i) => {
            if (i >= 6) {
              this.getAvgPeriod(jour.x, result[desti], i, true)
            }
            if (i >= 19) {
              this.getAvgPeriod(jour.x, result[desti], i, false)
            }

          })

        })


        this.destiData.forEach(desti => {
          this.matchDatasSearchForm(result[desti])
        })

        this.data = result

        this.loading = false
      })
      this.options.xaxis.min = this.searchform.startDate
      this.generateTooltip()

    },
    groupAll(rs) {
      rs = rs.filter(r => this.destiData.includes(r.destinationAds));

      const groups = rs.reduce((groups, day) => {
        const date = day.realDate;
        if (!groups[date]) {
          groups[date] = [];
        }
        groups[date].push(day);
        return groups;
      }, {});

      const datas = Object.keys(groups).map((date) => {
        return {
          date,
          days: groups[date]
        };
      });

      const groupArrays = datas.map((data) => {
        return data.days.reduce((a, b) => {
              b = {
                date: data.date,
                spend: b.spend + a.spend,
                purchaseBooked: b.purchaseBooked + a.purchaseBooked,
                revenuBooked: b.revenuBooked + a.revenuBooked,
              }
              return b
            },
            {
              spend: 0,
              purchaseBooked: 0,
              revenuBooked: 0,

            })

      })

      const all = groupArrays.reduce((prev, act) => {

        const date = this._parseDate(act.date, "yyyy-MM-dd")
        const y = act.revenuBooked <= 0 ? 0 : this.getAvgDay(act.spend, act.purchaseBooked, act.revenuBooked)
        prev.day.data.push({
          x: date,
          y: y * 100,
          spend: act.spend,
          purchase: act.purchaseBooked,
          revenu: act.revenuBooked
        })
        return prev
      }, {
        day: {
          name: "Journalier",
          type: 'line',
          data: []
        },
        week: {name: "7 jours", type: 'line', data: []},
        twentyDays: {name: "20 jours", type: 'line', data: []},
      })




      all.day.data.forEach((jour, i) => {
        if (i >= 7) {
          let spend = 0
          let purchase = 0
          let rev = 0
          all.day.data.slice(i - 7, i).map((d) => {
            spend = spend + d.spend
            purchase = purchase + d.purchase
            rev = rev + d.revenu
          })

          const y = rev == 0 ? 0 : this.getAvgDay(spend, purchase, rev)
          all.week.data.push({
            x: jour.x,
            y: y * 100,
          })

        }
        if (i >= 20) {
          let spend = 0
          let purchase = 0
          let rev = 0
          all.day.data.slice(i - 20, i).map((d) => {
            spend = spend + d.spend
            purchase = purchase + d.purchase
            rev = rev + d.revenu
          })
          const y = rev == 0 ? 0 : this.getAvgDay(spend, purchase, rev)
          all.twentyDays.data.push({
            x: jour.x,
            y: y * 100,
          })
        }
      })



      this.matchDatasSearchForm(all)


      this.groupedAllDatas = all
      this.groupedAllOpt = this.options
      this.groupedAllOpt.chart.id = 'allDesti'
      this.groupedAllOpt.chart.type = 'line'


    },
    matchDatasSearchForm(serie) {
      serie.day.data = serie.day.data.filter(jour => this._isSameOrAfter(jour.x, this._parseDate(this.searchform.startDate, "dd/MM/yyyy")))
      serie.week.data = serie.week.data.filter(jour => this._isSameOrAfter(jour.x, this._parseDate(this.searchform.startDate, "dd/MM/yyyy")))
      serie.twentyDays.data = serie.twentyDays.data.filter(jour => this._isSameOrAfter(jour.x, this._parseDate(this.searchform.startDate, "dd/MM/yyyy")))
    },
    generateTooltip() {
      const self = this
      this.options.tooltip.custom = function ({series, seriesIndex, dataPointIndex, w}) {
        const date = self._formatDate(new Date(w.globals.seriesX[seriesIndex][dataPointIndex]), "eee dd MMMM yyyy")
        const values = self.getAvgValuesByDay(date, seriesIndex, dataPointIndex, w.config.title.text)
        const color = self.options.colors
        if (date !== null && w.config.series[seriesIndex] != null && series[seriesIndex] != null) {
          let firstLine = ""
          let secondLine = ""
          let thirdLine = ""
          if (values.day != null && values.day != "") {
            firstLine = `<div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;" >
                  <span class="apexcharts-tooltip-marker" style="background-color: ${color[2]}"></span>
                    <div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
                      <div class="apexcharts-tooltip-y-group d-flex flex-row justify-space-between">
                        <span class="apexcharts-tooltip-text-y-label">${w.config.series[2].name} </span>
                        <span class="apexcharts-tooltip-text-y-value">${values.day}%</span>
                      </div>
                    </div>
                  </div>`
          }

          if (values.week != null && values.week != "") {
            secondLine = `  <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">
                  <span class="apexcharts-tooltip-marker" style="background-color: ${color[1]}"></span>
                    <div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
                      <div class="apexcharts-tooltip-y-group d-flex flex-row justify-space-between">
                        <span class="apexcharts-tooltip-text-y-label">${w.config.series[1].name} </span>
                        <span class="apexcharts-tooltip-text-y-value">${values.week}%</span>
                      </div>
                    </div>`
          }
          if (values.twenty != null && values.twenty != "") {
            thirdLine = `  <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">
                  <span class="apexcharts-tooltip-marker" style="background-color: ${color[0]}"></span>
                    <div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
                      <div class="apexcharts-tooltip-y-group d-flex flex-row justify-space-between">
                        <span class="apexcharts-tooltip-text-y-label">${w.config.series[0].name} </span>
                        <span class="apexcharts-tooltip-text-y-value">${values.twenty}%</span>
                      </div>
                    </div>`
          }
          return `
                <div style="left: 663.205px; top: 107.287px;">
                  <div class="apexcharts-tooltip-title arrow_box" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
                  ${date}
                  </div>
                  <div class="flex flex-column">
                  <div>${firstLine}</div>
                  <div>${secondLine}</div>
                  <div>${thirdLine}</div>
                  </div>
                  </div>
                </div>`
        } else {
          return ""
        }
      }
    }
    ,
    clearSearchform() {
      this.searchform = {...this.defaultSearchform};
    }
    ,
    getWidgets(serie, desti) {

      let result = {
        week: 0,
        twenty: 0
      }
      this.options.title.text = desti;

      [7, 20].map((s) => {
        let length = serie[s === 7 ? 'week' : 'twentyDays'].data.length

        result[s === 7 ? 'week' : 'twentyDays'] = length <= 0 || serie[s === 7 ? 'week' : 'twentyDays'].data[length - 1] == null ? '-' : Math.round(serie[s === 7 ? 'week' : 'twentyDays'].data[length - 1].y * 100) / 100 + "%"//((Math.round(this.getAvgDay(spend, purchase, rev) * 10000) / 100) + "%")
      })

      return result

    }
    ,
    getAvgDay(spend, purchase, rev) {
      return (1 - ((spend + purchase) / rev))
    }
    ,
    getAvgPeriod(date, serie, i, isWeek) {
      let spend = 0
      let purchase = 0
      let rev = 0
      serie.day.data.slice(i - (isWeek ? 7 : 20), i).map((d) => {
        spend = spend + d.spend
        purchase = purchase + d.purchase
        rev = rev + d.revenu
      })
      const y = rev == 0 ? 0 : this.getAvgDay(spend, purchase, rev)
      serie[isWeek ? 'week' : 'twentyDays'].data.push({
        x: date,
        y: y * 100,
      })

    }
    ,
    getAvgValuesByDay(date, serieIndex, dataIndex, desti) {
      let serieTmp
      if (this.data[desti] == null) {
        serieTmp = this.groupedAllDatas
      } else {
        serieTmp = this.data[desti]
      }
      switch (serieIndex) {
        case 2:
          return {
            day: serieTmp.day.data[dataIndex] != null ? this.roundPercent(serieTmp.day.data[dataIndex].y) : null,
            week: serieTmp.week.data[dataIndex] != null ? this.roundPercent(serieTmp.week.data[dataIndex].y) : null,
            twenty: serieTmp.twentyDays.data[dataIndex] != null ? this.roundPercent(serieTmp.twentyDays.data[dataIndex].y) : null,
          }

        case 1:
          return {
            day: serieTmp.day.data[dataIndex] != null ? this.roundPercent(serieTmp.day.data[dataIndex].y) : null,
            week: serieTmp.week.data[dataIndex] != null ? this.roundPercent(serieTmp.week.data[dataIndex].y) : null,
            twenty: serieTmp.twentyDays.data[dataIndex] != null ? this.roundPercent(serieTmp.twentyDays.data[dataIndex].y) : null,
          }
        case 0:
          return {
            day: serieTmp.day.data[dataIndex] != null ? this.roundPercent(serieTmp.day.data[dataIndex].y) : null,
            week: serieTmp.week.data[dataIndex] != null ? this.roundPercent(serieTmp.week.data[dataIndex].y) : null,
            twenty: serieTmp.twentyDays.data[dataIndex] != null ? this.roundPercent(serieTmp.twentyDays.data[dataIndex].y) : null,
          }
        default:
          return {
            day: 0,
            week: 0,
            twenty: 0,
          }
      }
    }
    ,
    roundPercent(val) {
      return Math.round(val * 100) / 100
    },
  }
}
</script>