<template>
  <v-card :id="`hotel-card-${hotelprice.hotel.id}`" width="100%">
    <v-card-title class="headline">
      <div>
        {{hotelprice.hotel.name}}
        <sup>{{hotelprice.hotel.rating.label}}</sup>

        <div class="caption grey--text">
          <span
            class="font-weight-medium body-2 font-weight-medium"
          >{{hotelprice.hotel.destination.name}}</span>
          <span
            class="ml-2"
            v-for="(t,idx) in hotelprice.hotel.tags"
            :key="idx+'-'+hotelprice.hotel.id + t"
          >{{t}}</span>
          <span
            class="ml-2"
            v-for="(s,idx) in hotelprice.hotel.services"
            :key="idx+'-'+hotelprice.hotel.id + s"
          >{{s}}</span>

          <product-details
            :title="hotelprice.hotel.name"
            :services-items="hotelprice.hotel.services"
            :productId="hotelprice.hotel.id"
            :siteId="ctx.env.siteId"
            product-type="hotel"
          ></product-details>
        </div>
      </div>
    </v-card-title>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="items"
        :disable-pagination="true"
        hide-default-footer
        class="pricegrid elevation-1"
        item-key="roomName"
      >
        <template #header="{headers}">
          <tr>
            <th
              v-for="header in headers"
              :key="header.text"
              class="[header.sortable, header.align, header.width]"
            >
              {{header.text}}
              <mealplan-details
                :site-id="ctx.env.siteId"
                :meals="hotelprice.hotel.meals"
                :meal-plan-type-id="header.mealPlanTypeId"
              />
            </th>
          </tr>
        </template>
        <template #item="{item}">
          <tr @click="item.expanded = !item.expanded">
            <td class="pt-2">
              {{ item.roomName }}
              <div>
                <span
                  class="list-horizontal pa-0 grey--text"
                  v-for="(r,rIndex) in item.rooms"
                  :key="rIndex"
                >
                  <span class="mr-2 pt-3" v-if="r.surface">{{r.surface}}m2</span>
                  <span class="mr-2 pt-3" v-if="r.view">Vue {{r.view}}</span>
                </span>

                <product-details
                  v-if="item.rooms && item.rooms.length > 0 && item.rooms[0].services"
                  :title="item.roomName"
                  :services-items="item.rooms[0].services"
                  :productId="item.rooms[0].roomId"
                  :siteId="ctx.env.siteId"
                  product-type="room"
                ></product-details>
                <!-- <smart-dialog :title="props.item.roomName">

									<v-icon small slot="activator">mdi-information-outline</v-icon>

									<v-layout row wrap v-for="r in props.item.rooms" class="pa-2">
										<v-flex xs4 v-for="a in r.arguments">{{a}}</v-flex>
										<v-flex xs4 v-for="s in r.services">{{s.name}}</v-flex>
									</v-layout>

                </smart-dialog>-->
              </div>
            </td>
            <td
              class="text-center"
              v-for="(meal,idx) in allMeals"
              :key="idx+'-'+item.roomName+'-'+meal.mealPlanTypeId"
            >
              <hotel-price-cell
                :hotelprice="hotelprice"
                :proposition="findProp(item.propositions, meal)"
                @select="select"
              ></hotel-price-cell>
            </td>
          </tr>
        </template>
      </v-data-table>
      <v-dialog v-model="dialog" width="800">
        <hotel-price-details
          :bookingId="bookingId"
          :bookingPrice="bookingPrice"
          :hotelprice="hotelprice"
          :ctx="ctxForHotel"
          :proposition="proposition"
          @close="dialog = false"
        ></hotel-price-details>
      </v-dialog>
    </v-card-text>
  </v-card>
</template>
<script>
import _ from "lodash";
import HotelPriceCell from "@/components/commons/HotelPriceCell";
import HotelPriceDetails from "@/components/commons/HotelPriceDetails";
import ProductDetails from "./ProductDetails";
import MealplanDetails from "./MealplanDetails";

export default {
  name: "HotelPrice",
  components: {
    MealplanDetails,
    ProductDetails,
    HotelPriceCell,
    HotelPriceDetails,
  },
  props: {
    bookingId: { required: true },
    bookingPrice: { required: true },
    hotelprice: { required: true },
    ctx: { required: true },
  },
  data: () => ({
    items: [],
    dialog: false,
    allMeals: [
      { name: "Hébergement seul", mealPlanTypeId: 4 },
      { name: "Petit-déjeuner", mealPlanTypeId: 3 },
      { name: "Demi-Pension", mealPlanTypeId: 1 },
      { name: "Pension complète", mealPlanTypeId: 2 },
      { name: "All inclusive", mealPlanTypeId: 5 },
    ],
    headers: [
      {
        text: "",
        sortable: false,
        align: "left",
        mealDesc: { shortdescription: null },
      },
    ],
    proposition: null,
  }),
  created() {
    this.init();
    this.allMeals.map((meal) => {
      this.headers.push({
        text: meal.name,
        mealPlanTypeId: meal.mealPlanTypeId,
        sortable: false,
        align: "center",
        width: 150,
        descMenu: false,
        mealDesc: { shortdescription: null },
      });
    });
  },
  watch: {
    hotelprice(newVal) {
      this.init();
    },
  },
  computed: {
    ctxForHotel() {
      return Object.assign({}, this.ctx, {
        hotelId: this.hotelprice.hotel.id,
      });
    },
  },
  methods: {
    init() {
      this.items = [];

      //this.items = this.hotelprice.hotel.propositions

      this.hotelprice.hotel.propositions.map((p) => {
        const roomName = p.rooms.map((r) => r.name).join(", ");
        const rooms = _.intersectionBy(
          this.hotelprice.hotel.rooms,
          p.rooms,
          "roomId"
        );

        var index = _.findIndex(this.items, (i) => {
          return i.roomName === roomName;
        });
        if (index === -1) {
          this.items.push({
            roomName: roomName,
            rooms: rooms,
            descDialog: false,
            propositions: [p],
          });
        } else {
          this.items[index].propositions.push(p);
        }
      });
    },
    findProp(propositions, meal) {
      return propositions.find((p) => {
        return (
          _.findIndex(p.meals, (m) => {
            return m.mealPlanTypeId === meal.mealPlanTypeId;
          }) !== -1
        );
      });
    },
    mealShortName(meal) {
      switch (meal.mealPlanTypeId) {
        case 1:
          return "Demi p.";
        case 2:
          return "Pension c.";
        case 3:
          return "Petit dej.";
        case 4:
          return "Heb. seul";
        case 5:
          return "All inc.";
      }
    },
    select(proposition) {
      this.proposition = proposition;
      this.dialog = true;
    },
  },
};
</script>
<style scoped>
.list-horizontal li {
  display: inline-block;
}

.list-horizontal li:before {
  content: "\00a0\2022\00a0\00a0";
  color: #999;
  color: rgba(0, 0, 0, 0.5);
  font-size: 11px;
}

.list-horizontal li:first-child:before {
  content: "";
}

.v-menu__content {
  left: 25% !important;
  right: 25% !important;
}
</style>

