<template>
  <layout :drawer="false">
    <template slot="nav-tools">
      <smart-btn @click.native="newSupplier" class="mr-1">
        <v-icon>mdi-folder-plus</v-icon>
        <span class="pl-1">Nouveau fournisseur</span>
      </smart-btn>
    </template>

    <template slot="content">
      <template v-if="listing.suppliers.length === 0">
        <v-container fluid class="pa-3">
          <v-row class="fill-height">
            <v-col cols="12">
              <v-skeleton-loader
                class
                height="100%"
                type="list-item-avatar-two-line, list-item-three-line@7"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
        </v-container>
      </template>
      <template v-else>
        <v-container>
          <v-card>
            <v-card-title>
              <h3>Fournisseurs comptabilité</h3>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="listing.search"
                class="mb-2"
                append-icon="search"
                label="Recherche (Nom - Compte)"
                hide-details
              ></v-text-field>
            </v-card-title>
            <v-data-table
              :loading="listing.loading"
              :headers="listing.headers"
              :items="listing.suppliers"
              :footer-props="{'items-per-page-options':listing.rowsPerPageItems, 'items-per-page-text':'Lignes par page'}"
              :search="listing.search"
              item-key="name"
              class="elevation-1 tbl"
            >
              <template #item.active="{value}">
                <v-icon>{{ value ? 'mdi-check-circle-outline' : 'mdi-close-circle-outline'}}</v-icon>
              </template>
              <template #item.edit="{item}">
                <v-btn icon small @click="edit(item)" class="mr-2">
                  <v-icon small>edit</v-icon>
                </v-btn>
                <v-btn icon small @click="remove(item)" :disabled="isEvaoBilleterie(item.id)">
                  <v-icon small>delete</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-card>
        </v-container>
      </template>

      <smart-dialog title="Modifier fournisseur" v-model="edition.dialog" :width="600">
        <v-container v-if="edition.supplier">
          <v-form v-on:submit.prevent="saveSupplier" ref="form" v-model="edition.valid">
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="edition.supplier.name"
                  label="Nom"
                  :disabled="isEvaoBilleterie(edition.supplier.id)"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="edition.supplier.accountingNumber"
                  label="Compte"
                  type="number"
                  :counter="12"
                  :rules="rules.number()"
                />
              </v-col>
              <v-col cols="12">
                <v-checkbox
                  v-model="edition.supplier.active"
                  label="Actif"
                  :disabled="isEvaoBilleterie(edition.supplier.id)"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                Conditions de réglement
                <v-btn icon small @click="addCondition()">
                  <v-icon small>mdi-plus</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row
              v-for="(condition,conditionIndex) in edition.supplier.paymentConditions"
              :key="conditionIndex"
            >
              <v-col>
                <v-text-field
                  v-model="condition.amount"
                  reverse
                  prefix="%"
                  label="Montant"
                  placeholder=" "
                />
              </v-col>
              <v-col>
                <v-select
                  v-model="condition.paymentConditionType"
                  :items="paymentConditionTypes"
                  label="Type de condition"
                />
              </v-col>
              <v-col>
                <v-text-field v-model="condition.day" label="Jour" placeholder=" " />
              </v-col>
              <v-col>
                <v-btn icon small class="mt-3" @click="deleteCondition(conditionIndex)">
                  <v-icon small>mdi-delete</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-container>

        <template slot="actions">
          <smart-btn primary @click.native="saveSupplier">Enregistrer</smart-btn>
        </template>
      </smart-dialog>
    </template>
  </layout>
</template>

<script>
import Layout from "@/views/Layout";
import { get, post } from "@/utils/api";
import FormMixin from "@/components/mixins/FormMixin";
import { EventBus } from "@/components/commons/event-bus";

export default {
  name: "SupplierAccountingEdit",
  components: {
    Layout,
  },
  mixins: [FormMixin],
  data() {
    return {
      listing: {
        search: "",
        rowsPerPageItems: [50, 100, { text: "Tous", value: -1 }],
        headers: [
          { text: "ID", value: "id", align: "left" },
          { text: "NOM", value: "name", align: "left" },
          { text: "COMPTE", value: "lang", align: "left" },
          { text: "ACTIF", value: "active", align: "left" },
          { text: "EDIT", value: "edit", align: "left" },
        ],

        suppliers: [],
      },
      edition: {
        supplier: null,
        dialog: false,
        valid: true,
      },
      paymentConditionTypes: [],
    };
  },
  created() {
    this.init();
  },
  watch: {},
  computed: {},
  methods: {
    isEvaoBilleterie(id) {
      return [307, 552, 551].indexOf(id) != -1;
    },
    init() {
      const url = "/api/supplier/get/foraccounting";

      get(url).json((r) => {
        this.listing.suppliers = r;
      });

      get("/api/supplier/condition-types").json(
        (data) =>
          (this.paymentConditionTypes = data.map((d) => {
            return {
              value: d,
              text: this.$t("supplierPaymentConditionTypes." + d),
            };
          }))
      );
    },
    remove(supplier) {
      this.$root
        .$confirm(
          this.$t("confirm"),
          "Attention, la suppression est définitive ?",
          { width: 350 }
        )
        .then((confirm) => {
          if (!confirm) {
            return Promise.resolve();
          } else {
            return get(`/api/supplier/accounting/delete/${supplier.id}`)
              .badRequest((err) => {
                EventBus.$emit("toaster-msg", err.toString());
              })
              .res(() => {
                this.init();
                EventBus.$emit("toaster-msg", "Suppression enregistrée");
              });
          }
        });
    },
    edit(supplier) {
      this.edition.supplier = { ...supplier };
      this.edition.dialog = true;
    },
    newSupplier() {
      this.edition.supplier = {
        id: 0,
        active: false,
        name: "",
        accountingNumber: "",
        lang: "",
        paymentConditions: [],
      };
      this.edition.dialog = true;
    },
    saveSupplier() {
      if (this.edition.valid && this.$refs.form.validate()) {
        const url = "/api/supplier/accounting/save";
        post(url, this.edition.supplier).res(() => {
          this.init();
          this.edition.dialog = false;
          EventBus.$emit("toaster-msg", "Fournisseur enregistré");
        });
      }
    },
    addCondition() {
      var conditions = this.edition.supplier.paymentConditions || [];
      conditions.push({
        id: 0,
        supplierId: this.edition.supplier.id,
        amount: 100,
        paymentConditionType: "prestation_start",
        day: 0,
      });

      this.edition.supplier = {
        ...this.edition.supplier,
        ...{ paymentConditions: conditions },
      };
    },
    deleteCondition(index) {
      this.edition.supplier.paymentConditions.splice(index, 1);
    },
  },
};
</script>

<style scoped>
</style>
