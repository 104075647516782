<template>
  <layout :drawerShow="_isDesktop()" :drawerWidth="350">
    <template slot="nav">
      <v-tabs>
        <v-tab :to="{ name: 'BookingList' }">Dossiers</v-tab>
      </v-tabs>
    </template>
    <template slot="nav-tools">
      <booking-add>
        <smart-btn
          slot="activator"
          slot-scope="{ toggle }"
          @click.native="toggle"
        >
          <v-icon>mdi-folder-plus</v-icon>
          <span class="pl-1 d-none d-sm-flex">Nouveau dossier</span>
        </smart-btn>
      </booking-add>
    </template>
    <template slot="drawer">
      <v-form ref="searchform" v-if="searchform" @submit.prevent="loadData">
        <v-row no-gutters class="pt-2 pr-1">
          <v-col cols="12" class="pr-1">
            <smart-multi-select
              v-model="searchform.selectedVirtualSites"
              label="Sites"
              :items="virtualSites"
              :all-is-same-as-empty="true"
              :default-select-all="true"
            ></smart-multi-select>
          </v-col>

          <v-col cols="6" class="pr-1 mt-2">
            <smart-select
              v-model="searchform.selectedChains"
              item-text="name"
              item-value="id"
              label="Chaines"
              :default-select-all="true"
              :all-is-same-as-empty="true"
              :loading="loadingChains"
              :items="chains"
              :chip-style="{ width: '120px' }"
            ></smart-select>
          </v-col>

          <v-col cols="6" class="pr-1 mt-2">
            <smart-select
              v-model="searchform.selectedHotels"
              item-text="name"
              item-value="id"
              label="Hotels"
              :default-select-all="true"
              :all-is-same-as-empty="true"
              :loading="loadingHotels"
              :items="hotels"
              :chip-style="{ width: '120px' }"
            ></smart-select>
          </v-col>

          <v-col cols="6" class="pr-1 mt-2">
            <smart-select
              v-model="searchform.selectedNetworks"
              item-text="name"
              item-value="id"
              label="Réseaux"
              :default-select-all="true"
              :all-is-same-as-empty="true"
              :items="networks"
              :chip-style="{ width: '120px' }"
            ></smart-select>
          </v-col>

          <v-col cols="6" class="pr-1 mt-2">
            <v-autocomplete
              dense
              label="Agence"
              v-model="searchform.selectedAgencies"
              item-text="name"
              item-value="id"
              :items="agencies"
              :search-input.sync="agencyName"
              hide-details
              hide-no-data
              multiple
            ></v-autocomplete>
          </v-col>

          <v-col cols="12" class>
            <v-subheader light class="pa-0 mb-0 mx-0 form-subheader"
              >Client / Passager</v-subheader
            >
            <v-divider></v-divider>
          </v-col>
          <v-col cols="12 mb-2">
            <v-text-field
              clearable
              v-model="searchform.client"
              label="Nom, Email"
              placeholder=" "
              hide-details
            >
            </v-text-field>
          </v-col>

          <v-col cols="12" class="mt-2">
            <v-subheader light class="pa-0 mb-0 mx-0 form-subheader"
              >Dates de réservation</v-subheader
            >
            <v-divider></v-divider>
          </v-col>
          <v-col class="pr-1" cols="6">
            <smart-picker
              v-model="searchform.bookingDateStart"
              :stopDate="searchform.bookingDateStop"
              :inputOpts="{
                label: $t('begin'),
                clearable: true,
                placeholder: ' ',
              }"
            ></smart-picker>
          </v-col>
          <v-col cols="6" class="pr-1">
            <smart-picker
              v-model="searchform.bookingDateStop"
              :startDate="searchform.bookingDateStart"
              :inputOpts="{
                label: $t('end'),
                clearable: true,
                placeholder: ' ',
              }"
            ></smart-picker>
          </v-col>

          <v-col cols="12">
            <v-subheader light class="pa-0 mt-5 mb-0 mx-0 form-subheader"
              >Dates de voyage</v-subheader
            >
            <v-divider></v-divider>
          </v-col>
          <v-col class="pr-1" cols="6">
            <smart-picker
              v-model="searchform.tripDateStart"
              :stopDate="searchform.tripDateStop"
              :inputOpts="{
                label: $t('begin'),
                clearable: true,
                placeholder: ' ',
              }"
            ></smart-picker>
          </v-col>
          <v-col cols="6" class="pr-1">
            <smart-picker
              v-model="searchform.tripDateStop"
              :startDate="searchform.tripDateStart"
              :inputOpts="{
                label: $t('end'),
                clearable: true,
                placeholder: ' ',
              }"
            ></smart-picker>
          </v-col>

          <v-col cols="6">
            <v-subheader light class="pa-0 mt-5 mb-0 mx-0 form-subheader"
              >Statut Dossier</v-subheader
            >
            <v-divider></v-divider>
          </v-col>
          <v-col cols="6">
            <v-subheader light class="pa-0 mt-5 mb-0 mx-0 form-subheader"
              >Statut Fournisseur</v-subheader
            >
            <v-divider></v-divider>
          </v-col>
          <v-col cols="6">
            <v-checkbox
              v-for="(bs, index) in bookingStatus"
              :key="'bs-' + index"
              v-model="searchform.bookingStatus"
              :label="bs.label"
              :value="bs.value"
              :class="'pt-1 ' + (index == 0 ? 'mt-2' : 'mt-0')"
              height="1"
            >
            </v-checkbox>
          </v-col>
          <v-col cols="6">
            <v-checkbox
              v-for="(s, index) in supplierStatus"
              :key="'s-' + index"
              v-model="searchform.supplierStatus"
              :label="s.label"
              :value="s.value"
              :class="'pt-1 ' + (index == 0 ? 'mt-2' : 'mt-0')"
              height="1"
            >
            </v-checkbox>
          </v-col>

          <v-col cols="12">
            <v-subheader light class="pa-0 mt-2 mb-0 mx-0 form-subheader"
              >Etiquettes</v-subheader
            >
            <v-divider></v-divider>
          </v-col>
          <v-col cols="12">
            <smart-multi-select
              v-model="searchform.tags"
              :items="tags"
              :all-is-same-as-empty="false"
              :default-select-all="false"
              item-value="id"
              item-text="name"
            ></smart-multi-select>
          </v-col>

          <v-col cols="12">
            <v-subheader light class="pa-0 mt-2 mx-0 form-subheader"
              >Paiement</v-subheader
            >
            <v-divider></v-divider>
          </v-col>
          <v-col cols="12" class="mt-1 mb-4">
            <v-select
              v-model="searchform.paymentStatus"
              chips
              small-chips
              dense
              :items="paymentStatus"
              class="mt-0 pt-0"
              hide-details
              item-value="value"
              item-text="label"
            ></v-select>
          </v-col>
        </v-row>

        <smart-btn type="submit" :loading="loading" block primary
          >Rechercher</smart-btn
        >
        <smart-btn
          block
          tertiary
          @click.native="
            clearSearchform();
            loadData();
          "
          >Réinitialiser la recherche</smart-btn
        >
      </v-form>
    </template>
    <template slot="content">
      <v-container fluid>
        <v-row justify="space-between">
          <v-col cols="12" class="mb-3">
            <v-card class="pa-0 mt-5 blue-grey lighten-1">
              <v-row justify="space-between">
                <v-col cols="12" md="3" class="pa-2 ma-0">
                  <widget-booking-size :bus="widgetBus"></widget-booking-size>
                </v-col>
                <v-col cols="12" md="3" class="pa-2 ma-0">
                  <widget-booking-passenger
                    :bus="widgetBus"
                  ></widget-booking-passenger>
                </v-col>
                <v-col cols="12" md="3" class="pa-2 ma-0">
                  <widget-booking-ca :bus="widgetBus"></widget-booking-ca>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col cols="12" class="mb-3">
            <widget-booking-resume :bus="widgetBus"></widget-booking-resume>
          </v-col>
        </v-row>

        <bookings-list-table
          @loading="setLoading"
          ref="bookingListTable"
        ></bookings-list-table>
      </v-container>
    </template>
  </layout>
</template>

<script>
import Layout from "@/views/Layout";
import { get } from "@/utils/api";
import WidgetBookingSize from "@/components/commons/widgets/WidgetBookingSize";
import WidgetBookingPassenger from "@/components/commons/widgets/WidgetBookingPassenger";
import WidgetBookingCa from "@/components/commons/widgets/WidgetBookingCa";
import WidgetBookingResume from "@/components/commons/widgets/WidgetBookingResume";
import Vue from "vue";
import Availability from "@/components/commons/Availability";
import BookingsListTable from "../../components/commons/BookingsListTable";
import SmartSelect from "../../components/commons/SmartSelect";
import BookingAdd from "../../components/booking/BookingAdd";
import SmartMultiSelect from "../../components/commons/SmartMultiSelect";

export default {
  components: {
    SmartMultiSelect,
    SmartSelect,
    BookingAdd,
    BookingsListTable,
    Availability,
    WidgetBookingResume,
    WidgetBookingCa,
    WidgetBookingSize,
    WidgetBookingPassenger,
    Layout,
  },
  mixins: [],
  data() {
    return {
      dialog: false,
      //searchform
      defaultSearchform: {
        selectedSites: [],
        selectedVirtualSites: [],
        selectedNetworks: [],
        selectedAgencies: [],
        selectedChains: [],
        selectedHotels: [],
        selectedDestinations: [],
        client: null,
        ref: null,
        bookingDateStart: this._nowFormatted("dd/MM/yyyy"),
        bookingDateStop: this._nowFormatted("dd/MM/yyyy"),
        tripDateStart: null,
        tripDateStop: null,
        bookingStatus: ["booked"],
        supplierStatus: [],
        paymentStatus: null,
        tags: [],
      },
      searchform: null,

      //
      loadingAgencies: false,
      loadingChains: false,
      loadingHotels: false,
      searchAgencyInput: "",
      //
      valid: false,
      sites: [],
      virtualSites: [],
      chains: [],
      hotels: [],
      networks: [],
      agencies: [],
      agencyName: "",
      tags: [],

      bookingStatus: [
        { value: "quotation", label: "Devis" },
        { value: "booked", label: "Réservation" },
        { value: "cancelled", label: "Annulé" },
      ],
      supplierStatus: [
        { value: "ondemand", label: "En attente" },
        { value: "confirmed", label: "Confirmé" },
        { value: "refused", label: "Refusé" },
      ],
      paymentStatus: [
        { value: null, label: "Tous" },
        { value: false, label: "Non soldés" },
        { value: true, label: "Soldés" },
      ],
      loading: false,

      //------
      widgetBus: new Vue(),
    };
  },
  created() {
    get("/api/search/priceclasstypes").json((priceclass) => {
      this.priceclass = priceclass;
    });

    const loadChains = get("/api/search/chains").json((chains) => {
      this.chains = chains;
    });

    const loadHotels = get("/api/search/hotels").json((hotels) => {
      this.hotels = hotels;
    });

    const loadVirtualSites = get("/api/search/virtual-sites").json((sites) => {
      this.virtualSites = sites;
      this.defaultSearchform.selectedVirtualSites = this.virtualSites.map(
        (s) => s.id
      );
    });
    const loadNetworks = get("/api/search/networks").json((networks) => {
      this.networks = networks;
    });

    const loadTags = get("/api/search/tags").json((tags) => {
      this.tags = [...tags];
    });

    Promise.all([
      loadVirtualSites,
      loadNetworks,
      loadChains,
      loadHotels,
      loadTags,
    ]).then(() => {
      this.initSearchform();
      this.loadData();
    });
  },

  mounted() {},
  computed: {},
  watch: {
    agencyName(newVal) {
      newVal &&
        get("/api/agency/search?search=" + newVal).json((agencies) => {
          this.agencies = agencies.map((agt) => {
            return {
              id: agt.id,
              name: `${agt.name} ${agt.address.city} ${agt.address.postalcode}`,
            };
          });
        });
    },
  },
  methods: {
    loadData() {
      if (!this.loading) {
        //set qs
        window.history.pushState(
          "",
          "",
          "?search=" + JSON.stringify(this.searchform)
        );

        this.getWidgets(this.searchform);
        this.getBookings(this.searchform);
      }
    },
    getWidgets(form) {
      this.widgetBus.$emit("search", form);
    },
    getBookings(form) {
      this.$refs.bookingListTable.load("/api/widget/bookings", form);
    },
    initSearchform() {
      const qs = new URLSearchParams(window.location.search);
      const search = (() => {
        try {
          return JSON.parse(qs.get("search"));
        } catch (e) {
          return null;
        }
      })();

      if (search) {
        this.searchform = search;
      } else this.clearSearchform();
    },
    clearSearchform() {
      this.searchform = { ...this.defaultSearchform };
      // /!\ black magic /!\ do not remove /!\
      this.$nextTick(() => {
        this.searchform.tripDateStart = null;
        this.searchform.tripDateStop = null;
      });
    },
    setLoading(loading) {
      this.loading = loading;
    },
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.v-subheader {
  width: 100%;
  height: 20px;
  color: #616161;
}

td:hover {
  cursor: pointer;
}
</style>
