<template>
  <layout :drawerShow="false" :drawerWidth="350">
    <template slot="nav">
      <v-tabs>
        <v-tab :to="{ name: 'AdminUsers' }">Utilisateurs</v-tab>
      </v-tabs>
    </template>
    <!--<template slot="nav-tools"></template>
    <template slot="drawer"></template>-->
    <template slot="content">
      <v-container fluid>
        <v-card class="mt-1" style="max-width:1024px;margin:auto">
          <v-row >
            <v-col cols="3" class="left-col overflow-auto">
              <v-treeview
                ref="treeview"
                shaped
                hoverable
                activatable
                open-on-click
                transition
                overflow-x-auto
                open-all
                :items="items"
                :active.sync="active"
                :open.sync="open"
                :load-children="loadUsers"
              >
                <template v-slot:prepend="{ item }">
                  <v-icon
                    v-if="!item.children"
                  >{{ item.active && !item.isOnPlaceAgentId ? 'mdi-account' : !item.active ? 'mdi-account-off' : 'mdi-account-arrow-right-outline' }}</v-icon>
                </template>
                <template v-slot:label="{ item }">{{ item.name }}</template>
              </v-treeview>
            </v-col>

            <v-divider vertical></v-divider>

            <v-col class="d-flex text-center">
              <v-scroll-y-transition mode="out-in">
                <div
                  v-if="!selected"
                  class="text-h6 grey--text text--lighten-1 font-weight-light"
                  style="align-self: center;"
                >Selectionner un utilisateur</div>
                <v-card v-else :key="selected.id" class="pt-6 mx-auto" flat max-width="600">
                  <v-card-text>
                    <h3 class="text-h5 mb-2">{{ selected.firstname }} {{ selected.lastname }}</h3>
                    <div class="blue--text mb-2">{{ selected.email }}</div>
                    <span
                      class="subheading font-weight-bold"
                      :class="'selected.active' ? 'blue--text' : 'orange--text'"
                    >{{ selected.active ? 'Actif' : 'Innactif' }}</span>
                    <span
                      v-if="selected.isOnPlaceAgentId"
                      class="subheading font-weight-bold ml-1"
                      :class="'selected.active' ? 'blue--text' : 'orange--text'"
                    >- Agent sur place</span>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-row class="text-left" tag="v-card-text">
                    <v-col class="text-right mr-4 mt-1" tag="strong" cols="5">Roles:</v-col>
                    <v-col cols="5">
                      <v-chip
                        color="green"
                        outlined
                        pill
                        v-for="(role, index) in selected.roles"
                        :key="index"
                      >{{ role.name }}</v-chip>
                    </v-col>
                    <v-col class="text-right mr-4 mt-1" tag="strong" cols="5">Groupes:</v-col>
                    <v-col cols="5">
                      <v-chip
                        class="ml-1"
                        color="blue"
                        pill
                        outlined
                        v-for="(group, index) in selected.groups"
                        :key="index"
                      >{{ group.name }}</v-chip>
                    </v-col>
                    <v-col class="text-right mr-4 mt-1" tag="strong" cols="5">Sites</v-col>
                    <v-col cols="5">
                      <site-label
                        class="d-inline mx-1"
                        v-for="(site, index) in selected.sites"
                        :key="index"
                        :sitename="site"
                      ></site-label>
                    </v-col>
                  </v-row>
                  <v-divider></v-divider>

                  <v-row class="text-left" tag="v-card-text">
                    <v-col class="text-right mr-4 mt-2" tag="strong" cols="5">Absent:</v-col>
                    <v-col>
                      <v-checkbox
                        dense
                        class="ma-0 pa-0"
                        v-model="selected.vacant"
                        @change="vacant(selected)"
                        label="L'utilisateur ne se verra plus attribuer de dossier durant son absence"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                </v-card>
              </v-scroll-y-transition>
            </v-col>
          </v-row>
        </v-card>
      </v-container>
    </template>
  </layout>
</template>
<script>
import { EventBus } from "@/components/commons/event-bus";
import Layout from "@/views/Layout";
import { get, post } from "@/utils/api";
import SiteLabel from "@/components/commons/SiteLabel";

export default {
  components: {
    Layout, SiteLabel
  },
  data: () => ({
    active: [],
    open: [],
    users: [],
  }),

  computed: {
    items() {
      return [
        {
          id: "1",
          name: 'Utilisateurs ',
          children: this.users,
        },
      ]
    },
    selected() {
      if (!this.active.length) return undefined

      const id = this.active[0]
      return this.users.find(user => user.id === id)
    },
  },

  watch: {

  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
    },
    async loadUsers() {
      const url = "/api/search/users";
      get(url)
        .json((data) => {
          this.users = [...data]
        })
        .then(() => {
          this.open = [this.items[0].id]
        });
    },
    vacant(user) {
      const url = `/api/user/${user.id}/update`;
      post(url, user)
        .res((data) => {
          EventBus.$emit("toaster-msg", "Utilisateur mis à jour");
        });
    }
  }
}
</script>
<style scoped>
.left-col {
  height: 800px;
}
</style>