<template>
  <smart-dialog
    v-model="dialog"
    eager
    :title="title"
    :width="prestasWithFees ? 900 : 300"
    @close="cancel"
  >
    <v-card-text v-if="!!message">{{message}}</v-card-text>
    <v-card-text v-if="prestasWithFees">
      <v-row class="mb-4 align-end" v-for="(presta, index) in prestasWithFees" :key="index">
        <v-col cols="5" class="align-content-center">
          <span class="body-2 font-weight-medium">
            {{presta.name}}
            <br />
          </span>
          <span class="body-2 font-weight-medium">{{$t('from')}}</span>
          {{presta.start}}
          <span class="body-2 font-weight-medium">{{$t('to')}}</span>
          {{presta.stop}}
        </v-col>
        <v-col cols="3" class="px-1">
          <v-text-field
            v-if="presta.price > 0"
            append-icon="euro_symbol"
            v-model="presta.purchase"
            :label="'Prix d\'achat (' + presta.purchase + ')'"
            hide-details
          ></v-text-field>
          <v-text-field
            v-else
            append-icon="euro_symbol"
            v-model="noCancelFee"
            :label="'Prix d\'achat (' + presta.purchase + ')'"
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="3" class="px-1">
          <v-text-field
            append-icon="euro_symbol"
            v-model="presta.price"
            label="Frais d'annulation"
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3" class="px-1"></v-col>
        <v-col cols="3" offset="5" class="px-1">
          <v-text-field
            append-icon="euro_symbol"
            v-model="total"
            label="Total Frais d'annulation"
            disabled
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="actions-btn">
      <v-spacer></v-spacer>
      <smart-btn primary @click.native="agree">{{options.ok}}</smart-btn>
    </v-card-actions>
  </smart-dialog>
</template>

<script>
import DialogMixin from "@/components/mixins/DialogMixin";
import _ from "lodash";

export default {
  data() {
    return {
      prestasWithFees: null,
      totalpurchase: 0,
      total: 0,
      noCancelFee: 0,
    };
  },
  mixins: [DialogMixin],
  watch: {
    prestasWithFees: {
      deep: true,
      handler(newVal) {
        if (newVal == null) return 0;
        else {
          const price = newVal
            .map(({ name, price, purchase, start, stop }) => {
              return price;
            })
            .map((p) => parseFloat(p) || 0);
          const purchase = newVal
            .map(({ name, price, purchase, start, stop }) => {
              return purchase;
            })
            .map((p) => parseFloat(p) || 0);

          this.total = _.sum(price);
          this.totalpurchase = _.sum(purchase);
        }
      },
    },
  },
  methods: {
    open(title, message, options, prestasWithFees) {
      this._open(title, message, options);

      this.prestasWithFees = prestasWithFees;

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },

    agree() {
      if (this.resolve) {
        this.prestasWithFees.map((presta) => {
          if (presta.price == 0) {
            presta.purchase = 0;
          }
        });
        this.resolve([true, this.prestasWithFees]);
        this.dialog = false;
      }
    },
    cancel() {
      if (this.resolve) {
        this.resolve([false, null]);
        this.dialog = false;
      }
    },
  },
};
</script>
