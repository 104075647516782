var render = function render(){var _vm=this,_c=_vm._self._c;return _c('layout',{attrs:{"drawer":false,"navFixed":""}},[_c('template',{slot:"nav"},[_c('v-btn',{attrs:{"color":"primary","elevation":"2","text":""},on:{"click":_vm.mergeAgents}},[_vm._v("Fusionner les agents")])],1),_c('template',{slot:"drawer"},[_vm._v("rien pour l'instant")]),_c('template',{slot:"content"},[_c('v-container',{staticClass:"relative mt-16",attrs:{"fluid":""}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.listing.headers,"items":_vm.listing.items,"item-key":"id","sort-by":"login","group-by":"login","show-group-by":"","options":_vm.listing.pagination,"footer-props":{
                    'items-per-page-options': _vm.listing.rowsPerPageItems,
                    'items-per-page-text': 'Lignes par page',
                },"hide-default-footer":"","show-select":"","calculate-widths":true},on:{"update:options":function($event){return _vm.$set(_vm.listing, "pagination", $event)}},scopedSlots:_vm._u([{key:"item.id",fn:function({item}){return [_c('div',{staticClass:"d-flex align-center justify-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"mb-0 caption",staticStyle:{"width":"50px"}},[_vm._v(_vm._s(item.id))])])]}},{key:"item.lastname",fn:function({item}){return [_c('div',{staticClass:"d-flex align-center justify-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"mb-0",staticStyle:{"width":"100px"}},[_vm._v(_vm._s(item.lastname))])])]}},{key:"item.firstname",fn:function({item}){return [_c('div',{staticClass:"d-flex align-center justify-center",staticStyle:{"height":"100%"}},[_c('p',{staticClass:"mb-0",staticStyle:{"width":"100px"}},[_vm._v(_vm._s(item.firstname))])])]}},{key:"item.creationDate",fn:function({ value }){return _vm._l((_vm._formatDate(new Date(value), _vm.datePatternConfig.fr.localDateWithTime).split(' ')),function(item,i){return _c('p',{key:i,staticClass:"mb-0",class:i == 1 ? 'caption grey--text text--darken-1' : ''},[_vm._v(_vm._s(item))])})}},{key:"item.active",fn:function({ value }){return [_c('v-chip',{staticClass:"mx-1 my-0",style:({
                            border:
                                'solid 1px ' + value ? 'green' : 'red' + '!important',
                        }),attrs:{"color":value ? 'green lighten-5' : 'red lighten-5',"small":"","label":""}},[_vm._v(_vm._s(value ? "Actif" : "Inactif"))])]}},{key:"item.agency",fn:function({ item }){return [_c('div',{staticClass:"d-flex flex-column"},[_c('p',{staticClass:"mb-0 font-weight-medium"},[_vm._v(_vm._s(item.agencyName))]),_c('p',{staticClass:"mb-0 caption grey--text text--darken-1"},[_vm._v(_vm._s(item.agencyAddress))])])]}},{key:"item.other",fn:function({ item }){return [_c('v-btn',{attrs:{"text":"","x-small":"","to":{ name: 'CrmAgencyDetails', params: { id: item.agencyId } },"target":"_blank"}},[_vm._v("Voir l'agence")]),_c('v-btn',{attrs:{"text":"","x-small":""},on:{"click":function($event){return _vm.editAgent(item)}}},[_vm._v("Editer l'agent")])]}}]),model:{value:(_vm.selectedAgents),callback:function ($$v) {_vm.selectedAgents=$$v},expression:"selectedAgents"}})],1),_c('smart-dialog',{attrs:{"title":"Édition Agent","fullscreen":_vm._isMobile()},model:{value:(_vm.editAgentModal.dialog),callback:function ($$v) {_vm.$set(_vm.editAgentModal, "dialog", $$v)},expression:"editAgentModal.dialog"}},[(_vm.editAgentModal.agent)?_c('agent-edit-tool',{on:{"success":function($event){_vm.editAgentModal.dialog = false; _vm.$emit('success')}},model:{value:(_vm.editAgentModal.agent),callback:function ($$v) {_vm.$set(_vm.editAgentModal, "agent", $$v)},expression:"editAgentModal.agent"}}):_vm._e()],1),_c('smart-dialog',{attrs:{"title":"Fusion Agents","width":"950"},model:{value:(_vm.mergeAgentModal.dialog),callback:function ($$v) {_vm.$set(_vm.mergeAgentModal, "dialog", $$v)},expression:"mergeAgentModal.dialog"}},[(_vm.mergeAgentModal.dialog)?_c('agent-merge-tool',{attrs:{"selectedAgents":_vm.mergeAgentModal.agents},on:{"success":function($event){_vm.mergeAgentModal.dialog = false; _vm.init()}}}):_vm._e()],1)],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }