<template>
  <div class="comment-container">
    <v-card v-if="parsedAgencyComment" class="mb-2">
      <v-card-text class="text--primary">
        {{parsedAgencyComment}}
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        Commentaire agence
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
    <v-card v-for="(comment, index) in sortedComments" :key="index" class="mb-2">
      <v-card-text class="text--primary">
        <span v-html="comment.message"></span>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        Le {{comment.creationDate}}
        par {{comment.userName}}
        <v-spacer></v-spacer>
        <v-icon small @click="toggleStar(comment)">{{comment.star?'star':'mdi-star-outline'}}</v-icon>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { get, post } from "@/utils/api";
import { EventBus } from "@/components/commons/event-bus";

export default {
  name: "BookingComment",
  mixins: [],
  props: {
    bookingId: {
      type: Number,
      required: true,
    },
    comments: {
      type: Array,
      required: true,
    },
    agencyComment:{
      type:Array, required:false
    }
  },
  data() {
    return {
      sortedComments: [],
    };
  },
  created() {},
  watch: {
    comments: {
      immediate: true,
      handler(newVal) {
        const arr = newVal.slice(0);

        const sort = (a, b) => {
          const aDate = this._parseDateTime(a.creationDate);
          const bDate = this._parseDateTime(b.creationDate);
          return aDate > bDate ? -1 : aDate < bDate ? 1 : 0;
        };

        const staredComments = arr
          .reduce((acc, c) => (c.star ? acc.concat(c) : acc), [])
          .sort(sort);
        const otherComments = arr
          .reduce((acc, c) => (!c.star ? acc.concat(c) : acc), [])
          .sort(sort);

        this.sortedComments = staredComments.concat(otherComments);
      },
    },
  },
  computed: {
    parsedAgencyComment(){
      return this.agencyComment != null && this.agencyComment.length > 0 ? JSON.parse(this.agencyComment[0].value) : null
    }
  },
  methods: {
    toggleStar(comment) {
      comment.star = !comment.star;
      const url = `/api/booking/${this.bookingId}/comment/update/${comment.id}`;
      post(url, comment).res((r) => {
        EventBus.$emit("reloadbooking", {});
        return r;
      });
    },
  },
};
</script>

<style scoped>
.comment-container {
  max-height: 400px;
  overflow: auto;
}
</style>
