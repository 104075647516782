<template>
  <v-col class="logsList">
    <v-col class="mb-1" v-for="(items, index) in groupedLogs" :key="index">
      <v-row dense class="mb-2" justify="space-between">
        <v-chip small text-color="#277ac7" color="#E3F2FD">
          <b>
            {{_parseAndFormatDate(items.logdate,
            datePatternConfig.serverLocalDate, datePatternConfig.fr.full)}}
          </b>
        </v-chip>
        <v-col cols="1">
          <v-progress-circular
            v-if="progressCircleState && index == 0"
            :indeterminate="progressCircleState"
            color="primary"
            size="20"
          ></v-progress-circular>
          <v-icon v-else-if="!progressCircleState && index == 0" @click="init(true)">mdi-autorenew</v-icon>
        </v-col>
      </v-row>

      <v-col class="pa-0 ma-0" v-for="(log, logIndex) in items.logs" :key="logIndex">
        <v-row
          dense
          justify="space-between"
          @mouseenter="log.infoIcon = true"
          @mouseleave="log.infoIcon = false"
          class="logMessage"
          @click="openLog(log)"
        >
          <v-col cols="9">
            <span
              class="mr-2"
            >{{_parseAndFormatDate(log.time, datePatternConfig.fr.time, datePatternConfig.en.time)}}</span>
            {{log.message}}
            <v-chip x-small v-if="log.userName"> Par {{log.userName}}</v-chip>
          </v-col>
          <v-col cols="1">
            <v-tooltip top>
              <v-icon
                v-if="log.infoIcon"
                small
                class="infoIcon"
                slot="activator"
              >mdi-information-outline</v-icon>
              <span>{{log.userName}}</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-col>
    </v-col>
  </v-col>
</template>

<script>
import { EventBus } from "@/components/commons/event-bus";
import { get } from "@/utils/api";
import _ from "lodash";

export default {
  name: "BookingLogs",
  props: {
    bookingid: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      progressCircleState: false,
      infoIcons: [],
      groupedLogs: [],
    };
  },
  created() {
    this.init(false);
    EventBus.$on("reloadbooking", (msg) => {
      this.init(false);
    });
  },
  destroyed() {},
  watch: {},
  computed: {},
  methods: {
    init(reload) {
      const url = `/api/logs/${this.bookingid}`;

      this.progressCircleState = reload;

      get(url).json((data) => {
        this.$emit("logsloaded", data.length);
        const logs = data.map((d) => {
          const time = this._parseAndFormatLocalDateTime(
            d.creationDate,
            this.datePatternConfig.fr.time
          );
          const date = this._parseAndFormatLocalDateTime(
            d.creationDate,
            this.datePatternConfig.serverLocalDate
          );
          const current = d.current ? d.current : "{}";
          const previous = d.previous ? d.previous : "{}";

          return {
            ...d,
            ...{
              details: d.details,
              ...{ current: current },
              ...{ previousData: previous },
              ...{
                contentDetails: this.buildHTML(
                  this.compareTree("{}", JSON.stringify(d.details)),
                  "Détails"
                ),
              },
              ...{
                contentCompare: this.buildHTML(
                  this.compareTree(previous, current),
                  "Changements groupe"
                ),
              },
              ...{ time: time },
              ...{ date: date },
              ...{ open: false },
            },
          };
        });

        this.groupedLogs = Object.entries(_.groupBy(logs, "date")).map(
          (item) => {
            item[1] = item[1].map((i) => {
              return {
                ...i,
                infoIcon: false,
              };
            });

            this.progressCircleState = false;

            return {
              logdate: item[0],
              logs: item[1],
            };
          }
        );
      });
    },

    compareTree(jsonStrA, jsonStrB) {
      const jsonA = JSON.parse(jsonStrA);
      const jsonB = JSON.parse(jsonStrB);

      let data = [];
      this.compareTreeRec(jsonA, jsonB, "", data);
      let root = {};
      data.forEach((node) => {
        let prev = root;
        node.path.split(".").map((item) => {
          let cur;
          if (!prev.hasOwnProperty(item)) {
            cur = prev[item] = {};
          } else {
            cur = prev[item];
          }

          prev = cur;
        });
        prev.message = node.message;
        prev.type = node.type;
      });

      return root;
    },

    compareTreeRec(a, b, path, res) {
      if (
        _.isPlainObject(a) ||
        _.isPlainObject(b) ||
        _.isArray(a) ||
        _.isArray(b)
      ) {
        let keys = [];
        for (let i in a) {
          if (a.hasOwnProperty(i)) {
            keys.push(i);
          }
        }
        for (let i in b) {
          if (b.hasOwnProperty(i)) {
            keys.push(i);
          }
        }

        keys.sort();
        for (let i = 0; i < keys.length; i++) {
          if (keys[i] === keys[i - 1]) {
            continue;
          }
          this.compareTreeRec(
            a && a[keys[i]],
            b && b[keys[i]],
            path + path == "" ? keys[i] : path + "." + keys[i],
            res
          );
        }
      } else {
        if (typeof a === "undefined") {
          res.push({ path: path, type: "added", message: b });
        } else if (typeof b === "undefined") {
          res.push({ path: path, type: "removed", message: a });
        } else if (a !== b) {
          res.push({ path: path, type: "changed", message: a + " -> " + b });
        }
      }
    },

    buildHTML(data, name) {
      let result = "";
      if (name != "") {
        if (!isNaN(parseInt(name))) {
          if (parseInt(name) === 0) {
            result += "<ul class=''><li>1er";
          } else {
            let number = 1 + parseInt(name);
            result += "<ul class=''><li>" + number + "ième";
          }
        } else {
          result += "<ul class='hidden log-details'><li>" + name;
        }
      }
      if (typeof data === "object") {
        if (data.hasOwnProperty("message")) {
          result +=
            ' : <span class="' + data.type + '">' + data.message + "</span>";
        } else {
          for (let i in data) {
            result += this.buildHTML(data[i], i);
          }
        }
      }
      if (name != "") {
        result += "</li></ul>";
      }
      return result;
    },
    openLog(log) {
      this.$emit("dialog", log);
    },
  },
};
</script>

<style scoped>
>>> .changed {
  color: blue;
}

>>> .added {
  color: green;
}

>>> .removed {
  color: red;
}

>>> .v-chip__content {
  border-radius: 5px;
}

.logMessage:hover {
  color: #2d84cc;
  cursor: pointer;
}

.logsList {
  height: 300px;
  overflow: scroll;
}
</style>
