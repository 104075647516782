<template>

	<span>
		<v-icon small @click="open = true">mdi-information-outline</v-icon>

		<smart-dialog :title="title" v-model="open" :width="950" :persistent="false">
			<v-row   v-if="details" class="pa-1">

				<v-col cols="12" class="ml-2 mr-2">
					<!-- TEXTS -->
					<v-col cols="12" class="mt-2">
						<v-col cols="12" v-html="details.text3" class="pl-1 py-0 ma-0"></v-col>
					</v-col>
					<v-col cols="12">
						<!-- à remplacer -->
						<v-col cols="12"><v-subheader class="pl-1 py-0 ma-0">Description</v-subheader></v-col>
						<v-col cols="12" v-html="details.desc" class="pl-1 py-0 ma-0"></v-col>
					</v-col>

				</v-col>

				<v-row  class="ma-2">
					<v-col cols="8">
						<!-- PHOTOS -->
							<v-carousel
								height="300"
							>
								<v-carousel-item
									v-for="(item,i) in details.medias"
									:key="i"
									:src="item"
								></v-carousel-item>
							</v-carousel>
					</v-col>
					<v-col cols="3" class="ml-2">
						<!-- SERVICES -->

						<v-col cols="12"><v-subheader class="pl-1 py-0 ma-0">Services</v-subheader></v-col>
						<v-col cols="12" class="pl-1 py-0 ma-0 serviceitem" v-for="(item, i) in getItems" :key="i">
							• {{item.name}} {{item.paymentType == 'onPlaceFree' ? '' : '(€)'}}
						</v-col>
					</v-col>
				</v-row>
			</v-row>
			<v-row   v-else>
				<v-col cols="12">
					<v-skeleton-loader class="" height="100%" type="list-item-avatar-two-line, list-item-three-line@7"></v-skeleton-loader>
				</v-col>
			</v-row>
		</smart-dialog>
	</span>

</template>

<script>
	import {get} from "@/utils/api"

	export default {
		name: "ProductDetails",
		props: {
			title: {
				type: String, required: true
			},
			productType: {
				type: String, required: true
			},
			productId: {
				type: Number, required: true
			},
			siteId: {
				type: Number, required: true
			},
			servicesItems: {
				type: Array, required: false
			},
		},
		mixins: [],
		data() {
			return {
				open: false,
				details: null,
			}
		},
		created() {
		},
		watch: {
			open() {

				if (!this.details) this.init()
			}
		},
		computed: {
			url() {
				let url = `/api/search/${this.productType}/details/${this.productId}`
				this.productType == "hotel" ? url = url + `/${this.siteId}` : url
				return url
			},
			getItems() {
				return this.servicesItems.map(item => {

					if (typeof item == 'string') {
						return {
							name: item,
							paymentType: 'onPlaceFree'

						}
					} else return item
				})
			}
		},
		methods: {
			init() {
				const url = this.url
				get(url)
					.json(r => {
						this.details = r
						//this.details.medias = r.medias.map(m => "https://via.placeholder.com/500x300")
					})

			}
		}
	}
</script>

<style scoped>
	.serviceitem {
		font-size: 13px;
	}
</style>
