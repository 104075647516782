<template>
  <layout :drawerShow="_isDesktop()" :drawerWidth="350">
    <template slot="drawer">
      <v-form
        ref="searchform"
        v-if="searchform && searchform.selectedNetworks"
        @submit.prevent="search"
      >
        <v-row no-gutters class="pt-2 pr-1">
          <v-col cols="12" class="pr-1">
            <smart-multi-select
              v-model="searchform.selectedNetworks"
              label="Réseaux"
              :items="networks"
              :all-is-same-as-empty="true"
              :default-select-all="true"
              @input="loadPaymentTerms()"
            ></smart-multi-select>
          </v-col>
          <!--
          <v-col
            cols="12"
            class="pr-1 mt-2"
            v-if="paymentTerms && paymentTerms.length > 0"
          >
            <smart-multi-select
              v-model="searchform.selectedPaymentTerms"
              item-text="name"
              item-value="id"
              label="Conditions de paiment"
              :all-is-same-as-empty="true"
              :default-select-all="true"
              :items="paymentTerms"
            ></smart-multi-select>
          </v-col>
-->
          <v-col cols="12" class="mt-2">
            <v-subheader light class="pa-0 mb-0 mx-0 form-subheader"
              >Dates de réservation</v-subheader
            >
            <v-divider></v-divider>
          </v-col>

          <v-col class="pr-1" cols="6">
            <smart-picker
              v-model="searchform.bookingStartDate"
              :stopDate="searchform.bookingStopDate"
              :inputOpts="{
                label: $t('begin'),
                clearable: true,
                placeholder: ' ',
              }"
            ></smart-picker>
          </v-col>

          <v-col cols="6" class="pr-1">
            <smart-picker
              v-model="searchform.bookingStopDate"
              :startDate="searchform.bookingStartDate"
              :inputOpts="{
                label: $t('end'),
                clearable: true,
                placeholder: ' ',
              }"
            ></smart-picker>
          </v-col>

          <v-col cols="12">
            <v-subheader light class="pa-0 mt-5 mb-0 mx-0 form-subheader"
              >Dates de voyage</v-subheader
            >
            <v-divider></v-divider>
          </v-col>
          <v-col class="pr-1" cols="6">
            <smart-picker
              v-model="searchform.departureStartDate"
              :stopDate="searchform.departureStopDate"
              :inputOpts="{
                label: $t('begin'),
                clearable: true,
                placeholder: ' ',
              }"
            ></smart-picker>
          </v-col>
          <v-col cols="6" class="pr-1">
            <smart-picker
              v-model="searchform.departureStopDate"
              :startDate="searchform.departureStartDate"
              :inputOpts="{
                label: $t('end'),
                clearable: true,
                placeholder: ' ',
              }"
            ></smart-picker>
          </v-col>
        </v-row>

        <smart-btn class="mt-8" :loading="loading" type="submit" block primary
          >Rechercher</smart-btn
        >
        <smart-btn
          block
          tertiary
          @click.native="
            clearSearchform();
            init();
          "
          >Réinitialiser la recherche</smart-btn
        >
      </v-form>
    </template>

    <template slot="content">
      <v-container fluid>
        <v-row>
          <v-col cols="12" class="mb-3">
            <v-card class="pa-0 ma-0 blue-grey lighten-1">
              <v-row justify="space-around">
                <v-col cols="12" md="3" class="px-1">
                  <widget-network-ca
                    :bus="widgetBus"
                    url="/api/widget/networkca-resume"
                  ></widget-network-ca>
                </v-col>
                <v-col cols="12" md="3" class="px-1">
                  <widget-network-agencies
                    :bus="widgetBus"
                    url="/api/widget/networkagency-resume"
                  ></widget-network-agencies>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <v-col>
          <widget-network-stats-by-ca
            :bus="widgetBus"
            url="/api/widget/network-stats"
          ></widget-network-stats-by-ca>
          <widget-network-stats-by-desti
            :bus="widgetBus"
            url="/api/widget/network-stats/desti"
          ></widget-network-stats-by-desti>
          <widget-network-top-agencies
            @loading="setLoading"
            :bus="widgetBus"
            url="/api/widget/network-stats/agency"
          ></widget-network-top-agencies>
        </v-col>
      </v-container>
    </template>
  </layout>
</template>
<script>
import WidgetNetworkCa from "@/components/commons/widgets/WidgetNetworkCa";
import WidgetNetworkAgencies from "@/components/commons/widgets/WidgetNetworkAgencies";
import WidgetNetworkStatsByCa from "@/components/commons/widgets/WidgetNetworkStatsByCa";
import WidgetNetworkTopAgencies from "../../components/commons/widgets/WidgetNetworkTopAgencies.vue";
import WidgetNetworkStatsByDesti from "@/components/commons/widgets/WidgetNetworkStatsByDesti";
import Vue from "vue";
import Layout from "@/views/Layout";
import { get, post } from "@/utils/api";
import SmartMultiSelect from "@/components/commons/SmartMultiSelect";
import SmartSelect from "@/components/commons/SmartSelect";
import FormMixin from "@/components/mixins/FormMixin";

export default {
  name: "NetworkStats",
  components: {
    SmartSelect,
    SmartMultiSelect,
    WidgetNetworkCa,
    WidgetNetworkAgencies,
    WidgetNetworkStatsByCa,
    WidgetNetworkTopAgencies,
    WidgetNetworkStatsByDesti,
    Layout,
  },
  mixins: [FormMixin],
  data() {
    return {
      widgetBus: new Vue(),
      searchform: {},
      defaultSearchform: {
        bookingStartDate: null,
        bookingStopDate: null,
        departureStartDate: null,
        departureStopDate: null,
        selectedNetworks: [],
        selectedPaymentTerms: [],
      },
      networks: [],
      paymentTerms: [],
      loading: false,
    };
  },
  async created() {
    await this.loadNetworks();

    this.clearSearchform();

    this.searchform.selectedNetworks = this.networks.map((i) => i.id);
    //this.searchform.selectedPaymentTerms = this.paymentTerms.map(i => i.id);
    this.searchform.bookingStartDate = this._formatDate(
      this._getFirstMonthDay(this._now()),
      this.datePatternConfig.serverLocalDate
    );
    this.searchform.bookingStopDate = this._formatDate(
      this._now(),
      this.datePatternConfig.serverLocalDate
    );

    //await this.loadPaymentTerms();
  },
  methods: {
    search() {
      if (!this.loading) {
        let form = {
          ...this.searchform,
          networkIds: this.searchform.selectedNetworks,
          selectedPaymentTerms: null,
          bookingStartDate: this.searchform.bookingStartDate
            ? this.searchform.bookingStartDate + " 00:00:00"
            : null,
          bookingStopDate: this.searchform.bookingStopDate
            ? this.searchform.bookingStopDate + " 23:59:59"
            : null,
          departureStartDate: this.searchform.departureStartDate
            ? this.searchform.departureStartDate + " 00:00:00"
            : null,
          departureStopDate: this.searchform.departureStopDate
            ? this.searchform.departureStopDate + " 23:59:59"
            : null,
        };

        this.loadWidgets(form);
      }
    },
    loadWidgets(form) {
      this.widgetBus.$emit("search", form);
    },
    async loadNetworks() {
      const url = "/api/search/networks";

      return get(url).json((data) => {
        this.networks = data;
      });
    },
    async loadPaymentTerms() {
      const url = "/api/widget/network-paymentterms";

      post(url, this.searchform.selectedNetworks).json((data) => {
        this.paymentTerms = data;
      });
    },
    clearSearchform() {
      this.searchform = this.defaultSearchform;
    },
    setLoading(loading) {
      this.loading = loading;
    },
  },
};
</script>
<style></style>
