<template>
  <v-card ref="vCardRef" :elevation="1"
          class="pa-4"
          style="height:100%; width:100%; overflow: hidden">
    <div class="mr-10 font-weight-bold">
      {{conf.title }}</div>
    <apexchart
        ref="apexcharts"
        type="heatmap"
        :height="series.length > 0 ? chartHeight : null"
        :options="options"
        :series="series"
        v-if="series.length > 0"

    ></apexchart>
  </v-card>
</template>
<script>
import {post} from "@/utils/api";
import {EventBus} from "@/components/commons/event-bus";
import {ref} from "vue";

export default {
  name: "SalesPerHourChart",
  props: {
    form: {type: Object, required: true},
    conf: {type: Object, required: false, default: null}
  },
  data() {
    return {
      vCardRef: ref(null),
      heatMapUrl: `/api/widget/dashboard/sales/hour/days`,
      series:[],
      options: {
        chart: {
          toolbar: {
            show: false
          },
          type: "heatmap"
        },
        colors: ["#0e2d5d"],
        plotOptions: {
          heatmap: {
            shadeIntensity: 1
          }
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          type: 'category',
          show: true,
          tickPlacement: 'between',
          tickAmount: 24,
          max: 24,
        },
        tooltip: {
          x: {
            show: true,
            formatter: (val) => val + 'H',
          },
        }
      },
    }
  },
  created() {
    EventBus.$on('editMode', () => this.updateSize())
    EventBus.$on('searchDashboard', () => this.init());
    this.init()
  },
  mounted(){
      this.vCardRef = this.$refs.vCardRef.$el.clientHeight
    if(this.conf.chartOptions == null){
          this.conf.chartOptions = this.options
        delete this.conf.chartOptions.xaxis.min
        delete this.conf.chartOptions.xaxis.max
      } else {
        this.options = {...this.options, ...this.conf.chartOptions}
      }
  },
  computed: {
    chartHeight() {
      return this.vCardRef != null ? `${this.$refs.vCardRef.$el.clientHeight - 50}px` : 'auto';
    },
  },
  methods: {
    init() {
      const searchForm = this._formatPayloadWidgetDashboard(this.conf,this.form)
      const self = this
      this.options.tooltip.y = {
        formatter: function (val) {
          return self._formatCurrency(val, 'EUR')
        }
      }
      post(this.heatMapUrl, searchForm, {"Content-Type": "application/json"})
          .json(r => {
            const fullData = this.fullFilledEmptyData(r)
            this.formDataHeatMap(fullData)
          })

      this.options.tooltip.custom = function ({series, seriesIndex, dataPointIndex, w}) {
        const value = self._formatCurrency(series[seriesIndex][dataPointIndex], 'EUR')
        const headerTooltip = w.config.series[seriesIndex].name + " " + w.config.series[seriesIndex].data[dataPointIndex].x + "h00"
        const count = w.config.series[seriesIndex].data[dataPointIndex].count + " dossiers"
        return `
                <div style="left: 663.205px; top: 107.287px;">
                  <div class="apexcharts-tooltip-title" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
                  ${headerTooltip}
                  </div>
                  <div class="apexcharts-tooltip-series-group apexcharts-active d-flex flex-column" style="order: 1; display: flex;" >

                        <span class="apexcharts-tooltip-text-y-value">${count}</span>
                        <span class="apexcharts-tooltip-text-y-value">${value}</span>
                  </div>
                  </div>
                </div>`
      }
    },
    updateSize(){
      this.vCardRef = this.$refs.vCardRef != null ? this.$refs.vCardRef.$el.clientHeight : 0
    },
    formDataHeatMap(list) {
      let dayIndex = 1
      while (dayIndex <= 7) {
        this.series[dayIndex - 1] = {name: this.getDays(dayIndex - 1)}
        const serie = list.filter(d => d.day === dayIndex)
        this.series[dayIndex - 1].data = serie.map((d) => {
          return {x: d.hour.toString(), y: d.total != null ? d.total : 0, count: d.count}
        })
        dayIndex++
      }
      this.series.reverse()
    },
    fullFilledEmptyData(list) {
      const copyList = JSON.parse(JSON.stringify(list))
      for(let ind=1; ind<=7; ind++){
        let dayDatas = copyList.filter(d => d.day === ind)
        for(let hourInd=0; hourInd<=23; hourInd++){
          const hour = dayDatas.find(day => day.hour === hourInd)
          if(hour == null){
            const listIndex = list.findIndex(obj => obj.day === ind && obj.hour === hourInd-1)
            list.splice(listIndex, 0, {
              day: ind, hour: hourInd, count: 0, total: 0
            })
          }
        }
      }
      list.sort((a,b)=> a.hour - b.hour)
      return list
    },
    getDays(ind) {
      const week = ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi", "Dimanche"]
      return week[ind]
    }
  },
}
</script>