<template>
  <prestation
    kind="prestationgroup"
    ref="prestationcpnt"
    :booking="booking"
    :prestation="prestation"
    :index="index"
    :loading="disablePanel"
    :renderfab="renderfab"
  >
    <div slot="header-sup">{{ prestation.start }} - {{ prestation.stop }}</div>

    <div slot="header-title">
      <div class="subtitle-1 font-weight-medium">
        {{ prestation.name }}
      </div>
      <div class="body-2">
        {{
          prestation.data.name && prestation.data.name != prestation.name
            ? prestation.data.name + " - "
            : ""
        }}
        <availability
          v-model="prestation.availability"
          kind="Stock"
          :chip="false"
          class="caption"
        />
      </div>
      <div
        class="small"
        v-if="prestation.options && prestation.options.length > 0"
      >
        Options:
        <span v-for="(opts, index) in prestation.options" :key="index">
          {{ opts.name }}
          <span v-if="opts.payOnSite">A payer sur place</span>
          <span
            >{{ opts.price }} {{ $t(prestation.currency + "-symbol") }}</span
          >
          |
        </span>
      </div>
    </div>

    <div slot="header-status">
      <availability v-model="prestation.status" kind="BookingStatus" />
      <availability v-model="prestation.supplierStatus" kind="SupplierStatus" />
    </div>

    <div slot="header-price">
      <div class="headline font-weight-regular">
        {{ prestation.price }} {{ $t(prestation.currency + "-symbol") }}
      </div>
    </div>

    <div slot="content">
      <!-- <div v-if="nbAdults>0">
				{{ $tc("Adult-count-small", nbAdults, {size:nbAdults}) }} ({{initials(adults)}})
			</div>


			<div v-if="nbChilds>0">
				{{ $tc("Child-count-small", nbChilds, {size:nbChilds}) }} ({{initials(childs)}})
			</div>
      -->
      <!-- ----------------------------- dialogs ----------------------------- -->

      <v-row v-if="prestation.oldPrestationGroup" class="align-center">
        <v-col
          cols="8"
          class="pa-1 elevation-1 grey-lighten-5"
          style="background: #fafafa"
        >
          <v-icon size="14">mdi-timer-sand</v-icon>
          Remplace la prestation
          {{ prestation.oldPrestationGroup.name }}
          <availability
            v-model="prestation.oldPrestationGroup.status"
            kind="BookingStatus"
          />
          <availability
            v-model="prestation.oldPrestationGroup.supplierStatus"
            kind="SupplierStatus"
          />

          <b>{{
            prestation.oldPrestationGroup.price
              | currency(prestation.oldPrestationGroup.currency)
          }}</b>

          <v-icon
            size="14"
            @click="showOldPrestationGroup = !showOldPrestationGroup"
            >mdi-eye</v-icon
          >
        </v-col>
      </v-row>

      <smart-dialog
        v-model="editDialog"
        fullscreen
        persistent
        :title="$t('carrental')"
      >
        <template>
          <add-carrental
            :booking="booking"
            :carrent-selected="prestation"
            :sumPrestation="addPrestationRecap"
            :lastPrestationDate="prestation.start"
          ></add-carrental>
        </template>
      </smart-dialog>

      <smart-dialog
        v-model="editOptionsDialog"
        :width="450"
        title="Modifier options"
      >
        <div
          row
          wrap
          v-if="
            carRentDetails != null &&
            carRentDetails.options != null &&
            carRentDetails.options.length > 0
          "
        >
          <v-checkbox
            v-for="option in carRentDetails.options"
            :key="option.id"
            :label="
              option.name +
              ' ' +
              option.pricing.prices.price +
              ' ' +
              $t(option.pricing.prices.purchaseCurrency.code + '-symbol')
            "
            v-model="option.checked"
          ></v-checkbox>
        </div>
        <div v-else>Aucune options disponible.</div>
        <v-card-actions class="actions-btn">
          <smart-btn
            v-if="
              carRentDetails != null &&
              carRentDetails.options != null &&
              carRentDetails.options.length > 0
            "
            primary
            :loading="loading"
            block
            @click.native="validOptions"
            >Confirmer</smart-btn
          >
          <smart-btn
            v-else
            primary
            :loading="loading"
            block
            @click.native="editOptionsDialog = false"
            >Fermer</smart-btn
          >
        </v-card-actions>
      </smart-dialog>

      <smart-dialog
        v-if="prestation.oldPrestationGroup"
        v-model="showOldPrestationGroup"
        :width="900"
        :title="prestation.oldPrestationGroup.name"
      >
        <template>
          <prestation-carrental
            :booking="booking"
            :prestation="prestation.oldPrestationGroup"
            :index="0"
            :renderfab="false"
          ></prestation-carrental>
        </template>
        <v-btn
          dark
          icon
          absolute
          top
          right
          style="top: 6px; right: 70px"
          @click="addPrestationRecap = true"
        >
          <v-icon>mdi-information-outline</v-icon>
        </v-btn>

        <v-navigation-drawer
          v-model="addPrestationRecap"
          absolute
          right
          temporary
          style="margin-top: 48px; height: calc(100% - 48px)"
        >
          <booking-drawer-content
            v-if="booking"
            :booking="booking"
          ></booking-drawer-content>
        </v-navigation-drawer>
      </smart-dialog>

      <smart-dialog
        title="Message Location de véhicule"
        v-model="dialogs.email"
        :width="800"
      >
        <mail-composer
          :subject="'Dossier réf ' + booking.id"
          :tos="supplierEmails"
          :loading="mailLoading"
          @send="sendMsgSupplier"
          :message="messageSupplier"
        ></mail-composer>
      </smart-dialog>
    </div>

    <div slot="menu-top">
      <v-list-item @click="editDialog = !editDialog">
        <v-list-item-title>
          <v-icon small>mdi-swap-horizontal</v-icon>
          {{ $t("modify-carrent") }}
        </v-list-item-title>
      </v-list-item>
      <v-list-item @click="openEditOptions">
        <v-list-item-title>
          <v-icon small>mdi-view-grid-plus-outline</v-icon>
          {{ $t("modify-options") }}
        </v-list-item-title>
      </v-list-item>
    </div>

    <div slot="menu-bottom">
      <v-list-item @click="openEmailDialog">
        <v-list-item-title>{{ $t("message-transfert") }}</v-list-item-title>
      </v-list-item>
      <v-list-item
        :disabled="prestation.supplierStatus !== 'ondemand'"
        @click="sendRevival"
      >
        <v-list-item-title>{{ $t("revival-dmd") }}</v-list-item-title>
      </v-list-item>
    </div>
  </prestation>
</template>
<script>
import { get, post } from "@/utils/api";
import GuestList from "@/components/commons/GuestList";
import CollapsePanel from "@/components/commons/CollapsePanel";
import BookingPriceComputing from "@/components/commons/BookingPriceComputing";
import BookingPrestaInvoices from "@/components/commons/BookingPrestaInvoices";
import BookingPrestaVoucher from "@/components/commons/BookingPrestaVoucher";
import PrestationMixin from "@/components/mixins/PrestationMixin";
import GuestsMixin from "@/components/mixins/GuestsMixin";
import Availability from "@/components/commons/Availability";
import AddCarrental from "@/components/booking/carrental/AddCarrental";
import { EventBus } from "@/components/commons/event-bus";
import BookingPrestaCancelfees from "../commons/BookingPrestaCancelfees";
import Prestation from "@/components/booking/Prestation";
import BookingDrawerContent from "../commons/BookingDrawerContent";
import MailComposer from "../commons/MailComposer";

export default {
  name: "PrestationCarrental",
  props: ["index", "renderfab", "prestation"],
  mixins: [PrestationMixin, GuestsMixin],
  components: {
    MailComposer,
    BookingDrawerContent,
    BookingPrestaCancelfees,
    AddCarrental,
    CollapsePanel,
    Availability,
    BookingPrestaVoucher,
    BookingPrestaInvoices,
    BookingPriceComputing,
    GuestList,
    Prestation,
  },

  data() {
    return {
      addPrestationRecap: true,
      guests: this.prestation.guests,
      panel: 1, //default open
      editDialog: false,

      editOptionsDialog: false,
      showOldPrestationGroup: false,
      carRentDetails: null,

      loading: false,

      evtShow: this.prestation.prestationLink
        ? "over-" +
          this.prestation.kind +
          "-" +
          this.prestation.prestationLink.fromPrestationLinkId
        : null,

      tab: "",
      dialogs: {
        email: false,
      },
      supplierEmails: [],
      mailLoading: false,
    };
  },
  created() {
    EventBus.$on("carrental-edited", () => {
      this.editDialog = false;
    });
  },
  destroyed() {
    EventBus.$off("carrental-added");
  },
  computed: {
    messageSupplier() {
      const guests = this.guests
        .map((g) => `<li>${g.lastName} ${g.firstName}</li>`)
        .join("");
      return `
					Ref dossier: ${this.booking.id}<br/>
					Nom client: ${this.booking.customer.lastname} ${
        this.booking.customer.firstname
      }<br/>
					in: ${this._parseAndFormatDate(
            this.prestation.start,
            this._datePattern.localDateTime,
            this._prestationDatePattern(this.prestation.start)
          )}<br/>
					out: ${this._parseAndFormatDate(
            this.prestation.stop,
            this._datePattern.localDateTime,
            this._prestationDatePattern(this.prestation.stop)
          )}<br/>
					Passagers: <ul>${guests}</ul>
				`;
    },
  },
  methods: {
    remove() {
      this.deletePrestationGroup();
    },
    cancel() {
      this.cancelPrestationGroup();
    },
    openEditOptions() {
      const ctx = {
        start: this._parseAndFormatDate(
          this.prestation.start,
          this.datePatternConfig.serverLocalDateTime,
          this.datePatternConfig.serverLocalDate
        ),
        stop: this._parseAndFormatDate(
          this.prestation.stop,
          this.datePatternConfig.serverLocalDateTime,
          this.datePatternConfig.serverLocalDate
        ),
        from: {
          pointType: "HOTEL",
          id: this.prestation.fromId,
        },
        to: {
          pointType: "HOTEL",
          id: this.prestation.toId,
        },
        guests: this.prestation.guests,
        priceClassTypeId: this.booking.context.priceClassType.id,
        currency: {
          code: this.booking.currency,
        },
        debug: false,
      };

      post("/api/prestation/add/search-carrental", ctx)
        .badRequest((err) => {
          EventBus.$emit("toaster-msg", JSON.parse(err.null).errors.join(","));
        })
        .json((data) => {
          this.carRentDetails = JSON.parse(data.data).find(
            (d) => d.id == this.prestation.rentId
          );

          const currentOptIds = this.prestation.options.map((o) => o.id);
          this.carRentDetails.options.map((o) => {
            o.checked = currentOptIds.indexOf(o.id) > -1;
            return o;
          });
        })
        .then(() => (this.editOptionsDialog = true))
        .catch(() => {
          this.loading = false;
        });
    },
    validOptions() {
      this.loading = true;

      post(
        `/api/prestation/edit/carrental?bookingId=${this.booking.id}&prestationGroupId=${this.prestation.prestationGroupId}`,
        this.buildCtx()
      )
        .forbidden((err) => console.log(err.status)) //TODO
        .internalError((err) => {
          EventBus.$emit("toaster-msg", this.$t("error-500"));
        })
        .badRequest((err) => {
          EventBus.$emit("toaster-msg", JSON.parse(err.null).errors.join(","));
        })
        .json(() => {
          this.editOptionsDialog = false;
          this.loading = false;

          EventBus.$emit("reloadbooking", {});
          EventBus.$emit("toaster-msg", "Modifications enregistrées");
        })
        .then(() => (this.editOptionsDialog = false))
        .catch(() => {
          this.loading = false;
        });
    },
    buildCtx() {
      return {
        startDate: this._parseAndFormatDate(
          this.carRentDetails.start,
          this.datePatternConfig.serverLocalDate,
          this.datePatternConfig.serverLocalDateTime
        ),
        stopDate: this._parseAndFormatDate(
          this.carRentDetails.stop,
          this.datePatternConfig.serverLocalDate,
          this.datePatternConfig.serverLocalDateTime
        ),
        name: "",
        guests: this.prestation.guests,
        rent: {
          ...this.carRentDetails,
          ...this.booking.priceClassTypeId,
        },
        from: {
          pointType: "HOTEL",
          id: this.prestation.fromId,
        },
        to: {
          pointType: "HOTEL",
          id: this.prestation.toId,
        },
      };
    },

    showLinks() {
      EventBus.$emit(this.evtShow, { render: true });
    },
    hideLinks() {
      EventBus.$emit(this.evtShow, { render: false });
    },
    openEmailDialog() {
      const url = `/api/supplier/${this.prestation.supplierId}`;
      get(url).json((r) => {
        this.supplierEmails = r && r.email ? r.email.split(",") : [];
      });
      this.dialogs.email = true;
    },
    sendMsgSupplier(email) {
      this.mailLoading = true;
      const url = `/api/mail/sendSupplier/${this.booking.id}`;
      post(url, email)
        .json((r) => {
          EventBus.$emit("toaster-msg", "Email envoyé");
          EventBus.$emit("reloadbooking", {});
          return r;
        })
        .then(() => {
          this.mailLoading = false;
          this.dialogs.email = false;
        });
    },
    sendRevival() {
      this.$refs.prestationcpnt.sendRevival();
    },
  },
};
</script>

<style scoped>
</style>
