<template>
  <div>
    <p class="mb-0 headline text-center">{{ title }}</p>
    <div class="d-flex align-center justify-center">
      <v-icon
          v-for="(star, i) in 4"
          :key="i"
          small
          :color="i + 1 <= average ? 'yellow darken-2' : 'white'"
      >{{ setStars(i,average) }}</v-icon
      >
      <p class="mb-0 ml-4 body-1">{{count}} avis</p>
    </div>
  </div>
</template>
<script>
export default {
  name:"AverageItem",
  props:{
    average:{type:Number,required:true},
    count:{type:Number,required:true},
    title:{type:String ,required:true}
  },
  methods:{
    setStars(i, star) {
      return i + 1 <= star ? "mdi-star" : "mdi-star-outline";
    },
  }
}
</script>