<template>
  <div style="height: 100%">
    <div style="height: 100%" v-html="conf.data.vHtml" v-if="conf.data.vHtml.length > 0"></div>
    <div v-else class="d-flex flex-column justify-center align-center text-center" style="height: 100%">
      <p>Pour ajouter un iframe ou autre chose dans ce widget, il suffit juste de coller le contenu dans la propriété "vHtml" de la config</p>
      <smart-btn @click.native="openConfig()">Config</smart-btn>
    </div>
  </div>
</template>
<script>
import {post} from "@/utils/api";
import WidgetTile from "@/components/commons/widgets/WidgetTile.vue";
import {EventBus} from "@/components/commons/event-bus";
export default {
  name: "WidgetTemplate",
  components: {},
  props:{
    form: {type:Object, required:true},
    conf: {type: Object, required: false, default: null}
  },
  computed: {
  },
  created() {
    EventBus.$on('searchDashboard', () => console.log('x'));
  },
  methods: {
    openConfig(){
      this.$emit("openConfig")
    }
  },
}
</script>

