<template>
  <layout>
    <template slot="nav">
      <nav-exports />
    </template>
    <template slot="drawer"></template>
    <template slot="content">
      <v-container>
        <v-row>
          <v-col cols="12" sm="6" md="4">
            <v-card>
              <v-row align="center">
                <v-col cols="9">
                  <v-card-title class="subheading">Ecritures</v-card-title>
                  <v-card-text
                    class="py-0 grey--text caption"
                  >Dernier export le {{lastExports.accounting}}</v-card-text>
                </v-col>
                <v-col cols="3" class="text-center">
                  <a href="#" @click="exportAccounting">
                    <v-icon large round>mdi-file-download</v-icon>
                  </a>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </layout>
</template>
<script>
import Layout from "@/views/Layout";
import NavExports from "./NavExports";
import { get } from "@/utils/api";

export default {
  components: { Layout, NavExports },
  data: () => ({
    lastExports: {},
  }),
  created() {
    get("/api/accounting/export/last-dates").json(
      (data) => (this.lastExports = data)
    );
  },
  methods: {
    exportAccounting() {
      const url = "/api/accounting/export/accounting";
      const win = window.open(url, "_blank");
      win.focus();
    },
  },
};
</script>