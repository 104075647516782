<template>
  <v-col cols="12" @click="deactivateResults">
    <v-row>
      <v-text-field
        solo
        flat
        :loading="loading"
        :light="isActive"
        :height="30"
        hide-details
        dense
        :rows="1"
        v-model="search"
        clearable
        :color="colorLabel"
        :background-color="inputBackground"
        label="Rechercher un dossier par référence, client, email"
        @focus="activeInput"
        @blur="blurInput"
        prepend-inner-icon="place"
        :class="{'inputFontColor' : !isActive}"
      >
        <v-icon slot="prepend-inner" :color="colorLabel">search</v-icon>
      </v-text-field>
    </v-row>

    <v-row
      class="mt-1 d-none d-sm-flex"
      id="searchResultsContainer"
      style="background-color: white;position: absolute; box-shadow: #1a1a1a; max-height: 700px ;"
    >
      <div v-show="isVisible">
        <slot></slot>
      </div>
    </v-row>

    <v-row
      class="mt-1 d-flex d-sm-none"
      id="searchResultsContainer"
      style="background-color: white;position: absolute; box-shadow: #1a1a1a; max-height: 700px ;left: 0;"
    >
      <div v-show="isVisible">
        <slot></slot>
      </div>
    </v-row>
  </v-col>
</template>

<script>
import SiteLabel from "./SiteLabel";
import EventBus from "./event-bus";
import _ from "lodash";

export default {
  name: "SmartSearch",
  components: { SiteLabel },
  props: {
    value: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
    },
    isVisible: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      select: null,
      search: null,
      result: null,
      isActive: false,
      colorLabel: "#fff",
      inactiveInputBackground: "hsla(0,0%,100%,.16)",
      inputBackground: null,
      menu: {
        closeOnClick: false,
        closeOnContentClick: false,
        openOnClick: false,
        maxHeight: 500,
      },
    };
  },
  created() {
    this.inputBackground = this.inactiveInputBackground;
  },
  watch: {
    search: _.debounce(function (newSearch) {
      if (newSearch && typeof newSearch !== "object") {
        const search =
          typeof newSearch == "string" ? newSearch.trim() : newSearch;
        this.$emit("input", search);
      }
    }, 300),
  },
  methods: {
    activeInput() {
      this.inputBackground = "#fff";
      this.colorLabel = "blue";
      this.isActive = true;
    },
    blurInput() {
      this.inputBackground = this.inactiveInputBackground;
      this.colorLabel = "white";
      this.isActive = false;
    },
    deactivateResults() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
>>> .v-input__slot {
  background-color: hsla(0, 0%, 100%, 0.16);
}

>>> .v-text-field .v-input__control {
  min-height: 36px !important;
}

#searchResultsContainer {
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.inputFontColor >>> .v-text-field__slot input {
  color: #fff;
}

.inputFontColor >>> .v-text-field__slot v-label {
  color: white !important;
}
</style>