<template>
  <v-navigation-drawer
    v-model="drawer"
    right
    fixed
    stateless
    class="booking-summary-drawer"
    width="350"
  >
    <v-list class="pt-0" dense v-if="drawerInfos.booking">
      <v-list-item>
        <open-booking-drawer :booking="drawerInfos.booking"></open-booking-drawer>
        <v-list-item-content class="title">Récapitulatif</v-list-item-content>
      </v-list-item>
      <booking-drawer-content :booking="drawerInfos.booking"></booking-drawer-content>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
//import {EventBus} from "@/components/commons/event-bus";
import OpenBookingDrawer from "./OpenBookingDrawer";
import BookingDrawerContent from "./BookingDrawerContent";

export default {
  name: "BookingDrawer",
  components: { OpenBookingDrawer, BookingDrawerContent },
  mixins: [],
  data: function () {
    return {
      drawer: false,
    };
  },
  created() {},
  destroyed: function () {},

  watch: {
    drawerBookingSummary(newVal) {
      this.drawer = newVal;
    },
  },
  computed: {
    drawerBookingSummary() {
      return this.$store.state.drawerBookingSummary;
    },
    drawerInfos() {
      return this.$store.state.drawerInfos;
    },
  },

  methods: {},
};
</script>

<style scoped>
.booking-summary-drawer {
  z-index: 300;
}

.rowprestation {
  padding: 5px 16px 5px 16px;
}

.title-resume {
  margin-bottom: 10px;
}
</style>
