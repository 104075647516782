<template>
  <layout :drawer-show="true">
    <template slot="nav">
      <nav-currencies />
    </template>

    <template slot="drawer">
      <v-container fluid class="pa-0">
        <v-row no-gutters>
          <v-col cols="12">
            <v-subheader light class="pa-0 mt-1 mb-0 mx-0 form-subheader"
              >Filtres</v-subheader
            >
            <v-divider></v-divider>
          </v-col>

          <v-col cols="12" class="pr-1">
            <v-select
              clearable
              v-model="selectedYear"
              :items="years"
              item-value="id"
              @change="load"
              item-text="name"
              label="Année"
              hide-details
            ></v-select>
          </v-col>
          <v-col cols="12" class="mb-3 mt-3">
            <v-btn class="primary block" :loading="loading" @click="load">
              <v-icon>search</v-icon>Rechercher
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </template>

    <template slot="content">
      <v-container fluid>
        <v-card>
          <currencies-payment-table
            @loading="setLoading"
            hideEuroRate
            :loading="loading"
            :raw-data="rawData"
          />
        </v-card>
      </v-container>
    </template>
  </layout>
</template>

<script>
import Layout from "@/views/Layout";
import NavCurrencies from "@/views/accounting/currenciespayments/NavCurrencies";
import CurrenciesPaymentTable from "./CurrenciesPaymentTable.vue";
import { EventBus } from "@/components/commons/event-bus";
import { get, post } from "@/utils/api";

export default {
  components: {
    Layout,
    NavCurrencies,
    CurrenciesPaymentTable,
  },
  data: () => ({
    rowsPerPageItems: [{ text: "Tous", value: -1 }, 50, 200],
    pagination: {
      sortBy: "bookingDate",
    },
    rawData: [],
    selectedYear: new Date().getFullYear(),
    loading: false,
  }),
  computed: {
    years() {
      const items = [];
      const year = new Date().getFullYear();
      for (let index = year - 4; index < year + 3; index++) {
        items.push(index);
      }
      return items;
    },
  },
  created() {
    this.load();
  },
  methods: {
    load() {
      this.loading = true;
      const url = `/api/accounting/usd-covering/history?year=${this.selectedYear}`;
      get(url)
        .json((r) => {
          this.rawData = r;
        })
        .then(() => {
          this.loading = false;
          this.items;
        });
    },
    setLoading(loading) {
      this.loading = loading;
    },
  },
};
</script>
<style scoped></style>
