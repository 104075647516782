<template>
  <div>
    <v-row align="center" v-for="(customer, idx) in guests" :key="idx">
      <v-col cols="1">
        <v-text-field disabled label="ID" v-model="customer.id" />
      </v-col>
      <v-col cols="3" class="px-1">
        <v-text-field
          v-model="customer.firstName"
          :rules="checkRules ? rules.nonEmpty() : []"
          :label="$t('firstname')"
          required
        />
      </v-col>
      <v-col cols="3" class="px-1">
        <v-text-field
          v-model="customer.lastName"
          :rules="checkRules ? rules.nonEmpty() : []"
          :label="$t('lastname')"
          required
        />
      </v-col>
      <v-col cols="2" class="px-1">
        <v-select
          v-model="customer.ageClassType"
          :rules="rules.nonEmpty()"
          :items="ageClassTypeItems"
          item-text="label"
          item-value="value"
          :label="$t('ageclasstype')"
        />
      </v-col>
      <v-col cols="2" class="px-1">
        <v-text-field
          v-model="customer.birthDate"
          :rules="rules.birthDate(idx)"
          :label="$t('birthdate')"
          v-mask="'##/##/####'"
          @change="birthDateChange(customer)"
        />
      </v-col>
      <v-col cols="1" class="text-center" v-show="idx == guests.length - 1">
        <v-tooltip right>
          <template #activator="{ on }">
            <v-icon v-on="on" slot="activator" @click="addCustomer"
              >mdi-account-plus</v-icon
            >
          </template>
          <span>Ajouter un voyageur</span>
        </v-tooltip>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import FormMixin from "@/components/mixins/FormMixin";
import GuestsMixin from "@/components/mixins/GuestsMixin";

export default {
  name: "BookingGuestCreation",
  mixins: [GuestsMixin, FormMixin],
  props: {
    value: {
      type: Array,
      required: true,
    },
    bookingStatus: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      checkRules: false,

      defaultCustomer: {
        id: null,
        firstName: "",
        lastName: "",
        ageClassType: "Adult",
        birthDate: null,
      },

      guests: [],

      //--
      rules: {
        birthDate: (idx) => [
          (v) => {
            if (this.guests[idx].ageClassType !== "Adult")
              return !!v || this.$t("birthdate-required");
            else return true;
          },
        ],
      },
    };
  },
  created() {
    this.init();
  },
  watch: {
    value: {
      deep: true,
      handler(newVal) {
        this.guests = newVal;
      },
    },
    guests: {
      deep: true,
      handler(newVal) {
        this.$emit("input", newVal);
      },
    },
    bookingStatus: {
      immediate: true,
      handler(newVal) {
        this.checkRules = newVal !== "quotation";
      },
    },
  },
  computed: {},
  methods: {
    init() {
      const customer = { ...{}, ...this.defaultCustomer };
      this.guests = this.value.length > 0 ? this.value : [customer];
    },

    addCustomer() {
      this.guests.push({ ...this.defaultCustomer });
    },
    birthDateChange(customer) {
      if (this._isMajor(customer.birthDate)) {
        customer.ageClassType = "Adult";
      } else {
        customer.ageClassType = "Child";
      }
    },
  },
};
</script>

<style scoped></style>
