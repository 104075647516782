<template>
  <layout drawerShow>
    <template slot="nav">
      <nav-exports />
    </template>
    <template slot="drawer">
      <v-form>
        <v-subheader light class="pa-0 ma-0">Réservation</v-subheader>
        <v-row>
          <v-col class="pr-1" cols="6">
            <smart-picker
              v-model="filters.start"
              :stopDate="filters.stop"
              :inputOpts="{ label: $t('begin') }"
            />
          </v-col>
          <v-col cols="6">
            <smart-picker
              v-model="filters.stop"
              :startDate="filters.start"
              :inputOpts="{ label: $t('end') }"
            />
          </v-col>
        </v-row>
        <smart-btn
          class="mt-5"
          block
          primary
          @click.native="search"
          :loading="loading"
          >Rechercher</smart-btn
        >
      </v-form>
    </template>
    <template slot="content">
      <v-container fluid>
        <v-card>
          <v-card-text>
            <v-row>
              <v-col cols="6">
                <span class="headline">Ventes</span>
              </v-col>
              <v-col cols="6" class="text-right">
                <smart-btn primary @click.native="exportCsv">
                  <v-icon small class="mr-1">mdi-file-export</v-icon>Export
                </smart-btn>
              </v-col>
            </v-row>

            <v-data-table
              :headers="headers"
              :items="items"
              :loading="loading"
              class="tbl elevation-1"
              :item-class="itemClass"
              :footer-props="{
                'items-per-page-options': [
                  { text: 'Tous', value: -1 },
                  50,
                  200,
                ],
                'items-per-page-text': 'Lignes par page',
              }"
            ></v-data-table>
          </v-card-text>
        </v-card>
        <v-card class="my-5">
          <v-card-text>
            <v-row>
              <v-col cols="6">
                <span class="headline">Recap</span>
              </v-col>
              <v-col cols="6" class="text-right">
                <smart-btn primary @click.native="exportRecapCsv">
                  <v-icon small class="mr-1">mdi-file-export</v-icon>Export
                  Recap
                </smart-btn>
              </v-col>
            </v-row>

            <v-data-table
              :headers="headersRecap"
              :items="itemsRecap"
              :loading="loading"
              class="tbl elevation-1"
              :footer-props="{
                'items-per-page-options': [
                  { text: 'Tous', value: -1 },
                  50,
                  200,
                ],
                'items-per-page-text': 'Lignes par page',
              }"
            ></v-data-table>
          </v-card-text>
        </v-card>
        <v-card class="my-5">
          <v-card-text>
            <v-tabs>
              <v-tab key="vt">Ecritures Ventes</v-tab>
              <v-tab-item key="vt" class="pt-3">
                <smart-btn
                  absolute
                  top
                  right
                  primary
                  @click.native="exportEcritures(true)"
                >
                  <v-icon small class="mr-1">mdi-file-export</v-icon>Export
                  Ecritures Ventes
                </smart-btn>

                <v-data-table
                  :headers="headersEcritures"
                  :items="ecrituresVT"
                  :loading="loading"
                  class="tbl elevation-1"
                  :footer-props="{
                    'items-per-page-options': [
                      { text: 'Tous', value: -1 },
                      50,
                      200,
                    ],
                    'items-per-page-text': 'Lignes par page',
                  }"
                >
                  <template v-slot:item="props">
                    <td
                      v-for="(item, itemIndex) in props.item"
                      :key="itemIndex"
                    >
                      {{ item }}
                    </td>
                  </template>
                </v-data-table>
              </v-tab-item>

              <v-tab key="ha">Ecritures Achats</v-tab>
              <v-tab-item key="ha" class="pt-3">
                <smart-btn
                  absolute
                  top
                  right
                  primary
                  @click.native="exportEcritures(false)"
                >
                  <v-icon small class="mr-1">mdi-file-export</v-icon>Export
                  Ecritures Achats
                </smart-btn>

                <v-data-table
                  :headers="headersEcritures"
                  :items="ecrituresHA"
                  :loading="loading"
                  class="tbl elevation-1 mt-2"
                  :footer-props="{
                    'items-per-page-options': [
                      10,
                      25,
                      50,
                      {
                        text: '$vuetify.dataIterator.rowsPerPageAll',
                        value: -1,
                      },
                    ],
                    'rows-per-page-options': 'Lignes par page',
                  }"
                >
                  <template v-slot:item="props">
                    <td
                      v-for="(item, itemIndex) in props.item"
                      :key="itemIndex"
                    >
                      {{ item }}
                    </td>
                  </template>
                </v-data-table>
              </v-tab-item>
            </v-tabs>
          </v-card-text>
        </v-card>
      </v-container>
    </template>
  </layout>
</template>
<script>
import Layout from "@/views/Layout";
import NavExports from "./NavExports";
import { get, post } from "@/utils/api";
import { startOfMonth } from "date-fns";

export default {
  components: { Layout, NavExports },
  data() {
    return {
      loading: false,
      items: [],
      ecrituresVT: [],
      ecrituresHA: [],
      headers: [
        { text: "#", value: "id" },
        { text: "SITE", value: "site" },
        { text: "ZONE", value: "zone" },
        { text: "DATE RESA", value: "bookingDate" },
        { text: "DATE DEPART", value: "startDate" },
        { text: "STATUT", value: "status" },
        { text: "VENTE", value: "euroPrice", width: 100 },
        { text: "ACHAT PREV", value: "euroPurchasePrice", width: 100 },
        { text: "VENTE FACT", value: "vf", width: 100 },
        { text: "VENTE NON FACT", value: "vnf", width: 100 },
        { text: "ACHAT REEL", value: "haReel", width: 100 },
        { text: "ACHAT NON FACT", value: "haPrev", width: 100 },
        { text: "ACHAT PROV", value: "haProv", width: 100 },
        { text: "DESACCORD", value: "desaccord", width: 50 },
      ],
      headersRecap: [
        { text: "#", value: "siteId", width: 30 },
        { text: "SITE", value: "site" },
        { text: "ZONE", value: "zone" },
        { text: "ANNEE", value: "year" },
        { text: "MOIS", value: "month" },
        { text: "VENTE FACT", value: "vf" },
        { text: "VENTE NON FACT", value: "vnf" },
        { text: "ACHAT REEL", value: "haReel" },
        { text: "ACHAT NON FACT", value: "haPrev" },
        { text: "ACHAT PROV", value: "haProv" },
      ],
      headersEcritures: [
        { text: "DATE" },
        { text: "" }, // Z9
        { text: "COMPTE" },
        { text: "ZONE" },
        { text: "DEBIT" },
        { text: "CREDIT" },
      ],
      itemsRecap: [],
      filters: {
        start: null,
        stop: null,
      },
    };
  },
  created() {
    this.filters.start = this._formatDate(
      startOfMonth(new Date()),
      this.datePatternConfig.serverLocalDate
    );
    this.filters.stop = this._formatDate(
      new Date(),
      this.datePatternConfig.serverLocalDate
    );

    this.search();
  },
  methods: {
    itemClass(item) {
      if (item.vf + item.vnf < item.haReel + item.haPrev + item.haProv) {
        return ["red lighten-1"];
      }
      return [];
    },
    search() {
      this.loading = true;
      this.items = [];
      this.ecrituresVT = [];
      this.ecrituresHA = [];
      post(`/api/accounting/export/ventes`, this.filters)
        .json((data) => {
          this.items = data.results;
          this.itemsRecap = data.recap;
          this.ecrituresVT = data.ecrituresVT;
          this.ecrituresHA = data.ecrituresHA;
        })
        .then((d) => {
          this.loading = false;
        });
    },
    exportCsv() {
      post(`/api/accounting/export/ventes/csv`, this.filters).blob((blob) => {
        var date = (this.filters.start + "_" + this.filters.stop).replace(
          /\//g,
          "-"
        );

        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "export_" + date + ".csv";
        link.click();
        link.remove();
      });
    },
    exportRecapCsv() {
      post(`/api/accounting/export/ventes/csv/recap`, this.filters).blob(
        (blob) => {
          var date = (this.filters.start + "_" + this.filters.stop).replace(
            /\//g,
            "-"
          );

          var link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "export_recap_" + date + ".csv";
          link.click();
          link.remove();
        }
      );
    },
    exportEcritures(VT) {
      post(`/api/accounting/export/ventes/csv/ecritures`, {
        ...this.filters,
        ...{ vt: VT },
      }).blob((blob) => {
        var date = (this.filters.start + "_" + this.filters.stop).replace(
          /\//g,
          "-"
        );
        var label = VT ? "vente" : "achat";

        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "export_ecritures_" + label + "_" + date + ".csv";
        link.click();
        link.remove();
      });
    },
  },
};
</script>
<style scoped></style>
