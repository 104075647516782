<template>
  <div>
    réf. {{payment.ref}}
    <!--
    <smart-dialog v-model="dialog" title="Détail du paiement" scrollable>
      <v-btn small icon class="ma-0" slot="activator"><v-icon small color="grey">mdi-dots-horizontal</v-icon></v-btn>
      <v-list dense>
        <template v-for="(value,key,index) in details.params">
          <v-divider v-if="index>0"></v-divider>
          <v-list-item>
            <v-list-item-content class="grey--text">{{key}}:</v-list-item-content>
            <v-list-item-content class="align-end">{{ value }}</v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
      <template slot="actions">
        <smart-btn tertiary @click.native="close">Fermer</smart-btn>
      </template>
    </smart-dialog>
    -->
  </div>
</template>
<script>
  export default {
    name: "EmptyDetail",
    props:{
      'payment': {type:Object}
    },
    data: () => ({
      dialog:false
    }),
    methods:{
      close(){
        this.dialog = false
      }
    }
  }
</script>
