<template>
  <div
    class="ma-1 task-bloc"
    :class="taskTextColor(task.level) + ' ' + taskColor(task.level)"
  >
    <v-row class="caption grey--text px-2 py-1" align="center" no-gutters>
      <v-col cols="4">
        <span v-if="task.expirationDate">
          <v-icon small style="font-size: 13px; margin-left: 1px">timer</v-icon>
          {{ task.expirationDate }}
        </span>
      </v-col>

      <v-col class="text-right">
        <span v-show="task.groupName" class="ml-3">
          <v-icon small color="grey">mdi-account-group</v-icon>
          {{ task.groupName }}
        </span>
        <v-icon small color="grey" class="ml-3" @click="dialog = true"
          >mdi-playlist-edit</v-icon
        >
      </v-col>
    </v-row>

    <v-row :class="taskTextColor(task.level)" class="mt-1 px-2 pb-1" no-gutters>
      <v-col cols="12">
        <v-icon small :class="taskTextColor(task.level)">{{
          taskIcon(task.level)
        }}</v-icon>
        {{ $t(task.message) }}
        <v-tooltip top>
          <template #activator="{ on }">
            <span v-on="on" class="grey--text timeago">
              (créée
              <timeago
                locale="fr"
                :datetime="
                  _parseDate(
                    task.creationDate,
                    datePatternConfig.serverLocalDateTime
                  )
                "
              ></timeago
              >)
            </span>
          </template>
          {{ task.creationDate }}
        </v-tooltip>
      </v-col>
    </v-row>

    <smart-dialog v-model="dialog" title="Edition">
      <task-edit
        :task="task"
        @saved="dialog = false"
        @change="change"
      ></task-edit>
    </smart-dialog>
  </div>
</template>

<script>
import ColorMixin from "../mixins/ColorMixin";
import FormMixin from "../mixins/FormMixin";
import TaskEdit from "@/components/commons/TaskEdit";

export default {
  name: "TaskItem",
  components: { TaskEdit },
  mixins: [ColorMixin, FormMixin],
  props: {
    task: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      alreadyOpened: false,
      dialog: false,
    };
  },
  created() {},
  watch: {},
  computed: {},
  methods: {
    change() {
      this.$emit("change", {});
    },
  },
};
</script>

<style scoped>
.creation-date {
  font-size: 10px;
}

.task-bloc {
  border-radius: 5px;
}

.timeago {
  font-size: 10px;
}
</style>
