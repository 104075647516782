<template>
  <v-card width="100%">
    <v-card-title>
      <v-row dense>
        <v-col cols="12">
          <h3 class="headline mb-0">
            <v-row dense>
              <v-col cols="9">
                <v-icon>{{ renderIcon(transferprice.from.pointType) }}</v-icon>
                {{ transferprice.from.name }} -
                <v-icon>{{ renderIcon(transferprice.to.pointType) }}</v-icon>
                {{ transferprice.to.pointType }}
              </v-col>

              <v-col class="text-right" cols="3">
                {{ transferprice.pricing.prices.price }}
                {{ $t(transferprice.pricing.prices.currency.code + "-symbol") }}

                <span
                  class="grey--text"
                  style="text-decoration: line-through"
                  v-if="
                    transferprice.pricing.prices.price <
                    transferprice.pricing.prices.stroke
                  "
                  >{{ transferprice.pricing.prices.stroke }}
                  {{
                    $t(transferprice.pricing.prices.currency.code + "-symbol")
                  }}</span
                >
                <small v-if="transferSelected">
                  (
                  <price-diff
                    :cssdisplay="'inline'"
                    :show-baseprice="false"
                    :new-price="transferprice.pricing.prices.price"
                    :base-price="transferSelected.price"
                  ></price-diff
                  >)
                </small>
              </v-col>
            </v-row>
          </h3>
        </v-col>
        <v-col cols="12">
          <availability
            v-model="transferprice.pricing.availability"
            kind="Stock"
          ></availability>
          <v-chip
            small
            v-for="(v, index) in transferprice.vehicles"
            :key="index"
            >{{ $t("vehicleType." + v) }}</v-chip
          >
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row
        dense
        v-for="(segment, index) in transferprice.segments"
        :key="index"
        class="pb-2"
      >
        <v-col cols="9" class="font-weight-medium">
          {{ segment.from.name }} -> {{ segment.to.name }}
          <span xs12 class="caption">({{ segment.transfer.name }})</span>
        </v-col>
        <v-col class="text-right" cols="3">
          {{ segment.pricing.prices.price }}
          {{ $t(segment.pricing.prices.currency.code + "-symbol") }}
          <br />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <smart-btn
        primary
        v-if="transferSelected"
        @click.native="editdialog = !editdialog"
        :loading="loading"
        >{{ $t("edit") }}</smart-btn
      >
      <smart-btn primary v-else @click.native="add" :loading="loading">{{
        $t("add")
      }}</smart-btn>
    </v-card-actions>

    <v-dialog
      v-if="transferSelected"
      v-model="editdialog"
      :max-width="1200"
      persistent
    >
      <v-card>
        <v-toolbar dark color="primary" dense>
          <v-toolbar-title>Edition transfert</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click.native="editdialog = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-title>
          <v-row dense>
            <v-col cols="6" class="headline">Transfert actuel</v-col>
            <v-col cols="6" class="headline">Transfert selectionné</v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row dense>
            <v-col cols="6" class="pa-1">
              <v-row
                dense
                v-for="(segment, index) in transferSelected.segments"
                :key="index"
                class="pb-2"
              >
                <v-col cols="11" class="font-weight-medium">
                  {{ segment.fromCustomerName }} -> {{ segment.toCustomerName }}
                  <span class="caption">
                    <br />
                    {{ $t("vehicleType." + segment.vehicleType) }} ({{
                      segment.desc.name
                    }})
                  </span>
                </v-col>
                <v-col class="text-right" cols="1">
                  {{ segment.price }}
                  {{ $t(segment.currency + "-symbol") }}
                  <br />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6" class="pa-1">
              <v-row
                dense
                v-for="(segment, index) in transferprice.segments"
                :key="index"
                class="pb-2"
              >
                <v-col cols="11" class="font-weight-medium">
                  {{ segment.from.name }} -> {{ segment.to.name }}
                  <span class="caption">
                    <br />
                    {{ $t("vehicleType." + segment.transfer.vehicleType) }} ({{
                      segment.transfer.name
                    }})
                  </span>
                </v-col>
                <v-col class="text-right" cols="1">
                  {{ segment.pricing.prices.price }}
                  {{ $t(segment.pricing.prices.currency.code + "-symbol") }}
                  <br />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6" class="pa-1">
              <v-row dense>
                <v-col class="mt-2 font-weight-medium" cols="11">
                  <v-divider></v-divider>Total
                </v-col>
                <v-col class="mt-2 font-weight-medium text-right" cols="1">
                  <v-divider></v-divider>
                  {{ transferSelected.price }}
                  {{ $t(transferSelected.currency + "-symbol") }}
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6" class="pa-1">
              <v-row dense>
                <v-col class="mt-2 font-weight-medium" cols="3">
                  <v-divider></v-divider>Total
                </v-col>
                <v-col class="mt-2 font-weight-medium text-right" cols="9">
                  <v-divider></v-divider>
                  {{ transferprice.pricing.prices.price }}
                  {{
                    $t(transferprice.pricing.prices.currency.code + "-symbol")
                  }}
                  (
                  <price-diff
                    :cssdisplay="'inline'"
                    :show-baseprice="false"
                    :new-price="transferprice.pricing.prices.price"
                    :base-price="transferSelected.price"
                  ></price-diff
                  >)
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <smart-btn primary :loading="loading" @click.native="edit">{{
            $t("add")
          }}</smart-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import Availability from "@/components/commons/Availability";
import RenderIconMixin from "@/components//mixins/RenderIconMixin";
import { EventBus } from "@/components/commons/event-bus";
import PriceDiff from "@/components/commons/PriceDiff";
import { get, post } from "@/utils/api";

export default {
  name: "TransferPrice",
  components: { PriceDiff, Availability },
  props: {
    bookingid: {
      type: Number,
      required: true,
    },
    transferprice: {
      type: Object,
      required: true,
    },
    ctx: {
      type: Object,
      required: true,
    },
    transferSelected: {
      type: Object,
      required: false,
    },
  },
  mixins: [RenderIconMixin],
  data() {
    return {
      loading: false,
      editdialog: false,
    };
  },
  created() {},
  watch: {},
  computed: {},
  methods: {
    buildContext() {
      return {
        ctx: {
          ...this.ctx,
          start: this.ctx.start + " 00:00:00",
          stop: this.ctx.stop + " 00:00:00",
          // l'objet prestation sera fait server side car il n'y a pas d'objet.
        },
        guests: this.ctx.guests,
      };
    },
    add() {
      this.loading = true;

      const ctx = this.buildContext();

      post(
        `/api/prestation/add/transfer?bookingId=${this.bookingid}&hash=${this.transferprice.hash}`,
        ctx
      )
        .badRequest((err) => {
          EventBus.$emit("toaster-msg", JSON.parse(err.null).errors.join(","));
        })
        .json((data) => {
          EventBus.$emit("toaster-msg", this.$t("transferadded"));
          EventBus.$emit("reloadbooking", {});
          setTimeout(() => {
            EventBus.$emit("prestation-added", {});
          }, 500);
        })
        .then(() => {
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    edit() {
      this.loading = true;
      const ctx = this.buildContext();
      post(
        `/api/prestation/edit/transfer?bookingId=${this.bookingid}&prestationGroupId=${this.transferSelected.prestationGroupId}&hash=${this.transferprice.hash}`,
        ctx
      )
        .badRequest((err) => {
          EventBus.$emit("toaster-msg", JSON.parse(err.null).errors.join(","));
        })
        .json(() => {
          EventBus.$emit("transfer-edited", {});
          EventBus.$emit("toaster-msg", this.$t("transferadded"));
          this.editdialog = false;
          setTimeout(() => {
            EventBus.$emit("reloadbooking", {});
          }, 500);
        })
        .then(() => {
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped></style>
