<template>
  <v-row class="pa-5" v-if="booking">
    <v-col md="8" sm="12" class="pa-2">
      <v-row>
        <v-col class="pa-2" cols="12" v-html="$t('confirmpresta.intro')"></v-col>

        <v-col cols="12" v-if="loading" class="loading-layer">
          <v-progress-circular :size="50" color="primary" :indeterminate="loading"></v-progress-circular>
        </v-col>

        <v-col
          cols="12"
          v-if="!prestations || prestations.length == 0"
        >Aucune prestation à confirmer</v-col>
        <v-col cols="12" v-else>
          <v-card
            v-for="(prestation, index) in prestations"
            :key="index"
            class="mt-2"
            :id="'presta-block-'+prestation.id"
          >
            <v-card-text>
              <span v-if="prestation.kind == 'RoomPrestation'">
                <h3>
                  <v-icon small>hotel</v-icon>
                  {{prestation.hotelName}} - {{prestation.name}} / {{prestation.mealplanName}}
                </h3>
                <p>{{ _parseAndFormatLocalDateTime(prestation.start) }} - {{ _parseAndFormatLocalDateTime(prestation.stop) }}</p>
              </span>
              <span v-else-if="prestation.kind == 'TransferSegmentPrestation'">
                <h3>
                  <v-icon small>directions_bus</v-icon>
                  {{prestation.fromName}}
                  <v-icon small>arrow_right_alt</v-icon>
                  {{prestation.toName}}
                  ({{prestation.vehicleType}})
                </h3>
                <p>
                  {{ _parseAndFormatLocalDateTime(prestation.start, datePatternConfig.fr.fullWithTime) }}
                  ({{ _durationFormatted(prestation.start, prestation.stop, datePatternConfig.fr.time) }})
                </p>
              </span>
              <span v-else-if="prestation.kind == 'CarrentalPrestation'">
                <h3>
                  <v-icon small>directions_car</v-icon>
                  {{prestation.name}}
                </h3>
                <p>{{_parseAndFormatLocalDateTime(prestation.start) }} - {{_parseAndFormatLocalDateTime(prestation.stop) }}</p>
              </span>
              <span>
                <br />
                <div class="body-2 font-weight-medium">
                  <v-icon small>mdi-account-group</v-icon>
                  {{$t('guests')}}
                </div>
                <div v-for="(guest, guestIndex) in prestation.guests" :key="guestIndex">
                  {{guest.firstName}} {{guest.lastName}} • {{guest.ageClassType}}
                  <span
                    v-if="guest.ageClassType==='Child'"
                  >• {{_age(guest.birthDate, _parseDateTime(prestation.start))}}ans ({{guest.birthDate}})</span>
                </div>
              </span>
              <span>
                <br />
                <div class="body-2 font-weight-medium">{{$t('extras')}}</div>
                <ul
                  v-if="prestation.data && prestation.data.extras && prestation.data.extras.length > 0"
                >
                  <li v-for="extra in prestation.data.extras.filter(e => e.included)" :key="extra.id">{{extra.name}}</li>
                </ul>
                <div v-else>
                  <i>{{$t('no-extras')}}</i>
                </div>
              </span>
              <span>
                <br />
                <div class="body-2 font-weight-medium">{{$t('options')}}</div>
                <ul
                  v-if="prestation.data && prestation.data.offers && prestation.data.offers.length > 0"
                >
                  <li v-for="offer in prestation.data.offers" :key="offer.id">{{offer.name}}</li>
                </ul>
                <ul
                  v-else-if="prestation.data && prestation.data.options && prestation.data.options.length > 0"
                >
                  <li v-for="option in prestation.data.options" :key="option.id">{{option.name}}</li>
                </ul>
                <div v-else>
                  <i>{{$t('no-offers')}}</i>
                </div>
              </span>
            </v-card-text>

            <v-card-actions>
              <v-row>
                <v-col cols="12">
                  <v-radio-group v-model="prestation.accepted" row>
                    <v-radio :label="$t('confirm')" color="primary" :value="true"></v-radio>
                    <v-radio :label="$t('refuse')" color="red" :value="false" :disabled="isDisabled(prestation)"></v-radio>
                  </v-radio-group>
                  <v-alert
                    class="pa-0 my-1"
                    xs12
                    :value="prestation.hasError"
                    type="warning"
                    outlined
                  >{{$t('confirmpresta.error.missinganwser')}}</v-alert>
                  <v-alert
                    xs12
                    class="pa-0 my-1"
                    :value="!prestation.hasError && prestation.hasMissingMsg"
                    type="warning"
                    outlined
                  >{{$t('confirmpresta.error.missingmsg')}}</v-alert>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    v-show="prestation.accepted != null && !prestation.accepted"
                    outlined
                    v-model="prestation.refuseMsg"
                    :label="$t('confirmpresta.label.reason')"
                    value
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>

          <v-card class="mt-2">
            <v-card-text>
              <v-text-field
                id="inputname"
                ref="inputname"
                v-model="supplierName"
                :rules="supplierNameRules"
                :label="$t('confirmpresta.label.name')"
                :error-messages="inputErrorMsg"
                required
              ></v-text-field>
            </v-card-text>
            <v-card-actions>
              <smart-btn :loading="loading" @click.native="confirm" primary large>{{$t('confirm')}}</smart-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>

      <smart-dialog title="Infos" v-model="infodialog">
        <div>Votre réponse a bien été prise en compte.</div>
      </smart-dialog>
    </v-col>
    <v-col md="4" sm="12" class="pa-2">
      <h4 pl-3>{{$t('recap')}}</h4>
      <!-- todo: pass a copy of booking with filtered prestations -->
      <booking-drawer-content :showprices="false" v-if="booking" :booking="booking"></booking-drawer-content>

      <customer-flights-infos :additional-infos="booking.additionalInfos"></customer-flights-infos>
    </v-col>
  </v-row>
</template>

<script>
import { EventBus } from "@/components/commons/event-bus";
import OpenBookingDrawer from "@/components/commons/OpenBookingDrawer";
import BookingDrawerContent from "../../components/commons/BookingDrawerContent";
import GuestsMixin from "@/components/mixins/GuestsMixin";
import { get, post } from "@/utils/api";
import CustomerFlightsInfos from "@/components/booking/CustomerFlightsInfos";

export default {
  name: "ConfirmPrestas",
  props: {
    bookingid: {
      type: String,
      required: true,
    },
    supplierid: {
      type: String,
      required: true,
    },
  },
  components: {
    BookingDrawerContent,
    OpenBookingDrawer,
    CustomerFlightsInfos,
  },
  mixins: [GuestsMixin],
  data() {
    return {
      booking: null,
      supplier: null,

      prestations: [],

      missingNameMsg: this.$t("error.missing.name"),
      supplierName: null,
      supplierNameRules: [(v) => !!v || this.missingNameMsg],

      loading: false,

      inputErrorMsg: null,

      infodialog: false,

      oldPrestationsGroup: [],
      oldPrestations: [],
    };
  },
  created() {
    this.init();
  },
  watch: {},
  computed: {},
  methods: {
    init() {
      this.loadBooking();
      this.loadPresta();
      this.loadSupplier();
    },
    isDisabled(prestation){
      return prestation.availability == 'freesell' || prestation.availability == 'onstock'
    },
    confirm() {
      let goToId;

      this.prestations = this.prestations.map((p) => {
        p.hasError = p.accepted == null;
        p.hasMissingMsg =
          !p.accepted && (p.refuseMsg == null || p.refuseMsg == "");

        if ((p.hasError || p.hasMissingMsg) && goToId == null) {
          goToId = "#presta-block-" + p.id;
        }

        return p;
      });

      this.inputErrorMsg = this.$refs.inputname.valid
        ? null
        : this.missingNameMsg;
      goToId =
        !this.$refs.inputname.valid && goToId == null ? "#inputname" : goToId;

      const errorLengths = this.prestations.filter(
        (p) => p.hasError || p.hasMissingMsg
      ).length;
      if (errorLengths == 0 && this.$refs.inputname.valid) {
        this.validConfirm();
      } else {
        this.$vuetify.goTo(goToId, {
          duration: 300,
          offset: -100,
          easing: "linear",
        });
        this.loading = false;
      }
    },
    validConfirm() {
      this.loading = true;
      const url = `/api/prestation/suppliersconfirm/${this.bookingid}/${this.supplierid}`;

      return post(url, {
        name: this.supplierName,
        prestations: this.prestations,
      })
        .badRequest((err) => {
          EventBus.$emit(
            "toaster-msg",
            JSON.parse(err.null).errors.map((e) => JSON.parse(e))
          );
        })
        .res((res) => {
          this.loading = false;
          this.infodialog = true;
          this.init();
        });
    },
    loadBooking() {
      const url = `/api/booking/${this.bookingid}/supplier/${this.supplierid}`;

      return get(url)
        .badRequest((err) => {
          EventBus.$emit(
            "toaster-msg",
            JSON.parse(err.null).errors.map((e) => JSON.parse(e))
          );
        })
        .json((data) => {
          this.booking = data.data;
        });
    },
    loadPresta() {
      const url = `/api/prestation/forsuppliers/${this.bookingid}/${this.supplierid}`;

      return get(url)
        .badRequest((err) => {
          EventBus.$emit(
            "toaster-msg",
            JSON.parse(err.null).errors.map((e) => JSON.parse(e))
          );
        })
        .json((prestas) => {
          this.prestations = prestas;
          //.filter((presta) => presta.oldPrestationId == null || (presta.oldPrestationId != null && prestas.find(p => presta.oldPrestationId == p.oldPrestationGroupId) != null))
          //.filter((presta) => presta.oldPrestationGroupId == null || (presta.oldPrestationGroupId != null && prestas.find(p => presta.prestationGroupId == p.oldPrestationGroupId) != null))
          //
          //recupérer les prestas => le old est null OU presta est pas null et qu'il existe ailleur
        });
    },
    loadSupplier() {
      const url = `/api/supplier/${this.supplierid}`;

      return get(url)
        .badRequest((err) => {
          EventBus.$emit(
            "toaster-msg",
            JSON.parse(err.null).errors.map((e) => JSON.parse(e))
          );
        })
        .json((data) => {
          this.supplier = data;
          this.$locale = this.supplier.lang.substring(0, 2);
        });
    },
  },
};
</script>

<style scoped>
p {
  margin-bottom: 0;
}

.v-input--selection-controls {
  margin: 0 !important;
}
</style>
