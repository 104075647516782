<script>
import {get} from "@/utils/api";
import {EventBus} from "@/components/commons/event-bus";

export default {
  name: "PrestationsSuppliersList",
  props: {
    supplierId: {
      type: [Number, String], required: false
    },
    supplierEmails: {
      type: Array, required: true
    },
    suppliers: {
      type: Array, required: true,
    },
    context: {
      type: String, required: true
    }
  },
  data() {
    return {
      loading: false,
      prestations: [],

      headers: [
        {text: "Ref.", value: "bId"},
        {text: "Prestation", value: "prestationName"},
        {text: "Arrivée / Départ", value: "dates"}
      ],
      rowsPerPageItems: [{text: "Tous", value: -1}],

      selectedPrestations: [],
      selectedNewSupplier: null,

      contextEnum: {
        Edit: 'Edit',
        RemoveEmail: 'RemoveEmail',
        Delete: 'Delete'
      },
    }
  },
  created() {
    this.init()
  },
  computed: {
    prestationsEmpty() {
      return this.prestations.length == 0
    },
    isDeleteCtx() {
      return this.context == this.contextEnum.Delete
    },
    isRemoveEmailCtx() {
      return this.context == this.contextEnum.RemoveEmail && this.supplierEmails.length == 0
    },
    isEditCtx() {
      return this.context == this.contextEnum.Edit
    },
    canSave() {
      return this.isDeleteCtx && this.prestationsEmpty || this.isRemoveEmailCtx && this.prestationsEmpty || this.isEditCtx
    }
  },
  watch: {
    canSave() {
      this.emitCanSave()
    }
  },
  methods: {
    async init() {
      this.initSelected();
      await this.getPrestations()
    },
    initSelected() {
      this.selectedPrestations = [];
      this.selectedNewSupplier = null;
    },
    getPrestations() {
      this.loading = true;
      // load data
      const url = `/api/supplier/${this.supplierId}/prestations-list`
      return get(url).json(data => {
        this.prestations = data
      }).then(() => {
        this.loading = false;
      })
    },
    attributeSupplier() {
      const selectedPrestations = this.selectedPrestations.map(p => {
        return `prestationsIds=${p.pId}`
      }).join('&')
      const selectedNewSupplier = this.selectedNewSupplier
      const url = `/api/prestations/supplier/${selectedNewSupplier.id}/attribute?${selectedPrestations}`

      get(url)
          .badRequest(err => {
            EventBus.$emit('toast-msg', err)
          })
          .res(data => {
            EventBus.$emit('toast-msg', "Mise à jour réussi")
            this.init()
          })
    },
    emitCanSave() {
      this.$emit('canSave', this.canSave)
    }
  }
}
</script>

<template>
  <div>
    <v-divider/>
    <div class="mt-4 mb-4" v-if="!loading && prestations.length == 0">
      <h3 class="ml-2 mb-2">Aucunes prestations en cours attachées</h3>
    </div>
    <div v-else>
      <v-row class="mt-4">
        <v-col cols="12">
          <h3 class="ml-2 mb-2">Liste des prestations en cours attachées</h3>
          <v-data-table
              dense
              show-select
              item-key="pId"
              :single-select="false"
              v-model="selectedPrestations"
              :items="prestations"
              :headers="headers"
              :loading="loading"
              :footer-props="{'items-per-page-options': rowsPerPageItems}"
              hide-default-footer
              class="tbl  ">

            <template #item.dates="{item}">
              Du {{
                _parseAndFormatDate(
                    item.startDate,
                    datePatternConfig.en.localDate,
                    datePatternConfig.fr.localDate
                )
              }}
              au
              {{
                _parseAndFormatDate(
                    item.stopDate,
                    datePatternConfig.en.localDate,
                    datePatternConfig.fr.localDate
                )
              }}
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-divider class="mb-4"/>

      <v-row align="center" v-if="selectedPrestations.length > 0" dense class="pa-2">
        <v-col cols="6">
          <v-select dense outlined hide-details persistent-placeholder placeholder="Sélectionner un prestataire"
                    :label="`${selectedPrestations.length} prestations a attribuer  à` " v-model="selectedNewSupplier"
                    :items="suppliers"
                    item-text="name" return-object>
          </v-select>
        </v-col>
        <v-col>

          <v-btn :disabled="selectedNewSupplier  == null" depressed outlined  color="blue-grey"
                 @click="attributeSupplier">Valider
          </v-btn>
        </v-col>
      </v-row>
      <v-divider/>
    </div>
  </div>
</template>

<style scoped>

</style>