<template>

	<v-row  >
		<v-form ref="form" v-model="valid" lazy-validation v-on:submit.prevent="save" >
			<v-col cols="12" v-for="(prestation, i) in prestations" :key="i" class="pa-2">
				<v-row   class="align-center">
					<v-col cols="6" class="text-right">
						<v-subheader>{{prestation.name}}</v-subheader>
					</v-col>
					<v-col cols="3" class="px-1">
						<v-text-field
							v-model="prestation.startTime"
							@change="updateDate(prestation, true)"
							:rules="rules.time()"
							label="Départ"
							mask="time"
							clearable
							return-masked-value
						></v-text-field>
					</v-col>
					<v-col cols="3" class="px-1">
						<v-text-field
							v-model="prestation.stopTime"
							@change="updateDate(prestation, false)"
							:rules="rules.time()"
							label="Arrivée"
							mask="time"
							clearable
							return-masked-value
						></v-text-field>
					</v-col>
				</v-row>
			</v-col>
			<v-col cols="12">
				<v-card-actions class="actions-btn">
					<v-spacer></v-spacer>
					<smart-btn :disabled="!valid" type="submit">Enregistrer</smart-btn>
				</v-card-actions>
			</v-col>
		</v-form>
	</v-row>

</template>

<script>
	import {EventBus} from "@/components/commons/event-bus";
	import {get, post} from "@/utils/api"
	import FormMixin from "../mixins/FormMixin";

	export default {
		name: "ModifyTimes",
		props: {
			prestationGroupId: {
				type: Number, required: true
			}
		},
		mixins: [FormMixin],
		data() {
			return {

				valid: true,
				prestations: []
			}
		},
		created() {
			//load prestations name && prestation time from server
			const url = `/api/prestation-group/${this.prestationGroupId}/times`
			get(url)
				.json(data => {
					this.prestations = data
				})
				.then(() => {

				})
		},
		watch: {},
		computed: {},
		methods: {
			updateDate(prestation, isStart) {
				const dateName = isStart ? "startDate" : "stopDate"
				const timeName = isStart ? "startTime" : "stopTime"
				const localDate = prestation[dateName].substring(0, prestation[dateName].lastIndexOf('T') + 1)

				prestation[dateName] = localDate + prestation[timeName] + ':00'
			},
			save() {
				const valid = this.$refs.form.validate()

				if(valid) {
					const saves = this.prestations.map( p => {
						const url = `/api/prestation/${p.id}/savetime`
						return post(url, {startDate:p.startDate, stopDate:p.stopDate})
					})

					Promise.all(saves).then(() => {
						EventBus.$emit('reloadbooking', {})
						this.$emit('done')
					})

				}
			}
		}
	}
</script>

<style scoped>

</style>
