<template>
  <v-container class="pb-0">
    <v-form
      v-model="valid"
      ref="migrationForm"
      @submit.prevent="save"
      autocomplete="off"
    >
      <v-row>
        <v-col>
          <v-subheader light class="pa-0 mb-0 mx-0 form-subheader"
            >Agents à migrer</v-subheader
          >
          <v-data-table
            :headers="headers"
            :items="selectedAgents"
            hide-default-footer
            dense
            no-data-text="Aucun agent à migrer"
          >
            <template #item.active="{ item }">
              <v-chip
                class="mx-1 my-0"
                :color="item.active ? 'green lighten-5' : 'red lighten-5'"
                :style="{
                  border:
                    'solid 1px ' + item.active ? 'green' : 'red' + '!important',
                }"
                small
                label
              >
                {{ item.active ? "Actif" : "Inactif" }}
              </v-chip>
            </template>
          </v-data-table>

          <v-row class="mt-5">
            <v-col cols="6">
              <v-subheader light class="pa-0 mb-4 mx-0 form-subheader"
                >Vers l'agence</v-subheader
              >
              <v-autocomplete
                v-model="selectedAgency"
                :items="agencies"
                hide-no-data
                item-text="name"
                item-value="id"
                return-object
                dense
                hide-details
                :label="$t('agency')"
                :search-input.sync="searchTerm"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-row class="justify-center">
                <v-col class="ml-4 pa-0">{{
                  selectedAgency ? selectedAgency.name : null
                }}</v-col>
              </v-row>
              <v-row class="justify-center">
                <v-col class="ml-4 pa-0">{{
                  selectedAgency ? selectedAgency.address.street : null
                }}</v-col></v-row
              >
              <v-row class="justify-center">
                <v-col class="ml-4 pa-0"
                  >{{ selectedAgency ? selectedAgency.address.city : null }}
                  {{
                    selectedAgency ? selectedAgency.address.country : null
                  }}</v-col
                ></v-row
              >
            </v-col>
          </v-row>
          <v-row class="mt-8 mb-0 pb-0">
            <v-col class="text-center caption">
              Attention! Une fois migrés, les agents concernés n'auront plus
              accès à leurs anciens dossiers et devis.
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row class="justify-end grey lighten-4">
        <v-col cols="1" class="mr-10">
          <smart-btn type="submit" primary>Migrer</smart-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>
<script>
import { post, get } from "@/utils/api";
import { EventBus } from "@/components/commons/event-bus";
import _ from "lodash";

export default {
  name: "AgentMigrationTool",
  props: {
    selectedAgents: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      valid: false,
      searchTerm: null,
      agencies: [],
      headers: [
        { text: "Nom", value: "lastname", sortable: true },
        { text: "Prénom", value: "firstname", sortable: true },
        { text: "Téléphone", value: "phone", sortable: true },
        { text: "Email", value: "email", sortable: true },
        { text: "Agence", value: "agencyName", sortable: true },
        { text: "Statut", value: "active", sortable: true },
      ],
      selectedAgency: null,
    };
  },
  watch: {
    searchTerm: _.debounce(function (newVal) {
      if (newVal && typeof newVal !== "object") {
        this.findAgency(newVal);
      }
    }, 300),
  },
  created() {},
  methods: {
    findAgency(term) {
      const url = `/api/agency/search?search=${term}`;

      get(url)
        .badRequest((br) => {
          console.log("Error: ", br);
        })
        .json((data) => {
          this.agencies = data.map((agency) => {
            return {
              ...agency,
              ...{
                name:
                  agency.name +
                  " " +
                  agency.address.street +
                  " " +
                  agency.address.postalcode +
                  " " +
                  agency.address.city,
              },
            };
          });
        });
    },
    save() {
      const url = `/api/agency/${this.selectedAgency.id}/migrate`;
      let ids = this.selectedAgents.map((agent) => agent.id);

      post(url, ids)
        .badRequest((br) => {
          console.log("Error: ", br);
        })
        .json((data) => {
          this.$emit("success", {});
          EventBus.$emit("toaster-msg", "Migration effectuée avec succès");
          EventBus.$emit('agent.migration.done')
        });
    },
  },
};
</script>
<style lang="less" scoped>
table {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
  border: 0;
}
th {
  vertical-align: top;
  font-weight: normal;
}
td,
th {
  padding: 10px 20px;
}
.bad {
  background-color: #ffebee;
}
.good {
  background-color: #e8f5e9;
}
</style>
