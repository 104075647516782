<template>
	<v-tooltip :open-delay="50" bottom :disabled="tooltipText==''">
		<template #activator="{on}">
			<div v-on="on" :style="'width:'+width+'px;max-width:'+width+'px'">
				<span class="content-container">
					<div class="content" >{{ value.value | decimal({maximumFractionDigits: 0})}}<span v-if="percent">%</span> </div>
				</span>
				<span v-if="withLastYear" class="content-container">
					<div class="content" > {{ value.previousValue | decimal({maximumFractionDigits: 0})}}<span v-if="percent">%</span></div>
				</span>
				<span v-if="withLastYear && doShowDiff" class="content-container">
					<div class="content">{{ value.diff | decimal({maximumFractionDigits: 0})}}</div>
				</span>
				<span v-if="withLastYear && doShowDiff" class="content-container">
					<div class="content" >{{ value.percentageDiff | decimal({maximumFractionDigits: 0})}}%</div>
				</span>
			</div>
		</template>
		<span >{{tooltipText}}</span>
	</v-tooltip>
</template>

<script>
	export default {
		name: "HotelStatsCell",
		mixins: [],
		props: {
			tooltipText: {
				type:String, default:''
			},
			value: {
				type: Object, required: true,
			},
			withLastYear:{
				type:Boolean, required:true
			},
			doShowDiff: {
				type:Boolean, required:false, default:true
			},
			width:{
				type:Number, required:true
			},
			percent:{
				type:Boolean, required:false, default:false
			}
		},
		data() {
			return {
				tooltipModel:false
			}
		},
		created() {
		},
		watch: {},
		computed: {},
		methods: {}
	}
</script>

<style scoped lang="less">
	.content-container {
		width: 55px;
		display: inline-block;
		height: 30px;

		&:not(:first-child) {
			border-left: 1px solid #d1d1d1;
		}
	}

	.content {
		height: 100%;
		top: 30%;
		width: 60px;
		position: relative;
	}
</style>
