<template>
    <layout :drawer="false" navFixed>
        <!-- :drawerShow="_isDesktop()" -->
        <template slot="nav">
            <v-btn @click="mergeAgents" color="primary" elevation="2" text>Fusionner les agents</v-btn>
        </template>
        <template slot="drawer">rien pour l'instant</template>

        <template slot="content">
            <v-container fluid class="relative mt-16">
                <v-data-table
                    :headers="listing.headers"
                    :items="listing.items"
                    item-key="id"
                    sort-by="login"
                    group-by="login"
                    class="elevation-1"
                    show-group-by
                    :options.sync="listing.pagination"
                    :footer-props="{
                        'items-per-page-options': listing.rowsPerPageItems,
                        'items-per-page-text': 'Lignes par page',
                    }"
                    hide-default-footer
                    show-select
                    :calculate-widths="true"
                    v-model="selectedAgents"
                >
                  <template #item.id="{item}">
                    <div style="height: 100%" class="d-flex align-center justify-center">
                      <p class="mb-0 caption" style="width: 50px">{{item.id}}</p>
                    </div>
                  </template>
                  <template #item.lastname="{item}">
                    <div style="height: 100%" class="d-flex align-center justify-center">
                      <p class="mb-0" style="width: 100px">{{item.lastname}}</p>
                    </div>
                  </template>
                  <template #item.firstname="{item}">
                    <div style="height: 100%" class="d-flex align-center justify-center">
                      <p class="mb-0" style="width: 100px">{{item.firstname}}</p>
                    </div>
                  </template>
                    <template #item.creationDate="{ value }">
                      <p :class="i == 1 ? 'caption grey--text text--darken-1' : ''" class="mb-0"
                         v-for="(item,i) in _formatDate(new Date(value), datePatternConfig.fr.localDateWithTime).split(' ')" :key="i"
                      >{{item}}</p>
                    </template>
                    <template #item.active="{ value }">
                        <v-chip
                            class="mx-1 my-0"
                            :color="value ? 'green lighten-5' : 'red lighten-5'"
                            :style="{
                                border:
                                    'solid 1px ' + value ? 'green' : 'red' + '!important',
                            }"
                            small
                            label
                        >{{ value ? "Actif" : "Inactif" }}</v-chip>
                    </template>
                  <template #item.agency="{ item }">
                    <div class="d-flex flex-column">
                      <p class="mb-0 font-weight-medium">{{item.agencyName}}</p>
                      <p class="mb-0 caption grey--text text--darken-1">{{item.agencyAddress}}</p>
                    </div>
                  </template>
                    <template #item.other="{ item }">
                        <v-btn
                            text
                            x-small
                            :to="{ name: 'CrmAgencyDetails', params: { id: item.agencyId } }"
                            target="_blank"
                        >Voir l'agence</v-btn>
                        <v-btn text x-small @click="editAgent(item)">Editer l'agent</v-btn>
                    </template>
                </v-data-table>
            </v-container>

            <!-- -------------- ------------------------ -------------- -->

            <smart-dialog
                v-model="editAgentModal.dialog"
                title="Édition Agent"
                :fullscreen="_isMobile()"
            >
                <agent-edit-tool
                    v-if="editAgentModal.agent"
                    v-model="editAgentModal.agent"
                    @success="editAgentModal.dialog = false; $emit('success')"
                />
            </smart-dialog>

            <smart-dialog v-model="mergeAgentModal.dialog" title="Fusion Agents" width="950">
                <agent-merge-tool
                    v-if="mergeAgentModal.dialog"
                    :selectedAgents="mergeAgentModal.agents"
                    @success="mergeAgentModal.dialog = false; init()"
                ></agent-merge-tool>
            </smart-dialog>
        </template>
    </layout>
</template>
<script>
import Layout from "@/views/Layout";
import AgentEditTool from "@/components/commons/AgentEditTool.vue";
import AgentMergeTool from "@/components/commons/AgentMergeTool.vue";
import { get, post } from "@/utils/api";
export default {
    components: {
        Layout, AgentEditTool, AgentMergeTool
    },
    data() {
        return {
            listing: {
                items: [],
                headers: [
                    {
                        text: 'ID',
                        align: 'start',
                        value: 'id',
                        groupable: false,
                    },
                    { text: 'login', value: 'login', align: 'start' },
                    { text: 'Nom', value: 'lastname', align: 'start', groupable: false, },
                    { text: 'Prénom', value: 'firstname', align: 'start', groupable: false, },
                    { text: 'Créé le', value: 'creationDate', align: 'start', groupable: false, },
                    { text: 'Active', value: 'active', align: 'start', groupable: false, },
                    { text: 'Agence', value: 'agency', align: 'start', groupable: false, },
                    { text: '', value: 'other', align: 'end', groupable: false, },
                ],
                rowsPerPageItems: [{ text: "Tous", value: -1 }],
                loading: false,
            },

            selectedAgents: [],

            editAgentModal: {
                dialog: false,
                agent: null
            },
            mergeAgentModal: {
                dialog: false,
                agents: []
            }
        }
    },
    created() {
        this.init()
    },
    watch: {
        'editAgentModal.dialog': {
            handler(newVal) {
                if (!newVal) this.editAgentModal.agent = null
            }
        }
    },
    methods: {
        async init() {
            await this.getAgents()
            this.editAgentModal.agent = null;
            this.selectedAgents = []
            this.mergeAgentModal.agents = [];
        },
        getAgents() {
            const qs = window.location.search
            const url = `/api/agency/agents/deduplicates${qs}`;
            return get(url).json((data) => {
                this.listing.items = data;
            });
        },
        editAgent(agent) {
            this.editAgentModal.dialog = true
            this.editAgentModal.agent = agent
        },
        mergeAgents() {
            this.mergeAgentModal.agents.splice(0, this.mergeAgentModal.agents.length)
            this.mergeAgentModal.agents = [...this.selectedAgents]
            this.mergeAgentModal.dialog = true;
        }
    }
}
</script>