<template>
  <layout :drawer="false">
    <template slot="nav">
      <v-tabs>
        <v-tab :to="{ name: 'lead' }">Lead</v-tab>
      </v-tabs>
    </template>
    <template slot="nav-tools">
    </template>
    <template slot="drawer"></template>
    <template slot="content">
      <v-row class="pa-1">
        <!-- ------------------- left bloc -------------------  -->
        <v-col cols="4" class="pr-1" dense>
          <v-row no-gutters>
            <v-col>
              <collapse-panel :fillHeight="false" :collapsable="false" :loading="loading" v-if="lead">
                <div slot="title">
                  <v-row align="center">
                    <v-col class="caption font-weight-medium">
                      {{ lead.label || "" }} <span class="caption grey--text lighten-1"
                    >#{{ lead.id }}</span
                    >
                    </v-col>

                    <v-col class="d-flex justify-end text-right align-center items-center">
                      <ScoreLead :score="lead.score" class="mr-3"/>
                      <v-chip small outlined label
                              :color="lead.status == 'open' ? 'green' : lead.status == 'close' ? 'red' : 'orange'"
                      >
                        {{ $t('lead.status.' + lead.status) }}
                      </v-chip>
                      <v-chip v-if="lead.status == 'close' && lead.failure" small outlined label color="red" class="ml-1">{{ $t('lead.fail.' + lead.failure) }} </v-chip>
                      <v-chip v-if="lead.converted" small outlined label color="green" class="ml-1">Convertis</v-chip>
                    </v-col>
                  </v-row>
                </div>

                <div slot="header">
                  <v-row dense>
                    <v-col cols="8">
                      <div class="d-flex justify-space-between rounded  align-center"
                           style="">
                        <div class="d-flex">
                          <div class="d-flex text-left align-center">
                            <span class="caption grey--text lighten-2">Budget: </span>
                            <span v-if="lead.budget" class="ml-1 font-weight-medium"> {{
                                lead.budget | currency("EUR", lead.budget)
                              }}</span>
                            <span v-else-if="quotations.length>0">{{ maxPrice | currency("EUR", maxPrice) }}</span>
                            <span v-else> - </span>
                          </div>
                        </div>
                      </div>
                    </v-col>
                    <v-col cols="4" class="align-self-end text-right">
                      <div class="d-flex rounded justify-end align-center text-right" style="">
                        <v-icon class="ml-3">mdi-account</v-icon>
                        <span :class="lead.user ? 'body-2' : 'caption'">{{
                            userName()
                          }}</span>
                      </div>
                    </v-col>
                    <v-col class="body-2">
                      <div class="">
                          <span class="caption">Créé le {{
                              _parseAndFormatDate(lead.creationDate, datePatternConfig.serverLocalDateTime, "dd/MM/yyyy hh:mm:ss")
                            }}</span>
                        <span v-if="lead.modifyDate"></span>
                      </div>
                      <div v-if="lead.message != null && lead.message != ''" class="d-flex flex-column rounded py-3"
                           style="">
                        <span class="grey--text lighten-2 caption">Commentaire:</span>
<div class="caption" v-html="htmlMessage"></div>
<!--                        <div class="caption">{{ htmlMessage }}</div>-->

                      </div>

                    </v-col>
                    <v-col cols="12">

                    </v-col>
                    <v-col cols="12">
                      <div class="d-flex ">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-chip
                                v-bind="attrs"
                                v-on="on"
                                label
                                flat
                                small
                                outlined
                                class="grey lighten-2 green--text text--lighten-2 mr-1"
                            >
                              <v-icon small class="mr-2">mdi-download</v-icon>
                              {{ $t('lead.source.' + lead.source) }}
                            </v-chip>
                          </template>
                          Origine du lead
                        </v-tooltip>
                        <v-chip
                            label
                            flat
                            small
                            outlined
                            class="grey lighten-2 blue--text text--lighten-2  mr-1"
                            v-if="lead.destinations.length >0"
                        >
                          <v-icon small class="mr-2">mdi-tag</v-icon>
                          {{ lead.destinations.map(x => x.name).join(", ") }}
                        </v-chip>


                        <v-chip
                            label
                            flat
                            small
                            outlined
                            class="grey lighten-2 blue--text text--lighten-2 mr-1"
                        >
                          <v-icon small class="mr-2">mdi-phone</v-icon>
                          {{ lead.phonecalls }} appels
                        </v-chip>
                      </div>
                    </v-col>

                  </v-row>
                </div>

                <div slot="menu">
                  <v-list-item @click="dialogs.infos.show = true">
                    <v-list-item-title >
                      <v-icon small>edit</v-icon>
                      <span>Éditer le lead</span>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="dialogs.closer.show = true" v-if="lead.status == 'open'">
                    <v-list-item-title >
                      <v-icon small>mdi-database-check
                      </v-icon
                      >
                      Cloturer le lead
                    </v-list-item-title>
                  </v-list-item>


                  <v-list-item @click="setStatusLead('open')" v-else>
                    <v-list-item-title>
                    <v-icon small>mdi-database-check
                    </v-icon
                    >
                    Réouvrir le lead
                    </v-list-item-title>
                  </v-list-item>
                </div>
              </collapse-panel>
              <booking-customer-details style="border: 1px solid lightgray;" v-if="lead"
                                        :booking="lead"
                                        :kagnots="null"
                                        :noTags="true"
                                        :fillHeight="false"
                                        class="mt-2"
                                        urlOverride="/api/lead/customer"
                                        urlSnapchotCustomer="/api/lead"
              ></booking-customer-details>
              <collapse-panel :collapsable="false" :loading="loading" v-if="lead" :fill-height="false" class="mt-2">
                <div slot="title">
                  <h3>
                    Actions
                    <span class="caption">({{ actions.length }})</span>
                  </h3>
                </div>

                <div slot="menu">
                  <v-list-item :disabled="lead.status == 'close'" @click="dialogs.action.show = true">
                    <v-list-item-title class="">
                    <v-icon small>mdi-database-check
                    </v-icon>
                    <span >Ajouter une action</span>
                    </v-list-item-title>
                  </v-list-item>
                </div>

                <div slot="content">
                  <div v-if="actions.length > 0">
                  <div v-for="(action, i) in actions" :key="action.id" class="my-2 px-3">
                    <div class="">
                      <div class="d-flex justify-space-between" style="height:1.5em;">
                        <div class=" items-center"
                             :class="action.type !== 'empty' ? 'font-weight-medium' : ' grey--text'">
                          <v-icon class="mr-1" size="medium">{{ getIcon(action) }}</v-icon>
                          {{ $t("lead.action." + action.type) }}
                        </div>
                        <div class="d-flex caption" v-if="action.type !== 'empty'">
                          <p class=" grey--text lighten-1">
                            {{
                              _parseAndFormatDate(action.creationDate, datePatternConfig.serverLocalDateTime, "dd/MM/yyyy hh'h'mm")
                            }}</p>
                          <p v-if="action.user" class="ml-1">• {{ userName(action.user) }}</p>
                        </div>
                      </div>
                      <div class="caption mb-3 ">{{ action.message }}</div>
                      <v-divider v-if="i < actions.length - 1" class="mt-4"/>
                    </div>
                  </div>
                  </div>
                  <div v-else>
                    <span class="grey--text">
                      {{ $t("lead.action.empty") }}
                    </span>
                  </div>
                </div>
              </collapse-panel>
            </v-col>
          </v-row>
        </v-col>
        <!-- ------------------- right bloc -------------------  -->
        <v-col cols="8" class="pl-1">
          <collapse-panel :collapsable="false" :loading="loading" v-if="lead" class="" :fill-height="false">
            <div slot="title">
              <h3>Liste des devis</h3>
            </div>
            <div slot="menu">
              <v-list-item :disabled="lead.status == 'close'" @click="$refs.bookingAddDialog.toggle()">
                <v-list-item-title  >
                  <v-icon>mdi-folder-plus</v-icon>
                  <span>Créer un devis</span>

                </v-list-item-title>
              </v-list-item>

            </div>
            <template slot="header">
              <v-data-table
                  :headers="quotationsHeaders"
                  :items="quotations"
                  :items-per-page="1000"
                  hide-default-footer
                  no-data-text="Aucuns devis rattaché à ce lead pour le moment"
                  class="tbl">
                <template #item="{ item }">
                  <tr
                      @click.ctrl="goToBooking(item.bookingId, true)"
                      @click.meta="goToBooking(item.bookingId, true)"
                      @click.exact="goToBooking(item.bookingId)">
                    <!---->
                    <td class="text-left font-weight-regular">
                      <div class="d-flex flex-column">
                        <span class="caption grey--text text-lighten-2">#{{ item.bookingId }}</span>
                        <site-label :sitename="item.virtualSite"></site-label>
                      </div>
                    </td>
                    <td class="text-left font-weight-regular">
                      {{ item.name || '-' }}
                    </td>
                    <td class="text-left font-weight-regular">
                      <div class="d-flex flex-column">
                    <span>{{
                        _parseAndFormatDate(item.creationDate, datePatternConfig.serverLocalDateTime, "dd/MM/yyyy")
                      }}</span>
                        <span
                            class="caption grey--text text-lighten-2">{{
                            _parseAndFormatDate(item.creationDate, datePatternConfig.serverLocalDateTime, "hh'h'mm")
                          }}</span>
                      </div>
                    </td>
                    <td class="text-left font-weight-regular">
                      <div class="d-flex flex-column">
                        <span>{{ item.startDate }}</span>
                        <span class="caption grey--text text-lighten-2">{{
                            item.stopDate
                          }} •{{ duration(item) }}n</span>
                      </div>
                    </td>
                    <td class="text-left font-weight-regular">

                      {{ item.flight.length > 0 ? item.flight.join(", ") : "-" }}
                    </td>
                    <td class="text-left font-weight-regular">
                      <v-icon v-if="item.transfer">mdi-bus-side</v-icon>
                      <span v-else>-</span>
                    </td>
                    <td class="text-left font-weight-regular">
                    <span v-for="desti in item.destination" :key="desti.id">
                  {{ desti.name }}
                </span>
                    </td>
                    <td class="text-left font-weight-regular">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <p
                              v-bind="attrs"
                              v-on="on"
                              class="d-flex text-truncate flex-column"
                              :class="lead.budget > 0 && item.ca > lead.budget ? 'orange--text': ''">
                            {{ item.ca | currency("EUR") }}
                            <span class="body-2 grey--text">
                              {{ item.purchase | currency("EUR") }}
                              <span class="caption ml-1 grey--text text--lighten-1">{{
                                  item.markup | percent
                                }}</span>
                            </span>
                          </p>
                        </template>
                        <span>Budget du client dépassé</span>
                      </v-tooltip>

                    </td>

                  </tr>
                </template>
              </v-data-table>
            </template>
          </collapse-panel>
        </v-col>
      </v-row>

      <booking-add ref="bookingAddDialog" :afterCreationFn="rattachCustomer"/>
      <smart-dialog
          v-model="dialogs.infos.show"
          title="Éditer lead"
      >
        <LeadCreationDialog :lead-info="lead" @done="() => closeEditionDialog()"/>
        <template title="actions">
          <v-card-actions class="actions-btn mt-4">
            <v-spacer/>
            <smart-btn type="submit" form="creation-lead">
              <v-icon small class="mr-1">mdi-content-save</v-icon>
              Sauvegarder
            </smart-btn>
          </v-card-actions>
        </template>
      </smart-dialog>
      <smart-dialog
          v-model="dialogs.action.show"
          title="Ajouter une action"
      >
        <lead-action-creation :lead="lead" @done="() => closeActionDialog()" v-if="dialogs.action.show"/>
      </smart-dialog>
      <smart-dialog
          v-model="dialogs.closer.show"
          title="Cloturé le lead"
      >
        <v-form id="close-form" class="pa-4 mb-4">
          <v-select v-if="lead" v-model="lead.failure"
                    :items="failTypes"
                    item-value="id"
                    item-text="label"
                    label="Raison de la cloture"
                    dense
                    hide-details
                    placeholder=""/>
        </v-form>
        <template #actions>
          <v-card-actions class="actions-btn ">
            <v-spacer/>
            <smart-btn @click.native="setStatusLead('close')">
              Valider
            </smart-btn>
          </v-card-actions>
        </template>

      </smart-dialog>

    </template>
  </layout>
</template>
<script>
import Layout from "@/views/Layout.vue";
import {get, post} from "@/utils/api";
import SiteLabel from "@/components/commons/SiteLabel.vue";
import ScoreLead from "@/components/booking/ScoreLead.vue";
import LeadCreationDialog from "@/views/lead/LeadCreationDialog.vue";
import CollapsePanel from "@/components/commons/CollapsePanel.vue";
import BookingCustomerDetails from "@/components/booking/BookingCustomerDetails.vue";
import {EventBus} from "@/components/commons/event-bus";
import BookingAdd from "@/components/booking/BookingAdd.vue";
import LeadActionCreation from "@/views/lead/LeadActionCreation.vue";


export default {
  name: "Lead",
  components: {
    LeadActionCreation,
    BookingAdd,
    SiteLabel,
    BookingCustomerDetails,
    ScoreLead,
    CollapsePanel,
    Layout,
    LeadCreationDialog,
  },
  data() {
    return {
      lead: null,
      quotations: [],
      actions: [],
      failTypes: [],
      quotationsHeaders: [
        {
          text: "Site",
          value: "virtualSite",
          align: "left",
          sortable: true,
        },
        {
          text: "Nom du devis",
          value: "name",
          align: "left",
          sortable: true,
        },
        {
          text: "Résa",
          value: "creationDate",
          align: "left",
          sortable: true,
        },
        {
          text: "Séjour",
          value: "startDate",
          align: "left",
          sortable: true,
        },
        {
          text: "Vols",
          value: "flight",
          align: "left",
          sortable: true,
        },
        {
          text: "Trsf.",
          value: "transfer",
          align: "left",
          sortable: true,
        },
        {
          text: "Destinations",
          value: "destination",
          align: "left",
          sortable: true,
        },
        {
          text: "Montant",
          value: "ca",
          align: "left",
          sortable: true,
        },
      ],
      loading: true,
      dialogs: {
        closer: {
          show: false
        },
        infos: {
          show: false
        },
        action: {
          show: false
        },
      },
      rattachCustomer: (idBooking) => {
        if (this.lead.customer.id != null) {
          return get(`/api/booking/attachcustomer/${idBooking}/${this.lead.customer.id}`).json(() => {
            return Promise.resolve()
          })
        } else {
          return Promise.resolve()
        }
      },
    }
  },
  created() {
    this.init()
    EventBus.$on("reloadLead", (msg) => {
      this.init();
    });

  },
  computed: {
    maxPrice() {
      if (this.quotations.length > 0) {
        return this.quotations.map(q => q.ca).sort((a, b) => b - a)[0];
      } else return "-"
    },
    htmlMessage() {
      return `<p>${this.lead.message.replaceAll("\n","<br/>")}</p>`
    }
  },
  methods: {
    init() {
      const url = `/api/lead/${this.$route.params.id}`

      const loadingLead = this.loadLead()

      const loadingQuotes = get(url + "/quotations").json((rs) => {
        this.quotations = rs
      })

      const loadActions = get(url + "/actions").json((rs) => {
        if (rs.length > 0) {
          this.actions = rs
        } else {
          this.actions = []
        }


      })

      const loadFailTypes = get("/api/lead/failure/types").json((rs) => {
        this.failTypes = rs.map(f => {
          return {id: f, label: this.$t("lead.fail." + f)}
        })
      })

      Promise.all([
        loadingLead, loadingQuotes, loadActions, loadFailTypes
      ]).then(() => {
        this.loading = false
      });
    },
    loadLead() {
      const url = `/api/lead/${this.$route.params.id}`
      return get(url).json((rs) => {
        if (rs.customer == null) {
          rs.customer = {id: null}
        }
        this.lead = rs
      })
    },
    goToBooking(id, newTab = false) {
      if (newTab) {
        let route = this.$router.resolve({
          name: "Booking",
          params: {id: id},
        });
        window.open(route.href, "_blank");
        return false;
      } else {
        this.$router.push({name: "Booking", params: {id: id}});
      }
    },
    duration(item) {
      const start = this._parseDate(item.startDate, "dd/MM/yyyy")
      const stop = this._parseDate(item.stopDate, "dd/MM/yyyy")
      return this._duration(start, stop, 'days')
    },
    closeEditionDialog() {
      this.dialogs.infos.show = false
      this.loadLead()
    },
    closeActionDialog() {
      this.dialogs.action.show = false
    },
    setStatusLead(to) {
      let toStatus = ''
      let toastMsg = ''
      switch (to) {
        case 'open':
          toastMsg = 'réouvert'
          toStatus = "open"
          break;
        case 'close':
          toastMsg = 'cloturé'
          toStatus = "close"
          break;
        case 'pending':
          toastMsg = 'en attente client'
          toStatus = "pending"
          break;
      }
      let endPath = this.lead.failure && toStatus === 'close' ? "/" + this.lead.failure : ""

      get(`/api/lead/${this.lead.id}/${toStatus}${endPath}`).res(() => {
        this.dialogs.closer.show = false
        EventBus.$emit("toaster-msg", `Lead ${toastMsg}`)
        this.init()

      })
    },
    getIcon(action) {
      switch (action.type) {
        case 'update':
          return "mdi-update";
        case 'call':
          return "mdi-phone";
        case 'email':
          return "mdi-mail";
        case 'proposition':
          return "mdi-currency-eur";
      }
    },
    userName(user) {
      if (this.lead.user && user == null) {
        return `${this.lead.user.firstname[0]}. ${this.lead.user.lastname}`
      } else if (user != null) {
        return `${user.firstname[0]}. ${user.lastname}`
      } else {
        return "Aucun agent affecté"
      }
    },
  },
  destroyed() {
    EventBus.$off('reloadLead')
  }

}
</script>
<style>
</style>