<template>
  <layout :drawer="true" :drawerShow="_isDesktop()">
    <template slot="nav">
      <!--<v-tab :to="{ name: 'BookingList' }">Dossiers</v-tab>-->
    </template>
    <template slot="nav-tools">
      <smart-btn @click.native="agencyDialog.show = true" class="mr-1">
        <v-icon>mdi-folder-plus</v-icon>
        <span class="pl-1 d-none d-sm-flex">Nouvelle agence</span>
      </smart-btn>
    </template>
    <template slot="drawer">
      <v-row>
        <v-col>
          <v-form
              ref="searchform"
              v-if="searchform"
              v-on:submit.prevent="search(_isMobile())"
          >
            <v-row no-gutters class="pt-2 pr-1">
              <v-col cols="12" class="pr-1">
                <v-text-field
                    v-model="searchform.search"
                    prepend-inner-icon="mdi-magnify"
                    label="Recherche"
                    placeholder="Rechercher une agence"
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="pr-1">
                <v-text-field
                    v-model="searchform.searchByMailAndLogin"
                    prepend-inner-icon="mdi-magnify"
                    label="Recherche par login et email d'agent / agence"
                    placeholder="login et email d'agent / agence"
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="pr-1">
                <smart-multi-select
                    v-model="searchform.regions"
                    label="Régions"
                    :items="marketingAreas"
                ></smart-multi-select>
              </v-col>

              <v-col cols="12" class="pt-4 pr-1">
                <v-autocomplete
                    dense
                    label="Réseaux"
                    v-model="searchform.networks"
                    item-text="name"
                    item-value="id"
                    :items="networks"
                    hide-details
                    hide-no-data
                    multiple
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" class="pr-1">
                <v-select
                    v-model="searchform.status"
                    :items="agenciesStatus"
                    item-text="text"
                    item-value="value"
                    label="Status agence"
                    class
                ></v-select>
              </v-col>
              <v-col cols="12" class="pr-1">
                <smart-multi-select
                    v-model="searchform.potential"
                    :items="potentials"
                    label="Potentiels"
                    defaultSelectAll
                ></smart-multi-select>
              </v-col>
              <v-col cols="9">
                <v-subheader light class="pa-0 form-subheader solo-subheader">
                  {{
                    searchform.withFlight
                        ? "Agences uniquement avec vols"
                        : "Agences avec et sans vols"
                  }}
                </v-subheader>
              </v-col>
              <v-col cols="3" class="mt-0 mb-0 pb-0">
                <v-switch
                    dense
                    hide-details
                    label
                    v-model="searchform.withFlight"
                    class="mt-0"
                ></v-switch>
              </v-col>
            </v-row>
            <v-row v-if="errorMissingParam">
              <v-alert icon="warning" outlined type="error"
              >Veuillez saisir au moins un paramètre de recherche
              </v-alert
              >
            </v-row>
            <smart-btn
                :loading="listing.loading"
                type="submit"
                block
                primary
                @click="search"
            >Rechercher
            </smart-btn
            >
            <smart-btn block tertiary @click.native="clearSearchform()"
            >Réinitialiser la recherche
            </smart-btn
            >
          </v-form>
        </v-col>
      </v-row>
    </template>

    <template slot="content">
      <v-container fluid>
        <div class="d-flex">
          <p class="blue-grey--text text--darken-1" v-if="listing.items.length > 0">Resultat : {{listing.items.length}} {{listing.items.length == 1 ? 'agence' : 'agences'}}</p>
        </div>
        <v-data-table
            :header-props="{ sortByText: 'Trier par' }"
            :headers="listing.headers"
            :loading="listing.loading"
            :items="listing.items"
            :options.sync="listing.pagination"
            :footer-props="{
            'items-per-page-options': listing.rowsPerPageItems,
            'items-per-page-text': 'Lignes par page',
          }"
            class="elevation-1 tbl"
            no-data-text="Il n'y a pas d'agences à afficher"
            @click:row="openAgency"
        >
          <template #item.oldCa="{ item }">{{
              item.oldCa | currency("EUR")
            }}
          </template>
          <template v-if="$vuetify.breakpoint.name == 'xs'" #item.newEvent="{ item }" class="mb-10">
            <v-btn color="blue lighten-1" class="buttonNewEvent d-flex align-center pa-2 mb-3"
                   @click.stop="setEventEditor(item.agencyId)">
              <v-icon color="white">mdi-folder-plus</v-icon>
              <span class="pl-1">Nouvel évènement</span>
            </v-btn>
          </template>
          <template #item.potential="{item}">
            {{getTextPotential(item.potential)}}
          </template>
        </v-data-table>
        <smart-dialog
            v-if="openEventEditor"
            title="Créer un évènement"
            v-model="openEventEditor"
            :width="900"
            @close="openEventEditor = false"
            :fullscreen="_isMobile()"
        >
          <CrmNewEvent @closeEvent="openEventEditor = false" :selectorAgents="selectorAgents" :agencyId="agencyId"/>
        </smart-dialog>
      </v-container>
      <smart-dialog
          :width="1200"
          title="Nouvelle agence"
          v-model="agencyDialog.show"
          transition="dialog-bottom-transition"
      >
        <crm-agency-info/>
      </smart-dialog>
    </template>
  </layout>
</template>

<script>
import Layout from "@/views/Layout";
import {get, post} from "@/utils/api";
import {EventBus} from "@/components/commons/event-bus";
import SmartMultiSelect from "../../components/commons/SmartMultiSelect";
import CrmAgencyInfo from "../../components/commons/CrmAgencyInfo.vue";
import FormMixin from "@/components/mixins/FormMixin";
import CrmNewEvent from "@/components/commons/CrmNewEvent.vue";

export default {
  name: "Crm",
  mixins: [FormMixin],
  components: {
    CrmNewEvent,
    Layout,
    SmartMultiSelect,
    CrmAgencyInfo,
  },
  props: {},
  data() {
    return {
      agenciesStatus: [
        {text: "Validé", value: "validated"},
        {text: "En attente", value: "pending"},
        {text: "Désactivé", value: "innactive"},
      ],
      potentials: [
        {id: 1, name: "Ne pas démarcher"},
        {id: 2, name: "Faible potentiel"},
        {id: 3, name: "Fort potentiel"}
      ],
      marketingAreas: [],
      networks: [],
      isPostcodeLoading: false,
      searchPostcode: {
        val: null,
      },
      searchCity: {val: null},
      searchform: {
        search: null,
        searchByMailAndLogin: null,
        regions: [],
        networks: [],
        status: "validated",
        withFlight: false,
        potential: []
      },
      selectorAgents: [],

      openEventEditor: false,

      errorMissingParam: false,

      listing: {
        rowsPerPageItems: [{text: "Tous", value: -1}, 50, 200],
        pagination: {
          options: {
            page: 1,
            itemsPerPage: 200,
            sortBy: [],
            sortDesc: [false],
          },
        },
        loading: false,
        headers: [
          {
            text: "Agence",
            value: "agencyName",
          },
          {
            text: "Réseau",
            value: "networkName",
          },
          {
            text: "Adresse",
            value: "address",
          },
          {
            text: "Ville",
            value: "city",
          },
          {
            text: "CP",
            value: "postalcode",
          },
          {
            text: "Ca actuel",
            value: "currentCa",
          },
          {
            text: "Ca n-1",
            value: "oldCa",
          },
          {
            text: "Potentiel",
            value: "potential",

          },
          {
            text: "Dernière date de réservation",
            value: "lastBookingDate",
          },
          {
            text: "",
            value: "newEvent",
            sortable: false
          },

        ],
        items: [],
      },

      agencyDialog: {
        show: false,
      },
      agencyId: null,
    };
  },
  created() {
    this.init();
  },
  destroyed() {
    //EventBus.$off("agent.migration.done")
    //EventBus.$off("agent.fusion.done")
  },
  methods: {
    async init() {
      await this.getMarketingAreas();
      await this.getNetworks();

      this.clearSearchform();
      this.initSearchform();

      /*EventBus.$on("agent.migration.done", (msg) => {
        this.getAgents()
      });
      EventBus.$on("agent.fusion.done", (msg) => {
        this.getAgents()
      });*/

    },
    async getMarketingAreas() {
      const url = "/api/search/marketingarea";
      return get(url).json((data) => {
        this.marketingAreas = data;
      });
    },
    async getNetworks() {
      const url = "/api/search/networks";
      return get(url).json((data) => {
        this.networks = data;
      });
    },
    loadData() {
      //set qs
      window.history.pushState(
          "",
          "",
          "?search=" + JSON.stringify(this.searchform)
      );
    },
    initSearchform() {
      const qs = new URLSearchParams(window.location.search);
      const search = (() => {
        try {
          return JSON.parse(qs.get("search"));
        } catch (e) {
          return null;
        }
      })();

      if (search) {
        this.searchform = search;
        this.search();
      }
    },
    clearSearchform() {
      this.searchform = {
        search: null,
        searchByMailAndLogin: null,
        regions: [],
        networks: [],
        status: "validated",
        withFlight: false,
        potential: []
      };
    },
    search(isMobile) {
      if (!this.listing.loading) {

        if (isMobile) {
          EventBus.$emit("close.filters")
        }
        const url = "/api/agency/list";
        const isValid = Object.values(this.searchform).reduce((valid, item) => {
          return (
              valid ||
              !(item == null || (Array.isArray(item) && item.length == 0))
          );
        }, null);

        this.searchform.searchByMailAndLogin = this.searchform.searchByMailAndLogin == ""
            ? null : this.searchform.searchByMailAndLogin

        this.errorMissingParam = !isValid;
        if (isValid) {
          this.listing.items = [];
          this.listing.loading = true;
          post(url, this.searchform)
              .json((data) => {
                this.listing.items = data;
                this.loadData();
              })
              .then(() => {
                this.listing.loading = false;
              });
        }
      }
    },
    getTextPotential(potential){
      return potential ? this.potentials.find(p => p.id === potential).name : ""
    },
    setEventEditor(id) {
      this.openEventEditor = true
      this.agencyId = id
      this.getAgents(id)
    },
    openAgency(item) {
      this.$router.push({
        name: "CrmAgencyDetails",
        params: {id: item.agencyId},
      });
    },
    getAgents(id) {
      const url = `/api/agency/${id}/agents`;

      return get(url)
          .badRequest((br) => {
            console.log("Error: ", br);
          })
          .json((data) => {
            return data.map((agent) => {
              return {
                id: agent.id,
                lastname: agent.lastname ? agent.lastname : "-",
                firstname: agent.firstname ? agent.firstname : "-",
                email: agent.email ? agent.email : "-",
                login: agent.login ? agent.login : "-",
                password: null,
                phone: agent.phone ? agent.phone : "-",
                active: agent.active || agent.active == null,
                isFromSmartresas: agent.isFromSmartresas,
                agencyName: agent.agencyName ? agent.agencyName : null,
                allowIncentive: agent.allowIncentive
              };
            });
          })
          .then((r) => {
            this.agents = r;
            this.selectorAgents = r.filter(agent => agent.active).map((item) => {
              return {
                id: item.id,
                name: item.firstname + " " + item.lastname
              };
            });
          });
    }
  },
};
</script>

<style scoped>
>>> tr.v-data-table__mobile-table-row:nth-of-type(odd) {
  background-color: #ececec;
}

>>> tr.v-data-table__mobile-table-row > td {
  min-height: 38px !important;
  height: 38px !important;
}

>>> tr.v-data-table__mobile-table-row:nth-of-type(odd) > td {
  background-color: #ececec;
}

.buttonNewEvent {
  color: white;
}
</style>
