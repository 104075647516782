<template>
  <widget-container
    :url="url"
    :bus="bus"
    :dark="false"
    :alwaysrender="true"
    cardclass="ma-0 pa-0 elevation-1 "
    @ready="setData"
  >
    <template slot="content" v-if="data ">
      <v-data-table
        ref="table"
        :loading="loading"
        :items="data"
        :options.sync="pagination"
        :footer-props="{'items-per-page-options':rowsPerPageItems, 'rows-per-page-options':'Lignes par page'}"
        :headers="headers"
        item-key="name"
        hide-default-footer
        :hide-default-header="!open"
        no-data-text="Aucun résultats"
        :class="' pa-0 ma-0 ' + (open ? ' tbl ' : '')"
      >
        <template v-if="!open" #header></template>
        <template #header.actions="{item}">
          <v-icon small @click="open = !open">mdi-eye-off</v-icon>
          <v-icon small @click="copy" class="pl-2">mdi-clipboard-text-outline</v-icon>
        </template>

        <template #body="{items}">
          <tbody>
            <template v-if="open">
              <tr v-for="(item, itemIndex) in items" :key="itemIndex">
                <td class="text-center">{{ item.name }}</td>
                <td class="text-right">{{ item.bookingSize }}</td>
                <td class="text-right">{{ item.nightSize }}</td>
                <td
                  class="text-right"
                >{{ item.guestSize }} | {{item.nbAdults}}AD, {{item.nbChilds}}CH</td>
                <td class="text-right">{{ item.ca | currency('EUR') }}</td>
                <td class="text-right">{{ item.sale | currency('EUR') }}</td>
                <td class="text-right">
                  <span
                    v-if="item.markup*100>0"
                  >{{ item.markup*100 | decimal({maximumFractionDigits:2}) }}%</span>
                </td>
                <td
                  class="text-right"
                >{{ item.averageBooking | currency('EUR', {maximumFractionDigits:2}) }}</td>
                <td></td>
              </tr>
            </template>
            <tr :class="!open ? 'big-footer' : ''">
              <td class="body-2 font-weight-medium text-center">{{open ? 'Total' : 'Synthèse'}}</td>
              <td class="body-2 font-weight-medium text-right">
                {{ total.bookingSize }}
                <span
                  class="grey--text lighten-1 caption"
                  v-show="!open"
                >{{$t('bookingSize-small')}}</span>
              </td>
              <td class="body-2 font-weight-medium text-right">
                {{ total.nightSize }}
                <span class="grey--text lighten-1 caption" v-show="!open">{{$t('nightSize-small')}}</span>
              </td>
              <td class="body-2 font-weight-medium text-right">
                {{ total.guestSize }} | {{total.nbAdults}}AD,
                {{total.nbChilds}}CH
              </td>
              <td class="body-2 font-weight-medium text-right">
                {{ total.ca | currency('EUR') }}
                <span class="grey--text lighten-1 caption" v-show="!open">{{$t('ca-small')}}</span>
              </td>
              <td class="body-2 font-weight-medium text-right">
                {{ total.sale | currency('EUR') }}
                <span class="grey--text lighten-1 caption" v-show="!open">{{$t('sale-small')}}</span>
              </td>
              <td class="body-2 font-weight-medium text-right">
                {{ total.markup*100 | decimal({maximumFractionDigits:2})
                }}%
                <span class="grey--text lighten-1 caption" v-show="!open">{{$t('markup-small')}}</span>
              </td>
              <td class="body-2 font-weight-medium text-right">
                {{ total.averageBooking | currency('EUR',
                {maximumFractionDigits:2}) }}
                <span
                  class="grey--text lighten-1 caption"
                  v-show="!open"
                >{{$t('averageBooking-small')}}</span>
              </td>
              <td v-if="!open" class="body-2 font-weight-medium text-right" key="close">
                <v-icon small @click="open = !open">mdi-eye</v-icon>
                <v-icon small @click="copy" class="pl-2">mdi-clipboard-outline</v-icon>
              </td>
              <td v-else></td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
    </template>
  </widget-container>
</template>

<script>
import WidgetContainer from "@/components/commons/widgets/WidgetContainer";
import clipboard from "../../../utils/clipboard";

export default {
  name: "WidgetBookingResume",
  components: { WidgetContainer },
  mixins: [],
  props: {
    bus: {
      type: Object,
      required: true,
    },
    url: {
      type: String,
      required: false,
      default: "/api/widget/bookingresume",
    },
    renderRows: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      search: null,
      data: null,
      total: null,

      open: false,

      rowsPerPageItems: [{ text: "Tous", value: -1 }],
      pagination: {
        options: {
          sortBy: "ordering",
        },
      },

      headers: [
        { text: this.$t("agency"), value: "name", align: "center", icon: "" },
        {
          text: this.$t("bookingSize"),
          value: "bookingSize",
          align: "right",
          icon: "",
        },
        {
          text: this.$t("nightSize"),
          value: "nightSize",
          align: "right",
          icon: "",
        },
        { text: this.$t("pax"), value: "pax", align: "right", icon: "" },
        { text: this.$t("ca"), value: "ca", align: "right", icon: "" },
        { text: this.$t("sale"), value: "sale", align: "right", icon: "" },
        { text: this.$t("markup"), value: "markup", align: "right", icon: "" },
        {
          text: this.$t("averageBooking"),
          value: "averageBooking",
          align: "right",
          icon: "",
        },
        { text: "", value: "actions", sortable: false, width: "100px" },
      ],
    };
  },
  created() {
    this.$nextTick(() => {
      this.bus.$on("search", () => {
        this.loading = true;
        this.open = this.renderRows;
      });
    });
  },
  destroyed() {
    this.bus.$off("search");
  },
  watch: {},
  computed: {},
  methods: {
    setData(data) {
      this.data = data.items;
      this.total = data.total;
      this.loading = false;
    },
    changeSort(column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending;
      } else {
        this.pagination.sortBy = column;
        this.pagination.descending = false;
      }
    },
    copy() {
      const defaultSeparator = "\t";
      const decimalOpts = { maximumFractionDigits: 0, useGrouping: false };

      const headers = this.headers
        .map((h) => {
          return `${h.text}${defaultSeparator}`;
        })
        .join(" ");

      const rows = this.data.map((i) => {
        const rowObj = {
          name: i.name,
          bookingSize: i.bookingSize,
          nightSize: i.nightSize,
          guestSize: i.guestSize,
          ca: i.ca,
          sale: this.$options.filters.decimal(i.sale, decimalOpts),
          markup: this.$options.filters.decimal(i.markup * 100, decimalOpts),
          averageBooking: this.$options.filters.decimal(
            i.averageBooking,
            decimalOpts
          ),
        };

        return `${Object.values(rowObj).join(defaultSeparator)}`;
      });

      const allRows = [headers].concat(rows);

      clipboard.copyTextToClipboard(allRows.join("\n"));
    },
  },
};
</script>

<style scoped>
>>> .big-footer {
  height: 57px !important;
}

>>> .footer {
  vertical-align: middle;
}

>>> tr td {
  border-top: 1px solid #e4e4e4;
}
</style>
