<template>
  <div>
    <v-autocomplete
      v-model="selected"
      v-bind="options"
      :items="list"
      :placeholder="allSelected ? 'Tous' : null"
      :item-value="itemValueName"
      :filter="defaultFilter"
      @change="select"
      @focus="focus=true"
      @blur="focus=false"
    >
      <v-list-item dense slot="prepend-item" ripple @click="toggleAll">
        <v-list-item-action>
          <v-icon :color="selected > 0 ? 'indigo darken-4' : ''">{{ icon }}</v-icon>
        </v-list-item-action>
        <v-list-item-title>Tous</v-list-item-title>
      </v-list-item>
      <v-divider slot="prepend-item"></v-divider>

      <template slot="selection" slot-scope="{ item, index }">
        <v-chip class="max-width-chip" small  v-show="!focus">{{item.name}}</v-chip>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  name: "SmartSelect",
  mixins: [],
  props: {
    value: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: true,
      default: () => [],
    },
    itemValueName: {
      type: String,
      required: false,
      default: "id",
    },
    allIsSameAsEmpty: {
      //$emit event will replace returned array by an empty array if this props is true
      type: Boolean,
      required: false,
      default: false,
    },
    "chip-style": {
      type: Object,
      required: false,
      default: () => {
        return { width: "100px" };
      },
    },
    defaultSelectAll: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      focus: false,
      selected: [],
      loading: false,
      list: [],
      options: {
        "no-data-text": "Recherche...",
        "cache-items": true,
        flat: true,
        "hide-details": false,
        dense: true,
        "chips-small": true,
      },
    };
  },
  watch: {
    $attrs: {
      deep: true,
      immediate: true,
      handler(newVal) {
        this.options = { ...this.options, ...newVal };
      },
    },
    items: {
      deep: true,
      immediate: true,
      handler(newVal) {
        this.list = newVal;
        this.selected = this.defaultSelectAll
          ? this.list.map((l) => l[this.itemValueName]).slice()
          : [];
        //this.select()
      },
    },
    value: {
      immediate: true,
      handler(newVal) {
        if (Array.isArray(newVal)) {
          if (newVal.length == 0) {
            this.selected = [];
            this.select(false);
          } else this.selected = newVal[0];
        } else if (typeof newVal === "number") {
          this.selected = newVal;
        }
      },
    },
  },
  created() {},
  computed: {
    allSelected() {
      const selected = _.flatten([...[this.selected], ...[]]);
      return (
        selected.length == this.list.length ||
        (this.allIsSameAsEmpty && selected.length == 0)
      );
    },
    haveSelected() {
      const selected = _.flatten([...[this.selected], ...[]]);
      return selected.length > 0 && !this.allSelected;
    },

    icon() {
      if (this.allSelected) return "check_box";
      if (this.haveSelected) return "mdi-minus-box";
      else return "mdi-checkbox-blank-outline";
    },
  },
  methods: {
    defaultFilter: (item, queryText, itemText) => {
      const normalize = (s) =>
        s.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      const hasValue = (val) => (val != null ? normalize(val) : "");

      const text = hasValue(itemText);
      const query = hasValue(queryText);

      return (
        text.toString().toLowerCase().indexOf(query.toString().toLowerCase()) >
        -1
      );
    },
    toggleAll() {
      this.$nextTick(() => {
        if (this.allSelected) {
          this.selected = [];
        } else {
          this.selected = this.list.map((l) => l[this.itemValueName]).slice();
        }

        this.select(true);
      });
    },
    select(doEmit) {
      doEmit = doEmit !== "undefined" ? doEmit : true;

      let input = null;
      if (this.selected.length == this.list.length && this.allIsSameAsEmpty) {
        input = [];
      } else {
        input = _.flatten([...[this.selected], ...[]]);
      }
      this.focus = false;

      if (doEmit) this.$emit("input", input);
    },
  },
};
</script>

<style scoped>
.v-chip {
  position: absolute !important;
  top: 0 !important;
}

>>> .v-chip__content {
  line-height: 27px !important;
  padding-right: 5px !important;
  display: inline-block !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  position: relative !important;
}

>>> input::placeholder {
  color: #000000de !important;
  background: #e0e0e0;
  border-radius: 28px;
  font-size: 13px;
  height: 24px;
  line-height: 24px;
  padding-left: 10px;
}

>>> .v-select__selections {
  padding-bottom: 1px;
}

>>> .v-list-item__title {
  font-weight: normal !important;
  font-size: 14px !important;
}
</style>
