<template>
  <v-col class="">
    <h3 class="">Packages</h3>
    <v-row v-if="pkglist && pkglist.length > 0" justify="start">
      <v-card
        v-for="(pkg, key) in pkglist"
        :key="key"
        max-width="300"
        class="mx-3 my-3 clickableItem"
        :class="!pkg.active ? 'red-shadow' : ''"
        @click="openPackage(pkg)"
      >
        <v-img
          class="white--text align-end"
          height="200px"
          :src="'/api/file/download/' + pkg.imagePath"
        >
          <v-card-title class="package-title text-wrap"
            >{{ pkg.translations[0].value }}
          </v-card-title>
        </v-img>

        <!-- <v-card-subtitle class="pb-0">{{
          meals(pkg).join(", ")
        }}</v-card-subtitle> -->

        <v-card-text class="text--primary">
          <v-col cols="12" class="px-0">
            <v-row v-for="(item, idx) in pkg.items"  :key="idx">
              <v-col cols="12">
                <v-subheader
                  light
                  class="pa-0 mb-0 mx-0 form-subheader solo-subheader"
                >
                  Étape {{ idx + 1}}</v-subheader
                >
                <v-row>
                  <v-col cols="12" class="pb-0">
                    <span>{{ island(item.islandId) ? island(item.islandId).name : "" }} de {{ item.nbNightsMin }} à {{ item.nbNightsMax }} nuits</span>
                  </v-col>
                  <v-col cols="12" class="pt-0">
                   <span>{{ item.ratings.length > 0 ? item.ratings.length > 1 ? "de " + stepRatingsInterval(item.ratings).start + " à " + stepRatingsInterval(item.ratings).stop + "*," : item.ratings[0] + "*," : ""}} {{ item.hotels.length > 0 ? item.hotels.length + " hotel(s)" : "" }} </span> 
                  </v-col>
                </v-row>
                
              </v-col>
            </v-row>
          </v-col>
        </v-card-text>
      </v-card>
    </v-row>
  </v-col>
</template>
<script>
import _ from "lodash";

export default {
  name: "PackagesRelated",
  props: ["skeletonId", "skeleton", "pkglist", "islands", "nights"],
  data() {
    return {};
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.$emit("editing", {});
    },
    openPackage(pkg) {
      this.$router.push({
        name: "Package",
        params: { packageId: pkg.id },
      });
      this.$emit("selected", pkg);
    },
    findIslands(pkg) {
      let names = pkg.items.map((item) => {
        return this.islands.find((island) => island.id == item.islandId);
      });
      return names.map((name) => {
        return name ? name.name : "";
      });
    },
    hotels(pkg) {
      return pkg.items
        .map((item) => {
          return item.hotels.map((hotel) => hotel.name);
        })
        .flat();
    },
    meals(pkg) {
      return _.uniq(
        pkg.items
          .map((item) => {
            return item.meals.map((meal) => meal.name);
          })
          .flat()
      );
    },
    ratings(pkg) {
      return _.uniq(
        pkg.items
          .map((item) => {
            return item.ratings.map((rating) => {
              return rating.toString() + "*";
            });
          })
          .flat()
      );
    },
    stepRatingsInterval(ratings) {
      return {start: ratings[0], stop: ratings[ratings.length - 1]}
    },
    island(islandId) {
      return this.islands.find((i) => i.id == islandId);
    },
  },
};
</script>
<style>
.clickableItem:hover {
  cursor: pointer;
}
.package-title {
  filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));
}
.red-shadow {
  box-shadow: 0 3px 5px -1px rgba(209, 20, 20, 0.2),
    0 5px 8px 0 rgba(204, 18, 18, 0.349), 0 1px 14px 0 rgba(0, 0, 0, 0.12) !important;
}
</style>