import Packages from '../views/package/Packages';
import PackagesList from '../views/package/PackagesList';
import PackageEdit from '../views/package/PackageEdit';
import Noop from '@/components/Noop';

const PackageRoutes = [

	{
		path: '/packages',
		name: 'Packages',
		component: PackagesList,
		props: true,
		meta: {
			breadcrumb: [
				{ name: 'Packages', route: { name: 'PackagesList' } },
				{ name: 'PackagesList' },
			]
		}

	},
	{
		path: '/packages/:id',
		name: 'EditPackages',
		component: Packages,
		props: true,
		meta: {
			breadcrumb: [
				{ name: 'Packages', route: { name: 'PackagesList' } },
				{ name: (id) => `Groupe de packages n° ${id[0]}` }
			]
		},
		children: [
			{
				path: 'pack/:packageId',
				name: 'Package',
				component: PackageEdit,
				props: true,
				meta: {
					breadcrumb: [
						{ name: 'Packages', route: { name: 'PackagesList' }},
						{ name: (packageId) => `Groupe de packages n° ${packageId[0]}`}
					]
				}
			}
		]
	}
]

export default PackageRoutes;