<template>
  <div>
    <v-form id="creation-lead" class="pa-4" v-on:submit.prevent="save">
      <v-row>
        <v-col cols="6">
          <v-select
              v-model="searchformNewLead.virtualSite"
              :items="sites"
              item-value="name"
              item-text="name"
              label="Site"
              dense
              hide-details
              placeholder=""
          />
        </v-col>
        <v-col cols="6">
          <v-text-field dense
                        v-model="searchformNewLead.label"
                        label="Nom du lead"/>
        </v-col>

      </v-row>
      <v-row id="secondLine" class="align-center d-flex justify-start pr-7">
        <v-col cols="4">
          <v-select
              v-model="searchformNewLead.score"
              :items="scores"
              item-value="id"
              item-text="label"
              label="Score"
              dense
              hide-details
              placeholder=""
          >
            <template #item="{item}" class=" align-center">
              <ScoreLead :score="item.id"/>
              <span class="pl-4">{{ item.label }}</span>
            </template>

            <template #selection="{item}" class=" align-center">
              <ScoreLead :score="item.id"/>
              <span class="pl-4">{{ item.label }}</span>
            </template>
          </v-select>
        </v-col>
        <v-col>
          <v-text-field
              v-model="searchformNewLead.budget"
              label="Budget"
              type="number"
          />
        </v-col>
        <v-col cols="4" class=" ">
          <v-btn-toggle dense v-model="searchformNewLead.status">
            <v-btn value="open" class="green--text">
              Ouvert
            </v-btn>
            <v-btn value="pending" class="orange--text">
              Attente client
            </v-btn>
          </v-btn-toggle>
        </v-col>
      </v-row>
      <v-divider class="my-2"/>
      <v-row>
        <v-col cols="12">
          <p class="font-weight-medium">Remarque client</p>
          <v-textarea v-model="searchformNewLead.message"/>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-select
              v-model="searchformNewLead.source"
              :items="sources"
              :error="errorInputSource"
              item-value="id"
              item-text="label"
              label="Origine"
              dense
              hide-details
              placeholder=""
          />
        </v-col>
        <v-col cols="6">
          <v-select v-model="searchformNewLead.user"
                    :items="users"
                    item-value="id"
                    item-text="fullName"
                    label="Agent Hotelissima rattaché"
                    dense
                    hide-details
                    placeholder=""/>
        </v-col>

      </v-row>

    </v-form>
  </div>
</template>
<script>
import {defineComponent} from "vue";
import SmartSelect from "@/components/commons/SmartSelect.vue";
import {get, post} from "@/utils/api";
import ScoreLead from "@/components/booking/ScoreLead.vue";
import jwtdecode from "jwt-decode";
import {EventBus} from "@/components/commons/event-bus";

export default defineComponent({
  components: {ScoreLead, SmartSelect},
  props: {
    leadInfo: {type: Object, required: false, default: null},
    'create-action': {type: String, default: null, required: false}
  },
  data() {
    return {
      searchformNewLead: {
        creationDate: null,
        client_id: null,
        site: 1,
        virtualSite: "Hotelissima",
        budget: null,
        status: "open",
        message: null,
        source: null,
        score: 1,
        user: null,
        destinations: [],
        label: null,
        phonecalls: 0,
        converted:null,
        quotes:0

      },
      createdLead: null,
      loadingSites: true,
      sites: null,
      sources: null,
      users: null,
      scores: [
        {id: 0, label: "Froid"},
        {id: 1, label: "Tiède"},
        {id: 2, label: "Chaud"},
      ],
      errorInputSource: false
    }
  },
  created() {
    if (this.leadInfo) {
      this.searchformNewLead = {
        creationDate: this.leadInfo.creationDate,
        client_id: this.leadInfo.client_id,
        label: this.leadInfo.label ? this.leadInfo.label : "",
        site: this.leadInfo.site,
        virtualSite: this.leadInfo.virtualSite,
        budget: this.leadInfo.budget,
        status: this.leadInfo.status,
        message: this.leadInfo.message,
        source: this.leadInfo.source,
        score: this.leadInfo.score,
        user: this.leadInfo.user ? this.leadInfo.user.id : null,
        destinations: this.leadInfo.destinations,
        phonecalls: this.leadInfo.phonecalls,
        quotes: 0,
      }
    } else {
      this.searchformNewLead.user = jwtdecode(localStorage.getItem("auth"))
      this.searchformNewLead.label = ""
    }
    const loadSites = get("/api/search/sites").json((rs) => {
      this.sites = rs.map(s => {
        if (s.id != 1) {
          s.disabled = true
        }
        return s
      });
    });

    const loadSources = get("/api/search/lead/sources").json((rs) => {
      this.sources = rs.map(s => {
        s = {id: s, label: this.$t("lead.source." + s)}
        return s
      });
    });

    const loadUsers = get("/api/search/users").json(rs => {
      this.users = rs.map(u => u = {...u, fullName: `${u.firstname} ${u.lastname}`})
    })


    Promise.all([loadSites, loadSources, loadUsers])
  },
  methods: {
    async save() {
      let lead;
      if (this.searchformNewLead.source) {

        if(this.searchformNewLead.budget == ""){
          this.searchformNewLead.budget = null
        }

        if (this.leadInfo && this.leadInfo.id) {
          if(!this.searchformNewLead.user?.id){
            this.searchformNewLead.user = this.users.find(u => u.id == this.searchformNewLead.user )
          }

          await post(`/api/lead/${this.leadInfo.id}`, this.searchformNewLead).json(rs => {
            EventBus.$emit('toaster-msg', "Lead modifié avec succès !");
            EventBus.$emit("reloadLead")
            lead = rs
          })
          EventBus.$emit("reloadLead", {});
        } else {
          this.searchformNewLead.user = this.users.find(u => u.id == this.searchformNewLead.user)
          await post("/api/lead", this.searchformNewLead).json((rs) => {
            EventBus.$emit('toaster-msg', "Lead créé avec succès !");
            EventBus.$emit("reloadLead")
            this.createdLead = rs
            lead = rs
          })
        }
        if (this['create-action']) {

          let action = {
            creationDate: "",
            type: this['create-action'],
            message: "",
            leadId: this.leadInfo.id,
            user: this.leadInfo.user,
            bookingId: this.leadInfo.dataSup.infoCall.bookingId,
            phonecallId: this.leadInfo.dataSup.event.event_id,
          }
          await post('/api/lead/actions', action)
        }

        this.$emit('done', lead);
      } else {
        this.errorInputSource = true
      }
    }
  },
  watch:
      {
        searchformNewLead: {
          deep: true,
          handler(newVal) {
            if (newVal.source) {
              this.errorInputSource = false
            }
          }
        }
      }
})
</script>