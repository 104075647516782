<template>
  <div>
    <div v-for="(task, taskIndex) in tasks" :key="taskIndex">
      <task-item :task="task" />
    </div>
  </div>
</template>
<script>
import TaskItem from "@/components/commons/TaskItem";
import { get } from "@/utils/api";

export default {
  name: "BookingActions",
  components: { TaskItem },
  props: {
    booking: { type: Object, required: true },
  },
  data: () => ({
    tasks: [],
  }),
  created() {},
  watch: {
    booking: {
      immediate: true,
      handler(newVal, oldVal) {
        this.init();
      },
    },
  },
  methods: {
    init() {
      get(`/api/booking/${this.booking.id}/tasks`).json((data) => {
        this.$emit("actionsloaded", data.length);
        this.tasks = data;
      });
    },
  },
};
</script>