import { render, staticRenderFns } from "./CrmAgencyEvents.vue?vue&type=template&id=f778fc90&scoped=true&"
import script from "./CrmAgencyEvents.vue?vue&type=script&lang=js&"
export * from "./CrmAgencyEvents.vue?vue&type=script&lang=js&"
import style0 from "./CrmAgencyEvents.vue?vue&type=style&index=0&id=f778fc90&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f778fc90",
  null
  
)

export default component.exports