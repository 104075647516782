<template>
  <layout :drawerShow="false">
    <template slot="content">
      <v-container fluid>
        <v-row>
          <v-col cols="12">
            <v-data-table
              ref="tickettable"
              class="tbl"
              :headers="headers"
              :items="tickets"
              :expand="true"
              :loading="listing.loading"
              :footer-props="{
                'items-per-page-options': listing.rowsPerPageItems,
                'items-per-page-text': 'Lignes par page',
              }"
              item-key="ref"
              :sort-by="['bookingDate']"
              no-data-text="Il n'y a pas de billet à émettre"
            >
              <template #item.site="{ value }">
                <site-label :sitename="value"></site-label>
              </template>
              <template #item.bookingDate="{ value }">
                <div class="font-weight-regular">
                  {{
                    _parseAndFormatDate(
                      value,
                      datePatternConfig.serverLocalDateTime,
                      "dd/MM/yy"
                    )
                  }}
                </div>
                <div class="caption grey--text text--darken-1">
                  {{
                    _parseAndFormatDate(
                      value,
                      datePatternConfig.serverLocalDateTime,
                      datePatternConfig.fr.time
                    )
                  }}
                </div>
              </template>
              <template #item.startDate="{ value }">
                <div class="font-weight-regular">
                  {{
                    _parseAndFormatDate(
                      value,
                      datePatternConfig.serverLocalDateTime,
                      "dd/MM/yy"
                    )
                  }}
                </div>
              </template>
              <template #item.tickettingDate="{ value }">
                <div class="font-weight-regular">
                  {{
                    _parseAndFormatDate(
                      value,
                      datePatternConfig.serverLocalDateTime,
                      "dd/MM/yy"
                    )
                  }}
                </div>
              </template>
              <template #item.status="{ value }">
                <availability :value="value" kind="SupplierStatus" />
              </template>
              <template #item.check="{ item }">
                <v-tooltip top>
                  <template #activator="{ on }">
                    <v-btn v-on="on" fab small text @click="refresh(item)">
                      <v-icon>mdi-eye-check</v-icon>
                    </v-btn>
                  </template>
                  <span>Vérification de l'emission</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </layout>
</template>

<script>
import ColorMixin from "@/components/mixins/ColorMixin";
import SiteLabel from "@/components/commons/SiteLabel";
import Availability from "@/components/commons/Availability";
import Layout from "@/views/Layout";
import { get } from "@/utils/api";
import { EventBus } from "@/components/commons/event-bus";

export default {
  name: "TickettingList",
  components: {
    Layout,
    SiteLabel,
    Availability,
  },
  mixins: [ColorMixin],
  data() {
    return {
      listing: {
        loading: true,
        rowsPerPageItems: [{ text: "Tous", value: -1 }, 50, 200],
      },
      headers: [
        { text: "Dossier", value: "bookingId", align: "center" },
        { text: "Client", value: "customerName", align: "center" },
        { text: "Site", value: "site", align: "center", sortable: true },
        {
          text: "Résa",
          value: "bookingDate",
          align: "center",
          sortable: true,
          sort: this.sortByDateTime,
        },
        {
          text: "Départ",
          value: "startDate",
          align: "center",
          sortable: true,
          sort: this.sortByDateTime,
        },
        {
          text: "Destination",
          value: "destinations",
          align: "center",
          sortable: true,
        },
        { text: "PNR", value: "pnr", align: "center" },
        { text: "Airline", value: "airline", align: "center", sortable: true },
        {
          text: "Emission",
          value: "tickettingDate",
          align: "center",
          sortable: true,
          sort: this.sortByDateTime,
        },
        { text: "Statut", value: "status", align: "center", sortable: false },
        { text: "", value: "check", sortable: false },
      ],
      tickets: [],
    };
  },
  created() {
    const url = "/api/ticketting/list";

    get(url)
      .json((rs) => {
        rs.map((ticket) => {
          this.tickets.push(ticket);
        });
      })
      .then(() => (this.listing.loading = false));
  },
  watch: {},
  computed: {},
  methods: {
    openBooking(id) {
      let route = this.$router.resolve({ name: "Booking", params: { id: id } });
      window.open(route.href, "_blank");
    },
    customSort(items, index, isDesc) {
      const formatPattern = "yyyyMMdd";

      if (isDesc !== null) {
        items.sort((a, b) => {
          let aEl = a[index];
          let bEl = b[index];

          if (
            index === "bookingDate" ||
            index === "boardingDate" ||
            index === "tickettingDate"
          ) {
            aEl = this._parseAndFormatDate(
              a[index],
              this.datePatternConfig.serverLocalDateTime,
              formatPattern
            );
            bEl = this._parseAndFormatDate(
              b[index],
              this.datePatternConfig.serverLocalDateTime,
              formatPattern
            );
          }
          return !isDesc ? (aEl < bEl ? -1 : 1) : bEl < aEl ? -1 : 1;
        });
      }
      return items;
    },
    sortByDateTime(a, b) {
      return this._sortDates(a, b, this.datePatternConfig.serverLocalDateTime);
    },
    refresh(item) {
      this.listing.loading = true;
      const url = "/api/ticketting/list/refresh/";
      get(url + item.id).json((r) => {
        if (r.emitted) {
          let index = this.tickets.indexOf(item);
          this.tickets.splice(index, 1);
          EventBus.$emit("toaster-msg", "La prestation a bien été émise.");
        } else {
          EventBus.$emit("toaster-msg", "La prestation n'a pas été émise.");
        }
        this.listing.loading = false;
      });
    },
  },
};
</script>

<style></style>
