<template>
  <div class="callback-popover" v-show="popover "> <!--&& userRegistered-->
    <v-card
        elevation="24"
        outlined
        shaped
        tile
        v-if="callState != null && connected && !isZilCall && !panelIsClose && callState === 'Calling'">

      <v-card-text class="">
        <div class="d-flex flex-column justify-start">
          <div class="d-flex flex-row  mb-2 justify-space-between align-center">
            <span class="caption">{{ getStatusEvent }}</span>
            <v-chip small :color="connected ? 'green' : 'red'" outlined label class="pl-0 pr-2">
              <div :class="connected ? 'green':'red'" class="connected-status"></div>
              {{ connected ? "Connecté" : "Déconnecté" }}
            </v-chip>
            <div style="cursor: pointer" @click="close()">
              <v-icon class="text-black">close</v-icon>
            </div>
          </div>
          <div class="d-flex flex-row justify-space-between">
          <span v-if="getCallInfo.name">{{ getCallInfo.name }}</span>
          <span v-else>Client inconnu</span>
          <site-label v-if="callSite" :sitename="callSite" />
          </div>
          <span>Tel: {{ getCallInfo.number }}</span>
        </div>

      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions v-if="(infoCall && infoCall.id) || (infoCall && infoCall.lastBookingId)"
                      class="d-flex flex-row justify-space-around">
        <smart-btn v-if="infoCall && infoCall.id" x-small class="mx-1" @click.native="toCustomerPage()">
          Fiche Client
        </smart-btn>
        <smart-btn  x-small class="mx-1" @click.native="toBooking()" v-if="infoCall && infoCall.lastBookingId">
          Ref: {{ infoCall?.lastBookingId || '000000'}}
        </smart-btn>
        <smart-btn  x-small class="mx-1" @click.native="leadCreationShow = true"> <!--v-if="infoCall && infoCall.id"-->
          Créer Lead
        </smart-btn>
      </v-card-actions>
    </v-card>
    <div v-else class="white rounded-lg chipOnly" :class="connected ? '':'disconnected-status'">
      <v-chip small :color="connected ? 'green' : 'red'" outlined label class="pl-0 pr-2">
        <div :class="connected ? 'green':'red'" class="connected-status"></div>
        {{ connected ? "Connecté" : "Déconnecté" }}
      </v-chip>
    </div>
    <smart-dialog
      v-model="leadCreationShow"
      title="Nouveau lead">
      <LeadCreationDialog v-if="infoCall" @done="() => closeCreationLead()" :lead-info="buildedLead()" create-action="call"/>
      <template title="actions">
        <v-card-actions class="actions-btn mt-4">
          <v-spacer/>
          <smart-btn type="submit" form="creation-lead">
            <v-icon small class="mr-1">mdi-content-save</v-icon>
            Créer
          </smart-btn>
        </v-card-actions>
      </template>
    </smart-dialog>


  </div>

</template>
<script>
import SiteLabel from "@/components/commons/SiteLabel.vue";
import { get } from "@/utils/api";
import LeadCreationDialog from "@/views/lead/LeadCreationDialog.vue";
import jwtdecode from "jwt-decode";
import {EventBus} from "@/components/commons/event-bus";

export default {
  name: "CallbackPopover",
  components: {LeadCreationDialog, SiteLabel},
  component: {},
  props:{
  },
  data() {
    return {
      userRegistered: true,
      isZilCall: false,
      tryConnectCount: 0,
      maxTryConnect: 10,
      showPopover: false,
      isRinging: false,
      connected: false,
      callState: null,
      callSite: null,
      infoCall: {
        id: null,
        firstname: null,
        lastname: null,
        email: null,
        lastBookingId: null
      },
      phone: null,
      phoneCallId : null,
      connection: null,
      intervalId: null,
      panelIsClose : false,
      errorClose : false,
      leadCreationShow: false,
      event:null
    }
  },
  watch: {},
  created () {
    this.init()
  },
  computed: {
    popover() {
      if (this.getToken() != null) {
        return this.currentUser != null && (this.isAdmin || this.isExternalLanguageAdmin);
      } else return false
    },
    getCallInfo: function () {
      return {
        name: this.infoCall && this.infoCall.firstname ? [this.infoCall.firstname, this.infoCall.lastname.toUpperCase()].join(" ") : null,
        number: this.phone ? this.phone : "Numéro inconnu"
      }
    },
    getStatusEvent: function () {
      switch (this.callState) {
        case 'Ringing':
          return "Appel Entrant"
        case 'Answered':
          return "Appel en cours"
        case 'Calling' :
          return "Appel sortant en cours"
        case 'Idle':
          return "Rien à signaler"
        default:
          return "Rien à signaler"
      }
    },
  },
  methods: {
    init: function () {
      const isDev = process.env.NODE_ENV == 'development'

      if (this.popover) {
        const self = this
        const url = isDev ?`ws://localhost:9000` :  `wss://resas.smart-be.com`
        this.connection = new WebSocket(`${url}/api/phonecall/user/${this.currentUser.id}/status`)

        this.connection.onopen = function () {
          console.log("Socket Connected")
          self.connected = true
          self.subscribeWs();
          if (self.intervalId) {
            clearInterval(self.intervalId)
            self.intervalId = null
          }
        }

        this.connection.onmessage = function (event) {
          self.isZilCall = false;
          self.connected = self.connection.readyState === 1
          this.event = JSON.parse(event.data)
          self.callState = this.event.lastEvent
          self.phoneCallId = this.event.phoneCallId
          self.infoCall = this.event.details
          self.phone = this.event.phone
          if (this.event.lastEvent === 'userNotRegister') {
            self.userRegistered = false;
          } else {

            switch (this.event.site) {
              case "HOT" :
                self.callSite = "Hotelissima"
                break;
              case "HLF" :
                self.callSite = "HLagons"
                break;
              case "HLI" :
                self.callSite = "HLagonsItaly"
                break;
              case "ZIL" :
                self.isZilCall = true
                self.callSite = "ZilMaurice"
                break;
            }
          }
          if(self.callState == 'Calling'){
            self.panelIsClose = false
          }
        }

        this.connection.onclose = function () {
          self.connected = false
          if(this.userRegistered){
            self.intervalId = setInterval(self.tryConnect, 1000)
          }
        }
      }
    },
    tryConnect: function () {
      this.tryConnectCount++;
      if (!this.connected && this.tryConnectCount !== this.maxTryConnect && this.userRegistered) {
        this.init()
      }
    },
    subscribeWs: function () {
      this.connection.send(JSON.stringify("subscribe"));
    },
    toCustomerPage: function () {
      if (this.infoCall && this.infoCall.id) {
        this.$router.push({name: "customer-edition", params: {id: this.infoCall.id}});
      }
    },
    toBooking: function () {
      if (this.infoCall && this.infoCall.lastBookingId) {
        this.$router.push({name: 'Booking', params: {id: this.infoCall.lastBookingId}});
      }
    },
    close(){
      const url = `/api/phonecall/closepanel/${this.phoneCallId}`
      this.panelIsClose = true
      get(url)
    },
    buildedLead(){
       return {
         client_id: this.infoCall.id,
         site: 1,
         virtualSite: "Hotelissima",
         budget: null,
         status: "open",
         message: null,
         source: 'phone',
         score: 2,
         user: jwtdecode(localStorage.getItem("auth")).id,
         destinations: [],
         dataSup: {event:this.event,infoCall:this.infoCall}
       }
    },
    closeCreationLead(){
      this.leadCreationShow = false
      EventBus.$emit('reloadListLead', {})
    }
  }
}
</script>
<style scoped>

.callback-popover {
  position: fixed;
  bottom: 5px;
  right: 5px;
  z-index: 50;
  width: 300px;
}

.connected-status {
  height: 10px;
  width: 10px;
  border-radius: 10px;
  margin: 6px;
}

.chipOnly {
  padding: 2px;
  width: 88px;
  float: right;
}

</style>