<template>
    <v-card>
        <v-card-title>
            <v-icon>mdi-file</v-icon>
            {{$t('supplier-invoice')}}
        </v-card-title>
        <v-card-text>
            test
        </v-card-text>
    </v-card>
</template>

<script>
	export default {
		name: "BookingPrestaInvoices"
	}
</script>

<style scoped>

</style>