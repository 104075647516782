<template>
<widget-container
		:url="url"
		:bus="bus"
		color="transparent flat"
		cardclass="pa-1 "
		@ready="setData"
	>
		<template slot="content" v-if="data">
			<div>{{$tc('passanger', data.size, {size:data.size})}}</div>
				<div class="body-1">
					<span v-show="data.Adult !== 0">{{$tc('Adult-count-small', data.Adult, {size:data.Adult})}} </span>
					<span v-show="data.Teenager !== 0"> | {{$tc('Teen-count-small', data.Teenager, {size:data.Teenager})}} </span>
					<span v-show="data.Child !== 0"> | {{$tc('Child-count-small', data.Child, {size:data.Child})}} </span>
					<span v-show="data.Baby !== 0"> | {{$tc('Baby-count-small', data.Baby, {size:data.Baby})}} </span>
				</div>
		</template>
		<template slot="default">
			Aucun passager
		</template>
	</widget-container>
</template>

<script>
	import WidgetContainer from "@/components/commons/widgets/WidgetContainer";

	export default {
		name: "WidgetBookingPassenger",
		components: {WidgetContainer},
		mixins: [],
		props:{
			bus:{
				type:Object,
				required:true
			},
			url:{
				type:String,
				required:false,
				default:'/api/widget/passangerSize'
			}
		},
		data() {
			return {
				search:null,
				data:null
			}
		},
		created() {
		},
		watch: {
		},
		computed: {},
		methods: {
			setData(data){
				this.data = data;
			}
		}
	}
</script>

<style scoped>

</style>
